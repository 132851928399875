// Feature flags

(global as any).Feathery = {
  // want autosave on by default
  autoSave: process.env.REACT_APP_AUTOSAVE
    ? process.env.REACT_APP_AUTOSAVE === 'true'
    : true,
  // pricing plan override for local testing
  pricingTierOverride: process.env.REACT_APP_PRICING_TIER_OVERRIDE
    ? Number(process.env.REACT_APP_PRICING_TIER_OVERRIDE)
    : undefined
};

export {};
