import { memo, useState } from 'react';

import ProductSelectorModal from './ProductSelectorModal';
import { useProductCatalog } from './useProductCatalog';
import { DynamicTextTooltip } from '../../Core';
import ruleStyles from '../../NavigationRules/styles.module.scss';
import styles from './styles.module.scss';
import classNames from 'classnames';

const ProductSelectorWithModal = ({
  selectedId,
  className = '',
  placeholder = 'Select Product',
  onSelect = () => {},
  error = false,
  ...props
}: any) => {
  const { productCatalogNamesById } = useProductCatalog();
  const productName = productCatalogNamesById[selectedId];

  const [show, setShow] = useState(false);

  return (
    <>
      <ProductSelectorModal
        selectedId={selectedId}
        show={show}
        setShow={setShow}
        onSelect={onSelect}
      />
      <DynamicTextTooltip text={productName} maxLength={12}>
        <div
          className={classNames(
            styles.productSelector,
            ruleStyles.ruleTarget,
            ruleStyles.fieldTarget,
            selectedId && ruleStyles.selected,
            error && ruleStyles.fieldTargetError,
            className
          )}
          onClick={() => {
            setShow(true);
          }}
          {...props}
        >
          <span className={ruleStyles.fieldSpan}>
            {productName || placeholder}
          </span>
        </div>
      </DynamicTextTooltip>
    </>
  );
};

export default memo(ProductSelectorWithModal);
