import styles from './styles.module.scss';
import classNames from 'classnames';

export default function Label({ children, className, ...props }: any) {
  return (
    <div className={classNames(styles.label, className)} {...props}>
      {children}
    </div>
  );
}
