function Novidea({ width = 180, height = 70 }) {
  return (
    <img
      alt='Novidea logo'
      src={require('./Novidea.jpeg')}
      style={{ width, height }}
    />
  );
}

export default Novidea;
