import { useCallback, useState } from 'react';

// Returns a function that will force a component to rerender using some unused state
const useForcedRender = (): (() => void) => {
  const [_, setForceRender] = useState(false);

  const triggerRender = useCallback((): void => {
    setForceRender((prev) => !prev);
  }, []);

  return triggerRender;
};

export default useForcedRender;
