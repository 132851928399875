import { Col, Row } from 'react-bootstrap';
import { CollapsibleSection } from '../../../../Core';
import { AXIS } from '../../../../../utils/constants';
import {
  ColumnSplitIcon,
  ReleaseIcon,
  RotateIcon,
  RowSplitIcon
} from '../../../../Icons';
import ContainerControlButton from './ContainerControlButton';
import useViewport from '../../../../../hooks/useViewport';
import { useSelectedCell } from '../../../../../hooks/useSelectedCell';

export type CellPropertiesPanelProps = { cellNode: any };

const ContainerControlsSection = ({ cellNode }: CellPropertiesPanelProps) => {
  const { isMobile } = useViewport();
  const { rotateAxis, splitRows, splitCols, releaseChildren } =
    useSelectedCell();

  if (!cellNode.hasChildren() && isMobile) return null;

  return (
    <CollapsibleSection title='Controls' collapsible>
      <Row>
        <Col>
          <ContainerControlButton
            icon={<RotateIcon width={24} height={24} />}
            label={`Rotate into ${
              cellNode.axis === AXIS.ROW ? 'columns' : 'rows'
            }`}
            onClick={() => rotateAxis(cellNode)}
            size='wide'
          />
        </Col>
      </Row>
      {cellNode.canReleaseChildren() && !isMobile && (
        <Row>
          <Col>
            <ContainerControlButton
              icon={<ReleaseIcon width={24} height={24} />}
              label='Ungroup elements'
              onClick={() => releaseChildren(cellNode)}
              size='wide'
            />
          </Col>
        </Row>
      )}
      {!isMobile && (
        <>
          <Row>
            <Col>
              <ContainerControlButton
                icon={<RowSplitIcon />}
                label='Split into rows'
                onClick={() => splitRows(cellNode)}
              />
            </Col>
            <Col>
              <ContainerControlButton
                icon={<ColumnSplitIcon />}
                label='Split into cols'
                onClick={() => splitCols(cellNode)}
              />
            </Col>
          </Row>
        </>
      )}
    </CollapsibleSection>
  );
};

export default ContainerControlsSection;
