import '../../style/dialog-form.css';

import { useEffect, useRef } from 'react';

import IntegrationsSidebar from './IntegrationsSidebar';
import { ALL_INTEGRATIONS_MAP, INTEGRATIONS } from './types';

function ZapierSettingsSidebar() {
  const embed = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!embed.current) return;

    const script = document.createElement('script');
    script.type = 'module';
    script.src =
      'https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js';
    document.head.appendChild(script);

    // Load CSS
    const stylesheet = document.createElement('link');
    stylesheet.rel = 'stylesheet';
    stylesheet.href =
      'https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.css';
    document.head.appendChild(stylesheet);

    // Create and display zapier-app-directory
    const appDir: any = document.createElement('zapier-app-directory');
    appDir.app = 'feathery';
    appDir.linkTarget = 'new-tab';
    appDir.theme = 'light';
    appDir.applimit = 5;
    appDir.hide = 'zoho-crm,slack,hubspot';
    appDir.zapstyle = 'row';
    appDir.introcopy = 'hide';
    appDir.createWithoutTemplate = 'show';
    appDir.useThisZap = 'show';

    embed.current.appendChild(appDir);
  }, []);

  return (
    <IntegrationsSidebar
      integrationInfo={ALL_INTEGRATIONS_MAP[INTEGRATIONS.ZAPIER]}
      showConnectedStatus={false}
      hideConnectButtons
      hideSaveButton
    >
      <div style={{ width: '100%' }} ref={embed} />
    </IntegrationsSidebar>
  );
}

export default ZapierSettingsSidebar;
