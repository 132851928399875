export default function DocsIcon({
  width = 14,
  height = 15,
  color = '#333849'
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 14 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.6 8.9H8.4V7.5H5.6V8.9ZM5.6 6.8H11.2V5.4H5.6V6.8ZM5.6 4.7H11.2V3.3H5.6V4.7ZM4.2 11.7C3.815 11.7 3.4853 11.5628 3.2109 11.2884C2.9365 11.014 2.79953 10.6845 2.8 10.3V1.9C2.8 1.515 2.9372 1.1853 3.2116 0.910901C3.486 0.636501 3.81547 0.499535 4.2 0.500001H12.6C12.985 0.500001 13.3147 0.637201 13.5891 0.911601C13.8635 1.186 14.0005 1.51547 14 1.9V10.3C14 10.685 13.8628 11.0147 13.5884 11.2891C13.314 11.5635 12.9845 11.7005 12.6 11.7H4.2ZM4.2 10.3H12.6V1.9H4.2V10.3ZM1.4 14.5C1.015 14.5 0.685301 14.3628 0.410901 14.0884C0.136501 13.814 -0.000465478 13.4845 1.18846e-06 13.1V3.3H1.4V13.1H11.2V14.5H1.4Z'
        fill={color}
      />
    </svg>
  );
}
