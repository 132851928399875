function AppliedEpic({ width = 676, height = 144 }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 675.91 144.33'
      width={width}
      height={height}
    >
      <path
        fill='#00b3e6'
        d='M100.57,126.94l38.53,79H62ZM185.21,61.6H132.44l70.41,144.33h52.76Z'
        transform='translate(-62.05 -61.6)'
      />
      <polygon
        fill='#3d3d40'
        points='454.72 52.19 464.26 52.19 464.26 102.96 496.49 102.96 496.49 111.51 454.72 111.51 454.72 52.19'
      />
      <rect fill='#3d3d40' x='516.57' y='52.19' width='9.54' height='59.32' />
      <path
        fill='#3d3d40'
        d='M678.36,113.78h22.23a63.12,63.12,0,0,1,14.5,1.44C728.86,118.56,738,127.47,738,142.5s-9.36,24.76-23.67,28.72a62.69,62.69,0,0,1-16.93,1.89h-19Zm18.82,50.77A54.8,54.8,0,0,0,712,162.85C721.93,160,728,153.48,728,142.5s-6.39-16.56-15.39-18.9a52,52,0,0,0-13.15-1.26H687.9v42.22Z'
        transform='translate(-62.05 -61.6)'
      />
      <polygon
        fill='#3d3d40'
        points='266.57 86.12 276.46 65.33 276.65 65.33 287.09 86.13 271.53 86.13 271.53 94.68 291.41 94.68 299.87 111.51 310.4 111.51 279.98 51.92 273.31 51.92 243.97 111.51 254.5 111.51 266.57 86.12'
      />
      <path
        fill='#3d3d40'
        d='M497.78,132.78c0,11.52-8.37,17.1-16.74,18.81a53.63,53.63,0,0,1-10.89.9h-.58v-8.55h.94a38.91,38.91,0,0,0,8.55-.63c4.86-1.17,8.73-4.23,8.73-10.26,0-5.85-3.24-8.64-7.47-9.9-2.7-.81-5.86-.81-9.72-.81H463.3v50.77h-9.54V113.78h17.55a46.42,46.42,0,0,1,11.61,1.08c7.84,2.07,14.86,7.2,14.86,17.92'
        transform='translate(-62.05 -61.6)'
      />
      <path
        fill='#3d3d40'
        d='M434.78,132.78c0,11.52-8.37,17.1-16.74,18.81a53.62,53.62,0,0,1-10.89.9h-.58v-8.55h.94a38.9,38.9,0,0,0,8.55-.63c4.86-1.17,8.73-4.23,8.73-10.26,0-5.85-3.24-8.64-7.47-9.9-2.7-.81-5.85-.81-9.72-.81H400.3v50.77h-9.54V113.78h17.55a46.43,46.43,0,0,1,11.61,1.08c7.83,2.07,14.86,7.2,14.86,17.92'
        transform='translate(-62.05 -61.6)'
      />
      <path
        fill='#3d3d40'
        d='M623,147.27v17.28H656.3v8.55H613.45V113.78h42.31v8.55H623v24.94Zm6.26,0h23.2v-8.55h-23.2Z'
        transform='translate(-62.05 -61.6)'
      />
    </svg>
  );
}

export default AppliedEpic;
