import { SetFieldAction } from './SetFieldAction';
import { OpenURLAction } from './OpenURLAction';
import { NavigateToStepAction } from './NavigateToStepAction';
import { ConnectToAPIAction } from './ConnectToAPIAction';
import { SetErrorAction } from './SetErrorAction';
import { CalendlyAction } from './CalendlyAction';
import { SmsCodeAction } from './SmsCodeAction';
import { TelesignOtpAction } from './TelesignOtpAction';
import { EmailAction } from './EmailAction';
import { AIExtractionAction } from './AIExtractionAction';
import { RunIntegrationAction } from './RunIntegrationAction';
import { EmailCodeAction } from './EmailCodeAction';

export const SET_ACTION = 'set';
export const OPEN_URL_ACTION = 'open_url';
export const NAVIGATE_TO_STEP_ACTION = 'navigate_to_step';
export const CONNECT_TO_API = 'connect_to_api';
export const SET_ERROR_ACTION = 'set_error_action';
export const CALENDLY_ACTION = 'calendly';
export const SMS_CODE_ACTION = 'sms_code';
export const EMAIL_CODE_ACTION = 'email_code';
export const TELESIGN_VOICE_ACTION = 'telesign_voice';
export const TELESIGN_SMS_ACTION = 'telesign_sms';
export const EMAIL_ACTION = 'email';
export const AI_EXTRACTION_ACTION = 'ai_extraction';
export const RUN_INTEGRATION_ACTION = 'run_integration';

export const AVAILABLE_ACTIONS = [SET_ACTION];

export const PRIVATE_ACTIONS = [
  SMS_CODE_ACTION,
  EMAIL_CODE_ACTION,
  TELESIGN_VOICE_ACTION,
  TELESIGN_SMS_ACTION,
  EMAIL_ACTION
];

export const ACTION_DETAILS = {
  [SET_ACTION]: {
    name: 'Set',
    menuName: 'Set Field Value'
  },
  [OPEN_URL_ACTION]: {
    name: 'Open URL'
  },
  [NAVIGATE_TO_STEP_ACTION]: {
    name: 'Navigate to Step'
  },
  [CONNECT_TO_API]: {
    name: 'Connect to API'
  },
  [SET_ERROR_ACTION]: {
    name: 'Set Error'
  },
  [CALENDLY_ACTION]: {
    name: 'Set Calendly URL'
  },
  [SMS_CODE_ACTION]: {
    name: 'Send SMS Verification Code'
  },
  [EMAIL_CODE_ACTION]: {
    name: 'Send Email Verification Code'
  },
  [TELESIGN_VOICE_ACTION]: {
    name: 'Send Telesign Voice OTP'
  },
  [TELESIGN_SMS_ACTION]: {
    name: 'Send Telesign SMS OTP'
  },
  [EMAIL_ACTION]: {
    name: 'Send Custom Email'
  },
  [AI_EXTRACTION_ACTION]: {
    name: 'Run AI Extraction'
  },
  [RUN_INTEGRATION_ACTION]: {
    name: 'Run Integrations'
  }
};

export const ACTION_COMPONENTS: { [key: string]: any } = {
  [SET_ACTION]: { action: SetFieldAction, hasMenu: true },
  [OPEN_URL_ACTION]: { action: OpenURLAction },
  [NAVIGATE_TO_STEP_ACTION]: { action: NavigateToStepAction },
  [CONNECT_TO_API]: { action: ConnectToAPIAction },
  [SET_ERROR_ACTION]: { action: SetErrorAction },
  [CALENDLY_ACTION]: { action: CalendlyAction },
  [SMS_CODE_ACTION]: { action: SmsCodeAction },
  [EMAIL_CODE_ACTION]: { action: EmailCodeAction },
  [TELESIGN_VOICE_ACTION]: { action: TelesignOtpAction },
  [TELESIGN_SMS_ACTION]: { action: TelesignOtpAction },
  [EMAIL_ACTION]: { action: EmailAction },
  [AI_EXTRACTION_ACTION]: { action: AIExtractionAction },
  [RUN_INTEGRATION_ACTION]: { action: RunIntegrationAction }
};

// Supported action expression operators
interface ExpressionOperator {
  operator: string;
  emittedCode: string;
}

export const EXPRESSION_OPERATORS: Record<string, ExpressionOperator> = {
  '+': { operator: '+', emittedCode: '+' },
  '-': { operator: '-', emittedCode: '-' },
  '*': { operator: '*', emittedCode: '*' },
  '/': { operator: '/', emittedCode: '/' },
  '%': { operator: '%', emittedCode: '%' },
  '===': { operator: '===', emittedCode: '===' },
  '==': { operator: '==', emittedCode: '==' },
  '!==': { operator: '!==', emittedCode: '!==' },
  '!=': { operator: '!=', emittedCode: '!=' },
  '>': { operator: '>', emittedCode: '>' },
  '>=': { operator: '>=', emittedCode: '>=' },
  '<': { operator: '<', emittedCode: '<' },
  '<=': { operator: '<=', emittedCode: '<=' }
};
