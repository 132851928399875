export default function StytchCtaIcon(props: any) {
  return (
    <svg
      width='42'
      height='51'
      viewBox='0 0 42 51'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect
        x='1'
        y='1'
        width='40'
        height='40'
        rx='8'
        fill='#E6E9F0'
        stroke='#A3AABA'
        strokeMiterlimit='10'
      />
      <path
        d='M18.0255 21.1981V23.2167H19.186V21.1981C19.5257 20.6631 20.8982 18.1983 21.0259 17.9429H19.6763C19.4656 18.3164 18.0255 21.1981 18.0255 21.1981ZM18.3267 19.4744L17.6884 20.6306C17.3737 20.0816 16.4755 18.5079 16.1875 17.9391H17.5409L18.3267 19.4744Z'
        fill='#19303D'
      />
      <path
        d='M8.74024 22.2922C8.87757 22.2949 9.01473 22.2812 9.14881 22.2513C9.24022 22.2299 9.32682 22.1916 9.40416 22.1384C9.4649 22.0968 9.51199 22.0382 9.5395 21.9698C9.56669 21.8996 9.58055 21.8249 9.58035 21.7496C9.5794 21.6644 9.55688 21.5809 9.51491 21.5068C9.47294 21.4326 9.41288 21.3703 9.34032 21.3257C9.1803 21.2151 8.90516 21.0948 8.51489 20.965C8.34466 20.905 8.17442 20.8361 8.00418 20.7582C7.83801 20.6835 7.6836 20.585 7.54582 20.4658C7.40939 20.3459 7.29752 20.2006 7.21641 20.0381C7.12639 19.8473 7.08264 19.638 7.08874 19.4271C7.08513 19.2036 7.13114 18.9821 7.22344 18.7785C7.31247 18.5879 7.4434 18.4199 7.60647 18.287C7.78459 18.1453 7.98864 18.0397 8.20719 17.9761C8.46535 17.9 8.7335 17.8632 9.00262 17.8669C9.309 17.8614 9.61462 17.8992 9.91039 17.9793C10.1284 18.0399 10.3396 18.1229 10.5405 18.227L10.2028 19.1494C10.0472 19.0702 9.88535 19.0038 9.71891 18.9509C9.5118 18.8895 9.29647 18.8604 9.0805 18.8647C8.80004 18.8647 8.59873 18.9035 8.47659 18.9809C8.41667 19.0182 8.36795 19.071 8.33558 19.1337C8.30322 19.1964 8.28841 19.2667 8.29273 19.3371C8.2886 19.4226 8.31138 19.5072 8.35785 19.5791C8.40902 19.6502 8.47419 19.7102 8.54936 19.7553C8.63953 19.8119 8.73487 19.8597 8.83408 19.8983C8.94176 19.9409 9.06071 19.9844 9.19094 20.0291C9.46119 20.1292 9.69633 20.2279 9.89635 20.3253C10.0793 20.4108 10.2473 20.5253 10.3936 20.6643C10.5254 20.7927 10.6268 20.9489 10.6905 21.1214C10.7602 21.3269 10.7933 21.5429 10.7882 21.7598C10.7882 22.245 10.6179 22.6212 10.2774 22.8884C9.93701 23.1558 9.42459 23.2896 8.74024 23.29C8.53203 23.2914 8.324 23.2776 8.11781 23.2485C7.95198 23.2251 7.7878 23.1912 7.62626 23.147C7.5009 23.1129 7.37805 23.0703 7.25855 23.0193C7.1564 22.974 7.06703 22.9319 7 22.8917L7.33005 21.9615C7.51306 22.0578 7.70562 22.1345 7.90459 22.1907C8.17697 22.2637 8.4583 22.2979 8.74024 22.2922Z'
        fill='#19303D'
      />
      <path
        d='M15.5908 17.9447V18.9732H14.0139V23.2235H12.8483V18.9732H11.2031V17.9447H15.5908Z'
        fill='#19303D'
      />
      <path
        d='M26.0517 17.9447V18.9732H24.4781V23.2235H23.3092V18.9732H21.6641V17.9447H26.0517Z'
        fill='#19303D'
      />
      <path
        d='M29.0444 23.2933C28.226 23.2933 27.6027 23.0593 27.1745 22.5911C26.7464 22.123 26.5321 21.4561 26.5317 20.5904C26.5251 20.1986 26.5915 19.8089 26.7277 19.4413C26.8467 19.1231 27.0297 18.8327 27.2652 18.5878C27.4944 18.3547 27.7721 18.1747 28.0785 18.0605C28.4051 17.9386 28.7513 17.8775 29.0999 17.8805C29.2947 17.8791 29.4891 17.8954 29.6808 17.929C29.8356 17.9571 29.9884 17.9947 30.1386 18.0414C30.2515 18.0767 30.3615 18.1211 30.4674 18.1741C30.5548 18.2188 30.6174 18.2539 30.6588 18.2788L30.332 19.2217C30.16 19.1304 29.9787 19.0576 29.7913 19.0047C29.5627 18.9416 29.3262 18.9113 29.0891 18.9147C28.9149 18.9149 28.7421 18.9454 28.5783 19.0047C28.4105 19.0657 28.259 19.1644 28.1353 19.2932C27.998 19.4401 27.893 19.614 27.827 19.8039C27.7436 20.0505 27.7043 20.3098 27.7108 20.57C27.7086 20.7949 27.7332 21.0193 27.7842 21.2384C27.8289 21.4318 27.913 21.6139 28.0313 21.7734C28.1491 21.9265 28.3025 22.0485 28.4781 22.1289C28.6882 22.2217 28.9163 22.2664 29.1459 22.2598C29.2941 22.2609 29.4422 22.2508 29.5889 22.2298C29.7064 22.2129 29.8228 22.189 29.9375 22.1583C30.0292 22.1351 30.119 22.105 30.2063 22.0683C30.2837 22.033 30.3539 22.0004 30.4169 21.9706L30.7323 22.9039C30.5209 23.0249 30.2935 23.1156 30.0568 23.1734C29.7262 23.2584 29.3857 23.2987 29.0444 23.2933Z'
        fill='#19303D'
      />
      <path
        d='M34.7642 17.9503H35.9983V23.231H34.7642V20.9679H32.7757V23.231H31.6094V17.9503H32.7757V19.9389H34.7642V17.9503Z'
        fill='#19303D'
      />
      <path
        d='M30.7759 43.0393C30.5716 43.905 30.2651 44.8216 29.9075 45.5854C28.9369 47.4696 27.4044 48.9463 25.5144 49.9138C23.5732 50.8813 21.2745 51.2887 18.9758 50.7795C13.561 49.6592 10.0873 44.3633 11.2112 38.9656C12.335 33.5678 17.5965 30.0542 23.0113 31.2254C24.9525 31.6328 26.6382 32.6003 28.0685 33.9243C30.4694 36.3176 31.4911 39.7803 30.7759 43.0393Z'
        fill='#E2626E'
      />
      <rect
        x='19.75'
        y='35.75'
        width='2.5'
        height='6.5'
        rx='1.25'
        fill='white'
        stroke='white'
        strokeWidth='0.5'
      />
      <rect
        x='19.75'
        y='44.75'
        width='2.5'
        height='2.5'
        rx='1.25'
        fill='white'
        stroke='white'
        strokeWidth='0.5'
      />
    </svg>
  );
}
