import { useState } from 'react';
import { DocumentTriggerModal } from '../../components/Modals';
import { Button } from '../../components/Core/Button/button';

export default function DocumentAutofillButton({
  className
}: {
  className?: string;
}) {
  const [showTriggerModal, setShowTriggerModal] = useState(false);

  return (
    <>
      <Button onClick={() => setShowTriggerModal(true)}>
        Set Autofill Trigger
      </Button>
      <DocumentTriggerModal
        show={showTriggerModal}
        close={() => setShowTriggerModal(false)}
      />
    </>
  );
}
