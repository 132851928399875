export default function LinkIcon({
  width = 32,
  height = 32,
  color = '#A3AABA',
  ...props
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 32 32'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M19.1872 23.4404C19.773 22.8546 19.773 21.9049 19.1872 21.3191C18.6015 20.7333 17.6517 20.7333 17.0659 21.3191L19.1872 23.4404ZM14.238 26.2684L13.1773 25.2077L14.238 26.2684ZM5.73165 17.762L4.67099 16.7014H4.67099L5.73165 17.762ZM10.6809 14.9341C11.2667 14.3483 11.2667 13.3985 10.6809 12.8128C10.0951 12.227 9.14538 12.227 8.55959 12.8128L10.6809 14.9341ZM12.8128 8.55959C12.227 9.14538 12.227 10.0951 12.8128 10.6809C13.3985 11.2667 14.3483 11.2667 14.9341 10.6809L12.8128 8.55959ZM17.762 5.73165L16.7014 4.67099V4.67099L17.762 5.73165ZM26.2684 14.238L27.329 15.2986L26.2684 14.238ZM21.3191 17.0659C20.7333 17.6517 20.7333 18.6015 21.3191 19.1872C21.9049 19.773 22.8546 19.773 23.4404 19.1872L21.3191 17.0659ZM20.6454 13.4759C21.2312 12.8901 21.2312 11.9404 20.6454 11.3546C20.0596 10.7688 19.1098 10.7688 18.5241 11.3546L20.6454 13.4759ZM11.3544 18.5242C10.7687 19.11 10.7687 20.0597 11.3544 20.6455C11.9402 21.2313 12.89 21.2313 13.4758 20.6455L11.3544 18.5242ZM17.0659 21.3191L13.1773 25.2077L15.2986 27.329L19.1872 23.4404L17.0659 21.3191ZM13.1773 25.2077C11.4542 26.9308 8.51538 26.9308 6.79231 25.2077L4.67099 27.329C7.56563 30.2237 12.404 30.2237 15.2986 27.329L13.1773 25.2077ZM6.79231 25.2077C5.06923 23.4846 5.06923 20.5458 6.79231 18.8227L4.67099 16.7014C1.77634 19.596 1.77634 24.4344 4.67099 27.329L6.79231 25.2077ZM6.79231 18.8227L10.6809 14.9341L8.55959 12.8128L4.67099 16.7014L6.79231 18.8227ZM14.9341 10.6809L18.8227 6.79231L16.7014 4.67099L12.8128 8.55959L14.9341 10.6809ZM18.8227 6.79231C20.5458 5.06923 23.4846 5.06923 25.2077 6.79231L27.329 4.67099C24.4344 1.77634 19.596 1.77634 16.7014 4.67099L18.8227 6.79231ZM25.2077 6.79231C26.9308 8.51538 26.9308 11.4542 25.2077 13.1773L27.329 15.2986C30.2237 12.404 30.2237 7.56563 27.329 4.67099L25.2077 6.79231ZM25.2077 13.1773L21.3191 17.0659L23.4404 19.1872L27.329 15.2986L25.2077 13.1773ZM18.5241 11.3546L11.3544 18.5242L13.4758 20.6455L20.6454 13.4759L18.5241 11.3546Z' />
    </svg>
  );
}
