export default function LogicRulesIcon(props: any) {
  return (
    <svg
      width='128'
      height='127'
      viewBox='0 0 128 127'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M64 126C98.493 126 126.455 98.0178 126.455 63.5C126.455 28.9822 98.493 1 64 1C29.507 1 1.54492 28.9822 1.54492 63.5C1.54492 98.0178 29.507 126 64 126Z'
        fill='#F1F3F9'
        stroke='#D6DCE8'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M110.66 120.562H18.3553C15.52 120.562 13.1572 118.276 13.1572 115.36V11.64C13.1572 8.80271 15.4412 6.51709 18.3553 6.51709H110.66C113.495 6.51709 115.858 8.80271 115.858 11.7189V115.36C115.858 118.197 113.574 120.562 110.66 120.562Z'
        fill='white'
        stroke='#D6DCE8'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M104.91 37.8065H23.4739C22.5288 37.8065 21.7412 37.0184 21.7412 36.0726V20.6249C21.7412 19.6791 22.5288 18.891 23.4739 18.891H104.91C105.855 18.891 106.642 19.6791 106.642 20.6249V35.9938C106.721 37.0184 105.933 37.8065 104.91 37.8065Z'
        fill='#F1F3F9'
        stroke='#D6DCE8'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M30.2475 30.7133C31.5524 30.7133 32.6102 29.6547 32.6102 28.3488C32.6102 27.043 31.5524 25.9844 30.2475 25.9844C28.9426 25.9844 27.8848 27.043 27.8848 28.3488C27.8848 29.6547 28.9426 30.7133 30.2475 30.7133Z'
        fill='#AAB2C5'
      />
      <path
        d='M88.3704 29.925H40.5643C40.1705 29.925 39.8555 29.6097 39.8555 29.2157V27.4029C39.8555 27.0089 40.1705 26.6936 40.5643 26.6936H88.3704C88.7642 26.6936 89.0792 27.0089 89.0792 27.4029V29.2157C89.0792 29.6097 88.7642 29.925 88.3704 29.925Z'
        fill='#AAB2C5'
      />
      <path
        d='M104.91 62.0813H23.4739C22.5288 62.0813 21.7412 61.2932 21.7412 60.3474V44.9786C21.7412 44.0328 22.5288 43.2446 23.4739 43.2446H104.91C105.855 43.2446 106.642 44.0328 106.642 44.9786V60.3474C106.721 61.2932 105.933 62.0813 104.91 62.0813Z'
        fill='#F1F3F9'
        stroke='#D6DCE8'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M30.2475 54.988C31.5524 54.988 32.6102 53.9294 32.6102 52.6236C32.6102 51.3178 31.5524 50.2592 30.2475 50.2592C28.9426 50.2592 27.8848 51.3178 27.8848 52.6236C27.8848 53.9294 28.9426 54.988 30.2475 54.988Z'
        fill='#AAB2C5'
      />
      <path
        d='M88.3704 54.2786H40.5643C40.1705 54.2786 39.8555 53.9634 39.8555 53.5693V51.7566C39.8555 51.3625 40.1705 51.0472 40.5643 51.0472H88.3704C88.7642 51.0472 89.0792 51.3625 89.0792 51.7566V53.5693C89.0792 53.9634 88.7642 54.2786 88.3704 54.2786Z'
        fill='#AAB2C5'
      />
      <path
        d='M104.91 110.71H23.4739C22.5288 110.71 21.7412 109.922 21.7412 108.976V93.6072C21.7412 92.6614 22.5288 91.8733 23.4739 91.8733H104.91C105.855 91.8733 106.642 92.6614 106.642 93.6072V108.976C106.721 109.922 105.933 110.71 104.91 110.71Z'
        fill='#F1F3F9'
        stroke='#D6DCE8'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M30.2475 103.617C31.5524 103.617 32.6102 102.558 32.6102 101.252C32.6102 99.9463 31.5524 98.8877 30.2475 98.8877C28.9426 98.8877 27.8848 99.9463 27.8848 101.252C27.8848 102.558 28.9426 103.617 30.2475 103.617Z'
        fill='#AAB2C5'
      />
      <path
        d='M88.3704 102.829H40.5643C40.1705 102.829 39.8555 102.513 39.8555 102.119V100.307C39.8555 99.9124 40.1705 99.5972 40.5643 99.5972H88.3704C88.7642 99.5972 89.0792 99.9124 89.0792 100.307V102.119C89.0792 102.513 88.7642 102.829 88.3704 102.829Z'
        fill='#AAB2C5'
      />
      <path
        d='M123.103 85.3562H41.6672C41.2751 85.3562 40.9346 85.0165 40.9346 84.6223V69.2535C40.9346 68.8593 41.2751 68.5195 41.6672 68.5195H123.103C123.495 68.5195 123.836 68.8593 123.836 69.2535V84.6223C123.836 85.0165 123.495 85.3562 123.103 85.3562Z'
        fill='#D6DCE8'
        stroke='#AAB2C5'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M48.4409 79.3417C49.7458 79.3417 50.8036 78.2831 50.8036 76.9772C50.8036 75.6714 49.7458 74.6128 48.4409 74.6128C47.136 74.6128 46.0781 75.6714 46.0781 76.9772C46.0781 78.2831 47.136 79.3417 48.4409 79.3417Z'
        fill='#AAB2C5'
      />
      <path
        d='M106.564 78.5537H58.7577C58.3639 78.5537 58.0488 78.2384 58.0488 77.8443V76.0316C58.0488 75.6375 58.3639 75.3223 58.7577 75.3223H106.564C106.958 75.3223 107.273 75.6375 107.273 76.0316V77.8443C107.273 78.2384 106.958 78.5537 106.564 78.5537Z'
        fill='#AAB2C5'
      />
    </svg>
  );
}
