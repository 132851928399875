function AMS360({ width = 144, height = 72 }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 398 112'
      fill='none'
    >
      <path
        d='M54.2872 43.6837L50.5563 40.1376L44.5869 44.4302L53.3545 52.4557C54.0476 53.0899 54.9064 53.5143 55.831 53.6795C56.7556 53.8447 57.7081 53.7439 58.5777 53.3889C59.4327 53.0002 60.1541 52.368 60.6518 51.5713C61.1495 50.7746 61.4013 49.8488 61.3759 48.9096V32.4854L54.2872 37.5246V43.6837Z'
        fill='#D66C2C'
      />
      <path
        d='M61.3758 12.7019C62.122 14.0084 63.0547 15.3148 63.9875 16.8079L69.7703 12.7019C66.9722 8.22254 64.7336 4.48976 63.2413 2.2501C62.6883 1.36277 61.8597 0.681428 60.8825 0.31058C59.9053 -0.0602685 58.8335 -0.100145 57.8315 0.197066C56.8099 0.476317 55.9089 1.08488 55.2681 1.92845C54.6274 2.77203 54.2826 3.8035 54.2872 4.86304V23.9002L61.3758 18.6743V12.7019Z'
        fill='#D66C2C'
      />
      <path
        d='M94.5811 0.571046C93.7872 0.168521 92.8985 -0.00945186 92.0109 0.0563311C91.1232 0.122114 90.2704 0.429155 89.5444 0.944324L83.2019 5.6103L80.4038 7.66333L12.6882 56.1895L6.90537 60.2955V10.2763L36.1928 36.9657L42.1622 32.673L8.02463 1.50424C7.33153 0.870049 6.47277 0.445694 5.54815 0.2805C4.62353 0.115306 3.67104 0.216058 2.8014 0.571046C1.94648 0.959721 1.22506 1.59195 0.727369 2.38866C0.229677 3.18537 -0.0222015 4.11121 0.00323224 5.05038V64.5882C-0.0304157 65.4812 0.199256 66.3643 0.663642 67.1276C1.12803 67.8909 1.8066 68.5007 2.61485 68.8809C3.30669 69.241 4.0736 69.4328 4.85338 69.4408C5.85848 69.4173 6.83332 69.0921 7.65154 68.5076L16.9788 61.7886L84.6943 13.2625L90.4772 9.15644V45.7377C86.3732 39.0187 80.9634 30.2466 75.9267 22.0345L70.1439 26.1406C77.046 37.3389 84.8808 49.8437 88.6117 56.1895C89.1509 57.0971 89.9692 57.8057 90.9444 58.2094C91.9195 58.6131 92.999 58.6902 94.0215 58.4291C95.0431 58.1499 95.9441 57.5413 96.5849 56.6978C97.2256 55.8542 97.5704 54.8227 97.5658 53.7632V4.6771C97.4269 3.80581 97.0841 2.97971 96.5654 2.26615C96.0467 1.55259 95.3668 0.971769 94.5811 0.571046Z'
        fill='#D66C2C'
      />
      <path
        d='M130.212 101.915C130.249 102.459 130.443 102.981 130.772 103.416C131.1 103.852 131.549 104.181 132.063 104.365C132.576 104.548 133.132 104.578 133.662 104.45C134.192 104.321 134.673 104.041 135.046 103.643C135.418 103.245 135.667 102.747 135.761 102.21C135.854 101.672 135.789 101.119 135.573 100.618C135.357 100.118 134.999 99.691 134.544 99.3908C134.089 99.0907 133.556 98.9301 133.01 98.9289C132.245 98.9581 131.522 99.288 130.998 99.847C130.474 100.406 130.192 101.149 130.212 101.915ZM138.98 101.915C139.034 102.657 138.934 103.403 138.686 104.104C138.438 104.806 138.048 105.449 137.54 105.992C137.032 106.535 136.417 106.968 135.734 107.262C135.05 107.556 134.314 107.705 133.57 107.701C132.957 107.752 132.341 107.641 131.785 107.379C131.228 107.117 130.75 106.713 130.399 106.208V111.994H127.414V96.316H130.399V97.6224C130.804 97.1296 131.318 96.7381 131.901 96.4789C132.484 96.2197 133.119 96.1 133.757 96.1293C134.486 96.1448 135.205 96.3093 135.869 96.6128C136.532 96.9163 137.127 97.3524 137.616 97.8943C138.106 98.4361 138.479 99.0723 138.713 99.7638C138.948 100.455 139.038 101.187 138.98 101.915Z'
        fill='#6C7376'
      />
      <path
        d='M143.454 101.915C143.433 102.313 143.495 102.711 143.638 103.083C143.78 103.455 143.999 103.793 144.281 104.075C144.562 104.356 144.9 104.576 145.272 104.718C145.644 104.86 146.042 104.923 146.439 104.901C146.833 104.906 147.223 104.833 147.587 104.684C147.952 104.536 148.283 104.316 148.561 104.038C148.839 103.76 149.059 103.428 149.207 103.064C149.355 102.699 149.429 102.309 149.424 101.915C149.445 101.517 149.383 101.119 149.241 100.747C149.098 100.375 148.879 100.037 148.598 99.7557C148.316 99.474 147.978 99.2548 147.606 99.1123C147.234 98.9699 146.837 98.9074 146.439 98.929C146.038 98.8916 145.633 98.9431 145.254 99.08C144.875 99.2168 144.531 99.4356 144.246 99.7207C143.961 100.006 143.742 100.35 143.605 100.73C143.469 101.109 143.417 101.514 143.454 101.915ZM152.409 101.915C152.446 103.104 152.127 104.276 151.495 105.283C150.862 106.289 149.943 107.083 148.856 107.564C147.769 108.045 146.563 108.19 145.393 107.981C144.223 107.772 143.142 107.219 142.289 106.391C141.435 105.564 140.848 104.501 140.602 103.337C140.356 102.174 140.463 100.964 140.909 99.8615C141.354 98.7592 142.119 97.8152 143.104 97.1503C144.089 96.4854 145.251 96.13 146.439 96.1294C147.215 96.0991 147.99 96.2266 148.715 96.5042C149.441 96.7819 150.103 97.2039 150.661 97.7447C151.219 98.2855 151.661 98.9339 151.962 99.6508C152.262 100.368 152.414 101.138 152.409 101.915Z'
        fill='#6C7376'
      />
      <path
        d='M166.773 104.155L169.012 96.3164H172.183L168.452 107.515H165.094L162.669 99.8625L160.244 107.515H156.886L152.969 96.3164H156.14L158.565 104.155L160.99 96.3164H164.161L166.773 104.155Z'
        fill='#6C7376'
      />
      <path
        d='M176.101 100.796H180.951C180.951 100.152 180.696 99.5349 180.241 99.0799C179.786 98.6249 179.169 98.3692 178.526 98.3692C177.883 98.3692 177.266 98.6249 176.811 99.0799C176.356 99.5349 176.101 100.152 176.101 100.796ZM183.936 101.915V102.849H176.101C176.194 103.509 176.535 104.108 177.055 104.524C177.576 104.941 178.235 105.142 178.899 105.088C179.94 105.084 180.94 104.683 181.697 103.968L183.376 105.835C182.087 106.959 180.422 107.559 178.712 107.515C177.942 107.558 177.171 107.438 176.449 107.162C175.728 106.887 175.073 106.462 174.527 105.916C173.981 105.37 173.557 104.715 173.282 103.993C173.006 103.271 172.886 102.5 172.93 101.729C172.865 100.968 172.963 100.203 173.219 99.4842C173.474 98.7654 173.881 98.1097 174.411 97.5613C174.942 97.013 175.584 96.5849 176.293 96.3057C177.003 96.0265 177.764 95.9028 178.526 95.9429C179.277 95.9663 180.017 96.141 180.699 96.4565C181.382 96.7721 181.994 97.222 182.499 97.7794C183.004 98.3369 183.391 98.9904 183.638 99.7011C183.885 100.412 183.986 101.165 183.936 101.915Z'
        fill='#6C7376'
      />
      <path
        d='M193.45 96.3156L193.077 99.3018C192.481 99.0718 191.85 98.9455 191.212 98.9285C190.899 98.9216 190.59 98.9811 190.302 99.1032C190.015 99.2253 189.757 99.4071 189.545 99.6365C189.334 99.866 189.173 100.138 189.074 100.434C188.976 100.73 188.941 101.044 188.973 101.355V107.327H185.988V96.3156H188.786V97.4354C189.123 96.9154 189.599 96.5 190.159 96.2361C190.72 95.9723 191.343 95.8705 191.958 95.9423C192.517 96.1289 193.077 96.1289 193.45 96.3156Z'
        fill='#6C7376'
      />
      <path
        d='M197.367 100.796H202.218C202.211 100.488 202.142 100.184 202.015 99.9032C201.888 99.6224 201.706 99.3699 201.48 99.1609C201.254 98.9519 200.988 98.7907 200.698 98.6867C200.408 98.5828 200.1 98.5383 199.793 98.5559C198.487 98.5559 197.741 99.4891 197.367 100.796ZM205.389 101.915V102.849H197.554C197.647 103.509 197.988 104.108 198.509 104.524C199.029 104.941 199.688 105.142 200.352 105.088C201.393 105.084 202.393 104.683 203.15 103.968L204.829 105.835C203.54 106.959 201.876 107.559 200.166 107.515C199.395 107.558 198.624 107.438 197.902 107.162C197.181 106.887 196.526 106.462 195.98 105.916C195.435 105.37 195.01 104.715 194.735 103.993C194.459 103.271 194.34 102.5 194.383 101.729C194.318 100.968 194.416 100.203 194.672 99.4842C194.928 98.7654 195.334 98.1097 195.865 97.5613C196.395 97.013 197.037 96.5849 197.746 96.3057C198.456 96.0265 199.217 95.9028 199.979 95.9429C200.731 95.9663 201.47 96.141 202.152 96.4565C202.835 96.7721 203.447 97.222 203.952 97.7794C204.457 98.3369 204.844 98.9904 205.091 99.7011C205.338 100.412 205.439 101.165 205.389 101.915Z'
        fill='#6C7376'
      />
      <path
        d='M209.678 101.916C209.714 102.46 209.909 102.982 210.237 103.417C210.566 103.852 211.015 104.182 211.528 104.366C212.041 104.549 212.597 104.579 213.127 104.451C213.657 104.322 214.138 104.042 214.511 103.644C214.884 103.246 215.132 102.748 215.226 102.21C215.32 101.673 215.255 101.12 215.038 100.619C214.822 100.119 214.465 99.6918 214.009 99.3917C213.554 99.0915 213.021 98.931 212.476 98.9298C212.092 98.9289 211.712 99.007 211.36 99.1592C211.008 99.3115 210.691 99.5345 210.428 99.8146C210.166 100.095 209.964 100.426 209.835 100.787C209.706 101.149 209.652 101.533 209.678 101.916ZM215.274 97.4367V90.9043H218.259V107.329H215.274V106.022C214.869 106.515 214.354 106.906 213.771 107.166C213.189 107.425 212.553 107.544 211.916 107.515C211.181 107.491 210.458 107.322 209.788 107.018C209.119 106.714 208.515 106.281 208.013 105.744C207.51 105.207 207.119 104.576 206.86 103.887C206.602 103.198 206.482 102.465 206.506 101.729C206.452 100.987 206.552 100.242 206.8 99.5402C207.048 98.8386 207.438 98.1959 207.946 97.6525C208.454 97.109 209.069 96.6767 209.753 96.3826C210.436 96.0885 211.172 95.939 211.916 95.9436C213.172 96.049 214.355 96.575 215.274 97.4367Z'
        fill='#6C7376'
      />
      <path
        d='M228.893 101.915C228.929 102.459 229.124 102.981 229.452 103.416C229.781 103.851 230.23 104.181 230.743 104.365C231.257 104.548 231.812 104.578 232.342 104.449C232.872 104.321 233.353 104.041 233.726 103.643C234.099 103.245 234.348 102.747 234.441 102.209C234.535 101.672 234.47 101.119 234.254 100.618C234.037 100.117 233.68 99.6907 233.225 99.3906C232.769 99.0904 232.236 98.9299 231.691 98.9287C231.307 98.9278 230.928 99.0059 230.575 99.1582C230.223 99.3104 229.906 99.5334 229.643 99.8135C229.381 100.094 229.179 100.425 229.05 100.786C228.921 101.148 228.867 101.532 228.893 101.915ZM237.66 101.915C237.715 102.657 237.615 103.402 237.367 104.104C237.119 104.806 236.729 105.448 236.221 105.992C235.713 106.535 235.098 106.968 234.414 107.262C233.731 107.556 232.994 107.705 232.251 107.701C231.614 107.73 230.978 107.61 230.395 107.351C229.813 107.092 229.298 106.7 228.893 106.208V107.514H225.908V91.0898H228.893V97.6222C229.259 97.1325 229.739 96.7402 230.292 96.4799C230.845 96.2195 231.454 96.0991 232.064 96.1291C232.822 96.104 233.576 96.238 234.279 96.5224C234.982 96.8068 235.618 97.2353 236.145 97.7803C236.672 98.3254 237.079 98.9749 237.34 99.687C237.601 100.399 237.71 101.158 237.66 101.915Z'
        fill='#6C7376'
      />
      <path
        d='M242.325 111.994H239.153L243.071 105.088L238.221 96.3164H241.579L244.75 102.102L247.548 96.3164H250.906L242.325 111.994Z'
        fill='#6C7376'
      />
      <path
        d='M278.327 97.6229H285.603C285.538 96.7214 285.138 95.8768 284.482 95.2551C283.826 94.6335 282.962 94.2797 282.058 94.2634C281.13 94.2588 280.234 94.6004 279.544 95.2216C278.854 95.8428 278.42 96.699 278.327 97.6229ZM290.08 99.1161V100.609H278.327C278.492 101.605 279.025 102.502 279.819 103.124C280.614 103.746 281.612 104.048 282.618 103.969C283.379 103.973 284.133 103.826 284.837 103.538C285.541 103.25 286.182 102.825 286.722 102.289L289.334 105.089C287.362 106.764 284.829 107.631 282.245 107.515C281.105 107.565 279.966 107.377 278.902 106.964C277.839 106.55 276.872 105.92 276.065 105.112C275.258 104.305 274.628 103.338 274.215 102.273C273.801 101.209 273.614 100.07 273.664 98.9294C273.574 97.7863 273.726 96.637 274.111 95.557C274.496 94.4769 275.105 93.4904 275.897 92.6622C276.69 91.8339 277.648 91.1826 278.71 90.751C279.772 90.3193 280.913 90.117 282.058 90.1574C286.908 90.344 290.08 93.8902 290.08 99.1161Z'
        fill='#6C7376'
      />
      <path
        d='M303.51 90.7177L302.764 95.1971C301.941 94.8339 301.052 94.6433 300.152 94.6371C299.663 94.61 299.175 94.6895 298.72 94.8702C298.265 95.0508 297.854 95.3283 297.517 95.6834C297.18 96.0385 296.924 96.4627 296.767 96.9266C296.61 97.3904 296.556 97.8829 296.608 98.3699V107.329H292.131V90.7177H296.421V92.5841C296.951 91.852 297.655 91.2638 298.469 90.8727C299.284 90.4817 300.183 90.3001 301.085 90.3444C301.899 90.4255 302.709 90.5501 303.51 90.7177Z'
        fill='#6C7376'
      />
      <path
        d='M315.635 94.8229H309.666V100.795C309.666 102.848 310.785 103.595 312.091 103.595C313.227 103.506 314.321 103.12 315.262 102.475L316.941 106.021C315.342 107.102 313.46 107.686 311.531 107.701C307.427 107.701 305.375 105.461 305.375 101.169V85.8643H309.852V90.7169H315.821L315.635 94.8229Z'
        fill='#6C7376'
      />
      <path
        d='M321.792 99.1157C321.829 99.9558 322.111 100.767 322.604 101.448C323.098 102.129 323.78 102.65 324.566 102.946C325.353 103.243 326.209 103.301 327.029 103.115C327.848 102.929 328.595 102.506 329.177 101.899C329.758 101.292 330.149 100.527 330.3 99.7003C330.451 98.8731 330.355 98.0197 330.026 97.2461C329.696 96.4725 329.147 95.8128 328.446 95.3489C327.745 94.8849 326.923 94.6372 326.083 94.6364C325.503 94.6332 324.928 94.7483 324.394 94.9747C323.859 95.2011 323.377 95.534 322.975 95.9531C322.574 96.3723 322.262 96.8689 322.058 97.4126C321.855 97.9564 321.765 98.5358 321.792 99.1157ZM334.477 107.514H330.187V105.648C329.579 106.387 328.807 106.975 327.933 107.363C327.059 107.752 326.106 107.932 325.15 107.888C324.042 107.857 322.951 107.604 321.942 107.143C320.934 106.682 320.028 106.022 319.28 105.204C318.532 104.385 317.955 103.425 317.586 102.379C317.216 101.333 317.061 100.223 317.129 99.1157C317.129 94.0765 320.487 90.3437 325.15 90.3437C326.103 90.3205 327.049 90.5094 327.92 90.8968C328.791 91.2841 329.566 91.8602 330.187 92.5834V90.717H334.477V107.514Z'
        fill='#6C7376'
      />
      <path
        d='M346.415 94.8238H341.192V107.515H336.715V88.8513C336.715 84.9319 338.767 82.5056 342.684 82.5056C344.486 82.3992 346.268 82.9275 347.721 83.9987L345.856 87.5449C344.986 87.1014 344.032 86.847 343.057 86.7983C341.752 86.7983 341.192 87.5449 341.192 89.038V91.091H346.415V94.8238Z'
        fill='#6C7376'
      />
      <path
        d='M350.893 99.1158C350.869 99.7104 350.969 100.303 351.186 100.858C351.402 101.412 351.731 101.915 352.152 102.336C352.572 102.757 353.075 103.086 353.629 103.302C354.183 103.519 354.776 103.619 355.37 103.595C355.96 103.603 356.546 103.492 357.092 103.27C357.639 103.047 358.135 102.717 358.553 102.3C358.97 101.882 359.299 101.386 359.522 100.839C359.744 100.292 359.855 99.7061 359.847 99.1158C359.847 97.9278 359.376 96.7885 358.536 95.9484C357.696 95.1084 356.558 94.6365 355.37 94.6365C354.183 94.6365 353.044 95.1084 352.204 95.9484C351.365 96.7885 350.893 97.9278 350.893 99.1158ZM364.324 99.1158C364.361 100.895 363.868 102.645 362.907 104.143C361.946 105.64 360.561 106.818 358.928 107.524C357.295 108.231 355.49 108.435 353.74 108.111C351.991 107.787 350.379 106.949 349.108 105.704C347.837 104.458 346.965 102.863 346.604 101.12C346.243 99.3773 346.409 97.5665 347.081 95.9186C347.753 94.2707 348.9 92.8604 350.377 91.8675C351.853 90.8746 353.591 90.3442 355.37 90.3438C356.54 90.3086 357.705 90.51 358.795 90.936C359.885 91.362 360.877 92.0037 361.713 92.8229C362.55 93.642 363.212 94.6216 363.66 95.7029C364.109 96.7842 364.335 97.9451 364.324 99.1158Z'
        fill='#6C7376'
      />
      <path
        d='M377.382 90.7177L376.636 95.1971C375.753 94.8148 374.8 94.6239 373.838 94.6371C373.349 94.61 372.86 94.6895 372.405 94.8702C371.95 95.0508 371.54 95.3283 371.203 95.6834C370.866 96.0385 370.61 96.4627 370.453 96.9266C370.296 97.3904 370.241 97.8829 370.293 98.3699V107.329H365.816V90.7177H370.107V92.5841C370.637 91.852 371.341 91.2638 372.155 90.8727C372.969 90.4817 373.868 90.3001 374.771 90.3444C375.648 90.4141 376.52 90.5388 377.382 90.7177Z'
        fill='#6C7376'
      />
      <path
        d='M381.673 97.6229H388.948C388.883 96.7214 388.484 95.8768 387.828 95.2551C387.172 94.6335 386.307 94.2797 385.404 94.2634C384.476 94.2588 383.579 94.6004 382.889 95.2216C382.2 95.8428 381.766 96.699 381.673 97.6229ZM393.425 99.1161V100.609H381.673C381.838 101.605 382.37 102.502 383.165 103.124C383.959 103.746 384.958 104.048 385.964 103.969C386.725 103.973 387.479 103.826 388.183 103.538C388.887 103.25 389.528 102.825 390.068 102.289L392.679 105.089C390.707 106.764 388.175 107.631 385.591 107.515C384.45 107.565 383.312 107.377 382.248 106.964C381.184 106.55 380.218 105.92 379.411 105.112C378.604 104.305 377.974 103.338 377.56 102.273C377.147 101.209 376.959 100.07 377.01 98.9294C376.919 97.7863 377.072 96.637 377.457 95.557C377.842 94.4769 378.45 93.4904 379.243 92.6622C380.036 91.8339 380.994 91.1826 382.056 90.751C383.118 90.3193 384.259 90.117 385.404 90.1574C390.254 90.344 393.425 93.8902 393.425 99.1161Z'
        fill='#6C7376'
      />
      <path
        d='M267.695 107.514H261.912L252.771 83.4375H257.995L264.71 102.661L271.612 83.4375H276.649L267.695 107.514Z'
        fill='#6C7376'
      />
      <path
        d='M258.377 56.5352C254.658 56.5352 251.051 56.0447 247.556 55.0637C244.106 54.038 241.328 52.7225 239.223 51.1172L242.919 42.9567C244.935 44.4283 247.332 45.61 250.11 46.5018C252.888 47.3937 255.666 47.8396 258.444 47.8396C261.536 47.8396 263.821 47.3937 265.299 46.5018C266.778 45.5654 267.517 44.3391 267.517 42.8229C267.517 41.7081 267.069 40.794 266.173 40.0805C265.322 39.3224 264.201 38.7204 262.812 38.2745C261.468 37.8285 259.631 37.338 257.301 36.8029C253.717 35.9556 250.782 35.1084 248.497 34.2611C246.212 33.4138 244.241 32.0538 242.583 30.1809C240.97 28.308 240.164 25.8108 240.164 22.6893C240.164 19.9691 240.903 17.5165 242.381 15.3315C243.86 13.1018 246.078 11.3404 249.035 10.0472C252.037 8.75402 255.688 8.10742 259.99 8.10742C262.992 8.10742 265.926 8.46416 268.794 9.17765C271.661 9.89113 274.94 11.6825 277.091 13.0203L272.961 20.4819C268.615 18.0293 264.269 16.803 259.923 16.803C256.876 16.803 254.613 17.2935 253.135 18.2746C251.701 19.2556 250.984 20.5488 250.984 22.1542C250.984 23.7595 251.813 24.9635 253.471 25.7662C255.173 26.5243 257.75 27.2823 261.2 28.0404C264.784 28.8877 267.719 29.7349 270.004 30.5822C272.289 31.4295 274.238 32.7673 275.851 34.5956C277.509 36.4239 278.337 38.8988 278.337 42.0203C278.337 44.6958 277.576 47.1484 276.052 49.3781C274.574 51.5631 272.334 53.3022 269.332 54.5954C266.33 55.8886 262.678 56.5352 258.377 56.5352Z'
        fill='#3D4345'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M127 56.1934H137.095L141.537 44.8871H161.727L166.168 56.1934H176.263L157.285 8.94922H146.382L127 56.1934ZM152.036 18.6411L144.768 36.4082H158.901L152.036 18.6411Z'
        fill='#3D4345'
      />
      <path
        d='M189.009 56.5352H179.617V8.10742H193.501L206.159 40.5294L218.818 8.10742H232.702V56.5352H223.31V20.83L211.059 52.0208H201.259L189.009 20.83V56.5352Z'
        fill='#3D4345'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M280.443 15.0051L286.097 20.6583C287.308 19.0431 291.023 15.8127 296.191 15.8127C302.652 15.8127 304.267 19.8507 304.267 21.8697C304.267 23.8887 302.571 28.3304 297.403 28.3304H290.538V36.0026H297.403C299.96 36.0026 305.075 37.3755 305.075 42.8671C305.075 48.3587 300.232 48.9241 297.403 48.9241C294.172 48.9241 288.923 48.1165 286.097 44.4823L280.443 50.5393C282.193 52.5582 288.035 56.5962 297.403 56.5962C306.771 56.5962 311.536 51.7506 312.747 49.3279C313.285 48.3857 314.362 45.936 314.362 43.6747C314.362 41.4134 313.555 39.2329 313.151 38.4254C312.612 37.214 310.567 34.3066 306.69 32.3684C308.978 30.8878 313.555 26.7152 313.555 21.8697C313.555 16.1804 310.324 12.8516 308.709 11.371C307.229 10.025 304.267 7.33301 296.191 7.33301C289.731 7.33301 283.001 12.4478 280.443 15.0051ZM335.764 8.1406H346.262L334.552 25.1001C340.205 25.1001 344.809 25.3423 348.685 29.5418C351.915 33.0414 353.531 36.4064 353.531 41.6557C353.531 45.2899 351.512 50.0547 347.877 52.962C344.647 55.5463 342.224 57 335.764 57C330.595 57 326.072 55.3848 324.053 53.7696C322.034 52.4236 317.593 48.1165 317.593 41.2519C317.593 34.3874 320.823 29.9456 323.246 26.7152C324.546 24.982 328.219 19.4586 331.513 14.5062C333.13 12.0746 334.655 9.78063 335.764 8.1406ZM335.358 48.5196C340.041 48.5196 343.837 45.0847 343.837 40.8475C343.837 36.6103 340.041 33.1754 335.358 33.1754C330.674 33.1754 326.878 36.6103 326.878 40.8475C326.878 45.0847 330.674 48.5196 335.358 48.5196Z'
        fill='#3D4345'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M377.756 7.33301C377.553 7.33301 377.351 7.33532 377.151 7.33989C376.951 7.33532 376.749 7.33301 376.546 7.33301C368.47 7.33301 364.506 11.4799 362.009 14.6013C358.779 18.6393 356.76 23.4765 356.76 33.176C356.76 41.2519 359.183 47.3089 362.817 50.9431C366.451 54.5772 370.489 56.5962 376.546 56.5962C376.749 56.5962 376.951 56.5943 377.151 56.5906C377.351 56.5943 377.552 56.5962 377.756 56.5962C383.813 56.5962 387.851 54.5772 391.485 50.9431C395.119 47.3089 397.542 41.2519 397.542 33.176C397.542 23.4765 395.523 18.6393 392.293 14.6013C389.796 11.4799 385.832 7.33301 377.756 7.33301ZM377.291 16.2169C377.176 16.2169 377.063 16.2185 376.95 16.2216C376.838 16.2185 376.724 16.2169 376.61 16.2169C372.132 16.2169 369.935 18.9362 368.55 20.983C366.759 23.6309 365.64 26.8028 365.64 33.1632C365.64 38.4589 366.983 42.4307 368.998 44.8137C371.013 47.1968 373.252 48.5207 376.61 48.5207C376.724 48.5207 376.838 48.5195 376.95 48.517C377.063 48.5195 377.176 48.5207 377.291 48.5207C380.649 48.5207 382.888 47.1968 384.902 44.8137C386.917 42.4307 388.261 38.4589 388.261 33.1632C388.261 26.8028 387.141 23.6309 385.35 20.983C383.966 18.9362 381.768 16.2169 377.291 16.2169Z'
        fill='#3D4345'
      />
    </svg>
  );
}

export default AMS360;
