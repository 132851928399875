import { CollapsibleSection, PropertyLabel } from '../../Core';
import { FieldSelectorWithModal } from '../../Modals';
import classNames from 'classnames';
import styles from '../styles.module.scss';
import { memo } from 'react';

export function CustomerAddressFieldMapping({
  stripeConfig = {},
  setStripeConfig = () => {},
  label,
  addressFieldTag,
  fieldTag,
  extraLevel
}: any) {
  let srcObject =
    stripeConfig.metadata.customer_field_mappings?.[addressFieldTag];
  if (extraLevel) srcObject = srcObject?.[extraLevel];
  return (
    <div className={classNames(styles.fieldMapping, styles.marginBottom)}>
      <PropertyLabel label={label} />
      <FieldSelectorWithModal
        className={classNames(styles.fieldSelectorButton, 'test-target')}
        selectId={srcObject?.[fieldTag]?.id}
        selectType={srcObject?.[fieldTag]?.type}
        placeholder='Select'
        onSelect={({ selectId, selectType }: any) => {
          const addressDictionary =
            stripeConfig.metadata.customer_field_mappings?.[addressFieldTag];
          const containerObject = extraLevel
            ? {
                ...addressDictionary?.[extraLevel]
              }
            : {
                ...addressDictionary
              };
          // support removing the mapping as well so delete any existing value first
          delete containerObject[fieldTag];
          // Now set the mapping if one is selected
          if (selectId)
            containerObject[fieldTag] = {
              id: selectId,
              type: selectType
            };

          const newAddressObject = extraLevel
            ? {
                ...addressDictionary,
                [extraLevel]: {
                  ...containerObject
                }
              }
            : {
                ...containerObject
              };

          setStripeConfig({
            ...stripeConfig,
            metadata: {
              ...stripeConfig.metadata,
              customer_field_mappings: {
                ...stripeConfig.metadata.customer_field_mappings,
                [addressFieldTag]: {
                  ...newAddressObject
                }
              }
            }
          });
        }}
      />
    </div>
  );
}

function AddressFieldSection({
  stripeConfig = {},
  setStripeConfig = () => {},
  title,
  collapsible = false,
  expanded = false,
  addressFieldTag,
  includeNamePhone = false,
  extraLevel
}: any) {
  return (
    <CollapsibleSection
      title={title}
      collapsible={collapsible}
      expanded={expanded}
    >
      <CustomerAddressFieldMapping
        stripeConfig={stripeConfig}
        setStripeConfig={setStripeConfig}
        label='Line 1 Field'
        addressFieldTag={addressFieldTag}
        fieldTag='line1'
        extraLevel={extraLevel}
      />
      <CustomerAddressFieldMapping
        stripeConfig={stripeConfig}
        setStripeConfig={setStripeConfig}
        label='Line 2 Field'
        addressFieldTag={addressFieldTag}
        fieldTag='line2'
        extraLevel={extraLevel}
      />
      <CustomerAddressFieldMapping
        stripeConfig={stripeConfig}
        setStripeConfig={setStripeConfig}
        label='City Field'
        addressFieldTag={addressFieldTag}
        fieldTag='city'
        extraLevel={extraLevel}
      />
      <CustomerAddressFieldMapping
        stripeConfig={stripeConfig}
        setStripeConfig={setStripeConfig}
        label='State/Province Field'
        addressFieldTag={addressFieldTag}
        fieldTag='state'
        extraLevel={extraLevel}
      />
      <CustomerAddressFieldMapping
        stripeConfig={stripeConfig}
        setStripeConfig={setStripeConfig}
        label='Country Field'
        addressFieldTag={addressFieldTag}
        fieldTag='country'
        extraLevel={extraLevel}
      />
      <CustomerAddressFieldMapping
        stripeConfig={stripeConfig}
        setStripeConfig={setStripeConfig}
        label='Postal Code Field'
        addressFieldTag={addressFieldTag}
        fieldTag='postal_code'
        extraLevel={extraLevel}
      />
      {includeNamePhone && (
        <>
          <CustomerAddressFieldMapping
            stripeConfig={stripeConfig}
            setStripeConfig={setStripeConfig}
            label='Name'
            addressFieldTag={addressFieldTag}
            fieldTag='name'
          />
          <CustomerAddressFieldMapping
            stripeConfig={stripeConfig}
            setStripeConfig={setStripeConfig}
            label='Phone'
            addressFieldTag={addressFieldTag}
            fieldTag='phone'
          />
        </>
      )}
    </CollapsibleSection>
  );
}

export default memo(AddressFieldSection);
