import { useMemo } from 'react';
import { useAppSelector } from './index';
import { deepEquals } from '../utils/core';

export const useFields = (inFormBuilder = false) => {
  const servars = useAppSelector(
    (state) =>
      inFormBuilder
        ? Object.values((state as any).formBuilder.servars)
        : (state as any).fields.servars ?? [],
    deepEquals
  );

  const hiddenFields = useAppSelector(
    (state) => (state as any).fields.hiddenFields ?? []
  );

  return useMemo(() => [...servars, ...hiddenFields], [servars, hiddenFields]);
};
