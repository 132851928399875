import { ignoreDrawControls } from './utils';
import React from 'react';

const OuterControl = (props: {
  direction?: string;
  children?: any;
  position?: string;
  distance?: number;
  offset?: { left: number; top: number };
  zIndex?: number;
  hoverBuffer?: boolean;
  secondaryControl?: boolean;
  flipped?: boolean;
}) => {
  const position = props.position || 'center';
  const distance = props.distance === undefined ? 8 : props.distance;
  const offset = props.offset || { left: 0, top: 0 };
  const zIndex = props.zIndex ?? undefined;

  if (props.direction === 'left') {
    return (
      <div
        className={ignoreDrawControls}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          position: 'absolute',
          top: offset.top,
          left: offset.left,
          zIndex
        }}
      >
        <div
          className={ignoreDrawControls}
          style={{
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'center',
            height: '100%',
            position: 'absolute',
            paddingRight: 8,
            top: 0,
            right: 0,
            width: 0,
            overflow: 'visible',
            pointerEvents: 'none'
          }}
        >
          {props.children}
        </div>
      </div>
    );
  }

  return (
    <div
      className={ignoreDrawControls}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        position: 'absolute',
        top: offset.top,
        left: offset.left,
        zIndex
      }}
    >
      <div
        className={ignoreDrawControls}
        style={{
          display: 'flex',
          justifyContent: position,
          alignItems: 'end',
          width: '100%',
          position: 'absolute',
          paddingBottom: distance,
          height: 0,
          bottom: props.flipped ? -18 : 0,
          left: 0,
          pointerEvents: 'none'
        }}
      >
        {props.children}
      </div>
    </div>
  );
};

export default React.memo(OuterControl);
