import { Viewport, ViewportType } from './viewports';
import { Grid } from './Grid';
import { DataMapper } from './mappers';
import { Cell } from '.';

type GigOptions = {
  viewport?: ViewportType;
  setViewports?: boolean;
  theme?: any;
};

export class Gig {
  #raw;
  #viewport;
  #viewports;
  #grid: Grid;
  #theme: any;

  constructor(
    rawStep: any,
    { viewport = Viewport.Desktop, setViewports = true, theme }: GigOptions = {}
  ) {
    const _viewport =
      viewport === Viewport.Unconstrained || !viewport
        ? Viewport.Desktop
        : viewport;

    this.#raw = JSON.parse(JSON.stringify(rawStep));
    this.#viewport = new Viewport(_viewport);
    this.#viewports = setViewports ? Viewport.Viewports : [_viewport];
    this.#grid = DataMapper.toGrid(this, this.#viewport);
    this.#theme = theme;
  }

  /**
   * Converts the current grid structure to raw data structure that can be sent to the API
   * @returns {any} Raw step data
   */
  toStep() {
    return DataMapper.toStep(this.#grid);
  }

  /**
   * Retrieve a cell from the grid by the node's position or uuid
   * @param {(string | number[])} uuidOrPositionId - Node UUID or position
   * @returns {(Cell | undefined)} A cell if found or undefined
   */
  get(uuidOrPositionId: string | number[]): Cell | undefined {
    return this.#grid.get(uuidOrPositionId);
  }

  /**
   * Set the active viewport of GIG
   * @param {ViewportType} viewport - The viewport to set the active viewport to
   */
  setViewport(viewport: ViewportType) {
    const _viewport =
      viewport !== Viewport.Unconstrained ? viewport : Viewport.Desktop;

    if (this.#viewports.includes(_viewport)) {
      this.#viewport.setActiveViewport(_viewport);
    }
  }

  /**
   * @returns {boolean} Whether the current active viewport of GIG is desktop
   */
  isDesktopViewport(): boolean {
    return this.#viewport.isDesktop();
  }

  /**
   * @returns {boolean} Whether the current active viewport of GIG is mobile
   */
  isMobileViewport(): boolean {
    return this.#viewport.isMobile();
  }

  /**
   * Remove redundant cells (determined by Cell.isRedundant()) from the grid
   * Note: Redundant cells are explained more in depth within the comments of the Cell class.
   */
  removeRedundantCells(): void {
    this.#grid.children.forEach((cell: Cell) => {
      if (!cell.isRedundant()) return; // Continue if the cell is not redundant

      if (cell.isRoot()) {
        // If the cell is redundant and is root, consume its child
        // Note: Due to Cell.isRedundant(), it's safe to assume there is only one child in this case
        cell.consume((cell.getFirstChild() as Cell).copy({ unique: true }));
      } else if (cell.weight > 1) {
        // If the cell is not root and has more than one child, remove just the cell
        cell.remove({ parentOnly: true });
      } else {
        // Remove the cell
        cell.remove();
      }
    });
  }

  get raw() {
    return this.#raw;
  }

  get viewportName() {
    return this.#viewport.activeViewport;
  }

  get step() {
    return this.#grid.step;
  }

  get viewport() {
    return this.#grid.raw;
  }

  get viewportInstance() {
    return this.#viewport;
  }

  get grid() {
    return this.#grid;
  }

  get theme() {
    return this.#theme;
  }

  get hash() {
    return JSON.stringify(this.viewport);
  }
}
