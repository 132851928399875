import React, { forwardRef, memo, Ref, useLayoutEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { ignoreDrawControls } from '../../RenderingEngine/Controls/utils';
import testIds from '../../../utils/testIds';

export type AnchorType =
  | 'top_left'
  | 'top_right'
  | 'bottom_left'
  | 'bottom_right';
type ElevatedMenuProps = React.PropsWithChildren<{
  className?: string;
  styles?: any;
  show?: boolean;
  position: {
    x: number;
    y: number;
  };
  anchor?: AnchorType;
  bestFit?: boolean;
  relativeParent?: HTMLElement;
  // Element that is the target of the menu.
  // If provided and best fit, the menu will be positioned to not overlap this element.
  target?: HTMLElement | null;
}>;

function ElevatedMenu(
  {
    className = '',
    styles: customStyles = {},
    show,
    position,
    anchor = 'top_left',
    bestFit = false,
    children,
    relativeParent,
    target
  }: ElevatedMenuProps,
  ref: Ref<HTMLDivElement>
) {
  const fullStyle = {
    ...customStyles,
    top: ['top_left', 'top_right'].includes(anchor) ? position.y : undefined,
    left: ['top_left', 'bottom_left'].includes(anchor) ? position.x : undefined,
    bottom: ['bottom_left', 'bottom_right'].includes(anchor)
      ? window.innerHeight - position.y
      : undefined,
    right: ['top_right', 'bottom_right'].includes(anchor)
      ? window.innerWidth - position.x
      : undefined
  };
  if (position.x === undefined && position.y === undefined) {
    fullStyle.display = 'none';
  }

  const containerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (bestFit && containerRef && containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();

      let targetWidth = 0,
        targetHeight = 0;
      let targetX = position.x;
      let targetY = position.y;
      if (target) {
        const targetRect = target.getBoundingClientRect();
        targetWidth = targetRect.width;
        targetHeight = targetRect.height;
        targetX = targetRect.x;
        targetY = targetRect.y;
      }

      // For now just checking that it fits vertically and horizontally and only that it is not cut-off on the bottom/right.
      if (rect.right > window.innerWidth) {
        containerRef.current.style.right = '';
        const adjust = targetX < position.x ? targetWidth : 0;
        containerRef.current.style.left = `${
          position.x - adjust - rect.width
        }px`;
      }
      if (rect.bottom > window.innerHeight) {
        containerRef.current.style.bottom = '';
        const adjust = targetY < position.y ? targetHeight : 0;
        containerRef.current.style.top = `${
          position.y - adjust - rect.height
        }px`;
      }
    }
  }, [position, bestFit, containerRef, target]);

  const rootEl = relativeParent ?? document.body;

  return ReactDOM.createPortal(
    show ? (
      <div id='elevated-menu-container' ref={ref}>
        <div
          data-testid={testIds.elevatedMenu}
          ref={containerRef}
          className={classNames(
            ignoreDrawControls,
            className,
            styles.elevatedMenu
          )}
          style={fullStyle}
        >
          {children}
        </div>
      </div>
    ) : null,
    rootEl
  );
}

export default memo(
  forwardRef<HTMLDivElement, ElevatedMenuProps>(ElevatedMenu as any)
);
