export default function ButtonGroupIcon() {
  return (
    <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect
        x='4'
        y='7'
        width='40'
        height='13.6585'
        rx='3'
        stroke='#414859'
        strokeWidth='3'
        strokeLinecap='round'
      />
      <rect
        x='4'
        y='26.5122'
        width='40'
        height='13.6585'
        rx='3'
        stroke='#414859'
        strokeWidth='3'
        strokeLinecap='round'
      />
    </svg>
  );
}
