import { ComponentProps, memo } from 'react';
import { OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { DRAFT_STATUS, PUBLISH_STATUS } from '../../../../redux/utils';
import { CheckIcon, PublishRocket } from '../../../Icons';
import classNames from 'classnames';
import { Button } from '../button';

interface PublishButtonProps extends ComponentProps<typeof Button> {
  status: string;
  draftStatus?: string;
  onPublish?: any;
  partial?: boolean;
  saveFlag?: boolean;
}
function PublishButton({
  status,
  disabled: notAllowed = false,
  onPublish = () => {},
  partial = false,
  saveFlag = false,
  draftStatus = DRAFT_STATUS.OPERATIONAL
}: PublishButtonProps) {
  let content;
  const needsRefresh = [
    DRAFT_STATUS.CONFLICT,
    DRAFT_STATUS.CONFLICT_IGNORE,
    DRAFT_STATUS.ERROR
  ].includes(draftStatus);
  if (needsRefresh) content = 'Refresh';
  else {
    if (status === PUBLISH_STATUS.FULFILLED) {
      content = (
        <>
          <CheckIcon
            className='tw-w-4 tw-h-4 tw-mt-0.5'
            color={'var(--prim-100)'}
            stroke='1'
          />
          {saveFlag ? 'Saved' : 'Published'}
        </>
      );
    } else {
      let message = 'Publish';
      if (saveFlag) message = 'Save';
      else if (status === PUBLISH_STATUS.AUTO_SAVING) message = 'Saving';
      const spinner = (
        <Spinner
          animation='border'
          role='status'
          style={{ width: '16px', height: '16px' }}
        />
      );
      const saving =
        status === PUBLISH_STATUS.LOADING ||
        status === PUBLISH_STATUS.AUTO_SAVING;
      if (saveFlag) {
        content = saving ? spinner : message;
      } else {
        content = (
          <>
            {saving ? (
              spinner
            ) : (
              <PublishRocket className='tw-fill-current tw-w-7 tw-h-7' />
            )}
            {message}
          </>
        );
      }
    }
  }

  const disconnected = DRAFT_STATUS.NOT_CONNECTED === draftStatus;
  const isDisabled =
    ([
      PUBLISH_STATUS.FULFILLED,
      PUBLISH_STATUS.AUTO_SAVING,
      PUBLISH_STATUS.LOADING
    ].includes(status) &&
      !needsRefresh) ||
    notAllowed ||
    disconnected;

  return (
    <OverlayTrigger
      placement='bottom'
      delay={{
        show: 500,
        hide: 0
      }}
      overlay={
        notAllowed ? (
          <Tooltip>Your account cannot publish forms.</Tooltip>
        ) : (
          <></>
        )
      }
    >
      <Button
        className={classNames(
          'disabled:tw-bg-primary tw-px-3 tw-w-[100px]',
          (isDisabled || disconnected) &&
            'tw-bg-white disabled:tw-bg-white !tw-text-primary',
          (notAllowed || disconnected) && 'tw-text-primary/50',
          partial && 'tw-rounded-r-none'
        )}
        onClick={onPublish}
        disabled={isDisabled}
      >
        {content}
      </Button>
    </OverlayTrigger>
  );
}

export default memo(PublishButton);
