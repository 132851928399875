import { forwardRef, memo, Ref, useState } from 'react';

import { ActiveFilterState, FilterWidget } from './index';
import styles from './styles.module.scss';
import ElevatedMenu, { AnchorType } from '../Core/ElevatedMenu';
import { CloseIcon } from '../Icons';
import classNames from 'classnames';
import { Button } from '../Core/Button/button';

type FilterOverlayProps = React.PropsWithChildren<{
  show: boolean;
  close: () => void;
  position?: {
    x: number;
    y: number;
  };
  SelectedFilter: FilterWidget;
  activeFilterState: ActiveFilterState;
  setActiveFilterState: (state: ActiveFilterState) => void;
  onSave: () => void;
  onDelete: () => void;
  newFilter: boolean;
  filterName: string;
  anchor?: AnchorType;
  className?: string;
}>;

function FilterOverlay(
  {
    show,
    close,
    position,
    SelectedFilter,
    activeFilterState,
    setActiveFilterState,
    onSave,
    onDelete,
    newFilter,
    filterName,
    anchor = 'top_left',
    className,
    children
  }: FilterOverlayProps,
  ref: Ref<HTMLDivElement>
) {
  const [activeFilterError, setActiveFilterError] = useState<string>('');
  return (
    <ElevatedMenu
      ref={ref}
      className={classNames(styles.filterDialog, className)}
      position={position as { x: number; y: number }}
      anchor={anchor}
      bestFit
      show={show}
    >
      <div className={styles.filterOverlay}>
        <div className={styles.filterDialogClose} tabIndex={0} onClick={close}>
          <CloseIcon />
        </div>
        <div className={styles.filterContent}>
          <>
            <div className={styles.filterTitle}>{filterName}</div>

            <SelectedFilter
              paramName={activeFilterState.paramName}
              paramValue={activeFilterState.paramValue}
              onChange={(
                id: string,
                paramName: string,
                paramValue: string,
                displayState: string,
                error = ''
              ) =>
                setActiveFilterState({
                  id,
                  filterId: activeFilterState.filterId ?? id,
                  paramName,
                  paramValue,
                  displayState,
                  error
                })
              }
            />
            <div className={styles.filterDialogButtons} tabIndex={0}>
              {!newFilter && (
                <Button
                  variant='outline'
                  onClick={() => {
                    onDelete();
                    close();
                  }}
                >
                  Clear
                </Button>
              )}

              <Button
                variant='dark'
                onClick={() => {
                  // validate and save the filter
                  if (!activeFilterState) return;
                  const { error } = activeFilterState;
                  setActiveFilterError('');
                  if (error) {
                    // don't display error until the user tries to save the filter
                    setActiveFilterError(error);
                    return;
                  }
                  onSave();
                  close();
                }}
              >
                {newFilter ? 'Add' : 'Update'}
              </Button>
            </div>
            {activeFilterError && (
              <div className={styles.filterError}>{activeFilterError}</div>
            )}
          </>
        </div>
      </div>
    </ElevatedMenu>
  );
}

export default memo(forwardRef(FilterOverlay));
