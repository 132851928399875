import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DropdownField } from '../../../Core';
import { mapRawAPIConnector } from '../utils';
import useFeatheryRedux from '../../../../redux';
import styles from './styles.module.scss';

type APIConnectorInputProps = {
  apiConnector?: string;
  onChange?: (value: string) => void;
  filter?: (apiConnector: any) => boolean;
};

export const APIConnectorInput = ({
  apiConnector,
  onChange = () => {},
  filter = () => true
}: APIConnectorInputProps) => {
  const { getCustomAPIs } = useFeatheryRedux();
  const { formId } = useParams<{ formId: string }>();

  const [apiConnectors, setAPIConnectors] = useState<any[]>([]);
  const apiConnectorOptions = useMemo(() => {
    return apiConnectors.filter(filter).map((apiConnector) => ({
      display: apiConnector.name || apiConnector.url || apiConnector.base_url,
      value: apiConnector.id
    }));
  }, [apiConnectors]);

  useEffect(() => {
    getCustomAPIs({
      panelId: formId
    }).then((res: any) => {
      if (Array.isArray(res)) {
        setAPIConnectors(res.map(mapRawAPIConnector));
      }
    });
  }, [formId]);

  return (
    <DropdownField
      className={styles.apiConnectorInput}
      onChange={(event: any) => onChange(event.target.value)}
      selected={apiConnector}
      options={apiConnectorOptions}
      placeholder='Select'
    />
  );
};
