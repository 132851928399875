import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const getUsers = createAsyncThunk(
  'users/getUsers',
  async ({ token, ...data }: { token: string; panel?: string }) => {
    const response = await FeatheryAPI.getUsers(token, data);
    if (response.status === 200) {
      return await response.json();
    } else {
      throw new Error('Could not get users');
    }
  }
);

const deleteUser = createAsyncThunk(
  'users/deleteUser',
  async ({ token, userId }: { token: string; userId: string }) => {
    const response = await FeatheryAPI.deleteUser(token, { userId });

    if (response.status !== 204) {
      throw new Error('Could not delete user');
    }
  }
);

const getUserDetail = createAsyncThunk(
  'users/getUserDetail',
  async ({
    token,
    userId,
    panel
  }: {
    token: string;
    userId: string;
    panel: any;
  }) => {
    const response = await FeatheryAPI.getUserDetail(token, { userId, panel });

    if (response.status === 200) {
      return await response.json();
    } else {
      throw new Error('Could not get data for user');
    }
  }
);

const usersSlice = createSlice({
  name: 'users',
  initialState: {
    users: [],
    count: 0,
    prevUrl: '',
    nextUrl: '',
    panelId: '',
    detailsLoaded: false
  },
  reducers: {
    clearUsers(state) {
      state.users = [];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getUsers.pending, (state) => {
      state.detailsLoaded = false;
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      // Don't let previous request clear out current data
      if (state.detailsLoaded) return;

      const {
        meta: { arg },
        payload
      } = action;
      state.panelId = arg.panel ?? '';
      state.users = payload.results;
      state.count = payload.count;
      state.prevUrl = payload.previous;
      state.nextUrl = payload.next;
    });
    builder.addCase(deleteUser.fulfilled, (state: any, action: any) => {
      state.users = state.users.filter(
        (user: any) => user.id !== action.meta.arg.userId
      );
    });
    builder.addCase(getUserDetail.fulfilled, (state: any, action: any) => {
      const {
        meta: { arg },
        payload
      } = action;
      state.panelId = arg.panel;
      const index = state.users.findIndex(
        (user: any) => user.id === arg.userId
      );
      if (index >= 0) state.users[index] = payload;
      else state.users.push(payload);
      state.detailsLoaded = true;
    });
  }
});

const syncFunctions = usersSlice.actions;

export default usersSlice.reducer;
export { getUsers, deleteUser, getUserDetail, syncFunctions };
