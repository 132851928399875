export default function TextCenter({ width = 24, height = 24 }) {
  return (
    <svg
      width={width}
      height={height}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4 5a1 1 0 0 0 0 2V5Zm16 2a1 1 0 1 0 0-2v2ZM4 7h16V5H4v2ZM6.664 17a1 1 0 1 0 0 2v-2Zm10.667 2a1 1 0 1 0 0-2v2ZM6.664 19h10.667v-2H6.664v2ZM8.664 11a1 1 0 1 0 0 2v-2Zm6.667 2a1 1 0 1 0 0-2v2Zm-6.667 0h6.667v-2H8.664v2Z'
        fill='#414859'
      />
    </svg>
  );
}
