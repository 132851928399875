function Stytch({ width = 144, height = 72 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 607 223'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M243.414 123.819V158.323H263.251V123.819C269.057 114.675 292.517 72.5445 294.7 68.1798H271.632C268.031 74.5632 243.414 123.819 243.414 123.819ZM248.564 94.3574L237.652 114.119C232.273 104.735 216.92 77.8368 211.998 68.1143H235.132L248.564 94.3574Z'
        fill='#19303D'
      />
      <path
        d='M84.6787 142.501C87.0261 142.547 89.3706 142.313 91.6623 141.802C93.2248 141.436 94.7051 140.781 96.027 139.871C97.0652 139.16 97.8701 138.158 98.3403 136.99C98.8051 135.79 99.042 134.513 99.0387 133.226C99.0224 131.77 98.6375 130.342 97.9201 129.075C97.2026 127.808 96.176 126.743 94.9358 125.98C92.2006 124.089 87.4976 122.034 80.8268 119.815C77.917 118.789 75.0071 117.611 72.0973 116.28C69.2569 115.003 66.6177 113.319 64.2626 111.282C61.9306 109.232 60.0184 106.749 58.632 103.971C57.0932 100.71 56.3454 97.1324 56.4497 93.5282C56.388 89.708 57.1744 85.9216 58.7521 82.4418C60.2739 79.1838 62.5119 76.3116 65.2992 74.0396C68.3438 71.6175 71.8316 69.8125 75.5673 68.7255C79.98 67.4247 84.5634 66.7957 89.1635 66.8596C94.4004 66.7645 99.6244 67.4111 104.68 68.7801C108.407 69.8161 112.016 71.2349 115.45 73.0139L109.678 88.7816C107.018 87.4263 104.252 86.2913 101.407 85.388C97.8669 84.3376 94.1862 83.8407 90.4947 83.9149C85.7008 83.9149 82.2599 84.5769 80.1721 85.9008C79.1479 86.5381 78.3152 87.4404 77.7619 88.5123C77.2087 89.5842 76.9556 90.7857 77.0295 91.9897C76.9589 93.4507 77.3482 94.897 78.1425 96.1253C79.0172 97.3416 80.1311 98.3665 81.416 99.1369C82.9573 100.104 84.5869 100.922 86.2827 101.581C88.1232 102.309 90.1564 103.054 92.3825 103.818C97.0018 105.528 101.021 107.215 104.44 108.881C107.567 110.342 110.438 112.299 112.94 114.675C115.192 116.87 116.925 119.539 118.014 122.488C119.206 126 119.771 129.693 119.684 133.4C119.684 141.693 116.774 148.124 110.954 152.692C105.135 157.261 96.3762 159.549 84.6787 159.556C81.1198 159.58 77.5639 159.343 74.0396 158.847C71.205 158.447 68.3987 157.867 65.6375 157.112C63.4948 156.529 61.3948 155.8 59.3522 154.929C57.6063 154.155 56.0787 153.434 54.9329 152.747L60.5744 136.848C63.7026 138.493 66.994 139.805 70.395 140.766C75.0508 142.014 79.8595 142.598 84.6787 142.501V142.501Z'
        fill='#19303D'
      />
      <path
        d='M201.709 68.1686V85.7477H174.756V158.399H154.831V85.7477H126.711V68.1686H201.709Z'
        fill='#19303D'
      />
      <path
        d='M380.522 68.1686V85.7477H353.624V158.399H333.644V85.7477H305.524V68.1686H380.522Z'
        fill='#19303D'
      />
      <path
        d='M431.709 159.621C417.72 159.621 407.066 155.62 399.748 147.618C392.43 139.616 388.767 128.217 388.76 113.42C388.647 106.722 389.783 100.061 392.11 93.779C394.145 88.34 397.272 83.375 401.298 79.1898C405.216 75.2044 409.962 72.1276 415.199 70.1766C420.782 68.0921 426.7 67.0491 432.658 67.0995C435.987 67.0762 439.31 67.3537 442.588 67.9288C445.233 68.4097 447.845 69.0511 450.412 69.8493C452.343 70.4537 454.222 71.2127 456.032 72.1189C457.527 72.8828 458.596 73.4829 459.305 73.9085L453.718 90.0253C450.778 88.4638 447.68 87.2201 444.476 86.3153C440.568 85.2363 436.527 84.7183 432.473 84.7767C429.496 84.7805 426.542 85.3011 423.743 86.3153C420.875 87.3576 418.284 89.0451 416.171 91.2474C413.823 93.7573 412.028 96.731 410.9 99.9769C409.475 104.191 408.803 108.624 408.914 113.071C408.876 116.916 409.297 120.751 410.169 124.496C410.933 127.802 412.371 130.915 414.392 133.64C416.406 136.257 419.028 138.343 422.03 139.718C425.621 141.304 429.52 142.068 433.444 141.955C435.978 141.973 438.509 141.801 441.017 141.442C443.025 141.153 445.015 140.745 446.975 140.22C448.543 139.824 450.078 139.309 451.569 138.681C452.893 138.078 454.093 137.521 455.17 137.012L460.56 152.965C456.947 155.033 453.06 156.583 449.015 157.57C443.364 159.023 437.544 159.713 431.709 159.621Z'
        fill='#19303D'
      />
      <path
        d='M529.534 68.2997H550.627V158.563H529.534V119.88H495.544V158.563H475.608V68.2997H495.544V102.29H529.534V68.2997Z'
        fill='#19303D'
      />
    </svg>
  );
}

export default Stytch;
