import {
  distance,
  distanceToLine,
  drawBox,
  drawLine,
  getBoundingBoxSides,
  getCenters,
  isHoveringOverClass,
  isPointInBoundingBox,
  keepIds,
  point,
  ignoreDrawControls,
  sideControlClass,
  labelControlClass,
  textEditControlClass
} from './index';
import {
  ControlLayerDetails,
  controlLayerId,
  ControlReference,
  ControlReferenceMap,
  ControlState,
  SideControlReference
} from '../ControlLayer';
import { CanvasElement } from '../CanvasRefs';
import { editorCanvasContainerId } from '../../RenderedStepContainer';

export const lockControlLayerWhenNotOverSide = (
  controlState: ControlState,
  nextControlState: ControlState
): ControlState => {
  nextControlState = {
    ...controlState,
    hoverState: nextControlState.hoverState
  };
  if (
    controlState.insideControl &&
    controlState.selectedControl &&
    controlState.insideControl.id === controlState.selectedControl.id
  ) {
    nextControlState.insideControl = controlState.selectedControl;
  }
  const ids = [
    controlState?.selectedControl?.id,
    controlState.insideControl?.id
  ];
  if (!controlState.hoverState.label) {
    if (controlState?.sideControl?.id) {
      ids.push(controlState?.sideControl?.id);
    }
  } else {
    nextControlState.sideControl = undefined;
  }
  keepIds(ids, controlLayerId);
  return nextControlState;
};

export const lockControlLayer = (controlState: ControlState): ControlState => {
  const nextControlState = { ...controlState };
  if (
    controlState.insideControl &&
    controlState.selectedControl &&
    controlState.insideControl.id === controlState.selectedControl.id
  ) {
    nextControlState.insideControl = controlState.selectedControl;
  }
  keepIds(
    [
      controlState?.sideControl?.id,
      controlState?.insideControl?.id,
      controlState?.selectedControl?.id
    ],
    controlLayerId
  );
  return nextControlState;
};

export const setHoverState = (
  nextControlState: ControlState,
  clientCursor: point
) => {
  nextControlState.hoverState.side = isHoveringOverClass(
    clientCursor,
    sideControlClass
  );
  nextControlState.hoverState.label = isHoveringOverClass(
    clientCursor,
    labelControlClass
  );
  nextControlState.hoverState.textEdit = isHoveringOverClass(
    clientCursor,
    textEditControlClass
  );
  nextControlState.hoverState.ignoreDrawControls = isHoveringOverClass(
    clientCursor,
    ignoreDrawControls
  );
};

export const getControlsFromCanvasElements = (
  divs: CanvasElement[],
  {
    cursor,
    isDragging,
    selectedNode,
    gigPositionRef,
    scrollOffset,
    isOverRestrictedClassAndNotEditingText
  }: {
    cursor: point | null | undefined;
    isDragging: boolean | undefined;
    selectedNode: any;
    gigPositionRef: any;
    scrollOffset: any;
    isOverRestrictedClassAndNotEditingText: boolean | undefined;
  }
): {
  closeSides: SideControlReference[];
  insideDiv: CanvasElement | null;
  selectedDiv: CanvasElement | null;
} => {
  const closeSides: SideControlReference[] = [];
  let insideDiv: CanvasElement | null = null;
  let selectedDiv: CanvasElement | null = null;

  let insideKey: string | undefined = undefined;

  divs.forEach((div: CanvasElement) => {
    // We need to ignore rendering some controls when dragging
    let ignoreDragging = false;
    if (isDragging && ControlLayerDetails.draggingNode) {
      const draggingNode = ControlLayerDetails.draggingNode;
      if (draggingNode.position.join(',') === div.node.position.join(','))
        ignoreDragging = true;
      if (draggingNode.hasChildren()) {
        if (div.node.isDescendent(draggingNode)) {
          ignoreDragging = true;
        }
      }
    }

    const boxId = `box:${div.key}`;

    const isSelectedControl =
      (div.node.position.join(',') === selectedNode?.position.join(',') &&
        Array.isArray(gigPositionRef.current) &&
        gigPositionRef.current.length > 0) ||
      gigPositionRef.current === 'root';

    const isCursorInsideControl = isPointInBoundingBox(
      div.rect,
      cursor as point,
      scrollOffset
    );

    if (isSelectedControl) {
      selectedDiv = div;
    }

    if (isSelectedControl || isCursorInsideControl) {
      if (isCursorInsideControl && !isOverRestrictedClassAndNotEditingText) {
        if (insideKey === undefined || boxId.length > insideKey.length) {
          insideKey = boxId;
          insideDiv = div;
        }
      }
    }

    if (ignoreDragging) {
      return;
    }

    // Get each side of the div
    const sides: { [key: string]: point[] } = getBoundingBoxSides(
      div.rect,
      scrollOffset
    );
    Object.keys(sides).forEach((side: string) => {
      const sidePoints = sides[side] as [point, point];
      const distanceToSide = distanceToLine(
        cursor as point,
        sidePoints[0],
        sidePoints[1],
        scrollOffset
      );
      closeSides.push({
        id: `${side}:${div.key}`,
        side,
        start: sidePoints[0],
        end: sidePoints[1],
        distance: distanceToSide,
        canvasReference: div
      });
    });
  });

  return {
    closeSides,
    insideDiv,
    selectedDiv
  };
};

export const renderContainerControl = (
  canvasElement: CanvasElement,
  {
    scrollOffset,
    color,
    hideControlLayer
  }: {
    scrollOffset: point | undefined;
    color: string;
    hideControlLayer: boolean;
  }
): ControlReference | null => {
  const id = `box:${canvasElement.key}`;

  if (canvasElement.key === '' && hideControlLayer) color = 'transparent';

  const el = drawBox(
    id,
    controlLayerId,
    canvasElement.rect,
    scrollOffset,
    1,
    color
  );

  if (el) return { id, el, canvasReference: canvasElement };

  return null;
};

export const renderSideControl = (
  lineControl: SideControlReference,
  { scrollOffset, color }: { scrollOffset: point | undefined; color: string }
): SideControlReference | null => {
  const el = drawLine(
    lineControl.id,
    controlLayerId,
    lineControl.start,
    lineControl.end,
    scrollOffset,
    color,
    1,
    ignoreDrawControls
  );

  if (el) return { ...lineControl, el };

  return null;
};

export const getClosestSide = (
  sides: SideControlReference[],
  nextControlState: ControlState,
  maxDistance: number
) => {
  sides = sides
    .filter((s) => s.distance < maxDistance)
    .sort((a, b) => {
      const nextSelectedNode =
        nextControlState.selectedControl?.canvasReference?.node;
      const nextInsideNode =
        nextControlState.insideControl?.canvasReference?.node;

      let aIsInside = false;
      let aIsSelected = false;
      let bIsInside = false;
      let bIsSelected = false;

      if (a?.canvasReference?.node) {
        if (nextSelectedNode) {
          aIsSelected = nextSelectedNode.isEqual(a.canvasReference.node);
        }
        if (nextInsideNode) {
          aIsInside = nextInsideNode.isEqual(a.canvasReference.node);
        }
      }

      if (b?.canvasReference?.node) {
        if (nextSelectedNode) {
          bIsSelected = nextSelectedNode.isEqual(b.canvasReference.node);
        }
        if (nextInsideNode) {
          bIsInside = nextInsideNode.isEqual(b.canvasReference.node);
        }
      }

      return (
        a.distance - b.distance ||
        Number(bIsSelected) - Number(aIsSelected) ||
        Number(bIsInside) - Number(aIsInside)
      );
    });

  return sides[0];
};

export const getTextSpanFromControl = (
  controlReference: ControlReference
): HTMLElement | null => {
  return document.getElementById(
    `span-${controlReference.canvasReference.node.id}`
  );
};

export const disableTextEditControl = (
  textEditSpan: HTMLElement,
  resizeObserver: ResizeObserver
) => {
  textEditSpan.style.zIndex = '';
  textEditSpan.style.position = '';
  textEditSpan.classList.remove(textEditControlClass);
  resizeObserver.unobserve(textEditSpan);
};

export const enableTextEditControl = (
  textEditSpan: HTMLElement,
  resizeObserver: ResizeObserver
) => {
  textEditSpan.style.zIndex = '99';
  textEditSpan.style.position = 'relative';
  textEditSpan.classList.add(textEditControlClass);
  resizeObserver.observe(textEditSpan);
};

export const styleControls = (
  controlState: ControlState,
  {
    isDragging,
    primaryColor,
    secondaryColor
  }: { isDragging: boolean; primaryColor: string; secondaryColor: string }
) => {
  const hoveredControl = controlState.insideControl;
  const selectedControl = controlState.selectedControl;

  const hoveredControlIsSelectedControl =
    hoveredControl &&
    selectedControl &&
    hoveredControl.id === selectedControl.id;

  if (isDragging && controlState.sideControl) {
    if (hoveredControl) {
      hoveredControl.el.style.borderColor = secondaryColor;
    }
    if (selectedControl) {
      selectedControl.el.style.borderColor = secondaryColor;
    }
  }

  if (hoveredControl?.id === 'box') {
    if (!hoveredControlIsSelectedControl) {
      hoveredControl.el.style.borderColor = primaryColor;
      hoveredControl.el.style.borderStyle = 'dashed';
    }
  }

  if (isDragging && selectedControl && !hoveredControlIsSelectedControl) {
    selectedControl.el.style.borderColor = secondaryColor;
  }

  if (hoveredControl) {
    if (!hoveredControlIsSelectedControl) {
      hoveredControl.el.style.borderColor = primaryColor;
      hoveredControl.el.style.borderStyle = isDragging ? 'solid' : 'dashed';
    }
  }
};

export const recycleControlLayerDivs = (controlState: ControlState) => {
  keepIds(
    [
      controlState?.sideControl?.id,
      controlState?.insideControl?.id,
      controlState?.selectedControl?.id
    ],
    controlLayerId
  );
};

export const handleTextControl = (
  nextControlState: ControlState,
  currentControlState: ControlState,
  {
    resizeObserver,
    isDragging
  }: { resizeObserver: ResizeObserver; isDragging: boolean }
) => {
  // Disable any textEdit controls if they're no longer selected
  if (currentControlState.textEditControl) {
    const deselectedTextEdit =
      !nextControlState.textEditControl && currentControlState.textEditControl;

    const selectedAnotherContainer =
      currentControlState.textEditControl &&
      nextControlState.selectedControl?.id !==
        currentControlState.selectedControl?.id;

    if (deselectedTextEdit || selectedAnotherContainer || isDragging)
      disableTextEditControl(
        currentControlState.textEditControl.el,
        resizeObserver
      );
  }

  // Enable text editing
  if (
    nextControlState.insideControl &&
    nextControlState.selectedControl &&
    nextControlState.selectedControl.id === nextControlState.insideControl.id &&
    !isDragging
  ) {
    const textElement = getTextSpanFromControl(nextControlState.insideControl);
    if (textElement) {
      nextControlState.textEditControl = {
        id: textElement.id,
        el: textElement,
        controlReference: nextControlState.insideControl
      };
      enableTextEditControl(
        nextControlState.textEditControl.el,
        resizeObserver
      );
    }
  }
};

const doesControlOverflowCanvas = (control: ControlReference) => {
  // Get the control rect
  const canvasElement = control.canvasReference.rect;

  // Get top-left corner point
  const topLeftPoint = {
    x: canvasElement.left,
    y: canvasElement.top + -18
  };

  const isTopLeftPoint = isPointInCanvas(topLeftPoint);
  return !isTopLeftPoint;
};

export const setControlOverflow = (controlState: ControlState) => {
  // Check if the control overflows the canvas
  if (controlState.selectedControl) {
    controlState.selectedControl.overflowsCanvas = doesControlOverflowCanvas(
      controlState.selectedControl
    );
  }

  // Check if the control overflows the canvas
  if (controlState.insideControl) {
    controlState.insideControl.overflowsCanvas = doesControlOverflowCanvas(
      controlState.insideControl
    );
  }
};

export const isOverlapping = (
  control1: ControlReference,
  control2: ControlReference
): boolean => {
  const rect1 = control1.canvasReference.rect;
  const rect2 = control2.canvasReference.rect;

  const rect1Sides = getBoundingBoxSides(rect1, { x: 0, y: 0 });
  const rect2Sides = getBoundingBoxSides(rect2, { x: 0, y: 0 });
  const rect1Centers = getCenters(rect1Sides);
  const rect2Centers = getCenters(rect2Sides);

  return (
    distance(rect2Centers.Top, rect1Centers.Top) < 15 &&
    distance(rect2Centers.Left, rect1Centers.Left) < 15 &&
    distance(rect1Centers.Right, rect2Centers.Right) < 15 &&
    distance(rect1Centers.Bottom, rect2Centers.Bottom) < 15
  );
};

export const bundleOverlappingControls = (
  controls: ControlReferenceMap
): ControlReferenceMap => {
  const tmp = new Set();

  for (const key1 in controls) {
    for (const key2 in controls) {
      if (key1 === key2) continue;
      const sortedKey = [key1, key2].sort().join(':');
      if (tmp.has(sortedKey)) continue;

      tmp.add(sortedKey);

      const control1 = controls[key1];
      const control2 = controls[key2];

      const overlapped = isOverlapping(control1, control2);
      if (!overlapped) continue;

      if (control1.id.length > control2.id.length) {
        control2.overlapped = true;
        if (!control1.overlappingControls) control1.overlappingControls = [];
        control1.overlappingControls.push(control2);
      } else {
        control1.overlapped = true;
      }
    }
  }

  return controls;
};

export const getSideProximityLimit = (
  controlState: ControlState,
  isDragging: boolean
) => {
  const insideControl = controlState?.insideControl;
  const insideRect = insideControl?.canvasReference?.rect;

  const insideSmallDiv =
    insideRect && (insideRect?.width < 45 || insideRect?.height < 45);
  const distanceLimit = insideSmallDiv ? 5 : 10;
  let maxDistance = Infinity;
  if (isDragging && insideControl) {
    const isInsideAGap = insideControl.canvasReference.node.hasChildren();

    const isOverAnElement = insideControl.canvasReference.node.isElement;

    if (!isInsideAGap && !isOverAnElement) {
      maxDistance = distanceLimit;
    }
  } else {
    maxDistance = distanceLimit;
  }

  return maxDistance;
};

export const controlSelectionHelper = (
  control?: ControlReference
): ControlReference | undefined => {
  if (control?.overlappingControls) {
    return control.overlappingControls[0];
  }

  return control;
};

export const hasMenuOpen = () => {
  return !!document.getElementById('elevated-menu-container');
};

export const isPointInCanvas = (point: point): boolean => {
  const canvas = document.getElementById(editorCanvasContainerId);

  if (!canvas) return false;

  const rect = canvas.getBoundingClientRect();

  return isPointInBoundingBox(rect, point, { x: 0, y: 0 });
};

export default {
  lockControlLayerWhenNotOverSide,
  lockControlLayer,
  setHoverState,
  getControlsFromCanvasElements,
  getClosestSide,
  renderContainerControl,
  renderSideControl,
  disableTextEditControl,
  enableTextEditControl,
  getTextSpanFromControl,
  styleControls,
  recycleControlLayerDivs,
  handleTextControl,
  controlSelectionHelper,
  isPointInCanvas,
  hasMenuOpen,
  setControlOverflow
};
