function Amplitude({ width = 64, height = 64 }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      enableBackground='new 0 0 64 64'
      width={width}
      height={height}
      viewBox='0 0 64 64'
    >
      <g fill='#00a7cf'>
        <path d='m28.2 14.4c-.2-.2-.4-.4-.6-.4s-.3.1-.5.2c-1.7 1.3-4 7-5.9 14.5h1.7c3.3 0 6.8.1 10.2.1-.9-3.4-1.7-6.4-2.5-8.7-1.2-3.5-1.9-5-2.4-5.7z' />
        <path d='m32 0c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zm23 32.6s-.1.1-.1.1h-.1-.1c-.2.1-.5.2-.8.2h-15.1c.1.5.3 1.1.4 1.7.8 3.6 3 13.1 5.4 13.1h.1c1.8 0 2.8-2.6 4.8-8.4v-.1c.3-.9.7-2 1.1-3.1l.1-.3c.1-.4.5-.6.9-.5s.6.5.5.9l-.1.4c-.2.7-.4 1.6-.7 2.7-1.2 5.1-3.1 12.7-7.8 12.7-3.1 0-4.9-4.9-5.7-7-1.5-3.9-2.6-8.1-3.6-12.1h-14l-2.9 9.3c-.4.7-1.3.9-2 .5-.4-.3-.7-.7-.7-1.2v-.1l.2-1c.4-2.4.9-4.9 1.4-7.4h-5.9c-1.1-.2-1.9-1.1-1.9-2.2s.8-2 1.8-2.2h.7.3c1.9 0 3.8.1 6 .1 3.1-12.5 6.6-18.8 10.6-18.9 4.2 0 7.4 9.7 9.9 19.1 5.2.1 10.7.3 16 .6h.2.3c.9.2 1.5 1.1 1.3 2 0 .5-.2.8-.5 1.1z' />
      </g>
    </svg>
  );
}

export default Amplitude;
