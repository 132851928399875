import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { AIExtractionEntry } from '../types';
import { CaretIcon } from '../../../components/Icons';
import { useExtractionState, useExtractionUpdater } from '../context';
import { useSyncedRefState } from '../../../hooks/useSyncedRefState';
import { getValueId } from '../utils';
import { useScreen } from '../../../hooks/useOnScreen';
import AIEntryFieldValue from './AIEntryFieldValue';
import classNames from 'classnames';
import styles from './styles.module.scss';

type Props = {
  entries: AIExtractionEntry[];
  entities: any[];
  index: number;
  highlight?: boolean;
  onEdit?: (entry: any, newValue: string) => void;
  onDelete?: (index: number) => void;
};

const AIEntry = ({
  entries,
  entities,
  index,
  highlight = false,
  onEdit = () => {},
  onDelete = () => {}
}: Props) => {
  const [expanded, setExpanded] = useState(true);
  const { setSelectedValueId } = useExtractionUpdater();
  const { activeComponent, selectedValueId, hoveredValueId } =
    useExtractionState();

  const getExpanded = useSyncedRefState(expanded);
  const getSelectedValueId = useSyncedRefState(selectedValueId);
  const getActiveComponent = useSyncedRefState(activeComponent);
  const entryFieldRef = useRef<HTMLDivElement>(null);
  const getEntryFieldRef = () => entryFieldRef?.current;

  const { isIntersecting, scrollIntoView } = useScreen(entryFieldRef);

  const valueIds = useMemo(() => {
    return entries.map((entry) => getValueId(entry, index));
  }, [entries]);

  // Helper function for scrolling and highlighting UX
  const scrollAndHighlight = (
    { scroll = false, highlight = false } = {},
    valueId?: string
  ) => {
    const entryFieldDiv = getEntryFieldRef();
    const _expanded = getExpanded();

    if (!entryFieldDiv) {
      return; // Nothing to do
    }

    if (scroll && getActiveComponent() === 'canvas') {
      if (valueId) {
        scrollIntoView(valueId);
      } else if (!_expanded && !isIntersecting()) {
        scrollIntoView();
      }
    }

    if (highlight) {
      entryFieldDiv.classList.add(styles.highlight);
    } else {
      entryFieldDiv.classList.remove(styles.highlight);
    }
  };

  // Unselect the value if no longer expanded
  useEffect(() => {
    const _selectedValueId = getSelectedValueId();
    const entryFieldDiv = getEntryFieldRef();

    if (!expanded && _selectedValueId && valueIds.includes(_selectedValueId)) {
      setSelectedValueId(null);
    }

    if (
      expanded &&
      entryFieldDiv &&
      entryFieldDiv.classList.contains(styles.highlight)
    ) {
      scrollAndHighlight({ highlight: true }); // Remove the highlight if expanded
    }
  }, [expanded, valueIds]);

  // Auto expand the values if the selected value is in the list
  useEffect(() => {
    const isExpanded = getExpanded();

    if (selectedValueId && valueIds.includes(selectedValueId)) {
      if (!isExpanded) {
        setExpanded(true);
      }

      scrollAndHighlight({ scroll: true }, selectedValueId);
    }
  }, [selectedValueId, valueIds]);

  // Highlight the div that contains the hovered value if not expanded
  useEffect(() => {
    const isExpanded = getExpanded();

    if (!hoveredValueId) {
      scrollAndHighlight();
      return; // Nothing to do if already expanded
    }

    if (hoveredValueId && valueIds.includes(hoveredValueId)) {
      scrollAndHighlight({ highlight: !isExpanded, scroll: !isExpanded });
    }
  }, [hoveredValueId, valueIds]);

  return (
    <div
      ref={entryFieldRef}
      id={`entry-${index}`}
      className={classNames(styles.aiEntryField, {
        [styles.forceHighlight]: highlight
      })}
    >
      {entries.length > 1 && (
        <div
          className={styles.header}
          onClick={() => setExpanded((prev) => !prev)}
        >
          <div
            className={styles.text}
            style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}
          >
            Entry {index + 1}
          </div>
          <div className={classNames(styles.icon, { [styles.flip]: expanded })}>
            <CaretIcon width={20} height={20} />
          </div>
        </div>
      )}
      {(expanded || entries.length === 1) && (
        <div
          className={classNames(
            styles.content,
            entries.length > 1 && styles.separator
          )}
        >
          {entries.map((entry, entryIndex) => (
            <Fragment key={entry.id}>
              <div className={styles.header}>{entities[entryIndex].name}</div>
              <div className={styles.values}>
                <AIEntryFieldValue
                  entry={entry}
                  index={index}
                  onEdit={(newValue) => onEdit(entry, newValue)}
                  onDelete={() => onDelete(index)}
                />
              </div>
            </Fragment>
          ))}
        </div>
      )}
    </div>
  );
};

export default AIEntry;
