import '../../style/navigation.scss';
import { Link, useHistory, useParams } from 'react-router-dom';
import { BackArrowIcon } from '../Icons';
import { useAppSelector } from '../../hooks';
import { DynamicTextTooltip } from '../Core';

type URLParams = {
  extractionId: string;
};

const MAX_LABEL_LENGTH = 14;

export default function AINavigation({ activeItem, style, submitButton }: any) {
  const { extractionId } = useParams<URLParams>();
  const history = useHistory();

  const extraction = useAppSelector((state) =>
    state.ai.extractions.find((extraction) => extraction.id === extractionId)
  );

  let extractionLabel = extraction?.key;
  if (extraction?.key.length > MAX_LABEL_LENGTH) {
    extractionLabel = extraction?.key.slice(0, MAX_LABEL_LENGTH) + '...';
  }

  return (
    <nav className='main-nav' style={style}>
      <div className='left-nav'>
        <div className='left-nav-header'>
          <div className='back-container' onClick={() => history.push('/ai/')}>
            <BackArrowIcon />
          </div>
          <DynamicTextTooltip
            text={extraction?.key}
            maxLength={MAX_LABEL_LENGTH}
            placement='bottom'
          >
            <span>{extractionLabel}</span>
          </DynamicTextTooltip>
        </div>
        <Link
          className={activeItem === 'results' ? 'active' : undefined}
          to={`/ai/${extractionId}/results`}
        >
          Results
        </Link>
        <Link
          className={activeItem === 'logic' ? 'active' : undefined}
          to={`/ai/${extractionId}/logic`}
        >
          Logic
        </Link>
        <Link
          className={activeItem === 'integrations' ? 'active' : undefined}
          to={`/ai/${extractionId}/integrations`}
        >
          Integrations
        </Link>
        <Link
          className={activeItem === 'settings' ? 'active' : undefined}
          to={`/ai/${extractionId}/settings`}
        >
          Settings
        </Link>
      </div>
      {submitButton && (
        <div className='right-sub-nav ml-auto justify-right'>
          {submitButton}
        </div>
      )}
    </nav>
  );
}
