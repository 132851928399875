import { memo } from 'react';
import ShareFormOverlay from './ShareFormOverlay';
import ShareDocumentOverlay from './ShareDocumentOverlay';
import { ChevronDownIcon } from '../../../Icons';
import testIds from '../../../../utils/testIds';
import { Button } from '../button';

function Share({
  unsavedDraftChanges = false,
  deleteDraft = () => {},
  type = 'form'
}: {
  unsavedDraftChanges?: boolean;
  deleteDraft?: () => void;
  type?: 'form' | 'document';
}) {
  let Overlay, props;
  if (type === 'document') {
    Overlay = ShareDocumentOverlay;
  } else {
    Overlay = ShareFormOverlay;
    props = { unsavedDraftChanges, deleteDraft };
  }

  return (
    <Overlay {...props}>
      <Button
        className='tw-rounded-l-none tw-h-11'
        size='icon'
        data-testid={testIds.shareButton}
      >
        <ChevronDownIcon className='tw-stroke-current tw-w-5 tw-h-5' />
      </Button>
    </Overlay>
  );
}

export default memo(Share);
