import { memo, useMemo, useState } from 'react';

import classNames from 'classnames';
import styles from './styles.module.scss';
import ruleStyles from '../../NavigationRules/styles.module.scss';
import { TYPE_BUTTON, TYPE_TEXT } from '../../../utils/elements';
import { flattenWithAsset } from '../../../utils/themes';
import { ButtonIcon, CheckboxIcon, ContainerIcon, TextIcon } from '../../Icons';
import { DynamicTextTooltip } from '../../Core';
import ElementSelectorModal from './ElementSelectorModal';
import { useAppSelector, useFeatheryContext } from '../../../hooks';
import jsonpath from 'jsonpath';

const ElementSelectorWithModal = ({
  step,
  elementType = '',
  elementId = '',
  onSelect = () => {},
  error = false,
  ...props
}: any) => {
  const theme = useAppSelector((state) => state.formBuilder.theme);

  const { featheryContext } = useFeatheryContext();

  const [show, setShow] = useState(false);

  const getElementText = (element: any) => {
    // If text_mode property is set to 'data', then we don't want to render the properties.text.
    // Instead we want the text from the data element specified in the text_source property
    let textFromData = null;

    if (element.properties.text_mode === 'data') {
      let textSource = element.properties.text_source ?? '';
      // convert to valid jsonpath
      if (textSource.startsWith('feathery'))
        textSource = textSource.replace('feathery', '$');
      try {
        textFromData = jsonpath.value(featheryContext, textSource);
      } catch (e) {
        // Ignoring errors due to things like unset text_source, deleted products etc.
      }
    }
    return textFromData ?? element.properties.text;
  };

  const [elementDisplay, tooltipText] = useMemo(() => {
    if (!elementType) return ['Target', ''];
    else if (elementType === 'step') return [`Current Step`, ''];

    let element, props, text;
    switch (elementType) {
      case 'button':
        element = step.buttons.find((e: any) => e.id === elementId);
        element = flattenWithAsset(theme, TYPE_BUTTON, element);
        props = element.properties;
        text = getElementText(element);
        return [
          <>
            <ButtonIcon color='white' className={styles.elementSelectorIcon} />
            {text || props.image_url}
          </>,
          text || props.image_url
        ];
      case 'text':
        element = step.texts.find((e: any) => e.id === elementId);
        element = flattenWithAsset(theme, TYPE_TEXT, element);
        text = getElementText(element);
        return [
          <>
            <TextIcon color='white' className={styles.elementSelectorIcon} />
            {text}
          </>,
          text
        ];
      case 'field':
        element = step.servar_fields.find((e: any) => e.id === elementId);
        element = flattenWithAsset(theme, element.servar.type, element);
        return [
          <>
            <CheckboxIcon
              color='white'
              className={styles.elementSelectorIcon}
            />
            {element.servar.key}
          </>,
          element.servar.key
        ];
      case 'container':
        element = step.subgrids.find((e: any) => e.id === elementId);
        return [
          <>
            <ContainerIcon
              color='white'
              className={styles.elementSelectorIcon}
            />
            {element.key}
          </>,
          element.key
        ];
      default:
        return ['', ''];
    }
  }, [step, elementType, elementId, theme]);

  return (
    <>
      <ElementSelectorModal
        show={show}
        hide={() => setShow(false)}
        step={step}
        elementId={elementId}
        onSelect={(type: string, nodeId: string) => {
          onSelect(type, nodeId);
          setShow(false);
        }}
      />
      <DynamicTextTooltip text={tooltipText} maxLength={18}>
        <div
          className={classNames(
            ruleStyles.ruleTarget,
            ruleStyles.fieldTarget,
            ruleStyles.long,
            elementType && ruleStyles.selected,
            error && ruleStyles.fieldTargetError
          )}
          onClick={() => setShow(true)}
          {...props}
        >
          <div className={ruleStyles.fieldSpan}>{elementDisplay}</div>
        </div>
      </DynamicTextTooltip>
    </>
  );
};

export default memo(ElementSelectorWithModal);
