type Props = {
  height?: number;
  color?: string;
};

export default function AIStarIcon({ height = 20, color = 'white' }: Props) {
  return (
    <svg
      width={height}
      height={height}
      viewBox='0 0 11 11'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.02353 0.323441C5.19481 -0.107814 5.80519 -0.107814 5.97647 0.323441L7.23085 3.48191C7.28296 3.61312 7.38688 3.71704 7.51809 3.76915L10.6766 5.02354C11.1078 5.19481 11.1078 5.80519 10.6766 5.97646L7.51809 7.23085C7.38688 7.28296 7.28296 7.38688 7.23085 7.51809L5.97647 10.6766C5.80519 11.1078 5.19481 11.1078 5.02353 10.6766L3.76915 7.51809C3.71704 7.38688 3.61312 7.28296 3.48191 7.23085L0.323439 5.97646C-0.107812 5.80519 -0.107812 5.19481 0.323435 5.02354L3.48191 3.76915C3.61312 3.71704 3.71704 3.61312 3.76915 3.48191L5.02353 0.323441Z'
        fill={color}
      />
      <path
        d='M2.84055 1.62297C2.84055 2.26003 2.32411 2.77647 1.68705 2.77647C1.04999 2.77647 0.533555 2.26003 0.533555 1.62297C0.533555 0.98591 1.04999 0.469474 1.68705 0.469474C2.32411 0.469474 2.84055 0.98591 2.84055 1.62297Z'
        fill={color}
      />
      <path
        d='M8.80028 9.56928C9.11881 9.56928 9.37703 9.31106 9.37703 8.99253C9.37703 8.674 9.11881 8.41578 8.80028 8.41578C8.48175 8.41578 8.22353 8.674 8.22353 8.99253C8.22353 9.31106 8.48175 9.56928 8.80028 9.56928Z'
        fill={color}
      />
    </svg>
  );
}
