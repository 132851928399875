/* eslint-disable react-hooks/exhaustive-deps */
import React, { RefObject, useEffect, useRef, useState } from 'react';

/**
 * Stop propagation of React's synthetic event and the underlying native event.
 * See link for why we need to access the native event.
 * @see https://stackoverflow.com/questions/24415631/reactjs-syntheticevent-stoppropagation-only-works-with-react-events
 */
function stopReactEventPropagation(event: any) {
  event.stopPropagation();
  event.nativeEvent.stopImmediatePropagation();

  return event;
}

/**
 * Tracks global mousedown events and updates state to hide/show when clicking within or away from elements
 * @param {Array} refs List of elements to ignore (these are considered "within" the toggled element)
 * @param options
 */
function useGlobalMouseDownToggle(
  refs: RefObject<any>[] = [],
  options?: {
    shouldGuard?: boolean;
    closeOnKeyup?: boolean;
    onCloseCb?: () => void;
  }
): readonly [boolean, React.Dispatch<React.SetStateAction<boolean>>] {
  const {
    shouldGuard = false,
    onCloseCb = () => {},
    closeOnKeyup = false
  } = options || {};
  const [show, setShow] = useState(false);
  const guardRef = useRef<boolean>(true);

  const setShowHelper: React.Dispatch<React.SetStateAction<boolean>> = (
    newVal
  ) => {
    if (shouldGuard) guardRef.current = true;
    if (!newVal) onCloseCb();
    return setShow(newVal);
  };

  useEffect(() => {
    function hideOnClickAway(event: any) {
      const clickedWithin = refs.some(
        (ref: any) => ref.current && ref.current.contains(event.target)
      );
      // eslint-disable-next-line no-useless-return
      if (clickedWithin) return;
      else if (!shouldGuard) {
        setShowHelper(false);
      } else if (!guardRef.current) {
        // clicked outside and guard has already been flipped. reset guard for next time
        setShowHelper(false);
        guardRef.current = true;
      } else guardRef.current = false;
    }
    function hideOnKeyup() {
      if (closeOnKeyup) setShowHelper(false);
    }

    document.addEventListener('mousedown', hideOnClickAway);
    document.addEventListener('keyup', hideOnKeyup);
    return () => {
      document.removeEventListener('mousedown', hideOnClickAway);
      document.removeEventListener('keyup', hideOnKeyup);
    };
  }, []);

  return [show, setShowHelper] as const;
}

export { stopReactEventPropagation, useGlobalMouseDownToggle };
