export default function ErrorUFOIcon() {
  return (
    <svg
      width='268'
      height='164'
      viewBox='0 0 268 164'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M190.599 38.3264C190.599 57.9322 175.007 74.1447 155.613 75.2758C154.852 75.2758 154.091 75.2758 153.331 75.2758C146.866 75.2758 140.401 73.3906 135.077 70.3743C123.668 63.9648 116.062 51.8997 116.062 37.9494C116.062 28.5235 119.485 19.8517 125.57 13.4421C132.415 5.90145 142.302 1 153.331 1C167.782 1 180.331 9.29476 186.416 21.3599C189.078 26.6383 190.599 32.2939 190.599 38.3264Z'
        fill='white'
        stroke='#9AA0AA'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        opacity='0.1'
        d='M190.271 38.3265C190.271 57.9323 174.679 74.1447 155.285 75.2758C148.059 74.1447 141.214 72.2596 134.749 70.7514C123.34 64.3418 115.734 52.2767 115.734 38.3265C115.734 28.9006 119.157 20.2288 125.242 13.8192C137.791 13.4422 152.242 14.5733 167.073 17.2125C173.919 18.3436 180.384 19.8518 186.468 21.3599C188.75 26.6384 190.271 32.2939 190.271 38.3265Z'
        fill='#AAB2C5'
      />
      <path
        d='M235.737 63.2793C238.607 47.2891 203.219 27.6722 156.695 19.4638C110.171 11.2553 70.1295 17.5637 67.2594 33.5539C64.3892 49.5441 99.7775 69.161 146.301 77.3695C192.825 85.5779 232.867 79.2695 235.737 63.2793Z'
        fill='#F1F2F5'
        stroke='#9AA0AA'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M144.841 28.5941C145.053 25.8952 140.811 23.3671 135.367 22.9474C129.922 22.5277 125.337 24.3754 125.125 27.0743C124.914 29.7731 129.156 32.3012 134.6 32.7209C140.044 33.1406 144.63 31.2929 144.841 28.5941Z'
        fill='#9AA0AA'
      />
      <path
        d='M96.9875 35.5259C102.222 33.985 105.836 30.6323 105.059 28.0374C104.282 25.4425 99.4082 24.588 94.1735 26.1288C88.9388 27.6696 85.3252 31.0223 86.1023 33.6172C86.8794 36.2122 91.7528 37.0667 96.9875 35.5259Z'
        fill='#9AA0AA'
      />
      <path
        d='M183.296 38.5138C183.774 35.8488 179.804 32.9194 174.428 31.9709C169.051 31.0223 164.305 32.4138 163.827 35.0789C163.349 37.7439 167.319 40.6733 172.695 41.6218C178.071 42.5704 182.817 41.1789 183.296 38.5138Z'
        fill='#9AA0AA'
      />
      <path
        d='M212.17 58.2281C213.421 55.8221 210.501 51.8602 205.648 49.379C200.794 46.8977 195.845 46.8367 194.594 49.2427C193.343 51.6487 196.263 55.6106 201.116 58.0918C205.97 60.573 210.919 60.634 212.17 58.2281Z'
        fill='#9AA0AA'
      />
      <path
        d='M146.757 51.625L111.01 51.248L60.1016 145.938L202.99 160.965L187.068 64.4442L146.757 51.625Z'
        fill='url(#paint0_linear_1955_42926)'
      />
      <path
        d='M221.669 162.363H38.75C43.6938 157.932 50.9193 153.501 59.2856 150.178C63.8491 148.517 69.1732 146.301 74.4972 145.194C90.4694 140.763 109.864 137.994 130.4 137.994C155.119 137.994 177.936 141.871 195.429 147.963C201.514 150.178 206.838 152.394 211.402 155.163C215.585 157.378 219.007 159.593 221.669 162.363Z'
        fill='#F1F2F5'
        stroke='#9AA0AA'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M76.3394 149.773C76.579 152.023 72.659 154.715 68.1211 155.199C63.5833 155.682 59.6022 154.21 58.9844 152.001C63.4024 150.393 68.5368 148.329 73.751 147.016C75.3435 147.604 76.2196 148.648 76.3394 149.773Z'
        fill='#9AA0AA'
      />
      <path
        d='M109.107 146.288C115.197 146.288 120.135 145.107 120.135 143.649C120.135 142.191 115.197 141.01 109.107 141.01C103.016 141.01 98.0781 142.191 98.0781 143.649C98.0781 145.107 103.016 146.288 109.107 146.288Z'
        fill='#9AA0AA'
      />
      <path
        d='M168.547 146.072C168.672 144.202 163.847 142.362 157.769 141.963C151.692 141.564 146.664 142.756 146.539 144.626C146.414 146.496 151.239 148.335 157.317 148.735C163.394 149.134 168.422 147.942 168.547 146.072Z'
        fill='#9AA0AA'
      />
      <path
        d='M209.444 155.809C207.932 155.635 206.118 155.694 203.894 155.058C197.893 153.986 193.198 151.547 193.414 149.674C193.457 149.299 193.457 149.299 193.878 148.968C199.75 151.164 205.087 153.287 209.444 155.809Z'
        fill='#9AA0AA'
      />
      <path
        d='M186.841 65.6223C188.129 58.4472 172.076 49.614 150.985 45.8929C129.894 42.1717 111.753 44.9717 110.465 52.1468C109.177 59.3219 125.23 68.155 146.321 71.8762C167.412 75.5973 185.553 72.7974 186.841 65.6223Z'
        fill='#C8D0E0'
        stroke='#9AA0AA'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M50.2819 100.37C50.2819 100.37 37.1455 103.706 41.1262 106.374C45.1069 108.909 54.528 113.311 63.4182 110.376C72.3085 107.441 63.4182 103.305 63.5509 103.305C63.6836 103.305 50.2819 100.37 50.2819 100.37Z'
        fill='#CED7E2'
      />
      <path
        d='M34.2266 99.3031C39.0034 100.771 53.7321 98.3692 63.1531 92.2323C70.5838 101.438 73.3703 103.839 76.0241 104.106C71.9107 108.242 58.2435 114.912 50.5475 106.24C41.5245 111.977 34.2266 99.3031 34.2266 99.3031Z'
        fill='#E9EEF7'
      />
      <path
        d='M192.008 111.312C197.05 111.579 210.717 105.442 218.148 97.1704C227.702 104.241 230.886 105.842 233.673 105.442C230.621 110.512 221.332 121.585 208.196 122.385C198.642 116.782 192.008 111.312 192.008 111.312Z'
        fill='#E9EEF7'
      />
      <path
        d='M148.313 111.477C147.315 106.527 137.968 94.8195 128.106 89.6834C132.574 78.6696 133.331 75.1866 132.249 72.5876C137.919 74.2805 150.957 80.5178 155.005 93.0407C151.958 103.689 148.313 111.477 148.313 111.477Z'
        fill='white'
      />
      <path
        d='M266.85 162.363H255.303H15.918H1'
        stroke='#D5DAE8'
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <defs>
        <linearGradient
          id='paint0_linear_1955_42926'
          x1='131.555'
          y1='55.0594'
          x2='131.555'
          y2='116.642'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#BDC4D1' stopOpacity='0.53' />
          <stop offset='1' stopColor='#5D7CB8' stopOpacity='0' />
        </linearGradient>
      </defs>
    </svg>
  );
}
