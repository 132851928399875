export const KEY_HOLD_DELAY = 250;

export const EVENTS = {
  onKeypress: 'onKeypress',
  onKeydown: 'onKeydown',
  onKeyup: 'onKeyup'
};

export const MODIFIER_KEY_MAP = {
  Alt: 'Alt',
  Control: 'Control',
  Meta: 'Meta',
  Shift: 'Shift'
};
