export default function RefreshIcon({
  width = 16,
  height = 17,
  color = '#E2626E',
  ...props
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M15.8091 1.33751C15.6813 1.20982 15.5299 1.14576 15.3549 1.14576C15.18 1.14576 15.0288 1.20982 14.9007 1.33751L13.5887 2.63968C12.8688 1.96001 12.0445 1.43338 11.116 1.06004C10.1876 0.686655 9.23186 0.5 8.24945 0.5C6.39901 0.5 4.78423 1.06007 3.40482 2.18046C2.02537 3.30071 1.11705 4.76235 0.679688 6.56573V6.63633C0.679688 6.72379 0.71161 6.79955 0.77556 6.86339C0.839511 6.92734 0.915163 6.95927 1.00262 6.95927H3.01108C3.15916 6.95927 3.26002 6.88185 3.3139 6.72718C3.59646 6.05438 3.7747 5.66077 3.8488 5.54634C4.31975 4.77929 4.94551 4.17704 5.72606 3.73967C6.50669 3.30234 7.34774 3.08359 8.24931 3.08359C9.60189 3.08359 10.776 3.54453 11.7719 4.46636L10.3788 5.8591C10.2513 5.98696 10.1872 6.13844 10.1872 6.3134C10.1872 6.48831 10.2513 6.63972 10.3788 6.76755C10.5068 6.89542 10.6583 6.9593 10.8332 6.9593H15.3549C15.5299 6.9593 15.6813 6.89542 15.8091 6.76755C15.9368 6.63972 16.0007 6.48835 16.0007 6.3134V1.7917C16.0007 1.61675 15.9372 1.46541 15.8091 1.33751Z'
        fill={color}
      />
      <path
        d='M15.4272 9.55011H13.4894C13.3414 9.55011 13.2404 9.62742 13.1867 9.78222C12.9043 10.4551 12.7258 10.8485 12.6519 10.963C12.1808 11.7298 11.555 12.3323 10.7747 12.7696C9.99401 13.2071 9.15296 13.4256 8.25136 13.4256C7.59874 13.4256 6.96955 13.3046 6.36405 13.0624C5.75848 12.82 5.21676 12.4768 4.73909 12.0327L6.12186 10.65C6.24966 10.5223 6.31361 10.3707 6.31361 10.1958C6.31361 10.0209 6.24966 9.86933 6.12186 9.74168C5.994 9.6137 5.84266 9.54968 5.6677 9.54968H1.14601C0.970951 9.54968 0.819541 9.6137 0.691711 9.74168C0.56388 9.86937 0.5 10.021 0.5 10.1959V14.7176C0.5 14.8924 0.56388 15.0439 0.691746 15.1717C0.819683 15.2993 0.971093 15.3636 1.14605 15.3636C1.32097 15.3636 1.4723 15.2994 1.60021 15.1717L2.90216 13.8696C3.62216 14.5558 4.44143 15.0841 5.3599 15.4542C6.27836 15.8244 7.22878 16.0092 8.21109 16.0092C10.0548 16.0092 11.6629 15.4491 13.0355 14.3288C14.4082 13.2086 15.3098 11.7467 15.7406 9.9435C15.7474 9.93006 15.7506 9.90648 15.7506 9.87297C15.7506 9.78537 15.7185 9.70993 15.6546 9.64577C15.5906 9.58217 15.5148 9.55011 15.4272 9.55011Z'
        fill={color}
      />
    </svg>
  );
}
