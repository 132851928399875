export default function SSNumberIcon() {
  return (
    <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23.611 37.6667C23.611 38.4951 24.2826 39.1667 25.111 39.1667H41.2499C43.599 39.1667 45.4999 37.2604 45.4999 34.913V19.3092C45.4999 16.9618 43.599 15.0555 41.2499 15.0555H33.9722C33.1437 15.0555 32.4722 15.7271 32.4722 16.5555C32.4722 17.384 33.1437 18.0555 33.9722 18.0555H41.2499C41.9384 18.0555 42.4999 18.615 42.4999 19.3092V34.913C42.4999 35.6073 41.9384 36.1667 41.2499 36.1667H25.111C24.2826 36.1667 23.611 36.8382 23.611 37.6667ZM13.911 9.80245C14.2946 9.60358 14.7209 9.499 15.1554 9.50001C15.5893 9.50101 16.0146 9.60728 16.3968 9.80734C16.3975 9.80772 16.3983 9.80811 16.399 9.80849L26.2032 14.9172C26.2032 14.9172 26.2033 14.9172 26.2034 14.9173C26.6194 15.134 26.9691 15.4532 27.2244 15.8397C27.4796 16.2258 27.6337 16.6684 27.6766 17.1259L27.6767 17.1271C27.733 17.7335 28.7777 30.486 16.5155 37.6825C16.1106 37.9211 15.6495 38.0509 15.1762 38.0554C14.7017 38.06 14.2369 37.9385 13.8266 37.7064C1.16549 30.5557 2.51367 17.5069 2.56865 17.0425L2.56861 17.0425C2.67887 16.1428 3.21891 15.342 4.02989 14.9166L4.0372 14.9128L4.03721 14.9128L13.9104 9.80277C13.9106 9.80266 13.9108 9.80256 13.911 9.80245ZM25.5104 16.2472L24.9341 17.3531L24.9184 17.3833L24.9186 17.3833L24.9344 17.3529L25.5104 16.2472ZM24.918 17.3844L24.9178 17.3844L24.8171 17.5778C24.8171 17.5778 24.8172 17.5778 24.8173 17.5779L24.918 17.3844ZM24.6983 17.5159L15.1487 12.5399L5.53635 17.5149C5.49265 18.0487 5.33986 20.6782 6.27752 23.9186C7.32029 27.5222 9.70517 31.8767 15.1474 35.0062C25.1668 29.022 24.7833 18.7505 24.6983 17.5159Z'
        fill='#414859'
      />
    </svg>
  );
}
