export default function OpenLinkIcon({ width = 18, height = 18 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 17 18'
      fill='#414859'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M17 2.125V15.875C17 16.9105 16.1845 17.75 15.1786 17.75H1.82143C0.815469 17.75 0 16.9105 0 15.875V2.125C0 1.08945 0.815469 0.25 1.82143 0.25H15.1786C16.1845 0.25 17 1.08945 17 2.125ZM13.6607 2.75H9.41181C8.60306 2.75 8.19449 3.7602 8.76783 4.35043L9.9815 5.59992L2.56195 13.2377C2.38414 13.4207 2.38414 13.7175 2.56195 13.9006L3.73939 15.1127C3.91725 15.2957 4.20556 15.2957 4.38338 15.1127L11.8029 7.47496L13.0167 8.72461C13.587 9.3116 14.5714 8.89941 14.5714 8.06168V3.6875C14.5714 3.16973 14.1637 2.75 13.6607 2.75Z' />
    </svg>
  );
}
