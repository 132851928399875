import { MOBILE_DIVERGED_VIEWPORT_PROPERTIES, Viewport } from '../viewports';
import { Model } from './Model';

export class Element extends Model {
  static Type = 'Element';

  static ViewportFields = MOBILE_DIVERGED_VIEWPORT_PROPERTIES.elements;

  constructor(state: any = {}, viewport?: Viewport) {
    super(Element.Type, state, Element.ViewportFields, viewport);
  }

  getRaw() {
    const raw = super.getRaw();

    if (!raw.mobile_styles) raw.mobile_styles = {};

    return raw;
  }
}
