import styles from './styles.module.scss';
import classNames from 'classnames';

const Card = ({ children, className, onClick }: any) => {
  return (
    <div
      className={classNames(className, styles.cardContainer)}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default Card;
