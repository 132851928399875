import { memo } from 'react';

import { WarningIcon } from '../../../Icons';
import { useAppSelector } from '../../../../hooks';
import { DRAFT_STATUS } from '../../../../redux/utils';

import styles from './styles.module.scss';

type PanelWarningOverlayProps = {
  message?: string;
  Icon?: React.ElementType;
  actionlLabel?: string;
  onAction?: () => void;
};

const PanelWarningOverlay = ({
  message = "Your work won't be saved until you refresh.",
  Icon = WarningIcon
}: PanelWarningOverlayProps) => {
  const draftStatus = useAppSelector((state) => state.formBuilder.draftStatus);

  if (![DRAFT_STATUS.CONFLICT, DRAFT_STATUS.ERROR].includes(draftStatus))
    return null;

  return (
    <div className={styles.panelOverlay}>
      <Icon />
      <div className={styles.message}>{message}</div>
    </div>
  );
};

export default memo(PanelWarningOverlay);
