export default function CheckboxGroupIcon() {
  return (
    <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M23.4736 15.5C22.6452 15.5 21.9736 16.1716 21.9736 17C21.9736 17.8284 22.6452 18.5 23.4736 18.5V15.5ZM32.421 18.5C33.2494 18.5 33.921 17.8284 33.921 17C33.921 16.1716 33.2494 15.5 32.421 15.5V18.5ZM23.4736 18.5H32.421V15.5H23.4736V18.5Z'
        fill='#414859'
      />
      <path
        d='M23.4736 36.5526C22.6452 36.5526 21.9736 37.2242 21.9736 38.0526C21.9736 38.8811 22.6452 39.5526 23.4736 39.5526V36.5526ZM32.421 39.5526C33.2494 39.5526 33.921 38.8811 33.921 38.0526C33.921 37.2242 33.2494 36.5526 32.421 36.5526V39.5526ZM23.4736 39.5526H32.421V36.5526H23.4736V39.5526Z'
        fill='#414859'
      />
      <path
        d='M23.4736 8.6579C22.6452 8.6579 21.9736 9.32947 21.9736 10.1579C21.9736 10.9863 22.6452 11.6579 23.4736 11.6579V8.6579ZM43.9999 11.6579C44.8284 11.6579 45.4999 10.9863 45.4999 10.1579C45.4999 9.32947 44.8284 8.6579 43.9999 8.6579V11.6579ZM23.4736 11.6579H43.9999V8.6579H23.4736V11.6579Z'
        fill='#414859'
      />
      <path
        d='M23.4736 29.7105C22.6452 29.7105 21.9736 30.3821 21.9736 31.2105C21.9736 32.0389 22.6452 32.7105 23.4736 32.7105V29.7105ZM43.9999 32.7105C44.8284 32.7105 45.4999 32.0389 45.4999 31.2105C45.4999 30.3821 44.8284 29.7105 43.9999 29.7105V32.7105ZM23.4736 32.7105H43.9999V29.7105H23.4736V32.7105Z'
        fill='#414859'
      />
      <rect
        x='4'
        y='7'
        width='12.6316'
        height='12.6316'
        stroke='#414859'
        strokeWidth='3'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <rect
        x='4'
        y='28.0526'
        width='12.6316'
        height='12.6316'
        stroke='#414859'
        strokeWidth='3'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.21045 13.5072L10.1754 15.4211L16.6315 9.10526'
        stroke='#414859'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
