import { fieldTypeNameMap } from '../../../utils/elements';

const FIELDS: Record<string, string> = Object.keys(fieldTypeNameMap).reduce(
  (acc, type) => ({ ...acc, [type]: type }),
  {}
);

const FIELD_TYPES = [
  FIELDS.text_field,
  FIELDS.text_area,
  FIELDS.integer_field,
  FIELDS.button_group,
  FIELDS.select,
  FIELDS.multiselect,
  FIELDS.checkbox,
  FIELDS.dropdown,
  FIELDS.dropdown_multi,
  FIELDS.email,
  FIELDS.phone_number,
  FIELDS.gmap_line_1,
  FIELDS.gmap_line_2,
  FIELDS.gmap_city,
  FIELDS.gmap_state,
  FIELDS.gmail_country,
  FIELDS.gmap_zip
];

export const FIELDS_WITH_OPTIONS = [
  FIELDS.button_group,
  FIELDS.select,
  FIELDS.multiselect,
  FIELDS.checkbox,
  FIELDS.dropdown,
  FIELDS.dropdown_multi
];

export const ALLOWED_FIELD_TYPES: Record<string, string> = FIELD_TYPES.reduce(
  (acc, key) => ({ ...acc, [key]: true }),
  {}
);
