import { URL, httpDelete, httpGet, httpPatch, httpPost } from './utils';

export default {
  getTheme: (token: string, { themeId }: any) => {
    return httpGet({
      token,
      url: `${URL.THEMES}${themeId}/`
    });
  },
  getPanelThemeAssetUse: (token: string, cached = true) => {
    const url = `${URL.THEMES}asset_panel_use/`;
    const invalid = cached ? [] : [url];
    return httpGet({ token, url, invalid });
  },
  createTheme: (token: string, { themeId, copyThemeKey }: any) => {
    return httpPost({
      token,
      url: URL.THEMES,
      body: { key: themeId, copy_theme_key: copyThemeKey }
    });
  },
  editTheme: (token: string, data: any) => {
    const { themeId, ...newThemeData } = data;
    return httpPatch({
      token,
      baseUrl: URL.THEMES,
      path: `${themeId}/`,
      body: newThemeData
    });
  },
  deleteTheme: (token: string, { themeId }: any) => {
    return httpDelete({ token, baseUrl: URL.THEMES, path: `${themeId}/` });
  },
  getThemes: (token: string) => {
    return httpGet({ token, url: URL.THEMES });
  },
  getTemplates: (token: string) => {
    const url = `${URL.THEMES}templates/`;
    return httpGet({ token, url });
  }
};
