function MicrosoftDynamicsBusinessCentral({ width = 64, height = 64 }) {
  return (
    <img
      src={require('./MicrosoftDynamicsBusinessCentral.png')}
      style={{ width, height }}
    />
  );
}

export default MicrosoftDynamicsBusinessCentral;
