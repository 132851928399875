import { DropdownField, PropertyLabel } from '../../Core';
import classNames from 'classnames';
import styles from '../styles.module.scss';

import StripeConfig from './types';
import { memo } from 'react';

function StripePaymentSettings({
  stripeConfig,
  setStripeConfig = () => {}
}: {
  stripeConfig: StripeConfig;
  setStripeConfig: (c: StripeConfig) => void;
}) {
  const metadata = stripeConfig.metadata;
  return (
    <div className={classNames(styles.fieldMapping, styles.marginBottom)}>
      <PropertyLabel label='How do you want to collect payments?' />
      <DropdownField
        selected={metadata.checkout_type || 'custom'}
        onChange={(e: any) => {
          setStripeConfig({
            ...stripeConfig,
            ...{
              metadata: {
                ...stripeConfig.metadata,
                checkout_type: e.target.value
              }
            }
          });
        }}
        options={[
          { value: 'custom', display: 'In your form' },
          { value: 'stripe', display: 'Stripe Checkout' }
        ]}
        style={{ width: 'auto' }}
      />
    </div>
  );
}

export default memo(StripePaymentSettings);
