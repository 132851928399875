export default function TextNoStyleIcon({ width = 16, height = 16 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M9 4H13V2H3V4H7V7H9V4Z' fill='#414859' />
      <path d='M7 15V7H9V15H7Z' fill='#414859' />
    </svg>
  );
}
