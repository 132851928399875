import { useEffect, useState } from 'react';
import { useAppSelector } from '../hooks';
import useMounted from './useMounted';
import useFeatheryRedux from '../redux';
import { useParams } from 'react-router-dom';
import useGetJwt from './useGetJwt';
import { formatSteps } from './step';
import { objectApplyDeltas, objectRemove } from './core';

export default function useDraftForm(fetchData = true, global = false) {
  let { formId } = useParams<{ formId: string }>();
  if (global) formId = '';

  const mounted = useMounted();
  const getJwt = useGetJwt();

  const { getDraft } = useFeatheryRedux();

  const themes = useAppSelector((state) => state.themes).themes;

  const panelsData = useAppSelector((state) => state.panels.panels);
  const panel = panelsData[formId];

  /// /////////////////////////////////////////////////////////////////////////////
  //
  //  Logic for form published steps, draft steps and draft theme LOADING
  //
  /// /////////////////////////////////////////////////////////////////////////////

  const [loadedFormId, setLoadedFormId] = useState('');
  const [steps, setSteps] = useState<Record<string, any>>({});
  const [draftSteps, setDraftSteps] = useState<Record<string, any> | null>(
    null
  );
  const [logicRules, setLogicRules] = useState<Record<string, any> | null>(
    null
  );
  const [draftLogicRules, setDraftLogicRules] = useState<Record<
    string,
    any
  > | null>(null);

  const [draftTheme, setDraftTheme] = useState<Record<string, any> | null>(
    null
  );
  const [reloadForm, setReloadForm] = useState({ r: 1 });

  const parseAndSetDraftItems = (
    draftItemDeltas: Record<string, any>,
    changeRecordKey: string,
    publishedItems: Record<string, any>,
    setFn: (newDraftItems: any) => void
  ) => {
    const changedItems = Object.entries(draftItemDeltas)
      .filter(
        ([key, changeRecord]: [string, any]) =>
          changeRecord.operation !== 'delete'
      )
      .reduce(
        (
          overlayItems: Record<string, any>,
          [key, changeRecord]: [string, any]
        ) => {
          overlayItems[key] = changeRecord[changeRecordKey];
          return overlayItems;
        },
        {}
      );
    const deletedItemKeys = Object.entries(draftItemDeltas)
      .filter(
        ([key, changeRecord]: [string, any]) =>
          changeRecord.operation === 'delete'
      )
      .map(([key, changeRecord]: [string, any]) => key);
    setFn(
      Object.keys(changedItems).length || deletedItemKeys.length
        ? objectRemove({ ...publishedItems, ...changedItems }, deletedItemKeys)
        : null
    );
  };

  useEffect(() => {
    if (!fetchData || !panel) return;

    const handleStepsRes = (steps: any) => {
      const stepIdMap = formatSteps(steps);
      if (mounted.current) setSteps(stepIdMap);
      return stepIdMap;
    };

    const token = getJwt();
    FeatheryAPI.getPanelStep(token, { panelId: formId }).then(
      async (response: any) => {
        if (response?.status === 200) {
          const json = await response.json();
          if (Array.isArray(json)) {
            // Now overlay the draft onto the published steps & theme
            const draftJson = await getDraft({
              panelId: formId
            });
            setLoadedFormId(formId); // start periodic form freshness monitoring

            const draftStepDeltas = draftJson.steps ?? {};
            const publishedSteps = handleStepsRes(json);

            parseAndSetDraftItems(
              draftStepDeltas,
              'step',
              publishedSteps,
              setDraftSteps
            );

            // rules
            const rulesResponse = await FeatheryAPI.getPanelLogicRule(token, {
              panelId: formId
            });
            if (rulesResponse?.status === 200) {
              const rulesArr = await rulesResponse.json();
              const publishedLogicRules: Record<string, any> = {};
              rulesArr.forEach((rule: any) => {
                publishedLogicRules[rule.id] = rule;
              });
              setLogicRules(publishedLogicRules);

              const draftRuleDeltas = draftJson.logic_rules ?? {};
              parseAndSetDraftItems(
                draftRuleDeltas,
                'logic_rule',
                publishedLogicRules,
                setDraftLogicRules
              );
            }

            // theme - apply the draft theme deltas to the last published theme
            const themeDeltas = draftJson.theme ?? {};
            setDraftTheme(
              Object.keys(themeDeltas).length
                ? objectApplyDeltas(themes[panel.theme], themeDeltas)
                : null
            );
          }
        } else handleStepsRes([]);
      }
    );
  }, [formId, reloadForm]);

  return {
    steps,
    draftSteps,
    logicRules,
    draftLogicRules,
    draftTheme,
    loadedFormId,
    setReloadForm
  };
}
