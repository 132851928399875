import { Cell } from '../../RenderingEngine/GridInGrid/engine';
import useFeatheryRedux from '../../../redux';
import useCustomDragLayer from './useCustomDragLayer';
import { GridInGrid } from '../../RenderingEngine/GridInGrid';
import styles from '../styles.module.scss';
import { Viewport } from '../../RenderingEngine/GridInGrid/engine/viewports';
import { CustomDragOptions } from '../index';
import useViewport from '../../../hooks/useViewport';
import { CellControlsProps } from '../../RenderingEngine/GridInGrid/components/CellContainer';

export const SubgridIdentifier = 'subgrid';

const ControlComponent = ({ children, ...props }: CellControlsProps) => {
  return <div {...props}>{children}</div>;
};

const useSubgridDrag = (node: Cell, opts?: CustomDragOptions) => {
  const {
    // @ts-ignore TODO(ts) featheryRedux does not have a defined return type
    formBuilder: { updateDrag },
    toasts: { addInfoToast }
  } = useFeatheryRedux();
  const { isMobile } = useViewport();

  const [, dragRef] = useCustomDragLayer(
    () => ({
      type: SubgridIdentifier,
      opts,
      renderer: () => {
        return (
          <div className={styles.dragPreviewWrapper}>
            <GridInGrid
              node={node}
              viewport={Viewport.Desktop}
              initialStyle={{
                width: '100%',
                backgroundColor: 'white'
              }}
              // This will essentially disable and not render controls from the GridInGrid renderer
              controls={{
                Wrapper: ControlComponent
              }}
            />
          </div>
        );
      },
      onStart: () => {
        if (isMobile) {
          addInfoToast('Use Desktop view to drag containers');
          return null;
        }

        updateDrag(true);

        return { node, operation: 'move' };
      },
      onEnd: () => {
        updateDrag(false);
        if (opts && opts.onEnd) opts.onEnd();
      }
    }),
    [node]
  );

  return dragRef;
};

export default useSubgridDrag;
