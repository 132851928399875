import { useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { OpenLinkIcon } from '../../../Icons';
import { DocumentTriggerModal, SignDocumentModal } from '../../../Modals';
import { useAppSelector } from '../../../../hooks';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';

export default function ShareDocumentOverlay({
  children
}: {
  unsavedDraftChanges?: boolean;
  deleteDraft?: () => void;
  children: any;
}) {
  const [showSignModal, setShowSignModal] = useState(false);
  const [showTriggerModal, setShowTriggerModal] = useState(false);

  const { documentId } = useParams<{ documentId: string }>();
  const doc = useAppSelector((state) => state.documents.documents[documentId]);
  const isDocx = doc?.type === 'docx';

  const org = useAppSelector((state) => state.accounts.organization) ?? {};
  const { documents_over_limit: documentsOverLimit } = org;
  const documentsEnabled = org.enterprise_features.documents ?? false;

  let docLinkComponent = (
    <div
      className={classNames(
        'share-link',
        !documentsEnabled && documentsOverLimit ? 'disabled' : ''
      )}
      style={{ pointerEvents: 'auto' }} // support hover
      onClick={() => setShowSignModal(true)}
    >
      <OpenLinkIcon />
      Sign Document
    </div>
  );
  if (!documentsEnabled && documentsOverLimit)
    docLinkComponent = (
      <OverlayTrigger
        placement='bottom'
        overlay={
          <Tooltip>
            You have exceeded the monthly document limit for your plan. Document
            signing is disabled until next month. Consider upgrading.
          </Tooltip>
        }
      >
        <div>{docLinkComponent}</div>
      </OverlayTrigger>
    );

  return (
    <>
      <OverlayTrigger
        placement='bottom-end'
        trigger='click'
        rootClose
        overlay={
          <div className='share-modal share-document'>
            <div className='share-body'>
              {!isDocx && (
                <div className='share-link-container'>{docLinkComponent}</div>
              )}
              <div className='share-link-container'>
                <div
                  className='share-link'
                  onClick={() => setShowTriggerModal(true)}
                >
                  <OpenLinkIcon />
                  Set Autofill Trigger
                </div>
              </div>
            </div>
          </div>
        }
      >
        {children}
      </OverlayTrigger>
      <SignDocumentModal
        show={showSignModal}
        close={() => setShowSignModal(false)}
      />
      <DocumentTriggerModal
        show={showTriggerModal}
        close={() => setShowTriggerModal(false)}
      />
    </>
  );
}
