import { getAsset } from '../../../../utils/themes';
import { newElement } from '../../../../utils/step';
import {
  AXIS,
  SIZE_UNITS,
  UNDO_TITLES,
  UNDO_TYPES
} from '../../../../utils/constants';
import { addViewportCellToast } from '../toast';
import { Element } from '../engine/models';
import { batch } from 'react-redux';
import { getModalPayload } from '../../utils';
import { TYPE_CONTAINER } from '../../../../utils/elements';
import { Cell, fit } from '../engine';
import { refreshNode } from '../../utils/gig';

export const isInputOrTextAreaSelected = () => {
  return (
    document.activeElement instanceof HTMLAreaElement ||
    document.activeElement instanceof HTMLInputElement
  );
};

export const onDropWrapper = (
  node: any,
  nodeAction: any,
  item: any,
  store: any,
  formBuilder: any,
  addToast: any
) => {
  // refresh nodes with their most recent values
  // nodes might be stale if they didn't need to rerender during the last gig update
  node = refreshNode(node) ?? {};
  const itemNode: any = item.node ? refreshNode(item?.node) ?? {} : item.node;

  if (!node.isEmpty && nodeAction === 'setElement') {
    return;
  }

  if (node && itemNode?.hasChildren?.()) {
    const targetNode = node;
    const movingNode = itemNode;

    // Do not allow subgrids to be dragged into themselves.
    if (targetNode.isDescendent(movingNode)) {
      return;
    }
  }

  const state = store.getState();
  const gig = node.getGig();
  let targetNode = node;

  const {
    fields: { hiddenFields },
    formBuilder: { servars, activeStepId, workingSteps, theme }
  } = state;

  const { setPanelDataWithUndoRedo, gigSetPosition, focusElement, wipeFocus } =
    formBuilder;

  const { operation, elementType, assetId } = item;

  if (elementType === TYPE_CONTAINER) {
    targetNode = node[nodeAction === 'setElement' ? 'add' : nodeAction]();
    if (elementType === 'container') targetNode.axis = AXIS.COL;
  } else if (
    itemNode &&
    !itemNode.hasChildren?.() &&
    itemNode?.isContainer &&
    operation === 'move'
  ) {
    if (targetNode.id === itemNode?.id) return; // Prevent dropping an empty container on itself
    // Handle moving empty containers
    gig.get(itemNode?.position).remove();
    targetNode = node[nodeAction](itemNode);
  } else {
    const asset = getAsset(theme, elementType, assetId);

    const fields = [...Object.values(servars)];
    if (hiddenFields) fields.concat([...hiddenFields]);

    let el = newElement({
      elementType,
      defaultProperties: FeatheryConfig.default_element_properties[elementType],
      asset: !asset ? undefined : asset,
      fields
    });

    if (
      itemNode?.isElement &&
      node?.isElement &&
      operation === 'move' &&
      node?.isImmediateSibling(itemNode, nodeAction, true)
    ) {
      return; // Prevent move operations that would result in the same positioning
    }

    if (itemNode?.isElement && operation === 'move') {
      el = itemNode?.element.getRaw();
    }

    if (!itemNode || !itemNode?.hasChildren?.()) {
      (el as any)._type = elementType;

      if (nodeAction === 'setElement' && !node.isEmpty) {
        return addToast(addViewportCellToast);
      }

      if (itemNode) {
        if (itemNode?.position.toString() === node.position.toString()) {
          return;
        } else {
          // Remove the node from the current gig
          gig.get(itemNode?.position).remove();
        }
      }

      if (nodeAction === 'setElement') {
        if (
          targetNode.width === SIZE_UNITS.FIT &&
          targetNode.height === SIZE_UNITS.FIT
        ) {
          node[nodeAction](new Element(el));
        } else {
          targetNode = node.add();
          targetNode.setElement(new Element(el));
        }
      } else {
        if (nodeAction === 'add') {
          targetNode = node[nodeAction]();
          targetNode.setElement(new Element(el));
        } else {
          targetNode = node[nodeAction](new Element(el));
        }
      }
    } else if (itemNode?.hasChildren?.()) {
      const copy = itemNode?.copy?.({ unique: true });
      itemNode?.remove?.();

      const clearProps: any[] = [];

      const warnAboutRepeatableClearing = () => {
        addToast({
          text: 'Container is no longer repeatable since its parent or child is repeatable.'
        });
      };

      if (nodeAction === 'setElement') {
        const isFit = node.width === fit && node.height === fit;
        const hasSameDimensions =
          node.width === copy.width && node.height === copy.height;

        if (node.hasStyles(true) || (!hasSameDimensions && !isFit)) {
          targetNode = node.add() as Cell;
        }

        // The moved/set container cannot be repeated if an ancestor is repeated
        // but if the immediate parent is repeated, the moved container will be
        // consumed so leave as repeated
        if (targetNode.hasRepeatingAncestor() && !targetNode.repeated) {
          clearProps.push('repeated');
          if (copy.repeated) warnAboutRepeatableClearing();
        }

        targetNode.consume(copy, { clearProps });
      } else {
        const newNode = node[nodeAction]();
        // An moved and added container cannot be repeated if an acestor is repeated
        if (newNode.hasRepeatingAncestor()) {
          clearProps.push('repeated');
          if (copy.repeated) warnAboutRepeatableClearing();
        }
        newNode.consume(copy, { clearProps });
      }
    }
  }

  const newWorkingSteps = JSON.parse(JSON.stringify(workingSteps));
  newWorkingSteps[activeStepId] = gig.toStep();

  batch(() => {
    setPanelDataWithUndoRedo({
      id: activeStepId,
      oldValue: workingSteps[activeStepId],
      newValue: newWorkingSteps[activeStepId],
      title:
        operation === 'move' ? UNDO_TITLES.FORMAT : UNDO_TITLES.NEW_ELEMENT,
      type: UNDO_TYPES.STEP,
      workingSteps: newWorkingSteps
    });

    if (targetNode.isElement) {
      const element = targetNode.element.getRaw();

      focusElement(
        getModalPayload({
          element: element,
          elementType: element._type
        })
      );
    } else {
      wipeFocus();
    }

    gigSetPosition(targetNode.position);
  });
};
