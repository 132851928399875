import { useEffect, useState } from 'react';

import { Collapse } from 'react-bootstrap';
import { ChevronDownIcon } from '../../Icons';
import styles from './styles.module.scss';
import classNames from 'classnames';

export default function CollapsibleSection({
  title = '',
  expanded = true,
  collapsible = true,
  separator = true,
  resetHeaderStyle = false,
  showLabel = false,
  highlighted = false,
  hasAssets = false,
  isAssetSelected = false,
  customClasses = {},
  mouseDownTheme = () => {},
  style = {},
  children,
  isReversed = false,
  iconOnLeft = false,
  iconSize = 16
}: any) {
  const [isExpanded, setIsExpanded] = useState(expanded);

  useEffect(() => {
    setIsExpanded(expanded);
  }, [expanded]);

  const onMouseDown = (valid: boolean) => (event: any) => {
    if (!valid) return;

    event.stopPropagation();
    mouseDownTheme();
    if (collapsible) setIsExpanded(!isExpanded);
  };

  function renderIcon() {
    return (
      <div
        className={classNames(
          styles.expandIndicator,
          customClasses.expandIndicator
        )}
        onMouseDown={onMouseDown(isExpanded)}
      >
        <ChevronDownIcon width={iconSize} height={iconSize} />
      </div>
    );
  }

  function renderLabel() {
    return (
      <>
        {title && (
          <div
            className={classNames(
              resetHeaderStyle && styles.customHeader,
              highlighted && styles.highlighted,
              isExpanded && hasAssets && styles.borderBottom,
              isAssetSelected && styles.addBg
            )}
          >
            <div
              aria-label='header'
              className={classNames(
                styles.header,
                !isExpanded && collapsible && styles.collapsible,
                resetHeaderStyle && styles.resetHeader,
                customClasses.header
              )}
              onMouseDown={onMouseDown(!isExpanded)}
            >
              {collapsible && iconOnLeft && renderIcon()}
              <div
                onMouseDown={onMouseDown(isExpanded)}
                className={collapsible ? styles.collapsibleLabelSection : ''}
              >
                <div className={classNames(styles.title, customClasses.title)}>
                  {title}
                </div>
                {showLabel && <div className={styles.label}>Primary</div>}
              </div>
              {collapsible && !iconOnLeft && renderIcon()}
            </div>
          </div>
        )}
      </>
    );
  }

  return (
    <div
      className={classNames(
        styles.collapsibleSection,
        isExpanded && styles.expanded,
        separator && styles.separator,
        customClasses.root
      )}
      style={style}
    >
      {!isReversed && renderLabel()}
      <Collapse in={isExpanded}>
        <div
          className={classNames(
            styles.content,
            resetHeaderStyle && styles.resetHeader,
            customClasses.content
          )}
          id='collapsible-section'
        >
          {children}
        </div>
      </Collapse>
      {isReversed && renderLabel()}
    </div>
  );
}
