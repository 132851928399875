import { PropsWithChildren } from 'react';
import { ViewportType } from '../engine/viewports';
import { calculateDimensions } from '../../utils';
import { Cell } from '../engine';

export type StepProps = PropsWithChildren & {
  formId?: string;
  onMouseLeave?: any;
  viewport?: ViewportType;
  node: Cell;
  initialStyle?: any;
};

export const Step = ({
  formId,
  onMouseLeave,
  viewport,
  node,
  children,
  initialStyle
}: StepProps) => {
  if (node.parent) return <>{children}</>;

  return (
    <div
      id={`form-${formId}`}
      onMouseLeave={onMouseLeave}
      style={{
        display: 'flex',
        position: 'relative',
        ...calculateDimensions(viewport, node),
        height: 'auto',
        width: 'auto',
        minWidth: '100%',
        maxWidth: '100%',
        backgroundColor: 'white',
        ...initialStyle
      }}
    >
      {children}
    </div>
  );
};
