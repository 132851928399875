import { isExpression } from '../utils';

export const getAllFieldsInAction = (action: IRuleAction): IRuleOperand[] => {
  const { parameters } = action;

  return parameters.filter(
    (parameter) => 'type' in parameter && parameter.type === 'field'
  ) as IRuleOperand[];
};

export const getAllFieldsInExpression = (expression: IRuleExpression) => {
  const fields: IRuleOperand[] = [];

  expression.operands.forEach((operand) => {
    if (isExpression(operand)) {
      fields.push(...getAllFieldsInExpression(operand as IRuleExpression));
    } else if (
      expression.operator !== 'action' &&
      (operand as IRuleOperand).type === 'field'
    ) {
      fields.push(operand as IRuleOperand);
    }
  });

  return fields;
};

export const getAllFieldsInCondition = (condition: IRuleCondition) => {
  const fields: IRuleOperand[] = [];

  for (const operand of condition.operands) {
    if (isExpression(operand)) {
      fields.push(...getAllFieldsInExpression(operand));
    } else {
      fields.push(...getAllFieldsInCondition(operand as IRuleCondition));
    }
  }

  return fields;
};

export const getAllFieldsInClause = (
  clause: IRuleClause,
  includeActions = false
) => {
  const fields: IRuleOperand[] = [];

  // Search for fields in the when condition
  if (clause.when) {
    fields.push(...getAllFieldsInCondition(clause.when));
  }

  // Search for fields in the actions
  if (includeActions) {
    for (const action of clause.actions) {
      fields.push(...getAllFieldsInAction(action));
    }
  }

  return fields;
};

export const getAllFieldsInBranch = (
  branch: IRuleBranch,
  includeActions = false
) => {
  const fields: IRuleOperand[] = [];

  for (const clause of branch.clauses) {
    fields.push(...getAllFieldsInClause(clause, includeActions));
  }

  return fields;
};

export const getAllFieldsInDSL = (dsl: IRuleDSL, includeActions = false) => {
  const fields: IRuleOperand[] = [];

  for (const branch of dsl.branches) {
    fields.push(...getAllFieldsInBranch(branch, includeActions));
  }

  return fields;
};

export const isNumeric = (str: string) => {
  const isFloat = (test: string) => {
    const floatRegex = /^-?\d+(?:[.,]\d*?)?$/;

    if (!floatRegex.test(test)) {
      return false;
    }

    if (isNaN(parseFloat(test))) {
      return false;
    }

    return true;
  };

  const isInt = (test: string) => {
    const intRegex = /^-?\d+$/;

    if (!intRegex.test(test)) {
      return false;
    }

    return (
      parseFloat(test) === parseInt(test, 10) && !isNaN(parseInt(test, 10))
    );
  };

  return isFloat(str) || isInt(str);
};
