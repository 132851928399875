import { FieldSelectorWithModal } from '../..';
import { Tooltip } from '../../../Core/Tooltip/Tooltip';

export function FieldReference() {
  return (
    <Tooltip content='Use values from fields by typing {{<FieldId>}}'>
      <div>
        <FieldSelectorWithModal
          placeholder='Field Reference'
          disableSelect
          copyFieldVariable
          inFormBuilder
        />
      </div>
    </Tooltip>
  );
}
