import { useEffect } from 'react';
import { useKeyboardListener } from '../../hooks/useKeyboardListener';
import { useSyncedRefState } from '../../hooks/useSyncedRefState';
import { useClipboard } from '../../hooks/useClipboard';
import { useSelectedCell } from '../../hooks/useSelectedCell';
import { getOS } from '../../utils/browserUtils';
import { isInputOrTextAreaSelected } from '../RenderingEngine/GridInGrid/components/utils';
import useGridMode from '../../hooks/useGridMode';
import useFeatheryRedux from '../../redux';
import useViewport from '../../hooks/useViewport';
import { useAppSelector } from '../../hooks';

const CONTROL_KEY = getOS() === 'MacOS' ? 'Meta' : 'Control';

const GRID_MODE_SHORTCUTS = [`${CONTROL_KEY}+KeyG`] as const;
const COPY_SHORTCUT = `${CONTROL_KEY}+KeyC` as const;
const PASTE_SHORTCUT = `${CONTROL_KEY}+KeyV` as const;
const UNFORMATTED_PASTE_SHORTCUT = `${CONTROL_KEY}+Shift+KeyV` as const;
const UNDO_SHORTCUT = `${CONTROL_KEY}+KeyZ` as const;
const REDO_SHORTCUT = `${CONTROL_KEY}+Shift+KeyZ` as const;
const DELETE_KEYS = ['Backspace', 'Delete'] as const;
const DESKTOP_ONLY_SHORTCUTS = [
  COPY_SHORTCUT,
  PASTE_SHORTCUT,
  UNFORMATTED_PASTE_SHORTCUT,
  ...DELETE_KEYS
] as const;

const KEYS = [
  [CONTROL_KEY, 'KeyG'],
  [CONTROL_KEY, 'KeyC'],
  [CONTROL_KEY, 'KeyV'],
  [CONTROL_KEY, 'Shift', 'KeyV'],
  [CONTROL_KEY, 'KeyZ'],
  [CONTROL_KEY, 'Shift', 'KeyZ'],
  'Escape',
  ...DELETE_KEYS
] as const;

const Keybinds = () => {
  const {
    formBuilder: { gigSetPosition, handleRedo, handleUndo, wipeFocus },
    toasts: { addInfoToast }
  } = useFeatheryRedux();

  const getState = useSyncedRefState({
    isModalOpen: useAppSelector((s) => s.formBuilder.isModalOpen),
    gridMode: useGridMode(),
    clipboard: useClipboard(),
    selectedCell: useSelectedCell(),
    viewport: useViewport(),
    undoRedo: {
      handleRedo,
      handleUndo
    }
  });

  const getTextSelection = useSyncedRefState(
    useAppSelector((state) => state.formBuilder.textSelection)
  );

  const { onKeyup, onKeypress } = useKeyboardListener({
    keys: KEYS,
    ignore: () => {
      const isInputSelected =
        isInputOrTextAreaSelected() || getTextSelection() !== null;
      const isBodyFocused = (document as any).activeElement.nodeName === 'BODY';
      const isTextHighlighted = window.getSelection()?.type === 'Range';
      const isModalOpen = getState().isModalOpen;

      return (
        isInputSelected || !isBodyFocused || isTextHighlighted || isModalOpen
      );
    },
    preventDefault: true
  } as any);

  useEffect(() => {
    onKeypress(({ shortcut }: any) => {
      const { undoRedo, gridMode } = getState();

      if (shortcut === UNDO_SHORTCUT) {
        undoRedo.handleUndo();
      } else if (shortcut === REDO_SHORTCUT) {
        undoRedo.handleRedo();
      } else if (GRID_MODE_SHORTCUTS.includes(shortcut))
        gridMode.toggleGridMode();
    });

    onKeyup(({ shortcut, isClicked }: any) => {
      const {
        clipboard,
        selectedCell,
        viewport: { isMobile }
      } = getState();

      if (isMobile && DESKTOP_ONLY_SHORTCUTS.includes(shortcut)) {
        addInfoToast('Please switch to Desktop view to edit your form layout.');
      } else if (shortcut === COPY_SHORTCUT) {
        clipboard.copy();
      } else if (shortcut === PASTE_SHORTCUT) {
        clipboard.paste();
      } else if (shortcut === UNFORMATTED_PASTE_SHORTCUT) {
        if (clipboard.clipboard && !clipboard.clipboard.isElement) {
          clipboard.paste(false);
        }
      } else if (shortcut === 'Escape' && isClicked) {
        wipeFocus();
        gigSetPosition(null);
      } else if (DELETE_KEYS.includes(shortcut) && isClicked) {
        selectedCell.remove();
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export default Keybinds;
