import { isCondition, isEmptyDSL } from '../utils';
import {
  CALENDLY_ACTION,
  CONNECT_TO_API,
  EMAIL_ACTION,
  NAVIGATE_TO_STEP_ACTION,
  OPEN_URL_ACTION,
  SET_ACTION,
  SET_ERROR_ACTION,
  SMS_CODE_ACTION,
  EMAIL_CODE_ACTION,
  TELESIGN_VOICE_ACTION,
  EXPRESSION_OPERATORS,
  TELESIGN_SMS_ACTION,
  AI_EXTRACTION_ACTION,
  RUN_INTEGRATION_ACTION
} from '../components/RuleAction/constants';
import { fieldContext } from '../../RuleCodeEditor/featheryDefs';

// @ts-expect-error This esm version is needed to avoid bundling all parsers and bundle bloat
import prettier from 'prettier/esm/standalone.mjs';
import prettierEspree from 'prettier/parser-espree';
import { isValidJsIdentifier } from '../../RuleCodeEditor/utils';
import { isNumeric } from './utils';
import { NUMBER_FIELDS } from '../../../../../components/SelectionPanel/elementEntries';

const COMPARISON_FUNCTIONS: { [key: string]: string } = {
  equal: 'equals',
  not_equal: 'notEquals',
  equal_ignore_case: 'equalsIgnoreCase',
  not_equal_ignore_case: 'notEqualsIgnoreCase',
  selections_include: 'selectionsInclude',
  selections_dont_include: 'selectionsDoNotInclude',
  is_filled: 'isFilled',
  is_empty: 'isEmpty',
  greater_than: 'greaterThan',
  greater_than_or_equal: 'greaterThanOrEqual',
  less_than: 'lessThan',
  less_than_or_equal: 'lessThanOrEqual',
  is_numerical: 'isNumerical',
  is_text: 'isText',
  contains: 'contains',
  not_contains: 'doesNotContain',
  contains_ignore_case: 'containsIgnoreCase',
  not_contains_ignore_case: 'doesNotContainIgnoreCase',
  starts_with: 'startsWith',
  not_starts_with: 'doesNotStartWith',
  ends_with: 'endsWith',
  not_ends_with: 'doesNotEndWith',
  is_true: 'isTrue',
  is_false: 'isFalse'
};

export const PRIVATE_FUNCTIONS: { [key: string]: string } = {
  [SMS_CODE_ACTION]: '_sendSmsCode',
  [EMAIL_CODE_ACTION]: '_sendEmailOTP',
  [TELESIGN_VOICE_ACTION]: '_telesignVoice',
  [TELESIGN_SMS_ACTION]: '_telesignSms',
  [EMAIL_ACTION]: '_sendEmail'
};

const hasParameters = (comparisonFunction: string) => {
  const fnSignature = (fieldContext as any)[comparisonFunction];

  if (!fnSignature || fnSignature.indexOf('fn') < 0) {
    return false;
  }

  return fnSignature.match(/\(([^)]+)\)/)?.[1].indexOf('value') >= 0;
};

const getFieldKey = (operand: IRuleOperand) => {
  if (operand.type !== 'field') {
    return '';
  }

  return operand.meta?.field_key ?? operand.value;
};

const field = (operand: IRuleOperand) => {
  const key = getFieldKey(operand);

  if (isValidJsIdentifier(key)) {
    return key;
  }

  return `feathery.fields["${key}"]`;
};

const escapeValue = (val: string) => {
  return val.replaceAll('"', '\\"');
};

const formatValue = (val: any, formatNumbers = true): any => {
  // Array
  if (Array.isArray(val)) {
    return `[${val
      .map((arrVal: any) => `"${escapeValue(arrVal)}"`)
      .join(', ')}]`;
  }

  // Number
  if (isNumeric(val) && formatNumbers) {
    return val;
  }

  // Boolean
  if (val === 'true' || val === 'false') {
    return val;
  }

  // String
  if (typeof val === 'string') {
    return `"${escapeValue(val)}"`;
  }

  return val;
};

export const transformSetAction = ({ parameters }: IRuleAction) => {
  const leftOperand: IRuleOperand = parameters[0];
  const leftOperandType = leftOperand?.meta?.servar_type;
  return `${field(parameters[0])}.value = ${_transformParameter(
    leftOperandType,
    parameters[1],
    true
  )};`;
};

const _transformParameter = (
  leftOperandType: string,
  parameter: IRuleOperand | IRuleExpression,
  topLevel = false
): string => {
  if ('type' in parameter) {
    // IRuleOperand
    const operand = parameter as IRuleOperand<any>;
    let rightVal = operand.value;
    if (operand.type === 'field') {
      return `${field(operand)}.value`;
    } else {
      const formatNumbers = NUMBER_FIELDS.includes(leftOperandType);
      if (leftOperandType === 'button_group' && !Array.isArray(rightVal))
        rightVal = [rightVal];
      return `${formatValue(rightVal, formatNumbers)}`;
    }
  } else {
    // IRuleExpression
    const openParen = topLevel ? '' : '(';
    const closeParen = topLevel ? '' : ')';
    return `${openParen}${_transformParameter(
      leftOperandType,
      parameter.operands[0]
    )} ${
      EXPRESSION_OPERATORS[parameter.operator].emittedCode
    } ${_transformParameter(
      leftOperandType,
      parameter.operands[1]
    )}${closeParen}`;
  }
};

export const transformOpenURLAction = (action: IRuleAction) => {
  return `await feathery.openUrl("${
    (action.parameters[0] as IRuleOperand).value
  }", "${(action.parameters[1] as IRuleOperand).value}");`;
};

export const transformNavigateToStepAction = (action: IRuleAction) => {
  return `feathery.goToStep("${escapeValue(
    action.parameters[0].meta?.step_key ?? action.parameters[0].value
  )}");`;
};

export const transformConnectToAPIActions = (actions: IRuleAction[]) => {
  if (!actions.length) return '';

  const connectCodes = actions.map(
    (action) => `feathery.http.connect("${action.parameters[0].value}")`
  );
  const connectCode =
    connectCodes.length === 1
      ? connectCodes[0]
      : `Promise.all([${connectCodes.join(',\n')}])`;
  return `await ${connectCode};`;
};

export const transformSetErrorAction = ({ parameters }: IRuleAction) => {
  return `${field(parameters[0])}.setError("${escapeValue(
    (parameters[1] as IRuleOperand).value
  )}");`;
};

export const transformCalendlyAction = ({ parameters }: IRuleAction) => {
  return `feathery.setCalendlyUrl("${escapeValue(parameters[0].value)}");`;
};

export const transformSmsCodeAction = ({ parameters }: IRuleAction) => {
  return `await feathery.${PRIVATE_FUNCTIONS[SMS_CODE_ACTION]}(${escapeValue(
    parameters[0].meta.field_key
  )}.value);`;
};

export const transformEmailCodeAction = ({ parameters }: IRuleAction) => {
  return `await feathery.${PRIVATE_FUNCTIONS[EMAIL_CODE_ACTION]}(${escapeValue(
    parameters[0].meta.field_key
  )}.value);`;
};

export const transformTelesignVoiceAction = ({ parameters }: IRuleAction) => {
  return `await feathery.${PRIVATE_FUNCTIONS[TELESIGN_VOICE_ACTION]}(${field(
    parameters[0]
  )}.value);`;
};

export const transformTelesignSmsAction = ({ parameters }: IRuleAction) => {
  return `await feathery.${PRIVATE_FUNCTIONS[TELESIGN_SMS_ACTION]}(${field(
    parameters[0]
  )}.value);`;
};

export const transformEmailAction = ({ parameters }: IRuleAction) => {
  return `await feathery.${PRIVATE_FUNCTIONS[EMAIL_ACTION]}(${JSON.stringify(
    escapeValue(parameters[0].meta.id)
  )})`;
};

export const transformAIExtractionAction = ({ parameters }: IRuleAction) => {
  return `await feathery.runAIExtraction("${parameters[0]?.value}", ${
    (parameters[1] as IRuleOperand)?.value || 'false'
  });`;
};

export const transformRunIntegrationAction = ({ parameters }: IRuleAction) => {
  const integrations = parameters[0].value.split(',');
  let integrationParam = `"${integrations[0]}"`;

  let options: any = {};
  try {
    options = JSON.parse((parameters[1] as IRuleOperand).value);
  } catch (err) {
    // Do nothing
  }

  if (integrations.length > 1) {
    integrationParam = `[${parameters[0].value
      .split(',')
      .map((val: string) => `"${val}"`)
      .join(', ')}]`;

    options.multiple = true;
  }

  return `await feathery.runIntegrationActions(${integrationParam}, ${JSON.stringify(
    options
  )});`;
};

export const transformAction = (action: IRuleAction) => {
  switch (action.name) {
    case SET_ACTION:
      return transformSetAction(action);

    case OPEN_URL_ACTION:
      return transformOpenURLAction(action);

    case NAVIGATE_TO_STEP_ACTION:
      return transformNavigateToStepAction(action);

    case SET_ERROR_ACTION:
      return transformSetErrorAction(action);

    case CALENDLY_ACTION:
      return transformCalendlyAction(action);

    case SMS_CODE_ACTION:
      return transformSmsCodeAction(action);

    case EMAIL_CODE_ACTION:
      return transformEmailCodeAction(action);

    case TELESIGN_VOICE_ACTION:
      return transformTelesignVoiceAction(action);

    case TELESIGN_SMS_ACTION:
      return transformTelesignSmsAction(action);

    case EMAIL_ACTION:
      return transformEmailAction(action);

    case AI_EXTRACTION_ACTION:
      return transformAIExtractionAction(action);

    case RUN_INTEGRATION_ACTION:
      return transformRunIntegrationAction(action);

    default:
      return '';
  }
};

export const transformExpression = (expression: IRuleExpression) => {
  const { operator, operands } = expression;
  let code = '';

  let formatNumbers = false;
  const leftOperand = operands[0];
  const leftOperandType = (leftOperand as IRuleOperand)?.meta?.servar_type;

  if (NUMBER_FIELDS.includes(leftOperandType)) {
    formatNumbers = true;
  }

  const comparisonFunction = COMPARISON_FUNCTIONS[operator];
  const parameters = (operands.slice(1) as IRuleOperand[]).map((operand) => {
    if (operand.type === 'field') {
      return `${field(operand)}.value`;
    } else {
      return formatValue(operand.value, formatNumbers);
    }
  });

  code += `${field(operands[0] as IRuleOperand)}.${comparisonFunction}(`;

  if (hasParameters(comparisonFunction)) {
    code += `${parameters.join(', ')})`;
  } else {
    code += ')';
  }

  return code;
};

export const transformCondition = (condition: IRuleCondition) => {
  const { operator, operands } = condition;
  const op = operator === 'and' ? '&&' : '||';
  let code = '';

  for (let i = 0; i < operands.length; i++) {
    const operand = operands[i];

    if (isCondition(operand)) {
      code += `(${transformCondition(operand as IRuleCondition)})`;
    } else {
      code += `${transformExpression(operand)}`;
    }

    if (i < operands.length - 1) {
      code += ` ${op} `;
    }
  }

  return code;
};

const transformClause = (clause: IRuleClause) => {
  let code = '';

  let connectActions: IRuleAction[] = [];
  clause.actions.forEach((action) => {
    if (action.name === CONNECT_TO_API) connectActions.push(action);
    else {
      if (connectActions.length) {
        code += `  ${transformConnectToAPIActions(connectActions)}\n`;
        connectActions = [];
      }
      code += `  ${transformAction(action)}\n`;
    }
  });
  if (connectActions.length)
    code += `  ${transformConnectToAPIActions(connectActions)}\n`;

  return code;
};

export const transformBranch = (branch: IRuleBranch) => {
  let code = '';

  branch.clauses.forEach((clause, index) => {
    let closebracket = false;
    if (clause.when) {
      if (index === 0) code += `if (`;
      else code += `else if (`;
      code += `${transformCondition(clause.when)}) {\n`;
      closebracket = true;
    }

    if (!clause.when && index > 0) {
      code += `else {\n`;
      closebracket = true;
    }

    code += `  ${transformClause(clause)}\n`;
    if (closebracket) code += `}\n`;
  });

  return code;
};

export const transformDSL = (dsl: IRuleDSL) => {
  let code = '';

  // Transform all branches
  dsl.branches.forEach((branch) => {
    code += `\n${transformBranch(branch)}\n`;
  });

  return code;
};

export const transformToCode = async (dsl: IRuleDSL) => {
  let transformedCode = '';

  if (isEmptyDSL(dsl)) {
    return '// Javascript rule code';
  }

  try {
    transformedCode = transformDSL(dsl);
  } catch (err) {
    return '// Invalid rule logic';
  }

  const asyncWrapCode = (code: string) =>
    `(async () => {
      ${code}
    })()`;

  const unwrapCode = (code: string) =>
    code
      .split('\n')
      // remove any trailing empty line
      .filter(
        (line: string, index: number, lines) =>
          index < lines.length - 1 ||
          (index === lines.length - 1 && line.trim().length)
      )
      .map((line: string, index: number, lines) => {
        if (index === 0) {
          return line.replace(/(\(async\s*\(\)\s*=>\s*\{)/, '');
        } else if (index === lines.length - 1) {
          return line.replace(/(\}\)\(\);)/, '');
        } else {
          return line;
        }
      })
      .filter((line: string, index: number, lines) => {
        if (
          (index === 0 && !line.trim().length) ||
          (index === lines.length - 1 && !line.trim().length)
        ) {
          return false;
        }
        return true;
      })
      .map((line) => line.replace(/^ {2}/, ''))
      .join('\n');

  const prettyCode: string = await prettier.format(
    asyncWrapCode(transformedCode),
    {
      parser: 'espree',
      plugins: [prettierEspree],
      semi: true,
      singleQuote: true,
      arrowParens: 'always',
      useTabs: false,
      tabWidth: 2,
      bracketSpacing: true,
      trailingComma: 'none'
    }
  );
  return unwrapCode(prettyCode);
};
