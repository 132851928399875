import { memo } from 'react';

import AddressFieldSection from './AddressFieldSection';
import {
  CollapsibleSection,
  DropdownField,
  PropertyLabel,
  TextField
} from '../../Core';
import { FieldSelectorWithModal } from '../../Modals';
import classNames from 'classnames';
import styles from '../styles.module.scss';

export function CustomerFieldMapping({
  stripeConfig = {},
  setStripeConfig = () => {},
  label,
  fieldTag,
  mappingObjKey = 'customer_field_mappings',
  hideLabel = false
}: any) {
  return (
    <div className={classNames(styles.fieldMapping, styles.marginBottom)}>
      {!hideLabel && <PropertyLabel label={label} />}
      <FieldSelectorWithModal
        className={classNames(styles.fieldSelectorButton, 'test-target')}
        selectId={stripeConfig.metadata[mappingObjKey]?.[fieldTag]?.id}
        selectType={stripeConfig.metadata[mappingObjKey]?.[fieldTag]?.type}
        placeholder='Select'
        onSelect={({ selectId, selectType }) => {
          const newStripeConfig = {
            ...stripeConfig,
            metadata: {
              ...stripeConfig.metadata,
              [mappingObjKey]: {
                ...stripeConfig.metadata[mappingObjKey]
              }
            }
          };
          // support removing the mapping as well
          delete newStripeConfig.metadata[mappingObjKey][fieldTag];
          if (selectId)
            newStripeConfig.metadata[mappingObjKey][fieldTag] = {
              id: selectId,
              type: selectType
            };
          setStripeConfig(newStripeConfig);
        }}
      />
    </div>
  );
}

function StripeFieldSettings({
  stripeConfig = {},
  setStripeConfig = () => {},
  showFieldErrors = false
}: {
  stripeConfig: any;
  setStripeConfig: (config: any) => void;
  showFieldErrors?: boolean;
}) {
  const metadata = stripeConfig.metadata;
  return (
    <>
      <CollapsibleSection
        title='Customer Lookup (Optional)'
        collapsible
        expanded={false}
      >
        <p className={classNames('text-muted', styles.helperText)}>
          Use a user field value to find an existing customer in Stripe. If no
          customer can be found, a new one is created with the value saved.
        </p>
        <div
          className={classNames(
            styles.fieldMapping,
            styles.marginBottom,
            styles.paddingRight
          )}
        >
          <PropertyLabel label='Stripe Lookup Attribute' />
          <DropdownField
            selected={metadata.customer_id_type || 'stripe'}
            onChange={(e: any) => {
              setStripeConfig({
                ...stripeConfig,
                ...{
                  metadata: {
                    ...stripeConfig.metadata,
                    customer_id_type: e.target.value,
                    id_metadata_key:
                      e.target.value === 'custom'
                        ? metadata.id_metadata_key
                        : ''
                  }
                }
              });
            }}
            style={{ width: 'auto' }}
            options={[
              { value: 'stripe', display: 'Customer ID' },
              { value: 'custom', display: 'Custom' }
            ]}
          />
        </div>

        <div className={classNames(styles.fieldMapping, styles.marginLeft)}>
          <span
            className={classNames(styles.fieldHeaderText, styles.colHeader)}
          >
            Stripe Attribute
          </span>
          <span
            className={classNames(
              styles.fieldHeaderText,
              styles.colHeader,
              styles.left
            )}
          >
            Field
          </span>
        </div>

        {(!metadata.customer_id_type ||
          metadata.customer_id_type === 'stripe') && (
          <div
            className={classNames(
              styles.fieldMapping,
              styles.marginBottom,
              styles.validation
            )}
          >
            <a
              className={styles.labelLink}
              href={`https://stripe.com/docs/api/customers`}
              target='_blank'
              rel='noreferrer'
            >
              Stripe Customer ID
            </a>

            <CustomerFieldMapping
              stripeConfig={stripeConfig}
              setStripeConfig={setStripeConfig}
              label='Stripe Customer ID'
              hideLabel
              fieldTag='id'
            />
          </div>
        )}
        {metadata.customer_id_type === 'custom' && (
          <div
            className={classNames(
              styles.fieldMapping,
              styles.marginBottom,
              styles.validation
            )}
            style={{ alignItems: 'flex-start' }}
          >
            <div className={styles.validatedField}>
              <TextField
                className={classNames('dialog-form-input', styles.fieldText)}
                placeholder='Enter custom'
                value={stripeConfig.metadata.id_metadata_key}
                onChange={(value: any) =>
                  setStripeConfig({
                    ...stripeConfig,
                    ...{
                      metadata: {
                        ...stripeConfig.metadata,
                        id_metadata_key: value
                      }
                    }
                  })
                }
                error={
                  showFieldErrors &&
                  !metadata.id_metadata_key &&
                  metadata.customer_field_mappings.id
                }
              />
            </div>
            <CustomerFieldMapping
              stripeConfig={stripeConfig}
              setStripeConfig={setStripeConfig}
              label='Customer ID Field'
              hideLabel
              fieldTag='id'
            />
          </div>
        )}
      </CollapsibleSection>
      <CollapsibleSection
        title='Customer Fields (Optional)'
        collapsible
        expanded={false}
      >
        <p className={classNames('text-muted', styles.helperText)}>
          Data from the customer fields will be used when creating customer
          records in Stripe.
        </p>
        <CustomerFieldMapping
          stripeConfig={stripeConfig}
          setStripeConfig={setStripeConfig}
          label='Customer Name Field'
          fieldTag='name'
        />
        <CustomerFieldMapping
          stripeConfig={stripeConfig}
          setStripeConfig={setStripeConfig}
          label='Customer Description Field'
          fieldTag='description'
        />
        <CustomerFieldMapping
          stripeConfig={stripeConfig}
          setStripeConfig={setStripeConfig}
          label='Customer Email Field'
          fieldTag='email'
        />
        <CustomerFieldMapping
          stripeConfig={stripeConfig}
          setStripeConfig={setStripeConfig}
          label='Customer Phone Field'
          fieldTag='phone'
        />
        <AddressFieldSection
          stripeConfig={stripeConfig}
          setStripeConfig={setStripeConfig}
          title='Customer Address (Optional)'
          collapsible
          addressFieldTag='address'
        />
        <AddressFieldSection
          stripeConfig={stripeConfig}
          setStripeConfig={setStripeConfig}
          title='Customer Shipping Address (Optional)'
          collapsible
          addressFieldTag='shipping'
          includeNamePhone
          extraLevel='address'
        />
      </CollapsibleSection>
    </>
  );
}

export default memo(StripeFieldSettings);
