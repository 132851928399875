export default function UnlinkIcon({
  width = 32,
  height = 32,
  color = '#A3AABA'
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6 6L27 27'
        stroke={color}
        strokeWidth='3'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.3664 24.1833L14.2901 26.2596C11.9695 28.5802 8.06107 28.5802 5.74046 26.2596C3.41985 23.939 3.41985 20.0306 5.74046 17.71L8.06107 15.3894'
        stroke={color}
        strokeWidth='3'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.0229 8.54962L17.8321 5.74046C20.1527 3.41985 24.0611 3.41985 26.3817 5.74046C28.7023 8.06107 28.7023 11.9695 26.3817 14.2901L23.8168 16.855'
        stroke={color}
        strokeWidth='3'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.6642 12.458L12.4581 19.6641'
        stroke={color}
        strokeWidth='3'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
