function Segment({ width = 64, height = 64 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 1024 1024'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='512' cy='512' r='512' fill='#52bd94' />
      <path
        d='M346.8 654.5c-16 0-29 13-29 29s13 29 29 29 29-13 29-29-13-29-29-29zM666.7 304c-16 0-29.1 12.9-29.1 29 0 16 12.9 29.1 29 29.1 16 0 29.1-12.9 29.1-29v-.1c0-16-13-29-29-29zM456.6 421.2v35.6c0 5.3 4.3 9.7 9.7 9.7h287.4c5.3 0 9.7-4.3 9.7-9.7v-35.6c0-5.3-4.4-9.7-9.7-9.7H466.3c-5.4.1-9.7 4.4-9.7 9.7zm110.6 164.1v-35.5c0-5.3-4.3-9.7-9.7-9.7H270.1c-5.3 0-9.7 4.3-9.7 9.7v35.5c0 5.3 4.3 9.7 9.7 9.7h287.4c5.4 0 9.7-4.3 9.7-9.7zm194-47.8c-1.6-2-3.8-3.3-6.4-3.6l-35.3-3.6c-5.2-.6-9.9 3.1-10.6 8.3-14.8 110.2-116.3 187.6-226.5 172.7-15.3-2.1-30.3-5.9-44.7-11.3-4.9-1.9-10.4.4-12.3 5.3L411.9 738c-1 2.4-1 5.1 0 7.5 1 2.4 3 4.4 5.5 5.3 131.9 50.9 280.2-14.8 331.1-146.7 7.4-19.1 12.4-39 15-59.3.3-2.7-.5-5.3-2.3-7.3zm-498.7-81.6c-1.4-2.2-1.8-4.9-1.1-7.4 29-113.3 131.1-192.5 248-192.4 28.6 0 57.1 4.7 84.1 14.2 5 1.6 7.8 7 6.2 12-.1.2-.2.5-.3.7l-12.7 33.2c-1.8 4.9-7.2 7.5-12.1 5.7-21-7.2-43.1-10.8-65.3-10.8-46-.2-90.6 15.5-126.3 44.5-33.5 27.1-57.4 64.2-68.4 105.8-1.1 4.2-4.9 7.2-9.3 7.2-.7.1-1.5.1-2.2 0l-34.6-7.9c-2.6-.8-4.7-2.5-6-4.8z'
        fill='#fff'
      />
    </svg>
  );
}

export default Segment;
