import styles from './styles.module.scss';

type Props = {
  icon?: JSX.Element;
  label: string;
  value: JSX.Element | string | number;
};

const SummaryStatistic = ({ icon, label, value }: Props) => {
  return (
    <div className={styles.summaryStatisticContainer}>
      {icon}
      <div className={styles.summaryStatisticColumn}>
        <span className={styles.summaryStatisticLabel}>{label}</span>
        <span className={styles.summaryStatisticValue}>{value}</span>
      </div>
    </div>
  );
};

export default SummaryStatistic;
