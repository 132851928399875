import { PropsWithChildren, forwardRef, useState } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { CaretIcon } from '../Icons';

type ClassNameFn = (hidden: boolean) => string;
type ClassNameType = string | ClassNameFn;

type SidePanelProps = PropsWithChildren<{
  width?: number;
  position?: 'left' | 'right';
  classNames?: {
    sidePanel?: ClassNameType;
    content?: ClassNameType;
    sidebarContent?: ClassNameType;
    panelCollapse?: ClassNameType;
  };
}>;

const SidePanel = forwardRef<HTMLDivElement, SidePanelProps>(
  (
    {
      children,
      position = 'left',
      classNames: _classNames = {}
    }: SidePanelProps,
    ref
  ) => {
    const [hidden, setHidden] = useState(false);
    const classes: any = Object.entries(_classNames).reduce(
      (acc: any, [key, value]: any) => {
        if (typeof value === 'function') {
          acc[key] = value(hidden);
        }

        if (typeof value === 'string') {
          acc[key] = value;
        }

        return acc;
      },
      {}
    );

    return (
      <div
        className={classNames(styles.sidePanel, classes.sidePanel, {
          [styles.left]: position === 'left',
          [styles.right]: position === 'right',
          [styles.slideLeft]: hidden && position === 'left',
          [styles.slideRight]: hidden && position === 'right'
        })}
      >
        <div className={classNames(styles.content, classes.content)}>
          <div
            className={classNames({
              [styles.leftCollapse]: position === 'left',
              [styles.rightCollapse]: position === 'right'
            })}
          >
            <div
              className={classNames(
                styles.panelCollapse,
                classes.panelCollapse
              )}
              onClick={() => setHidden(!hidden)}
            >
              <CaretIcon
                className={classNames(styles.caretIcon, !hidden && styles.open)}
              />
            </div>
          </div>
          <div
            className={classNames(
              styles.sidebarContent,
              classes.sidebarContent
            )}
            ref={ref}
          >
            {children}
          </div>
        </div>
      </div>
    );
  }
);

SidePanel.displayName = 'SidePanel';

export default SidePanel;
