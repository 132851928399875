import { createSlice } from '@reduxjs/toolkit';
import { objectApply } from '../utils/core';
import { InfoIcon, WarningIcon } from '../components/Icons';

const DEFAULT_TOAST_PROPS = {
  decay: 3000,
  dismissible: true,
  show: true
};

type ToastProps = {
  title?: string;
  body?: string;
  text?: string;
};

function ToastBody({ title, body, text }: ToastProps) {
  if (title && body) {
    return (
      <>
        <strong>{title}</strong>
        <br />
        {body}
      </>
    );
  } else if (title) {
    return <strong>{title}</strong>;
  } else if (body || text) {
    return <>{body || text}</>;
  } else return null;
}

function addInternalToast(state: any, payload: any) {
  const id = Object.keys(state).length + 1;

  // In Redux, we can't store components (text is a string/component, icon is a component)
  // BUT! We can store a function which returns a component
  state[id] = objectApply(DEFAULT_TOAST_PROPS, payload, {
    toastBody: () => (
      <ToastBody
        title={payload.title}
        body={payload.body}
        text={payload.text}
      />
    ),
    icon: () => payload.icon
  });
}

const toastsSlice = createSlice({
  name: 'toasts',
  initialState: {},
  reducers: {
    addToast(state: any, action: any) {
      addInternalToast(state, action.payload);
    },
    addInfoToast(state: any, action: any) {
      addInternalToast(state, {
        dismissable: false,
        decay: 3000,
        text: action.payload,
        icon: <InfoIcon />
      });
    },
    addErrorToast(state: any, action: any) {
      if (!action.payload.decay) action.payload.decay = 6000;
      action.payload.icon = <WarningIcon />;
      addInternalToast(state, action.payload);
    },
    hideToast(state: any, action) {
      if (state[action.payload.id]) {
        state[action.payload.id].show = false;
      }
    },
    removeToast(state: any, action) {
      delete state[action.payload.id];
    }
  }
});

export default toastsSlice.reducer;
export const { addToast, addInfoToast, addErrorToast, hideToast, removeToast } =
  toastsSlice.actions;
