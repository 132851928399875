function Pipedrive({ width = 64, height = 64 }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 3291 2532'
      fill='none'
    >
      <rect x='581' y='202' width='2128' height='2128' rx='317' fill='black' />
      <path
        d='M1754.26 561.84C1597.61 561.84 1506.78 632.914 1463.33 680.297C1458.07 638.179 1430.42 584.215 1322.48 584.215H1086.85V829.026H1182.94C1198.74 829.026 1204 834.291 1204 850.085V1970.16H1483.08V1520.02C1526.52 1559.51 1609.45 1614.79 1739.78 1614.79C2012.27 1614.79 2203.15 1398.93 2203.15 1088.31C2204.46 773.746 2022.8 561.84 1754.26 561.84ZM1697.65 1371.29C1547.58 1371.29 1479.13 1227.83 1479.13 1093.58C1479.13 882.99 1593.66 807.967 1701.6 807.967C1833.24 807.967 1922.76 921.159 1922.76 1090.95C1921.44 1285.74 1808.23 1371.29 1697.65 1371.29Z'
        fill='#FDFFFF'
      />
    </svg>
  );
}

export default Pipedrive;
