export default [
  {
    countryName: 'European Union',
    countryCode: 'EU',
    taxIdType: 'eu_vat',
    taxIdTypeDescription: 'VAT number',
    flag: '\ud83c\uddea\ud83c\uddfa'
  },
  {
    countryName: 'United Kingdom',
    countryCode: 'GB',
    taxIdType: 'gb_vat',
    taxIdTypeDescription: 'VAT number',
    flag: '\ud83c\uddec\ud83c\udde7'
  }
];
