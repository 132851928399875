import '../../../style/dialog-form.css';

import { memo } from 'react';

import { PropertyLabel, TextField } from '../../Core';
import classNames from 'classnames';
import styles from '../styles.module.scss';

function StripeKeySettings({
  stripeConfig = {},
  setStripeConfig = () => {}
}: any) {
  const meta = stripeConfig.metadata;
  const secretMeta = stripeConfig.secret_metadata;

  return (
    <>
      <PropertyLabel label='Test Publishable Key' />
      <TextField
        className={classNames(
          'dialog-form-input',
          styles.marginBottom,
          'pk_test'
        )}
        type='password'
        placeholder='pk_test_...'
        value={meta.test_client_key || ''}
        onChange={(value: any) =>
          setStripeConfig({
            ...stripeConfig,
            ...{ metadata: { ...meta, test_client_key: value } }
          })
        }
      />
      <PropertyLabel label='Test Secret Key' />
      <TextField
        className={classNames(
          'dialog-form-input',
          styles.marginBottom,
          'sk_test'
        )}
        type='password'
        placeholder='sk_test_...'
        value={secretMeta.test_secret_key || ''}
        onChange={(value: any) =>
          setStripeConfig({
            ...stripeConfig,
            ...{
              secret_metadata: { ...secretMeta, test_secret_key: value }
            }
          })
        }
      />
      <PropertyLabel label='Live Publishable Key' />
      <TextField
        className={classNames(
          'dialog-form-input',
          styles.marginBottom,
          'pk_prod'
        )}
        type='password'
        placeholder='pk_live_...'
        value={meta.prod_client_key || ''}
        onChange={(value: any) =>
          setStripeConfig({
            ...stripeConfig,
            ...{ metadata: { ...meta, prod_client_key: value } }
          })
        }
      />
      <PropertyLabel label='Live Secret Key' />
      <TextField
        className={classNames(
          'dialog-form-input',
          styles.marginBottom,
          'sk_prod'
        )}
        type='password'
        placeholder='sk_live_...'
        value={secretMeta.prod_secret_key || ''}
        onChange={(value: any) =>
          setStripeConfig({
            ...stripeConfig,
            ...{ secret_metadata: { ...secretMeta, prod_secret_key: value } }
          })
        }
      />
    </>
  );
}

export default memo(StripeKeySettings);
