import { memo } from 'react';
import styles from './styles.module.scss';
import modalStyles from '../../Modals/ImageUploadModal/styles.module.scss';
import ImageThumbnail from './ImageThumbnail';
import { TrashIcon } from '../../Icons';
import { useAppSelector } from '../../../hooks';
import classNames from 'classnames';

function DisplayImage({ imageId = '', onDelete = () => {} }) {
  const currentImage = useAppSelector((state) => state.images)[imageId];

  return currentImage ? (
    <div className={styles.imageFieldContainer}>
      <div className={classNames(styles.imageFieldCard, styles.onlyDisplay)}>
        <ImageThumbnail
          fileUrl={currentImage.file}
          fileType={currentImage.type}
          height={100}
          width={100}
        />
        <div className={modalStyles.thumbnailLabel}>
          <span className={modalStyles.thumbnailLabelText}>
            {currentImage.name}
          </span>
          <div
            className={modalStyles.cardDeleteContainer}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onDelete();
            }}
          >
            <TrashIcon
              width={20}
              height={20}
              className={modalStyles.cardDelete}
            />
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

export default memo(DisplayImage);
