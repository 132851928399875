import { APPS } from '../templates';
import { useAppSelector } from '../../../../hooks';
import { Tooltip } from '../../../Core/Tooltip/Tooltip';

export function TemplateButton(props: any) {
  const appConfig = APPS[props.app as keyof typeof APPS];
  if (!appConfig) return <></>;

  if (appConfig.type === 'rollout') {
    return <RolloutTemplateButton {...props} />;
  }

  return <IntegrationTemplateButton {...props} />;
}

function IntegrationTemplateButton({
  app,
  onClick,
  selected,
  enabledIntegrations
}: any) {
  const appConfig = APPS[app as keyof typeof APPS];
  if (!appConfig) return <></>;
  const appDisplay = app.charAt(0).toUpperCase() + app.slice(1);
  const disabled = app !== 'custom' && !enabledIntegrations.includes(app);
  if (disabled) {
    return (
      <div className='tw-w-1/2 tw-p-2 tw-order-2'>
        <Tooltip
          content={`Set up a ${appDisplay} integration to use the ${appDisplay} API.`}
        >
          <button
            disabled={disabled}
            onClick={onClick}
            className='tw-w-full tw-h-24 tw-block tw-p-6 tw-bg-white !tw-border !tw-border-solid !tw-border-gray-200 tw-rounded-lg enabled:hover:tw-bg-gray-100 tw-flex tw-items-center tw-justify-center tw-gap-6 tw-cursor-pointer data-[selected]:!tw-border-primary data-[selected]:!tw-border-2 data-[selected]:!tw-bg-primary-light/20 disabled:tw-bg-gray-100/50 disabled:tw-cursor-auto disabled:tw-text-gray-600 disabled:tw-opacity-70'
            data-selected={selected || undefined}
          >
            {appConfig.logo}
            {appConfig.header}
          </button>
        </Tooltip>
      </div>
    );
  }

  return (
    <div className='tw-w-1/2 tw-p-2 tw-order-1'>
      <button
        disabled={disabled}
        onClick={onClick}
        className='tw-w-full tw-h-24 tw-block tw-p-6 tw-bg-white !tw-border !tw-border-solid !tw-border-gray-200 tw-rounded-lg enabled:hover:tw-bg-gray-100 tw-flex tw-items-center tw-justify-center tw-gap-6 tw-cursor-pointer data-[selected]:!tw-border-primary data-[selected]:!tw-border-2 data-[selected]:!tw-bg-primary-light/20 disabled:tw-bg-gray-100/50 disabled:tw-cursor-auto disabled:tw-text-gray-600 disabled:tw-opacity-70'
        data-selected={selected || undefined}
      >
        {appConfig.logo}
        {appConfig.header}
      </button>
    </div>
  );
}

function RolloutTemplateButton({ app, onClick, selected }: any) {
  const appConfig = APPS[app as keyof typeof APPS];
  const org = useAppSelector((state) => state.accounts.organization);
  if (!org) return null;
  if (
    appConfig.premiumIntegration &&
    !org.enterprise_features[appConfig.premiumIntegration]
  ) {
    return null;
  }
  return (
    <div className='tw-w-1/2 tw-p-2 tw-order-1'>
      <button
        onClick={onClick}
        className='tw-w-full tw-h-24 tw-block tw-p-6 tw-bg-white !tw-border !tw-border-solid !tw-border-gray-200 tw-rounded-lg enabled:hover:tw-bg-gray-100 tw-flex tw-items-center tw-justify-center tw-gap-6 tw-cursor-pointer data-[selected]:!tw-border-primary data-[selected]:!tw-border-2 data-[selected]:!tw-bg-primary-light/20 disabled:tw-bg-gray-100/50 disabled:tw-cursor-auto disabled:tw-text-gray-600 disabled:tw-opacity-70'
        data-selected={selected || undefined}
      >
        {appConfig.logo}
        {appConfig.header}
      </button>
    </div>
  );
}
