function Guidewire({ width = 652, height = 652 }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 652 652'
      width={width}
      height={height}
      enableBackground='new 0 0 652 652'
      xmlSpace='preserve'
    >
      <g>
        <g>
          <path
            fill='#666766'
            d='M197.9,349.7c-3.2,1.6-6.5,2.8-9.9,3.6c-3.4,0.8-7,1.1-10.5,1.1c-3.8,0-7.4-0.6-10.5-1.8     c-3.2-1.1-6-2.9-8.4-5.1c-2.3-2.3-4.2-4.9-5.5-8.1c-1.3-3.2-1.9-6.7-1.9-10.7s0.6-7.5,1.9-10.7c1.3-3.2,3.2-5.8,5.5-8.1     s5.1-3.9,8.4-5.1c3.2-1.1,6.7-1.8,10.5-1.8c3.9,0,7.5,0.5,10.8,1.4c3.3,0.9,6.2,2.5,8.6,4.8l-7.6,8.2c-1.5-1.5-3.2-2.7-4.9-3.4     s-4.2-1.1-6.8-1.1c-2.3,0-4.4,0.4-6.2,1.1c-1.9,0.8-3.4,1.9-4.8,3.3c-1.4,1.4-2.4,3-3,4.9c-0.8,1.9-1,4.1-1,6.2     c0,2.3,0.4,4.4,1,6.3c0.8,1.9,1.8,3.6,3,4.9c1.4,1.4,2.9,2.5,4.8,3.3c1.9,0.8,3.9,1.1,6.2,1.1s4.3-0.3,6.1-0.8     c1.6-0.5,3-1,4.1-1.6v-7.9h-8.7v-9.9h19V349.7z'
          />
          <path
            fill='#666766'
            d='M258.9,334.4c0,2.9-0.4,5.6-1.3,8c-0.9,2.4-2.2,4.6-3.9,6.3c-1.8,1.8-3.9,3.2-6.5,4.2     c-2.5,1-5.6,1.5-8.9,1.5c-3.4,0-6.3-0.5-9-1.5c-2.5-1-4.7-2.4-6.5-4.2c-1.8-1.8-3-3.9-3.9-6.3s-1.3-5.1-1.3-8v-29.9h10.8V334     c0,1.5,0.3,2.9,0.8,4.2c0.5,1.3,1.1,2.4,2,3.3c0.9,0.9,1.9,1.6,3.2,2.2c1.3,0.5,2.5,0.8,3.9,0.8c1.4,0,2.8-0.3,3.9-0.8     s2.3-1.3,3-2.2c0.9-0.9,1.5-2,2-3.3c0.5-1.3,0.8-2.7,0.8-4.2v-29.5h10.8V334.4z'
          />
          <path fill='#666766' d='M279.1,304.3h10.8v48.8h-10.8V304.3z' />
          <path
            fill='#666766'
            d='M309.9,304.3H326c3.9,0,7.6,0.4,11.2,1.3c3.6,0.9,6.6,2.3,9.3,4.2c2.7,1.9,4.7,4.4,6.2,7.6     c1.5,3.2,2.3,7,2.3,11.4c0,3.9-0.8,7.5-2.3,10.4c-1.5,3-3.6,5.6-6.1,7.6s-5.5,3.6-8.7,4.7c-3.3,1-6.7,1.5-10.4,1.5h-17.6v-48.7     L309.9,304.3L309.9,304.3z M320.7,343.2h5.6c2.5,0,4.8-0.3,7-0.8c2.2-0.5,3.9-1.4,5.6-2.5c1.5-1.1,2.8-2.7,3.7-4.6s1.4-4.2,1.4-7     c0-2.4-0.5-4.4-1.4-6.2c-0.9-1.8-2.2-3.2-3.7-4.4c-1.5-1.1-3.3-2-5.3-2.5c-2-0.6-4.2-0.9-6.5-0.9h-6.3L320.7,343.2L320.7,343.2z'
          />
          <path
            fill='#666766'
            d='M372.9,304.3H406v9.9h-22.4v9.1h21.2v9.9h-21.2v9.9h23.6v9.9h-34.2V304.3z'
          />
          <path
            fill='#666766'
            d='M421.2,304.3h11.7l7.7,31.3h0.1l10.1-31.3h10l10.1,32.1h0.1l8.2-32.1h10.9l-14.2,48.8h-9.6l-10.8-33.7h-0.1     l-10.8,33.7h-9.3L421.2,304.3z'
          />
          <path fill='#666766' d='M505.4,304.3h10.8v48.8h-10.8V304.3z' />
          <path
            fill='#666766'
            d='M536.2,304.3h18.9c2.5,0,4.8,0.3,7.1,0.8c2.3,0.5,4.2,1.3,5.8,2.4c1.6,1.1,3,2.7,4.1,4.6s1.5,4.3,1.5,7.1     c0,3.4-0.9,6.3-2.7,8.7c-1.8,2.4-4.4,3.9-7.9,4.6l12.4,20.5h-12.9l-10.1-19.5h-5.3V353h-10.8L536.2,304.3L536.2,304.3z      M547,324.5h6.3c1,0,2,0,3-0.1c1.1-0.1,2-0.3,2.9-0.6s1.6-0.9,2.2-1.6c0.6-0.8,0.9-1.8,0.9-3c0-1.3-0.3-2.3-0.8-2.9     c-0.5-0.8-1.1-1.3-1.9-1.6s-1.6-0.6-2.7-0.8c-1-0.1-2-0.3-2.9-0.3h-7v11L547,324.5L547,324.5z'
          />
          <path
            fill='#666766'
            d='M591.6,304.3h33.1v9.9h-22.3v9.1h21.2v9.9h-21.2v9.9H626v9.9h-34.4V304.3z'
          />
        </g>
        <polygon
          fill='#00739D'
          points='120.3,297.7 120.3,278.8 44.9,278.8 26,278.8 26,297.7 26,354.3 26,373.2 26,373.2 44.9,373.2    44.9,373.2 82.6,373.2 82.6,354.3 44.9,354.3 44.9,297.7  '
        />
        <polygon
          fill='#00739D'
          points='120.3,316.6 120.3,335.4 120.3,335.4 120.3,373.2 101.4,373.2 101.4,335.4 63.7,335.4 63.7,316.6  '
        />
      </g>
    </svg>
  );
}

export default Guidewire;
