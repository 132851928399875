// Expression parsing
import { useMemo, useState } from 'react';
import { Expression as AcornExpression, Literal, parse, Program } from 'acorn';
import { v4 as uuidv4 } from 'uuid';
import { EXPRESSION_OPERATORS } from '../../constants';
import { useAppSelector } from '../../../../../../../../hooks';
import { UNSUPPORTED_SERVAR_TYPES } from './Expression';

interface IParseError {
  message: string;
  pos: number;
}

export default function useExpressionParser() {
  const [replacementMappings, setReplacementMappings] = useState<
    Map<string, string>
  >(new Map());
  // Servar field options
  const localServars = useAppSelector((state) =>
    Object.values(state.formBuilder.servars)
  );
  // map servar key to servar
  const servarMap = useMemo(() => {
    const _servarMap: Record<string, any> = {};
    (localServars ?? [])
      .filter((servar: any) => !UNSUPPORTED_SERVAR_TYPES.includes(servar.type))
      .forEach((servar: any) => (_servarMap[servar.key] = servar));
    return _servarMap;
  }, [localServars, UNSUPPORTED_SERVAR_TYPES]);

  // parse the expression and catch any errors
  const parseExpressionIntoDsl = (
    exprText: string
  ): IRuleOperand | IRuleExpression | string => {
    let ast;

    try {
      const expressionText = replaceServarNamesWithHashes(exprText);
      ast = parse(expressionText, { ecmaVersion: 'latest' });
    } catch (error: any) {
      // parse errors thrown directly from acorn can be technical and not user-friendly
      // so we catch them here and use a more user-friendly message
      error.message = 'Unexpected token';
      return formatParseError(exprText, error);
    }

    try {
      return parseExpressionFromAst(ast);
    } catch (error: any) {
      return formatParseError(exprText, error);
    }
  };

  /**
   * Servar names can contain symbols (i.e. -) that acorn interprets as an operator.
   * To prevent acorn from parsing these names (identifiers) as operations, we replace the servar names
   * with their hashed counterparts in the expression string being passed to the parser.
   *
   * The hash does not contain any special characters and still gets parsed as type `Identifier`.
   *
   */
  const replaceServarNamesWithHashes = (expression: string) => {
    const servarNames = Object.keys(servarMap).sort(
      (a, b) => b.length - a.length
    ); // ensure longer matches are replaced first
    servarNames.forEach((servarName) => {
      if (expression.includes(servarName)) {
        // replacement value must begin with a string to prevent acorn from parsing it to multiple number/string values.
        const replacementValue = `_${(
          servarMap[servarName]?.id || ''
        ).replaceAll('-', '')}`;
        // regex for "replace whole word that matches servarName"
        const toBeReplacedRegex = new RegExp(`\\b${servarName}\\b`, 'g');
        setReplacementMappings(
          replacementMappings.set(replacementValue, servarName)
        );
        expression = expression.replaceAll(toBeReplacedRegex, replacementValue);
      }
    });
    return expression;
  };

  /**
   * Given a string that contains hashes for a servar name, replace the hash with the corresponding
   * servar name.
   */
  const replaceServarHashesWithNames = (expression: string) => {
    const valuesToBeReplaced = replacementMappings.keys();
    for (const toBeReplaced of valuesToBeReplaced) {
      expression = expression.replaceAll(
        toBeReplaced,
        replacementMappings.get(toBeReplaced) || '' // This case will never happen
      );
    }
    return expression;
  };

  const formatParseError = (exprText: string, error: IParseError) => {
    const e: IParseError = error;
    const errorToken = exprText.slice(e.pos).split(' ').pop();
    // not valid
    return `Error: ${e.message} at position ${e.pos}, near: ${errorToken}`;
  };

  const parseExpressionFromAst = (
    ast: Program
  ): IRuleOperand | IRuleExpression => {
    // Only supporting a body that contains a single ExpressionStatement.
    // For the ExpressionStatement call the parseExpression function.
    // For anything else, throw an IParseError.
    if (ast.body.length === 0)
      // Consider this a valid empty string literal
      return {
        id: uuidv4(),
        type: 'value',
        value: ''
      };

    if (ast.body.length > 1)
      return throwParseError(
        'Only a single expression is supported',
        ast.body[1].start
      );

    const expr = ast.body[0];
    if (expr.type !== 'ExpressionStatement') {
      return throwParseError(`Unexpected token`, expr.start);
    }

    return parseExpression(expr.expression);
  };

  const throwParseError = (message: string, pos: number) => {
    throw {
      message,
      pos
    };
  };

  const parseExpression = (
    expr: AcornExpression | Literal
  ): IRuleOperand | IRuleExpression => {
    // Only support SequenceExpression, Literal, BinaryExpression,
    // UnaryExpression, CallExpression and Identifier
    // Anything else throw an IParseError.
    switch (expr.type) {
      case 'BinaryExpression':
        return parseBinaryExpression(expr);
      case 'CallExpression':
        return parseCallExpression(expr);
      case 'UnaryExpression':
        return parseUnaryExpression(expr);
      case 'Literal':
        return parseLiteral(expr);
      case 'Identifier':
        // Only identifiers have a 'name' property
        expr.name = replaceServarHashesWithNames(expr.name);
        return parseIdentifier(expr);
      case 'SequenceExpression':
        return parseSequenceExpression(expr);
      default:
        throw {
          message: `Unexpected ${expr.type}`,
          pos: expr.start
        };
    }
  };

  const parseBinaryExpression = (expr: any): IRuleExpression => {
    // for left and right operands, call parseExpression
    const left = parseExpression(expr.left);
    const right = parseExpression(expr.right);
    // if either left or right is undefined, rthrow an error
    if (!left || !right) return throwParseError(`Invalid`, expr.start);
    // if the operator is not supported, throw an error
    if (!EXPRESSION_OPERATORS[expr.operator]) {
      throwParseError(`Unexpected operator: ${expr.operator}`, expr.left.end);
    }

    // return an IRuleExpression object
    return {
      id: uuidv4(),
      operator: expr.operator,
      operands: [left, right]
    };
  };

  const parseUnaryExpression = (expr: any): IRuleOperand => {
    // Only support unary minus of a literal argument that is a number
    if (expr.operator !== '-') {
      throwParseError(`Unexpected operator: ${expr.operator}`, expr.start);
    }
    if (
      expr.argument.type !== 'Literal' ||
      typeof expr.argument.value !== 'number'
    ) {
      throwParseError(
        `Unexpected token: ${expr.argument.value}`,
        expr.argument.start
      );
    }

    return {
      id: uuidv4(),
      type: 'value',
      value: expr.operator + expr.argument.raw
    };
  };

  const parseLiteral = (expr: any): IRuleOperand => {
    return {
      id: uuidv4(),
      type: 'value',
      value: expr.value
    };
  };

  const parseIdentifier = (expr: {
    name: string;
    [key: string]: any;
  }): IRuleOperand => {
    // is the expr name a servar key?
    const servar = servarMap[expr.name];
    if (servar) {
      return {
        id: uuidv4(),
        type: 'field',
        value: expr.name,
        meta: {
          field_type: 'servar',
          field_key: servar.key,
          servar_type: servar.type,
          servar
        }
      };
    }
    // thow an error - unknown field
    return throwParseError(`Unknown field: ${expr.name}`, expr.start);
  };

  const parseSequenceExpression = (expr: any): IRuleOperand => {
    // This is a special case for multi-valued controls like multi-selects
    // Only support a sequence of literals

    // Loop on expression and if any is not a literal, throw an error
    for (let i = 0; i < expr.expressions.length; i++) {
      if (expr.expressions[i].type !== 'Literal') {
        return throwParseError(
          `Invalid multiple assignment, only literals allowed.`,
          expr.expressions[i].start
        );
      }
    }

    // Set the operand value to an array of the literal values
    // Build an array the values from the expressions
    const values = expr.expressions.map((expr: any) => expr.value);
    return {
      id: uuidv4(),
      type: 'value',
      value: values
    };
  };

  const parseCallExpression = (expr: any): IRuleExpression => {
    // Not supporting call expressions yet but very important for future
    // Throw parse error
    return throwParseError(
      `Invalid function call ${expr.callee.name}`,
      expr.start
    );
  };

  return {
    parseExpressionIntoDsl
  };
}
