function Zendesk({ width = 64, height = 64 }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 363.2 259.5'
      width={width}
      height={height}
    >
      <path
        style={{ fill: '#03363d' }}
        d='M173.8 40.5v112.9H80.3zM173.8 0c0 25.8-20.9 46.7-46.7 46.7S80.3 25.8 80.3 0h93.5zM189.2 153.4c0-25.8 20.9-46.7 46.7-46.7 25.8 0 46.7 20.9 46.7 46.7h-93.4zM189.2 112.9V0h93.5zM241.5 249.9c4.8.1 9.5-1.7 13-5l6.4 6.9c-4.2 4.4-10.1 7.6-19.3 7.6-15.7 0-25.8-10.4-25.8-24.5-.3-13.3 10.3-24.2 23.5-24.5h.8c15.6 0 24.4 11.8 23.6 28.3H227c1.3 6.9 6.1 11.3 14.5 11.2m11.2-18.9c-1-6.4-4.8-11.1-12.4-11.1-7.1 0-12 4-13.3 11.1h25.7zM0 249.4l28.3-28.8H.7v-9h40.7v9.2l-28.3 28.8h28.7v9H0zM73.6 249.9c4.8.1 9.5-1.7 12.9-5l6.4 6.9c-4.2 4.4-10.1 7.6-19.3 7.6-15.7 0-25.8-10.4-25.8-24.5-.3-13.3 10.3-24.2 23.5-24.5h.8c15.6 0 24.4 11.8 23.6 28.3H59.1c1.3 6.9 6.1 11.3 14.5 11.2M84.8 231c-1-6.4-4.8-11.1-12.4-11.1-7.1 0-12 4-13.3 11.1h25.7zM157 235c0-15 11.2-24.4 23.6-24.4 6-.1 11.7 2.5 15.7 7v-27.7h10v68.6h-10V252c-3.9 4.7-9.7 7.5-15.8 7.4-12 0-23.5-9.5-23.5-24.4m39.8-.1c-.2-8.2-7.1-14.7-15.3-14.5-8.2.2-14.7 7.1-14.5 15.3.2 8.1 6.8 14.5 14.9 14.5 8.6 0 14.9-6.8 14.9-15.3M270.3 248.5l9.1-4.7c2.4 4.4 7.1 7 12.1 6.9 5.7 0 8.6-2.9 8.6-6.2 0-3.8-5.5-4.6-11.4-5.8-8-1.7-16.3-4.3-16.3-14 0-7.4 7.1-14.3 18.2-14.2 8.8 0 15.3 3.5 19 9.1l-8.4 4.6c-2.4-3.5-6.4-5.5-10.6-5.4-5.4 0-8.1 2.6-8.1 5.6 0 3.4 4.3 4.3 11.1 5.8 7.7 1.7 16.5 4.2 16.5 14 0 6.5-5.7 15.2-19.1 15.1-9.8.1-16.7-3.8-20.7-10.8M337.2 237.6l-7.9 8.7v12.2h-10v-68.6h10v44.9l21.2-23.3h12.2l-18.4 20.1 18.9 26.9h-11.3zM126.8 210.5c-11.9 0-21.9 7.7-21.9 20.5v27.5h10.2v-26.2c0-7.7 4.4-12.3 12-12.3s11.3 4.6 11.3 12.3v26.2h10.1V231c.1-12.8-9.9-20.5-21.7-20.5'
      />
    </svg>
  );
}

export default Zendesk;
