import { createSlice } from '@reduxjs/toolkit';

const tablesSlice = createSlice({
  name: 'tables',
  initialState: { sortOrders: {} as Record<string, any> },
  reducers: {
    updateSort(state, action) {
      state.sortOrders[action.payload.name] = action.payload.sort;
    }
  }
});
export default tablesSlice.reducer;
const exportFunctions = {
  syncFunctions: tablesSlice.actions
};
export { exportFunctions };
