function Morningstar({ width = 144, height = 72 }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 270.01251 56.637501'
      height={height}
      width={width}
      xmlSpace='preserve'
    >
      <g transform='matrix(1.25,0,0,-1.25,0,56.6375)' id='g10'>
        <g transform='scale(0.1,0.1)' id='g12'>
          <path
            fill='#ea293c'
            fillOpacity='1'
            fillRule='nonzero'
            stroke='none'
            d='m 1085.82,11.7969 38.9609,0 0,428.52 -38.9609,0 0,-428.52 z'
          />
          <path
            fill='#ea293c'
            fillOpacity='1'
            fillRule='nonzero'
            stroke='none'
            d='m 1622.64,440.316 124.39,0 0,-35.711 -42.3,0 0,-392.8081 -39.16,0 0,392.8081 -42.93,0 0,35.711'
          />
          <path
            fill='#ea293c'
            fillOpacity='1'
            fillRule='nonzero'
            stroke='none'
            d='m 1050.18,440.316 0,-428.5191 -40.82,0 -58.387,260.2931 0,-260.2931 -38.282,0 0,428.5191 35.223,0 63.976,-283.793 0,283.793 38.29,0'
          />
          <path
            fill='#ea293c'
            fillOpacity='1'
            fillRule='nonzero'
            stroke='none'
            d='m 202.523,440.316 0,-428.5191 -38.293,0 0,367.3281 -47.265,-367.3281 -31.9103,0 -46.4961,367.3281 0,-367.3281 -38.5586,0 0,428.5191 65.2188,0 37.3442,-258.312 35.226,258.312 64.734,0'
          />
          <path
            fill='#ea293c'
            fillOpacity='1'
            fillRule='nonzero'
            stroke='none'
            d='m 677.309,11.6875 c 30.312,41.1563 48.222,91.9645 48.222,147.0235 0,136.953 -111.043,248.004 -248.004,248.004 -136.98,0 -248.035,-111.051 -248.035,-248.004 0,-55.02 17.895,-105.8985 48.18,-147.0235 l 51.586,0 c -37.449,37.7656 -60.586,89.6285 -60.586,147.0235 0,115.32 93.5,208.832 208.855,208.832 115.328,0 208.844,-93.512 208.844,-208.832 0,-57.316 -23.094,-109.2579 -60.469,-147.0235 l 51.407,0'
          />
          <path
            fill='#ea293c'
            fillOpacity='1'
            fillRule='nonzero'
            stroke='none'
            d='m 1836.42,147.063 -25.93,236.246 -23.88,-236.246 49.81,0 z m 51.73,-135.2661 -38.12,0 -10.05,96.5311 -57.04,0 -9.31,-96.5311 -38.85,0 43.91,428.5191 59.43,0 50.03,-428.5191'
          />
          <path
            fill='#ea293c'
            fillOpacity='1'
            fillRule='nonzero'
            stroke='none'
            d='m 1422.45,66.2695 c 0,-19.3359 -11.34,-29.1797 -24.35,-29.1797 -13,0 -24.35,9.8438 -24.35,29.1797 l 0,323.6365 c 0,19.297 11.35,29.16 24.35,29.16 13.01,0 24.35,-9.863 24.35,-29.16 l -0.08,-15.472 0,-80.789 39.2,0 0,62.289 -0.1,17.988 c -0.27,53.414 -21.15,78.855 -64.73,78.855 -38.82,0 -64.08,-27.527 -64.08,-69.781 l 0.04,-15.734 0,-268.2386 -0.04,-17.3437 C 1332.66,26.7969 1354,0 1397.69,0 c 43.57,0 63.88,26.3086 63.88,82.8125 l 0,155.8985 -71.99,0 0,-37.527 32.79,0 0,-120.7035 0.08,-14.211'
          />
          <path
            fill='#ea293c'
            fillOpacity='1'
            fillRule='nonzero'
            stroke='none'
            d='m 1583.14,76.5547 0,111.4063 -89.34,66.707 0,124.492 c 0,48.926 22.05,73.965 65.18,73.965 43.75,0 63.66,-24.848 63.96,-79.832 l 0.1,-17.098 0,-63.136 -39.9,0 0,84.699 -0.12,12.148 c 0,19.297 -11.24,29.16 -24.08,29.16 -12.89,0 -24.12,-9.863 -24.12,-29.16 l 0,-116.664 88.22,-66.594 0,-128.8433 c 0,-50.4024 -22.42,-77.355481 -64.33,-77.355481 -42.44,0 -64.53,25.449181 -64.8,74.562481 l -0.11,16.961 0,83.8473 41.02,0 0,-109.5505 c 0,-19.3359 11.23,-29.1797 24.12,-29.1797 12.84,0 24.08,9.8438 24.08,29.1797 l 0.12,10.2852'
          />
          <path
            fill='#ea293c'
            fillOpacity='1'
            fillRule='nonzero'
            stroke='none'
            d='m 790.68,406.582 0,-169.687 13.75,0 c 25.234,0 37.109,11.582 37.109,36.179 l 0,87.328 c 0,35.176 -8.137,46.18 -34.18,46.18 l -16.679,0 z m 98.3,-394.7851 -39.042,0 c -5.352,14.4414 -7.696,27.9687 -7.942,46.1015 l -0.312,21.9375 -0.145,11.0821 0,83.66 c 0,17.563 -10.684,25.551 -23.019,25.551 l -27.84,0 0,-188.3321 -38.262,0 0,428.5191 49.91,0 c 56.067,0 77.649,-20.492 77.649,-75.336 l 0,-80.449 c 0,-36.047 -6.036,-51.179 -34.239,-61.582 0.399,-0.129 0.789,-0.273 1.192,-0.398 26.629,-7.778 32.597,-17.895 32.898,-51.672 l 0.149,-15.566 0,-78.2349 c 0,-28.4922 2.136,-43.9414 9.003,-65.2812'
          />
          <path
            fill='#ea293c'
            fillOpacity='1'
            fillRule='nonzero'
            stroke='none'
            d='m 1936.58,406.582 0,-169.687 13.77,0 c 25.25,0 37.11,11.582 37.11,36.179 l 0,87.328 c 0,35.176 -8.14,46.18 -34.22,46.18 l -16.66,0 z m 98.3,-394.7851 -39.05,0 c -5.35,14.4414 -7.68,27.9687 -7.94,46.1015 l -0.32,21.9375 -0.11,11.0821 0,83.66 c 0,17.563 -10.71,25.551 -23.06,25.551 l -27.82,0 0,-188.3321 -38.26,0 0,428.5191 49.91,0 c 56.09,0 77.63,-20.492 77.63,-75.336 l 0,-80.449 c 0,-36.047 -6.01,-51.179 -34.2,-61.582 0.37,-0.129 0.79,-0.273 1.16,-0.398 26.61,-7.778 32.63,-17.895 32.91,-51.672 l 0.13,-15.566 0,-78.2349 c 0,-28.4922 2.16,-43.9414 9.02,-65.2812'
          />
          <path
            fill='#ea293c'
            fillOpacity='1'
            fillRule='nonzero'
            stroke='none'
            d='m 1297.32,440.316 0,-428.5191 -40.84,0 -58.33,260.2931 0,-260.2931 -38.3,0 0,428.5191 35.24,0 63.93,-283.793 0,283.793 38.3,0'
          />
          <path
            fill='#ea293c'
            fillOpacity='1'
            fillRule='nonzero'
            stroke='none'
            d='m 2068.55,397.027 c 0,23.883 19.39,43.309 43.27,43.309 23.9,0 43.29,-19.426 43.29,-43.309 0,-23.886 -19.39,-43.316 -43.29,-43.316 -23.88,0 -43.27,19.43 -43.27,43.316 z m -4.98,0 c 0,-26.617 21.63,-48.273 48.25,-48.273 26.61,0 48.28,21.656 48.28,48.273 0,26.61 -21.67,48.266 -48.28,48.266 -26.62,0 -48.25,-21.656 -48.25,-48.266'
          />
          <path
            fill='#ea293c'
            fillOpacity='1'
            fillRule='nonzero'
            stroke='none'
            d='m 2101.26,398.621 14.24,0 c 5.89,0 10.03,1.887 10.03,8.227 0,6.3 -4.14,8.234 -10.03,8.234 l -14.24,0 0,-16.461 z m -6.3,21.957 21.52,0 c 7.5,0 15.34,-2.871 15.34,-13.73 0,-6.086 -2.75,-11.961 -11.04,-13.309 l 13.12,-20.594 -7.62,0 -12.84,20.2 -12.18,0 0,-20.2 -6.3,0 0,47.633'
          />
        </g>
      </g>
    </svg>
  );
}

export default Morningstar;
