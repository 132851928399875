import '../../style/dialog-form.css';
import { Form } from 'react-bootstrap';
import { useState } from 'react';
import { ALL_INTEGRATIONS_MAP, INTEGRATIONS } from './types';
import { PropertyLabel, TextField, InlineTooltip } from '../Core';
import IntegrationsSidebar from './IntegrationsSidebar';
import useIntegrations from './useIntegrations';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../hooks';
import useReceiveOauthMessage from './shared/useReceiveOauthMessage';

function EgnyteSettingsSidebar() {
  const { formId } = useParams<{ formId: string }>();

  const integration = useIntegrations({
    type: INTEGRATIONS.EGNYTE,
    panelId: formId,
    includeInactive: true
  });
  const org = useAppSelector((state) => state.accounts.organization) as any;

  const [isPartial, setIsPartial] = useState(false);

  const secretMetadata = integration?.data.secret_metadata ?? {};

  const [err, setErr] = useState('');
  const [domain, setDomain] = useState(secretMetadata.domain ?? '');
  const [folderPath, setFolderPath] = useState(
    secretMetadata.folder_path ?? ''
  );
  const oauthToken = secretMetadata.oauth_token;

  const oauthRedirect = `https://${domain}.egnyte.com/puboauth/token?client_id=3mqsz9b88ezmmu6jaqre7nzh&redirect_uri=https://app.feathery.io/oauth-redirect/&scope=Egnyte.filesystem&state=${org.id}&response_type=code`;

  function onSubmitCustom(newIsActive: boolean) {
    setErr('');

    if (newIsActive) {
      const partial = !domain || !folderPath;
      setIsPartial(partial);
      if (partial) return;
    }

    if (!oauthToken) {
      setErr('You must authorize your Egnyte account');
    }

    return {
      isUpdate: integration?.data,
      secretMetadata: {
        domain,
        folder_path: folderPath,
        oauth_token: oauthToken
      }
    };
  }

  const authorizeEgnyte = useReceiveOauthMessage(
    INTEGRATIONS.EGNYTE,
    oauthRedirect,
    !!oauthToken,
    domain
  );

  return (
    <IntegrationsSidebar
      integrationInfo={ALL_INTEGRATIONS_MAP[INTEGRATIONS.EGNYTE]}
      authorizeData={{
        authorize: authorizeEgnyte,
        oauthToken,
        domain
      }}
      isPartial={isPartial}
      onSubmitCustom={onSubmitCustom}
      customError={err}
    >
      <Form className='integration-modal-form'>
        <Form.Group controlId='Egnyte-info'>
          <div style={{ display: 'flex', marginTop: '10px' }}>
            <PropertyLabel label='Domain' />
            <InlineTooltip
              text='This is your Egnyte file system domain name. You can find it in your Egnyte file system url (eg. https://acme.egnyte.com/app/ and "acme" is your domain name)'
              inline
            />
          </div>
          <TextField
            placeholder='Enter You Egnyte Domain Name (eg. acme)'
            value={domain || ''}
            onChange={setDomain}
            error={isPartial && !domain}
          />
        </Form.Group>
        <Form.Group controlId='Egnyte-info'>
          <div style={{ display: 'flex', marginTop: '10px' }}>
            <PropertyLabel label='Folder Path' />
            <InlineTooltip
              text='This is your Egnyte folder path you wish to store all uploaded documents to.'
              inline
            />
          </div>
          <TextField
            placeholder='Shared/Documents'
            value={folderPath || ''}
            onChange={setFolderPath}
            error={isPartial && !folderPath}
          />
        </Form.Group>
      </Form>
    </IntegrationsSidebar>
  );
}

export default EgnyteSettingsSidebar;
