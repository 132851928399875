function Todoist({ width = 70, height = 70 }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      preserveAspectRatio='xMidYMid'
      viewBox='0 0 256 256'
      width={width}
      height={height}
    >
      <path
        d='m224.001997 0h-192.0039944c-17.6400645.03949644-31.93652166 14.336846-31.9980026 32v192c0 17.6 14.3971038 32 31.9980026 32h192.0039944c17.600899 0 31.998003-14.4 31.998003-32v-192c0-17.6-14.397104-32-31.998003-32'
        fill='#e44332'
      />
      <g fill='#fff'>
        <path d='m54.132778 120.802491c4.4632444-2.606216 100.343297-58.32504 102.535069-59.6161929 2.191772-1.2752126 2.311323-5.1964916-.159401-6.6151657-2.454785-1.410704-7.117281-4.0886507-8.846788-5.1167909-2.469416-1.3585708-5.470489-1.3222675-7.906319.095641-1.227392.7173071-83.1518364 48.29868-85.8855736 49.8608156-3.2916427 1.8809389-7.3324729 1.9128189-10.6002053 0l-43.2695601-25.3926724v21.5829745c10.5205046 6.2007218 36.7181549 21.5989148 43.062338 25.2253008 3.7857876 2.151921 7.4121737 2.104101 11.0784101-.02391' />
        <path d='m54.132778 161.609296c4.4632444-2.606216 100.343297-58.325039 102.535069-59.616192 2.191772-1.275213 2.311323-5.1964919-.159401-6.615166-2.454785-1.4107041-7.117281-4.0886507-8.846788-5.1167909-2.469416-1.3585708-5.470489-1.3222675-7.906319.095641-1.227392.7173071-83.1518364 48.2986799-85.8855736 49.8608159-3.2916427 1.880938-7.3324729 1.912819-10.6002053 0l-43.2695601-25.392673v21.582975c10.5205046 6.200721 36.7181549 21.598914 43.062338 25.2253 3.7857876 2.151922 7.4121737 2.104101 11.0784101-.02391' />
        <path d='m54.132778 204.966527c4.4632444-2.606216 100.343297-58.32504 102.535069-59.616192 2.191772-1.275213 2.311323-5.196492-.159401-6.615166-2.454785-1.410704-7.117281-4.088651-8.846788-5.116791-2.469416-1.358571-5.470489-1.322268-7.906319.095641-1.227392.717307-83.1518364 48.29868-85.8855736 49.860816-3.2916427 1.880938-7.3324729 1.912819-10.6002053 0l-43.2695601-25.392673v21.582975c10.5205046 6.200721 36.7181549 21.598914 43.062338 25.2253 3.7857876 2.151922 7.4121737 2.104101 11.0784101-.02391' />
      </g>
    </svg>
  );
}

export default Todoist;
