export default function MobileIcon({
  width = 32,
  height = 32,
  viewbox = '0 0 32 32'
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewbox}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.5 6C7.5 4.067 9.067 2.5 11 2.5H20.8462C22.7792 2.5 24.3462 4.067 24.3462 6V26C24.3462 27.933 22.7791 29.5 20.8462 29.5H11C9.067 29.5 7.5 27.933 7.5 26V6ZM11 5.5C10.7239 5.5 10.5 5.72386 10.5 6V26C10.5 26.2761 10.7239 26.5 11 26.5H20.8462C21.1223 26.5 21.3462 26.2761 21.3462 26V6C21.3462 5.72386 21.1223 5.5 20.8462 5.5H11Z'
        fill='white'
      />
      <path
        d='M15.9237 25.2308C16.2909 25.2308 16.6431 25.0849 16.9027 24.8252C17.1624 24.5656 17.3083 24.2134 17.3083 23.8462C17.3083 23.4789 17.1624 23.1268 16.9027 22.8671C16.6431 22.6074 16.2909 22.4615 15.9237 22.4615C15.5565 22.4615 15.2043 22.6074 14.9446 22.8671C14.6849 23.1268 14.5391 23.4789 14.5391 23.8462C14.5391 24.2134 14.6849 24.5656 14.9446 24.8252C15.2043 25.0849 15.5565 25.2308 15.9237 25.2308Z'
        fill='white'
      />
    </svg>
  );
}
