function Coda({ width = 54, height = 74 }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 34 54'
    >
      <path
        d='M.69 48.887c0 2.489 1.557 4.184 3.704 4.184 1.896 0 3.095-1.082 3.328-2.687h-1.79c-.142.721-.733 1.118-1.52 1.118-1.199 0-1.932-1.1-1.932-2.615 0-1.516.733-2.616 1.932-2.616.787 0 1.378.397 1.52 1.118h1.79c-.233-1.605-1.432-2.687-3.328-2.687-2.147 0-3.703 1.695-3.703 4.185zm15.623 0c0-2.454-1.539-4.185-3.775-4.185-2.237 0-3.775 1.731-3.775 4.185 0 2.453 1.538 4.184 3.775 4.184 2.236 0 3.775-1.731 3.775-4.184zm-1.79 0c0 1.515-.697 2.633-1.985 2.633-1.289 0-1.986-1.118-1.986-2.633 0-1.516.697-2.634 1.986-2.634 1.288 0 1.986 1.118 1.986 2.634zm8.665 4.003h1.79V41.167h-1.79v4.402c-.358-.451-1.145-.866-2.021-.866-2.326 0-3.686 1.894-3.686 4.185 0 2.308 1.36 4.184 3.685 4.184.877 0 1.664-.415 2.022-.865v.685zm0-2.488a1.927 1.927 0 0 1-1.717 1.064c-1.378 0-2.2-1.1-2.2-2.58 0-1.479.822-2.579 2.2-2.579.733 0 1.413.433 1.717 1.064v3.03zM33.31 52.89v-5.248c0-1.822-1.181-2.94-3.185-2.94-1.628 0-2.88 1.028-3.113 2.399h1.718c.196-.56.644-.866 1.36-.866 1.019 0 1.52.631 1.52 1.443v.65c-.322-.235-1.127-.488-1.843-.488-1.753 0-3.095 1.065-3.095 2.58 0 1.641 1.342 2.615 2.97 2.615.895 0 1.7-.325 1.968-.595v.45h1.7zm-1.7-2.128c-.197.505-.912.812-1.628.812-.805 0-1.646-.343-1.646-1.154 0-.794.84-1.137 1.646-1.137.716 0 1.431.307 1.628.812v.667zM30.682.928H3.318A2.99 2.99 0 0 0 .333 3.905v29.762a2.99 2.99 0 0 0 2.985 2.976h27.364a2.99 2.99 0 0 0 2.985-2.976v-1.39c-.05-1.785-.1-5.505-.1-7.192 0-.942-.696-1.736-1.592-1.736-.995 0-1.642.595-2.14 1.091-1.492 1.34-3.73 1.588-5.67 1.24-.896-.198-1.742-.446-2.439-.892-2.139-1.24-3.532-3.572-3.532-6.052 0-2.48 1.393-4.762 3.532-6.052.747-.446 1.592-.694 2.438-.892 1.89-.348 4.18-.1 5.672 1.24.547.496 1.194 1.09 2.14 1.09.895 0 1.591-.793 1.591-1.735 0-1.637.05-5.407.1-7.193v-1.29A2.99 2.99 0 0 0 30.682.929z'
        fill='#F46A54'
      />
    </svg>
  );
}

export default Coda;
