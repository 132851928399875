/* eslint-disable camelcase */
import { MOBILE_DIVERGED_VIEWPORT_PROPERTIES, Viewport } from '../viewports';
import { Model } from './Model';

export class Step extends Model {
  static Type = 'Step';

  static ViewportFields = MOBILE_DIVERGED_VIEWPORT_PROPERTIES.step;

  constructor(state: any = {}, viewport?: Viewport) {
    super(Step.Type, state, Step.ViewportFields, viewport);
  }
}
