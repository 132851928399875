export default function TextAreaIcon() {
  return (
    <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.5 36C5.5 36.8284 6.17157 37.5 7 37.5H27.0663H41C41.8284 37.5 42.5 36.8284 42.5 36V25.8629V12C42.5 11.1716 41.8284 10.5 41 10.5H7C6.17157 10.5 5.5 11.1716 5.5 12V36ZM7 40.5C4.51472 40.5 2.5 38.4853 2.5 36V12C2.5 9.51472 4.51472 7.5 7 7.5H41C43.4853 7.5 45.5 9.51472 45.5 12V25.8629V36C45.5 38.4853 43.4853 40.5 41 40.5H27.0663H7ZM11.2222 22.5C10.3937 22.5 9.72217 23.1716 9.72217 24C9.72217 24.8284 10.3937 25.5 11.2222 25.5H36.2222C37.0506 25.5 37.7222 24.8284 37.7222 24C37.7222 23.1716 37.0506 22.5 36.2222 22.5H11.2222ZM9.72217 16.7778C9.72217 15.9493 10.3937 15.2778 11.2222 15.2778H36.2222C37.0506 15.2778 37.7222 15.9493 37.7222 16.7778C37.7222 17.6062 37.0506 18.2778 36.2222 18.2778H11.2222C10.3937 18.2778 9.72217 17.6062 9.72217 16.7778ZM11.2222 29.7222C10.3937 29.7222 9.72217 30.3938 9.72217 31.2222C9.72217 32.0507 10.3937 32.7222 11.2222 32.7222H17.3333C18.1617 32.7222 18.8333 32.0507 18.8333 31.2222C18.8333 30.3938 18.1617 29.7222 17.3333 29.7222H11.2222Z'
        fill='#414859'
      />
    </svg>
  );
}
