import { MOBILE_DIVERGED_VIEWPORT_PROPERTIES, Viewport } from '../viewports';
import { Model } from './Model';
import { v4 as uuidv4 } from 'uuid';

const DEFAULT_VALUES = {
  key: null,
  axis: 'col',
  width: 'fit',
  height: 'fit',
  properties: {},
  styles: {},
  repeated: false,
  hide_ifs: [],
  show_logic: true // If true, changes the meaning of hide_ifs to show_ifs
};

export class Subgrid extends Model {
  static Type = 'Subgrid';

  static ViewportFields = MOBILE_DIVERGED_VIEWPORT_PROPERTIES.subgrids;

  constructor(state = {}, viewport?: Viewport) {
    super(
      Subgrid.Type,
      Subgrid.transform(state),
      Subgrid.ViewportFields,
      viewport
    );
  }

  static transform(state: any = {}) {
    const _state = {
      ...Model.transform(state, DEFAULT_VALUES)
    };

    if (!_state.id) _state.id = uuidv4(); // Generate a new ID if one doesn't exist
    if (_state.axis === 'column') _state.axis = 'col';
    if (_state.mobile_axis === 'column') _state.mobile_axis = 'col';

    return JSON.parse(JSON.stringify(_state));
  }

  getRaw() {
    const raw = super.getRaw();

    if (raw.axis === 'col') raw.axis = 'column';
    if (raw.mobile_axis === 'col') raw.mobile_axis = 'column';
    if (!raw.mobile_styles) raw.mobile_styles = {};

    raw.background_image = raw.styles?.background_image_id || null;
    raw.mobile_background_image =
      raw.mobile_styles?.background_image_id || null;

    return raw;
  }

  getCombinedStyles() {
    const raw = super.getRaw();

    return {
      ...(raw.styles || {}),
      ...(raw.mobile_styles || {})
    };
  }
}
