export default function DateSelectorIcon() {
  return (
    <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect
        x='6'
        y='8.44446'
        width='35.5556'
        height='35.5556'
        rx='3'
        stroke='#414859'
        strokeWidth='3'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <line
        x1='7.5'
        y1='17.6111'
        x2='40.0556'
        y2='17.6111'
        stroke='#414859'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <line
        x1='14.6111'
        y1='5.5'
        x2='14.6111'
        y2='11.3889'
        stroke='#414859'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <line
        x1='34.1667'
        y1='5.5'
        x2='34.1667'
        y2='11.3889'
        stroke='#414859'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
