import 'react-quill/dist/quill.snow.css';
import '../../style/dialog-form.css';
import { useParams } from 'react-router-dom';
import useIntegrations from './useIntegrations';
import { ALL_INTEGRATIONS_MAP, INTEGRATIONS } from './types';
import IntegrationsSidebar from './IntegrationsSidebar';
import { useAppSelector } from '../../hooks';

function SMSMessageSettingsSidebar() {
  const { formId } = useParams<{ formId: string }>();

  // Global/cached integration settings
  const integration = useIntegrations({
    type: INTEGRATIONS.SMS_MESSAGE,
    panelId: formId,
    includeInactive: true
  });
  const org = useAppSelector((state) => state.accounts.organization) as any;
  const enabled = org && org.tier >= 4 && org.enterprise_features.otp;

  function onSubmitCustom() {
    return { isUpdate: integration?.data };
  }

  return (
    <IntegrationsSidebar
      integrationInfo={ALL_INTEGRATIONS_MAP[INTEGRATIONS.SMS_MESSAGE]}
      onSubmitCustom={onSubmitCustom}
    >
      <>
        {!enabled &&
          'Custom SMS messages may only be sent on the Business plan.'}
      </>
    </IntegrationsSidebar>
  );
}

export default SMSMessageSettingsSidebar;
