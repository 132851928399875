import '../../style/dialog-form.css';

import { ALL_INTEGRATIONS_MAP } from './types';
import IntegrationsSidebar from './IntegrationsSidebar';

function DisabledIntegrationSidebar({
  integrationType
}: {
  integrationType: string;
}) {
  return (
    <IntegrationsSidebar
      integrationInfo={ALL_INTEGRATIONS_MAP[integrationType]}
    >
      Reach out to sales for access
    </IntegrationsSidebar>
  );
}

export default DisabledIntegrationSidebar;
