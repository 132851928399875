export default function TextIcon({ color = '#414859', ...props }) {
  return (
    <svg
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M38.6667 18H29.7778V21.3333H38.6667C39.1382 21.3333 39.5903 21.5089 39.9237 21.8215C40.2571 22.134 40.4444 22.558 40.4444 23V26.3333H33.3333C31.9193 26.3347 30.5635 26.8619 29.5637 27.7993C28.5638 28.7367 28.0014 30.0077 28 31.3333V33C28.0014 34.3257 28.5638 35.5967 29.5637 36.5341C30.5635 37.4715 31.9193 37.9987 33.3333 38H44V23C43.9986 21.6743 43.4362 20.4033 42.4363 19.4659C41.4365 18.5285 40.0807 18.0013 38.6667 18ZM33.3333 34.6667C32.8618 34.6667 32.4097 34.4911 32.0763 34.1785C31.7429 33.866 31.5556 33.442 31.5556 33V31.3333C31.5556 30.8913 31.7429 30.4674 32.0763 30.1548C32.4097 29.8423 32.8618 29.6667 33.3333 29.6667H40.4444V34.6667H33.3333Z'
        fill={color}
      />
      <path
        d='M24.5714 38H28L17.7143 10H14.2857L4 38H7.42857L10.3326 29.7647H21.6691L24.5714 38ZM11.4931 26.4706L15.7737 14.3301H16.2297L20.5086 26.4706H11.4931Z'
        fill={color}
      />
    </svg>
  );
}
