import { ComponentPropsWithoutRef } from 'react';
import ContentEditable from 'react-contenteditable';
import styles from './styles.module.scss';
import { onKeyDown, contentEditableSelectAll } from './utils';

interface InputSectionProps
  extends ComponentPropsWithoutRef<typeof ContentEditable> {
  gridRow: string;
  gridColumn: string;
}

export default function InputSection({
  gridRow,
  gridColumn,
  ...props
}: InputSectionProps) {
  return (
    <div
      className={styles.inputContainer}
      style={{
        gridRow,
        gridColumn
      }}
    >
      <ContentEditable
        tagName='span'
        draggable={false}
        onFocus={contentEditableSelectAll}
        style={{
          pointerEvents: 'auto',
          padding: '0 0.25rem',
          minWidth: 20,
          textAlign: 'center'
        }}
        onKeyDown={onKeyDown}
        {...props}
      />
    </div>
  );
}
