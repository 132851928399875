import { SVGProps } from 'react';

const FileIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={20}
    height={20}
    viewBox='0 0 20 20'
    fill='none'
    {...props}
  >
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.5}
      d='M8.312 2H6.167c-.575 0-1.126.21-1.532.586A1.925 1.925 0 0 0 4 4v12c0 .53.228 1.04.635 1.414.406.375.957.586 1.532.586h8.666c.575 0 1.126-.21 1.532-.586.407-.375.635-.884.635-1.414v-6M8.312 2C9.51 2 10.5 2.895 10.5 4v2c0 .53.228 1.04.635 1.414.406.375.957.586 1.532.586h2.166c.575 0 1.126.21 1.532.586.407.375.635.884.635 1.414M8.312 2C11.866 2 17 6.764 17 10'
    />
  </svg>
);
export default FileIcon;
