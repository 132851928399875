export default function EmailIcon() {
  return (
    <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M33.9721 31.6111C33.1437 31.6111 32.4721 32.2827 32.4721 33.1111C32.4721 33.9395 33.1437 34.6111 33.9721 34.6111V31.6111ZM7.33325 10.5C6.50482 10.5 5.83325 11.1716 5.83325 12C5.83325 12.8284 6.50482 13.5 7.33325 13.5V10.5ZM41.2499 31.6111H33.9721V34.6111H41.2499V31.6111ZM42.4999 30.3575C42.4999 31.0517 41.9384 31.6111 41.2499 31.6111V34.6111C43.599 34.6111 45.4999 32.7048 45.4999 30.3575H42.4999ZM42.4999 14.7536V30.3575H45.4999V14.7536H42.4999ZM41.2499 13.5C41.9384 13.5 42.4999 14.0594 42.4999 14.7536H45.4999C45.4999 12.4063 43.599 10.5 41.2499 10.5V13.5ZM7.33325 13.5H41.2499V10.5H7.33325V13.5Z'
        fill='#414859'
      />
      <rect
        x='4'
        y='19.7778'
        width='22.2222'
        height='16.6667'
        rx='2'
        stroke='#414859'
        strokeWidth='3'
      />
      <path
        d='M5.66675 22.5556L15.0245 29.2222L25.1112 22'
        stroke='#414859'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
