import { memo, useState } from 'react';

import { Handle } from 'reactflow';
import { DynamicTextTooltip } from '../../Core';
import classNames from 'classnames';

import styles from '../styles.module.scss';
import { LightningIcon, PlusIcon } from '../../Icons';
import useIsStepProtected from '../../../hooks/useIsStepProtected';
import useActiveLogic from '../../FormFieldEditors/DefaultEditor/LogicSection/useActiveLogic';
import { Tooltip } from '../../Core/Tooltip/Tooltip';

const LABEL_MAX_LENGTH = 12;

function StepNode({ id, data }: any) {
  const lock = useIsStepProtected(id);

  const [allowShow, setAllowShow] = useState(true);

  const activeLogic = useActiveLogic(id);

  let label = data.label;
  if (data.label.length > LABEL_MAX_LENGTH) {
    label = data.label.slice(0, LABEL_MAX_LENGTH) + '...';
  }

  const element = (
    <div
      onMouseDown={() => setAllowShow(false)}
      onMouseUp={() => setAllowShow(true)}
      onClick={data.onClick}
      onDoubleClick={data.onDoubleClick}
      className={classNames(
        styles.stepNode,
        data.origin && styles.stepOrigin,
        data.selected && styles.stepSelected
      )}
    >
      <span>{label}</span>
      {activeLogic.length > 0 && (
        <Tooltip side='top' content='This step has advanced logic rules'>
          <div style={{ paddingBottom: '2px' }}>
            <LightningIcon />
          </div>
        </Tooltip>
      )}
    </div>
  );

  return (
    <>
      {lock}
      <DynamicTextTooltip
        text={data.label}
        maxLength={LABEL_MAX_LENGTH}
        allowShow={allowShow && !data.disabled}
      >
        {element}
      </DynamicTextTooltip>
      <DynamicTextTooltip
        text='Drag to another step to create navigation rules'
        maxLength={0}
        allowShow={allowShow && !data.disabled}
      >
        <Handle
          className={styles.handle}
          // @ts-ignore
          position='right'
          type='source'
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        />
      </DynamicTextTooltip>
      <PlusIcon className={styles.handleIcon} />
    </>
  );
}

export default memo(StepNode);
