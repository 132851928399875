export default function URLIcon() {
  return (
    <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M34.8837 31.692C34.0553 31.692 33.3837 32.3635 33.3837 33.192C33.3837 34.0204 34.0553 34.692 34.8837 34.692V31.692ZM10.6665 12.5C9.83808 12.5 9.1665 13.1716 9.1665 14C9.1665 14.8284 9.83808 15.5 10.6665 15.5V12.5ZM41.4999 31.692H34.8837V34.692H41.4999V31.692ZM42.4999 30.6887C42.4999 31.2446 42.0503 31.692 41.4999 31.692V34.692C43.7109 34.692 45.4999 32.8977 45.4999 30.6887H42.4999ZM42.4999 16.5033V30.6887H45.4999V16.5033H42.4999ZM41.4999 15.5C42.0503 15.5 42.4999 15.9473 42.4999 16.5033H45.4999C45.4999 14.2942 43.7109 12.5 41.4999 12.5V15.5ZM10.6665 15.5H41.4999V12.5H10.6665V15.5Z'
        fill='#414859'
      />
      <path
        d='M9.29897 23.3239L10.5987 27.8873H12.2984L14.5979 20H12.5483L11.3486 24.7887L10.0988 20H8.49912L7.24937 24.7887L6.0496 20H4L6.29955 27.8873H7.99922L9.29897 23.3239Z'
        fill='#414859'
      />
      <path
        d='M20.0976 23.3239L21.3974 27.8873H23.097L25.3966 20H23.347L22.1472 24.7887L20.8975 20H19.2978L18.048 24.7887L16.8483 20H14.7987L17.0982 27.8873H18.7979L20.0976 23.3239Z'
        fill='#414859'
      />
      <path
        d='M30.8963 23.3239L32.1961 27.8873H33.8957L36.1953 20H34.1457L32.9459 24.7887L31.6962 20H30.0965L28.8467 24.7887L27.6469 20H25.5973L27.8969 27.8873H29.5966L30.8963 23.3239Z'
        fill='#414859'
      />
      <path
        d='M36.1004 26.9859C36.1004 27.5493 36.5003 28 37.0502 28C37.6001 28 38 27.5493 38 26.9859C38 26.4225 37.6001 25.9718 37.0502 25.9718C36.5003 25.9718 36.1004 26.4225 36.1004 26.9859Z'
        fill='#414859'
      />
    </svg>
  );
}
