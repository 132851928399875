import '../../style/dialog-form.css';

import IntegrationsSidebar from './IntegrationsSidebar';

import { ALL_INTEGRATIONS_MAP, INTEGRATIONS } from './types';
import useIntegrations from './useIntegrations';
import useReceiveOauthMessage from './shared/useReceiveOauthMessage';
import { useParams } from 'react-router-dom';

const oauthRedirect =
  'https://slack.com/oauth/v2/authorize?client_id=1141208976342.2275117916193&scope=incoming-webhook';

function SlackSettingsSidebar() {
  const { formId } = useParams<{ formId: string }>();

  // Global/cached integration settings
  const integration = useIntegrations({
    type: INTEGRATIONS.SLACK_LEGACY,
    panelId: formId
  });

  const authorizeSlack = useReceiveOauthMessage(
    INTEGRATIONS.SLACK_LEGACY,
    oauthRedirect,
    !!integration?.data.active
  );

  return (
    <IntegrationsSidebar
      integrationInfo={ALL_INTEGRATIONS_MAP[INTEGRATIONS.SLACK_LEGACY]}
      onSubmitCustom={(_) => authorizeSlack()}
      hideSaveButton
    />
  );
}

export default SlackSettingsSidebar;
