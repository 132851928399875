const InternationalSupportIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z'
        stroke='#6C7589'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4 12H20M12 4C14.001 6.19068 15.1382 9.03363 15.2 12C15.1382 14.9664 14.001 17.8093 12 20C9.99898 17.8093 8.8618 14.9664 8.8 12C8.8618 9.03363 9.99898 6.19068 12 4Z'
        stroke='#6C7589'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default InternationalSupportIcon;
