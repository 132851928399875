import { Positive } from '../../../components/Core/Button';
import Dialog from '../../../components/Dialog';
import { ErrorMarkerIcon } from '../../../components/Icons';
import '../../../style/dialog-form.css';
import ExtractionProperties from '../components/ExtractionProperties';
import styles from '../styles.module.scss';
import { useExtractionForm } from './useExtractionForm';
import useModalSubmissionLockout from '../../../utils/useModalSubmissionLockout';

export default function ExtractionCreateModal({ isOpen, close }: any) {
  const {
    formData,
    accounts,
    fileOptions,
    usedFields,
    handleChange,
    handleSubmit,
    error,
    loading,
    fieldProperties,
    hiddenFieldProperties,
    hiddenFieldOptions
  } = useExtractionForm(null, () => close());

  const { lockOutFlag, lockoutFunction } =
    useModalSubmissionLockout(handleSubmit);

  return (
    <Dialog isOpen={isOpen} onClose={close} title='Create Extraction' size='lg'>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          return lockoutFunction();
        }}
      >
        <ExtractionProperties
          formData={formData}
          handleChange={handleChange}
          accounts={accounts}
          fileOptions={fileOptions}
          hiddenFieldOptions={hiddenFieldOptions}
          usedFields={usedFields}
          fieldProperties={fieldProperties}
          hiddenFieldProperties={hiddenFieldProperties}
        />
        {error && (
          <div className={styles.error}>
            <div className={styles.icon}>
              <ErrorMarkerIcon height={30} width={30} />
            </div>
            <div className={styles.content}>
              <div className={styles.title}>{error}</div>
              <div className={styles.message}>
                Please try again or contact support if the problem persists
              </div>
            </div>
          </div>
        )}
        <div className='dialog-form-action text-center'>
          <Positive lockoutOverride={lockOutFlag} loading={loading}>
            Create
          </Positive>
        </div>
      </form>
    </Dialog>
  );
}
