import { mergeDeep } from '../utils/utilities';
import { Viewport, ViewportType } from './Viewport';

type ViewportStateOptions = {
  isSet?: (state: any) => boolean;
  fallback?: ViewportType | null;
  inherit?: ViewportType | null;
};

type GetOptions = {
  fallback?: ViewportType | null;
  inherit?: ViewportType | null;
  inheritIfFalse?: string[];
};

/**
 * A utility class that helps manage data that can be separated by viewports.
 */
export class ViewportState {
  #viewport: Viewport; // An instance of a Viewport
  #state: any; // The parsed version of the initial state, divided by viewports
  #options: Required<ViewportStateOptions>; // Instance options for this ViewportState
  #initialValue: any; // The initial value of the state

  constructor(
    viewport: Viewport,
    initialValue: any = {},
    options: ViewportStateOptions = {}
  ) {
    this.#viewport = viewport;
    this.#initialValue = initialValue;
    this.#options = {
      isSet: (state: any) => !!Object.keys(state).length,
      fallback: null,
      inherit: null,
      ...options
    };

    this.init();
  }

  /**
   * Initializes the state by dividing the initial state into viewports.
   */
  init() {
    this.#state = null;
    this.#state = [...Viewport.Viewports, Viewport.All].reduce(
      (state, viewport) => ({
        ...state,
        [viewport]: JSON.parse(JSON.stringify(this.#initialValue))
      }),
      {}
    );
  }

  /**
   * Retrieve the value of a key within the viewport state on the specified or current viewport
   * @param key - Key that is to be retrieved
   * @param viewport - Viewport that the key is to be retrieved from
   * @param options.fallback - A fallback viewport that is to be used if the key does not have a value on the current or specified viewport
   * @param options.inherit - A viewport that the key should inherit the value from if it is set
   * @param options.inheritIfFalse - An array of fields that should inherit from the specified viewport if they are falsey
   * @returns {any}
   */
  getKey(
    key: string,
    viewport: ViewportType | null = null,
    options: GetOptions = {}
  ) {
    const state = this.get(viewport, options);

    if (this.#options.isSet(state) && typeof state === 'object') {
      return state[key];
    }

    return null;
  }

  /**
   * Returns the entire state of the specified or current viewport.
   * @param viewport - Viewport to retrieve the entire state from
   * @param options.fallback - A fallback viewport that is to be used if the key does not have a value on the current or specified viewport
   * @param options.inherit - A viewport that the key should inherit the value from if it is set
   * @param options.inheritIfFalse - An array of fields that should inherit from the specified viewport if they are falsey
   * @returns {any}
   */
  get(
    viewport: ViewportType | null = null,
    {
      fallback = this.#options.fallback,
      inherit = null,
      inheritIfFalse = []
    }: GetOptions = {}
  ) {
    const _viewport = viewport || this.#viewport.activeViewport;
    const state = this.#state[_viewport];
    const isSet = this.#options.isSet(state);

    // Return a fallback state if the desired state is not defined
    if (!isSet && fallback && fallback !== _viewport) {
      return this.#state[fallback];
    }

    // Return the state inheriting the target state specified by inherit
    if (inherit && inherit !== _viewport) {
      const _state = mergeDeep(
        JSON.parse(JSON.stringify(this.#state[inherit])),
        JSON.parse(JSON.stringify(state))
      );

      // If inheritIfFalse is specified, it will inherit specified fields if they're falsey
      inheritIfFalse.forEach((field: string) => {
        if (!_state[field]) {
          _state[field] = this.#state[inherit][field];
        }
      });

      return _state;
    }

    return state;
  }

  /**
   * Set the value of a key on the current or specified viewport.
   * @param key - Key that is to be set
   * @param value - Value of the key that is to be set
   * @param viewport - Optional. Viewport that the key should be set on
   */
  setKey(key: string, value: any, viewport = this.#viewport.activeViewport) {
    this.#state[viewport][key] = value;
  }

  /**
   * Set the entire value of the state on the current or specified viewport.
   * @param value - Value of the state that should be set
   * @param viewport - Optional. Viewport that the state should be set on
   */
  set(value: any, viewport = this.#viewport.activeViewport) {
    const isIterable =
      (typeof this.#state[viewport] === 'object' ||
        Array.isArray(this.#state[viewport])) &&
      this.#state[viewport] !== null;

    if (isIterable) {
      this.#state[viewport] = {
        ...this.#state[viewport],
        ...value
      };
    } else {
      this.#state[viewport] = value;
    }
  }

  /**
   * Clear the state of the current or specified viewport.
   * @param viewport - Viewport that the state should be cleared on
   */
  clear(viewport = this.#viewport.activeViewport) {
    this.#state[viewport] = {};
  }

  /**
   * Re-initializes the state from the initial value passed.
   */
  reset() {
    this.init();
  }

  /**
   * Perform operations on the state for the specified viewport.
   * @param viewport - Viewport that should be used for the operations of the callback provided
   * @param callback - Callback function that should be used on the state with the specified viewport
   */
  with(viewport: ViewportType, callback?: any) {
    const set = (value: any) => {
      this.set(value, viewport);
    };

    callback(this.get(viewport), set);
  }

  /**
   * Returns an array of the states of the viewports specified (all if not specified)
   * @param viewports - Viewports that should be included
   * @returns {Array}
   */
  toArray(viewports = [...Viewport.Viewports]) {
    return Object.entries({ ...this.#state }).reduce<any[]>(
      (states, [viewport, viewportState]) => {
        if (viewports.includes(viewport as ViewportType)) {
          return [...states, viewportState];
        }

        return states;
      },
      []
    );
  }

  /**
   * Set a new Viewport instance
   * @param viewport - Viewport instance to be set on this instance
   */
  setViewport(viewport: Viewport): void {
    this.#viewport = viewport;
  }

  get viewport() {
    return this.#viewport;
  }

  get state() {
    return this.#state[this.#viewport.activeViewport];
  }

  get raw() {
    return this.#state;
  }
}
