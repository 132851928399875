import { RefObject, useEffect, useMemo, useState } from 'react';
import { useSyncedRefState } from './useSyncedRefState';

export const useIntersecting = (ref: RefObject<HTMLElement>) => {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = useMemo(() => {
    return new IntersectionObserver(([entry]) => {
      setIntersecting(entry.isIntersecting);
    });
  }, [ref]);

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return isIntersecting;
};

export const useScreen = (ref: RefObject<HTMLElement>) => {
  const _isIntersecting = useIntersecting(ref);
  const isIntersecting = useSyncedRefState(_isIntersecting);

  const scrollIntoView = (id?: string) => {
    if (id) {
      const element = document.querySelector(`div[data-id="${id}"]`);

      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    } else {
      ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return { isIntersecting, scrollIntoView };
};
