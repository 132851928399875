import { memo, useMemo } from 'react';
import { getModalPayload } from '../../RenderingEngine/utils';
import useFeatheryRedux from '../../../redux';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { getNodeLabel } from '../../RenderingEngine/utils/gig';
import useWindowSize from '../../../hooks/useWindowSize';

const nodeLabel = (label: any, key: any, onClick?: any) => {
  return (
    <div
      key={`${label} ${key}`}
      className={classNames(styles.breadcrumb, onClick ? '' : styles.ellipse)}
      onClick={onClick ? onClick : () => {}}
    >
      <span className={styles.breadcrumbLabel}>{label}</span>
    </div>
  );
};

const Breadcrumbs = ({ cellNode }: any) => {
  const {
    formBuilder: { gigSetPosition, focusElement }
  } = useFeatheryRedux();
  const [width] = useWindowSize();

  const groups = useMemo(() => {
    const groups = [];
    let curNode = cellNode;
    let first = true;
    while (curNode?.parent) {
      const elementType = curNode._type;
      const position = curNode.position;
      const element = curNode.unlinked();
      const onclick = () => {
        focusElement(
          getModalPayload({
            element,
            elementType
          })
        );
        gigSetPosition(position);
      };
      groups.push(
        // The current selection is already focused
        nodeLabel(
          getNodeLabel(curNode),
          curNode?.uuid,
          first ? () => {} : onclick
        )
      );
      if (first) first = false;
      curNode = curNode.parent;
    }
    groups.push(
      nodeLabel('Step', curNode?.uuid, () => {
        focusElement();
        gigSetPosition('root');
      })
    );
    groups.reverse();
    return groups;
  }, [cellNode]);

  const reducedGroups = useMemo(() => {
    const bar = document.getElementById('breadcrumbsBar');
    const container = document.getElementById('breadcrumbsBarControls');
    // Each breadcrumb takes approx 100px, add 5 to it isn't too cluttered
    const numOfBreadcrumbs =
      bar && container
        ? Math.floor((bar?.clientWidth - container?.clientWidth) / 105)
        : 0;

    if (numOfBreadcrumbs === 0) return <></>;
    if (groups.length > numOfBreadcrumbs) {
      const numExcessBreadcrumbs = groups.length - numOfBreadcrumbs;
      const numToGoBack = Math.ceil(numExcessBreadcrumbs / 2);
      const start = Math.floor(groups.length / 2) - numToGoBack;
      const reducedGroups = [...groups];
      reducedGroups.splice(
        start,
        numExcessBreadcrumbs,
        nodeLabel('...', '...')
      );
      return reducedGroups;
    }
    return groups;
  }, [groups, width]);

  return <div className={styles.breadcrumbContainer}>{reducedGroups}</div>;
};

export default memo(Breadcrumbs);
