import { memo, useMemo } from 'react';
import {
  assetUpdateOperations,
  elementOperation,
  getAsset
} from '../../../utils/themes';
import {
  ACTION_OPTIONS,
  calculateOverrideObjects,
  getActionOptions
} from '../utils';

import ClickActionSection from '../Sections/ClickActionSection';
import { HideIfsSection, TextPropertySection } from './components';
import { objectPick } from '../../../utils/core';
import { TYPE_TEXT } from '../../../utils/elements';
import { useRepeatedContainerChecks } from '../../../hooks/useRepeatedContainerChecks';
import LogicSection from '../../FormFieldEditors/DefaultEditor/LogicSection';

function TextPropertiesPanel({
  mode,
  viewport,
  theme,
  baseProps,
  overrideProps,
  handleUpdates
}: any) {
  const { result: element, isOverride: isOverrideProp } =
    calculateOverrideObjects(baseProps, overrideProps, true);
  const { repeatExists, inRepeatContainer } =
    useRepeatedContainerChecks(element);
  const asset = getAsset(theme, TYPE_TEXT, element.source_asset);

  function handlePropChange(propUpdate: any) {
    handleUpdates([elementOperation({ viewport, propUpdate })]);
  }

  function labelData(...propList: string[]) {
    const newProps = objectPick(element.properties, propList);

    return {
      mode,
      highlighted: isOverrideProp({ properties: propList }),
      themeOperations: {
        instance: {
          label: 'Reset to asset value',
          operation: () => {
            handlePropChange([elementOperation({ propReset: propList })]);
          }
        },
        ...(asset && {
          asset: {
            label: asset.key,
            operation: () => {
              handleUpdates(
                assetUpdateOperations({
                  viewport,
                  elementType: TYPE_TEXT,
                  asset,
                  newProps
                })
              );
            }
          }
        })
      }
    };
  }

  const isTheme = mode === 'theme';
  const actionOptions = useMemo(
    () =>
      getActionOptions({ isTheme, repeatExists }, [
        ACTION_OPTIONS.NEXT,
        ACTION_OPTIONS.BACK,
        ACTION_OPTIONS.URL,
        ACTION_OPTIONS.ADD_REPEATED_ROW,
        ACTION_OPTIONS.REMOVE_REPEATED_ROW,
        ACTION_OPTIONS.NEW_SUBMISSION,
        ACTION_OPTIONS.CUSTOM
      ]),
    [isTheme, repeatExists]
  );

  return (
    <>
      <TextPropertySection
        element={element}
        handlePropChange={handlePropChange}
      />
      <ClickActionSection
        title='When Clicked'
        elementId={element.id}
        elementType='text'
        properties={element.properties}
        actionOptions={actionOptions}
        labelData={labelData}
        handlePropChange={handlePropChange}
        isTheme={isTheme}
        inRepeatContainer={inRepeatContainer}
      />
      {mode === 'builder' && (
        <>
          <LogicSection elementId={element.id} />
          <HideIfsSection
            element={element}
            hideIfs={element.hide_ifs}
            showLogic={element.show_logic}
            handleUpdates={handleUpdates}
          />
        </>
      )}
    </>
  );
}

export default memo(TextPropertiesPanel);
