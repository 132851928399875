function Trello({ width = 70, height = 70 }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='#fff'
      height={height}
      width={width}
      viewBox='0 0 512 512'
    >
      <rect fill='#0079bf' height='512' rx='15%' width='512' />
      <rect height='188' rx='23' width='132' x='284' y='95' />
      <rect height='296' rx='23' width='132' x='97' y='95' />
    </svg>
  );
}

export default Trello;
