import { memo } from 'react';

import { useStore } from 'reactflow';

import styles from '../styles.module.scss';
import { getEdgeParams } from '../graph';

function AnimatedEdge({ id, source, target, style }: any) {
  const nodes = useStore((state) => state.nodeInternals);
  const sourceNode = nodes.get(source);
  const targetNode = nodes.get(target);

  if (!sourceNode || !targetNode) {
    return null;
  }

  const newX = sourceNode.position.x;
  const newY = sourceNode.position.y;
  const width = sourceNode.width ?? 0;
  const height = sourceNode.height ?? 0;
  const shiftNode = {
    ...sourceNode,
    position: { y: newY, x: newX + width / 2 }
  };
  const { tx, ty } = getEdgeParams(shiftNode, {
    ...targetNode,
    width: (targetNode.width ?? 0) + 10
  });
  const d = `M ${newX + width + 10},${newY + height / 2}\n L ${tx},${ty}`;

  return (
    <g>
      <defs>
        <marker
          id='arrowhead-edge'
          markerWidth='6'
          markerHeight='10'
          refX='6'
          refY='5'
          orient='auto'
          markerUnits='userSpaceOnUse'
        >
          <polygon points='0 0, 6 5, 0 10' fill='#e2626e' />
        </marker>
      </defs>
      <path
        id={id}
        className={styles.connectionLine}
        d={d}
        style={style}
        markerEnd='url(#arrowhead-edge)'
      />
    </g>
  );
}

export default memo(AnimatedEdge);
