function RotateIcon({ color = '#414859', width = 30, height = 30, ...props }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 25'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.6667 13.4793C17.1992 16.3243 14.7288 18.4948 11.7514 18.4948C9.88213 18.4948 8.21269 17.6393 7.11327 16.2985L8.95658 14.4551C9.265 14.1467 9.04657 13.6194 8.6104 13.6194L5.86082 13.6194C5.85204 13.5729 5.84379 13.5262 5.83609 13.4793L4.38165 13.4793C4.38781 13.5261 4.39442 13.5728 4.40145 13.6194C4.1336 13.6224 3.91738 13.8404 3.91738 14.109L3.91738 18.3124C3.91738 18.7486 4.44473 18.967 4.75315 18.6586L6.09188 17.3198C7.45536 18.9193 9.48492 19.9336 11.7514 19.9336C15.5249 19.9336 18.6417 17.1219 19.1211 13.4793L17.6667 13.4793ZM17.6667 11.521L19.1212 11.521C19.115 11.4742 19.1084 11.4277 19.1014 11.3812C19.3686 11.3775 19.584 11.1597 19.584 10.8917L19.584 6.68826C19.584 6.25209 19.0566 6.03366 18.7482 6.34208L17.4106 7.67968C16.0471 6.08042 14.0177 5.06625 11.7514 5.06625C7.97773 5.06625 4.86082 7.87813 4.38159 11.521L5.83602 11.521C6.30335 8.67573 8.77387 6.50502 11.7514 6.50502C13.6205 6.50502 15.2898 7.3604 16.3892 8.70106L14.5448 10.5455C14.2364 10.8539 14.4548 11.3813 14.891 11.3813L17.6421 11.3813C17.6508 11.4276 17.6591 11.4742 17.6667 11.521Z'
      />
    </svg>
  );
}

export default RotateIcon;
