import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { cn } from '../../../utils/cn';
import { ReactNode } from 'react';

const TooltipProvider = TooltipPrimitive.Provider;

interface TooltipProps {
  children: ReactNode;
  content: ReactNode;
  asChild?: boolean;
  side?: 'top' | 'right' | 'bottom' | 'left';
  align?: 'center' | 'start' | 'end';
  className?: string;
  disabled?: boolean;
  delay?: number;
}

const Tooltip = ({
  children,
  content,
  asChild = true,
  side = 'top',
  align = 'center',
  className = '',
  disabled = false,
  delay = 0
}: TooltipProps) => {
  return (
    <TooltipPrimitive.Root delayDuration={delay}>
      <TooltipPrimitive.Trigger asChild={asChild}>
        {children}
      </TooltipPrimitive.Trigger>
      <TooltipPrimitive.Portal>
        {!disabled && (
          <TooltipPrimitive.Content
            side={side}
            align={align}
            className={cn(
              'tw-z-[999999999] tw-overflow-hidden',
              'tw-max-w-[28ch] tw-mx-2 tw-text-center',
              'tw-rounded-md tw-bg-black/95 tw-px-3 tw-py-1.5 tw-text-sm tw-text-white',
              'tw-animate-in tw-fade-in-0 tw-zoom-in-95',
              'data-[state=closed]:tw-animate-out data-[state=closed]:tw-fade-out-0 data-[state=closed]:tw-zoom-out-95',
              'data-[side=bottom]:tw-slide-in-from-top-2',
              'data-[side=left]:tw-slide-in-from-right-2',
              'data-[side=right]:tw-slide-in-from-left-2',
              'data-[side=top]:tw-slide-in-from-bottom-2',
              className
            )}
          >
            {content}
          </TooltipPrimitive.Content>
        )}
      </TooltipPrimitive.Portal>
    </TooltipPrimitive.Root>
  );
};

export { Tooltip, TooltipProvider };
