export default function TextFieldIcon({ color = '#414859' }) {
  return (
    <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M27.6363 19.4546H21.5756V21.7273H27.6363C27.9577 21.7273 28.266 21.847 28.4934 22.0601C28.7207 22.2732 28.8484 22.5623 28.8484 22.8637V25.1364H23.9999C23.0358 25.1373 22.1114 25.4967 21.4297 26.1359C20.7479 26.775 20.3645 27.6416 20.3635 28.5455V29.6818C20.3645 30.5857 20.7479 31.4523 21.4297 32.0914C22.1114 32.7306 23.0358 33.09 23.9999 33.0909H31.2726V22.8637C31.2717 21.9598 30.8882 21.0932 30.2065 20.4541C29.5247 19.8149 28.6004 19.4555 27.6363 19.4546ZM23.9999 30.8182C23.6784 30.8182 23.3701 30.6985 23.1428 30.4854C22.9155 30.2723 22.7878 29.9832 22.7878 29.6818V28.5455C22.7878 28.2441 22.9155 27.955 23.1428 27.7419C23.3701 27.5288 23.6784 27.4091 23.9999 27.4091H28.8484V30.8182H23.9999Z'
        fill={color}
      />
      <path
        d='M18.026 33.0909H20.3636L13.3506 14H11.013L4 33.0909H6.33766L8.31766 27.4759H16.0471L18.026 33.0909ZM9.10896 25.2299L12.0275 16.9524H12.3384L15.2558 25.2299H9.10896Z'
        fill={color}
      />
      <path
        d='M36.7046 30.6818C35.8762 30.6818 35.2046 31.3534 35.2046 32.1818C35.2046 33.0102 35.8762 33.6818 36.7046 33.6818V30.6818ZM19.4546 13.4091C18.6262 13.4091 17.9546 14.0806 17.9546 14.9091C17.9546 15.7375 18.6262 16.4091 19.4546 16.4091V13.4091ZM41.75 30.6818H36.7046V33.6818H41.75V30.6818ZM42.5 29.9288C42.5 30.3465 42.1624 30.6818 41.75 30.6818V33.6818C43.823 33.6818 45.5 31.9997 45.5 29.9288H42.5ZM42.5 17.162V29.9288H45.5V17.162H42.5ZM41.75 16.4091C42.1624 16.4091 42.5 16.7443 42.5 17.162H45.5C45.5 15.0912 43.823 13.4091 41.75 13.4091V16.4091ZM19.4546 16.4091H41.75V13.4091H19.4546V16.4091Z'
        fill={color}
      />
    </svg>
  );
}
