import produce from 'immer';
import {
  TYPE_BUTTON,
  TYPE_IMAGE,
  TYPE_PROGRESS_BAR,
  TYPE_TEXT,
  TYPE_VIDEO
} from './elements';
import { v4 as uuidv4 } from 'uuid';

import store from '../redux/store';
import WebFont from 'webfontloader';
import { Viewport } from '../components/RenderingEngine/GridInGrid/engine';
import { S3_URL } from '../api/utils';

const CORNER_PROPS = [
  'corner_top_left_radius',
  'corner_top_right_radius',
  'corner_bottom_left_radius',
  'corner_bottom_right_radius'
];
const BORDER_PROPS = [
  'border_top_color',
  'border_right_color',
  'border_bottom_color',
  'border_left_color',
  'border_top_width',
  'border_right_width',
  'border_bottom_width',
  'border_left_width',
  'border_top_pattern',
  'border_right_pattern',
  'border_bottom_pattern',
  'border_left_pattern'
];
const HOVER_BORDER_PROPS = BORDER_PROPS.map((p) => `hover_${p}`);
const SELECTED_BORDER_PROPS = BORDER_PROPS.map((p) => `selected_${p}`);
const DISABLED_BORDER_PROPS = BORDER_PROPS.map((p) => `disabled_${p}`);

const PADDING_PROPS = [
  'padding_top',
  'padding_right',
  'padding_bottom',
  'padding_left'
];

const CELL_MARGIN_PROPS = [
  'external_padding_top',
  'external_padding_right',
  'external_padding_bottom',
  'external_padding_left'
];

const CTA_PADDING_PROPS = PADDING_PROPS.map((p) => `cta_${p}`);
const UPLOADER_PADDING_PROPS = PADDING_PROPS.map((p) => `uploader_${p}`);

const MARGIN_PROPS = [
  'margin_top',
  'margin_right',
  'margin_bottom',
  'margin_left'
];
const IMAGE_MARGIN_PROPS = MARGIN_PROPS.map((p) => `image_${p}`);
const BUTTON_MARGIN_PROPS = MARGIN_PROPS.map((p) => `button_${p}`);

const FLEX_DIRECTION_OPTIONS = [
  'row',
  'row-reverse',
  'column',
  'column-reverse'
];
const FLEX_DIRECTION_OPTIONS_LABEL = {
  row: 'Left Align',
  'row-reverse': 'Right Align',
  column: 'Above Text',
  'column-reverse': 'Below Text'
};

// Determine what assets prop on the theme object to access based on the element type
function elementTypeToAsset(elementType: any) {
  switch (elementType) {
    case TYPE_BUTTON:
      return 'button_assets';
    case TYPE_TEXT:
      return 'text_assets';
    case TYPE_PROGRESS_BAR:
      return 'progress_bar_assets';
    case TYPE_IMAGE:
      return 'image_assets';
    case TYPE_VIDEO:
      return 'video_assets';
    default:
      return 'servar_field_assets';
  }
}

function themeSelectorToElementType(l2: any, l1: any) {
  switch (l2) {
    case 'button':
      return TYPE_BUTTON;
    case 'image':
      return TYPE_IMAGE;
    case 'video':
      return TYPE_VIDEO;
    case 'text':
      return TYPE_TEXT;
    case 'progress_bar':
      return TYPE_PROGRESS_BAR;
    case 'field':
      return l1 || 'all_fields';
    default:
      return '';
  }
}

/**
 * Helper function to filter style updates to be only changes from the base or override styles.
 */
function filterToStyleOverridesOnly(styleUpdate: any, elementStyles: any) {
  if (!elementStyles) return {};
  // only change the style if update style not equal to the current value (either from base or override)
  return Object.entries(styleUpdate)
    .filter(([key, value]) => value !== elementStyles[key])
    .reduce(
      (cur, [key]) => Object.assign(cur, { [key]: styleUpdate[key] }),
      {}
    );
}

function elementTypeToThemeSelector(elementType: any) {
  switch (elementType) {
    case TYPE_BUTTON:
      return ['button', ''];
    case TYPE_TEXT:
      return ['text', ''];
    case TYPE_PROGRESS_BAR:
      return ['progress_bar', ''];
    case TYPE_IMAGE:
      return ['image', ''];
    case TYPE_VIDEO:
      return ['video', ''];
    default:
      if (elementType) return ['field', elementType];
      else return ['global', ''];
  }
}

/**
 * Helper function for updating a specific theme element, returns the updated theme
 */
function updateThemeElement(theme: any, category: any, transform: any) {
  const index = theme.elements.findIndex(
    (s: any) => s.level_2 === category[0] && s.level_1 === category[1]
  );
  let newTheme = theme;

  // If the element is found, apply the transform and update the overall theme
  if (index !== -1) {
    const element = { ...theme.elements[index] };
    const newElement = transform(element);

    newTheme = {
      ...theme,
      elements: [
        ...theme.elements.slice(0, index),
        newElement,
        ...theme.elements.slice(index + 1)
      ]
    };
  }

  return newTheme;
}

/**
 * Helper function for updating a specific theme asset, returns the updated theme
 */
function updateThemeAsset(
  theme: any,
  elementType: any,
  asset: any,
  transform: any
) {
  const assetThemeProp = elementTypeToAsset(elementType);
  const index = theme[assetThemeProp].findIndex((a: any) => a.id === asset.id);
  if (index === -1) {
    return theme;
  }

  return produce(theme, (draft: any) => {
    draft[assetThemeProp][index] = transform(draft[assetThemeProp][index]);
  });
}

function getThemeStyle(
  themeMap: any,
  viewport: any,
  l2: any,
  l1 = '',
  asset = {}
) {
  const style = themeMap[l2][l1];
  if (asset) {
    return viewport !== Viewport.Mobile
      ? { ...style.styles, ...(asset as any).styles }
      : { ...style.mobileStyles, ...(asset as any).mobile_styles };
  }
  return viewport !== Viewport.Mobile ? style.styles : style.mobileStyles;
}

function getCategories(level2: any, level1 = '') {
  return {
    global: ['global', ''],
    level2: [level2, ''],
    level1: [level2, level1]
  };
}

const themeOperation = (props: any) => ({
  ...props,
  type: 'theme'
});
const assetOperation = (props: any) => ({
  ...props,
  type: 'asset'
});
const elementOperation = (props: any) => ({
  ...props,
  type: 'element'
});
const cellOperation = (props: any) => ({
  ...props,
  type: 'cell'
});

/**
 * Creates a list of operations that represent setting a style at the Global level in a theme,
 * taking into account the viewport (desktop vs. mobile)
 */
function globalUpdateOperations({
  viewport,
  elementType,
  asset,
  newStyle,
  level2,
  level1
}: any) {
  const styleReset = Object.keys(newStyle);
  const c = getCategories(level2, level1);

  // Set the new style at the global level, then wipe each level below it
  const operations = [
    themeOperation({ viewport, selector: c.global, styleUpdate: newStyle }),
    themeOperation({ viewport, selector: c.level2, styleReset }),
    elementOperation({ viewport, styleReset })
  ];

  level1 &&
    operations.push(
      themeOperation({ viewport, selector: c.level1, styleReset })
    );
  asset &&
    operations.push(
      assetOperation({ viewport, elementType, asset, styleReset })
    );

  if (viewport === Viewport.Mobile) {
    operations.push(
      themeOperation({
        viewport: Viewport.Desktop,
        selector: c.level2,
        styleReset
      })
    );
    operations.push(
      elementOperation({ viewport: Viewport.Desktop, styleReset })
    );

    level1 &&
      operations.push(
        themeOperation({
          viewport: Viewport.Desktop,
          selector: c.level1,
          styleReset
        })
      );
    asset &&
      operations.push(
        assetOperation({
          viewport: Viewport.Desktop,
          elementType,
          asset,
          styleReset
        })
      );
  }

  return operations;
}

/**
 * Creates a list of operations that represent setting a style at the Level 2 level in a theme,
 * taking into account the viewport (desktop vs. mobile)
 */
function level2UpdateOperations({
  viewport,
  elementType,
  asset,
  newStyle,
  level2,
  level1
}: any) {
  const styleReset = Object.keys(newStyle);
  const c = getCategories(level2, level1);

  const operations = [
    themeOperation({ viewport, selector: c.level2, styleUpdate: newStyle }),
    elementOperation({ viewport, styleReset })
  ];

  level1 &&
    operations.push(
      themeOperation({ viewport, selector: c.level1, styleReset })
    );
  asset &&
    operations.push(
      assetOperation({ viewport, elementType, asset, styleReset })
    );

  if (viewport === Viewport.Mobile) {
    operations.push(
      elementOperation({ viewport: Viewport.Desktop, styleReset })
    );

    level1 &&
      operations.push(
        themeOperation({
          viewport: Viewport.Desktop,
          category: c.level1,
          styleReset
        })
      );
    asset &&
      operations.push(
        assetOperation({
          viewport: Viewport.Desktop,
          elementType,
          asset,
          styleReset
        })
      );
  }

  return operations;
}

/**
 * Creates a list of operations that represent setting a style at the Level 1 level in a theme,
 * taking into account the viewport (desktop vs. mobile)
 */
function level1UpdateOperations({
  viewport,
  elementType,
  asset,
  newStyle,
  level2,
  level1
}: any) {
  const styleReset = Object.keys(newStyle);
  const c = getCategories(level2, level1);

  const operations = [
    themeOperation({ viewport, selector: c.level1, styleUpdate: newStyle }),
    elementOperation({ viewport, styleReset })
  ];

  asset &&
    operations.push(
      assetOperation({ viewport, elementType, asset, styleReset })
    );

  if (viewport === Viewport.Mobile) {
    operations.push(
      elementOperation({ viewport: Viewport.Desktop, styleReset })
    );

    asset &&
      operations.push(
        assetOperation({
          viewport: Viewport.Desktop,
          elementType,
          asset,
          styleReset
        })
      );
  }

  return operations;
}

/**
 * Creates a list of operations that represent setting a style at the asset level in a theme,
 * taking into account the viewport (desktop vs. mobile)
 */
function assetUpdateOperations({
  viewport,
  elementType,
  asset,
  newStyle = {},
  newProps = {},
  newElementProps = {}
}: any) {
  const propReset = Object.keys(newProps);
  const styleReset = Object.keys(newStyle);

  const operations = [
    assetOperation({
      viewport,
      elementType,
      asset,
      propUpdate: { ...newElementProps, properties: newProps },
      styleUpdate: newStyle
    }),
    elementOperation({ viewport, propReset, styleReset })
  ];

  if (viewport === Viewport.Mobile) {
    operations.push(
      elementOperation({
        viewport: Viewport.Desktop,
        propReset,
        styleReset
      })
    );
  }

  return operations;
}

/**
 * Does the element have styles, mobile_styles, or properties
 */
function elementHasOverrides(element: any) {
  const overrideExists = (data: any) => data && Object.keys(data).length > 0;
  // Rich text prop always exists in properties as an array even if it's not overridden
  const { text_formatted: _, ...noTfProps } = element.properties;
  return (
    overrideExists(element.styles) ||
    overrideExists(element.mobile_styles) ||
    overrideExists(noTfProps)
  );
}

const FONT_ATTRIBUTES = [
  'font_size',
  'font_family',
  'font_color',
  'font_weight',
  'font_italic',
  'font_strike',
  'font_underline',
  'font_link',
  'letter_spacing',
  'text_transform'
];
const FONT_WEIGHT_OPTIONS = [100, 200, 300, 400, 500, 600, 700, 800, 900];
const FONT_STYLE_ATTRIBUTES = ['font_italic', 'font_underline', 'font_strike'];
const loadedFonts = new Set();
export const isFontLoaded = {
  uploadedFonts: true,
  storeFonts: true
};

export const hasLoadedFonts = () => {
  return isFontLoaded.uploadedFonts && isFontLoaded.storeFonts;
};

const loadFont = (styles: any) => {
  const family = styles.font_family;

  if (!('font_family' in styles) || loadedFonts.has(family)) {
    return;
  }

  const fontState = store.getState().integrations;
  const isUploadedFont = family in fontState.uploadedFonts;
  const storeHasFontFamily = fontState.defaultFonts.some(
    (defaultFont) => (defaultFont as any).value === family
  );

  if (storeHasFontFamily || isUploadedFont) {
    isFontLoaded.uploadedFonts = !isUploadedFont;
    isFontLoaded.storeFonts = !storeHasFontFamily;
  }

  if (storeHasFontFamily) {
    loadedFonts.add(family);
    let toLoad = `${family}:`;

    FONT_WEIGHT_OPTIONS.forEach(
      (option) => (toLoad += `${option},${option}italic,`)
    );

    WebFont.load({
      google: { families: [toLoad] },
      active: () => {
        isFontLoaded.storeFonts = true;
      }
    });
  } else if (family in fontState.uploadedFonts) {
    loadedFonts.add(family);
    const fontData = (fontState as any).uploadedFonts[family];

    Promise.all(
      fontData.map((data: any) =>
        loadFontWithFallback(family, data.source, data.style, data.weight)
      )
    ).then(() => {
      isFontLoaded.uploadedFonts = true;
    });
  }
};

function loadFontWithFallback(
  family: string,
  source: string,
  style: string,
  weight: string
): Promise<void | FontFaceSet> {
  const loadFont = (url: string) =>
    new FontFace(family, `url(${url})`, { style, weight })
      .load()
      .then((font) => document.fonts.add(font));

  return loadFont(source).catch(() => {
    // Attempt fallback to S3 if the CloudFront URL fails
    const fallback = new URL(source);
    fallback.hostname = S3_URL;
    return loadFont(fallback.toString()).catch((e) =>
      console.warn(`Font load issue: ${e}`)
    );
  });
}

/**
 * Convert properties within text_formatted to mobile or desktop properties
 */
function getFormattedTextStyle(
  textFormatted: any,
  baseStyles: any,
  mobileStyles = null,
  loadFonts = true
) {
  return textFormatted.map((op: any) => {
    const attributes = {};
    FONT_ATTRIBUTES.forEach((attr) => {
      let attrVal = op.attributes?.[attr] || baseStyles[attr];
      if (mobileStyles) {
        const mobileAttr = `mobile_${attr}`;
        attrVal =
          op.attributes?.[mobileAttr] ||
          baseStyles[mobileAttr] ||
          mobileStyles[attr] ||
          attrVal;
      }
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      attributes[attr] = attrVal;
    });
    if (loadFonts) {
      loadFont(attributes);
    }
    return { ...op, attributes };
  });
}

function getAsset(
  theme: any,
  elementType: string,
  assetId?: string,
  assetKey?: string
) {
  return theme[elementTypeToAsset(elementType)].find(
    (a: any) => a.id === assetId || a.key === assetKey
  );
}

function flattenWithAsset(theme: any, elementType: any, element: any) {
  if (element.source_asset) {
    const asset = getAsset(theme, elementType, element.source_asset);

    // Possible if asset deleted from theme but form draft still has references
    if (!asset) return element;

    return {
      ...element,
      properties: { ...asset.properties, ...element.properties },
      styles: { ...asset.styles, ...element.styles }
    };
  }

  return element;
}

// To render correctly, some fields need data (e.g. placeholder, text_formatted) which aren't in styles
const ELEMENT_DATA = {
  button: {
    id: uuidv4(),
    properties: {
      text_formatted: [{ insert: 'Button' }],
      text: 'Button',
      image: '',
      actions: [{ type: 'next' }]
    }
  },
  button_group: {
    id: uuidv4(),
    servar: {
      key: 'button_group',
      type: 'button_group',
      option_images: ['', ''],
      metadata: {
        options: ['Button 1', 'Button 2'],
        option_tooltips: [null, null],
        option_labels: [null, null],
        option_images: ['', '']
      }
    },
    properties: {}
  },
  checkbox: {
    id: uuidv4(),
    servar: {
      key: 'checkbox',
      type: 'checkbox',
      metadata: {}
    },
    properties: {}
  },
  multiselect: {
    id: uuidv4(),
    servar: {
      key: 'multiselect',
      type: 'multiselect',
      metadata: {
        options: ['Option 1', 'Option 2'],
        option_labels: [null, null],
        option_tooltips: [null, null],
        other: true
      }
    },
    properties: {}
  },
  dropdown: {
    id: uuidv4(),
    servar: {
      key: 'dropdown',
      type: 'dropdown',
      metadata: {
        options: ['Option 1', 'Option 2'],
        option_labels: [null, null],
        option_tooltips: [null, null]
      }
    },
    properties: {
      placeholder: 'Dropdown'
    }
  },
  dropdown_multi: {
    id: uuidv4(),
    servar: {
      key: 'dropdown_multi',
      type: 'dropdown_multi',
      metadata: {
        options: ['Option 1', 'Option 2'],
        option_labels: [null, null],
        option_tooltips: [null, null]
      }
    },
    properties: {
      placeholder: 'Dropdown Multiselect'
    }
  },
  email: {
    id: uuidv4(),
    servar: {
      key: 'email',
      type: 'email',
      metadata: {}
    },
    properties: {
      placeholder: 'Email',
      tooltipText: 'This is a tip'
    }
  },
  file_upload: {
    id: uuidv4(),
    servar: {
      key: 'file_upload',
      type: 'file_upload',
      metadata: { file_types: [] }
    },
    properties: {}
  },
  image: {
    id: uuidv4(),
    properties: { source_image: '' }
  },
  video: {
    id: uuidv4(),
    properties: { source_url: 'https://www.youtube.com/embed/dmg36v0zYbM' }
  },
  date_selector: {
    id: uuidv4(),
    servar: {
      type: 'date_selector',
      metadata: {}
    },
    properties: {}
  },
  password: {
    id: uuidv4(),
    servar: {
      type: 'password',
      metadata: {}
    },
    properties: {}
  },
  signature: {
    id: uuidv4(),
    servar: {
      type: 'signature',
      metadata: {}
    },
    properties: {}
  },
  hex_color: {
    id: uuidv4(),
    servar: {
      type: 'hex_color',
      metadata: {}
    },
    properties: {}
  },
  pin_input: {
    id: uuidv4(),
    servar: {
      key: 'pin_input',
      type: 'pin_input',
      max_length: 5,
      metadata: {}
    },
    properties: {}
  },
  qr_scanner: {
    id: uuidv4(),
    servar: {
      key: 'qr_scanner',
      type: 'qr_scanner',
      metadata: {}
    },
    properties: {}
  },
  select: {
    id: uuidv4(),
    servar: {
      key: 'select',
      type: 'select',
      metadata: {
        options: ['Option 1', 'Option 2'],
        option_labels: [null, null],
        option_tooltips: [null, null],
        other: true
      }
    },
    properties: {}
  },
  text_field: {
    id: uuidv4(),
    servar: {
      type: 'text_field',
      metadata: {}
    },
    properties: {
      placeholder: 'Text Field',
      tooltipText: 'This is a tip'
    }
  },
  text_area: {
    id: uuidv4(),
    servar: {
      type: 'text_area',
      metadata: {}
    },
    properties: {
      placeholder: 'Text Area',
      tooltipText: 'This is a tip'
    }
  },
  url: {
    id: uuidv4(),
    servar: {
      key: 'url',
      type: 'url',
      metadata: {}
    },
    properties: {
      placeholder: 'URL',
      tooltipText: 'This is a tip'
    }
  },
  progress_bar: {
    id: uuidv4(),
    properties: {
      progress: 50
    }
  },
  text: {
    id: uuidv4(),
    properties: {
      text_formatted: [
        {
          insert:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
        }
      ],
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      actions: []
    }
  },
  integer_field: {
    id: uuidv4(),
    servar: {
      type: 'integer_field',
      metadata: {}
    },
    properties: {
      placeholder: 'Number',
      tooltipText: 'This is a tip'
    }
  },
  slider: {
    id: uuidv4(),
    servar: {
      type: 'slider',
      metadata: {}
    },
    properties: {}
  },
  rating: {
    id: uuidv4(),
    servar: {
      type: 'rating',
      metadata: { max_length: 5 }
    },
    properties: {}
  },
  phone_number: {
    id: uuidv4(),
    servar: {
      type: 'phone_number',
      metadata: {}
    },
    properties: {
      placeholder: 'Phone Number',
      tooltipText: 'This is a tip'
    }
  },
  ssn: {
    id: uuidv4(),
    servar: {
      type: 'ssn',
      metadata: {}
    },
    properties: {
      placeholder: 'Social Security Number',
      tooltipText: 'This is a tip'
    }
  },
  gmap_line_1: {
    id: uuidv4(),
    servar: {
      type: 'gmap_line_1',
      metadata: {}
    },
    properties: {
      placeholder: 'Street Address Line 1',
      tooltipText: 'This is a tip'
    }
  },
  gmap_line_2: {
    id: uuidv4(),
    servar: {
      type: 'gmap_line_2',
      metadata: {}
    },
    properties: {
      placeholder: 'Street Address Line 2',
      tooltipText: 'This is a tip'
    }
  },
  gmap_city: {
    id: uuidv4(),
    servar: {
      type: 'gmap_city',
      metadata: {}
    },
    properties: {
      placeholder: 'City',
      tooltipText: 'This is a tip'
    }
  },
  gmap_state: {
    id: uuidv4(),
    servar: {
      type: 'gmap_state',
      metadata: {}
    },
    properties: {
      placeholder: 'State',
      tooltipText: 'This is a tip'
    }
  },
  gmap_zip: {
    id: uuidv4(),
    servar: {
      type: 'gmap_zip',
      metadata: {}
    },
    properties: {
      placeholder: 'Zipcode',
      tooltipText: 'This is a tip'
    }
  },
  gmap_country: {
    id: uuidv4(),
    servar: {
      type: 'gmap_country',
      metadata: {}
    },
    properties: {
      placeholder: 'Country',
      tooltipText: 'This is a tip'
    }
  },
  payment_method: {
    id: uuidv4(),
    servar: {
      type: 'payment_method',
      metadata: {}
    },
    properties: {
      placeholder: 'Payment Method',
      tooltipText: 'This is a tip'
    }
  }
};

export {
  CORNER_PROPS,
  BORDER_PROPS,
  BUTTON_MARGIN_PROPS,
  DISABLED_BORDER_PROPS,
  HOVER_BORDER_PROPS,
  SELECTED_BORDER_PROPS,
  PADDING_PROPS,
  CELL_MARGIN_PROPS,
  MARGIN_PROPS,
  CTA_PADDING_PROPS,
  UPLOADER_PADDING_PROPS,
  IMAGE_MARGIN_PROPS,
  FLEX_DIRECTION_OPTIONS,
  FLEX_DIRECTION_OPTIONS_LABEL,
  FONT_ATTRIBUTES,
  FONT_STYLE_ATTRIBUTES,
  FONT_WEIGHT_OPTIONS,
  ELEMENT_DATA,
  cellOperation,
  themeOperation,
  assetOperation,
  elementOperation,
  globalUpdateOperations,
  level2UpdateOperations,
  level1UpdateOperations,
  assetUpdateOperations,
  elementTypeToAsset,
  elementHasOverrides,
  themeSelectorToElementType,
  filterToStyleOverridesOnly,
  elementTypeToThemeSelector,
  updateThemeElement,
  updateThemeAsset,
  getThemeStyle,
  getFormattedTextStyle,
  getAsset,
  flattenWithAsset,
  loadFont,
  loadedFonts
};
