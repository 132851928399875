import { Spinner } from 'react-bootstrap';
import { PUBLISH_STATUS } from '../../../redux/utils';
import { Button } from '../../Core/Button/button';

const SaveButton = ({
  submittingStatus,
  onClick
}: {
  submittingStatus: string;
  onClick: any;
}) => {
  return (
    <Button variant='text-primary' type='submit' onClick={onClick}>
      {submittingStatus === PUBLISH_STATUS.LOADING ? (
        <Spinner animation='border' size='sm' />
      ) : (
        'Save Changes'
      )}
    </Button>
  );
};

export default SaveButton;
