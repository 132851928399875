import '../../style/dialog-form.css';

import { Form } from 'react-bootstrap';
import { useState } from 'react';

import { DropdownMultiField, InlineTooltip, PropertyLabel } from '../Core';
import IntegrationsSidebar from '../FormIntegrations/IntegrationsSidebar';
import useIntegrations from '../FormIntegrations/useIntegrations';
import { useParams } from 'react-router-dom';
import { AI_INTEGRATION_TYPES, AI_INTEGRATIONS_MAP } from './types';
import Combobox from '../Core/Combobox';
import { useAppSelector } from '../../hooks';

const DEFAULT_INBOX_CONFIG = {
  domains: [],
  panels: []
};

function InboxSettingsSidebar() {
  const { extractionId } = useParams<{ extractionId: string }>();

  const panels = useAppSelector((state) => state.panels.panels);

  const integration = useIntegrations({
    entityType: 'ai',
    type: AI_INTEGRATION_TYPES.EMAIL_INBOX,
    extractionId
  });

  const [inboxConfig, setInboxConfig] = useState(
    integration?.data.metadata ?? DEFAULT_INBOX_CONFIG
  );

  const [isPartial, setIsPartial] = useState(false);
  const [err, setErr] = useState('');

  const updateConfig = (key: string, val: any) => {
    setInboxConfig({ ...inboxConfig, [key]: val });
  };

  function onSubmitCustom(newIsActive: boolean) {
    setErr('');

    if (newIsActive) {
      const partial = inboxConfig.domains.length === 0;
      setIsPartial(partial);
      if (partial) return;
    }

    return {
      isUpdate: integration?.data,
      metadata: inboxConfig
    };
  }

  return (
    <IntegrationsSidebar
      integrationInfo={AI_INTEGRATIONS_MAP[AI_INTEGRATION_TYPES.EMAIL_INBOX]}
      isPartial={isPartial}
      onSubmitCustom={onSubmitCustom}
      customError={err}
      entityType='ai'
    >
      <Form className='integration-modal-form'>
        <Form.Group controlId='inbox-domains'>
          <PropertyLabel
            label={
              <>
                Email Domains{' '}
                <InlineTooltip
                  text='To extract a document attached to an email, forward it to documents@feathery.io from an email address within the specified domains. You may only specify domains from your Feathery account emails.'
                  inline
                />
              </>
            }
          />
          <Combobox
            value={inboxConfig.domains}
            onChange={(items: string[]) => {
              updateConfig('domains', items);
            }}
            placeholder='Type a domain and press enter...'
          />
        </Form.Group>
        <Form.Group controlId='inbox-forms'>
          <PropertyLabel
            label={
              <>
                New Form Submissions{' '}
                <InlineTooltip
                  text='(Optional) Automatically start new form submissions from the extracted document data'
                  inline
                />
              </>
            }
          />
          <DropdownMultiField
            selected={inboxConfig.panels ?? []}
            options={Object.values(panels).map((panel: any) => ({
              value: panel.id,
              label: panel.key
            }))}
            onChange={(items: { value: string }[]) =>
              updateConfig(
                'panels',
                items.map((item) => item.value)
              )
            }
            placeholder='Choose forms'
          />
        </Form.Group>
      </Form>
    </IntegrationsSidebar>
  );
}

export default InboxSettingsSidebar;
