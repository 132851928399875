import { PlusCircleIcon } from '../../../Icons';
import testIds from '../../../../utils/testIds';

export const PlusButton = ({ touchSize, onMouseDown, style, color }: any) => {
  return (
    <div
      data-testid={testIds.plusButton}
      onMouseDown={onMouseDown}
      style={{
        width: touchSize,
        height: touchSize,
        backgroundColor: 'transparent',
        borderRadius: touchSize / 2,
        color: 'white',
        textAlign: 'center',
        userSelect: 'none',
        pointerEvents: 'auto',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        position: 'absolute',
        cursor: 'pointer',
        ...style
      }}
    >
      <PlusCircleIcon
        width={touchSize}
        height={touchSize}
        color={'white'}
        background={color}
      />
    </div>
  );
};

export default PlusButton;
