import CanvasRefs, { CanvasElement } from './CanvasRefs';
import { RefObject } from 'react';
import { Cell } from '../GridInGrid/engine';

const getKey = (node: Cell) => node.position.join(',');

const eventType = 'CanvasRefsEvent';

const useCanvasRefs = () => {
  const linkDiv = (
    node: Cell,
    ref: RefObject<HTMLDivElement>,
    portalRef: RefObject<HTMLDivElement>
  ) => {
    const key = getKey(node);

    if (!ref.current) return;

    const canvasElement: CanvasElement = {
      key: key,
      ref: ref,
      node,
      portalRef: portalRef,
      rect: ref.current.getBoundingClientRect()
    };

    CanvasRefs.setCanvasElement(key, canvasElement);
  };

  const unlinkDiv = (node: Cell, position: number[]) => {
    CanvasRefs.removeCanvasElement(position.join(','));
  };

  const listen = (
    node: Cell,
    callbackOn: (eventDetail: any) => void,
    callbackOff: (eventDetail: any) => void
  ): (() => void) => {
    const key = getKey(node);

    const listener = (event: CustomEvent) => {
      if (event.detail.key === key) {
        callbackOn(event.detail);
      } else {
        callbackOff(event.detail);
      }
    };

    // @ts-ignore
    document.addEventListener(eventType, listener);

    // @ts-ignore
    return () => document.removeEventListener(eventType, listener);
  };

  const dispatch = (key: string | Cell, details: object) => {
    if (typeof key !== 'string') key = getKey(key);
    const customEvent = new CustomEvent(eventType, {
      detail: { key, ...details }
    });
    document.dispatchEvent(customEvent);
  };

  const getDivs = () => {
    return CanvasRefs.getAllCanvasElements();
  };

  const getData = () => {
    return CanvasRefs.data;
  };

  const getCanvasElement = (key: string): CanvasElement => {
    return CanvasRefs.getCanvasElement(key);
  };

  return {
    getData,
    getDivs,
    getCanvasElement,
    linkDiv,
    unlinkDiv,
    listen,
    dispatch,
    update: CanvasRefs.update
  };
};

export default useCanvasRefs;
