export default function CheckCircleIcon({
  width = 32,
  height = 32,
  color = '#ffffff',
  backgroundColor = '#0ebe74'
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 14 14'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='1.66797'
        y='1.66663'
        width='10.6667'
        height='10.6667'
        rx='5.33333'
        fill={backgroundColor}
        stroke={backgroundColor}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.33594 7.25046L5.9952 8.86663L9.31372 5.66663'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='transparent'
      />
    </svg>
  );
}
