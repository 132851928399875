function Sendgrid({ width = 128, height = 128 }) {
  return (
    <svg
      width={width}
      height={height}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 792 230.7'
      enableBackground='new 0 0 792 230.7'
      xmlSpace='preserve'
    >
      <g>
        <path
          style={{ fill: '#243746' }}
          d='M179.3,176.3l18.5-14.5c5.2,9,13.5,14.2,23,14.2c10.3,0,15.8-6.7,15.8-14c0-8.7-10.6-11.5-21.9-15   c-14.2-4.4-30.1-9.9-30.1-30.2c0-17,14.8-30.5,35.3-30.5c17.3,0,27.2,6.5,35.8,15.4l-16.7,12.6c-4.4-6.5-10.6-10-18.9-10   c-9.4,0-14.5,5.1-14.5,11.8c0,8.1,10.2,10.9,21.5,14.7c14.4,4.7,30.7,11,30.7,31.4c0,16.9-13.4,33.1-36.8,33.1   C201.7,195.4,188.9,187.2,179.3,176.3z M341.9,121.2h19.8v8.1c4.7-6,11.8-9.6,20.1-9.6c17.1,0,27.5,11,27.5,29.8v44.3H389v-41.7   c0-9.7-4.5-15.4-13.4-15.4c-7.6,0-13.8,5.2-13.8,17.4v39.7h-19.9V121.2z M413.9,157.6c0-25.7,18.9-37.8,35.3-37.8   c9.4,0,16.9,3.5,21.7,8.6V87.8h19.9v106.1h-19.9v-8.1c-4.8,5.7-12.5,9.6-21.9,9.6C433.5,195.4,413.9,183.1,413.9,157.6z    M471.4,157.4c0-10.8-7.8-19.5-18.6-19.5c-11,0-19.5,8.3-19.5,19.5c0,11.2,8.4,19.6,19.5,19.6C463.6,177,471.4,168.2,471.4,157.4z    M495.4,140.9c0-30.2,22.8-54.5,54.2-54.5c15.7,0,28.9,5.7,38.5,14.7c3.9,3.7,7.2,7.9,9.8,12.5l-17.7,10.8   c-6.5-12.1-16.6-18.3-30.4-18.3c-19.3,0-34.3,15.8-34.3,34.9c0,19.5,14.7,34.9,35,34.9c15.4,0,26.3-8.7,30.1-22.2h-33.3v-19.3h55.1   v8.1c0,28.5-20.3,53-51.9,53C517.3,195.4,495.4,170.2,495.4,140.9z M607.4,121.2h19.8v11.9c3.6-7.6,10.2-11.9,20.1-11.9h8   l-7.2,19.3h-5.4c-10.6,0-15.3,5.5-15.3,19v34.3h-19.9V121.2z M660.4,121.2h19.9v72.7h-19.9v-53.3l-7.2,0L660.4,121.2z M670.4,110.2   c6.6,0,11.9-5.3,11.9-11.9c0-6.6-5.3-11.9-11.9-11.9c-6.6,0-11.9,5.3-11.9,11.9C658.5,104.8,663.8,110.2,670.4,110.2z M685,157.6   c0-25.7,18.9-37.8,35.3-37.8c9.4,0,16.9,3.5,21.7,8.6V87.8h19.9v106.1H742v-8.1c-4.8,5.7-12.5,9.6-21.9,9.6   C704.6,195.4,685,183.1,685,157.6z M742.6,157.4c0-10.8-7.8-19.5-18.6-19.5c-11,0-19.5,8.3-19.5,19.5c0,11.2,8.4,19.6,19.5,19.6   C734.7,177,742.6,168.2,742.6,157.4z M337.2,157.4c0-20.9-15.3-37.6-37.5-37.6c-20.9,0-37.8,16.9-37.8,37.8   c0,20.9,15.7,37.8,38.4,37.8c15.7,0,27-7.6,33.3-18.4l-15.8-9.4c-3.3,6.4-9.8,10.4-17.4,10.4c-10.5,0-17-5.2-19.3-13.2h56.1V157.4z    M282,148.7c3.1-6.8,9.4-11.5,17.9-11.5c8.4,0,14.7,3.9,17.3,11.5H282z'
        />
      </g>
      <g>
        <rect
          x='70.6'
          y='151.5'
          style={{ fill: '#99E1F4' }}
          width='42.4'
          height='42.4'
        />
        <polygon
          style={{ fill: '#99E1F4' }}
          points='70.6,109 70.6,109 70.6,109 28.2,109 28.2,151.5 70.6,151.5  '
        />
        <rect
          x='28.2'
          y='151.5'
          style={{ fill: '#1A82E2' }}
          width='42.4'
          height='42.4'
        />
        <polygon
          style={{ fill: '#00B2E3' }}
          points='155.5,109 155.5,109 113.1,109 113.1,151.5 155.5,151.5 155.5,109  '
        />
        <rect
          x='70.6'
          y='66.6'
          style={{ fill: '#00B2E3' }}
          width='42.4'
          height='42.4'
        />
        <polygon
          style={{ fill: '#009DD9' }}
          points='70.6,109 70.6,151.5 113.1,151.5 113.1,109 113.1,109  '
        />
        <rect
          x='113.1'
          y='66.6'
          style={{ fill: '#1A82E2' }}
          width='42.4'
          height='42.4'
        />
      </g>
      <g>
        <g>
          <path
            style={{ fill: '#243746' }}
            d='M199.4,41v24.7h-5.7V41h-8.8V36h23.4l0,4.9H199.4z'
          />
          <path
            style={{ fill: '#243746' }}
            d='M249.9,65.7h-5.4l-6.6-21.6l-6.6,21.6h-5.4l-8.3-29.8l6,0.1l5.5,21l6.2-21h5.7l6.2,21l5.4-21h5.7L249.9,65.7z    '
          />
          <path
            style={{ fill: '#243746' }}
            d='M269.7,65.7V36h5.7v29.7H269.7z'
          />
          <path
            style={{ fill: '#243746' }}
            d='M288.7,65.7V36h5.7v24.7h9.9l0.1,5H288.7z'
          />
          <path
            style={{ fill: '#243746' }}
            d='M316.2,65.7V36h5.7v29.7H316.2z'
          />
          <path
            style={{ fill: '#243746' }}
            d='M348,66.3c-8.4,0-13.9-6.2-13.9-15.4c0-9.2,5.5-15.4,14-15.4c8.5,0,13.9,6.2,13.9,15.4    C361.9,60,356.5,66.3,348,66.3z M348,40.2c-5.1,0-8.1,4.2-8.1,10.7s3.1,10.7,8.1,10.7c5.1,0,8.2-4.2,8.2-10.7S353.1,40.2,348,40.2    z'
          />
        </g>
      </g>
    </svg>
  );
}

export default Sendgrid;
