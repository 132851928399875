/* eslint-disable react-hooks/exhaustive-deps */

import { memo, useState } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';
import useElementDrag, {
  ElementDragOperation
} from '../../../CustomDragLayer/hooks/useElementDrag';

function ListItem({
  mode,
  icon,
  label,
  elementType,
  onElementMouseDown,
  asset,
  disabled,
  separator,
  ...props
}: any) {
  const [hovered, setHovered] = useState(false);

  const drag = useElementDrag(() => ({
    type: elementType,
    operation: ElementDragOperation.New,
    assetId: asset?.id,
    opts: {
      preview: {
        anchor: 'center'
      }
    }
  }));

  return (
    <div
      className={classNames(
        styles.listItem,
        hovered && !disabled && styles.highlighted,
        disabled && styles.disabled,
        separator && styles.separator
      )}
      style={{ paddingLeft: mode === 'click' ? '12px' : undefined }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onMouseDown={() => setHovered(false)}
      onMouseUp={() => setHovered(true)}
      {...(mode === 'drag' && { ref: drag })}
      {...(['nav', 'click'].includes(mode) && {
        onMouseDown: () => onElementMouseDown(elementType, asset)
      })}
      {...props}
    >
      <div className={styles.icon}>{icon}</div>
      <span className={styles.label}>{label}</span>
    </div>
  );
}

export default memo(ListItem);
