export const ignoreDrawControls = 'ignore-draw-controls';

export const sideControlClass = 'control-layer-line';
export const labelControlClass = 'control-layer-label';

export const textEditControlClass = 'control-layer-text';

export interface point {
  x: number;
  y: number;
}

const sqr = (n: number) => {
  return Math.pow(n, 2);
};

const distanceSqr = (p1: point, p2: point) => {
  return sqr(p1.x - p2.x) + sqr(p1.y - p2.y);
};

const distance = (p1: point, p2: point) => {
  return Math.sqrt(distanceSqr(p1, p2));
};

const distanceToLineSqr = (p1: point, p2: point, p3: point) => {
  const l1 = distanceSqr(p2, p3);

  if (l1 === 0) return distanceSqr(p1, p2);

  const t =
    ((p1.x - p2.x) * (p3.x - p2.x) + (p1.y - p2.y) * (p3.y - p2.y)) / l1;

  if (t < 0) return distanceSqr(p1, p3);
  if (t > 1) return distanceSqr(p1, p3);

  return distanceSqr(p1, {
    x: p2.x + t * (p3.x - p2.x),
    y: p2.y + t * (p3.y - p2.y)
  });
};

const distanceToLine = (
  p1: point,
  p2: point,
  p3: point,
  scrollOffset: point
) => {
  const op1 = { ...p1 };
  const op2 = { ...p2 };
  const op3 = { ...p3 };

  op1.y += scrollOffset.y;
  op2.y += scrollOffset.y;
  op3.y += scrollOffset.y;

  op1.x += scrollOffset.x;
  op2.x += scrollOffset.x;
  op3.x += scrollOffset.x;

  return Math.sqrt(distanceToLineSqr(op1, op2, op3));
};

const keepIds = (childrenIds: any[], containerId: string) => {
  const childrenIdSet = new Set(childrenIds);

  const container = document.getElementById(containerId);
  if (!container) return;

  for (let i = 0; i < container.children.length; i++) {
    const child = container.children[i];
    if (!childrenIdSet.has(child.id)) child.remove();
  }
};

const drawBox = (
  boxId: string,
  elementId: string,
  boundingBox: DOMRect,
  scrollOffset: point = { x: 0, y: 0 },
  size = 1,
  color = '#0084ff',
  borderStyle = 'solid'
) => {
  const container = document.getElementById(elementId);

  if (!container) return;

  const b = boundingBox;

  let box;
  const foundBox = document.getElementById(boxId);
  if (foundBox) {
    box = foundBox;
  } else {
    box = document.createElement('div');
  }

  box.id = boxId;
  box.style.position = 'absolute';
  box.style.width = b.width + size + 'px';
  box.style.height = b.height + size + 'px';
  box.style.border = `${size}px ${borderStyle} ${color}`;
  box.style.top = b.top - 55 + scrollOffset.y - size / 2 + 'px';
  box.style.left = b.left + scrollOffset.x - size / 2 + 'px';
  box.style.contain = 'size style';

  if (!foundBox) container.appendChild(box);

  return box;
};

const drawLine = (
  lineId: string,
  elementId: string,
  p1: point,
  p2: point,
  scrollOffset: point = { x: 0, y: 0 },
  color: string,
  size: number,
  className: string
): HTMLElement | null => {
  const container = document.getElementById(elementId);

  if (!container) return null;

  let line;
  const foundLine = document.getElementById(lineId);
  if (foundLine) {
    line = foundLine;
  } else {
    line = document.createElement('div');
    line.classList.add(className);
  }

  const op1 = { ...p1 };
  const op2 = { ...p2 };

  line.id = lineId;
  line.classList.add(sideControlClass);
  line.style.position = 'absolute';
  line.style.top = op1.y - 55 - size / 2 + 'px';
  line.style.left = op1.x - size / 2 + 'px';
  line.style.width = Math.max(0, op2.x - op1.x) + size + 'px';
  line.style.height = Math.max(0, op2.y - op1.y) + size + 'px';
  line.style.zIndex = '1';
  line.style.background = color;
  line.style.contain = 'size style';

  if (!foundLine) container.appendChild(line);

  return line;
};

const isPointInBoundingBox = (
  rect: DOMRect,
  p1: point,
  scrollOffset: point
) => {
  if (!p1) return false;

  const { x, y } = p1;

  return (
    y >= rect.top + scrollOffset.y &&
    y < rect.bottom + scrollOffset.y &&
    x >= rect.left + scrollOffset.x &&
    x < rect.right + scrollOffset.x
  );
};

const getBoundingBoxSides = (
  rect: DOMRect,
  scrollOffset: point
): { Top: point[]; Right: point[]; Bottom: point[]; Left: point[] } => {
  const topLeft = {
    x: rect.left + scrollOffset.x,
    y: rect.top + scrollOffset.y
  };
  const topRight = {
    x: rect.right + scrollOffset.x,
    y: rect.top + scrollOffset.y
  };
  const bottomRight = {
    x: rect.right + scrollOffset.x,
    y: rect.bottom + scrollOffset.y
  };
  const bottomLeft = {
    x: rect.left + scrollOffset.x,
    y: rect.bottom + scrollOffset.y
  };

  return {
    Top: [topLeft, topRight],
    Right: [topRight, bottomRight],
    Bottom: [bottomLeft, bottomRight],
    Left: [topLeft, bottomLeft]
  };
};

const getCenter = (points: point[]): point => {
  const [p1, p2] = points;
  return { x: (p1.x + p2.x) / 2, y: (p1.y + p2.y) / 2 };
};

const getCenters = (sides: {
  [key: string]: point[];
}): { Top: point; Right: point; Bottom: point; Left: point } => {
  return {
    Top: getCenter(sides.Top),
    Right: getCenter(sides.Right),
    Bottom: getCenter(sides.Bottom),
    Left: getCenter(sides.Left)
  };
};

const getPositionOffset = (elementId: string): point => {
  const element = document.getElementById(elementId);
  const top = element?.parentElement?.offsetTop;
  const left = element?.parentElement?.offsetLeft;

  return { x: left || 0, y: top || 0 };
};

const getScrollOffset = (elementId: string): point => {
  const element = document.getElementById(elementId)?.parentElement;
  const top = element?.parentElement?.scrollTop;
  const left = element?.parentElement?.scrollLeft;

  return { x: left || 0, y: top || 0 };
};

const getElementAtPoint = (p1: point): Element | null => {
  if (!p1) return null;
  return document.elementFromPoint(p1.x, p1.y);
};

const getElementsAtPoint = (p1: point): Element[] => {
  if (!p1) return [];
  return document.elementsFromPoint(p1.x, p1.y);
};

const elementHasClass = (element: Element, className: string): boolean => {
  const hasClass = element.classList.contains(className);

  if (!hasClass && element.parentElement)
    return elementHasClass(element.parentElement, className);

  return hasClass;
};

const elementsHaveClass = (elements: Element[], className: string): boolean => {
  for (let i = 0; i < elements.length; ++i) {
    const el = elements[i];
    if (el.classList.contains(className)) return true;
  }

  return false;
};

const isHoveringOverClass = (
  p1: point,
  className: string,
  allLayers?: boolean // This is slow
): boolean => {
  if (allLayers) {
    return elementsHaveClass(getElementsAtPoint(p1), className);
  }

  const element = getElementAtPoint(p1);
  if (!element) {
    return false;
  }
  return elementHasClass(element, className);
};

const throttledResizeObserver = (
  callback: (entries: ResizeObserverEntry[]) => void
) => {
  let running = false;

  const resizeObserver = new ResizeObserver((entries) => {
    if (!running) {
      running = true;

      requestAnimationFrame(() => {
        running = false;
        callback(entries);
      });
    }
  });

  return {
    observe(target: HTMLElement | Element) {
      resizeObserver.observe(target);
    },
    unobserve(target: HTMLElement | Element) {
      resizeObserver.unobserve(target);
    },
    disconnect() {
      resizeObserver.disconnect();
    }
  };
};

const throttle = (callback: any, limit: number) => {
  let lastCall = 0;
  return (...args: any[]) => {
    const now = new Date().getTime();
    if (now - lastCall < limit) {
      return;
    }
    lastCall = now;
    // @ts-ignore
    return callback.apply(this, args);
  };
};

const frameThrottle = (func: (...args: any[]) => void) => {
  let running = false;
  let lastArgs: any[] | null = null;

  return (...args: any[]): void => {
    lastArgs = args;
    if (!running) {
      running = true;
      requestAnimationFrame(() => {
        running = false;
        if (lastArgs) {
          func(...lastArgs);
        }
      });
    }
  };
};

export {
  distance,
  distanceToLine,
  isPointInBoundingBox,
  drawBox,
  drawLine,
  getBoundingBoxSides,
  getCenters,
  getPositionOffset,
  getScrollOffset,
  getElementAtPoint,
  elementHasClass,
  isHoveringOverClass,
  keepIds,
  throttledResizeObserver,
  throttle,
  frameThrottle
};
