/* eslint-disable react-hooks/exhaustive-deps */

import { useHistory } from 'react-router-dom';

import useFeatheryRedux from '../../redux';

import '../../style/dialog-form.css';
import { Neutral, Positive } from '../Core/Button';
import Dialog from '../Dialog';
import { uniqifyKey } from '../../utils/format';
import Label from '../Dialog/Label';
import { DropdownField, InlineTooltip, TextField } from '../Core';
import styles from '../Dialog/styles.module.scss';
import { useState } from 'react';

export default function PanelCopyModal({ close, panel, panelsData }: any) {
  const history = useHistory();
  const {
    createPanel,
    getFields,
    getPanelThemeAssetUse,
    getThemes,
    getIntegrations,
    toasts: { addToast }
  } = useFeatheryRedux();

  const [linkFields, setLinkFields] = useState(false);
  const [customSuffix, setCustomSuffix] = useState('');

  const handleClose = () => {
    setLinkFields(false);
    setCustomSuffix('');
    close();
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const oldKey = panel.key;
    const allKeys = Object.values(panelsData).map(
      (panel) => (panel as any).key
    );
    const newKey = uniqifyKey(oldKey, allKeys);
    const response = await createPanel({
      key: newKey,
      copy_panel_key: oldKey,
      link_fields: linkFields,
      copy_servar_suffix: customSuffix,
      promotable: false
    });
    await Promise.all([
      getPanelThemeAssetUse({ cached: false }),
      getThemes(),
      getIntegrations(),
      getFields()
    ]);
    handleClose();
    addToast({
      text: (
        <>
          <strong>{newKey}</strong> was created.
        </>
      )
    });
    history.push(`/forms/${response.id}`);
  };

  return (
    <Dialog isOpen={panel} onClose={handleClose} title='Copy Form' size='xsm'>
      <Label style={{ display: 'flex' }}>
        Link Copied Fields{' '}
        <InlineTooltip
          text='If copied form fields are linked to the current form, they will share the same properties and user data.'
          inline
        />
      </Label>
      <DropdownField
        className={styles.input}
        onChange={(event: any) => setLinkFields(event.target.value === 'true')}
        selected={linkFields.toString()}
        options={[
          { value: 'true', display: 'Yes' },
          { value: 'false', display: 'No' }
        ]}
      />
      {!linkFields && (
        <>
          <Label style={{ display: 'flex' }}>
            New Field Suffix{' '}
            <InlineTooltip
              text='The copied field IDs will include this custom suffix'
              inline
            />
          </Label>
          <TextField
            value={customSuffix}
            onComplete={(suffix: any) => setCustomSuffix(suffix)}
            className={styles.input}
          />
        </>
      )}
      <div className='dialog-form-action text-center'>
        <Neutral onClick={handleClose}>Cancel</Neutral>
        <Positive onClick={handleSubmit}>Copy</Positive>
      </div>
    </Dialog>
  );
}
