function Calendly({ width = 64, height = 64 }) {
  return (
    <svg
      width={width}
      height={height}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 150 150'
    >
      <g id='Layer_3'>
        <path id='Layer_3-2' style={{ fill: 'none' }} d='M0,0H150V150H0V0Z' />
      </g>
      <path
        style={{ fill: '#006bff' }}
        d='M138.46,86.65c-2.81-2.06-8.53-4.62-13.36-6.26,.5-3.15,.68-7.1-.09-11.67h-.02v-.11h.02v.11c4.86-.88,9.46-2.85,13.45-5.77,3.83-2.84,3.09-6.04,2.51-7.94-4.74-15.37-14.84-28.52-28.45-37.08-13.61-8.55-29.85-11.94-45.75-9.55-15.9,2.4-30.41,10.42-40.9,22.61-10.49,12.18-16.26,27.73-16.26,43.81s5.77,31.63,16.26,43.81c10.49,12.19,25,20.21,40.9,22.61,15.9,2.39,32.14-1,45.75-9.55,13.61-8.56,23.71-21.71,28.45-37.08,.58-1.9,1.32-5.1-2.51-7.94Zm-4.32,5.83c-4.23,13.71-13.24,25.44-25.38,33.07-12.15,7.63-26.63,10.64-40.81,8.5-14.19-2.14-27.13-9.29-36.49-20.17-9.35-10.87-14.5-24.74-14.5-39.08s5.15-28.21,14.5-39.08c9.36-10.88,22.3-18.03,36.49-20.17,14.18-2.14,28.66,.87,40.81,8.5,12.14,7.63,21.15,19.36,25.38,33.07,0,.03,.02,.07,.04,.1-2.07,1.5-6.86,3.3-11.14,4.57l-.04-.08c-.61-1.51-1.32-2.99-2.14-4.4l-6.81-11.8c-3.07-5.31-7.48-9.73-12.8-12.8s-11.35-4.69-17.49-4.69h-13.63c-6.14,0-12.17,1.62-17.49,4.69-5.31,3.07-9.73,7.49-12.8,12.8l-6.81,11.8c-3.07,5.32-4.69,11.35-4.69,17.49s1.62,12.17,4.69,17.49l6.81,11.8c3.07,5.31,7.49,9.73,12.8,12.8,5.32,3.07,11.35,4.69,17.49,4.69h13.63c6.14,0,12.17-1.62,17.49-4.69s9.73-7.49,12.8-12.8l6.81-11.78c.82-1.41,1.53-2.89,2.14-4.4l.04-.09s0-.01,.01-.02h.01c4.02,.62,7.83,2.19,11.12,4.58,0,.03-.02,.07-.04,.1Z'
      />
      <path
        style={{ fill: '#0ae8f0' }}
        d='M123.04,61.8c-1.53,.25-3.08,.39-4.64,.39-10.19,0-14.01-3.39-18.43-7.31-4.27-3.79-9.58-8.49-19.25-8.49h-5.78c-6.99,0-13.35,2.54-17.89,7.14-4.44,4.5-6.89,10.66-6.89,17.34v7.9c0,6.68,2.45,12.84,6.89,17.34,4.55,4.6,10.9,7.14,17.89,7.14h5.78c9.67,0,14.98-4.71,19.25-8.49,4.42-3.9,8.25-7.31,18.43-7.31,1.55,0,3.11,.12,4.64,.37,.88-2.2,2.17-7.92,2.06-7.43-2.32-.21-4.5-.11-6.72-.11-23.41,0-22.19,15.81-37.69,15.81h-5.78c-10.64,0-17.63-7.6-17.63-17.33v-7.92c0-9.72,6.99-17.32,17.63-17.32h5.78c15.5,0,14.28,15.8,37.69,15.8,2.22,0,4.43-.2,6.61-.6v-.11c-.42-2.33-1.07-4.62-1.95-6.82Z'
      />
    </svg>
  );
}

export default Calendly;
