import styles from './styles.module.scss';

type ErrorDisplayProps = {
  errorMessage?: string;
};

export const ErrorDisplay = ({ errorMessage }: ErrorDisplayProps) => {
  if (!errorMessage) {
    return null;
  }

  return <div className={styles.errorDisplay}>{errorMessage}</div>;
};
