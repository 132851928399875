import { useState } from 'react';
import { AIDisplayPDF, AIExtractionRun } from '../../types';
import styles from '../styles.module.scss';
import Viewer from './Viewer';
import DataLayer from './DataLayer';

type Props = {
  run?: AIExtractionRun;
};

const PDFViewer = ({ run }: Props) => {
  const [pageCount, setPageCount] = useState(0);

  if (!run) {
    return null;
  }

  return (
    <div className={styles.pdfViewerContainer}>
      <Viewer
        selectedFile={run.displayPDF as AIDisplayPDF}
        setPageCount={(count: number) =>
          requestAnimationFrame(() => setPageCount(count))
        }
      />
      {pageCount > 0 && <DataLayer run={run} pageCount={pageCount} />}
    </div>
  );
};

export default PDFViewer;
