import {
  Cell,
  Gig,
  Grid,
  Viewport,
  getParentPositionId,
  getPositionId,
  makeGetNextContainerKey
} from '..';
import {
  ElementType,
  TYPE_BUTTON,
  TYPE_IMAGE,
  TYPE_PROGRESS_BAR,
  TYPE_TEXT,
  TYPE_VIDEO
} from '../../../../../utils/elements';
import { getStepPropFromElementType } from '../../../../../utils/step';
import { Element, Step, Subgrid, Model } from '../models';

const sortByPosition = (subgridA: any, subgridB: any) => {
  const a = getPositionId(subgridA.position);
  const b = getPositionId(subgridB.position);

  if (!a) return 1;
  if (!b) return -1;
  if (a === 'root') return -1;
  if (b === 'root') return 1;
  if (b.length > a.length) return -1;
  if (a.length === b.length) {
    const aNum = a.split(',').pop();
    const bNum = b.split(',').pop();

    if (aNum !== undefined && bNum !== undefined) {
      return Number.parseFloat(aNum) - Number.parseFloat(bNum);
    }
  }

  return 1;
};

export const applyElementType = (elements = [], type?: ElementType) => {
  return elements.map((element: any) => {
    if (!element._type) {
      if (element.servar?.type) {
        element._type = element.servar.type;
      } else {
        element._type = type;
      }
    }

    return element;
  });
};

/**
 * DataMapper
 * This is a static class meant to map Step data to and from a Grid class
 */
export class DataMapper {
  static toGrid(gig: Gig, viewport: Viewport) {
    const {
      subgrids = [],
      images = [],
      videos = [],
      progress_bars: progressBars = [],
      texts = [],
      buttons = [],
      servar_fields: servarFields = [],
      ...rawStep
    } = gig.raw;

    const step = new Step(rawStep);
    const grid = new Grid(gig, {
      step,
      viewport
    });

    // Used for tracking unmapped subgrids and elements (missing positions)
    const unmapped: any = {
      subgrids: [],
      elements: []
    };

    // Combine all elements into an array and apply `_type` to them
    const elements = [
      ...applyElementType(images, TYPE_IMAGE),
      ...applyElementType(videos, TYPE_VIDEO),
      ...applyElementType(progressBars, TYPE_PROGRESS_BAR),
      ...applyElementType(texts, TYPE_TEXT),
      ...applyElementType(buttons, TYPE_BUTTON),
      ...applyElementType(servarFields)
    ];

    // Sort subgrids & elements by position
    const cells = [...subgrids, ...elements];
    cells.sort(sortByPosition);

    // Iterate through all subgrids and elements (subgrids first)
    cells.forEach((obj) => {
      const { position, ...rest } = obj;

      // If the position is missing, add the subgrid or element to unmapped
      if (!position) {
        if (rest._type) unmapped.elements.push(obj);
        else unmapped.subgrids.push(obj);
        return;
      }

      // Handle subgrids
      if (!obj._type) {
        const subgrid = new Subgrid(rest, viewport);

        // If the position is root, set the subgrid as the root on the grid
        if (getPositionId(position) === 'root') {
          grid.setRoot(new Cell(subgrid, { position: [] }));
        } else {
          // Find the parent and add the subgrid as a child to the parent
          const parent = grid.get(getParentPositionId(position));

          if (parent) {
            parent.add(new Cell(subgrid, { position }));
          }
        }
      }

      // If the object is an element, find the subgrid of the element's position and set the element on the cell
      if (obj._type) {
        const element = new Element(rest, viewport);
        const parent = grid.get(getParentPositionId(position)); // Get the parent cell of the position

        if (parent) {
          // Add a new implicit container for the element to be set to
          const container = parent.add(new Cell(undefined, { position }));

          if (container) {
            container.setElement(element);
          }
        }
      }
    });

    // Set the unmapped subgrids & elements on the grid
    grid.unmapped = unmapped;

    return grid;
  }

  static toStep(grid: Grid) {
    const seenKeys: any = {};
    const step = {
      ...grid.step.getRaw()
    };

    // Make the create function for the next container key
    const getNextContainerKey = makeGetNextContainerKey(grid.children);

    step.subgrids = [];
    step.elements = [];
    step.progress_bars = [];
    step.servar_fields = [];
    step.buttons = [];
    step.images = [];
    step.texts = [];
    step.videos = [];

    // Iterate through all of the children in the tree to map them to either subgrids or elements
    grid.children.forEach((child) => {
      if (!child.isElement) {
        // Map subgrid
        const subgrid = (child.model as Model).getRaw();
        subgrid.position = child.position;

        // If the subgrid key does not exist or has already been seen, create a new key
        if (!subgrid.key || seenKeys[subgrid.key]) {
          subgrid.key = getNextContainerKey();
        }

        seenKeys[subgrid.key] = true;
        step.subgrids.push(subgrid);
      } else {
        // Map element
        const elementModel = child.element as Model;
        const element = elementModel.getRaw();
        element.position = child.position; // Set the position of the element to the position of the node that contains it

        step.elements.push(element);
        step[getStepPropFromElementType(element._type)].push(element);
      }
    });

    step.subgrids = [...step.subgrids, ...grid.unmapped.subgrids];
    step.elements = [...step.elements, ...grid.unmapped.elements];

    return step;
  }
}
