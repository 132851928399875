import { IAPIActionState } from '../../pages/types';
import { Template } from '../types';

export const SALESFORCE_UPDATE: Template = {
  id: 'salesforce_update',
  display: 'Update Record',
  defaultState: {
    name: 'Salesforce Update',
    template: 'salesforce_update',
    url: '',
    method: 'PATCH',
    headers: {
      Authorization: 'Bearer {{salesforce_token}}'
    },
    body: '{}',
    params: {},
    tokens: { salesforce: '' },
    responseStructure: '',
    responseMapping: {},
    testParameters: {}
  },
  configuration: {},
  loadConfig: (state) => {
    return {
      name: state.name,
      object: state.url.split('/').filter(Boolean).at(-2),
      object_id: state.url.split('/').filter(Boolean).at(-1),
      field_values: JSON.parse(state.body)
    };
  },
  saveConfig: (state, configValues) => {
    const { name, credential, object, object_id, field_values } = configValues;

    // remove falsy values (empty strings)
    const filteredFieldValues = Object.keys(field_values).reduce(
      (acc: any, key: string) => {
        if (field_values[key]) {
          acc[key] = field_values[key];
        }
        return acc;
      },
      {}
    );

    const newValues: Partial<IAPIActionState> = {
      name,
      body: JSON.stringify(filteredFieldValues, null, 2),
      url: `${credential.data.userCustomDomainUrl}/services/data/v57.0/sobjects/${object}/${object_id}`,
      tokens: { salesforce: credential.credentialKey }
    };

    return {
      ...state,
      ...newValues
    };
  }
};
