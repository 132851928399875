import { forwardRef, memo, Ref, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { useFormBuilderChangeStep } from '../../../../../hooks/useFormBuilderChangeStep';
import {
  ConnectionIcon,
  ConnectionsIcon,
  CopyIcon,
  EditIcon,
  LightningIcon,
  OpenOverflowIcon,
  PlusCircleIcon,
  PlusIcon,
  TrashIcon
} from '../../../../Icons';
import { useGlobalMouseDownToggle } from '../../../../Core/util';
import { ContextMenu, DynamicTextTooltip, TextField } from '../../../../Core';
import { DeleteStepModal } from '../../../../Modals';
import { UNDO_TITLES, UNDO_TYPES } from '../../../../../utils/constants';
import { objectApply } from '../../../../../utils/core';
import useFeatheryRedux from '../../../../../redux';
import { useInView } from 'react-intersection-observer';
import useStepDrag, {
  StepIdentifier
} from '../../../../CustomDragLayer/hooks/useStepDrag';
import { useDrop } from 'react-dnd';
import useIsStepProtected from '../../../../../hooks/useIsStepProtected';
import { useAppSelector } from '../../../../../hooks';
import useActiveLogic from '../../../../FormFieldEditors/DefaultEditor/LogicSection/useActiveLogic';
import { Tooltip } from '../../../../Core/Tooltip/Tooltip';

const LABEL_MAX_LENGTH = 16;

interface StepEntryProps {
  step: { id: string; key: string };
  excessConnections?: number;
  addMenuLocation?: 'top' | 'bottom' | null;
  createOrMoveStepAndConnect?: (
    stepId: string,
    duplicate: boolean,
    existingStepId?: string | null
  ) => void;
  handleStepVisibilityChange?: (stepId: string, inView: boolean) => void;
  setShowConnectionsPanel?: (show: boolean) => void;
}

const StepEntry = (
  {
    step,
    excessConnections = 0,
    addMenuLocation = null,
    createOrMoveStepAndConnect = () => {},
    handleStepVisibilityChange = () => {},
    setShowConnectionsPanel = () => {}
  }: StepEntryProps,
  ref: Ref<HTMLDivElement>
) => {
  const lock = useIsStepProtected(step.id);
  const changeStep = useFormBuilderChangeStep();
  const activeStepId = useAppSelector(
    (state) => state.formBuilder.activeStepId
  );
  const workingSteps = useAppSelector(
    (state) => state.formBuilder.workingSteps
  );
  const {
    formBuilder: { setPanelDataWithUndoRedo }
  } = useFeatheryRedux();

  const [isRenaming, setIsRenaming] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [menuPosition, setMenuPosition] = useState<{
    x?: number;
    y?: number;
  }>({});
  const labelEditRef = useRef<any>();
  const menuRef = useRef<HTMLDivElement>(null);
  const [showMenu, setShowMenu] = useGlobalMouseDownToggle([menuRef]);

  const openMenu = (e: any) => {
    e.preventDefault();
    setMenuPosition({ x: e.pageX, y: e.pageY });
    setShowMenu(true);
  };

  // The add/plus menu that conditionally appears on the step border
  const addMenuRef = useRef<HTMLDivElement>(null);
  const [showAddMenu, setShowAddMenu] = useGlobalMouseDownToggle([addMenuRef]);
  const [addMenuPosition, setAddMenuPosition] = useState<{
    x?: number;
    y?: number;
  }>({});
  function revealAddMenu(event: MouseEvent) {
    event.preventDefault();
    setAddMenuPosition({
      x: event.clientX,
      y: event.clientY
    });
    setShowAddMenu(true);
  }

  const activeLogic = useActiveLogic(step.id);

  useEffect(() => {
    // Must focus input after a delay because occasionally blur event gets triggered immediately
    // otherwise. Not sure why this is.
    if (isRenaming) setTimeout(() => labelEditRef.current?.focus(), 100);
  }, [isRenaming]);

  const handleRename = (newKey: string) => {
    setIsRenaming(false);
    newKey = newKey.trim();

    if (!newKey) return;
    const allKeys = Object.values(workingSteps).map((step: any) => step.key);
    if (allKeys.includes(newKey)) return;

    setPanelDataWithUndoRedo({
      id: step.id,
      oldValue: { key: step.key },
      newValue: { key: newKey },
      title: UNDO_TITLES.STEP_ID,
      type: UNDO_TYPES.STEP,
      workingSteps: objectApply(workingSteps, {
        [step.id]: { key: newKey }
      })
    });
  };

  let label = step.key;
  if (step.key.length > LABEL_MAX_LENGTH) {
    label = step.key.slice(0, LABEL_MAX_LENGTH) + '...';
  }

  const newStepAndConnect = (duplicate = false) => {
    setShowMenu(false); // This is important in order for auto-scroll to new step to work properly
    createOrMoveStepAndConnect(step.id, duplicate);
  };

  // Tracking when any step entry goes into or out of the view port (so we can auto scroll as needed)
  const [inViewRef] = useInView({
    threshold: 0.5, // only trigger when passing through the 50% showing threshold
    onChange: (inView) => handleStepVisibilityChange(step.id, inView)
  });

  // Step entries can be dragged onto other steps and onto connections
  const dragRef = useStepDrag(step.id);
  const [{ isOver, canDrop }, dropRef] = useDrop(
    () => ({
      accept: [StepIdentifier],
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
      }),
      canDrop: (item: { step: { id: string; key: string } }) =>
        !!(item.step.id !== step.id && addMenuLocation),
      drop: (item: { step: { id: string; key: string } }) => {
        createOrMoveStepAndConnect(step.id, false, item.step.id);
      }
    }),
    [step, createOrMoveStepAndConnect]
  );

  const plusSize = showAddMenu || (canDrop && isOver) ? 22 : 16;

  return (
    <div ref={inViewRef}>
      <div ref={dragRef}>
        <div ref={dropRef}>
          <div
            id={step.id}
            className={classNames(
              styles.stepSelector,
              activeStepId === step.id && styles.selected
            )}
            ref={ref}
            onClick={() => {
              changeStep(step.id);
              setShowConnectionsPanel(false);
            }}
            onContextMenu={openMenu}
            onDoubleClick={() => setIsRenaming(true)}
          >
            {lock}
            {excessConnections > 0 && (
              <div
                className={styles.connectorBadge}
                title={`${excessConnections} additional connection${
                  excessConnections > 1 ? 's' : ''
                } not shown`}
                onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                  e.stopPropagation();
                  changeStep(step.id);
                  setShowConnectionsPanel(true);
                }}
              >{`+${excessConnections}`}</div>
            )}
            <DeleteStepModal
              show={showDeleteModal}
              setShow={(show: boolean) => setShowDeleteModal(show)}
            />
            <ContextMenu
              ref={menuRef}
              show={showMenu}
              close={() => setMenuPosition({})}
              position={menuPosition as any}
              actions={[
                {
                  onMouseDown: () => {
                    setShowConnectionsPanel(true);
                  },
                  title: 'Connections',
                  Icon: ConnectionsIcon
                },
                {
                  onMouseDown: () => {
                    newStepAndConnect(true);
                  },
                  title: 'Duplicate',
                  Icon: CopyIcon
                },
                {
                  onMouseDown: () => setIsRenaming(true),
                  title: 'Rename',
                  Icon: ({ color }: any) => (
                    <EditIcon width={16} height={16} color={color} />
                  )
                },
                {
                  onMouseDown: () => setShowDeleteModal(true),
                  title: 'Delete',
                  Icon: ({ color }: any) => (
                    <TrashIcon width={16} height={16} color={color} />
                  )
                }
              ]}
            />
            {isRenaming ? (
              <TextField
                value={step.key}
                onComplete={handleRename}
                maxLength={128}
                ref={labelEditRef}
                className={styles.input}
              />
            ) : (
              <DynamicTextTooltip text={step.key} maxLength={LABEL_MAX_LENGTH}>
                <div className={styles.label}>{label}</div>
              </DynamicTextTooltip>
            )}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {activeLogic.length > 0 && (
                <Tooltip
                  side='top'
                  content='This step has advanced logic rules'
                >
                  <div style={{ paddingBottom: '2px' }}>
                    <LightningIcon />
                  </div>
                </Tooltip>
              )}
              <div className={styles.overflowTrigger} onClick={openMenu}>
                <OpenOverflowIcon />
              </div>
            </div>
            {addMenuLocation && (
              <>
                <div
                  className={classNames(
                    styles.plusButtonHolder,
                    addMenuLocation === 'top' ? styles.top : '',
                    {
                      [styles.canDrop]: canDrop && isOver
                    }
                  )}
                >
                  <PlusCircleIcon
                    height={plusSize}
                    width={plusSize}
                    className={classNames(styles.plusButton, {
                      [styles.canDrop]: canDrop && isOver,
                      [styles.activeMenu]: showAddMenu
                    })}
                    onClick={revealAddMenu}
                  />
                </div>
                <ContextMenu
                  ref={addMenuRef}
                  show={showAddMenu}
                  close={() => setAddMenuPosition({})}
                  position={addMenuPosition as any}
                  actions={[
                    {
                      onMouseDown: () => {
                        newStepAndConnect();
                      },
                      title: 'New step',
                      Icon: PlusIcon
                    },
                    {
                      onMouseDown: () => {
                        setShowConnectionsPanel(true);
                      },
                      title: 'Add connection',
                      Icon: ConnectionIcon
                    }
                  ]}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const StepEntryDrag = ({ step }: StepEntryProps) => {
  return (
    <div className={styles.stepSelector}>
      <div className={styles.label}>{step.key}</div>
    </div>
  );
};

export default memo(forwardRef<HTMLDivElement, StepEntryProps>(StepEntry));
