import { WarningIcon } from '../../../Icons';

export const addViewportCellToast = {
  text: (
    <>
      <strong>
        To place this Element, you need to make room for it in your mobile
        layout.
      </strong>
      &nbsp;Add a new cell to your mobile layout to fit this Element.
    </>
  ),
  icon: <WarningIcon />,
  decay: 8000
};
