export default [
  'Busan',
  'Chungbuk',
  'Chungnam',
  'Daegu',
  'Daejeon',
  'Gangwon',
  'Gwangju',
  'Gyeongbuk',
  'Gyeonggi',
  'Gyeongnam',
  'Incheon',
  'Jeju',
  'Jeonbuk',
  'Jeonnam',
  'Sejong',
  'Seoul',
  'Ulsan'
];
