import React from 'react';

interface ExpandingInputProps extends React.ComponentPropsWithoutRef<'input'> {
  value: string | number | readonly string[];
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

/**
 * An input whose width adjusts to the value. Must be a controlled input.
 *
 * @component
 * @param {Object} props - The component accepts all props from an `input`.
 * @param {string} value - The value of the input.
 * @param {function} onChange - Called when the value of the input changes.
 * @returns {JSX.Element} The rendered text area.
 *
 * @example
 * // Example usage of the controlled component
 * <ExpandingInput
 *    value={value}
 *    onChange={(event) => setValue(event.target.value)}
 * />
 */
const ExpandingInput = ({ value, ...props }: ExpandingInputProps) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  React.useLayoutEffect(() => {
    if (!inputRef.current) return;
    resizeInput(inputRef.current);
  }, [value]);

  return <input ref={inputRef} value={value} {...props} />;
};

ExpandingInput.displayName = 'ExpandingInput';

export default ExpandingInput;

// Expand the input to fit its content
function resizeInput(element: HTMLInputElement) {
  // reset the scroll position and width
  element.scrollTop = 0;
  element.scrollLeft = 0;
  element.style.removeProperty('width');

  // set the width to fit the content plus the borders
  const computedStyle = window.getComputedStyle(element);
  const borderLeft = parseFloat(computedStyle.borderLeftWidth);
  const borderRight = parseFloat(computedStyle.borderRightWidth);
  const newWidth = element.scrollWidth + borderLeft + borderRight;
  element.style.width = `${newWidth}px`;
}
