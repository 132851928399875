export default function SplitTestsIcon({ width = 28, height = 28 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.40694 10.8139C10.2885 10.8139 11.8139 9.28854 11.8139 7.40694C11.8139 5.52534 10.2885 4 8.40694 4C6.52534 4 5 5.52534 5 7.40694C5 9.28854 6.52534 10.8139 8.40694 10.8139Z'
        strokeWidth='3'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M23.0189 10.8139C24.9005 10.8139 26.4259 9.28854 26.4259 7.40694C26.4259 5.52534 24.9005 4 23.0189 4C21.1373 4 19.612 5.52534 19.612 7.40694C19.612 9.28854 21.1373 10.8139 23.0189 10.8139Z'
        strokeWidth='3'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.713 28C17.5946 28 19.1199 26.4746 19.1199 24.593C19.1199 22.7114 17.5946 21.1861 15.713 21.1861C13.8314 21.1861 12.306 22.7114 12.306 24.593C12.306 26.4746 13.8314 28 15.713 28Z'
        strokeWidth='3'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.40692 10.7761V11.6846C8.40692 12.8959 9.05045 14.0316 10.0725 14.6751L14.653 17.5142C15.3344 17.9306 15.7508 18.6877 15.7508 19.4827V20.9212'
        strokeWidth='3'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M23.0189 10.7761V11.6846C23.0189 12.8959 22.3754 14.0316 21.3533 14.6751L16.7728 17.5142C16.0915 17.9306 15.675 18.6877 15.675 19.4827V20.9212'
        strokeWidth='3'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
