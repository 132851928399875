import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  CleanIcon,
  CodeIcon,
  DocsIcon,
  ErrorMarkerIcon,
  MaximizeIcon,
  MinimizeIcon
} from '../../../../components/Icons';
import { FORMAT_SHORTCUT, KEYS } from './utils';
import AceEditor, { IAnnotation, IMarker } from 'react-ace';
// @ts-expect-error This esm version is needed to avoid bundling all parsers and bundle bloat
import prettier from 'prettier/esm/standalone.mjs';
import prettierEspree from 'prettier/parser-espree';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-tomorrow';
import langTools from 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/snippets/javascript';
import { Ace } from 'ace-builds';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { useKeyboardListener } from '../../../../hooks/useKeyboardListener';
import { validateCode as _validateCode } from './validation';
import debounce from 'lodash.debounce';
import '../../../../style/code-editor.css';
import { LogicTriggerEvent } from '../RuleFieldsPanel';
import featheryCompleter from './featheryCompleter';
import { useAppSelector } from '../../../../hooks';
import { ConfirmationModal } from '../ConfirmationModal';
import useRuleServarKeys from '../RuleBuilder/useRuleServarKeys';
import { Tooltip } from '../../../../components/Core/Tooltip/Tooltip';
import { Button } from '../../../../components/Core/Button/button';
import useFeatheryRedux from '../../../../redux';

export const NO_CODE_DOCS_URL =
  'https://docs.feathery.io/platform/build-forms/logic/advanced/visual-rule-builder';
export const CODE_DOCS_URL =
  'https://docs.feathery.io/platform/build-forms/logic/advanced/javascript-rule-builder';
const EXAMPLES_URL =
  'https://docs.feathery.io/platform/build-forms/logic/advanced/examples';

interface ICodeErrorData {
  line: number;
  character: number;
  reason: string;
  evidence: string;
  code: string;
}

export type Code = {
  text: string;
  valid: boolean;
};

type RuleCodeEditorProps = {
  code?: Code;
  triggerEvent: LogicTriggerEvent;
  readOnly?: boolean;
  readOnlyText?: any;
  onChange?: (code: Code) => void;
  onChangeMode?: (mode: 'rule_builder' | 'code_editor') => void;
};

const RuleCodeEditor = (props: RuleCodeEditorProps) => {
  const {
    code = { text: '', valid: true },
    onChange = () => {},
    triggerEvent,
    readOnly = false,
    readOnlyText,
    onChangeMode = () => {}
  } = props;

  const [showChangeModeModal, setShowChangeModeModal] = useState(false);
  const codeEditorMaximized = useAppSelector(
    (s) => s.formBuilder.codeEditorMaximized
  );
  const {
    formBuilder: { setCodeEditorMaximized }
  } = useFeatheryRedux();

  const servarKeys = useRuleServarKeys();
  const hiddenFields = useAppSelector(
    (state) => state.fields.hiddenFields ?? []
  );
  const fieldKeys = useMemo(() => {
    const hiddenFieldKeys = hiddenFields.map((hf) => hf.key);
    return [...servarKeys, ...hiddenFieldKeys].sort((a: any, b: any) => {
      a = a.toLowerCase();
      b = b.toLowerCase();
      return a > b ? 1 : b > a ? -1 : 0;
    });
  }, [hiddenFields, servarKeys]);

  // Setup keyboard shortcuts for the editor
  const { onKeypress } = useKeyboardListener({
    keys: KEYS,
    preventDefault: true
  } as any);

  useEffect(() => {
    if (onKeypress) {
      onKeypress(({ shortcut }: any) => {
        if (shortcut === FORMAT_SHORTCUT) {
          makeCodePretty();
        }
      });
    }
  }, [code?.text, onKeypress]);

  // This block of code syncs the border left width of the "codeSpacer" div with the gutter width of the code editor
  // This is to achieve seamless padding on top of the code editor's first line
  const codeSpacerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        if (codeSpacerRef.current) {
          codeSpacerRef.current.style.borderLeftWidth = `${entry.contentRect.width}px`;
        }
      });
    });

    observer.observe(document.querySelector('.ace_gutter' as any));

    return () => {
      observer.disconnect();
    };
  }, [codeSpacerRef]);

  // Setup code validation + error handling
  const [errorAnnotations, setErrorAnnotations] = useState<IAnnotation[]>([]);
  const [lineMarkers, setLineMarkers] = useState<IMarker[]>([]);
  const [errors, setErrors] = useState<ICodeErrorData[]>([]);

  const validateCode = (newCode: string) => {
    const result = _validateCode(newCode, getEditor(), triggerEvent, fieldKeys);

    setErrors(result.errors);
    setErrorAnnotations(result.annotations);
    setLineMarkers(result.lineMarkers);

    return result.isValid;
  };

  // Debouncing code validation so that it doesn't run on every keystroke
  const analyzeCodeOnPause = useCallback(
    debounce(async () => {
      const valid = validateCode(code.text);

      // only update state if it's changed
      if (valid !== code.valid) {
        onChange({ ...code, valid });
      }
    }, 300),
    [code?.text]
  );

  // When the rule changes, validate the code so that the UI is up to date
  useEffect(() => {
    analyzeCodeOnPause.cancel();
    analyzeCodeOnPause();

    return () => {
      analyzeCodeOnPause.cancel();
    };
  }, [code?.text]);

  const makeCodePretty = async () => {
    if (code?.text) {
      let valid = validateCode(code.text);
      let prettyCode = code.text;

      // only make pretty if it's valid
      if (valid) {
        prettyCode = await prettier.format(asyncWrapCode(code.text), {
          parser: 'espree',
          plugins: [prettierEspree],
          semi: true,
          singleQuote: true,
          arrowParens: 'always',
          useTabs: false,
          tabWidth: 2,
          bracketSpacing: true,
          trailingComma: 'none'
        });

        // if the code is changed, then revalidate it so that line numbers are correct
        prettyCode = unwrapCode(prettyCode);
        if (prettyCode !== code.text) {
          valid = validateCode(prettyCode);
          onChange({ text: prettyCode, valid });
        }
      }
    }
  };

  const asyncWrapCode = (code: string) =>
    `(async () => {
      ${code}
    })()`;
  // Remove the async wrapper prefix/suffix and remove the 2 space indentation
  const unwrapCode = (code: string) =>
    code
      .split('\n')
      // remove any trailing empty line
      .filter(
        (line: string, index: number, lines) =>
          index < lines.length - 1 ||
          (index === lines.length - 1 && line.trim().length)
      )
      .map((line: string, index: number, lines) => {
        if (index === 0) {
          return line.replace(/(\(async\s*\(\)\s*=>\s*\{)/, '');
        } else if (index === lines.length - 1) {
          return line.replace(/(\}\)\(\);)/, '');
        } else {
          return line;
        }
      })
      .filter((line: string, index: number, lines) => {
        if (
          (index === 0 && !line.trim().length) ||
          (index === lines.length - 1 && !line.trim().length)
        ) {
          return false;
        }
        return true;
      })
      .map((line) => line.replace(/^ {2}/, ''))
      .join('\n');

  const getEditor = (): Ace.Editor => {
    const editor = editorRef.current;
    return (editor as any)?.editor as Ace.Editor;
  };

  const onCodeChange = (newCode: string, event: any) => {
    onChange({ text: newCode, valid: !!code?.valid });
    analyzeCodeOnPause.cancel();
    analyzeCodeOnPause();

    // trigger autocomplete if the user types a period after feathery, etc.
    const editor = getEditor();
    if (editor) {
      if (
        featheryEventCompleter.shouldTriggerAutoComplete(
          editor,
          editor.session,
          event.end
        )
      ) {
        // triggering auto complete
        setTimeout(() => {
          editor.commands.byName.startAutocomplete.exec(editor);
        }, 50);
      }
    }
  };

  // Code completion
  const featheryEventCompleter = useMemo(() => {
    return featheryCompleter(triggerEvent, fieldKeys);
  }, [triggerEvent, fieldKeys]);

  const editorRef = useRef(null);

  useEffect(() => {
    langTools.setCompleters([
      // excluding langTools.snippetCompleter because the snippets DO NOT seem useful and pollutes the list
      // excluding langTools.textCompleter because it pollutes the autocomplete too much irrelevant stuff
      // excluding langTools.keyWordCompleter because it puts lots of keywords into the autocomplete dropdown that are mostly irrelevant
      featheryEventCompleter
    ]);
  }, [featheryEventCompleter]);

  const maxLabel = codeEditorMaximized ? 'Minimize' : 'Maximize';

  return (
    <>
      <ConfirmationModal
        show={showChangeModeModal}
        setShow={setShowChangeModeModal}
        title='Warning'
        message='Editing the code will prevent you from using Rule Builder. Are you sure you want to continue?'
        onConfirm={() => onChangeMode('code_editor')}
        onCancel={() => setShowChangeModeModal(false)}
      />
      <div
        className={classNames(styles.ruleCodeContainer, {
          [styles.maximized]: codeEditorMaximized
        })}
      >
        <div className={styles.ruleCodeEditorContainer}>
          <div
            ref={codeSpacerRef}
            className={classNames(styles.ruleCodeSpacer, {
              [styles.readOnlyText]: readOnly && !!readOnlyText
            })}
          >
            {readOnly && readOnlyText}
          </div>
          <div className={styles.ruleCodeHeader}>
            <div className={styles.ruleCodeButtons}>
              <Button variant='gray' onClick={() => window.open(CODE_DOCS_URL)}>
                <DocsIcon width={22} height={22} /> Docs
              </Button>
              <Button variant='gray' onClick={() => window.open(EXAMPLES_URL)}>
                <CodeIcon width={22} height={20} /> Examples
              </Button>
              {readOnly ? (
                <Button
                  variant='gray'
                  onClick={() => setShowChangeModeModal(true)}
                >
                  Edit Code
                </Button>
              ) : (
                <>
                  <Tooltip content={`Format Code - ${FORMAT_SHORTCUT}`}>
                    <Button variant='gray' size='icon' onClick={makeCodePretty}>
                      <CleanIcon width={32} height={32} />
                    </Button>
                  </Tooltip>
                  <Tooltip content={`${maxLabel} Editor`}>
                    <Button
                      variant='gray'
                      size='icon'
                      onClick={() =>
                        setCodeEditorMaximized({
                          maximized: !codeEditorMaximized
                        })
                      }
                    >
                      {codeEditorMaximized ? (
                        <MinimizeIcon height={32} width={32} />
                      ) : (
                        <MaximizeIcon height={32} width={32} />
                      )}
                    </Button>
                  </Tooltip>
                </>
              )}
            </div>
          </div>
          <div className={styles.ruleCodeEditor}>
            <AceEditor
              mode='javascript'
              ref={editorRef}
              theme='tomorrow'
              className={styles.codeEditor}
              style={{
                width: '100%' // necessary to override inline style
              }}
              height='100%'
              highlightActiveLine={!readOnly}
              showGutter={true}
              markers={lineMarkers}
              annotations={errorAnnotations}
              fontSize={16}
              name='rule-code'
              value={code?.text}
              readOnly={readOnly}
              onChange={onCodeChange}
              onLoad={(editor) => {
                // cmd-f causes error because not loading/using searchbox extension
                editor.commands.removeCommand('find');
                // F1 open the command palette whish is also an issue
                editor.commands.removeCommand('openCommandPalette');
              }}
              setOptions={{
                useWorker: false,
                showLineNumbers: true,
                tabSize: 2,
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true
              }}
              editorProps={{ $blockScrolling: true }}
            />
          </div>
        </div>
        {errors.length > 0 && !codeEditorMaximized && (
          <div className={styles.ruleValidations}>
            <div className={styles.ruleValidationsHeader}>
              <h3 className={styles.ruleValidationsHeaderTitle}>
                Rule Validation - {errors.length} errors found
              </h3>
            </div>
            <div className={styles.ruleValidationsContent}>
              {errors.map((err: ICodeErrorData, index: number) => (
                <div
                  className={styles.ruleValidationsError}
                  key={`${err.line}-${err.character}-${err.code}-${index}`}
                >
                  <ErrorMarkerIcon />
                  <div className={styles.ruleValidationsErrorLine}>
                    Error: Line {err.line}, {err.character} - {err.reason}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default memo(RuleCodeEditor);
