import { useEffect, useRef } from 'react';

/**
 * This hook allows syncing a state/value into a Ref
 * so it can be used in places such as an event listener
 * (window.addEventListener). The value that is passed to this hook
 * is assumed to change which explains the `useEffect` that constantly
 * syncs the value to the current value of the Ref.
 *
 * It returns a function that allows to get the Ref value.
 */
export const useSyncedRefState = (value: any) => {
  const state = useRef(value);

  useEffect(() => {
    state.current = value;
  }, [value]);

  return () => {
    return state.current;
  };
};
