import { createSlice } from '@reduxjs/toolkit';

const clipboardInitialState = {
  gigClipboard: null
};

const clipboardSlice = createSlice({
  name: 'clipboard',
  initialState: JSON.parse(JSON.stringify(clipboardInitialState)),
  reducers: {
    setGigClipboard(state, action) {
      state.gigClipboard = action.payload;
    }
  }
});

export default clipboardSlice.reducer;

export const exportFunctions = {
  syncFunctions: clipboardSlice.actions,
  asyncFunctions: {}
};
