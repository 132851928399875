import { createContext, useCallback, useContext } from 'react';
import { getGigAndNode } from './utils';
import {
  Cell,
  Gig,
  NullCell
} from '../../components/RenderingEngine/GridInGrid/engine';
import { getUndoRedoPayload } from '../../utils/step';
import useFeatheryRedux from '../../redux';
import { useAppSelector } from '../../hooks';

export type GigState = {
  gig: Gig | null;
  node: Cell;
  workingSteps: any;
  activeStepId: any;
  activeStep: any;
  gigPosition: any;
  viewport: any;
};

export const GigContext = createContext<GigState>({
  gig: null,
  node: new NullCell() as Cell,
  workingSteps: null,
  activeStepId: null,
  activeStep: null,
  gigPosition: null,
  viewport: null
});

export const useGig = (overrideStep?: any) => {
  const context = useContext(GigContext);
  const theme = useAppSelector((s) => s.formBuilder.theme);
  const {
    formBuilder: { setPanelDataWithUndoRedo }
  } = useFeatheryRedux();

  if (overrideStep) {
    Object.assign(context, {
      ...getGigAndNode(
        overrideStep,
        context.viewport,
        context.gigPosition,
        theme
      )
    });
  }

  const syncToStore = useCallback(() => {
    const step = (context.gig as Gig).toStep();
    const oldSteps = JSON.parse(JSON.stringify(context.workingSteps));
    const newSteps = JSON.parse(JSON.stringify(oldSteps));

    newSteps[context.activeStepId] = step;

    const undoRedoPayload = getUndoRedoPayload({
      elementType: 'cell',
      oldSteps,
      newSteps,
      stepId: context.activeStepId,
      changedStepKeys: [context.activeStepId]
    });

    setPanelDataWithUndoRedo(undoRedoPayload);
  }, [
    context.gig,
    context.workingSteps,
    context.activeStepId,
    context.node,
    setPanelDataWithUndoRedo
  ]);

  return { ...context, syncToStore };
};
