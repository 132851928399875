import styles from './styles.module.scss';
import useMetrics from './useMetrics';

export default function StatValue({ statKey }: { statKey: string }) {
  const metrics = useMetrics();
  let value = metrics[statKey].value;
  let unit = '';
  if (statKey === 'average_time') {
    if (value > 60) {
      value = Math.round(value / 6) / 10;
      unit = ' min';
    } else {
      unit = ' s';
    }
  } else if (statKey === 'average_completion_rate') unit = '%';

  const delta = metrics[statKey].trend;
  const positive = delta >= 0;
  return (
    <div className={styles.cardStat}>
      <span className={styles.cardStatNumber}>
        {value}
        {unit}
      </span>
      <div
        className={styles.cardTrend}
        style={{
          backgroundColor: positive ? '#DEF7EC' : '#F7DEDE',
          color: positive ? '#0F985E' : '#B9151F'
        }}
      >
        {positive ? '+' : '-'}
        {Math.abs(delta)}%
      </div>
    </div>
  );
}
