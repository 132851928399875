function GoogleTagManager({ width = 64, height = 64 }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width={width}
      height={height}
      viewBox='0 0 2469.7 2469.8'
    >
      <g>
        <path
          d='M1449.8,2376L1021,1946.7l921.1-930.5l436.7,436.6L1449.8,2376z'
          fill='#8AB4F8'
        />
        <path
          fill='#4285F4'
          d='M1452.9,527.1L1016.3,90.4L90.5,1016.2c-120.6,120.5-120.7,315.8-0.2,436.4c0.1,0.1,0.2,0.2,0.2,0.2   l925.8,925.8l428.3-430.3L745,1235.1L1452.9,527.1z'
        />
        <path
          fill='#8AB4F8'
          d='M2378.7,1016.2L1452.9,90.4c-120.6-120.6-316.1-120.6-436.7,0c-120.6,120.6-120.6,316.1,0,436.6l926.3,925.8   c120.6,120.6,316.1,120.6,436.6,0c120.6-120.6,120.6-316.1,0-436.6L2378.7,1016.2z'
        />
        <circle fill='#246FDB' cx='1231.2' cy='2163.9' r='306' />
      </g>
    </svg>
  );
}

export default GoogleTagManager;
