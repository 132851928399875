import { useState } from 'react';
import { APIServiceModal } from '../../../../../../../components/Modals/CreateAPIServiceModal';
import { APIConnectorInput } from './APIConnectorInput';
import styles from '../styles.module.scss';
import { useRuleBuilderUpdater } from '../../../context';

type ConnectToAPIActionProps = {
  action: IRuleAction;
  mode?: RuleBuilderMode;
};

const EditMode = ({ action }: ConnectToAPIActionProps) => {
  const [showModal, setShowModal] = useState(false);
  const { updateOperand } = useRuleBuilderUpdater((s) => ({
    updateOperand: s.updateOperand
  }));

  const apiConnectorId = action.parameters[0]?.meta?.id;
  const apiConnectorMethod = action.parameters[0]?.meta?.method;
  const apiConnectorUrl = action.parameters[0]?.meta?.url;
  const apiConnectorName = action.parameters[0]?.value;

  const name = [apiConnectorName, apiConnectorUrl].filter(Boolean).join(' - ');
  const handleComplete = (selected: any) => {
    updateOperand(action.parameters[0].id, {
      type: 'value',
      value: selected.name,
      meta: {
        id: selected.id,
        method: selected.request_method,
        url: selected.base_url
      }
    });

    setShowModal(false);
  };

  return (
    <>
      <APIServiceModal
        show={showModal}
        value={apiConnectorId}
        onClose={() => setShowModal(false)}
        onComplete={(selected) => handleComplete(selected)}
      />
      <APIConnectorInput
        url={name}
        method={apiConnectorMethod}
        onClick={() => setShowModal(true)}
      />
    </>
  );
};

const ViewMode = ({ action }: ConnectToAPIActionProps) => {
  const apiConnectorMethod = action.parameters[0]?.meta?.method;
  const apiConnectorUrl = action.parameters[0]?.meta?.url;
  const apiConnectorName = action.parameters[0]?.value;

  const name = [apiConnectorName, apiConnectorUrl].filter(Boolean).join(' - ');

  return (
    <>
      <APIConnectorInput url={name} method={apiConnectorMethod} disabled />
    </>
  );
};

export const ConnectToAPIAction = ({
  action,
  mode = 'view'
}: ConnectToAPIActionProps) => {
  return (
    <div className={styles.actionContent}>
      {mode === 'view' ? (
        <ViewMode action={action} />
      ) : (
        <EditMode action={action} />
      )}
    </div>
  );
};
