import { memo, useState } from 'react';

import { useStore } from 'reactflow';

import styles from '../styles.module.scss';
import classNames from 'classnames';
import { getEdgeParams } from '../graph';

function shiftNode(oldNode: any) {
  return {
    ...oldNode,
    width: oldNode.width + 10
  };
}

function HoverEdge({ id, data, source, target, style }: any) {
  const [hover, setHover] = useState(false);
  const nodes = useStore((state) => state.nodeInternals);
  const sourceNode = nodes.get(source);
  const targetNode = nodes.get(target);

  if (!sourceNode || !targetNode) {
    return null;
  }

  const { sx, sy, tx, ty } = getEdgeParams(
    shiftNode(sourceNode),
    shiftNode(targetNode)
  );
  const d = `M ${sx},${sy}\n L ${tx},${ty}`;

  const s = data.selected;
  const markerStart = data.bidirectional
    ? hover || s
      ? 'url(#arrow-flip-active)'
      : 'url(#arrow-flip)'
    : undefined;
  const markerEnd = hover || s ? 'url(#arrow-active)' : 'url(#arrow)';
  return (
    <g>
      <defs>
        <marker
          id='arrow-active'
          markerWidth='6'
          markerHeight='10'
          refX='6'
          refY='5'
          orient='auto'
          markerUnits='userSpaceOnUse'
        >
          <polygon points='0 0, 6 5, 0 10' fill='#e2626e' />
        </marker>
        <marker
          id='arrow'
          markerWidth='6'
          markerHeight='10'
          refX='6'
          refY='5'
          orient='auto'
          markerUnits='userSpaceOnUse'
        >
          <polygon points='0 0, 6 5, 0 10' />
        </marker>
        <marker
          id='arrow-flip-active'
          markerWidth='6'
          markerHeight='10'
          refX='0'
          refY='5'
          orient='auto'
          markerUnits='userSpaceOnUse'
        >
          <polygon points='6 0, 0 5, 6 10' fill='#e2626e' />
        </marker>
        <marker
          id='arrow-flip'
          markerWidth='6'
          markerHeight='10'
          refX='0'
          refY='5'
          orient='auto'
          markerUnits='userSpaceOnUse'
        >
          <polygon points='6 0, 0 5, 6 10' />
        </marker>
      </defs>
      <path
        id={id}
        className={classNames(
          styles.displayEdge,
          (s || hover) && styles.displayEdgeActive
        )}
        d={d}
        style={style}
        markerEnd={markerEnd}
        markerStart={markerStart}
      />
      {data.enableHover && (
        <path
          id={id}
          className={styles.hoverEdge}
          d={d}
          style={style}
          onMouseOver={() => setHover(true)}
          onMouseOut={() => setHover(false)}
        />
      )}
    </g>
  );
}

export default memo(HoverEdge);
