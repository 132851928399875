export default function TransparencyIndicator() {
  return (
    <svg viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='13' width='4.33333' height='3' fill='#B3B3B3' />
      <rect x='13' y='6' width='4.33333' height='3' fill='#B3B3B3' />
      <rect x='13' y='12' width='4.33333' height='3' fill='#B3B3B3' />
      <rect x='13' y='18' width='4.33333' height='3' fill='#B3B3B3' />
      <rect x='17.3335' y='21' width='4.33333' height='3' fill='#B3B3B3' />
      <rect x='17.3335' y='15' width='4.33333' height='3' fill='#B3B3B3' />
      <rect x='17.3335' y='9' width='4.33333' height='3' fill='#B3B3B3' />
      <rect x='17.3335' y='3' width='4.33333' height='3' fill='#B3B3B3' />
      <rect x='13' y='24' width='4.33333' height='2' fill='#B3B3B3' />
      <rect x='21.6667' width='4.33333' height='3' fill='#B3B3B3' />
      <rect x='21.6665' y='6' width='4.33333' height='3' fill='#B3B3B3' />
      <rect x='21.6665' y='12' width='4.33333' height='3' fill='#B3B3B3' />
      <rect x='21.6665' y='18' width='4.33333' height='3' fill='#B3B3B3' />
      <rect x='21.6665' y='24' width='4.33333' height='2' fill='#B3B3B3' />
      <rect x='17.3333' width='4.33333' height='3' fill='white' />
      <rect x='17.3335' y='6' width='4.33333' height='3' fill='white' />
      <rect x='17.3335' y='12' width='4.33333' height='3' fill='white' />
      <rect x='17.3335' y='18' width='4.33333' height='3' fill='white' />
      <rect x='21.6665' y='21' width='4.33333' height='3' fill='white' />
      <rect x='21.6665' y='15' width='4.33333' height='3' fill='white' />
      <rect x='21.6665' y='9' width='4.33333' height='3' fill='white' />
      <rect x='21.6665' y='3' width='4.33333' height='3' fill='white' />
      <rect x='13' y='21' width='4.33333' height='3' fill='white' />
      <rect x='13' y='15' width='4.33333' height='3' fill='white' />
      <rect x='13' y='9' width='4.33333' height='3' fill='white' />
      <rect x='13' y='3' width='4.33333' height='3' fill='white' />
      <rect x='17.3335' y='24' width='4.33333' height='2' fill='white' />
      <rect y='3' width='4.33333' height='3' fill='#B3B3B3' />
      <rect y='9' width='4.33333' height='3' fill='#B3B3B3' />
      <rect y='15' width='4.33333' height='3' fill='#B3B3B3' />
      <rect y='21' width='4.33333' height='3' fill='#B3B3B3' />
      <rect x='4.3335' width='4.33333' height='3' fill='#B3B3B3' />
      <rect x='4.3335' y='18' width='4.33333' height='3' fill='#B3B3B3' />
      <rect x='4.3335' y='12' width='4.33333' height='3' fill='#B3B3B3' />
      <rect x='4.3335' y='6' width='4.33333' height='3' fill='#B3B3B3' />
      <rect x='8.66675' y='3' width='4.33333' height='3' fill='#B3B3B3' />
      <rect x='8.6665' y='9' width='4.33333' height='3' fill='#B3B3B3' />
      <rect x='8.6665' y='15' width='4.33333' height='3' fill='#B3B3B3' />
      <rect x='8.6665' y='21' width='4.33333' height='3' fill='#B3B3B3' />
      <rect x='4.33325' y='3' width='4.33333' height='3' fill='white' />
      <rect x='4.3335' y='9' width='4.33333' height='3' fill='white' />
      <rect x='4.3335' y='15' width='4.33333' height='3' fill='white' />
      <rect x='4.3335' y='21' width='4.33333' height='3' fill='white' />
      <rect x='8.6665' width='4.33333' height='3' fill='white' />
      <rect x='8.6665' y='18' width='4.33333' height='3' fill='white' />
      <rect x='8.6665' y='12' width='4.33333' height='3' fill='white' />
      <rect x='8.6665' y='6' width='4.33333' height='3' fill='white' />
      <rect width='4.33333' height='3' fill='white' />
      <rect y='18' width='4.33333' height='3' fill='white' />
      <rect y='12' width='4.33333' height='3' fill='white' />
      <rect y='6' width='4.33333' height='3' fill='white' />
      <rect y='24' width='4.33333' height='2' fill='white' />
      <rect x='8.6665' y='24' width='4.33333' height='2' fill='white' />
      <rect x='4.3335' y='24' width='4.33333' height='2' fill='#B3B3B3' />
    </svg>
  );
}
