export const DEFAULT_STATE = {
  name: '',
  template: '',
  url: '',
  method: '',
  headers: {},
  body: '',
  params: {},
  tokens: {},
  responseStructure: '',
  responseMapping: {},
  testParameters: {}
};
