import { useEffect, useMemo } from 'react';
import { useRefState } from '../useRefState';
import { useEventListeners } from '../useEventListeners';
import { EVENTS, KEY_HOLD_DELAY } from './constants';
import {
  allKeysHeld,
  createKeyMap,
  createKeyShortcutMap,
  eventToKeyMap,
  getKey,
  toShortcutString
} from './utils';

const DEFAULT_KEY_STATE = {
  keys: {},
  held: {},
  shortcut: ''
};

export const useKeyboardListener = ({
  keys: targetKeys = [],
  ignore = () => false,
  preventDefault = false
} = {}) => {
  const [, setKeyState, getKeyState] = useRefState({ ...DEFAULT_KEY_STATE });
  const [eventListeners, _emit] = useEventListeners(Object.values(EVENTS));

  const targetKeyMap = useMemo(() => createKeyMap(targetKeys), [targetKeys]);
  const targetShortcutMap = useMemo(
    () => createKeyShortcutMap(targetKeys),
    [targetKeys]
  );

  const setState = (fn: any) => {
    setKeyState((prevState: any) => {
      const { previous: _, ...newPreviousState } = prevState;
      const newState = fn({ ...prevState });
      const isHeld = allKeysHeld(newState);

      return {
        ...newState,
        isHeld,
        isClicked: !isHeld,
        previous: {
          timestamp: new Date().getTime(),
          state: newPreviousState
        }
      };
    });
  };

  const emit = (event: any, { previous, held, ...state }: any) => {
    _emit(event, state);
  };

  useEffect(() => {
    const keyHoldTimeouts: Record<string, any> = {};

    const resetKeyHoldTimeout = (key: any) => {
      if (keyHoldTimeouts[key]) {
        clearTimeout(keyHoldTimeouts[key]);
        keyHoldTimeouts[key] = null;
        delete keyHoldTimeouts[key];
      }
    };

    const startKeyHoldTimeout = (keys: any) => {
      let _keys: any = [];

      if (typeof keys === 'string' || keys instanceof String) _keys = [keys];
      if (typeof keys === 'object' && keys !== null) _keys = Object.keys(keys);

      // @ts-expect-error TS(7006) FIXME: Parameter 'key' implicitly has an 'any' type.
      _keys.forEach((key) => {
        if (keyHoldTimeouts[key]) resetKeyHoldTimeout(key);
        if (getKeyState().held[key]) return; // Prevent override a key if it's already held

        keyHoldTimeouts[key] = setTimeout(() => {
          setState((prevState: any) => ({
            ...prevState,
            held: {
              ...prevState.held,
              [key]: true
            }
          }));

          resetKeyHoldTimeout(key);
        }, KEY_HOLD_DELAY);
      });
    };

    const keydownHandler = (e: any) => {
      if (targetKeys.length === 0) return; // No target keys to track

      const key = getKey(e.key, e.code);
      const keyMap = eventToKeyMap(e);

      // @ts-expect-error TS(2554) FIXME: Expected 0 arguments, but got 1.
      if (!targetKeyMap[key] || ignore(keyMap)) {
        setState((prevState: any) => {
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          if (targetShortcutMap[prevState.shortcut]) {
            emit(EVENTS.onKeyup, prevState);
          }

          return DEFAULT_KEY_STATE;
        });

        return; // Avoid doing anything for keys that aren't being tracked or are ignored
      }

      emit(EVENTS.onKeypress, {
        shortcut: toShortcutString(keyMap)
      });

      setState((prevState: any) => {
        const newKeys =
          prevState.shortcut.indexOf('Meta') >= 0
            ? keyMap
            : { ...prevState.keys, ...keyMap };

        if (JSON.stringify(newKeys) === JSON.stringify(prevState.keys)) {
          if (preventDefault) {
            e.preventDefault();
          }

          return prevState; // No change in keys down
        }

        // Emit `onKeyup` if the previous shortcut was a target and held
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        if (targetShortcutMap[prevState.shortcut]) {
          emit(EVENTS.onKeyup, prevState);
        }

        const newState = {
          ...prevState,
          keys: newKeys,
          held: {
            ...Object.keys(newKeys).reduce(
              (keys, key) => ({ ...keys, [key]: false }),
              {}
            )
          },
          shortcut: toShortcutString(newKeys)
        };

        // Prevent the default if the new shortcut is a target
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        if (targetShortcutMap[newState.shortcut]) {
          if (preventDefault) {
            e.preventDefault();
          }

          emit(EVENTS.onKeydown, newState);
        }

        return newState;
      });

      startKeyHoldTimeout({ ...getKeyState().keys });
    };

    const keyupHandler = (e: any) => {
      if (targetKeys.length === 0) return; // No target keys to track

      const key = getKey(e.key, e.code);
      if (!targetKeyMap[key]) {
        return; // Avoid doing anything for keys that aren't being tracked
      }

      // Avoid the hold timeout from firing as the key is now up
      if (keyHoldTimeouts[key] !== null) {
        resetKeyHoldTimeout(key);
      }

      setState((prevState: any) => {
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        if (targetShortcutMap[prevState.shortcut]) {
          emit(EVENTS.onKeyup, prevState);
        }

        return DEFAULT_KEY_STATE;
      });
    };

    document.addEventListener('keydown', keydownHandler);
    document.addEventListener('keyup', keyupHandler);

    return () => {
      document.removeEventListener('keydown', keydownHandler);
      document.removeEventListener('keyup', keyupHandler);
    };
  }, []);

  return eventListeners;
};
