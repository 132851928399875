function Plaid({ width = 64, height = 64 }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='181.5529327392578 119.37910461425781 224 226.09999084472656'
    >
      <g transform='matrix(1, 0, 0, 1, 160.552936, -88.320889)'>
        <path
          className='st3'
          d='M 109.7 207.7 L 40.4 225.8 L 21.3 295.5 L 45.3 320 L 21 344.1 L 39 414.1 L 108 433.4 L 132.3 409.3 L 156.2 433.8 L 225.5 415.7 L 244.6 346 L 220.7 321.5 L 245 297.4 L 227 227.4 L 158 208.1 L 133.7 232.2 L 109.7 207.7 Z M 67.2 239.2 L 103.7 229.6 L 119.7 246 L 96.4 269.1 L 67.2 239.2 Z M 147.3 246.3 L 163.5 230.2 L 199.8 240.4 L 170.1 269.9 C 170.2 269.8 147.3 246.3 147.3 246.3 Z M 43.2 289.8 L 53.3 253.1 L 82.5 283 L 59.2 306.1 L 43.2 289.8 Z M 183.9 283.9 L 213.6 254.4 L 223.1 291.3 L 206.9 307.4 C 206.8 307.4 183.9 283.9 183.9 283.9 Z M 110.1 283.3 L 133.4 260.2 L 156.3 283.7 L 133 306.8 L 110.1 283.3 Z M 72.9 320.2 L 96.2 297.1 L 119.1 320.6 L 95.8 343.7 L 72.9 320.2 Z M 146.7 320.8 L 170 297.7 L 192.9 321.2 L 169.6 344.3 C 169.6 344.4 146.7 320.8 146.7 320.8 Z M 42.8 350.2 L 59 334.1 L 81.9 357.6 L 52.3 387.1 C 52.2 387.1 42.8 350.2 42.8 350.2 Z M 109.5 357.8 L 132.8 334.7 L 155.7 358.2 L 132.4 381.3 L 109.5 357.8 Z M 183.3 358.4 L 206.6 335.3 L 222.6 351.7 L 212.6 388.4 C 212.5 388.4 183.3 358.4 183.3 358.4 Z M 65.9 401.1 L 95.5 371.6 L 118.4 395.1 L 102.2 411.2 L 65.9 401.1 Z M 146.1 395.4 L 169.4 372.3 L 198.6 402.2 L 162.1 411.8 L 146.1 395.4 Z'
          fill='rgb(17, 17, 17);'
        />
      </g>
    </svg>
  );
}

export default Plaid;
