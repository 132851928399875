function Flinks({ width = 100, height = 100 }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 319.5 460.9'
      width={width}
      height={height}
    >
      <path
        fill='#2c3c4e'
        d='M0 406.7v-13.8h11.4c.1-18.7 8.4-28 25-28 2.1 0 4.3.2 6.6.7v12.8c-1.6-.2-2.8-.3-3.6-.3-4.2 0-7.2 1-9 3-1.8 2-2.8 5.9-2.8 11.7H43v13.8H27.5v53.2H11.4v-53.2H0zM58.5 459.9v-92.5h16.1v92.5H58.5zM91.8 459.9v-67h16.1v67H91.8zM91.8 382.5v-15.4h16.1v15.4H91.8zM126 459.9v-60.7c9.8-4.9 20.1-7.3 30.7-7.3 9.4 0 16.7 2.4 21.9 7.3 5.3 4.9 7.9 11.7 7.9 20.5v40.2h-16.1v-41.6c0-1.8-.2-3.3-.5-4.5-.3-1.2-1-2.5-2-3.8-1-1.3-2.6-2.3-4.8-2.9-2.2-.7-5.1-1-8.6-1-3.9 0-8.1.4-12.4 1.2v52.6H126zM202.7 459.9v-92.1h16.1v49.7l22.5-24.6h21.4l-29.2 29.8 32.2 37.2h-20.4l-22.2-26.4-4.5 4.5v21.9h-15.9zM271.3 412.8c0-6.7 2.4-11.9 7.1-15.5 4.7-3.6 10.6-5.4 17.7-5.4 6.6 0 12.7 1.6 18.3 4.8V409c-4.5-2.1-9.7-3.2-15.5-3.2-7.7 0-11.5 1.9-11.5 5.7 0 1.9 1.1 3.4 3.3 4.4 2.2 1.1 4.9 2 8 2.8 3.1.8 6.3 1.9 9.4 3.3 3.1 1.4 5.8 3.7 8 7s3.3 7.5 3.3 12.6c0 5.6-2.3 10.3-6.9 13.9-4.6 3.7-10.6 5.5-18.1 5.5-7.7 0-14.8-1.8-21.2-5.5v-14.3c5.9 3.9 12.2 5.9 18.9 5.9 4.6 0 7.6-.5 9-1.6 1.4-1.1 2.1-2.7 2.1-4.8 0-2-1.1-3.6-3.3-4.8-2.2-1.2-4.9-2.3-8-3.2-3.1-.9-6.3-2-9.4-3.2-3.1-1.2-5.8-3.3-8-6.1-2-2.7-3.2-6.3-3.2-10.6z'
      />
      <path
        fill='#b9e5fb'
        d='M251.8 144.2c-5.2-7.3-17.4-20.8-32.5-36.6 23-23.1 42.8-43.1 43.2-43.4 5.4-5.4 5.3-14.1-.1-19.5-5.4-5.4-14.1-5.3-19.5.1-.4.4-20 20.2-42.8 43-7-7.1-14.2-14.4-21.3-21.5 17.3-17.1 33.2-32.7 43.5-42.6 5.5-5.3 5.6-14 .3-19.5s-14-5.6-19.5-.3c-1.9 1.8-20.7 20-43.8 43-23.2-23-41.9-41.1-43.8-43-5.5-5.3-14.2-5.2-19.5.3-5.3 5.5-5.2 14.2.3 19.5 10.3 10 26.2 25.5 43.5 42.6-7.1 7.1-14.3 14.4-21.3 21.5C95.6 65 76 45.2 75.6 44.8c-5.4-5.4-14.1-5.5-19.5-.1s-5.5 14.1-.1 19.5c.4.4 20.2 20.4 43.2 43.4C84 123.5 71.8 137 66.6 144.2 46.7 172 46.9 200.1 47 204.4c.1 61.7 50.3 111.9 112 112h.4c61.7-.1 111.9-50.3 112-112 .1-4.3.2-32.4-19.6-60.2zm-92.6-58.4c7.2 7.3 14.4 14.5 21.4 21.6-7.4 7.4-14.7 14.7-21.4 21.3-6.6-6.6-13.9-13.9-21.4-21.3 6.9-7.1 14.1-14.4 21.4-21.6zm2.9 84.5c19.8 19.2 20.9 33.8 20.9 33.8 0 13.1-10.7 23.8-23.8 23.9-13.1-.1-23.8-10.8-23.8-23.6 0-.2 1-14.8 20.9-34.1.9-.9 1.9-1.8 2.9-2.8 1 1 2 1.9 2.9 2.8zm81.7 33v.8c0 46.7-37.9 84.6-84.6 84.7-46.7-.1-84.6-38-84.6-84.7v-.7c0-.2-.7-21.8 14.5-43.1 4.4-6.2 15.5-18.3 29.6-33.1 7.3 7.3 14.4 14.4 21 20.9-.9.9-1.7 1.7-2.5 2.4-28.6 27.7-29.3 51.1-29.3 53.6 0 28.2 22.9 51.2 51.1 51.5H159.6c28.2-.2 51.1-23.2 51.1-51.4 0-2.6-.7-25.9-29.3-53.6-.8-.7-1.6-1.6-2.5-2.4 6.5-6.5 13.7-13.6 21-20.9 14.1 14.8 25.1 26.9 29.6 33.1 15.1 21.2 14.3 42.8 14.3 42.9z'
      />
      <g>
        <path
          opacity='0.8'
          fill='#00ace4'
          d='M159.1 255.6h.2-.3.1zM159.1 316.4c.1 0 .1 0 0 0h-.1.1zM182.8 204.1zM158.9 255.6c.1 0 .1 0 0 0h-.2.2zM159 167.5c5.2-5 12-11.8 19.6-19.4-5.1-5-11.9-11.7-19.6-19.4-7.7 7.6-14.5 14.4-19.6 19.4 7.7 7.6 14.5 14.3 19.6 19.4zM172.9 241.8c0-7.6-6.2-13.8-13.8-13.8h-.1-.1c-7.6 0-13.8 6.2-13.8 13.8 0 7.6 6.1 13.7 13.6 13.8h.6c7.6-.2 13.6-6.3 13.6-13.8z'
        />
        <path
          opacity='0.9'
          fill='#00ace4'
          d='M181.1 150.5c-.8-.7-1.6-1.6-2.5-2.4-7.7 7.6-14.4 14.3-19.6 19.4 1 1 2 2 2.9 2.8 19.8 19.2 20.9 33.8 20.9 33.8 0 13.1-10.7 23.8-23.8 23.9h-.1c-7.6 0-13.8 6.2-13.8 13.8 0 7.6 6.1 13.7 13.6 13.8h.6c28.2-.2 51.1-23.2 51.1-51.4 0-2.6-.7-25.9-29.3-53.7z'
        />
        <path
          opacity='0.8'
          fill='#00ace4'
          d='M137.7 107.4c-6.8 6.9-13.3 13.6-19.2 19.8 7.3 7.3 14.4 14.4 21 20.9 5.1-5 11.9-11.7 19.6-19.4-6.7-6.6-14-13.9-21.4-21.3zM55.9 44.7c-5.4 5.4-5.5 14.1-.1 19.5.4.4 20.2 20.4 43.2 43.5 6-6.3 12.5-13 19.2-19.8C95.4 65 75.8 45.2 75.4 44.8c-5.3-5.4-14.1-5.4-19.5-.1z'
        />
        <path
          opacity='0.8'
          fill='#00ace4'
          d='M137.7 107.4l-19.5-19.5c-6.7 6.8-13.2 13.5-19.2 19.8l19.5 19.5c5.9-6.2 12.4-12.9 19.2-19.8zM158.9 316.4c.1 0 .1 0 0 0 .1 0 0 0 0 0z'
        />
        <g opacity='0.95'>
          <path
            opacity='0.95'
            fill='#00ace4'
            d='M115.2 3.9c-5.5-5.3-14.2-5.2-19.5.3-5.3 5.5-5.2 14.2.3 19.5 10.3 10 26.2 25.5 43.5 42.6 6.7-6.7 13.3-13.2 19.5-19.5-23.1-22.9-41.9-41.1-43.8-42.9zM199.9 87.9c-7-7.1-14.2-14.4-21.3-21.5-6.4 6.3-13 12.9-19.5 19.5 7.2 7.3 14.4 14.5 21.4 21.6 6.4-6.6 13-13.3 19.4-19.6z'
          />
        </g>
        <path
          opacity='0.8'
          fill='#00ace4'
          d='M199.6 127.2l19.5-19.5c-6-6.3-12.5-13-19.2-19.8l-19.5 19.5c6.8 6.9 13.3 13.6 19.2 19.8z'
        />
        <path
          opacity='0.8'
          fill='#00ace4'
          d='M262.1 44.7c-5.4-5.4-14.1-5.3-19.5.1-.4.4-20 20.2-42.8 43l-19.5 19.5c-7.4 7.4-14.7 14.7-21.4 21.3-7.7 7.6-14.5 14.4-19.6 19.4-.9.9-1.7 1.7-2.5 2.4-28.6 27.7-29.3 51.1-29.3 53.6 0 28.2 22.9 51.2 51.1 51.5-7.5-.1-13.6-6.2-13.6-13.8 0-7.6 6.2-13.8 13.8-13.8h.1c-13.1-.1-23.8-10.8-23.8-23.6 0-.2 1-14.8 20.9-34.1.9-.9 1.9-1.8 2.9-2.8 5.2-5 12-11.8 19.6-19.4 6.5-6.5 13.7-13.6 21-20.9l19.5-19.5c23-23.1 42.8-43.1 43.2-43.4 5.4-5.4 5.4-14.1-.1-19.5zM159 85.8l19.5-19.5c-6.7-6.7-13.3-13.2-19.5-19.5-6.3 6.2-12.9 12.8-19.5 19.5 6.4 6.4 13 12.9 19.5 19.5z'
        />
        <path
          opacity='0.8'
          fill='#00ace4'
          d='M222.4 4.2c-5.3-5.5-14-5.6-19.5-.3-1.9 1.8-20.7 20-43.8 43-6.3 6.2-12.9 12.8-19.5 19.5-7.1 7.1-14.3 14.4-21.3 21.5-6.7 6.8-13.2 13.5-19.2 19.8C84 123.5 71.8 137 66.6 144.3c-19.8 27.8-19.7 55.9-19.6 60.1.1 61.7 50.3 111.9 112 112h.4c7.6 0 13.7-6.2 13.7-13.8 0-7.6-6.2-13.8-13.8-13.8h-.1c-46.7-.1-84.6-38-84.6-84.7v-.7c0-.2-.7-21.8 14.5-43.1 4.4-6.2 15.5-18.3 29.6-33.1 5.9-6.2 12.4-12.9 19.2-19.8 6.9-7.1 14.1-14.3 21.4-21.6l19.5-19.5c17.3-17.1 33.2-32.7 43.5-42.6 5.2-5.3 5.4-14 .1-19.5z'
        />
        <path
          opacity='0.95'
          fill='#00ace4'
          d='M251.6 144.2c-5.2-7.3-17.4-20.8-32.5-36.6l-19.5 19.5c14.1 14.8 25.2 26.9 29.6 33.1 15.2 21.3 14.5 42.9 14.5 43v.8c0 46.7-37.9 84.6-84.6 84.7h-.1c-7.6 0-13.8 6.2-13.8 13.8 0 7.6 6.1 13.7 13.7 13.8h.4c61.7-.1 111.9-50.3 112-112 .1-4.2.2-32.3-19.7-60.1z'
        />
      </g>
    </svg>
  );
}

export default Flinks;
