export default function CursorIcon({ width = 8, height = 12 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 8 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.57227 1.2H0V0H7.57227V1.2ZM3.24446 11.2005H0V10.0005H3.24446V1.20049H4.44446V10.0005H7.57227V11.2005H4.44446H3.24446Z'
        fill='#414859'
      />
    </svg>
  );
}
