import { useMemo } from 'react';

import LogicRulesSection, { DecisionLogic } from './LogicRulesSection';
import { cellOperation, elementOperation } from '../../../../utils/themes';
import { HideShowBehaviors } from '../../../Modals';
function HideIfsSection({
  element,
  hideIfs = [],
  showLogic = true,
  handleUpdates,
  isCell = false
}: any) {
  const reshapedHideIfs = useMemo(() => {
    const max =
      hideIfs.length === 0
        ? 0
        : Math.max(...hideIfs.map((hideIf: any) => hideIf.index)) + 1;
    const reshaped = Array.from(
      new Array(max),
      (): DecisionLogic => ({
        rules: []
      })
    );
    hideIfs.forEach((hideIf: any) => reshaped[hideIf.index].rules.push(hideIf));
    return reshaped;
  }, [hideIfs]);

  const title =
    hideIfs.length === 0
      ? 'Show or Hide Conditionally'
      : `${showLogic ? 'Show' : 'Hide'} Conditionally`;

  const operation = isCell ? cellOperation : elementOperation;
  return (
    <LogicRulesSection
      element={element}
      title={title}
      logicRulesType='visibility'
      tooltip='Create rules to show or hide the element.'
      logicItems={reshapedHideIfs}
      behaviorToPerform={showLogic ? 'show' : 'hide'}
      handleUpdates={(
        newHideIfs: DecisionLogic[],
        newBehavior: HideShowBehaviors
      ) => {
        const flatHideIfs: any = [];
        newHideIfs.forEach((hideIfRule, index) =>
          hideIfRule.rules.forEach((hideIf) => {
            flatHideIfs.push({ ...hideIf, index });
          })
        );
        handleUpdates([
          operation({
            propUpdate: {
              hide_ifs: flatHideIfs,
              show_logic: newBehavior === 'show'
            }
          })
        ]);
      }}
    />
  );
}

export default HideIfsSection;
