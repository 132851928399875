import { useParams } from 'react-router-dom';
import FormBuilderPage from '../FormBuilderPage';
import FormFlowPage from '../FormFlowPage';
import FormDesignerUtils from './FormDesignerUtils';
import FormLogicPage from '../FormLogicPage';

const FormDesigner = () => {
  const { stepId, ruleId } = useParams<{ stepId: string; ruleId: string }>();

  let mode = 'builder';
  let componentPage = <FormBuilderPage />;
  if (stepId === 'flow') {
    mode = 'flow';
    componentPage = <FormFlowPage />;
  } else if (stepId === 'logic' || ruleId) {
    mode = 'logic';
    componentPage = <FormLogicPage />;
  }

  return (
    <>
      <FormDesignerUtils mode={mode} />
      {componentPage}
    </>
  );
};

export default FormDesigner;
