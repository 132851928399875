export default function HorizontalCenterIcon({
  width = 16,
  height = 16,
  color = '#414859'
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.16669 10.0833C9.16669 9.53106 8.71897 9.08334 8.16669 9.08334C7.6144 9.08334 7.16669 9.53106 7.16669 10.0833H9.16669ZM7.16669 13C7.16669 13.5523 7.6144 14 8.16669 14C8.71897 14 9.16669 13.5523 9.16669 13H7.16669ZM7.16669 10.0833V13H9.16669V10.0833H7.16669Z'
        fill={color}
      />
      <path
        d='M9.16669 3C9.16669 2.44772 8.71897 2 8.16669 2C7.6144 2 7.16669 2.44772 7.16669 3H9.16669ZM7.16669 5.91667C7.16669 6.46895 7.6144 6.91667 8.16669 6.91667C8.71897 6.91667 9.16669 6.46895 9.16669 5.91667H7.16669ZM7.16669 3V5.91667H9.16669V3H7.16669Z'
        fill={color}
      />
      <rect
        x='4'
        y='5.91666'
        width='8.33333'
        height='4.16667'
        rx='1'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  );
}
