type PasteIconProps = {
  width?: number;
  height?: number;
  color?: string;
};

const PasteIcon = ({
  width = 24,
  height = 25,
  color = '#414859'
}: PasteIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.36364 7H6C5.44772 7 5 7.44772 5 8V20C5 20.5523 5.44772 21 6 21H17C17.5523 21 18 20.5523 18 20V8C18 7.44772 17.5523 7 17 7H15.6364'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.5786 4.75C12.0757 4.75 12.4786 5.15296 12.4786 5.65003H13.9786C13.9786 4.32453 12.9041 3.25 11.5786 3.25V4.75ZM10.6786 5.65003C10.6786 5.15296 11.0815 4.75 11.5786 4.75V3.25C10.2531 3.25 9.17859 4.32453 9.17859 5.65003H10.6786ZM9.61317 5.25H8.99997V6.75H9.61317V5.25ZM8.99997 5.25C8.03349 5.25 7.25 6.03349 7.25 6.99997H8.75C8.75 6.86191 8.86191 6.75 8.99997 6.75V5.25ZM7.25 6.99997V8.00003H8.75V6.99997H7.25ZM7.25 8.00003C7.25 8.96651 8.03349 9.75 8.99997 9.75V8.25C8.86191 8.25 8.75 8.13809 8.75 8.00003H7.25ZM8.99997 9.75H14V8.25H8.99997V9.75ZM14 9.75C14.9665 9.75 15.75 8.96651 15.75 8.00003H14.25C14.25 8.13809 14.1381 8.25 14 8.25V9.75ZM15.75 8.00003V6.99997H14.25V8.00003H15.75ZM15.75 6.99997C15.75 6.03349 14.9665 5.25 14 5.25V6.75C14.1381 6.75 14.25 6.86191 14.25 6.99997H15.75ZM14 5.25H13.5441V6.75H14V5.25ZM9.17859 5.65003C9.17859 5.56049 9.20999 5.47352 9.27214 5.40181C9.33842 5.32534 9.45758 5.25 9.61317 5.25V6.75C10.3059 6.75 10.6786 6.14131 10.6786 5.65003H9.17859ZM12.4786 5.65003C12.4786 6.14131 12.8514 6.75 13.5441 6.75V5.25C13.6997 5.25 13.8188 5.32533 13.8851 5.40181C13.9472 5.47352 13.9786 5.56049 13.9786 5.65003H12.4786Z'
        fill={color}
      />
      <line
        x1='8.75'
        y1='13.25'
        x2='14.25'
        y2='13.25'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <line
        x1='8.75'
        y1='16.25'
        x2='11.25'
        y2='16.25'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default PasteIcon;
