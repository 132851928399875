import '../../../../style/dialog-form.css';

import { useParams } from 'react-router-dom';
import '../rollout-overrides.scss';
import useFeatheryRedux from '../../../../redux';
import { useEffect, useRef, useState } from 'react';
import { useAppSelector } from '../../../../hooks';
import PDFActionRenderer from '../PDFActionRenderer';
import {
  CheckboxField,
  ContextMenu,
  DropdownField,
  InlineTooltip
} from '../../../Core';
import useDraftForm from '../../../../utils/useDraftForm';
import { Chip } from '../../../Core/Chip';
import { CopyIcon, OpenOverflowIcon } from '../../../Icons';
import { useGlobalMouseDownToggle } from '../../../Core/util';
import AccountSwitchModal from '../components/AccountSwitchModal';
import TriggerSwitchModal from '../components/TriggerSwitchModal';
import { ROLLOUT_APPS } from './constants';

export function useRenderFields(
  automations: any[],
  integrationType: string,
  triggerFetch: any
) {
  const appKey = ROLLOUT_APPS[integrationType];
  const [triggerSteps, setTriggerSteps] = useState<string[]>([]);
  const [exportPDFs, setExportPDFs] = useState<boolean[]>([]);
  const [showAccountSwitch, setShowAccountSwitch] = useState(false);
  const [showTriggerSwitch, setShowTriggerSwitch] = useState(false);
  const { steps } = useDraftForm();
  const { formId } = useParams<{ formId: string }>();
  const org = useAppSelector((state) => state.accounts.organization);
  const allowPdfExportUrl =
    org?.tier >= 4 && org?.enterprise_features.rollout_pdf_submissions;

  const [accountMenuPosition, setAccountMenuPosition] = useState<{
    x?: number;
    y?: number;
  }>({});
  const accountMenuRef = useRef<HTMLDivElement>(null);
  const [showAccountMenu, setShowAccountMenu] = useGlobalMouseDownToggle([
    accountMenuRef
  ]);

  const [triggerMenuPosition, setTriggerMenuPosition] = useState<{
    x?: number;
    y?: number;
  }>({});
  const triggerMenuRef = useRef<HTMLDivElement>(null);
  const [showTriggerMenu, setShowTriggerMenu] = useGlobalMouseDownToggle([
    triggerMenuRef
  ]);

  const { switchIntegrationProperty } = useFeatheryRedux();
  const panel = useAppSelector((state) => state.panels.panels[formId]);
  const helperText = panel.track_users ? (
    <div>
      * That means you should test the trigger in Incognito since{' '}
      <a href={`/forms/${formId}/settings/`}>Remember User</a> is on.
    </div>
  ) : null;

  useEffect(() => {
    setTriggerSteps(
      automations.map(
        (automation) => automation?.trigger.inputParams.stepId ?? ''
      )
    );
    setExportPDFs(
      automations.map(
        (automation) =>
          automation?.trigger.inputParams.exportSubmissionPDF ?? false
      )
    );
  }, [automations]);

  const openAccountMenu = (e: any) => {
    e.preventDefault();
    setAccountMenuPosition({ x: e.pageX, y: e.pageY });
    setShowAccountMenu(true);
  };

  const openTriggerMenu = (e: any) => {
    e.preventDefault();
    setTriggerMenuPosition({ x: e.pageX, y: e.pageY });
    setShowTriggerMenu(true);
  };

  const renderFieldFunc = (index: number, rolloutId?: string) => ({
    name: false,
    trigger: ({
      Card,
      TriggerKeyField,
      TriggerCredentialKeyField,
      automationFormData
    }: any) => (
      <Card>
        Select trigger:
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between'
          }}
        >
          <TriggerKeyField />
          {rolloutId && (
            <OpenOverflowIcon
              height={40}
              width={28}
              onClick={openTriggerMenu}
              style={{ cursor: 'pointer' }}
            />
          )}
        </div>
        <ContextMenu
          ref={triggerMenuRef}
          show={showTriggerMenu}
          close={() => setTriggerMenuPosition({})}
          position={triggerMenuPosition as any}
          actions={[
            {
              onMouseDown: () => setShowTriggerSwitch(true),
              title: 'Switch Trigger & Copy Mapping',
              Icon: CopyIcon
            }
          ]}
        />
        {automationFormData.trigger?.triggerKey === 'custom-logic' && (
          <>
            {rolloutId && (
              <Chip label={`ID: ${rolloutId}`} style={{ margin: 0 }} />
            )}
            <div style={{ marginBottom: '5px' }}>
              * Run this action from a Logic Rule. Each action only triggers
              once per submission.
            </div>
          </>
        )}
        {automationFormData.trigger?.triggerKey === 'form-completion' && (
          <>
            <div style={{ marginBottom: '5px' }}>
              * Form Completion only triggers once per submission. Further edits
              will not trigger again.
            </div>
            {helperText}
          </>
        )}
        {automationFormData.trigger?.triggerKey === 'step-loaded' && (
          <DropdownField
            selected={triggerSteps[index] ?? ''}
            onChange={(event: any) => {
              const newTriggers = [...triggerSteps];
              newTriggers[index] = event.target.value;
              setTriggerSteps(newTriggers);
            }}
            options={[
              { value: '', display: 'Select Step', disabled: true },
              ...Object.values(steps).map((step) => ({
                value: step.id,
                display: step.key
              }))
            ]}
          />
        )}
        {automationFormData.trigger?.triggerKey === 'file-submission' &&
          allowPdfExportUrl && (
            <CheckboxField
              checked={exportPDFs[index] ?? false}
              text={
                <>
                  Generate Submission PDFs
                  <InlineTooltip text='To access generated form submission PDF URLs, include the `Submission PDF` variable below.' />
                </>
              }
              onChange={(isChecked) => {
                const newFlags = [...exportPDFs];
                newFlags[index] = isChecked;
                setExportPDFs(newFlags);
              }}
            />
          )}
        <TriggerCredentialKeyField />
      </Card>
    ),
    action: ({
      automationFormData,
      Card,
      ActionKeyField,
      ActionCredentialKeyField,
      ActionInputFields
    }: any) => {
      return (
        <Card>
          <ActionKeyField />
          <AccountSwitchModal
            show={showAccountSwitch}
            close={() => setShowAccountSwitch(false)}
            appKey={appKey}
            switchAccount={async (credential: string) => {
              await switchIntegrationProperty({
                panelId: formId,
                integrationType: integrationType,
                propertyType: 'account',
                propertyValue: credential,
                automation: rolloutId
              });
              triggerFetch();
            }}
          />
          <TriggerSwitchModal
            show={showTriggerSwitch}
            close={() => setShowTriggerSwitch(false)}
            switchTrigger={async (trigger: string) => {
              await switchIntegrationProperty({
                panelId: formId,
                integrationType: integrationType,
                propertyType: 'trigger',
                propertyValue: trigger,
                automation: rolloutId
              });
              triggerFetch();
            }}
          />
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between'
            }}
          >
            <ActionCredentialKeyField />
            {rolloutId && ['hubspot', 'salesforce'].includes(appKey) && (
              <OpenOverflowIcon
                height={40}
                width={28}
                onClick={openAccountMenu}
                style={{ cursor: 'pointer' }}
              />
            )}
          </div>
          <ContextMenu
            ref={accountMenuRef}
            show={showAccountMenu}
            close={() => setAccountMenuPosition({})}
            position={accountMenuPosition as any}
            actions={[
              {
                onMouseDown: () => setShowAccountSwitch(true),
                title: 'Switch Account & Copy Mapping',
                Icon: CopyIcon
              }
            ]}
          />
          {automationFormData.action?.appKey === 'pdf' ? (
            <PDFActionRenderer
              ActionInputFields={ActionInputFields}
              index={index}
            />
          ) : (
            ActionInputFields && <ActionInputFields />
          )}
        </Card>
      );
    }
  });

  return [triggerSteps, exportPDFs, renderFieldFunc];
}
