import { memo, useEffect, useState } from 'react';
import Lottie from 'lottie-react';
import Image from '../Image';

import styles from './styles.module.scss';

function ImageThumbnail({ fileUrl, fileType, height, width, ...props }: any) {
  const [thumbnail, setThumbnail] = useState(null);
  const dimensions = {
    height: `${height}px`,
    width: width ? `${width}px` : undefined
  };

  useEffect(() => {
    if (!fileUrl || !fileType) return;

    const fetchImageData = async () => {
      if (fileType === 'image/*')
        setThumbnail(
          // @ts-expect-error TS(2345) FIXME: Argument of type 'Element' is not assignable to pa... Remove this comment to see the full error message
          <Image
            src={fileUrl}
            alt='Thumbnail'
            className={styles.thumbnail}
            {...props}
          />
        );
      else if (fileType === 'application/json') {
        const animationData = await fetch(fileUrl).then((response) =>
          response.json()
        );
        setThumbnail(
          // @ts-expect-error TS(2345) FIXME: Argument of type 'Element' is not assignable to pa... Remove this comment to see the full error message
          <Lottie
            style={dimensions}
            className={styles.thumbnail}
            animationData={animationData}
            loop
            autoplay
          />
        );
      }
    };
    fetchImageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setThumbnail, fileType, fileUrl]);

  return (
    <div style={dimensions} className={styles.thumbnailContainer}>
      {thumbnail}
    </div>
  );
}

export default memo(ImageThumbnail);
