import { useCallback, useEffect, useRef, useState } from 'react';
import { getCursor, getDistance, isInverted, isVertical } from './utils';

const SCALE_FACTOR = 0.5;
const DRAG_THRESHOLD = 5;

export function useDrag(
  onChange: (position: string, value: number) => void,
  onDragEnd?: () => void,
  min?: number,
  max?: number
) {
  const currentPosition = useRef<string | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const startValue = useRef(0);
  const startPos = useRef<[number, number]>([0, 0]);
  const onChangeRef = useRef(onChange);
  const onDragEndRef = useRef(onDragEnd);

  useEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);

  useEffect(() => {
    onDragEndRef.current = onDragEnd;
  }, [onDragEnd]);

  const onMouseMove = useCallback(
    (event: MouseEvent) => {
      const position = currentPosition.current;
      if (!position) return;
      const pos = startPos.current;
      const { clientX: x2, clientY: y2 } = event;
      const [x1, y1] = pos;
      const distance = getDistance(x1, y1, x2, y2);

      if (distance > DRAG_THRESHOLD) {
        setIsDragging(true);
      }

      let delta = distance * SCALE_FACTOR;

      const vertical = isVertical(position);
      const inverted = isInverted(position);
      if (!vertical && x2 < x1) delta = -delta;
      if (vertical && y1 < y2) delta = -delta;
      if (inverted) delta = -delta;

      let newValue: number = startValue.current + delta;
      if (min != null) newValue = Math.max(newValue, min);
      if (max != null) newValue = Math.min(newValue, max);
      newValue = +newValue.toFixed(0);
      if (newValue != null && !isNaN(newValue)) {
        onChangeRef.current(position, newValue);
      }
    },
    [min, max]
  );

  const onMouseEnd = useCallback(() => {
    setIsDragging((wasDragging) => {
      if (wasDragging) {
        onDragEndRef.current?.();
      }
      return false;
    });
    currentPosition.current = null;
    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseup', onMouseEnd);
  }, []);

  const onMouseDown = useCallback((position: string, currentValue: number) => {
    return (event: React.MouseEvent<SVGPathElement>) => {
      currentPosition.current = position;
      if (event.stopPropagation) event.stopPropagation();
      if (event.preventDefault) event.preventDefault();

      let _startValue = currentValue;
      if (isNaN(_startValue)) {
        _startValue = 0;
      }
      startValue.current = _startValue;
      startPos.current = [event.clientX, event.clientY];
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseEnd);
    };
  }, []);

  return {
    onMouseDown,
    isDragging,
    cursor: getCursor(currentPosition.current)
  };
}
