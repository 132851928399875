import { useRuleBuilderUpdater } from '../../context';
import { ActionBar } from '../ActionBar';
import { RuleExpression } from '../RuleExpression';
import classNames from 'classnames';
import styles from './styles.module.scss';

type RuleConditionProps = {
  condition: IRuleCondition;
  mode?: RuleBuilderMode;
  isOnlyChild?: boolean;
};

export const RuleCondition = ({
  condition,
  mode = 'view',
  isOnlyChild = false
}: RuleConditionProps) => {
  const { addExpressionToCondition, updateCondition } = useRuleBuilderUpdater(
    (s) => ({
      addExpressionToCondition: s.addExpressionToCondition,
      updateCondition: s.updateCondition
    })
  );

  const { operator, operands } = condition;
  const expressions = operands.filter((operand) => {
    return !['and', 'or'].includes(operand.operator);
  });

  const handleChangeOperator = () => {
    updateCondition(condition.id, {
      operator: operator === 'and' ? 'or' : 'and'
    });
  };

  return (
    <div
      className={classNames(styles.ruleConditionContainer, {
        [styles.viewMode]: mode === 'view',
        [styles.editMode]: mode === 'edit'
      })}
    >
      {expressions.map((expression, index) => (
        <div key={expression.id}>
          <RuleExpression
            expression={expression}
            isOnlyClause={isOnlyChild}
            isOnlyChild={expressions.length <= 1}
            mode={mode}
          />
          {index < expressions.length - 1 && (
            <div
              className={styles.ruleConditionOperator}
              onClick={
                mode === 'edit' ? () => handleChangeOperator() : undefined
              }
            >
              {operator === 'and' ? 'and' : 'or'}
            </div>
          )}
        </div>
      ))}
      {mode === 'edit' && (
        <ActionBar
          addCondition
          onClick={() => addExpressionToCondition(condition.id)}
        />
      )}
    </div>
  );
};
