import { memo } from 'react';

import styles from './styles.module.scss';
import { WarningIcon } from '../../Icons';

const WarningCard = ({ text }: any) => {
  return text ? (
    <div className={styles.cardContainer}>
      <div className={styles.iconContainer}>
        <WarningIcon height={20} width={20} />
      </div>
      {text}
    </div>
  ) : null;
};

export default memo(WarningCard);
