export default function EmbedCodeIcon({ width = 18, height = 18 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 17 18'
      fill='#414859'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M17 2.07143V15.4286C17 16.4345 16.1845 17.25 15.1786 17.25H1.82143C0.815469 17.25 0 16.4345 0 15.4286V2.07143C0 1.06547 0.815469 0.25 1.82143 0.25H15.1786C16.1845 0.25 17 1.06547 17 2.07143Z' />
      <path
        d='M2.69766 8.88295L4.86432 11.0496C4.91429 11.1014 4.97406 11.1426 5.04014 11.171C5.10623 11.1994 5.17731 11.2143 5.24923 11.215C5.32115 11.2156 5.39248 11.2019 5.45905 11.1746C5.52562 11.1474 5.58609 11.1072 5.63695 11.0563C5.68781 11.0055 5.72803 10.945 5.75527 10.8784C5.7825 10.8119 5.79621 10.7405 5.79558 10.6686C5.79496 10.5967 5.78001 10.5256 5.75163 10.4595C5.72324 10.3934 5.68197 10.3337 5.63024 10.2837L3.84653 8.49999L5.63024 6.71628C5.68197 6.66632 5.72324 6.60655 5.75163 6.54046C5.78001 6.47438 5.79496 6.4033 5.79558 6.33138C5.79621 6.25946 5.7825 6.18813 5.75527 6.12156C5.72803 6.05499 5.68781 5.99451 5.63695 5.94365C5.58609 5.8928 5.52562 5.85258 5.45905 5.82534C5.39248 5.7981 5.32115 5.7844 5.24923 5.78502C5.17731 5.78565 5.10623 5.80059 5.04014 5.82898C4.97406 5.85737 4.91429 5.89863 4.86432 5.95037L2.69766 8.11703C2.59611 8.21861 2.53906 8.35636 2.53906 8.49999C2.53906 8.64362 2.59611 8.78137 2.69766 8.88295Z'
        fill='white'
        stroke='white'
      />
      <path
        d='M12.1344 5.95037C12.0844 5.89863 12.0247 5.85737 11.9586 5.82898C11.8925 5.80059 11.8214 5.78565 11.7495 5.78502C11.6776 5.7844 11.6063 5.7981 11.5397 5.82534C11.4731 5.85258 11.4126 5.8928 11.3618 5.94365C11.3109 5.99451 11.2707 6.05499 11.2435 6.12156C11.2162 6.18813 11.2025 6.25946 11.2031 6.33138C11.2038 6.4033 11.2187 6.47438 11.2471 6.54046C11.2755 6.60655 11.3168 6.66632 11.3685 6.71628L13.1522 8.49999L11.3685 10.2837C11.3168 10.3337 11.2755 10.3934 11.2471 10.4595C11.2187 10.5256 11.2038 10.5967 11.2031 10.6686C11.2025 10.7405 11.2162 10.8119 11.2435 10.8784C11.2707 10.945 11.3109 11.0055 11.3618 11.0563C11.4126 11.1072 11.4731 11.1474 11.5397 11.1746C11.6063 11.2019 11.6776 11.2156 11.7495 11.215C11.8214 11.2143 11.8925 11.1994 11.9586 11.171C12.0247 11.1426 12.0844 11.1014 12.1344 11.0496L14.3011 8.88295C14.4026 8.78137 14.4597 8.64362 14.4597 8.49999C14.4597 8.35636 14.4026 8.21861 14.3011 8.11703L12.1344 5.95037Z'
        fill='white'
        stroke='white'
      />
      <path
        d='M9.01596 3.97519L7.01921 12.7055C6.98286 12.8645 6.99851 13.0349 7.06272 13.1795C7.12693 13.324 7.23445 13.4308 7.36165 13.4763C7.40637 13.4917 7.45253 13.4997 7.49893 13.5C7.60741 13.4999 7.71291 13.4557 7.79947 13.374C7.88602 13.2923 7.94892 13.1776 7.97865 13.0473L9.9754 4.31692C9.99622 4.2372 10.0039 4.15305 9.99812 4.0695C9.9923 3.98596 9.97304 3.90475 9.94152 3.83071C9.90999 3.75668 9.86683 3.69135 9.81463 3.63863C9.76242 3.58591 9.70224 3.54689 9.63768 3.5239C9.57313 3.50091 9.50552 3.49441 9.43891 3.50481C9.3723 3.51521 9.30806 3.54228 9.25001 3.58442C9.19197 3.62655 9.14133 3.68287 9.10111 3.75001C9.0609 3.81716 9.03194 3.89374 9.01596 3.97519Z'
        fill='white'
        stroke='white'
      />
    </svg>
  );
}
