function FileUploadIcon({ width = 32, height = 32 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.80822 10.1824L16.0018 3.99991L22.1953 10.1824'
        stroke='#414859'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16 20.9159L16 3.99997'
        stroke='#414859'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4 28L28 28'
        stroke='#414859'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export default FileUploadIcon;
