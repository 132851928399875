import { MIN_AXIS_SIZE } from '../constants';
import { AXIS, SIZE_UNITS, SizeUnit } from '../../../../../utils/constants';

export * from './form';

const MINVALUE = 10;

export const fit = 'fit'; // fit track;
export const fill = 'fill'; // fill track;
export const fixedValueRegex = new RegExp(
  `^([0-9]+)px$|^minmax\\(${MINVALUE}px, ([0-9]+)px\\)$`
);

export const getPositionId = (position: number[]) => {
  if (Array.isArray(position)) {
    return position.join(',') || 'root';
  }

  return position;
};

export const getParentPositionId = (position: string | number[]) => {
  const _position =
    typeof position === 'string' ? position.split(',') : [...position];

  _position.pop();

  return getPositionId(_position as number[]);
};

export const getPxValue = (size: string) =>
  isPx(size) ? Number.parseFloat(size) : MIN_AXIS_SIZE;

export const getSizeName = (axis: string) =>
  axis === AXIS.COL ? 'width' : 'height';

export const isPx = (size: string) => size?.indexOf('px') >= 0;

export const isFixed = (valueString: string) => {
  return fixedValueRegex.test(valueString);
};

export const isFit = (valueString: string) => {
  return valueString === fit;
};

export const isFill = (valueString: string) => {
  return valueString === fill;
};

export const isPercentage = (valueString: string) => {
  return valueString.indexOf('%') >= 0;
};

export const parseValue = (valueString: string) => {
  if (!valueString) return MINVALUE;
  if (valueString.indexOf('%') >= 0) return parseInt(valueString.split('%')[0]);
  const parsedValue = valueString.match(fixedValueRegex);
  if (!parsedValue) return MINVALUE;
  return parseInt(parsedValue[1] || parsedValue[2]);
};

export const parseUnit = (unitString: string): SizeUnit => {
  if (isFixed(unitString)) return SIZE_UNITS.PX;
  else if (isPercentage(unitString)) return SIZE_UNITS.PERCENTAGE;
  return unitString as SizeUnit;
};
