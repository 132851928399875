function Argyle({ width = 144, height = 100 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 800 800'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='800' height='800' fill='white' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M538.375 451.541H558.387V308H538.375V451.541ZM251.254 344.078V451.54H271.267V391.212C271.267 373.173 280.122 362.506 297.438 361.921V341.168C283.39 341.945 275.114 346.6 270.306 353.776H269.917V344.078H251.254ZM176.376 359.599C198.509 359.599 213.519 377.054 213.519 398.003C213.519 418.369 197.159 435.828 176.376 435.828C156.363 435.828 140.008 418.181 140.008 398.003C140.008 376.862 156.363 359.599 176.376 359.599ZM213.134 434.858V451.537H233.147V344.079H213.134V359.209H212.753C204.478 346.985 191.39 341.169 175.805 341.169C144.44 341.169 120 365.221 120 399.555C120 430.591 145.401 454.254 176.575 454.254C191.776 454.254 205.248 447.658 212.753 434.858H213.134ZM392.113 397.809C392.113 372.593 373.066 359.597 354.594 359.597C334.772 359.597 318.031 376.86 318.031 397.809C318.031 418.371 334.962 435.826 354.784 435.826C378.069 435.826 392.113 415.265 392.113 397.809ZM391.729 437.378C383.454 448.63 370.18 454.257 354.976 454.257C324.377 454.257 298.019 427.68 298.019 398.585C298.019 366.192 323.035 341.168 355.167 341.168C369.025 341.168 383.649 346.988 392.115 356.494V344.078H412.127V437.378C412.127 472.293 390.769 493.823 354.595 493.823C330.155 493.823 310.912 480.827 301.863 459.881H324.191C331.691 470.548 341.702 475.399 354.785 475.399C379.226 475.399 392.115 462.014 392.115 437.378H391.729ZM464.44 449.404L448.859 487.425H469.634L528.521 344.08H506.971L474.832 427.486L443.471 344.08H421.918L464.44 449.404ZM625.349 359.597C642.475 359.597 658.446 372.785 659.986 391.021H591.094C592.634 372.593 608.99 359.597 625.349 359.597ZM626.121 435.825C609.765 435.825 595.713 424.386 591.095 406.538H679.424C680 402.852 680 399.75 680 396.452C680 365.608 656.14 341.168 624.775 341.168C596.487 341.168 571.082 364.835 571.082 398.777C571.082 427.488 595.331 454.257 625.35 454.257C648.25 454.257 668.263 439.904 676.538 417.786H656.525C650.366 429.233 638.629 435.825 626.121 435.825Z'
        fill='black'
      />
    </svg>
  );
}

export default Argyle;
