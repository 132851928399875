import SettingsContainer from '../../../components/SettingsContainer';
import CollaboratorConfig from '../../../components/Modals/UserCollaborationModal/CollaboratorConfig';

const CollaborationSection = ({ title, formData, handleValueChange }: any) => {
  return (
    <SettingsContainer title={title}>
      <CollaboratorConfig
        showEnableSwitch
        collaborationEnabled={formData.collaboration_enabled ?? false}
        setCollaborationEnabled={(val: any) =>
          handleValueChange('collaboration_enabled')(val)
        }
        anonymousCollaboration={formData.anonymous_collaboration ?? false}
        setAnonymousCollaboration={(val: any) =>
          handleValueChange('anonymous_collaboration')(val)
        }
        nonCollaborationDisabled={formData.non_collaboration_disabled ?? false}
        setNonCollaborationDisabled={(val: any) =>
          handleValueChange('non_collaboration_disabled')(val)
        }
        collaboratorOrdered={formData.collaborator_ordered ?? false}
        setCollaboratorOrdered={(val: any) =>
          handleValueChange('collaborator_ordered')(val)
        }
        collaboratorTemplate={formData.collaborator_template}
        setCollaboratorTemplate={(val: any) =>
          handleValueChange('collaborator_template')(val)
        }
      />
    </SettingsContainer>
  );
};

export default CollaborationSection;
