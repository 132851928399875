import {
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from 'react';

import {
  ConnectionConfirmationModal,
  NavigationEdge,
  StepEntry
} from './components';
import { useAppSelector } from '../../../hooks';

import { FlowIcon } from '../../Icons';
import styles from './styles.module.scss';
import {
  DirectionalEdge,
  linearizeFlow,
  optimizeDisplayableBranches
} from './linearFlow';
import {
  copyStep,
  findSourceElementForConnection,
  getFirstStep,
  Step,
  useNewStep
} from '../../../utils/step';
import { ConditionData as Condition } from '../../NavigationRules/components/types';
import { uniqifyKey } from '../../../utils/format';
import { StepCreateWithModal } from '../../Modals';
import { useFormBuilderChangeStep } from '../../../hooks/useFormBuilderChangeStep';
import { useHistory, useParams } from 'react-router-dom';
import useFeatheryRedux from '../../../redux';
import { SIZE_UNITS, UNDO_TITLES, UNDO_TYPES } from '../../../utils/constants';
import { ACTION_OPTIONS } from '../utils';
import useElementRefCleanup from '../../../utils/useElementRefCleanup';

interface FlowPanelProps {
  setShowConnectionsPanel: (show: boolean) => void;
}
function FlowPanel({ setShowConnectionsPanel }: FlowPanelProps) {
  const { formId } = useParams<{ formId: string }>();
  const history = useHistory();

  const servars = useAppSelector((s) => s.formBuilder.servars);
  const servarUsage = useAppSelector((s) => s.formBuilder.servarUsage);
  const workingSteps = useAppSelector((s) => s.formBuilder.workingSteps);
  const hiddenFields = useAppSelector((state) => state.fields.hiddenFields);
  const panels = useAppSelector((state) => Object.values(state.panels.panels));

  const activeStepId = useAppSelector(
    (state) => state.formBuilder.activeStepId
  );
  const {
    formBuilder: {
      setPanelDataWithUndoRedo,
      updateNavRulesByPair,
      updateNavRulesByElement
    },
    toasts: { addToast }
  } = useFeatheryRedux();

  const [steps, edges] = useMemo(
    () => linearizeFlow(workingSteps),
    [workingSteps]
  );
  const changeStep = useFormBuilderChangeStep();

  const [flowContainerRef, setFlowContainerRef] =
    useState<HTMLDivElement | null>(null);

  // Create a map of step ids to StepEntry elements
  const [stepMapRef, setStepMapRef] = useState<{
    [stepId: string]: HTMLDivElement;
  }>({});

  const setStepElement = (stepId: string, el: HTMLDivElement | null) => {
    if (el && el !== stepMapRef[stepId]) {
      setStepMapRef((stepMapRef) => ({ ...stepMapRef, [stepId]: el }));
    }
  };
  const [, setRender] = useState({ v: 1 });
  useEffect(() => {
    // Keep the map up to date
    const stepKeys = steps.map((step) => step.id);
    const mappedKeys = Object.keys(stepMapRef);
    if (mappedKeys.some((key) => !stepKeys.includes(key))) {
      // remove obsolete StepEntry refs by rebuilding from steps
      const newStepMapRef: { [stepId: string]: HTMLDivElement } = steps.reduce(
        (mapObj: { [stepId: string]: HTMLDivElement }, step) => {
          mapObj[step.id] = stepMapRef[step.id];
          return mapObj;
        },
        {}
      );
      setStepMapRef(newStepMapRef);
    }
    setRender((render) => ({ ...render })); // steps have changed, possibly including reordering, so force rerender
  }, [steps]);

  // Figure out which edges (branches) to display
  const maxBranchLanes = 4;
  const maxBranchPoints = 4;
  const branchLanePairs = optimizeDisplayableBranches(
    steps,
    edges,
    maxBranchLanes,
    maxBranchPoints
  );

  // determine if there are right side branches to be displayed
  const branchesToDisplay = branchLanePairs.reduce(
    (branches, branchLanePair) =>
      branchLanePair[1] !== null && branchLanePair[1] + 1 > branches
        ? branchLanePair[1] + 1
        : branches,
    0
  );

  // Get the edges to adjacent steps for each step
  const stepAdjacentEdges = edges.reduce(
    (
      adjacentEdgesMap: {
        [stepId: string]: DirectionalEdge[];
      },
      edge
    ) => {
      if (edge.distance === 1) {
        [edge.source, edge.target].forEach((stepId) => {
          const adjacentEdges = adjacentEdgesMap[stepId] || [];
          adjacentEdgesMap[stepId] = adjacentEdges;
          adjacentEdges.push(edge);
        });
      }
      return adjacentEdgesMap;
    },
    {}
  );
  const addPositionOnStep = (
    stepId: string,
    stepAbove: Step | null,
    stepBelow: Step | null
  ): 'top' | 'bottom' | null => {
    const adjacentEdges = stepAdjacentEdges[stepId];
    if (!adjacentEdges) return 'bottom';
    if (adjacentEdges.length > 1) return null;
    // only 1 incoming edge
    if (
      stepAbove &&
      (adjacentEdges[0].source === stepAbove.id ||
        adjacentEdges[0].target === stepAbove.id)
    )
      return 'bottom';
    return stepAbove ? 'top' : null; // no plus on top edge of top step for now
  };

  // Determine branch connection points of each step (right side)
  // The to/from above branches should be on top and shortest ones first
  // and the to/from below branches should be on bottom and shortest last in order to minimize crossings.
  const stepConnectionsMap: { [key: string]: DirectionalEdge[] } = steps.reduce(
    (mapObj: { [key: string]: DirectionalEdge[] }, step) => {
      mapObj[step.id] = branchLanePairs
        .filter(
          ([edge, lane]) =>
            (edge.source === step.id || edge.target === step.id) &&
            lane !== null // only concerned with true branches
        )
        .map(([edge]): [DirectionalEdge, string] => [
          edge,
          (edge.source === step.id && edge.direction === 'up') ||
          (edge.target === step.id && edge.direction === 'down')
            ? 'above'
            : 'below'
        ])
        .sort(([aEdge, aFrom], [bEdge, bFrom]) => {
          if (aFrom !== bFrom) {
            if (aFrom === 'above') return -1;
            return 1;
          }
          return aFrom === 'above'
            ? aEdge.distance - bEdge.distance
            : bEdge.distance - aEdge.distance;
        })
        .map(([edge]) => edge);
      return mapObj;
    },
    {}
  );

  // Determine the excess non-displayable edges for each step
  const stepExcessEdgeMap: { [key: string]: number } = steps.reduce(
    (mapObj: { [key: string]: number }, step) => {
      const displayableCount = branchLanePairs.filter(
        ([edge]) => edge.source === step.id || edge.target === step.id
      ).length;
      const totalCount = edges.filter(
        (edge) => edge.source === step.id || edge.target === step.id
      ).length;
      mapObj[step.id] = totalCount - displayableCount;
      return mapObj;
    },
    {}
  );

  const firstStep = useMemo(() => getFirstStep(workingSteps), [workingSteps]);

  // Track step visibility states
  const [visibleSteps, setVisibleSteps] = useState(new Set<string>());
  const handleStepVisibilityChange = (stepId: string, inView: boolean) => {
    const change = inView !== visibleSteps.has(stepId);
    if (change) {
      // const newVisibleSteps = new Set([...visibleSteps]);
      // inView ? newVisibleSteps.add(stepId) : newVisibleSteps.delete(stepId);
      // setVisibleSteps(newVisibleSteps);
      setVisibleSteps((visibleSteps) => {
        const newVisibleSteps = new Set([...visibleSteps]);
        inView ? newVisibleSteps.add(stepId) : newVisibleSteps.delete(stepId);
        return newVisibleSteps;
      });
    }
  };

  // Support scrolling steps into view when auto selected
  const numSteps = Object.keys(workingSteps).length; // re-scroll after a step delete
  const [forceScroll, setForceScroll] = useState(false);
  useLayoutEffect(() => {
    if (activeStepId && !visibleSteps.has(activeStepId)) {
      const stepEl = stepMapRef[activeStepId];
      if (stepEl)
        stepEl.scrollIntoView({
          behavior: 'smooth', // smooth causes problems with large flows
          block: 'end'
        });
    }
    setForceScroll(false);
  }, [activeStepId, numSteps, forceScroll]);

  /// //////////////////////////////////////////////////////////////////////
  // Methods for creating a step on a step or dropping a step onto a step.
  /// //////////////////////////////////////////////////////////////////////

  const { cleanupAllElementReferences } = useElementRefCleanup();

  // Create a step (either a new one or duplicate) or use an existing step
  //  and connect it if possible from the original step
  const newStep = useNewStep();
  const createOrMoveStepAndConnect = useCallback(
    (
      stepId: string,
      duplicate = false,
      existingStepId: string | null = null
    ): void => {
      const sourceStep = workingSteps[stepId];
      if (existingStepId) {
        autoConnectStep(sourceStep, workingSteps[existingStepId], true);
      } else {
        const newKey = uniqifyKey(
          sourceStep.key,
          Object.values(workingSteps).map((step: any) => step.key)
        );
        const theNewStep = duplicate
          ? copyStep(
              sourceStep,
              newKey,
              formId,
              formId,
              servars,
              hiddenFields ?? [],
              panels,
              servarUsage,
              Object.values(workingSteps),
              cleanupAllElementReferences
            )
          : newStep({
              key: newKey,
              width: (firstStep as any)?.width ?? SIZE_UNITS.FILL,
              height: (firstStep as any)?.height ?? SIZE_UNITS.FILL,
              origin: Object.keys(workingSteps).length === 0
            });

        // save the steps
        setPanelDataWithUndoRedo({
          id: theNewStep.id,
          oldValue: {},
          newValue: { [theNewStep.id]: theNewStep },
          title: UNDO_TITLES.NEW_STEP,
          type: UNDO_TYPES.CONDITION,
          workingSteps: { ...workingSteps, [theNewStep.id]: theNewStep }
        });

        // now try and connect it.
        autoConnectStep(sourceStep, theNewStep);
      }
    },
    [workingSteps, formId, servars, hiddenFields, panels, servarUsage, newStep]
  );

  // Auto connect the source step to the new step using element eligibility rules to determine the target element
  // on the source step.
  const autoConnectStep = (
    sourceStep: Step,
    destinationStep: Step,
    move = false
  ): void => {
    // Do not connect if the source is already connected to the destination (outbound)
    if (areStepsConnected(sourceStep, destinationStep)) {
      addToast({
        text: (
          <div>
            <strong>
              The step cannot be connected to from the target step.
            </strong>
            <br />
            They are already connected.
          </div>
        ),
        decay: 6000
      });
      return;
    }

    // Find an eligible auto connection element on the original step
    const sourceElementType = findSourceElementForConnection(
      sourceStep,
      destinationStep
    );

    // If no eligible auto connection element, pop a toast message.
    // Otherwise, if eligible auto connection element, create the non-conditional connection from original to the copy.
    if (!sourceElementType)
      addToast({
        text: move ? (
          <div>
            <strong>
              The step cannot be connected to from the target step.
            </strong>{' '}
            <br />
            No suitable elements available from which to connect it.
          </div>
        ) : (
          <div>
            <strong>New step created but not connected.</strong> <br />
            No suitable elements available from which to connect it.
          </div>
        ),
        decay: 6000
      });
    else {
      const [sourceElement, type] = sourceElementType;
      const newData = [
        {
          previous_step: sourceStep.id,
          next_step: destinationStep.id,
          element_id: sourceElement.id,
          element_type: type,
          created_at: new Date().toISOString(),
          metadata: {},
          rules: []
        }
      ];
      updateNavRulesByPair({
        prevStepId: sourceStep.id,
        nextStepId: destinationStep.id,
        newData,
        formId
      });
    }

    // Select the new copied step and it scroll into view if necessary
    // Working around a race condition with FormBuilderPage.  Just let it call changeStep in its useEffect.
    history.push(`/forms/${formId}/${destinationStep.id}/`);
    setForceScroll(true);
  };

  /// //////////////////////////////////////////////////////////////////////
  // State and methods for creating or dropping a step onto a connection.
  /// //////////////////////////////////////////////////////////////////////

  // Edge (connection) to break upon confirmation
  const [brokenConnection, setBrokenConnection] =
    useState<DirectionalEdge | null>(null);
  const [stepToInsert, setStepToInsert] = useState<Step | null>();
  const [showConfirm, setShowConfirm] = useState(false);
  const [connectionsToBeBroken, setConnectionsToBeBroken] = useState<
    [string, string][]
  >([]);

  /**
   * Insert a new or existing connection into a connection.  The connection
   * is broken and the new/existing step connected in.  Prompts for confirmation first if needed.
   * @param connection Connection to break
   * @param insertedStep Step to insert into the connection, if null, then create a new one.
   * @returns
   */
  const insertNewOrExistingStepIntoConnection = (
    connection: DirectionalEdge,
    insertedStep: Step | null = null
  ): void => {
    // If there are other connections on the existing (dropped) step that will need to be broken in order to
    // connect it into the target connection, then we need to warn the designer and confirm with the modal.
    // We are not warning about the breaking of the original connection here.
    // Otherwise, just do the operation without warning/confirm.

    // Need to check if the step being inserted will need to break connections.
    // Check both ways if bi-directional.
    // Existing connections to the first or second step will be re-used and won't cause an extra broken connection.
    // Available eligible elements will be used for connections before breaking existing connections.

    if (insertedStep) {
      const firstStep = workingSteps[connection.source] as Step;
      const secondStep = workingSteps[connection.target] as Step;
      // get connection (condition) from the first to the second step and any reverse connection as well
      const [connectionToBreak, reverseConnectionToBreak] =
        getConnectionConditionsForSteps(firstStep, secondStep);

      // Sort of have to simulate the connections that would be made to determine what would be broken.
      const getOtherConnectionsToBeBroken = (
        insertedStep: Step,
        secondStep: Step,
        excludedElementIds: string[],
        connectionsToBeBroken: [string, string][]
      ) => {
        if (!areStepsConnected(insertedStep, secondStep)) {
          // Find an eligible auto connection element on the new step
          const stepSourceElementType = findSourceElementForConnection(
            insertedStep,
            secondStep,
            excludedElementIds
          );
          if (stepSourceElementType) {
            const [stepSourceElement] = stepSourceElementType;
            excludedElementIds.push(stepSourceElement.id);
          } else {
            // no existing connection and no element available to create a new connection sooo - must break a connection
            // if one is available!
            const { condition: shortestConnectionToBreak, distance } =
              getShortestOutboundConnection(
                insertedStep,
                firstStep,
                secondStep,
                excludedElementIds
              );
            if (shortestConnectionToBreak) {
              if (distance > 1)
                connectionsToBeBroken.push([
                  workingSteps[shortestConnectionToBreak.previous_step].key,
                  workingSteps[shortestConnectionToBreak.next_step].key
                ]);
              excludedElementIds.push(shortestConnectionToBreak.element_id);
            }
          }
        }
      };

      const excludedElementIds: string[] = [];
      const connectionsToBeBroken: [string, string][] = [];
      if (connectionToBreak)
        getOtherConnectionsToBeBroken(
          insertedStep,
          secondStep,
          excludedElementIds,
          connectionsToBeBroken
        );
      if (reverseConnectionToBreak)
        // dropped on a bidirectional connection
        getOtherConnectionsToBeBroken(
          insertedStep,
          firstStep,
          excludedElementIds,
          connectionsToBeBroken
        );

      if (connectionsToBeBroken.length) {
        setBrokenConnection(connection);
        setStepToInsert(insertedStep);
        setConnectionsToBeBroken(connectionsToBeBroken);
        setShowConfirm(true);
        return;
      }
    }
    doInsertStepIntoConnection(connection, insertedStep);
  };
  /**
   *  Insert a new or existing connection into a connection after the confirmation.
   * @returns
   */
  const onConfirmInsertStepIntoConnection = () => {
    brokenConnection &&
      doInsertStepIntoConnection(brokenConnection, stepToInsert);
  };

  const doInsertStepIntoConnection = (
    brokenConnection: DirectionalEdge,
    insertedStep: Step | null | undefined
  ) => {
    if (!brokenConnection) return;
    const firstStep = workingSteps[brokenConnection.source] as Step;
    const secondStep = workingSteps[brokenConnection.target] as Step;

    // get connection (condition) from the first to the second step and any reverse connection as well
    const [connectionToBreak, reverseConnectionToBreak] =
      getConnectionConditionsForSteps(firstStep, secondStep);

    if (!insertedStep) {
      // use the first step's key to default the new step's key
      const newKey = uniqifyKey(
        firstStep.key,
        Object.values(workingSteps).map((step: any) => step.key)
      );
      insertedStep = newStep({
        key: newKey,
        width: (firstStep as any)?.width ?? SIZE_UNITS.FILL,
        height: (firstStep as any)?.height ?? SIZE_UNITS.FILL,
        origin: Object.keys(workingSteps).length === 0,
        backActionType: reverseConnectionToBreak
          ? ACTION_OPTIONS.NEXT
          : ACTION_OPTIONS.BACK
      }) as Step;

      // save the steps
      setPanelDataWithUndoRedo({
        id: insertedStep.id,
        oldValue: {},
        newValue: { [insertedStep.id]: insertedStep },
        title: UNDO_TITLES.NEW_STEP,
        type: UNDO_TYPES.CONDITION,
        workingSteps: { ...workingSteps, [insertedStep.id]: insertedStep }
      });
    }

    if (connectionToBreak) {
      // eslint-disable-next-line prefer-const
      let { complete, usedElementId } = breakConnectionAndInsertStep(
        insertedStep,
        firstStep,
        secondStep,
        connectionToBreak
      );
      // Break any bi-directional connection
      if (reverseConnectionToBreak) {
        const { complete: reverseComplete } = breakConnectionAndInsertStep(
          insertedStep,
          secondStep,
          firstStep,
          reverseConnectionToBreak,
          // Already used the element, don't use again.  Local object does not reflect this.
          usedElementId ? [usedElementId] : []
        );
        if (!reverseComplete) complete = false;
      }

      // if could not fully connect the step to the second step, serve up some toast
      if (!complete)
        addToast({
          text: (
            <div>
              <strong>The step was moved but only partially connected.</strong>
              <br />
              Not enough suitable elements available from which to connect it.
            </div>
          ),
          decay: 6000
        });
    }
    // Select the new step and it will scroll into view if necessary
    changeStep(insertedStep.id);
    setForceScroll(true);

    setBrokenConnection(null);
    setStepToInsert(null);
  };

  const breakConnectionAndInsertStep = (
    insertedStep: Step,
    firstStep: Step,
    secondStep: Step,
    connectionToBreak: Condition,
    excludedElementIds: string[] = []
  ): { complete: boolean; usedElementId?: string } => {
    // Remove the connection(s) to the second step from the first step that are using the element of the connectionToBreak.
    updateNavRulesByPair({
      prevStepId: firstStep.id,
      nextStepId: secondStep.id,
      newData: [],
      formId
    });

    // If there is already a connection from the first step to the inserted step, then leave it as is.
    // If there is not already a connection, then connect the first step (non-conditionally) to the new step using the
    // same source element on the first step
    if (!areStepsConnected(firstStep, insertedStep)) {
      const newData = [
        {
          previous_step: firstStep.id,
          next_step: insertedStep.id,
          element_id: connectionToBreak.element_id,
          element_type: connectionToBreak.element_type,
          created_at: new Date().toISOString(),
          metadata: {},
          rules: []
        }
      ];
      updateNavRulesByElement({
        prevStepId: firstStep.id,
        nextStepId: insertedStep.id,
        elementId: connectionToBreak.element_id,
        newData,
        formId
      });
    }

    // If there is already a connection from the inserted set to the second step, then leave it as is.
    // If there is not already a connection, then connect the inserted step (non-conditionally) to the second step using
    // any button/text on the new step that is available.
    if (!areStepsConnected(insertedStep, secondStep)) {
      // Find an eligible auto connection element on the new step
      const newStepSourceElementType = findSourceElementForConnection(
        insertedStep,
        secondStep,
        excludedElementIds
      );
      if (newStepSourceElementType) {
        const [newStepSourceElement, type] = newStepSourceElementType;
        const newData = [
          {
            previous_step: insertedStep.id,
            next_step: secondStep.id,
            element_id: newStepSourceElement.id,
            element_type: type,
            created_at: new Date().toISOString(),
            metadata: {},
            rules: []
          }
        ];
        updateNavRulesByPair({
          prevStepId: insertedStep.id,
          nextStepId: secondStep.id,
          newData,
          formId
        });
        return { complete: true, usedElementId: newStepSourceElement.id };
      } else {
        // no eligible element available for the connection so try breaking the most proximal connection that is not to
        //  either first or second element and that is not using an element already used to make a connection here.
        const { condition: shortestConnectionToBreak } =
          getShortestOutboundConnection(
            insertedStep,
            firstStep,
            secondStep,
            excludedElementIds
          );
        if (shortestConnectionToBreak) {
          const newData = [
            {
              previous_step: insertedStep.id,
              next_step: secondStep.id,
              element_id: shortestConnectionToBreak.element_id,
              element_type: shortestConnectionToBreak.element_type,
              created_at: new Date().toISOString(),
              metadata: {},
              rules: []
            }
          ];

          updateNavRulesByElement({
            prevStepId: insertedStep.id,
            nextStepId: secondStep.id,
            elementId: shortestConnectionToBreak.element_id,
            newData,
            formId
          });
          return {
            complete: true,
            usedElementId: shortestConnectionToBreak.element_id
          };
        }
        return { complete: false }; // unable to connect the inserted step to step 2
      }
    }
    return { complete: true };
  };

  // finds and returns the pair for forward and reverse connection conditions for 2 steps
  const getConnectionConditionsForSteps = (step: Step, anotherStep: Step) => [
    step.next_conditions.find(
      (condition) => condition.next_step === anotherStep.id
    ),
    anotherStep.next_conditions.find(
      (condition) => condition.next_step === step.id
    )
  ];

  const areStepsConnected = (step: Step, anotherStep: Step) =>
    step.next_conditions.find(
      (condition) => condition.next_step === anotherStep.id
    ) !== undefined;

  const getShortestOutboundConnection = (
    step: Step,
    firstStep: Step,
    secondStep: Step,
    excludedElementIds: string[] = []
  ) => {
    // Return the most proximal (shortest) outbound connection from the step that is not to
    //  either first or second element and that is not using an element already used to proeviously make a connection.
    const condition = step.next_conditions
      .filter(
        (condition) =>
          condition.previous_step === step.id &&
          condition.next_step !== firstStep.id &&
          condition.next_step !== secondStep.id &&
          !excludedElementIds.includes(condition.element_id)
      )
      .reduce((proximalCondition: Condition | null, condition) => {
        if (!proximalCondition) return condition;
        const edge = branchLanePairForCondition(condition);
        const proximalEdge = branchLanePairForCondition(proximalCondition);
        if (edge && proximalEdge && edge[0].distance < proximalEdge[0].distance)
          return condition;
        return proximalCondition;
      }, null);
    const edge = condition ? branchLanePairForCondition(condition) : null;
    return { condition, distance: edge ? edge[0].distance : 0 };
  };

  const branchLanePairForCondition = (condition: Condition) =>
    branchLanePairs.find(
      ([edge]) =>
        (edge.source === condition.previous_step &&
          edge.target === condition.next_step) ||
        (edge.bidirectional &&
          edge.target === condition.previous_step &&
          edge.source === condition.next_step)
    );

  // completely disconnect the steps
  const disconnectSteps = (connection: DirectionalEdge) => {
    updateNavRulesByPair({
      prevStepId: connection.source,
      nextStepId: connection.target,
      newData: [],
      formId
    });
    // remove any bidirectional as well
    updateNavRulesByPair({
      prevStepId: connection.target,
      nextStepId: connection.source,
      newData: [],
      formId
    });
  };

  return (
    <div className={styles.flowContainer}>
      <div
        className={styles.editFlowButton}
        onClick={() => history.push(`/forms/${formId}/flow/`)}
      >
        <FlowIcon />
        <span>Flow Editor</span>
      </div>
      <div
        ref={setFlowContainerRef}
        className={styles.flowStepsContainer}
        style={{ paddingRight: `${branchesToDisplay * 8}px` }}
      >
        {steps.map((step, i) => (
          <StepEntry
            key={step.id}
            step={step}
            addMenuLocation={addPositionOnStep(
              step.id,
              i > 0 ? steps[i - 1] : null,
              i + 1 <= steps.length ? steps[i + 1] : null
            )}
            excessConnections={stepExcessEdgeMap[step.id] || 0}
            createOrMoveStepAndConnect={createOrMoveStepAndConnect}
            ref={(el) => setStepElement(step.id, el)}
            handleStepVisibilityChange={handleStepVisibilityChange}
            setShowConnectionsPanel={setShowConnectionsPanel}
          />
        ))}
        {branchLanePairs.map(([edge, lane]) => {
          const sourceConnectons = stepConnectionsMap[edge.source] || [];
          const targetConnectons = stepConnectionsMap[edge.target] || [];
          return (
            <NavigationEdge
              key={`${edge.source}-${edge.target}`}
              edge={edge}
              sourceRef={stepMapRef[edge.source]}
              targetRef={stepMapRef[edge.target]}
              containerRef={flowContainerRef}
              bidirectional={edge.bidirectional}
              direction={edge.direction}
              lane={lane}
              activeStep={
                activeStepId === edge.source || activeStepId === edge.target
              }
              sourceConnection={sourceConnectons.indexOf(edge)}
              sourceTotalConnections={sourceConnectons.length}
              targetConnection={targetConnectons.indexOf(edge)}
              targetTotalConnections={targetConnectons.length}
              onInsertNewOrExistingStep={insertNewOrExistingStepIntoConnection}
              onDisconnect={() => disconnectSteps(edge)}
            />
          );
        })}
      </div>
      <StepCreateWithModal
        createStep={(newStep: Step) =>
          autoConnectStep(steps[steps.length - 1], newStep)
        }
        buttonStyle={{ position: 'sticky', bottom: '20px' }}
      />
      <ConnectionConfirmationModal
        show={showConfirm}
        setShow={setShowConfirm}
        connectionsToBeBroken={connectionsToBeBroken}
        onConfirm={onConfirmInsertStepIntoConnection}
      />
    </div>
  );
}

export default memo(FlowPanel);
