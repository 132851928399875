import { OverflowList } from 'react-overflow-list';
import { DynamicTextTooltip } from '../Core';
import styles from './styles.module.scss';

const TAG_MAX_LENGTH = 14;

const TagRenderer = (tag: string, index: number) => {
  let truncatedTag = tag;
  if (tag.length > TAG_MAX_LENGTH) {
    // substract 3 for the ellipsis
    truncatedTag = `${tag.slice(0, TAG_MAX_LENGTH - 3).trim()}...`;
  }
  truncatedTag = truncatedTag.trim().replaceAll(' ', '\u00A0');
  return (
    <DynamicTextTooltip key={index} text={tag} maxLength={TAG_MAX_LENGTH}>
      <div className={styles.tag}>{truncatedTag}</div>
    </DynamicTextTooltip>
  );
};

const TagOverflowRenderer = (items: string[]) => {
  return (
    <DynamicTextTooltip text={items.join(', ')} maxLength={0}>
      <div className={styles.tag}>+&nbsp;{items.length}&nbsp;more</div>
    </DynamicTextTooltip>
  );
};

type TagsContainerProps = {
  tags: string[];
};

export const TagsContainer = (props: TagsContainerProps) => {
  const { tags = [] } = props;

  // safe guard
  if (!(Array.isArray(tags) && tags.length)) {
    return null;
  }

  return (
    <OverflowList
      collapseFrom='end'
      minVisibleItems={0}
      items={tags}
      itemRenderer={TagRenderer}
      overflowRenderer={TagOverflowRenderer}
      className={styles.tagsContainer}
    />
  );
};
