import '../../style/dialog-form.css';

import { Form } from 'react-bootstrap';
import { useState } from 'react';

import IntegrationsSidebar from './IntegrationsSidebar';
import { PropertyLabel, TextField } from '../Core';
import useIntegrations from './useIntegrations';
import { useParams } from 'react-router-dom';

function APIKeySidebar({
  integrationInfo,
  label = '',
  placeholder = '',
  showTest = false
}: any) {
  const { formId } = useParams<{ formId: string }>();

  const integrationType = integrationInfo.key;
  // Global/cached integration settings
  const integration = useIntegrations({
    type: integrationType,
    panelId: formId,
    includeInactive: true
  });

  // Local/draft integration settings
  const [apiKey, setApiKey] = useState(integration?.data.api_key ?? '');
  const [testApiKey, setTestApiKey] = useState(
    integration?.data.metadata.test_api_key ?? ''
  );
  const [isPartial, setIsPartial] = useState(false);

  function onSubmitCustom(newIsActive: boolean) {
    if (newIsActive) {
      const partial = !apiKey;
      setIsPartial(partial);
      if (partial) return;
    }

    const metadata = { api_key: apiKey, test_api_key: testApiKey };
    return {
      isUpdate: integration?.data,
      metadata,
      apiKey
    };
  }

  return (
    <IntegrationsSidebar
      integrationInfo={integrationInfo}
      isPartial={isPartial}
      onSubmitCustom={onSubmitCustom}
    >
      <Form>
        <Form.Group controlId='live-api-key'>
          <PropertyLabel label={(showTest ? 'Live ' : '') + label} />
          <TextField
            placeholder={placeholder}
            value={apiKey || ''}
            onChange={setApiKey}
            error={isPartial && !apiKey}
          />
        </Form.Group>
        {showTest && (
          <Form.Group controlId='test-api-key'>
            <PropertyLabel label={'Test ' + label + ' (Optional)'} />
            <TextField
              placeholder={placeholder}
              value={testApiKey || ''}
              onChange={setTestApiKey}
            />
          </Form.Group>
        )}
      </Form>
    </IntegrationsSidebar>
  );
}

export default APIKeySidebar;
