type Props = {
  height?: number;
  color?: string;
};

const ASPECT_RATIO = 10 / 12;

export default function AIIcon({ height = 12, color = '#6C7589' }: Props) {
  return (
    <svg
      width={height * ASPECT_RATIO}
      height={height}
      viewBox='0 0 10 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.62932 8.49073C1.17434 8.41575 1.17434 7.78597 1.62932 7.70974C2.43744 7.57481 3.18543 7.21155 3.7779 6.66629C4.37036 6.12104 4.78044 5.4185 4.95584 4.64829L4.98183 4.52708C5.08193 4.09348 5.72279 4.08973 5.82679 4.52333L5.85929 4.66453C6.04142 5.43126 6.45522 6.12914 7.04869 6.6705C7.64217 7.21186 8.38884 7.57255 9.1949 7.70724C9.65248 7.78347 9.65248 8.417 9.1949 8.49448C8.38884 8.62917 7.64217 8.98986 7.04869 9.53122C6.45522 10.0726 6.04142 10.7705 5.85929 11.5372L5.82679 11.6771C5.72279 12.1095 5.08193 12.107 4.98183 11.6734L4.95584 11.5534C4.78044 10.7832 4.37036 10.0807 3.7779 9.53543C3.18543 8.99017 2.43744 8.62566 1.62932 8.49073Z'
        fill={color}
      />
      <path
        d='M6.04562 2.14536C5.81813 2.10788 5.81813 1.79298 6.04562 1.75487C6.44968 1.6874 6.82367 1.50577 7.11991 1.23315C7.41614 0.960518 7.62118 0.609251 7.70887 0.224145L7.72187 0.163541C7.77192 -0.05326 8.09235 -0.0551343 8.14435 0.161667L8.1606 0.232267C8.25167 0.61563 8.45857 0.964571 8.7553 1.23525C9.05204 1.50593 9.42538 1.68627 9.82841 1.75362C10.0572 1.79173 10.0572 2.1085 9.82841 2.14724C9.42538 2.21459 9.05204 2.39493 8.7553 2.66561C8.45857 2.93629 8.25167 3.28523 8.1606 3.66859L8.14435 3.73857C8.09235 3.95474 7.77192 3.9535 7.72187 3.73669L7.70887 3.67671C7.62118 3.29161 7.41614 2.94034 7.11991 2.66771C6.82367 2.39509 6.44968 2.21283 6.04562 2.14536Z'
        fill={color}
      />
      <path
        d='M0.113744 4.33024C-0.0379146 4.30525 -0.0379146 4.09532 0.113744 4.06991C0.383117 4.02494 0.632449 3.90385 0.829937 3.7221C1.02743 3.54035 1.16412 3.30617 1.22258 3.04943L1.23125 3.00903C1.26461 2.86449 1.47824 2.86324 1.5129 3.00778L1.52373 3.05484C1.58445 3.31042 1.72238 3.54305 1.9202 3.7235C2.11803 3.90395 2.36692 4.02418 2.63561 4.06908C2.78813 4.09449 2.78813 4.30567 2.63561 4.33149C2.36692 4.37639 2.11803 4.49662 1.9202 4.67707C1.72238 4.85753 1.58445 5.09015 1.52373 5.34573L1.5129 5.39238C1.47824 5.5365 1.26461 5.53566 1.23125 5.39113L1.22258 5.35114C1.16412 5.09441 1.02743 4.86023 0.829937 4.67848C0.632449 4.49672 0.383117 4.37522 0.113744 4.33024Z'
        fill={color}
      />
    </svg>
  );
}
