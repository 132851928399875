import { MODIFIER_KEY_MAP } from './constants';

// @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
export const getKey = (key: string, code: any) => MODIFIER_KEY_MAP[key] || code;

export const eventToKeyMap = (e: any) => {
  const keys: Record<string, boolean> = {};

  if (e.ctrlKey) keys[MODIFIER_KEY_MAP.Control] = true;
  if (e.metaKey) keys[MODIFIER_KEY_MAP.Meta] = true;
  if (e.shiftKey) keys[MODIFIER_KEY_MAP.Shift] = true;
  if (e.altKey) keys[MODIFIER_KEY_MAP.Alt] = true;

  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  if (MODIFIER_KEY_MAP[e.key] && !keys[e.key]) {
    keys[e.key] = true;
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  } else if (!MODIFIER_KEY_MAP[e.key]) {
    keys[e.code] = true;
  }

  return keys;
};

export const toShortcutString = (keys: Record<string, any> = {}) => {
  let shortcutString = '';

  const append = (key: any) => {
    shortcutString += shortcutString.length > 0 ? `+${key}` : key;
  };

  if (keys?.constructor?.name === 'KeyboardEvent') {
    if (keys.ctrlKey) append(MODIFIER_KEY_MAP.Control);
    if (keys.metaKey) append(MODIFIER_KEY_MAP.Meta);
    if (keys.shiftKey) append(MODIFIER_KEY_MAP.Shift);
    if (keys.altKey) append(MODIFIER_KEY_MAP.Alt);

    // Avoid adding another modifier to the shortcut string
    if (
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      MODIFIER_KEY_MAP[keys.key] &&
      shortcutString.indexOf(keys.key) < 0
    ) {
      append(keys.key);
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    } else if (!MODIFIER_KEY_MAP[keys.key]) {
      append(keys.code);
    }
  } else {
    const {
      [MODIFIER_KEY_MAP.Control]: ctrlKey,
      [MODIFIER_KEY_MAP.Meta]: metaKey,
      [MODIFIER_KEY_MAP.Shift]: shiftKey,
      [MODIFIER_KEY_MAP.Alt]: altKey,
      ...remainingKeys
    } = keys; // Picking manually due to performance

    if (ctrlKey) append(MODIFIER_KEY_MAP.Control);
    if (metaKey) append(MODIFIER_KEY_MAP.Meta);
    if (shiftKey) append(MODIFIER_KEY_MAP.Shift);
    if (altKey) append(MODIFIER_KEY_MAP.Alt);

    Object.keys(remainingKeys)
      .sort()
      .forEach((key) => append(key));
  }

  return shortcutString;
};

export const createKeyShortcutMap = (keys = []) => {
  return keys.reduce((keyShortcutMap, key) => {
    if (Array.isArray(key)) {
      return {
        ...keyShortcutMap,
        [toShortcutString(
          (key as any).reduce(
            // @ts-expect-error TS(7006) FIXME: Parameter 'shortcutMap' implicitly has an 'any' ty... Remove this comment to see the full error message
            (shortcutMap, k) => ({
              ...shortcutMap,
              [k]: true
            }),
            {}
          )
        )]: true
      };
    }

    return {
      ...keyShortcutMap,
      [key]: true
    };
  }, {});
};

// @ts-expect-error TS(7023) FIXME: 'createKeyMap' implicitly has return type 'any' be... Remove this comment to see the full error message
export const createKeyMap = (keys = []) => {
  return keys.reduce((keyMap, key) => {
    if (Array.isArray(key)) {
      return {
        ...keyMap,
        ...createKeyMap(key)
      };
    }

    return {
      ...keyMap,
      [key]: true
    };
  }, {});
};

export const allKeysHeld = ({ held }: any) => {
  return Object.values(held).every((isHeld) => isHeld);
};
