import useFeatheryRedux from '../../../redux';
import styles from './styles.module.scss';
import ImageThumbnail from '../../Core/UploadedImageField/ImageThumbnail';
import { memo } from 'react';
import { TrashIcon, WarningIcon } from '../../Icons';

let deleteGuard = false;
function ImageSelectCard({
  image,
  index = 0,
  selected = false,
  onCardSelect = () => {},
  clearSelection = () => {}
}: any) {
  const {
    deleteImage,
    toasts: { addToast }
  } = useFeatheryRedux();

  return image ? (
    <div
      style={{ gridColumn: `${index + 1}/${index + 2}` }}
      className={`${styles.card} ${selected ? styles.selected : ''}`}
      onDoubleClick={() => onCardSelect(image, true)}
      onClick={() => onCardSelect(image)}
    >
      <ImageThumbnail
        fileUrl={image.file}
        fileType={image.type}
        height={112}
        width={195}
      />
      <div className={styles.thumbnailLabel}>
        <span className={styles.thumbnailLabelText}>{image.name}</span>
        <div
          className={styles.cardDeleteContainer}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (deleteGuard) return;
            deleteGuard = true;

            deleteImage({ imageId: image.id })
              .then(() => {
                if (selected) {
                  onCardSelect(null);
                  clearSelection();
                }
              })
              .catch(() => {
                addToast({
                  text: (
                    <>
                      <strong>{image.name}</strong> could not be deleted because
                      it is in use.
                    </>
                  ),
                  icon: <WarningIcon />,
                  decay: 6000
                });
              })
              .finally(() => (deleteGuard = false));
          }}
        >
          <TrashIcon width={20} height={20} className={styles.cardDelete} />
        </div>
      </div>
    </div>
  ) : null;
}

export default memo(ImageSelectCard);
