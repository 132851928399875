import { forwardRef, memo, Ref } from 'react';

import styles from './styles.module.scss';
import ElevatedMenu, { AnchorType } from '../ElevatedMenu';
import classNames from 'classnames';

type ContextMenuAction =
  | boolean
  | 'divider'
  | {
      title: string;
      subtitle?: string;
      Icon?: React.ElementType;
      show?: () => boolean;
      onMouseDown?:
        | ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void)
        | null;
    };

type ContextMenuProps = {
  actions?: ContextMenuAction[];
  show: boolean;
  close: () => void;
  position?: {
    x: number;
    y: number;
  };
  anchor?: AnchorType;
  relativeParent?: HTMLElement;
  className?: string;
  selectedIndex?: number;
  // Element that is the target of the menu.
  // If provided and best fit, the menu will be positioned to not overlap this element.
  target?: HTMLElement | null;
};

function ContextMenu(
  {
    actions = [],
    show,
    close,
    position,
    anchor = 'top_left',
    relativeParent,
    className,
    selectedIndex,
    target
  }: ContextMenuProps,
  ref: Ref<HTMLDivElement>
) {
  if (actions.length === 0) close();

  let selectableActionIndex = 0;
  const _actions = actions
    .filter((a: ContextMenuAction) => !!a)
    .map((action: ContextMenuAction, i: number) => {
      if (action === 'divider') {
        return (
          <div key={`${action}-${i}`} className={styles.contextMenuDividor} />
        );
      }

      if (typeof action === 'object') {
        if (action.show && !action.show()) {
          return null;
        }

        const Icon = action.Icon as any;
        const selected = selectedIndex === selectableActionIndex++;

        return (
          <div
            key={`${action.title}-${i}`}
            className={classNames(
              styles.contextMenuItem,
              !action.onMouseDown && styles.disabled,
              selected && styles.selected
            )}
            onMouseDown={(e) => {
              e.stopPropagation();
              if (action.onMouseDown) {
                close();
                action.onMouseDown(e);
              }
            }}
          >
            {action.Icon && (
              <div className={styles.contextMenuIcon}>
                <Icon width={20} height={20} color='#ffffff' />
              </div>
            )}
            {action.title}
            {action.subtitle && (
              <span className={styles.contextMenuSubtitle}>
                {action.subtitle}
              </span>
            )}
          </div>
        );
      }

      return null;
    });

  return (
    <ElevatedMenu
      ref={ref}
      className={classNames(styles.contextMenu, className)}
      position={position as { x: number; y: number }}
      anchor={anchor}
      bestFit
      show={show}
      relativeParent={relativeParent}
      target={target}
    >
      {_actions}
    </ElevatedMenu>
  );
}

export default memo(forwardRef(ContextMenu));
