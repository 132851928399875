export default function ConnectionsIcon({
  width = 15,
  height = 14,
  color = 'white',
  ...props
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 15 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect
        width='6.00003'
        height='6.00787'
        rx='1'
        transform='matrix(-4.7256e-08 1 1 4.04326e-08 0.992188 7.44838)'
        fill={color}
      />
      <rect
        width='6.00003'
        height='6.00787'
        rx='1'
        transform='matrix(-3.72596e-08 1 1 5.12803e-08 8.55469 0)'
        fill={color}
      />
      <path
        d='M12.558 3.00046H6.04687C4.94231 3.00046 4.04688 3.89589 4.04688 5.00046V9.50049'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  );
}
