export default function TasksIcon({
  width = 30,
  height = 30,
  color = '#DBDFE8'
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.8687 4.98811C19.482 4.57738 24.3112 8.34867 25.7365 13.5575M5.83681 23.7771C4.40254 22.0559 3.39855 19.7611 3.25512 17.1794C2.98571 13.8118 4.23443 10.8238 6.28839 8.57169M25.1995 20.7651C23.4784 24.6376 19.7492 27.5062 15.303 27.793C14.4772 27.8963 13.5771 27.8509 12.7096 27.7104'
        stroke={color}
        strokeWidth='3'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.9418 9.04372C16.7879 8.92895 18.1914 7.33938 18.0766 5.49332C17.9618 3.64725 16.3723 2.24376 14.5262 2.35853C12.6801 2.4733 11.2766 4.06288 11.3914 5.90894C11.5062 7.755 13.0957 9.15849 14.9418 9.04372Z'
        fill={color}
        stroke={color}
        strokeWidth='2.80194'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.90932 25.4906C7.8085 25.3725 9.25237 23.7372 9.13429 21.8381C9.01622 19.9389 7.38091 18.495 5.48174 18.6131C3.58256 18.7312 2.13869 20.3665 2.25676 22.2656C2.37483 24.1648 4.01014 25.6087 5.90932 25.4906Z'
        fill={color}
        stroke={color}
        strokeWidth='2.88256'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M24.5565 25.7433C26.4349 25.6265 27.8629 24.0091 27.7462 22.1307C27.6294 20.2523 26.012 18.8242 24.1336 18.941C22.2552 19.0578 20.8271 20.6752 20.9439 22.5536C21.0607 24.432 22.6781 25.8601 24.5565 25.7433Z'
        fill={color}
        stroke={color}
        strokeWidth='2.85103'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
