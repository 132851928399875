import { useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { ignoreDrawControls } from '../../RenderingEngine/Controls/utils';

const ReactPortal = ({ children }: React.PropsWithChildren<any>) => {
  const [rootEl, setRootEl] = useState<Element | null>(null);

  useLayoutEffect(() => {
    let root = document.querySelector('.dialog-root');

    if (!root) {
      root = document.createElement('div');
      root.classList.add('dialog-root');
      root.classList.add(ignoreDrawControls);
      document.body.appendChild(root);
    }

    setRootEl(root);
  }, []);

  if (!rootEl) return null;

  return createPortal(children, rootEl);
};

export default ReactPortal;
