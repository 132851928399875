export default function CheckIcon({
  width = 32,
  height = 32,
  color = 'var(--green)',
  stroke = '2',
  ...props
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 10 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M1 3.773 3.824 6.5 9 1.5'
        stroke={color}
        strokeWidth={stroke}
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
