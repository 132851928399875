import { useAppSelector } from '../../hooks';
import { FolderIcon } from '../Icons';
import styles from './styles.module.scss';

type FolderListProps = {
  folderData: Record<string, any[]>;
  onClick: (folder: string) => void;
  name: string;
};

export function FolderList({ folderData, onClick, name }: FolderListProps) {
  const sessionFolders =
    useAppSelector((state) => state.folders.sessionFolders[name]) || [];

  const folders = Array.from(
    new Set([...sessionFolders, ...Object.keys(folderData)])
  );

  return (
    <>
      {folders
        .sort((a: any, b: any) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1))
        .map((folder) => {
          const data = folderData[folder] ?? [];
          return (
            <div
              key={folder}
              className={styles.folderItem}
              onClick={() => onClick(folder)}
            >
              <FolderIcon />
              <p className={styles.folderLabel}>{folder || 'No Folder'}</p>
              <p className={styles.folderDetails}>{`${data.length} ${
                data.length === 1 ? name : name + 's'
              }`}</p>
            </div>
          );
        })}
    </>
  );
}
