import { memo } from 'react';
import { DimensionInput } from '../../../../Core';
import { DimensionInputProps } from '../../../../Core/DimensionInput';

const ImageSizeXY = ({ onChange = () => {}, ...props }) => {
  const handleSizeXYChange = ({ x, y }: any) => {
    // @ts-expect-error TS(2554) FIXME: Expected 0 arguments, but got 1.
    onChange({
      background_image_size_x: x,
      background_image_size_y: y
    });
  };

  return (
    <DimensionInput
      onChange={handleSizeXYChange}
      {...(props as DimensionInputProps)}
      linkRatio
      linkByDefault
    />
  );
};

export default memo(ImageSizeXY);
