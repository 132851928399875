import { useCallback, useEffect, useRef } from 'react';

import styles from './styles.module.scss';
import useCleanUp from '../../../utils/useCleanUp';
import InputSection from './input-section';
import { useDrag } from './useDrag';
import { useSyncedRefState } from '../../../hooks/useSyncedRefState';
import useForcedRender from '../../../hooks/useForcedRender';

function PaddingInput({ value, onComplete: customOnComplete = () => {} }: any) {
  // use ref so that cleanup function always has most recent value
  const padding = useRef({ ...value });
  const triggerRender = useForcedRender();
  const getOnComplete = useSyncedRefState(customOnComplete);

  const setPadding = useCallback((value: Record<string, number>) => {
    padding.current = value;
    triggerRender();
  }, []);

  const { clearCleanUp, setCleanUp } = useCleanUp({
    // Assume the padding component is placed in the detail panel
    triggerCleanUp: true
  });

  useEffect(
    () => {
      setPadding({ ...value });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value.top, value.bottom, value.left, value.right]
  );

  function formatPadding(oldPadding: any) {
    const newPadding = {};
    Object.entries(oldPadding).forEach(
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      ([position, val]) => (newPadding[position] = val || 0)
    );
    return newPadding;
  }

  function onComplete(newPadding: any) {
    newPadding = formatPadding(newPadding);
    setPadding(newPadding);
    getOnComplete()?.(newPadding);
    clearCleanUp();
  }

  function onBlur() {
    onComplete(padding.current);
  }

  function onChange(position: any) {
    return (event: any) => {
      const val = event.target.value;
      const parsedValue = Number.parseInt(val);
      if (isNaN(parsedValue) && val !== '') event.preventDefault?.();
      let value = isNaN(parsedValue) ? 0 : parsedValue;
      if (value && value < 0) value = 0;

      const newPadding = {
        ...padding.current,
        [position]: value
      };
      setPadding(newPadding);
      setCleanUp(() => onComplete(formatPadding(newPadding)));
    };
  }

  const { cursor, onMouseDown, isDragging } = useDrag(
    (position, value) => onChange(position)({ target: { value } }),
    () => onComplete(padding.current),

    0
  );

  return (
    <div className={styles.innerInput}>
      {/* Keep resize cursor while dragging */}
      {isDragging && cursor && (
        <style>{`* {cursor: ${cursor} !important;}`}</style>
      )}
      <svg
        width='100%'
        height='100%'
        viewBox='0 0 138 90'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        style={{ gridRow: '1 / 4', gridColumn: '1 / 4' }}
      >
        <path
          onMouseDown={onMouseDown('top', padding.current.top)}
          cursor='n-resize'
          name='paddingTop'
          d='M0 0L46 30H92L138 0H0Z'
          fill='#eff3f8'
          style={{ pointerEvents: 'all' }}
        />
        <path
          onMouseDown={onMouseDown('bottom', padding.current.bottom)}
          cursor='s-resize'
          name='paddingBottom'
          d='M138 90L92 60L46 60L0 90L138 90Z'
          fill='#eff3f8'
          style={{ pointerEvents: 'all' }}
        />
        <path
          onMouseDown={onMouseDown('left', padding.current.left)}
          cursor='w-resize'
          name='paddingLeft'
          d='M46 60V30L0 0V90L46 60Z'
          fill='#fff'
          style={{ pointerEvents: 'all' }}
        />
        <path
          onMouseDown={onMouseDown('right', padding.current.right)}
          cursor='e-resize'
          name='paddingRight'
          d='M92 30V60L138 90V0L92 30Z'
          fill='#fff'
          style={{ pointerEvents: 'all' }}
        />
      </svg>
      <div className={styles.centerpiece} />

      <InputSection
        gridRow='1 / 2'
        gridColumn='2 / 3'
        html={String(padding.current.top)}
        onChange={onChange('top')}
        onBlur={onBlur}
      />
      <InputSection
        gridRow='2 / 3'
        gridColumn='3 / 4'
        html={String(padding.current.right)}
        onChange={onChange('right')}
        onBlur={onBlur}
      />
      <InputSection
        gridRow='3 / 4'
        gridColumn='2 / 3'
        html={String(padding.current.bottom)}
        onChange={onChange('bottom')}
        onBlur={onBlur}
      />
      <InputSection
        gridRow='2 / 3'
        gridColumn='1 / 2'
        html={String(padding.current.left)}
        onChange={onChange('left')}
        onBlur={onBlur}
      />
    </div>
  );
}

export default PaddingInput;
