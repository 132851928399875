import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, VariantProps } from 'class-variance-authority';
import { cn } from '../../../utils/cn';

const buttonVariants = cva(
  'tw-inline-flex tw-items-center tw-justify-center tw-gap-2 tw-whitespace-nowrap tw-rounded-lg tw-text-[13.333px] tw-font-medium tw-ring-offset-white tw-transition-colors focus-visible:tw-outline-none disabled:tw-pointer-events-none [&_svg]:tw-pointer-events-none [&_svg]:tw-size-4 [&_svg]:tw-shrink-0',
  {
    variants: {
      variant: {
        default:
          'tw-bg-primary tw-text-white hover:tw-bg-primary-hover disabled:tw-bg-primary-light',
        dark: 'tw-bg-gray-900 tw-text-white hover:tw-bg-gray-800',
        light: 'tw-bg-white tw-text-primary hover:tw-bg-neutral-hover',
        'light-primary':
          'tw-bg-primary-100 tw-text-primary hover:tw-bg-primary-200',
        'gray-primary':
          'tw-bg-grey-100 tw-text-primary hover:tw-bg-gray-200 hover:tw-text-primary-dark disabled:tw-text-gray-600 disabled:tw-bg-gray-200',
        gray: 'tw-bg-grey-100 tw-text-gray-800 hover:tw-bg-gray-200 disabled:tw-text-gray-600 disabled:tw-bg-gray-200',
        destructive:
          'tw-bg-destructive tw-text-white hover:tw-bg-destructive-hover disabled:tw-bg-destructive-light',
        outline:
          'tw-box-border tw-border tw-border-neutral-border tw-text-neutral-text tw-bg-neutral hover:tw-bg-neutral-hover disabled:tw-opacity-100 disabled:tw-text-gray-500',
        'outline-primary':
          'tw-box-border tw-border tw-border-primary tw-text-primary tw-bg-white hover:tw-bg-primary hover:tw-text-white disabled:tw-opacity-100 disabled:tw-text-gray-500',
        ghost: 'tw-bg-transparent hover:tw-bg-gray-100 tw-text-gray-800',
        'ghost-primary':
          'tw-bg-transparent hover:tw-bg-primary tw-text-primary hover:tw-text-white',
        dashed:
          'tw-box-border tw-border tw-border-dashed tw-border-gray-500 tw-bg-gray-100 tw-text-gray-800 hover:tw-bg-gray-200',
        'text-primary':
          '!tw-px-0 !tw-py-0 tw-bg-transparent tw-text-primary hover:tw-text-primary-dark'
      },
      size: {
        md: 'tw-h-10 tw-px-4 tw-py-2',
        sm: 'tw-h-9 tw-px-3',
        lg: 'tw-h-11 tw-px-4',
        icon: 'tw-h-10 tw-aspect-square'
      }
    },
    defaultVariants: {
      variant: 'default',
      size: 'lg'
    }
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = 'Button';

export { Button, buttonVariants };
