import {
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useState
} from 'react';
import {
  ActiveComponent,
  ExtractionState,
  ExtractionUpdater,
  PDFState,
  PDFUpdater
} from './types';
import { ExtractionEngine } from './ExtractionEngine';

const initialState: ExtractionState = {
  editingValueId: null,
  hoveredValueId: null,
  selectedValueId: null,
  selectedQuestionId: null,
  activeComponent: null
};

const initialUpdater: ExtractionUpdater = {
  setEditingValueId: () => {},
  setHoveredValueId: () => {},
  setSelectedValueId: () => {},
  setSelectedQuestionId: () => {}
};

const initialPDFState: PDFState = {
  zoom: 1
};

const initialPDFUpdater: PDFUpdater = {
  setZoom: () => {}
};

const StateContext = createContext<ExtractionState>(initialState);
const UpdaterContext = createContext<ExtractionUpdater>(initialUpdater);

const PDFStateContext = createContext<PDFState>(initialPDFState);
const PDFUpdaterContext = createContext<PDFUpdater>(initialPDFUpdater);

export const useExtractionState = () => useContext(StateContext);
export const useExtractionUpdater = () => useContext(UpdaterContext);

export const usePDFState = () => useContext(PDFStateContext);
export const usePDFUpdater = () => useContext(PDFUpdaterContext);

const ExtractionProvider = ({ children }: PropsWithChildren) => {
  const [state, setState] = useState<ExtractionState>(initialState);
  const [pdfState, setPDFState] = useState<PDFState>(initialPDFState);

  const updater: ExtractionUpdater = useMemo(() => {
    return {
      setEditingValueId: (valueId: string | null) => {
        setState((prev) => ({ ...prev, editingValueId: valueId }));
      },
      setSelectedValueId: (
        valueId: string | null,
        activeComponent: ActiveComponent = null
      ) => {
        setState((prev) => {
          const newState = {
            ...prev,
            selectedValueId: valueId,
            activeComponent
          };

          if (valueId) {
            if (!activeComponent) {
              newState.activeComponent = prev.activeComponent;
            }

            if (newState.selectedQuestionId) {
              newState.selectedQuestionId = null;
            }
          }

          return newState;
        });
      },
      setHoveredValueId: (
        valueId: string | null,
        activeComponent: ActiveComponent = null
      ) => {
        setState((prev) => ({
          ...prev,
          hoveredValueId: valueId,
          activeComponent
        }));
      },
      setSelectedQuestionId: (questionId: string | null) => {
        setState((prev) => {
          const newState = { ...prev, selectedQuestionId: questionId };

          if (questionId) {
            newState.selectedValueId = null;
          }

          return newState;
        });
      },
      setZoom: (zoom: number) => {
        setState((prev) => ({ ...prev, zoom }));
      }
    };
  }, [setState]);

  const pdfUpdater: PDFUpdater = useMemo(() => {
    return {
      setZoom: (zoom: number) => {
        setPDFState((prev) => ({ ...prev, zoom }));
      }
    };
  }, [setPDFState]);

  return (
    <StateContext.Provider value={state}>
      <UpdaterContext.Provider value={updater}>
        <ExtractionEngine updater={updater} />
        <PDFStateContext.Provider value={pdfState}>
          <PDFUpdaterContext.Provider value={pdfUpdater}>
            {children}
          </PDFUpdaterContext.Provider>
        </PDFStateContext.Provider>
      </UpdaterContext.Provider>
    </StateContext.Provider>
  );
};

export default ExtractionProvider;
