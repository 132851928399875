import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { AXIS } from '../../../../utils/constants';
import { useGlobalMouseDownToggle } from '../../../Core/util';
import { ContextMenu } from '../../../Core';
import { useClipboard } from '../../../../hooks/useClipboard';
import { useSelectedCell } from '../../../../hooks/useSelectedCell';
import { getOS } from '../../../../utils/browserUtils';
import {
  ColumnSplitIcon,
  CopyIcon,
  PasteIcon,
  ReleaseIcon,
  RotateIcon,
  RowSplitIcon,
  TrashIcon,
  UnformattedPasteIcon
} from '../../../Icons';
import useViewport from '../../../../hooks/useViewport';
import useFeatheryRedux from '../../../../redux';
import testIds from '../../../../utils/testIds';

const CTRL_BUTTON = getOS() === 'MacOS' ? '⌘' : 'Ctrl';

const CellControlMenu = ({
  children,
  node,
  enabled,
  style,
  className,
  onOpen = () => {},
  onClose = () => {},
  id,
  dragRef
}: any) => {
  const {
    toasts: { addToast }
  } = useFeatheryRedux();
  const [menuPosition, setMenuPosition] = useState({});
  const { copy, paste, canPaste, canPasteWithoutCellStyles, clipboard } =
    useClipboard();
  const { remove, rotateAxis, splitRows, splitCols, releaseChildren } =
    useSelectedCell();
  const { isMobile } = useViewport();

  const menuRef = useRef<HTMLDivElement>(null);
  const [showMenu, setShowMenu] = useGlobalMouseDownToggle([menuRef]);

  const show = useMemo(() => {
    return showMenu && node && Object.keys(menuPosition);
  }, [showMenu, node, menuPosition]);

  useEffect(() => {
    if (!show && typeof onClose === 'function') onClose();
  }, [show]);

  const pasteText = useMemo(() => {
    if (!clipboard) return 'Paste';
    if (clipboard.isElement) return 'Paste element';
    else return 'Paste container';
  }, [clipboard]);

  if (!node) return null;

  const menuItems = [
    !node.isRoot() &&
      !isMobile && {
        onMouseDown: () => copy(node),
        title: 'Copy',
        subtitle: `${CTRL_BUTTON}+C`,
        Icon: ({ color }: any) => (
          <CopyIcon width={16} height={16} color={color} />
        )
      },
    canPaste(node) &&
      !isMobile && {
        onMouseDown: () => paste(true, node),
        title: pasteText,
        subtitle: `${CTRL_BUTTON}+V`,
        Icon: PasteIcon
      },
    canPasteWithoutCellStyles(node) &&
      !isMobile && {
        onMouseDown: () => paste(false, node),
        title: 'Paste without container styles',
        subtitle: `${CTRL_BUTTON}+Shift+V`,
        Icon: UnformattedPasteIcon
      },
    (!node.isRoot() || canPaste(node)) && !isMobile && 'divider',
    !isMobile && {
      onMouseDown: () => splitRows(node),
      title: 'Split into rows',
      Icon: RowSplitIcon
    },
    !isMobile && {
      onMouseDown: () => splitCols(node),
      title: 'Split into columns',
      Icon: ColumnSplitIcon
    },
    node.canReleaseChildren() &&
      !isMobile && {
        onMouseDown: () => releaseChildren(node),
        title: 'Ungroup elements',
        Icon: ({ color }: any) => (
          <ReleaseIcon width={16} height={16} color={color} />
        )
      },
    {
      onMouseDown: () => rotateAxis(node),
      title: `Rotate into ${node.axis === AXIS.ROW ? 'columns' : 'rows'}`,
      Icon: RotateIcon
    },
    !node.isRoot() && !isMobile && 'divider',
    !node.isRoot() &&
      !isMobile && {
        onMouseDown: () => remove(node),
        title: 'Delete',
        subtitle: 'Delete',
        Icon: ({ color }: any) => (
          <TrashIcon width={16} height={16} color={color} />
        )
      }
  ].filter(Boolean);

  const onContextMenu = (e: any) => {
    if (enabled) {
      e.preventDefault();

      if (menuItems.length > 0) {
        setMenuPosition({ x: e.pageX, y: e.pageY });
        setShowMenu(true);
        onOpen();
      } else {
        addToast({ text: 'Cannot edit container in Mobile view' });
      }
    }
  };

  const closeContextMenu = () => {
    setMenuPosition({});
    setShowMenu(false);
    onClose();
  };

  const onMouseDown = (e: any) => {
    if (show) {
      closeContextMenu();
      e.stopPropagation();
    }
  };

  return (
    <div
      id={id}
      data-testid={testIds.cellControlMenu}
      className={'cell-control-menu'}
      ref={dragRef}
      onContextMenu={onContextMenu}
      onMouseDown={onMouseDown}
      style={style}
    >
      <ContextMenu
        ref={menuRef}
        className={className}
        show={show}
        close={closeContextMenu}
        position={menuPosition as { x: number; y: number }}
        actions={menuItems}
      />
      {children}
    </div>
  );
};

export default memo(CellControlMenu);
