export default function VerticalBottomIcon({ width = 16, height = 16 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13 12C13.5523 12 14 12.4477 14 13C14 13.5523 13.5523 14 13 14L13 12ZM3 14C2.44772 14 2 13.5523 2 13C2 12.4477 2.44772 12 3 12L3 14ZM13 14L3 14L3 12L13 12L13 14Z'
        fill='#414859'
      />
      <rect
        width='7.23404'
        height='4.16667'
        rx='1'
        transform='matrix(-4.37114e-08 -1 -1 4.37114e-08 10.0833 10.2341)'
        stroke='#414859'
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  );
}
