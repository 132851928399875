import {
  ButtonEditor,
  DefaultEditor,
  ImageEditor,
  ProgressBarEditor,
  ServarFieldEditor,
  TextEditor,
  ThemeGlobalEditor,
  VideoEditor
} from './FormFieldEditors';

import { memo, useEffect } from 'react';
import {
  TYPE_BUTTON,
  TYPE_IMAGE,
  TYPE_PROGRESS_BAR,
  TYPE_TEXT,
  TYPE_VIDEO
} from '../utils/elements';
import ContainerEditor from './FormFieldEditors/ContainerEditor';
import { cleanup } from '../utils/useCleanUp';
import { useAppSelector } from '../hooks';

function DetailPanel({
  theme,
  mode,
  elementType = '',
  cellNode,
  ...props
}: any) {
  const gigPosition = useAppSelector((state) => state.formBuilder.gigPosition);
  const focusedElement = useAppSelector(
    (state) => state.formBuilder.focusedElement
  );

  // Run cleanup function when detail panel selection has changed
  useEffect(() => cleanup.runAndClear(), []);

  const editorProps = {
    mode,
    theme,
    cellNode,
    ...props
  };

  const _elementType =
    elementType ||
    (cellNode
      ? cellNode.isElement
        ? cellNode._type
        : undefined
      : focusedElement.elementType);

  switch (_elementType) {
    case TYPE_PROGRESS_BAR:
      return <ProgressBarEditor {...editorProps} />;
    case TYPE_IMAGE:
      return <ImageEditor {...editorProps} />;
    case TYPE_VIDEO:
      return <VideoEditor {...editorProps} />;
    case TYPE_TEXT:
      return <TextEditor {...editorProps} />;
    case TYPE_BUTTON:
      return <ButtonEditor {...editorProps} />;
    default:
      if (!_elementType && gigPosition && gigPosition.length > 0)
        return <ContainerEditor {...editorProps} />;
      else if (_elementType) return <ServarFieldEditor {...editorProps} />;
      else if (mode === 'theme') return <ThemeGlobalEditor {...editorProps} />;
      else return <DefaultEditor />;
  }
}

export default memo(DetailPanel);
