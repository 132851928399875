import React, { useEffect } from 'react';
import { batch } from 'react-redux';
import { getModalPayload } from '../../components/RenderingEngine/utils';
import { useSyncedRefState } from '../../hooks/useSyncedRefState';
import { useGig } from './context';
import useFeatheryRedux from '../../redux';
import { useAppSelector } from '../../hooks';

export const GigEngine = ({ children }: React.PropsWithChildren<any>) => {
  const { node } = useGig();

  const {
    formBuilder: { wipeFocus, focusElement, gigSetPosition }
  } = useFeatheryRedux();

  const getFocusedElement = useSyncedRefState(
    useAppSelector((state) => state.formBuilder.focusedElement)
  );

  useEffect(() => {
    if (!node || node.isUnlinked()) {
      batch(() => {
        wipeFocus();
        gigSetPosition(null);
      });
    }

    if (!node?.isUnlinked()) {
      const focusedElement = getFocusedElement() ?? {};

      if (node?.isElement && node.id !== focusedElement.elementId) {
        const element = node.element.getRaw();
        focusElement(
          getModalPayload({
            element: element,
            elementType: element._type
          })
        );
      }

      if (!node?.isElement && !!focusedElement.elementId) {
        wipeFocus();
      }
    }
  }, [node, wipeFocus, gigSetPosition, focusElement]);

  return children;
};
