export default function ButtonIcon({ color = '#414859', ...props }) {
  return (
    <svg
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M37.7698 33.1133C38.6385 32.5877 39.2192 31.634 39.2192 30.5446V20.0131C39.2192 18.3562 37.8761 17.0131 36.2192 17.0131H7C6.39196 17.0131 5.82617 17.194 5.3535 17.5049L4.03638 16.7009L7.5975 14.3268C8.38886 13.9311 9.04931 13.6697 9.96792 13.6697H40.439C42.4057 13.6697 44.0001 15.2641 44.0001 17.2309V29.0142C44.0001 30.0704 43.5402 31.0193 42.8098 31.6714L42.8131 31.7476L39.2193 33.9039L37.7698 33.1133Z'
        fill={color}
      />
      <rect
        x='4'
        y='17.0131'
        width='35.2192'
        height='16.5315'
        rx='3'
        stroke={color}
        strokeWidth='3'
        strokeLinecap='round'
      />
    </svg>
  );
}
