import useFeatheryRedux from '../redux';
import { useAppSelector } from './index';

function mapTabAndFolderToPreference(tab: string, folder: string | null) {
  const isFolderTabSelected = tab === 'folders';
  let preference: string | boolean = isFolderTabSelected;

  if (preference && folder != null) {
    preference = folder;
  }

  return preference;
}

function mapPreferenceToTabAndFolder(preference: boolean | string | null) {
  if (preference == null || preference === false) {
    return {
      tab: 'files',
      folder: null
    };
  }

  if (preference === true) {
    return {
      tab: 'folders',
      folder: null
    };
  }

  return {
    tab: 'folders',
    folder: preference
  };
}

export default function useFolderPreference(settingKey: string) {
  const folderViews = useAppSelector(
    (state) => state.accounts.account.folder_views
  );
  const { editAccount } = useFeatheryRedux();

  const preference = folderViews[settingKey] ?? null;
  const { tab, folder } = mapPreferenceToTabAndFolder(preference);

  const setFolderPreference = (tab: string, folder: string | null) => {
    const newPreference = mapTabAndFolderToPreference(tab, folder);
    const newFolderViews = {
      ...folderViews,
      [settingKey]: newPreference
    };
    editAccount({ folder_views: newFolderViews });
  };

  return { tab, folder, setFolderPreference };
}
