// Used by isEmptyStyles to skip specific styles when checking
const IGNORE_STYLES = ['horizontal_align', 'vertical_align'];

/**
 * Determines whether a styles object is considered empty or not. Falsey values within the
 * styles object will be considered empty unless ignored.
 * @param {object} styles - Styles object
 * @param {boolean} [strict=false] - Strict mode checks for an actual empty object
 * @returns {boolean} Whether the styles object is considered empty or not
 */
export const isEmptyStyles = (
  styles: { [key: string]: any },
  strict = false,
  ignoreStyles = IGNORE_STYLES
) => {
  const isEmptyObj = Object.keys(styles).length === 0;

  if (strict || isEmptyObj) {
    return isEmptyObj;
  }

  return Object.entries(styles).every(([style, value]) => {
    if (ignoreStyles.includes(style)) {
      return true;
    }

    if (!value) {
      return true;
    }

    return false;
  });
};
