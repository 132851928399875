const UserTrackingIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.8563 10.8571C13.7499 10.8571 15.2849 9.32212 15.2849 7.42857C15.2849 5.53502 13.7499 4 11.8563 4C9.96276 4 8.42773 5.53502 8.42773 7.42857C8.42773 9.32212 9.96276 10.8571 11.8563 10.8571Z'
        stroke='#6C7589'
        strokeWidth='1.5'
      />
      <path
        d='M18.7117 16.9523C18.7143 16.8274 18.7143 16.7002 18.7143 16.5714C18.7143 14.6781 15.644 13.1428 11.8571 13.1428C8.07029 13.1428 5 14.6781 5 16.5714C5 18.4647 5 20 11.8571 20C13.7694 20 15.1486 19.8803 16.1429 19.667'
        stroke='#6C7589'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
    </svg>
  );
};

export default UserTrackingIcon;
