import { useMemo } from 'react';
import { AIExtractionEntryValue, AIExtractionRun } from '../../types';
import { splitEntryValues } from '../../utils';
import styles from '../styles.module.scss';
import DataLayerPage from './DataLayerPage';
import ErrorCard from '../../../../components/ErrorCard';

type Props = {
  run: AIExtractionRun;
  pageCount: number;
};

const DataLayer = ({ run, pageCount }: Props) => {
  const pageArray = useMemo(() => new Array(pageCount).fill(0), [pageCount]);

  const entriesByPage = useMemo(() => {
    const singularEntries: AIExtractionEntryValue[] =
      run.questionGroups.flatMap((questionGroup) => {
        return questionGroup.entries.reduce((acc, entry) => {
          return [
            ...acc,
            ...splitEntryValues(
              entry,
              questionGroup.entries.filter(({ id }) => id !== entry.id)
            ).map((entryValue) => ({
              ...entryValue,
              questionGroupId: questionGroup.id
            }))
          ];
        }, [] as AIExtractionEntryValue[]);
      });

    return singularEntries.reduce((acc, entry) => {
      if (!acc[entry.pageNumber]) {
        acc[entry.pageNumber] = [entry];
      } else {
        acc[entry.pageNumber].push(entry);
      }

      return acc;
    }, {} as { [key: number]: AIExtractionEntryValue[] });
  }, [run]);

  return (
    <div className={styles.dataLayer}>
      {pageArray.map((_, index) => (
        <DataLayerPage
          key={`page_${index + 1}`}
          page={index + 1}
          entries={entriesByPage[index + 1] || []}
        />
      ))}
      {!run.success && (
        <>
          <div className={styles.dataLayerPushdown}></div>
          <div className={styles.dataLayerError}>
            <div className={styles.content}>
              <ErrorCard
                error={
                  run.errorMessage ||
                  "The extraction ran into an error while processing the user's document."
                }
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DataLayer;
