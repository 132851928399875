import { Cell } from './Cell';
import { Subgrid } from './models';

/**
 * NullCell is used for areas of the code that require a Cell to be defined
 * but no Cell can be provided. A NullCell can be provided to safetly satisfy
 * these conditions.
 */
export class NullCell {
  constructor() {
    return new Cell(new Subgrid());
  }
}
