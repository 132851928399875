import { encodeGetParams, httpDelete, httpGet, URL } from './utils';

export default {
  getTasks: (token: string, query: any) => {
    let url = `${URL.USERS}task/search/`;
    url += `?${encodeGetParams(query)}`;

    return httpGet({
      token,
      url,
      invalid: [URL.USERS]
    });
  },
  getUsers: (token: string, { panel, ...query }: any) => {
    const panelPath = panel ? `${panel}/` : '';
    const url = `${URL.USERS}search/${panelPath}?${encodeGetParams(query)}`;
    return httpGet({
      token,
      url,
      invalid: [URL.USERS]
    });
  },
  deleteUser: (token: string, { userId }: any) => {
    return httpDelete({
      token,
      baseUrl: URL.USERS,
      path: `${userId}/`,
      invalid: [URL.USERS]
    });
  },
  getUserDetail: (token: string, { userId, panel }: any) => {
    let url = `${URL.USERS}${userId}/`;
    if (panel) url += `?panel=${panel}`;
    return httpGet({ token, url });
  }
};
