import { Dispatch } from 'react';

/**
 * Action type definitions
 */
export const ADD_BRANCH = 'ADD_BRANCH';
export const UPDATE_BRANCH = 'UPDATE_BRANCH';
export const REMOVE_BRANCH = 'REMOVE_BRANCH';
export const ADD_CLAUSE = 'ADD_CLAUSE';
export const UPDATE_CLAUSE = 'UPDATE_CLAUSE';
export const REMOVE_CLAUSE = 'REMOVE_CLAUSE';
export const ADD_CONDITION_TO_CLAUSE = 'ADD_CONDITION_TO_CLAUSE';
export const ADD_CONDITION_TO_CONDITION = 'ADD_CONDITION_TO_CONDITION';
export const UPDATE_CONDITION = 'UPDATE_CONDITION';
export const REMOVE_CONDITION = 'REMOVE_CONDITION';
export const ADD_EXPRESSION_TO_CONDITION = 'ADD_EXPRESSION_TO_CONDITION';
export const ADD_EXPRESSION_TO_EXPRESSION = 'ADD_EXPRESSION_TO_EXPRESSION';
export const UPDATE_EXPRESSION = 'UPDATE_EXPRESSION';
export const REMOVE_EXPRESSION = 'REMOVE_EXPRESSION';
export const ADD_ACTION_TO_CLAUSE = 'ADD_ACTION_TO_CLAUSE';
export const UPDATE_ACTION = 'UPDATE_ACTION';
export const REMOVE_ACTION = 'REMOVE_ACTION';
export const ADD_OPERAND_TO_EXPRESSION = 'ADD_OPERAND_TO_EXPRESSION';
export const UPDATE_OPERAND = 'UPDATE_OPERAND';
export const REMOVE_OPERAND = 'REMOVE_OPERAND';
export const UPDATE_DSL = 'UPDATE_DSL';
export const UPDATE_ORIGINAL_DSL = 'UPDATE_ORIGINAL_DSL';
export const UPDATE_DSL_VALIDITY = 'UPDATE_DSL_VALIDITY';
export const UPDATE_ERRORS = 'UPDATE_ERRORS';
export const UPDATE_IS_NEW = 'UPDATE_IS_NEW';
export const SET_API_SERVICES = 'SET_API_SERVICES';
export const SET_PREVIOUS_DSL = 'SET_PREVIOUS_DSL';

export type Action = {
  type: string;
  payload?: any;
};

export type ActionFn = (dispatch: Dispatch<any>) => void;

/**
 * Action handler definitions
 */
const addBranch = (dispatch: Dispatch<any>) => {
  return () => {
    dispatch({ type: ADD_BRANCH });
  };
};

const updateBranch = (dispatch: Dispatch<any>) => {
  return (id: string, updates: Partial<IRuleBranch>) => {
    dispatch({ type: UPDATE_BRANCH, payload: { id, updates } });
  };
};

const removeBranch = (dispatch: Dispatch<any>) => {
  return (id: string) => {
    dispatch({ type: REMOVE_BRANCH, payload: { id } });
  };
};

const addClause = (dispatch: Dispatch<any>) => {
  return (branchId: string) => {
    dispatch({ type: ADD_CLAUSE, payload: { branchId } });
  };
};

const updateClause = (dispatch: Dispatch<any>) => {
  return (id: string, updates: Partial<IRuleClause>) => {
    dispatch({ type: UPDATE_CLAUSE, payload: { id, updates } });
  };
};

const removeClause = (dispatch: Dispatch<any>) => {
  return (id: string) => {
    dispatch({ type: REMOVE_CLAUSE, payload: { id } });
  };
};

const addConditionToClause = (dispatch: Dispatch<any>) => {
  return (clauseId: string) => {
    dispatch({ type: ADD_CONDITION_TO_CLAUSE, payload: { clauseId } });
  };
};

const addConditionToCondition = (dispatch: Dispatch<any>) => {
  return (conditionId: string) => {
    dispatch({ type: ADD_CONDITION_TO_CONDITION, payload: { conditionId } });
  };
};

const updateCondition = (dispatch: Dispatch<any>) => {
  return (id: string, updates: Partial<IRuleCondition>) => {
    dispatch({ type: UPDATE_CONDITION, payload: { id, updates } });
  };
};

const removeCondition = (dispatch: Dispatch<any>) => {
  return (id: string) => {
    dispatch({ type: REMOVE_CONDITION, payload: { id } });
  };
};

const addExpressionToCondition = (dispatch: Dispatch<any>) => {
  return (conditionId: string) => {
    dispatch({ type: ADD_EXPRESSION_TO_CONDITION, payload: { conditionId } });
  };
};

const addExpressionToExpression = (dispatch: Dispatch<any>) => {
  return (expressionId: string) => {
    dispatch({ type: ADD_EXPRESSION_TO_EXPRESSION, payload: { expressionId } });
  };
};

const updateExpression = (dispatch: Dispatch<any>) => {
  return (id: string, updates: Partial<IRuleExpression>) => {
    dispatch({ type: UPDATE_EXPRESSION, payload: { id, updates } });
  };
};

const removeExpression = (dispatch: Dispatch<any>) => {
  return (id: string) => {
    dispatch({ type: REMOVE_EXPRESSION, payload: { id } });
  };
};

const addActionToClause = (dispatch: Dispatch<any>) => {
  return (clauseId: string) => {
    dispatch({ type: ADD_ACTION_TO_CLAUSE, payload: { clauseId } });
  };
};

const updateAction = (dispatch: Dispatch<any>) => {
  return (id: string, updates: Partial<IRuleAction>) => {
    dispatch({ type: UPDATE_ACTION, payload: { id, updates } });
  };
};

const removeAction = (dispatch: Dispatch<any>) => {
  return (id: string) => {
    dispatch({ type: REMOVE_ACTION, payload: { id } });
  };
};

const addOperandToExpression = (dispatch: Dispatch<any>) => {
  return (expressionId: string) => {
    dispatch({ type: ADD_OPERAND_TO_EXPRESSION, payload: { expressionId } });
  };
};

const updateOperand = (dispatch: Dispatch<any>) => {
  return (id: string, updates: Partial<IRuleOperand>) => {
    dispatch({ type: UPDATE_OPERAND, payload: { id, updates } });
  };
};

const removeOperand = (dispatch: Dispatch<any>) => {
  return (id: string) => {
    dispatch({ type: REMOVE_OPERAND, payload: { id } });
  };
};

const updateDSL = (dispatch: Dispatch<any>) => {
  return (dsl: IRuleDSL) => {
    dispatch({ type: UPDATE_DSL, payload: { dsl } });
  };
};

const updateOriginalDSL = (dispatch: Dispatch<any>) => {
  return (dsl: IRuleDSL) => {
    dispatch({ type: UPDATE_ORIGINAL_DSL, payload: { dsl } });
  };
};

const updateDSLValidity = (dispatch: Dispatch<any>) => {
  return (valid: boolean) => {
    dispatch({ type: UPDATE_DSL_VALIDITY, payload: { valid } });
  };
};

const updateErrors = (dispatch: Dispatch<any>) => {
  return (errors: { [key: string]: RuleBuilderError }) => {
    dispatch({ type: UPDATE_ERRORS, payload: { errors } });
  };
};

const updateIsNew = (dispatch: Dispatch<any>) => {
  return (isNew: boolean) => {
    dispatch({ type: UPDATE_IS_NEW, payload: { isNew } });
  };
};

const setAPIServices = (dispatch: Dispatch<any>) => {
  return (apiServices: any[]) => {
    dispatch({ type: SET_API_SERVICES, payload: { apiServices } });
  };
};

const setPreviousDSL = (dispatch: Dispatch<any>) => {
  return (dsl: IRuleDSL) => {
    dispatch({ type: SET_PREVIOUS_DSL, payload: { dsl } });
  };
};

export const actions = {
  addBranch,
  updateBranch,
  removeBranch,
  addClause,
  updateClause,
  removeClause,
  addConditionToClause,
  addConditionToCondition,
  updateCondition,
  removeCondition,
  addExpressionToCondition,
  addExpressionToExpression,
  updateExpression,
  removeExpression,
  addActionToClause,
  updateAction,
  removeAction,
  addOperandToExpression,
  updateOperand,
  removeOperand,
  updateDSL,
  updateOriginalDSL,
  updateDSLValidity,
  updateErrors,
  updateIsNew,
  setAPIServices,
  setPreviousDSL
};
