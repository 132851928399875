import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const getDocuments = createAsyncThunk(
  'documents/getDocuments',
  async ({ token }: { token: string }) => {
    const response = await FeatheryAPI.getDocuments(token);
    if (response.status === 200) {
      return await response.json();
    } else {
      throw new Error('Could not fetch documents');
    }
  }
);

const createDocument = createAsyncThunk(
  'documents/createDocument',
  async ({ token, ...data }: { token: string }) => {
    const response = await FeatheryAPI.createDocument(token, data);
    if (response.status === 201) {
      return await response.json();
    } else {
      throw new Error('Could not create document');
    }
  }
);

const editDocument = createAsyncThunk(
  'documents/editDocument',
  async ({ token, ...data }: { token: string }) => {
    const response = await FeatheryAPI.editDocument(token, data);

    if (response.status === 200) {
      return await response.json();
    } else {
      let message = 'Could not edit document';
      let err: any;
      try {
        err = await response.json();
      } catch {
        throw new Error(message);
      }
      if (err) {
        if (Array.isArray(err)) message = err[0].message;
        else message = JSON.stringify(err);
        throw new Error(message);
      }
    }
  }
);

const deleteDocument = createAsyncThunk(
  'documents/deleteDocument',
  async ({ token, documentId }: { token: string; documentId: string }) => {
    const response = await FeatheryAPI.deleteDocument(token, documentId);

    if (response.status !== 204) {
      throw new Error('Could not delete document');
    }
  }
);

const getEnvelopes = createAsyncThunk(
  'documents/getEnvelopes',
  async ({ token, documentId }: { token: string; documentId: string }) => {
    const response = await FeatheryAPI.getEnvelopes(token, documentId);
    if (response.status === 200) {
      return await response.json();
    } else {
      throw new Error('Could not fetch envelopes');
    }
  }
);

const createEnvelope = createAsyncThunk(
  'documents/createEnvelope',
  async ({ token, ...data }: { token: string }) => {
    const response = await FeatheryAPI.createEnvelope(token, data);
    if (response.status === 201) {
      return await response.json();
    } else {
      throw new Error('Could not create envelopes');
    }
  }
);

const deleteEnvelope = createAsyncThunk(
  'documents/deleteEnvelope',
  async ({ token, envelopeId }: { token: string; envelopeId: string }) => {
    const response = await FeatheryAPI.deleteEnvelope(token, envelopeId);

    if (response.status !== 204) {
      throw new Error('Could not delete envelope');
    }
  }
);

const documentsSlice = createSlice({
  name: 'documents',
  initialState: {
    documents: {} as Record<string, any>,
    envelopes: {} as Record<string, any>
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDocuments.fulfilled, (state, action) => {
      const newDocs: Record<string, any> = {};
      action.payload.forEach((doc: any) => (newDocs[doc.id] = doc));
      state.documents = newDocs;
    });
    builder.addCase(createDocument.fulfilled, (state: any, action: any) => {
      const payload = action.payload;
      state.documents[payload.id] = payload;
    });
    builder.addCase(editDocument.fulfilled, (state: any, action: any) => {
      state.documents[action.payload.id] = action.payload;
    });
    builder.addCase(deleteDocument.fulfilled, (state: any, action: any) => {
      delete state.documents[action.meta.arg.documentId];
    });
    builder.addCase(getEnvelopes.fulfilled, (state: any, action: any) => {
      const newEnvelopes: Record<string, any> = {};
      action.payload.forEach((env: any) => (newEnvelopes[env.id] = env));
      state.envelopes = newEnvelopes;
    });
    builder.addCase(createEnvelope.fulfilled, (state: any, action: any) => {
      const payload = action.payload;
      state.envelopes[payload.id] = payload;
    });
    builder.addCase(deleteEnvelope.fulfilled, (state: any, action: any) => {
      delete state.envelopes[action.meta.arg.envelopeId];
    });
  }
});

export default documentsSlice.reducer;
export {
  getDocuments,
  createDocument,
  editDocument,
  deleteDocument,
  getEnvelopes,
  createEnvelope,
  deleteEnvelope
};
