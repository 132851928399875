import { memo, useRef } from 'react';

import useFeatheryRedux from '../../redux';
import { useParams } from 'react-router-dom';
import { AnnotatedField } from '../DocumentEditorPage/types';

import { useAppSelector } from '../../hooks';
import {
  FileType,
  acceptedFileTypesMap,
  createAnnotatedFieldsFromPdf,
  getFileType,
  mergeDocumentFields,
  readFile,
  validatePdf
} from '../../utils/documents';
import { Button } from '../../components/Core/Button/button';

function DocumentReplaceButton() {
  const { documentId } = useParams<{ documentId: string }>();
  const doc = useAppSelector((state) => state.documents.documents[documentId]);
  const docType: FileType = doc?.type;
  const fileInput = useRef<any>();

  const {
    editDocument,
    toasts: { addErrorToast, addInfoToast }
  } = useFeatheryRedux();

  const onChooseFile = async (e: any) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }

    const readPromise = readFile(file);
    const type = getFileType(file);

    if (!type) {
      addErrorToast({ text: 'Unsupported file type' });
      return;
    }
    if (type !== docType) {
      addErrorToast({ text: 'Document type must match original' });
      return;
    }

    if (type === 'pdf') {
      const { error } = await validatePdf(readPromise);
      if (error) {
        addErrorToast({ text: error as string });
        return;
      }
    }

    submit(readPromise, file, type);
  };

  const submit = async (
    fileReadPromise: Promise<any>,
    file: File,
    type: FileType
  ) => {
    if (!fileReadPromise) {
      addErrorToast({ text: 'Please choose a file' });
      return;
    }
    let annotatedFields: AnnotatedField[] | null = [];

    if (type === 'pdf') {
      // extract annotated fields from pdf based on any fillable fields
      annotatedFields = await createAnnotatedFieldsFromPdf(
        fileReadPromise,
        'required'
      );
      if (!annotatedFields) {
        addErrorToast({ text: 'Error reading PDF fields' });
        return;
      }

      annotatedFields = mergeDocumentFields(
        doc.annotated_fields,
        annotatedFields
      );
    }

    await editDocument({
      documentId,
      file,
      annotated_fields: annotatedFields
    });

    addInfoToast('Replaced document');
  };

  return (
    <div>
      <Button onClick={() => fileInput.current.click()}>Upload</Button>
      <input
        id='choose-document'
        ref={fileInput}
        onChange={onChooseFile}
        type='file'
        accept={acceptedFileTypesMap[docType]}
        style={{
          position: 'relative',
          height: 0,
          width: 0,
          opacity: 0
        }}
      />
    </div>
  );
}

export default memo(DocumentReplaceButton);
