export default [
  'Anhui',
  'Beijing',
  'Chongqing',
  'Fujian',
  'Gansu',
  'Guangdong',
  'Guangxi',
  'Guizhou',
  'Hainan',
  'Hebei',
  'Heilongjiang',
  'Henan',
  'Hubei',
  'Hunan',
  'Inner Mongolia',
  'Jiangsu',
  'Jiangxi',
  'Jilin',
  'Liaoning',
  'Ningxia',
  'Qinghai',
  'Shaanxi',
  'Shandong',
  'Shanghai',
  'Shanxi',
  'Sichuan',
  'Tianjin',
  'Xinjiang',
  'Xizang',
  'Yunnan',
  'Zhejiang'
];
