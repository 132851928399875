import { v4 as uuidv4 } from 'uuid';

import {
  CollapsibleSection,
  SectionHeader,
  TextField
} from '../../../components/Core';
import {
  DocsIcon,
  FieldManagementIcon,
  PlusIcon,
  SignatureIcon,
  TrashIcon
} from '../../../components/Icons';
import HelpLink from '../../../components/FormFieldEditors/DefaultEditor/HelpLink';
import { Col, Row } from 'react-bootstrap';
import { FieldSelectorWithModal } from '../../../components/Modals';
import classNames from 'classnames';
import styles from '../../../components/NavigationRules/styles.module.scss';
import { isNum } from '../../../utils/validate';
import { Fragment } from 'react';

const HELP_LINKS = [
  {
    title: 'Autofill Document Templates',
    url: 'https://docs.feathery.io/platform/signatures-and-document-autofill/autofill-pdf-templates',
    icon: <FieldManagementIcon />
  },
  {
    title: 'Sign Documents',
    url: 'https://docs.feathery.io/platform/signatures-and-document-autofill/document-signature-workflows',
    icon: <SignatureIcon color='white' />
  },
  {
    title: 'Submission PDF Exports',
    url: 'https://docs.feathery.io/platform/signatures-and-document-autofill/export-form-submission-pdf',
    icon: <DocsIcon color='white' />
  }
];

type Props = {
  doc: any;
  hidePages: any;
  setHidePages: any;
};

export const defaultHidePage = () => ({
  id: uuidv4(),
  field_type: '',
  servar: null,
  hidden_field: null,
  value: '',
  pages: []
});

const DefaultPanel = ({ doc, hidePages, setHidePages }: Props) => {
  const updateHidePages = (newData: Record<string, any>, index: number) => {
    const newHidePages = [...hidePages];
    newHidePages[index] = {
      ...newHidePages[index],
      ...newData
    };
    setHidePages(newHidePages);
  };

  const addHidePage = () => setHidePages([...hidePages, defaultHidePage()]);

  const deleteHidePage = (index: number) => {
    const newHidePages = [...hidePages];
    newHidePages.splice(index, 1);
    if (newHidePages.length === 0) newHidePages.push(defaultHidePage());
    setHidePages(newHidePages);
  };

  return (
    <>
      <SectionHeader title={doc.key} />
      <CollapsibleSection title='Hide Pages Conditionally' collapsible>
        {hidePages.map((hidePage: any, index: number) => (
          <Fragment key={index}>
            <Row style={{ marginBottom: '10px' }}>
              <Col style={{ display: 'flex', alignItems: 'center' }}>
                If
                <FieldSelectorWithModal
                  selectId={hidePage.servar || hidePage.hidden_field}
                  selectType={hidePage.field_type}
                  onSelect={(data: any) => {
                    const type = data.selectType;
                    const servar = type === 'servar' ? data.selectId : '';
                    const hidden_field = type === 'hidden' ? data.selectId : '';
                    updateHidePages(
                      {
                        servar,
                        hidden_field,
                        field_type: data.selectType
                      },
                      index
                    );
                  }}
                  excludeServarTypes={['file_upload', 'signature'] as any}
                  style={{ minWidth: '100px', marginLeft: '10px' }}
                />
                =
                <TextField
                  placeholder='Value'
                  value={hidePage.value}
                  onComplete={(val: any) => {
                    updateHidePages({ value: val }, index);
                  }}
                  triggerCleanUp
                  style={{ marginLeft: '5px' }}
                />
                <TrashIcon
                  style={{ cursor: 'pointer', marginLeft: '10px' }}
                  onClick={() => deleteHidePage(index)}
                />
              </Col>
            </Row>
            <Row
              style={{
                paddingBottom: '15px',
                marginBottom: '15px',
                borderBottom: '1px solid var(--grey-40)'
              }}
            >
              <Col style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '110px' }}>Hide pages</div>
                <TextField
                  placeholder='1, 3, 4'
                  value={hidePage.pages
                    .map((page: number) => page + 1)
                    .join(', ')}
                  onComplete={(newPages: string) => {
                    updateHidePages(
                      {
                        pages: newPages
                          .split(',')
                          .map((newPage) => newPage.trim())
                          .filter((newPage) => isNum(newPage))
                          .map((newPage) => parseInt(newPage) - 1)
                      },
                      index
                    );
                  }}
                  triggerCleanUp
                  style={{ marginLeft: '5px' }}
                />
              </Col>
            </Row>
          </Fragment>
        ))}
        <div
          className={classNames(styles.addNavigationRule, styles.ruleTarget)}
          onClick={addHidePage}
        >
          <PlusIcon width={15} height={15} className={styles.addNewRuleIcon} />
          Rule
        </div>
      </CollapsibleSection>
      <CollapsibleSection title='Help' collapsible>
        {HELP_LINKS.map(({ title, url, icon }, index) => (
          <HelpLink key={url + index} title={title} url={url} icon={icon} />
        ))}
      </CollapsibleSection>
    </>
  );
};

export default DefaultPanel;
