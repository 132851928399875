export default function BlockViewIcon(props: any) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='#414859'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M8.8664 4H5.1336C4.50854 4 4 4.50854 4 5.1336V8.8664C4 9.49146 4.50854 10 5.1336 10H8.8664C9.49146 10 10 9.49146 10 8.8664V5.1336C10 4.50854 9.49146 4 8.8664 4Z' />
      <path d='M18.8664 4H15.1336C14.5085 4 14 4.50854 14 5.1336V8.8664C14 9.49146 14.5085 10 15.1336 10H18.8664C19.4915 10 20 9.49146 20 8.8664V5.1336C20 4.50854 19.4915 4 18.8664 4Z' />
      <path d='M8.8664 14H5.1336C4.50854 14 4 14.5085 4 15.1336V18.8664C4 19.4915 4.50854 20 5.1336 20H8.8664C9.49146 20 10 19.4915 10 18.8664V15.1336C10 14.5085 9.49146 14 8.8664 14Z' />
      <path d='M18.8664 14H15.1336C14.5085 14 14 14.5085 14 15.1336V18.8664C14 19.4915 14.5085 20 15.1336 20H18.8664C19.4915 20 20 19.4915 20 18.8664V15.1336C20 14.5085 19.4915 14 18.8664 14Z' />
    </svg>
  );
}
