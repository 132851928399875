import { getOS } from '../../../../utils/browserUtils';

/**
 * Key listener constants for the code editor
 */
const CONTROL_KEY = getOS() === 'MacOS' ? 'Meta' : 'Control';

export const KEYS = [[CONTROL_KEY, 'KeyS']] as const;

export const FORMAT_SHORTCUT = `${CONTROL_KEY}+S`;
export const FORMAT_SHORTCUT_DISPLAY = getOS() === 'MacOS' ? 'Cmd+S' : 'Ctrl+S';

export const stripBeginAndEndQuotes = (str: string) => {
  if (
    (str[0] === '"' && str[str.length - 1] === '"') ||
    (str[0] === "'" && str[str.length - 1] === "'")
  ) {
    return str.slice(1, str.length - 1);
  }
  return str;
};

export function isValidJsIdentifier(str: string) {
  // Regular expression to match (approximately) all valid Unicode identifiers
  // The most complete regex is here: https://stackoverflow.com/a/2008444 but seems
  // impractical to use in this case
  const identifierRegex = /^[_$a-zA-Z\xA0-\uFFFF][_$a-zA-Z0-9\xA0-\uFFFF]*$/u;

  // Check if the string matches the regex and is not a reserved word
  return identifierRegex.test(str) && !isJsReservedWord(str);
}

// Helper function to check if a string is a Javascript reserved word
function isJsReservedWord(str: string) {
  const reservedWords = [
    'abstract',
    'await',
    'boolean',
    'break',
    'byte',
    'case',
    'catch',
    'char',
    'class',
    'const',
    'continue',
    'debugger',
    'default',
    'delete',
    'do',
    'double',
    'else',
    'enum',
    'export',
    'extends',
    'false',
    'final',
    'finally',
    'float',
    'for',
    'function',
    'goto',
    'if',
    'implements',
    'import',
    'in',
    'instanceof',
    'int',
    'interface',
    'let',
    'long',
    'native',
    'new',
    'null',
    'package',
    'private',
    'protected',
    'public',
    'return',
    'short',
    'static',
    'super',
    'switch',
    'synchronized',
    'this',
    'throw',
    'throws',
    'transient',
    'true',
    'try',
    'typeof',
    'var',
    'void',
    'volatile',
    'while',
    'with',
    'yield'
  ];
  return reservedWords.includes(str);
}
