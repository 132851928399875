import { CheckboxField, DynamicTextTooltip, SectionHeader } from '../../Core';
import { useState } from 'react';
import { UNDO_TITLES, UNDO_TYPES } from '../../../utils/constants';

import FormFieldEditor from '../FormFieldEditor';
import { produce } from 'immer';
import styles from './styles.module.scss';
import useFeatheryRedux from '../../../redux';
import { clearViewport } from '../../RenderingEngine/GridInGrid/gig';
import { hasViewportProperties } from '../../RenderingEngine/utils/gig';
import { DeleteStepModal } from '../../Modals';
import { Viewport } from '../../RenderingEngine/GridInGrid/engine';
import useViewport from '../../../hooks/useViewport';
import { useAppSelector } from '../../../hooks';
import { Button } from '../../Core/Button/button';
import HelpSection from './HelpSection';
import useActiveLogic from './LogicSection/useActiveLogic';
import LogicSection from './LogicSection';

const LABEL_MAX_LENGTH = 25;

function DefaultEditor() {
  const workingSteps = useAppSelector(
    (state) => state.formBuilder.workingSteps
  );
  const activeStepId = useAppSelector(
    (state) => state.formBuilder.activeStepId
  );

  const { viewport } = useViewport();
  const activeStep = workingSteps[activeStepId];

  const {
    formBuilder: { setPanelDataWithUndoRedo }
  } = useFeatheryRedux();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const activeLogic = useActiveLogic();

  const hasMobileProps = () => {
    const step = JSON.parse(JSON.stringify(workingSteps[activeStepId]));
    return hasViewportProperties(step, Viewport.Mobile);
  };

  const clearMobileProps = () => {
    const nextWorkingSteps = JSON.parse(JSON.stringify(workingSteps));
    const newStep = JSON.parse(JSON.stringify(workingSteps[activeStepId]));

    clearViewport(newStep, Viewport.Mobile);

    nextWorkingSteps[activeStepId] = newStep;

    setPanelDataWithUndoRedo({
      oldValue: workingSteps[activeStepId],
      newValue: nextWorkingSteps[activeStepId],
      title: UNDO_TITLES.FORMAT,
      type: UNDO_TYPES.STEP,
      id: activeStepId,
      workingSteps: nextWorkingSteps
    });
  };

  let label = activeStep.key;
  if (label.length > LABEL_MAX_LENGTH) {
    label = label.slice(0, LABEL_MAX_LENGTH) + '...';
  }

  return (
    <FormFieldEditor
      mode='builder'
      onDelete={() => setShowDeleteModal(true)}
      deleteLabel='Delete Step'
    >
      <DeleteStepModal
        show={showDeleteModal}
        setShow={(show: boolean) => setShowDeleteModal(show)}
      />
      <DynamicTextTooltip
        text={activeStep.key}
        maxLength={LABEL_MAX_LENGTH}
        placement='bottom'
      >
        <div>
          <SectionHeader
            title={
              <>
                {label}
                <CheckboxField
                  className={styles.startingStepControl}
                  checked={activeStep.origin}
                  disabled={activeStep.origin}
                  disabledMessage='To change the first step, toggle the new step directly'
                  text='First step of form'
                  onChange={(isChecked) => {
                    const oldValue: any = {};
                    const newValue: any = {};
                    const newWorkingSteps = produce(
                      workingSteps,
                      (draft: any) => {
                        Object.values(draft).forEach((step: any) => {
                          const oldOrigin = step.origin;
                          if (step.id === activeStep.id)
                            step.origin = isChecked;
                          else if (isChecked) step.origin = false;
                          if (oldOrigin !== step.origin) {
                            oldValue[step.id] = {
                              origin: oldOrigin
                            };
                            newValue[step.id] = {
                              origin: step.origin
                            };
                          }
                        });
                      }
                    );
                    setPanelDataWithUndoRedo({
                      id: activeStep.id,
                      oldValue,
                      newValue,
                      title: UNDO_TITLES.FIRST_CHECK,
                      type: UNDO_TYPES.SOME_STEPS,
                      workingSteps: newWorkingSteps
                    });
                  }}
                />
              </>
            }
          />
        </div>
      </DynamicTextTooltip>
      {viewport === Viewport.Mobile && (
        <>
          <hr />
          <div className={styles.mobileLayoutSection}>
            <div className={styles.title}>Mobile Dimensions and Styles</div>
            <Button
              variant='gray-primary'
              className={styles.button}
              onClick={clearMobileProps}
              disabled={!hasMobileProps()}
            >
              Reset all to desktop
            </Button>
          </div>
        </>
      )}
      {viewport !== Viewport.Mobile && !activeLogic.length && <HelpSection />}
      <LogicSection />
    </FormFieldEditor>
  );
}

export default DefaultEditor;
