export default function LaptopIcon({ width = 32, height = 32 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.16406 9C5.16406 7.61929 6.28335 6.5 7.66406 6.5H24.1256C25.5063 6.5 26.6256 7.61929 26.6256 9V19.3333C26.6256 20.714 25.5063 21.8333 24.1256 21.8333H7.66406C6.28335 21.8333 5.16406 20.714 5.16406 19.3333V9ZM8.16406 9.5V18.8333H23.6256V9.5H8.16406Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.5 24C2.5 23.1716 3.17157 22.5 4 22.5H28C28.8284 22.5 29.5 23.1716 29.5 24C29.5 24.8284 28.8284 25.5 28 25.5H4C3.17157 25.5 2.5 24.8284 2.5 24Z'
        fill='white'
      />
    </svg>
  );
}
