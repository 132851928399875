import styles from '../pages/FormResultsDetailPage/styles.module.scss';
import { ACTION_OPTIONS } from '../components/Panels/utils';

export const delay = async (seconds: any) => {
  return new Promise((resolve) =>
    setTimeout(() => {
      // @ts-expect-error TS(2794) FIXME: Expected 1 arguments, but got 0. Did you forget to... Remove this comment to see the full error message
      resolve();
    }, seconds)
  );
};

export function repeatConfig(step: any) {
  const repeatContainers = step.subgrids.filter((grid: any) => grid.repeated);
  const repeatContainerIds = repeatContainers.map((grid: any) => grid.id);

  const isInRepeatedCell = (element: any) => {
    if (!repeatContainers.length) return false;
    return repeatContainers.some((repeatCell: any) => {
      const elementPosition = element.position.join(',') + ',';
      const repeatPosition = repeatCell.position.join(',') + ',';
      return elementPosition.startsWith(repeatPosition);
    });
  };

  for (const field of step.servar_fields) {
    const inRepeatedCell = isInRepeatedCell(field);
    if (!inRepeatedCell && field.servar.repeated) {
      return `The field "${field.servar.key}" must be in a repeatable container since it's set as repeatable`;
    } else if (inRepeatedCell && !field.servar.repeated) {
      return `The field "${field.servar.key}" must be set as repeatable since it's in a repeatable container`;
    }
  }

  type ElementInfo = {
    element: any;
    elementType: string;
    text: string;
  };
  const checkStepElementRepeatAction = ({
    element,
    elementType,
    text
  }: ElementInfo) => {
    const addRepeatedRowActions =
      element.properties?.actions?.filter(
        (action: any) => action.type === ACTION_OPTIONS.ADD_REPEATED_ROW
      ) ?? [];
    // Ensure that all repeat_conainers in the add repeat container actions are
    // set and actually repeated.  It is possible to set the container but later set it non-repeating.
    if (addRepeatedRowActions.some((action: any) => !action.repeat_container)) {
      return `An "add repeating container" action on ${elementType} "${text}" has no repeating container selected`;
    }
    if (
      addRepeatedRowActions.some(
        (action: any) => !repeatContainerIds.includes(action.repeat_container)
      )
    ) {
      return `An "add repeating container" action on ${elementType} "${text}" is incorrectly configured with a non-repeating container`;
    }
    return null;
  };

  const repeatableActionElements = [
    ...(step.buttons.map((button: any) => ({
      element: button,
      elementType: 'button',
      text: button.properties?.text
    })) as ElementInfo[]),
    ...(step.texts.map((text: any) => ({
      element: text,
      elementType: 'text',
      text: text.properties?.text
    })) as ElementInfo[]),
    ...(step.subgrids.map((subgrid: any) => ({
      element: subgrid,
      elementType: 'container',
      text: subgrid.key
    })) as ElementInfo[])
  ];
  for (const elementInfo of repeatableActionElements) {
    const error = checkStepElementRepeatAction(elementInfo);
    if (error) return error;
  }
}

export const escapeSpecialChars = (string: any) => {
  return string.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
};

export const openTab = (url: any) => window.open(url, '_blank', 'noopener');

export const LinkText = ({ text, link }: any) => {
  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      href={link}
      target='_blank'
      rel='noopener'
      onClick={(e) => e.stopPropagation()}
      className={styles.assetLink}
    >
      <div className={styles.assetText}>{text}</div>
    </a>
  );
};
