import { memo, useEffect, useRef, useState } from 'react';

import { SearchIcon } from '../../Icons';
import { escapeSpecialChars } from '../../../utils/domOperations';
import classNames from 'classnames';
import styles from './styles.module.scss';

function SearchBar({ elementEntries, onSearch, padding = true }: any) {
  const [info, setInfoMessage] = useState({});
  const [search, setSearch] = useState('');
  const [searchCount, setSearchCount] = useState(0);
  const searchInput = useRef<any>({});

  const handleSearch = (e: any) => {
    const searchString = escapeSpecialChars(e.target.value);
    setSearch(searchString);
    const results = Object.entries(elementEntries)
      .filter(
        ([, entry]: any[]) =>
          entry.label.toLowerCase().search(searchString.toLowerCase()) > -1
      )
      .map(([key]: any[]) => key);
    onSearch(results);
    setSearchCount(results.length);
  };

  useEffect(() => {
    if (searchCount > 0) {
      setInfoMessage({
        type: 'normal',
        message: `${searchCount} element(s) found for '${search}'`
      });
    } else {
      setInfoMessage({
        type: 'notice',
        message: 'No elements found'
      });
    }
  }, [search, searchCount]);

  const clearSearch = () => {
    searchInput.current.value = '';
    searchInput.current.focus();
    setSearch('');
  };

  return (
    <div className={classNames(styles.searchWrap, padding && 'padded')}>
      <div className='search-field-sources'>
        <input
          type='text'
          name='search'
          placeholder='Search'
          className='form-control'
          ref={searchInput}
          onChange={handleSearch}
        />
        {search?.length > 0 && (
          <span className='clear-input' onClick={clearSearch}>
            &times;
          </span>
        )}
        <div className='search-icon'>
          <SearchIcon />
        </div>
      </div>
      {search && info && (
        <div className={`info-message ${(info as any).type}`}>
          {(info as any).message}
        </div>
      )}
    </div>
  );
}

export default memo(SearchBar);
