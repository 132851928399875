function isNum(candidate: any) {
  return !isNaN(parseInt(candidate));
}

const isValidUrl = (
  urlString: string,
  validProtocols = ['http:', 'https:']
) => {
  let url;
  try {
    url = new URL(urlString);
  } catch (e) {
    return false;
  }
  return validProtocols.includes(url.protocol);
};

const emailPatternStr =
  "^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]{2,63})+$";
const emailPattern = new RegExp(emailPatternStr);

const isValidEmail = (email: string) => {
  if (!email) return false;
  const parts = email.split('@');
  if (parts.length !== 2) return false;
  // Email handle cannot end with '.'
  if (parts[0].endsWith('.')) return false;

  return emailPattern.test(email);
};

export { isNum, isValidUrl, isValidEmail };
