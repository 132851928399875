import '../../style/dialog-form.css';

import { Form } from 'react-bootstrap';
import { useState } from 'react';

import { ALL_INTEGRATIONS_MAP, INTEGRATIONS } from './types';
import IntegrationsSidebar from './IntegrationsSidebar';
import { CollapsibleSection, PropertyLabel, TextField } from '../Core';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { FieldSelectorWithModal } from '../Modals';
import useIntegrations from './useIntegrations';
import { useParams } from 'react-router-dom';

const DEFAULT_SETTINGS = {
  fields: [],
  db_table: '',
  db_host: '',
  db_port: '5432',
  db_name: '',
  db_username: '',
  db_password: ''
};

function PostgresSettingsSidebar() {
  const { formId } = useParams<{ formId: string }>();

  // Global/cached integration settings
  const integration = useIntegrations({
    type: INTEGRATIONS.POSTGRES,
    panelId: formId,
    includeInactive: true
  });

  // Local/draft integration settings
  const [isPartial, setIsPartial] = useState(false);
  const [postgresConfig, setPostgresConfig] = useState(
    integration?.data.secret_metadata ?? DEFAULT_SETTINGS
  );

  function onSubmitCustom(newIsActive: boolean) {
    if (newIsActive) {
      // eslint-disable-next-line camelcase
      const { db_host, db_port, db_name, db_table } = postgresConfig;
      // eslint-disable-next-line camelcase
      const partial = !db_host || !db_port || !db_name || !db_table;
      setIsPartial(partial);
      if (partial) return;
    }

    // Creating / updating the Postgres config will result in requests to the
    // client DB, and those requests must originate from the whitelisted IP
    // address
    return {
      isUpdate: integration?.data,
      secretMetadata: postgresConfig
    };
  }

  return (
    <IntegrationsSidebar
      integrationInfo={ALL_INTEGRATIONS_MAP[INTEGRATIONS.POSTGRES]}
      isPartial={isPartial}
      onSubmitCustom={onSubmitCustom}
    >
      <Form>
        <Form.Group controlId='api-key'>
          <PropertyLabel label='Host*' />
          <TextField
            className={classNames('dialog-form-input', styles.marginBottom)}
            placeholder=''
            value={postgresConfig.db_host}
            onChange={(value: any) =>
              setPostgresConfig((postgresConfig: any) => ({
                ...postgresConfig,
                db_host: value
              }))
            }
            error={isPartial}
          />
          <PropertyLabel label='Port*' />
          <TextField
            className={classNames('dialog-form-input', styles.marginBottom)}
            placeholder=''
            value={postgresConfig.db_port}
            onChange={(value: any) =>
              setPostgresConfig((postgresConfig: any) => ({
                ...postgresConfig,
                db_port: value
              }))
            }
            error={isPartial}
          />
          <PropertyLabel label='Database Name*' />
          <TextField
            className={classNames('dialog-form-input', styles.marginBottom)}
            placeholder=''
            value={postgresConfig.db_name}
            onChange={(value: any) =>
              setPostgresConfig((postgresConfig: any) => ({
                ...postgresConfig,
                db_name: value
              }))
            }
            error={isPartial}
          />
          <PropertyLabel label='Table Name*' />
          <TextField
            className={classNames('dialog-form-input', styles.marginBottom)}
            placeholder=''
            value={postgresConfig.db_table}
            onChange={(value: any) =>
              setPostgresConfig((postgresConfig: any) => ({
                ...postgresConfig,
                db_table: value
              }))
            }
            error={isPartial}
          />
          <Form.Text className='text-muted'>* Required</Form.Text>
          <CollapsibleSection
            title='Authentication (Optional)'
            collapsible={false}
          >
            <PropertyLabel label='Username' />
            <TextField
              className={classNames('dialog-form-input', styles.marginBottom)}
              placeholder=''
              value={postgresConfig.db_username}
              onChange={(value: any) =>
                setPostgresConfig((postgresConfig: any) => ({
                  ...postgresConfig,
                  db_username: value
                }))
              }
            />
            <PropertyLabel label='Password' />
            <TextField
              className={classNames('dialog-form-input', styles.marginBottom)}
              placeholder=''
              value={postgresConfig.db_password}
              onChange={(value: any) =>
                setPostgresConfig((postgresConfig: any) => ({
                  ...postgresConfig,
                  db_password: value
                }))
              }
            />
          </CollapsibleSection>
          <CollapsibleSection title='Fields to Sync' collapsible={false}>
            {postgresConfig.fields.map(({ id, type }: any, index: any) => (
              <FieldSelectorWithModal
                key={id}
                selectId={id}
                selectType={type}
                placeholder='Select'
                onSelect={({ selectId, selectType }) => {
                  const curFields = [...postgresConfig.fields];
                  if (selectId)
                    curFields[index] = { id: selectId, type: selectType };
                  else curFields.splice(index, 1);
                  setPostgresConfig({
                    ...postgresConfig,
                    fields: curFields
                  });
                }}
                className={styles.marginBottom}
              />
            ))}
            <FieldSelectorWithModal
              key={postgresConfig.fields.length}
              selectId=''
              placeholder='Select'
              onSelect={({ selectId, selectType }) => {
                if (selectId)
                  setPostgresConfig({
                    ...postgresConfig,
                    fields: [
                      ...postgresConfig.fields,
                      { id: selectId, type: selectType }
                    ]
                  });
              }}
              className={styles.marginBottom}
            />
          </CollapsibleSection>
        </Form.Group>
      </Form>
    </IntegrationsSidebar>
  );
}

export default PostgresSettingsSidebar;
