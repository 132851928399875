/**
 * The codebase currently uses an inconsistent mix of properties for margin and padding. It can be confusing when
 * they're scattered around. This file is an attempt to consolidate the logic for margin and padding with readable
 * names. Long term, we should refactor the codebase to use a consistent set of properties for margin and padding.
 */

import { TYPE_BUTTON, TYPE_CONTAINER, TYPE_TEXT } from './elements';
import {
  CELL_MARGIN_PROPS,
  MARGIN_PROPS,
  PADDING_PROPS,
  UPLOADER_PADDING_PROPS
} from './themes';

export type MarginPaddingConfig = {
  usesPaddingForMargin: boolean;
  usesMargin: boolean;
  usesPadding: boolean;
  usesUploaderPaddingForPadding: boolean;
  usesExternalPadding: boolean;
  hasGroupPadding: boolean;
  hasGroupMargin: boolean;
  hasUploaderPadding?: boolean;
  labelOverride?: string;
};

const defaultMarginPaddingConfig: MarginPaddingConfig = {
  usesPaddingForMargin: true,
  usesMargin: true,
  usesPadding: false,
  usesUploaderPaddingForPadding: false,
  usesExternalPadding: false,
  hasGroupPadding: false,
  hasGroupMargin: false,
  hasUploaderPadding: false
};

const containerMarginPaddingConfig: MarginPaddingConfig = {
  usesPaddingForMargin: false,
  usesMargin: true,
  usesPadding: true,
  usesUploaderPaddingForPadding: false,
  usesExternalPadding: true,
  hasGroupPadding: false,
  hasGroupMargin: false,
  hasUploaderPadding: false
};

const textElementConfig: MarginPaddingConfig = {
  usesPaddingForMargin: true,
  usesMargin: false,
  usesPadding: true,
  usesUploaderPaddingForPadding: false,
  usesExternalPadding: false,
  hasGroupPadding: false,
  hasGroupMargin: false,
  hasUploaderPadding: false,
  labelOverride: 'Margin'
};

const buttonElementConfig: MarginPaddingConfig = {
  usesPaddingForMargin: true,
  usesMargin: true,
  usesPadding: true,
  usesUploaderPaddingForPadding: true,
  usesExternalPadding: false,
  hasGroupPadding: false,
  hasGroupMargin: false,
  hasUploaderPadding: false
};

export const getMarginThemeProps = (config: MarginPaddingConfig) => {
  if (config.usesExternalPadding) return CELL_MARGIN_PROPS;
  if (config.usesPaddingForMargin) return PADDING_PROPS;

  return MARGIN_PROPS;
};

export const getPaddingThemeProps = (config: MarginPaddingConfig) => {
  if (config.usesUploaderPaddingForPadding) return UPLOADER_PADDING_PROPS;

  return PADDING_PROPS;
};

export const getMarginName = (config: MarginPaddingConfig) => {
  if (config.usesExternalPadding) return `external_padding`;
  if (config.usesPaddingForMargin) return `padding`;

  return `margin`;
};

export const getPaddingName = (config: MarginPaddingConfig) => {
  if (config.usesUploaderPaddingForPadding) return `uploader_padding`;

  return `padding`;
};

export const getMarginPaddingConfig = (elementType: string) => {
  switch (elementType) {
    case TYPE_CONTAINER:
      return containerMarginPaddingConfig;
    case TYPE_BUTTON:
      return buttonElementConfig;
    case TYPE_TEXT:
      return textElementConfig;
    default:
      return defaultMarginPaddingConfig;
  }
};
