import { useEffect, useState } from 'react';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'reac...
import DatePicker from 'react-datepicker';

import { FilterWidget } from '.';

import styles from './styles.module.scss';

import { DropdownField, DropdownMultiField } from '../Core';

// Simple filter widgets that simply have a multi-select dropdown
export const createSimpleFilterWidget: (
  id: string,
  label: string,
  fixedParamName: string, // For these simple filters, the paramName is fixed
  selectOptions: { value: string; label: string }[],
  creatable?: boolean
) => FilterWidget = (id, label, fixedParamName, selectOptions, creatable) => {
  // eslint-disable-next-line react/display-name
  return ({ paramValue, onChange }) => {
    const selectedItems = paramValue ? paramValue.split(',') : [];
    const allSelectOptions = [
      ...selectOptions,
      // Add any selected items that are not in the options
      ...selectedItems
        .filter(
          (item) => !selectOptions.find((option) => option.value === item)
        )
        .map((item) => ({ value: item, label: item }))
    ];
    return (
      <>
        <DropdownMultiField
          selected={selectedItems}
          options={allSelectOptions}
          onChange={(items: { label: string; value: string }[]) => {
            const paramVal = items.map((item) => item.value).join(',');
            const paramValDisplay = items.map((item) => item.label).join(',');
            onChange(
              id,
              fixedParamName,
              paramVal,
              `${label}: ${paramValDisplay}`,
              !paramVal ? 'A value is required' : ''
            );
          }}
          creatable={creatable}
          formatCreateLabel={(inputValue: string) => `Add "${inputValue}"`}
        />
      </>
    );
  };
};

const DATE_TIME_OPERATOR_MAP: Record<string, string> = {
  eq: '=',
  ne: '!=',
  gt: '>',
  gte: '>=',
  lt: '<',
  lte: '<='
};
// Date comparison filter widgets that compare a field against a date time
export const createDateTimeFilterWidget: (
  id: string,
  label: string,
  baseParamName: string // Example param: invited_at__eq
) => FilterWidget = (id, label, baseParamName) => {
  // eslint-disable-next-line react/display-name
  return ({ paramName, paramValue, onChange }) => {
    const [filterOperator, setFilterOperator] = useState<string>('');
    const [filterValue, setFilterValue] = useState<any>('');
    useEffect(() => {
      if (!filterOperator && paramName) {
        const parts = paramName.split('__');
        if (parts.length === 2) {
          setFilterOperator(parts[1]);
        }
      }
      if (!filterValue && paramValue) setFilterValue(new Date(paramValue));
    }, [paramName, paramValue]);

    const handleChange = (operator: string, value: any) => {
      const paramValue = value ? new Date(value).toISOString() : '';
      let error = '';
      if (!operator) error = 'A comparison selection is required';
      else if (!value) error = 'A date is required';
      onChange(
        id,
        baseParamName + '__' + operator,
        paramValue,
        `${label}: ${DATE_TIME_OPERATOR_MAP[operator] ?? ''} ${paramValue}`,
        error
      );
    };

    return (
      <div className={styles.filterExpression}>
        <DropdownField
          onChange={(event: any) => {
            setFilterOperator(event.target.value);
            handleChange(event.target.value, filterValue);
          }}
          selected={filterOperator}
          options={[
            { value: '', display: '' },
            { value: 'eq', display: 'equals' },
            { value: 'ne', display: "doesn't equal" },
            { value: 'gt', display: 'is after' },
            { value: 'gte', display: 'is on or after' },
            { value: 'lt', display: 'is before' },
            { value: 'lte', display: 'is on or before' }
          ]}
        />
        <DatePicker
          selected={filterValue}
          showTimeSelect
          onSelect={(val: any) => {
            setFilterValue(val ?? '');
            handleChange(filterOperator, val);
          }}
          onChange={(val: any) => {
            setFilterValue(val ?? '');
            handleChange(filterOperator, val);
          }}
          className={styles.dateInput}
          popperClassName={styles.datePicker}
          maxDate={new Date()}
          isClearable
          dateFormat='MMM d, yyyy H:mm'
        />
      </div>
    );
  };
};
