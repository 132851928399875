const FormBehaviorIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.6667 4H5.33333C4.97971 4 4.64057 4.14048 4.39052 4.39052C4.14048 4.64057 4 4.97971 4 5.33333V18.6667C4 19.0203 4.14048 19.3594 4.39052 19.6095C4.64057 19.8595 4.97971 20 5.33333 20H18.6667C19.0203 20 19.3594 19.8595 19.6095 19.6095C19.8595 19.3594 20 19.0203 20 18.6667V5.33333C20 4.97971 19.8595 4.64057 19.6095 4.39052C19.3594 4.14048 19.0203 4 18.6667 4Z'
        stroke='#6C7589'
        strokeWidth='1.5'
        strokeLinejoin='round'
      />
      <path
        d='M12.027 12.1444L7.3211 15.9548C7.19365 16.059 7.00002 15.9714 7.00002 15.8105V14.3265C7.00002 14.2721 7.02698 14.2176 7.0711 14.1821L9.76227 12L7.0711 9.81785C7.04857 9.80067 7.03044 9.77871 7.01811 9.75368C7.00579 9.72864 6.9996 9.7012 7.00002 9.67348V8.18953C7.00002 8.02859 7.19365 7.94102 7.3211 8.04516L12.027 11.8533C12.1226 11.929 12.1226 12.0686 12.027 12.1444ZM17 15.8105C17 15.9146 16.9167 15.9998 16.8162 15.9998H12.2819C12.1814 15.9998 12.098 15.9146 12.098 15.8105V14.6744C12.098 14.5703 12.1814 14.4851 12.2819 14.4851H16.8162C16.9167 14.4851 17 14.5703 17 14.6744V15.8105Z'
        fill='#6C7589'
      />
    </svg>
  );
};

export default FormBehaviorIcon;
