import * as Icons from '../../Icons';

import { Col, Row } from 'react-bootstrap';
import {
  CollapsibleSection,
  NumberInput,
  PropertyLabel,
  RadioButtonGroup
} from '../../Core';
import {
  assetUpdateOperations,
  elementOperation,
  getAsset,
  level2UpdateOperations
} from '../../../utils/themes';
import { memo } from 'react';

import { calculateOverrideObjects } from '../utils';
import { objectPick } from '../../../utils/core';
import { TYPE_VIDEO } from '../../../utils/elements';
import { Viewport } from '../../RenderingEngine/GridInGrid/engine';
import { AXIS } from '../../../utils/constants';
import BoxSpacingInput from '../../Core/BoxSpacingInput';

function VideoStylePanel({
  mode,
  viewport,
  theme,
  baseProps,
  baseStyle,
  overrideProps,
  overrideStyle,
  handleUpdates,
  cellNode
}: any) {
  const { result: element } = calculateOverrideObjects(
    baseProps,
    overrideProps,
    true
  );
  const { result: elementStyle, isOverride: isOverrideStyle } =
    calculateOverrideObjects(baseStyle, overrideStyle);
  const asset = getAsset(theme, TYPE_VIDEO, element.source_asset);

  function handleStyleChange(styleUpdate: any) {
    handleUpdates([elementOperation({ viewport, styleUpdate })]);
  }

  function labelData(...props: string[]) {
    const newStyle = objectPick(elementStyle, props);
    const key = viewport === Viewport.Mobile ? 'Desktop' : 'Theme';
    return {
      mode,
      highlighted: isOverrideStyle(props),
      themeOperations: {
        instance: {
          label: `Reset to ${key}`,
          operation: () => {
            handleUpdates([elementOperation({ viewport, styleReset: props })]);
          }
        },
        ...(mode === 'builder' && {
          level2: {
            label: 'Videos',
            operation: () => {
              handleUpdates(
                level2UpdateOperations({
                  viewport,
                  newStyle,
                  asset,
                  elementType: TYPE_VIDEO,
                  level2: 'video'
                })
              );
            }
          }
        }),
        ...(asset && {
          asset: {
            label: asset.key,
            operation: () => {
              handleUpdates(
                assetUpdateOperations({
                  viewport,
                  elementType: TYPE_VIDEO,
                  asset,
                  newStyle
                })
              );
            }
          }
        })
      }
    };
  }

  return (
    <>
      <CollapsibleSection title='Layout' collapsible>
        <Row>
          <Col sm='3'>
            <PropertyLabel
              label='Width'
              {...labelData('width', 'width_unit')}
            />
          </Col>
          <Col>
            <NumberInput
              required
              min={0}
              value={elementStyle.width}
              unit={elementStyle.width_unit}
              units={['px', '%']}
              onComplete={({ value, unit }: any) =>
                handleStyleChange({ width: value, width_unit: unit })
              }
              triggerCleanUp
              dimension='width'
            />
          </Col>
          <Col sm='3'>
            <PropertyLabel
              label='Height'
              indented
              {...labelData('height', 'height_unit')}
            />
          </Col>
          <Col>
            <NumberInput
              required
              min={0}
              value={elementStyle.height}
              unit={elementStyle.height_unit}
              units={['px', '%']}
              onComplete={({ value, unit }: any) =>
                handleStyleChange({ height: value, height_unit: unit })
              }
              triggerCleanUp
              dimension='height'
            />
          </Col>
        </Row>
        <BoxSpacingInput
          elementStyle={elementStyle}
          handleStyleChange={handleStyleChange}
          labelData={labelData}
          elementType={TYPE_VIDEO}
        />
        {cellNode && (
          <Row>
            <Col sm='3'>
              <PropertyLabel
                label='Align Self'
                {...labelData('layout', 'vertical_layout')}
              />
            </Col>
            <Col>
              <RadioButtonGroup
                selected={elementStyle.layout}
                name='video-editor-horizontal-alignment'
                allowUnselect
                hideValueIfDisabled
                disabled={!cellNode.parent || cellNode.parent.axis === AXIS.COL}
                onChange={(value: any) => handleStyleChange({ layout: value })}
                options={[
                  {
                    value: 'flex-start',
                    display: <Icons.HorizontalLeftIcon />
                  },
                  { value: 'center', display: <Icons.HorizontalCenterIcon /> },
                  { value: 'flex-end', display: <Icons.HorizontalRightIcon /> }
                ]}
              />
            </Col>
            <Col>
              <RadioButtonGroup
                selected={elementStyle.vertical_layout}
                name='video-editor-vertical-alignment'
                allowUnselect
                hideValueIfDisabled
                disabled={!cellNode.parent || cellNode.parent.axis === AXIS.ROW}
                onChange={(value: any) =>
                  handleStyleChange({ vertical_layout: value })
                }
                options={[
                  { value: 'flex-start', display: <Icons.VerticalTopIcon /> },
                  { value: 'center', display: <Icons.VerticalCenterIcon /> },
                  { value: 'flex-end', display: <Icons.VerticalBottomIcon /> }
                ]}
              />
            </Col>
          </Row>
        )}
        {mode !== 'theme' && (
          <Row>
            <Col sm='3'>
              <PropertyLabel label='Visibility' />
            </Col>
            <Col>
              <RadioButtonGroup
                selected={elementStyle.visibility}
                name='video-editor-visibility'
                onChange={(value: any) =>
                  handleStyleChange({ visibility: value })
                }
                options={[
                  {
                    value: 'visible',
                    display: <Icons.ShowIcon width={18} height={18} />
                  },
                  {
                    value: 'hidden',
                    display: <Icons.HideIcon width={18} height={18} />
                  }
                ]}
              />
            </Col>
          </Row>
        )}
      </CollapsibleSection>
    </>
  );
}

export default memo(VideoStylePanel);
