import logoLoader from '../../Assets/logoLoader.json';
import Lottie from 'lottie-react';
import styles from './styles.module.scss';
import testIds from '../../../utils/testIds';

export default function LogoLoader() {
  return (
    <Lottie
      data-testid={testIds.logoLoader}
      className={styles.loader}
      animationData={logoLoader}
      loop
      autoplay
      renderer='svg'
    />
  );
}
