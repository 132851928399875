import { memo, useMemo, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { AIDisplayPDF } from '../../types';
import { usePDFState } from '../../context';
import { PDF_DISPLAY_WIDTH } from './constants';
import classNames from 'classnames';
import styles from '../styles.module.scss';

type Props = {
  selectedFile: AIDisplayPDF;
  setPageCount: (numPages: number) => void;
  onLoadSuccess?: () => void;
};

const Viewer = ({
  selectedFile,
  setPageCount,
  onLoadSuccess = () => {}
}: Props) => {
  const [numPages, setNumPages] = useState(0);
  const { zoom } = usePDFState();

  const docUrl = useMemo(() => {
    return selectedFile.url.split('?')[0];
  }, [selectedFile?.url]);

  const pageArray = useMemo(() => {
    return new Array(numPages).fill(0);
  }, [numPages]);

  const handleDocumentLoad = ({ numPages }: any) => {
    if (numPages) {
      setNumPages(numPages);
      setPageCount(numPages);
    }

    onLoadSuccess();
  };

  if (!selectedFile) {
    return (
      <div
        className={classNames(styles.infoCard, styles.white)}
        style={{ marginTop: '25px' }}
      >
        No file selected.
      </div>
    );
  }

  if (!selectedFile.url) {
    return (
      <div
        className={classNames(styles.infoCard, styles.white)}
        style={{ marginTop: '25px' }}
      >
        No file was uploaded for the selected document field.
      </div>
    );
  }

  return (
    <Document
      className={styles.pdfDocument}
      file={docUrl}
      onLoadSuccess={handleDocumentLoad}
    >
      {pageArray.map((_, index) => (
        <Page
          key={`page_${index + 1}`}
          className={styles.pdfPage}
          pageNumber={index + 1}
          width={PDF_DISPLAY_WIDTH}
          scale={zoom}
          renderTextLayer={false}
          renderAnnotationLayer={false}
        />
      ))}
    </Document>
  );
};

export default memo(Viewer);
