export default function CaseLowerIcon({ width = 20, height = 13 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.5 3.5L-1.74846e-07 3.5L0 5.5L9 5.5L9 3.5L5.5 3.5L5.5 -1.70857e-07L3.5 3.98811e-09L3.5 3.5Z'
        fill='#414859'
      />
      <path d='M3.5 13L3.5 5.5H5.5V13H3.5Z' fill='#414859' />
      <path
        d='M14.5 3.5L11 3.5L11 5.5L20 5.5L20 3.5L16.5 3.5L16.5 -1.70857e-07L14.5 3.98811e-09L14.5 3.5Z'
        fill='#414859'
      />
      <path d='M14.5 13L14.5 5.5H16.5V13H14.5Z' fill='#414859' />
    </svg>
  );
}
