import { useMemo } from 'react';
import { parseJSONStructure } from '../utils';
import { DropdownMultiField } from '../../../Core';
import styles from './styles.module.scss';

type JSONPathInputProps = {
  json: any;
  selected?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
};

export const JSONPathInput = ({
  json,
  selected,
  onChange = () => {},
  disabled = false
}: JSONPathInputProps) => {
  const jsonPaths = useMemo(() => {
    return parseJSONStructure(json);
  }, [json]);

  const options = useMemo(
    () =>
      Object.keys(jsonPaths).map((key) => ({
        value: key,
        label: key
      })),
    [jsonPaths]
  );

  return (
    <DropdownMultiField
      className={styles.jsonPathInput}
      isMulti={false}
      selected={selected || []}
      options={options}
      onChange={(val: any) => onChange(val.value)}
      disabled={disabled}
      classNames={{
        multiFieldValueContainer: styles.jsonPathInputValueContainer
      }}
    />
  );
};
