export default [
  'Bocas del Toro',
  'Chiriquí',
  'Coclé',
  'Colón',
  'Darién',
  'Emberá',
  'Herrera',
  'Kuna Yala',
  'Los Santos',
  'Ngöbe-Buglé',
  'Panamá',
  'Panamá Oeste',
  'Veraguas'
];
