import React, { useState } from 'react';
import styles from './styles.module.scss';
import {
  autoUpdate,
  size,
  offset,
  hide,
  useDismiss,
  useFloating,
  useInteractions,
  FloatingFocusManager,
  FloatingPortal,
  FloatingOverlay
} from '@floating-ui/react';
import classNames from 'classnames';

interface ExpandingTextareaProps
  extends Omit<React.ComponentPropsWithoutRef<'textarea'>, 'rows'> {
  value: string | number | readonly string[];
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

/**
 * A textarea that is 1 row tall. On focus it floats above the content and expands to fit
 * its value. Must be a controlled input.
 *
 * @component
 * @param {Object} props - The component accepts all props from a `textarea` except `rows`.
 * @param {string} value - The value of the textarea.
 * @param {function} onChange - Called when the value of the textarea changes.
 * @returns {JSX.Element} The rendered text area.
 *
 * @example
 * // Example usage of the controlled component
 * <ExpandingTextarea
 *    value={value}
 *    onChange={(event) => setValue(event.target.value)}
 * />
 */
const ExpandingTextarea = ({
  onChange,
  onFocus,
  onBlur,
  onKeyDown,
  className,
  ...props
}: ExpandingTextareaProps) => {
  const [open, setOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating<HTMLInputElement>({
    whileElementsMounted: autoUpdate,
    open,
    onOpenChange: (isOpen) => {
      // manually trigger the onBlur event when the floating textarea is closed
      if (!isOpen) {
        const textArea = refs.reference.current as HTMLTextAreaElement;
        onBlur?.({
          target: textArea
        } as any);
      }
      setOpen(isOpen);
    },
    middleware: [
      // Matches the original width and a max height of the available viewport
      size({
        apply({ rects, elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            maxHeight: `${availableHeight}px`
          });
        }
      }),
      // Align the floating textarea with its anchored one
      offset(({ rects }) => {
        return -rects.reference.height;
      }),
      // Hide the floating textarea when the anchored one is hidden
      hide()
    ]
  });

  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

  function openTextarea() {
    setOpen(true);
  }

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange?.(event);
    expandTextarea(event.target);
  };

  const handleFocus = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    onFocus?.(event);
    expandTextarea(event.target);
    moveCursorToEnd(event.target);
  };

  return (
    <>
      <textarea
        {...getReferenceProps({
          ref: refs.setReference,
          className: classNames(className, styles.textarea),
          rows: 1,
          spellCheck: 'false',
          onChange,
          onFocus: openTextarea,
          onBlur,
          ...props
        })}
      />
      {open && (
        <FloatingPortal>
          <FloatingFocusManager
            context={context}
            initialFocus={refs.floating}
            modal={false}
          >
            <FloatingOverlay className={styles.overlay} lockScroll>
              <textarea
                {...getFloatingProps({
                  ref: refs.setFloating,
                  className: classNames(
                    className,
                    styles.textarea,
                    styles.floating
                  ),
                  rows: 1,
                  spellCheck: 'false',
                  style: floatingStyles,
                  onChange: handleChange,
                  onFocus: handleFocus,
                  onBlur,
                  ...props
                })}
              />
            </FloatingOverlay>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  );
};

ExpandingTextarea.displayName = 'ExpandingTextarea';

export default ExpandingTextarea;

// Expand the textarea to fit its content
function expandTextarea(element: HTMLTextAreaElement) {
  // reset the scroll position and height
  element.scrollTop = 0;
  element.scrollLeft = 0;
  element.style.removeProperty('height');

  // set the height to the height needed for the content plus the borders
  const computedStyle = window.getComputedStyle(element);
  const borderTop = parseFloat(computedStyle.borderTopWidth);
  const borderBottom = parseFloat(computedStyle.borderBottomWidth);

  const newHeight = element.scrollHeight + borderTop + borderBottom;
  element.style.height = `${newHeight}px`;
}

function moveCursorToEnd(element: HTMLTextAreaElement) {
  const length = element.value.length;
  element.setSelectionRange(length, length);

  //scroll to bottom
  element.scrollTop = element.scrollHeight;
}
