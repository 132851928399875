import { memo } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
  elementOperation,
  FLEX_DIRECTION_OPTIONS,
  FLEX_DIRECTION_OPTIONS_LABEL,
  IMAGE_MARGIN_PROPS
} from '../../../../utils/themes';
import {
  DropdownField,
  NumberInput,
  PaddingInput,
  PropertyLabel,
  SoloImageField
} from '../../../Core';

function ImageStyleSection({
  element,
  elementStyle,
  viewport,
  labelData,
  labelPropData = () => {},
  handleStyleChange,
  handleUpdates,
  fieldType = '',
  hideImageSelection = false
}: any) {
  function handlePropChange(propUpdate: any) {
    handleUpdates([elementOperation({ viewport, propUpdate })]);
  }
  const imgKey = fieldType === 'file_upload' ? 'icon' : 'image';

  return (
    <>
      {!hideImageSelection && (
        <Row>
          <Col sm='3'>
            <PropertyLabel
              centered={false}
              label='Image'
              {...labelPropData('image')}
            />
          </Col>
          <Col>
            <SoloImageField
              imageId={element[imgKey]}
              // @ts-expect-error TS(2322) FIXME: Type '(newImage: any) => void' is not assignable t... Remove this comment to see the full error message
              setImage={(newImage: any) =>
                handlePropChange({
                  properties: { [imgKey]: newImage?.file || '' },
                  [imgKey]: newImage?.id || ''
                })
              }
              fileTypes={['image/*']}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col sm='3'>
          <PropertyLabel
            label='Padding'
            centered={false}
            {...labelData(...IMAGE_MARGIN_PROPS)}
          />
        </Col>
        <Col style={{ display: 'flex', justifyContent: 'center' }}>
          <PaddingInput
            value={{
              top: elementStyle.image_margin_top,
              right: elementStyle.image_margin_right,
              bottom: elementStyle.image_margin_bottom,
              left: elementStyle.image_margin_left
            }}
            onComplete={(newMargin: any) =>
              handleStyleChange({
                image_margin_top: newMargin.top,
                image_margin_right: newMargin.right,
                image_margin_bottom: newMargin.bottom,
                image_margin_left: newMargin.left
              })
            }
          />
        </Col>
      </Row>

      <Row>
        <Col sm='3'>
          <PropertyLabel
            label='Width'
            {...labelData('image_width', 'image_width_unit')}
          />
        </Col>
        <Col>
          <NumberInput
            value={elementStyle.image_width}
            unit={elementStyle.image_width_unit}
            units={['px', '%']}
            onComplete={({ value, unit }: any) => {
              const styleUpdate = {
                image_width: value,
                image_width_unit: unit
              };
              handleStyleChange(styleUpdate);
            }}
            triggerCleanUp
          />
        </Col>
      </Row>

      <Row>
        <Col sm='3'>
          <PropertyLabel label='Position' {...labelData('flex_direction')} />
        </Col>
        <Col>
          <DropdownField
            selected={elementStyle.flex_direction}
            onChange={(e: any) =>
              handleStyleChange({
                flex_direction: e.target.value
              })
            }
            options={FLEX_DIRECTION_OPTIONS.map((option) => ({
              value: option,
              // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              display: FLEX_DIRECTION_OPTIONS_LABEL[option]
            }))}
          />
        </Col>
      </Row>
    </>
  );
}

export default memo(ImageStyleSection);
