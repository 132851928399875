import { v4 as uuidv4 } from 'uuid';
import { getPositionId } from '../utils';
import { INode, NodeAddOptions } from './Node';
import {
  NodeIndexMap,
  NodePositionMap,
  NodeIdIndexMap,
  NodeSearchFn
} from './types';

export interface ITree {
  uuid: string;

  map: NodeIndexMap;
  idMap: NodeIdIndexMap;
  positionMap: NodePositionMap;

  root: INode | null;
  children: INode[];

  [key: string]: any; // This is to access element, subgrid and cell data without casting to any

  setRoot(node: INode): void;

  register(node: INode, index?: number): void;

  unregister(node: INode): void;

  get(uuidOrPositionId: string | number[]): INode | undefined;

  add(parent: INode, node: INode, options?: NodeAddOptions): INode | null;

  remove(node: INode): void;

  restructure(): void;

  setMiscMapKey(key: string, value: any): void;

  find(
    nodeOrPosition: INode | number[],
    fn: NodeSearchFn,
    seen?: { [key: string]: boolean }
  ): INode | null;

  indexOf(node: INode): number | undefined;
}

/**
 * Abstract class for the base functionality of a tree.
 * BaseTree is intended to hide functionality that is not related
 * to business logic and has a smaller likelihood of being changed.
 */
export abstract class BaseTree<N extends INode> implements ITree {
  #uuid: string; // Unique identifier of the Tree
  #map: NodeIndexMap; // Hashmap of all of the nodes connected to this tree, keyed by their UUIDs
  #idMap: NodeIdIndexMap; // Hashmap of all of the nodes connected to this tree, keyed by their IDs
  #positionMap: NodePositionMap; // Hashmap of all of the nodes connected to this tree, keyed by their positions
  #root: N | null; // Reference to the root node of the Tree
  #children: N[]; // Array of all of the nodes within this tree sorted from left to right by hierarchy.

  constructor(root: N | null = null) {
    this.#uuid = uuidv4();
    this.#root = root;
    this.#map = {};
    this.#idMap = {};
    this.#positionMap = {};
    this.#children = [];

    // If the root node is passed, link the root node to this Tree and register it as a child.
    if (this.#root) {
      this.#root.link(this);
      this.register(this.#root);
    }
  }

  /**
   * Set the root node of this Tree.
   * @param node - Node to be set as the root node of this tree
   */
  public setRoot(node: N) {
    this.#root = node;

    if (node.isUnlinked()) {
      this.#root.link(this);
      this.register(this.#root);
    }
  }

  /**
   * Register a node as a child to this tree. This function will ensure that the
   * node is added in the appropriate spot in the tree's children array. If the node has children,
   * it will also register each child. Once all registrations are complete, it will restructure the
   * tree.
   * @param node - Node to be registered as a child of this tree
   * @param index - Optional. Index of where to register the node as a child in the Tree's array of children.
   */
  public register(node: N, index?: number) {
    let targetIndex = index || 0;

    if (this.#map[node.uuid]) {
      return; // Already registered
    }

    if (!node.isRoot() && index === undefined) {
      // index of parent + index of node within parent + weight left of node index (siblings)
      const parentNodeIndex = this.#map[(node.parent as N).uuid];
      const nodeIndex = (node.parent as N).indexOf(node);
      const weightLeftOfNode = new Array(nodeIndex)
        .fill(null)
        .reduce((weight: number, _, index) => {
          return weight + (node.parent as N).children[index].weight;
        }, 1);

      targetIndex = parentNodeIndex + weightLeftOfNode;
    }

    this.#children.splice(targetIndex, 0, node);

    if (node.hasChildren()) {
      node.children.reduce((newIndex, child) => {
        this.register(child as N, newIndex);
        return newIndex + child.weight;
      }, targetIndex + 1);
    }

    if (index === undefined) {
      this.restructure();
    }
  }

  /**
   * Unregister a node from this tree. Any references to the target node will be undefined
   * after performing this. This will call remove() afterwards to remove it from the tree.
   * @param node - Node to be unregistered
   */
  public unregister(node: N) {
    if (node.parent) {
      node.parent.disconnectChild(node);
    }

    node.unlink();

    this.remove(node);
  }

  /**
   * Returns a node with the target UUID or position if it exists
   * @param uuidOrPositionId - Node's UUID or position (as a string)
   * @returns {BaseNode | undefined} The node being searched for if it exists
   */
  public get(uuidOrPositionId: string | number[]): N | undefined {
    let _uuidOrPositionId = uuidOrPositionId;

    if (Array.isArray(uuidOrPositionId)) {
      _uuidOrPositionId = getPositionId(uuidOrPositionId);
    }

    const node =
      this.#children[this.#map[_uuidOrPositionId as string]] ||
      this.#children[this.#map[this.#positionMap[_uuidOrPositionId as string]]];

    return node;
  }

  /**
   * Add a new node to the target node as a child.
   * @param parent - Node to add the new node to
   * @param node - Node that is to be added
   * @returns {BaseNode | null} The new node if successfully added
   */
  public add(parent: N, node: N, options: NodeAddOptions) {
    return parent.add(node, options);
  }

  /**
   * Remove a node from this tree.
   * @param node - Node to be removed
   */
  public remove(node: N) {
    if (node.tree) {
      node.remove();
    } else {
      this.#children.splice(this.#map[node.uuid], node.weight);
      delete this.#map[node.uuid];

      this.restructure();
    }
  }

  /**
   * Internal use only. Rebuild the internal hashmap and position map of children
   * within this tree.
   */
  public restructure() {
    this.#map = {};
    this.#idMap = {};
    this.#positionMap = {};

    this.#children.forEach((child, index) => {
      this.#map[child.uuid] = index;
      this.#idMap[child.id] = index;
      this.#positionMap[child.positionId as string] = child.uuid;
    });

    if (!this.#root && this.#children.length === 1) {
      this.setRoot(this.#children[0]);
    } else if (
      this.#root &&
      this.#children.length > 1 &&
      this.#root.uuid !== this.#children[0].uuid
    ) {
      this.setRoot(this.#children[0]);
    }
  }

  /**
   * Legacy support. Mainly used to add keys such as `unmapped` subgrids to the internal
   * hashmap of children.
   */
  public setMiscMapKey(key: string, value: any) {
    if (this.#map[key]) {
      return; // Prevent overriding existing keys
    }

    this.#map[key] = value;
  }

  /**
   * Search for a node within this tree relative to the node or position provided using the search function provided.
   * @param nodeOrPosition - Starting position of the search
   * @param fn - Function to be used on each function within the search
   * @param seen - Internal use only. The nodes that have been seen by the search function.
   * @returns {BaseNode | undefined} Node that satisfies the search or undefined
   */
  public find(
    nodeOrPosition: N | number[],
    fn: NodeSearchFn = () => true,
    seen: { [key: string]: boolean } = {}
  ): N | null {
    const position = Array.isArray(nodeOrPosition)
      ? [...nodeOrPosition]
      : [...nodeOrPosition.position];
    const node = this.get(position);

    // Find the closest ancestor that exists
    if (!node) {
      position.pop();
      return this.find(position, fn);
    }

    if (seen[node.positionId as string]) {
      return null;
    }

    seen[node.positionId as string] = true;

    if (fn(node)) {
      return node;
    }

    const target = node.children
      .map((child) => {
        if (!seen[child.positionId as string]) {
          return this.find(child as N, fn, seen);
        }

        return false;
      })
      .find((possibleTarget) => !!possibleTarget);

    if (target) {
      return target as N;
    }

    if (position.length > 0) {
      position.pop();
      return this.find(position, fn, seen);
    }

    return null; // Nothing found
  }

  /**
   * Returns the index of the node provided within the tree's children or undefined if it does not exist.
   * @param node - Node to search for
   * @returns {number | undefined} Index of the node provided within the tree's children or undefined if it does not exist
   */
  public indexOf(node: N): number | undefined {
    return this.#map[node.uuid];
  }

  get uuid() {
    return this.#uuid;
  }

  get root() {
    return this.#root;
  }

  get map() {
    return this.#map;
  }

  get idMap() {
    return this.#idMap;
  }

  set idMap(value: NodeIdIndexMap) {
    this.#idMap = value;
  }

  get positionMap() {
    return this.#positionMap;
  }

  get children() {
    return this.#children;
  }
}
