export default function CopyIcon({
  width = 32,
  height = 32,
  color = '#414859',
  ...props
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 18'
      stroke={color}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ cursor: 'pointer' }}
      {...props}
    >
      <path
        d='M3.66797 2.33333V13C3.66797 13.3536 3.80844 13.6928 4.05849 13.9428C4.30854 14.1929 4.64768 14.3333 5.0013 14.3333H13.0013C13.3549 14.3333 13.6941 14.1929 13.9441 13.9428C14.1942 13.6928 14.3346 13.3536 14.3346 13V5.228C14.3346 5.05037 14.2991 4.87454 14.2302 4.71083C14.1613 4.54712 14.0603 4.39883 13.9333 4.27467L10.9733 1.38C10.7242 1.13644 10.3897 1.00004 10.0413 1H5.0013C4.64768 1 4.30854 1.14048 4.05849 1.39052C3.80844 1.64057 3.66797 1.97971 3.66797 2.33333V2.33333Z'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.66667 3.66666H2.33333C1.97971 3.66666 1.64057 3.80714 1.39052 4.05719C1.14048 4.30724 1 4.64638 1 5V15.6667C1 16.0203 1.14048 16.3594 1.39052 16.6095C1.64057 16.8595 1.97971 17 2.33333 17H10.3333C10.687 17 11.0261 16.8595 11.2761 16.6095C11.5262 16.3594 11.6667 16.0203 11.6667 15.6667V14.6667'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <mask
        id='path-3-outside-1_2829_2307'
        maskUnits='userSpaceOnUse'
        x='8.33203'
        y='1.5'
        width='6'
        height='5'
        fill='black'
      >
        <rect fill='white' x='8.33203' y='1.5' width='6' height='5' />
        <path d='M10.332 1.5V3.66667C10.332 4.02029 10.4725 4.35943 10.7226 4.60948C10.9726 4.85952 11.3117 5 11.6654 5H13.9988' />
      </mask>
      <path
        d='M11.6654 5V6.5V5ZM8.83203 1.5V3.66667H11.832V1.5H8.83203ZM8.83203 3.66667C8.83203 4.41811 9.13054 5.13878 9.6619 5.67014L11.7832 3.54882C11.8145 3.58007 11.832 3.62246 11.832 3.66667H8.83203ZM9.6619 5.67014C10.1932 6.20149 10.9139 6.5 11.6654 6.5V3.5C11.7096 3.5 11.752 3.51756 11.7832 3.54882L9.6619 5.67014ZM11.6654 6.5H13.9988V3.5H11.6654V6.5Z'
        fill={color}
        mask='url(#path-3-outside-1_2829_2307)'
      />
    </svg>
  );
}
