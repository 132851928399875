import Dialog from '../../Dialog';
import { useState } from 'react';
import { Neutral, Positive } from '../../Core/Button';
import { Button } from '../../Core/Button/button';

export default function PDFActionRenderer({ ActionInputFields, index }: any) {
  const [show, setShow] = useState(false);
  const saveButton = document.getElementsByClassName('rollout-button--primary')[
    index
  ] as any;

  return (
    <>
      <Button style={{ width: '160px' }} onClick={() => setShow(true)}>
        Configure PDF
      </Button>
      <Dialog
        isOpen={show}
        onClose={() => setShow(false)}
        shouldShowCloseIcon
        title='PDF Generator'
        size='lg'
      >
        {ActionInputFields && <ActionInputFields />}
        <div className='dialog-form-action text-center'>
          <Neutral onClick={() => setShow(false)}>Cancel</Neutral>
          <Positive onClick={() => saveButton.click()}>Save</Positive>
        </div>
      </Dialog>
    </>
  );
}
