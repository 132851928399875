export default function MultiselectDropdownIcon() {
  return (
    <svg viewBox='0 0 49 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5 15H42C43.6569 15 45 16.3431 45 18V30.7755C45 32.4324 43.6682 33.7755 42.0114 33.7755C32.3769 33.7755 10.1975 33.7755 5.5 33.7755'
        stroke='#414859'
        strokeWidth='3'
        strokeLinecap='round'
      />
      <path
        d='M34.3877 23.1632L37.2448 26.0204L40.102 23.1632'
        stroke='#414859'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <rect y='19' width='11' height='11' rx='2' fill='#BDC2CE' />
      <rect x='13' y='19' width='15' height='11' rx='2' fill='#BDC2CE' />
    </svg>
  );
}
