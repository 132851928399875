export default function SMSIcon({ width = 64, height = 64 }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 48 48'
      width={width}
      height={height}
      fill='none'
    >
      <path
        fill='#009688'
        d='M37 39H11l-6 6V11c0-3.3 2.7-6 6-6h26c3.3 0 6 2.7 6 6v22c0 3.3-2.7 6-6 6z'
      />
      <g fill='#fff'>
        <circle cx='24' cy='22' r='3' />
        <circle cx='34' cy='22' r='3' />
        <circle cx='14' cy='22' r='3' />
      </g>
    </svg>
  );
}
