import { useEffect, useState } from 'react';
import { Cell, Gig } from '../components/RenderingEngine/GridInGrid/engine';
import { hasLoadedFonts, loadedFonts } from '../utils/themes';

const loadAllFonts = (gig: Gig) => {
  gig.grid.children.forEach((cell: Cell) => {
    if (cell.isElement) {
      cell.getRenderData({ loadFonts: true });
    }
  });
};

/**
 * useFontLoader
 * Using Gig, this hook will load all fonts and return whether
 * the fonts are still being loaded or not.
 */
export const useFontLoader = (gig: Gig) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (gig) {
      loadAllFonts(gig);

      if (Array.from(loadedFonts).length === 0) {
        setIsLoading(false);
      }

      if (!hasLoadedFonts() && Array.from(loadedFonts).length > 0) {
        setIsLoading(true);

        const interval = setInterval(() => {
          if (hasLoadedFonts()) {
            clearInterval(interval);
            setIsLoading(false);
          }
        }, 100);

        return () => {
          clearInterval(interval);
        };
      } else if (hasLoadedFonts()) {
        setIsLoading(false);
      }
    }
  }, [gig]);

  return isLoading;
};
