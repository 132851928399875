import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { parseAndThrowError } from './utils';

const getFields = createAsyncThunk(
  'fields/getFields',
  async ({ token, hidden = false }: { token: string; hidden?: boolean }) => {
    const [fieldRes, usageRes] = await Promise.all([
      FeatheryAPI.getFields(token, hidden),
      hidden ? null : FeatheryAPI.getServarUsage(token)
    ]);

    if (fieldRes.status === 200 && (hidden || usageRes.status === 200)) {
      return {
        fields: await fieldRes.json(),
        usage: hidden ? {} : await usageRes.json()
      };
    }

    throw new Error('Could not fetch fields');
  }
);

const createField = createAsyncThunk(
  'fields/createField',
  async ({
    token,
    hidden = false,
    ...data
  }: {
    token: string;
    hidden?: boolean;
  }) => {
    const response = await FeatheryAPI.createField(token, hidden, data);
    if (response.status === 201) {
      return await response.json();
    }

    throw new Error('Could not create field');
  }
);

const editField = createAsyncThunk(
  'fields/editField',
  async ({
    token,
    hidden = false,
    ...data
  }: {
    token: string;
    hidden?: boolean;
  }) => {
    const response = await FeatheryAPI.editField(token, hidden, data);
    if (response.status === 200) {
      return await response.json();
    }

    throw new Error('Could not edit field');
  }
);

const editFieldValue = createAsyncThunk(
  'fields/editFieldValue',
  async ({
    token,
    hidden = false,
    ...data
  }: {
    token: string;
    hidden?: boolean;
  }) => {
    const response = await FeatheryAPI.editFieldValue(token, hidden, data);
    const payload = await response.json();
    if (response.status === 201) {
      return payload;
    } else {
      parseAndThrowError(payload, 'Could not edit field value');
    }
  }
);

const deleteField = createAsyncThunk(
  'fields/deleteField',
  async ({
    token,
    hidden = false,
    id
  }: {
    token: string;
    hidden?: boolean;
    id: string;
  }) => {
    const response = await FeatheryAPI.deleteField(token, hidden, id);
    if (response.status !== 204) {
      throw new Error('Could not delete field');
    }
  }
);

const deleteFieldValue = createAsyncThunk(
  'fields/deleteFieldValue',
  async ({
    token,
    hidden = false,
    ...data
  }: {
    token: string;
    hidden?: boolean;
  }) => {
    const response = await FeatheryAPI.deleteFieldValue(token, hidden, data);
    if (response.status !== 204) {
      throw new Error('Could not delete field value');
    }
  }
);

const bulkUpload = createAsyncThunk(
  'fields/bulkUpload',
  async ({
    token,
    panelId,
    data
  }: {
    token: string;
    panelId: string;
    data: any;
  }) => {
    const response = await FeatheryAPI.bulkUpload(token, panelId, data);

    if (response.status !== 201) {
      throw new Error('Could not bulk upload CSV');
    }
  }
);

const fieldsSlice = createSlice({
  name: 'fields',
  initialState: {
    servars: null as null | any[],
    hiddenFields: null as null | any[],
    usage: {}
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFields.fulfilled, (state, action) => {
      const fieldKey = action.meta.arg.hidden ? 'hiddenFields' : 'servars';
      const { fields, usage } = action.payload;
      state[fieldKey] = fields;
      if (fieldKey === 'servars') state.usage = usage;
    });
    builder.addCase(createField.fulfilled, (state: any, action: any) => {
      const fieldKey = action.meta.arg.hidden ? 'hiddenFields' : 'servars';
      state[fieldKey] = [...state[fieldKey], action.payload];
    });
    builder.addCase(editField.fulfilled, (state: any, action: any) => {
      const {
        meta: { arg },
        payload
      } = action;
      const fieldKey = arg.hidden ? 'hiddenFields' : 'servars';

      const index = state[fieldKey].findIndex(
        (field: any) => field.id === arg.id
      );
      const newFields = state[fieldKey].filter(
        (field: any) => field.id !== arg.id
      );
      newFields.splice(index, 0, payload);
      state[fieldKey] = newFields;
    });
    builder.addCase(deleteField.fulfilled, (state: any, action: any) => {
      const {
        meta: { arg }
      } = action;
      const fieldKey = arg.hidden ? 'hiddenFields' : 'servars';
      const index = state[fieldKey].findIndex(
        (field: any) => field.id === arg.id
      );
      if (index >= 0) {
        state[fieldKey].splice(index, 1);
      }
    });
  }
});

export default fieldsSlice.reducer;
export {
  getFields,
  createField,
  editField,
  editFieldValue,
  deleteField,
  deleteFieldValue,
  bulkUpload
};
