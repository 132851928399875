export default [
  'Queensland',
  'New South Wales',
  'Australian Capital Territory',
  'Victoria',
  'Tasmania',
  'Northern Territory',
  'South Australia',
  'Western Australia'
];
