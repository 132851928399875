import { OPERATOR_CODE } from '@feathery/react';
import { UnresolvedComparisonRule } from '../../../../../../components/Modals';
import { RuleExpression } from '../../context/RuleDSL';
import { fieldTypeNameMap } from '../../../../../../utils/elements';
import {
  FIELD_OPERATORS,
  FIELD_OPERATOR_MAP
} from '../../../../../../utils/validation';

export const transformToComparisonRule = (
  expression: IRuleExpression
): UnresolvedComparisonRule => {
  const { operands, operator } = expression;
  const comparisonRule: UnresolvedComparisonRule = {
    comparison: operator as OPERATOR_CODE,
    values: [],
    field_id: null
  };

  // Handle the first operand which is the field being compared against
  const firstOperand = operands[0] as IRuleOperand;
  if (firstOperand && firstOperand.meta.ruleIndex === undefined) {
    const { meta } = firstOperand;

    if (meta.field_type) comparisonRule.field_type = meta.field_type;
    if (meta.hidden_field) comparisonRule.hidden_field = meta.hidden_field;
    if (meta.servar) comparisonRule.servar = meta.servar;
    if (meta.field_key) comparisonRule.field_key = meta.field_key;

    comparisonRule.field_id = firstOperand.value;
  }

  // Transform the rest of the operands into values of the ComparisonRule
  (operands as IRuleOperand[]).forEach((operand) => {
    if (operand.meta.ruleIndex === undefined) return;

    if (operand.type === 'field') {
      comparisonRule.values.push({
        field_id: operand.value,
        field_type: operand.meta.field_type
      });
    } else {
      comparisonRule.values.push(operand.value);
    }
  });

  if (operands.length === 1) {
    comparisonRule.values.push('');
  }

  return comparisonRule;
};

export const transformToExpression = (
  rule: UnresolvedComparisonRule
): IRuleExpression => {
  const expression = new RuleExpression();

  expression.operator = rule.comparison as string;
  const hasRightSideValue =
    FIELD_OPERATORS[rule.comparison as OPERATOR_CODE] &&
    !FIELD_OPERATORS[rule.comparison as OPERATOR_CODE].noValues;

  if (rule.field_id) {
    const { field_id, field_type, hidden_field, servar, field_key } = rule;
    const fieldOperand = expression.addOperand();

    fieldOperand.type = 'field';
    fieldOperand.value = field_id;
    fieldOperand.meta = {};

    if (field_type) fieldOperand.meta.field_type = field_type;
    if (hidden_field) fieldOperand.meta.hidden_field = hidden_field;
    if (servar) fieldOperand.meta.servar = servar;
    if (field_key) fieldOperand.meta.field_key = field_key;
  } else {
    const fieldOperand = expression.addOperand();
    fieldOperand.type = 'field';
  }

  if (rule.values.length > 0 && hasRightSideValue) {
    rule.values.forEach((value, index) => {
      const valueOperand = expression.addOperand();
      valueOperand.meta = {};

      if (typeof value === 'object' && value.field_id) {
        valueOperand.type = 'field';
        valueOperand.value = value.field_id;
        valueOperand.meta.field_type = value.field_type;
      } else if (typeof value === 'string') {
        valueOperand.type = 'value';
        valueOperand.value = value as string;
      }

      valueOperand.meta.ruleIndex = index;
    });
  }

  return expression.toJSON();
};

const fieldNameTypeMap: { [key: string]: string } = Object.keys(
  fieldTypeNameMap
).reduce((acc, key) => {
  return {
    ...acc,
    [fieldTypeNameMap[key]]: key
  };
}, {});

export const getDefaultOperator = (fieldType: string) => {
  const servarType = fieldNameTypeMap[fieldType];
  const fieldOperator = FIELD_OPERATOR_MAP[servarType];

  if (!fieldOperator) {
    return FIELD_OPERATORS.equal.code;
  }

  return Object.keys(fieldOperator)[0];
};
