export const MIN_AXIS_SIZE = 15;
export const MIN_LAYOUT_SIZE = 50;

export const DIRECTION = {
  Top: 'top',
  Right: 'right',
  Bottom: 'bottom',
  Left: 'left'
};

export const DIRECTION_ACTIONS = {
  addTop: 'top',
  addRight: 'right',
  addBottom: 'bottom',
  addLeft: 'left'
};
