import { memo } from 'react';
import useFeatheryRedux from '../../../../../redux';
import {
  calculateElementRenderData,
  getStepPropFromElementType,
  getUndoRedoPayload
} from '../../../../../utils/step';
import {
  COMPONENT_MAP,
  TYPE_CONTAINER,
  TYPE_TEXT,
  TYPE_VIDEO
} from '../../../../../utils/elements';
import produce from 'immer';
import styles from '../../../styles.module.scss';
import { getTextEditCallbacks } from '../../../../../utils/inlineTextEdit';
import { useAppSelector, useFeatheryContext } from '../../../../../hooks';
import useViewport from '../../../../../hooks/useViewport';
import ContainerElement from '../ContainerElement';
import CellContainer, { CellControlsType } from '../CellContainer';
import { StyledContainer } from '@feathery/react';
import { getElementStyleType } from './utils';
import useElementDrag, {
  ElementDragOperation
} from '../../../../CustomDragLayer/hooks/useElementDrag';
import { ControlLayerDetails } from '../../../Controls/ControlLayer';
import { Cell } from '../../engine';

const getComponent = (type: any) => {
  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return COMPONENT_MAP[type];
};

const RenderElement = ({ element }: any) => {
  const { style, type } = getElementStyleType(element);

  // Containers from the selection panel are treated differently
  // because they are not actually elements.
  if (type === TYPE_CONTAINER) return <ContainerElement />;

  const theme = useAppSelector((s) => s.formBuilder.theme);
  const { viewport } = useViewport();

  if (!theme) return null;

  const renderData: any = calculateElementRenderData({
    element,
    viewport,
    theme,
    style
  });
  const { featheryContext } = useFeatheryContext();

  const Component = getComponent(type);

  return (
    <StyledContainer
      node={{ ...renderData, isElement: true }}
      viewport={viewport}
      viewportOnly={true}
    >
      {type === TYPE_VIDEO && <div className={styles.videoCover} />}
      <Component element={renderData} featheryContext={featheryContext} />
    </StyledContainer>
  );
};

export const Element = memo(RenderElement);

type CellElementProps = {
  node: Cell;
  controls?: CellControlsType;
};

const CellElement = ({ node, controls }: CellElementProps) => {
  const textSelection = useAppSelector((s) => {
    // textSelection is only relevant if the element is focused
    // so we only return it if the element is focused
    // this prevents updates causing every component to re-render
    const focusedElement = s.formBuilder.focusedElement;
    if (focusedElement.elementId === node.id) {
      return s.formBuilder.textSelection;
    }
    return null;
  });
  const theme = useAppSelector((s) => s.formBuilder.theme);
  const { viewport } = useViewport();
  const isFocused = useAppSelector(
    // using the focusedElement directly will cause every element to re-render.
    // moving the logic to the selector causes only the focused and unfocused elements re-render
    (s) => s.formBuilder.focusedElement.elementId === node.id
  );
  const {
    formBuilder: {
      setEditingText,
      setTextSelection,
      setPanelDataWithUndoRedo
      // setShowImageModal TODO add this back
    }
  } = useFeatheryRedux();
  const { featheryContext } = useFeatheryContext();

  const elementDrag = useElementDrag(() => ({
    node,
    operation: ElementDragOperation.Move,
    opts: {
      onEnd: () => (ControlLayerDetails.draggingNode = null)
    }
  }));

  if (!node.isElement) return null;

  const element = node.unlinked().element.getRaw();
  const { style, type } = getElementStyleType(element);
  const renderData: any = calculateElementRenderData({
    element,
    viewport,
    theme,
    style,
    loadFonts: false
  });

  const textCallbacks = getTextEditCallbacks({
    setText: (text: any, textFormatted: any) => {
      const stepElementProp = getStepPropFromElementType(type);
      const workingSteps = (window as any).featheryGIG.rawSteps;
      const activeStepId = (window as any).featheryGIG.raw.id;
      const newSteps = produce(workingSteps, (draft: any) => {
        const draftElement = draft[activeStepId][stepElementProp].find(
          (el: any) => el.id === element.id
        );
        if (draftElement) {
          draftElement.properties.text = text;
          draftElement.properties.text_formatted = textFormatted;
        }
      });
      setPanelDataWithUndoRedo(
        getUndoRedoPayload({
          elementType: type,
          oldSteps: workingSteps,
          newSteps,
          stepId: activeStepId,
          changedStepKeys: [activeStepId]
        })
      );
    },
    curTextFormatted: element.properties.text_formatted,
    textSelection,
    setTextSelection,
    setEditingText
  });

  const Component = getComponent(type);

  const ComponentElement = (
    <Component
      editMode={
        controls || 'data' === renderData.properties?.text_mode
          ? 'disabled'
          : 'editable'
      }
      element={renderData}
      textCallbacks={textCallbacks}
      featheryContext={featheryContext}
      focused={isFocused}
    />
  );

  return (
    <CellContainer node={node} controls={controls}>
      {type === TYPE_VIDEO && <div className={styles.videoCover} />}
      {type === TYPE_TEXT ? (
        <div ref={elementDrag}>{ComponentElement}</div>
      ) : (
        ComponentElement
      )}
    </CellContainer>
  );
};

export default CellElement;
