import { useMemo } from 'react';
import { useRefState } from './useRefState';

/**
 * This hook allows to pass an array of event names and
 * returns a map of event names to functions that allows
 * subscribing to the events when called. The emit function
 * return allows to emit evens to the specified event name
 * which will run each handler subscribed to the event.
 */
export const useEventListeners = (events: any) => {
  const [handlers] = useRefState(
    events.reduce(
      // @ts-expect-error TS(7006) FIXME: Parameter 'eventHandlers' implicitly has an 'any' ... Remove this comment to see the full error message
      (eventHandlers, event) => ({
        ...eventHandlers,
        [event]: []
      }),
      {}
    )
  );

  const eventListeners = useMemo(() => {
    return events.reduce(
      // @ts-expect-error TS(7006) FIXME: Parameter 'eventFns' implicitly has an 'any' type.
      (eventFns, event) => ({
        ...eventFns,

        [event]: (listener: any) => {
          handlers[event].push(listener);
        }
      }),
      {}
    );
  }, []); // eslint-disable-line

  const emit = (event: any, data: any) => {
    if (handlers[event] && handlers[event].length > 0) {
      handlers[event].forEach((handler: any) => handler({ ...data }));
    }
  };

  return [eventListeners, emit];
};
