import '../../style/dialog-form.css';

import { ALL_INTEGRATIONS_MAP, INTEGRATIONS } from './types';
import IntegrationsSidebar from './IntegrationsSidebar';

function PowerAutomateSidebar() {
  return (
    <IntegrationsSidebar
      integrationInfo={ALL_INTEGRATIONS_MAP[INTEGRATIONS.POWER_AUTOMATE]}
    >
      <span>
        If Power Automate is enabled on your Business plan,{' '}
        <a
          target='_blank'
          href='https://www.feathery.io/integrations/power-automate'
          rel='noreferrer'
        >
          learn how to set up the integration.
        </a>
      </span>
    </IntegrationsSidebar>
  );
}

export default PowerAutomateSidebar;
