import { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from '../../../hooks';
import {
  groupByForm,
  groupKeysById,
  handleErrorMessage,
  transformFieldMap
} from './utils';
import useFeatheryRedux from '../../../redux';

interface Result {
  promoteForm: () => void;
  isRollback: boolean;
  promote_id: string;
  promote_key: string;
  servarKeysById: Record<string, string>;
  thisFormServarIds: string[];
  promoteToServarIds: string[];
  mapFieldData: Record<string, string>;
  setMapFieldData: React.Dispatch<React.SetStateAction<Record<string, string>>>;
}

export function usePromoteForm(formId: string): Result {
  const panel = useAppSelector((state) => state.panels.panels[formId]);
  const promote_id = panel.promote_to || panel.promote_from;
  const isRollback = !panel.promote_to && panel.promote_from;
  const promote_key = useAppSelector(
    (state) => state.panels.panels[promote_id]?.key ?? ''
  );

  const [mapFieldData, setMapFieldData] = useState<Record<string, string>>({});

  const {
    promotePanel,
    pollPromotion,
    getPanelPromoteLinkFields,
    toasts: { addErrorToast }
  } = useFeatheryRedux();

  const servarKeysById = useAppSelector((state) =>
    (state.fields.servars ?? []).reduce<Record<string, string>>(
      groupKeysById,
      {}
    )
  );

  const servarsByForm = useAppSelector((state) =>
    Object.entries<{ panel_id: string }[]>(state.fields.usage ?? {}).reduce<
      Record<string, string[]>
    >(groupByForm, {})
  );

  // remove duplicates from each array and eachother
  const setThisFormServars = new Set(servarsByForm[formId]);
  const setPromoteFormServars = new Set(servarsByForm[promote_id]);

  const thisFormServarIds = (servarsByForm[formId] ?? []).filter(
    (item) => !setPromoteFormServars.has(item)
  );
  const promoteToServarIds = (servarsByForm[promote_id] ?? []).filter(
    (item) => !setThisFormServars.has(item)
  );

  useEffect(() => {
    if (panel.promote_to && formId) {
      (async () => {
        const result = await getPanelPromoteLinkFields({ panelId: formId });
        setMapFieldData(result);
      })();
    }
  }, [panel.promote_to]);

  const promoteForm = useCallback(async () => {
    const MAX_RETRIES = 3;
    const POLLING_INTERVAL = 5_000;

    try {
      // Start the promotion, returns right away
      await promotePanel({
        panelId: formId,
        fieldMap: transformFieldMap(mapFieldData, thisFormServarIds)
      });

      // Poll for the promotion to finish
      await new Promise((resolve, reject) => {
        let retries = 0;

        const pollInterval = setInterval(async () => {
          try {
            const result = await pollPromotion({ panelId: formId });
            retries = 0;
            if (!result.promoting) {
              clearInterval(pollInterval);
              resolve(result);
              return;
            }
          } catch (error) {
            retries += 1;
            console.error(`Polling attempt ${retries} failed:`, error);

            // fail after consecutive errors
            if (retries >= MAX_RETRIES) {
              clearInterval(pollInterval);
              reject(
                `An unknown error occurred while checking for promotion status.`
              );
            }
          }
        }, POLLING_INTERVAL);
      }).catch((reason) => {
        throw new Error(reason);
      });
      window.location.assign('/forms');
    } catch (error) {
      addErrorToast({
        title: handleErrorMessage(error)
      });
    }
  }, [formId, mapFieldData, thisFormServarIds]);

  return {
    promoteForm,
    isRollback,
    promote_id,
    promote_key,
    servarKeysById,
    thisFormServarIds,
    promoteToServarIds,
    mapFieldData,
    setMapFieldData
  };
}
