export default function HorizontalRightIcon({ width = 16, height = 16 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 13C12 13.5523 12.4477 14 13 14C13.5523 14 14 13.5523 14 13L12 13ZM14 3C14 2.44772 13.5523 2 13 2C12.4477 2 12 2.44772 12 3L14 3ZM14 13L14 3L12 3L12 13L14 13Z'
        fill='#414859'
      />
      <rect
        x='10.234'
        y='10.0833'
        width='7.23404'
        height='4.16667'
        rx='1'
        transform='rotate(180 10.234 10.0833)'
        stroke='#414859'
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  );
}
