import classNames from 'classnames';
import { useRuleBuilderState, useRuleBuilderUpdater } from '../../context';
import { ActionBar } from '../ActionBar';
import { RuleBranch } from '../RuleBranch';
import styles from './styles.module.scss';

type RuleDSLProps = {
  mode?: RuleBuilderMode;
};

export const RuleDSL = ({ mode = 'view' }: RuleDSLProps) => {
  const dsl = useRuleBuilderState((s) => s.dsl);
  const addBranch = useRuleBuilderUpdater((s) => s.addBranch);

  if (mode === 'edit' && !dsl.branches.length) {
    return (
      <div className={styles.emptyDSL}>
        <ActionBar
          addBranch
          onClick={() => addBranch()}
          texts={{ addBranch: 'Add Action' }}
        />
      </div>
    );
  }

  return (
    <div
      className={classNames(styles.ruleDSLContainer, {
        [styles.viewMode]: mode === 'view',
        [styles.editMode]: mode === 'edit'
      })}
    >
      {dsl.branches.map((branch) => (
        <RuleBranch key={branch.id} branch={branch} mode={mode} />
      ))}
      {mode === 'edit' && <ActionBar addBranch onClick={() => addBranch()} />}
    </div>
  );
};
