export const ComputerIllustration = ({ width = 224, height = 154 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 224 154'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M151.826 152.779C138.53 153.096 122.702 152.779 111.464 152.621C100.226 152.937 84.3973 153.096 71.1016 152.779C84.2391 145.34 88.671 131.569 89.4624 122.389H133.307C134.257 131.569 138.688 145.34 151.826 152.779Z'
        fill='#F1F3F9'
        stroke='#D6DCE8'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M193.931 5.90678V119.712C193.931 122.561 191.715 124.619 189.024 124.619H33.9068C31.216 124.619 29 122.403 29 119.712V5.90678C29 3.21597 31.216 1 33.9068 1H189.024C191.715 1 193.931 3.05768 193.931 5.90678Z'
        fill='white'
        stroke='#D6DCE8'
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinejoin='round'
      />
      <path
        d='M193.929 5.90678V104.042H29.1562V5.90678C29.1562 3.21597 31.3722 1 34.063 1H189.18C191.713 1 193.929 3.05768 193.929 5.90678Z'
        fill='#F1F3F9'
        stroke='#D6DCE8'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M188.075 7.01465H35.0156V98.344H187.917V7.01465H188.075Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M123.744 33.9039C124.054 34.5789 124.498 35.7039 124.809 37.2339C125.387 40.0239 124.276 43.8939 122.5 45.8739C121.084 47.4557 118.838 48.7067 116.876 49.799C116.438 50.0429 116.015 50.2789 115.617 50.5088C114.241 51.3187 112.909 52.0836 111.577 52.8484L111.577 52.8488C110.688 53.3663 109.811 53.8838 108.934 54.4013C108.057 54.9188 107.18 55.4363 106.292 55.9538C106.154 56.0329 106.015 56.1121 105.876 56.1914C104.271 57.1046 102.619 58.0453 101.23 59.3288C100.342 60.1838 99.7204 61.1288 99.3651 62.3437C99.2763 62.6587 98.7879 62.7487 98.6102 62.4337C98.3855 62.0921 98.1924 59.7016 98.1108 58.6916C98.0956 58.5041 98.0843 58.3642 98.0774 58.2938C97.8998 55.4588 98.033 52.5338 99.4539 50.0588C100.342 48.4838 101.674 47.2688 103.14 46.2789C104.239 45.5364 105.413 44.9457 106.587 44.3551C106.978 44.1582 107.369 43.9614 107.758 43.7589C119.347 37.8189 122.056 35.6139 122.989 33.9039C123.166 33.5889 123.61 33.5889 123.744 33.9039ZM121.2 52.2613C121.648 51.8585 121.961 52.0823 122.274 52.5745C123.214 54.1855 123.527 56.7361 123.438 58.6156C123.229 63.1633 119.519 64.9102 115.827 66.649C115.559 66.7751 115.291 66.9012 115.025 67.0283L114.991 67.0449C112.57 68.2104 108.489 70.1751 107.91 73.2036C107.508 75.1725 108.179 76.8282 109.119 78.3049C109.342 78.6629 109.029 79.1103 108.626 78.9761C104.912 77.8574 102.362 75.1277 101.333 71.9058C98.9934 64.7208 104.531 61.8644 110.993 58.5312C111.453 58.2939 111.918 58.054 112.385 57.8101C116.726 55.5279 119.589 53.738 121.2 52.2613Z'
        fill='#D6DCE8'
      />
      <path
        d='M111.467 118.287C113.74 118.287 115.582 116.445 115.582 114.172C115.582 111.899 113.74 110.057 111.467 110.057C109.194 110.057 107.352 111.899 107.352 114.172C107.352 116.445 109.194 118.287 111.467 118.287Z'
        fill='#D6DCE8'
      />
      <path
        d='M1 152H222.975'
        stroke='#CDD6E1'
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
