export default [
  'Auckland',
  'Bay of Plenty',
  'Canterbury',
  'Chatham Islands',
  'Gisborne',
  'Hawke’s Bay',
  'Manawatu-Wanganui',
  'Marlborough',
  'Nelson',
  'Northland',
  'Otago',
  'Southland',
  'Taranaki',
  'Tasman',
  'Waikato',
  'Wellington',
  'West Coast'
];
