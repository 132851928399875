import classNames from 'classnames';
import styles from './styles.module.scss';
import { ErrorDisplay } from './ErrorDisplay';
import { DocsIcon } from '../../../Icons';
import { Button } from '../../../Core/Button/button';

export type NavBarProps = {
  onClick?: (button: string) => void;
  error?: string;
  back?: boolean;
  next?: boolean;
  cancel?: boolean;
  submit?: boolean;
  secondary?: boolean;
  disabled?: {
    back?: boolean;
    next?: boolean;
    cancel?: boolean;
    submit?: boolean;
    secondary?: boolean;
  };
  text?: {
    back?: string;
    next?: string;
    cancel?: string;
    submit?: string;
    secondary?: string;
  };
  docs?: string;
};

export const NavBar = (props: NavBarProps) => {
  const {
    onClick = () => {},
    error = '',
    back = false,
    next = false,
    cancel = false,
    submit = false,
    secondary = false,
    docs = '',
    disabled = {},
    text = {}
  } = props;

  return (
    <div className={styles.navBar}>
      {error && !submit && (
        <div className={classNames(styles.navBarError, styles.navBarSection)}>
          <ErrorDisplay errorMessage={error} />
        </div>
      )}
      {(back || next) && (
        <div
          className={classNames(styles.pageNavigation, styles.navBarSection)}
        >
          {back && (
            <Button
              variant='gray'
              onClick={() => !disabled.back && onClick('back')}
              disabled={disabled.back}
            >
              {text.back || 'Back'}
            </Button>
          )}
          {docs && !submit && (
            <Button variant='gray' onClick={() => window.open(docs, '_blank')}>
              <DocsIcon width={22} height={22} /> Docs
            </Button>
          )}
          {secondary && !submit && (
            <Button
              variant='gray'
              onClick={() => !disabled.secondary && onClick('secondary')}
              disabled={disabled.secondary}
            >
              {text.secondary || 'Secondary'}
            </Button>
          )}
          {next && (
            <Button
              onClick={() => !disabled.next && onClick('next')}
              disabled={disabled.next}
            >
              {text.next || 'Next'}
            </Button>
          )}
        </div>
      )}
      {(cancel || submit) && (
        <div
          className={classNames(styles.modalNavigation, styles.navBarSection)}
        >
          {cancel && (
            <Button
              variant='gray'
              onClick={() => !disabled.cancel && onClick('cancel')}
              disabled={disabled.cancel}
            >
              {text.cancel || 'Cancel'}
            </Button>
          )}
          {error && submit && (
            <div
              className={classNames(styles.navBarError, styles.navBarSection)}
            >
              <ErrorDisplay errorMessage={error} />
            </div>
          )}
          {docs && submit && (
            <Button variant='gray' onClick={() => window.open(docs, '_blank')}>
              <DocsIcon width={22} height={22} /> Docs
            </Button>
          )}
          {secondary && (
            <Button
              variant='gray'
              onClick={() => !disabled.secondary && onClick('secondary')}
              disabled={disabled.secondary}
            >
              {text.secondary || 'Secondary'}
            </Button>
          )}
          {submit && (
            <Button
              onClick={() => !disabled.submit && onClick('submit')}
              disabled={disabled.submit}
            >
              {text.submit || 'Submit'}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
