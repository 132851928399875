import type { ComponentProps } from 'react';
import LockoutButton from './LockoutButton';

function Negative({
  variant = 'destructive',
  children,
  ...props
}: ComponentProps<typeof LockoutButton>) {
  return (
    <LockoutButton {...props} variant={variant}>
      {children}
    </LockoutButton>
  );
}

export default Negative;
