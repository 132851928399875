import Label from '../../../components/Dialog/Label';
import styles from '../styles.module.scss';
import { ExternalLinkIcon } from '../../../components/Icons';
import { useHistory, useParams } from 'react-router-dom';
import {
  elementEvents,
  elementLabelMap,
  getElementLogicId,
  stepEvents
} from './RuleFieldsPanel';

export default function TriggerLabel({ entity, rule, steps }: any) {
  const history = useHistory();
  const { formId } = useParams<{ formId: string }>();

  let stepId = '';
  if (stepEvents.includes(rule.trigger_event)) {
    stepId = rule.steps[0];
  } else if (elementEvents.includes(rule.trigger_event)) {
    Object.values(steps).forEach((step: any) => {
      Object.keys(elementLabelMap).forEach((attr) => {
        step[attr].forEach((el: any) => {
          const elementId = getElementLogicId(el, attr);
          if (rule.elements.includes(elementId)) {
            stepId = step.id;
            return;
          }
        });
      });
    });
  }

  return (
    <Label className={styles.fieldLabel}>
      {entity} that trigger rule
      {stepId && (
        <div
          className={styles.linkIcon}
          onClick={() => history.push(`/forms/${formId}/${stepId}`)}
        >
          <ExternalLinkIcon />
        </div>
      )}
    </Label>
  );
}
