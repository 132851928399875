import { Viewport } from '../components/RenderingEngine/GridInGrid/engine/viewports';
import { createThemeMap, flattenStyleMap } from '../utils/elements';

import { PUBLISH_STATUS } from './utils';

const initialState: {
  theme: any;
  themeMap: any;
  flatThemeMap: any;
  publishStatus: string;
  viewport: string;
  textSelection: any;
} = {
  theme: undefined,
  themeMap: undefined,
  flatThemeMap: undefined,
  publishStatus: PUBLISH_STATUS.FULFILLED,
  viewport: Viewport.Desktop,
  textSelection: null
};

function setTheme(theme: any, publishStatus: any) {
  // TODO (jake K): Make themeBuilder module consistent with formBuilder module
  // The formBuilder.setTheme action creator accepts a parameter of { theme, status }
  // As opposed to this setTheme which accepts (theme, status)
  // The next few lines allow us to accept { theme, status } here as well
  theme = theme?.theme ?? theme;
  publishStatus = theme?.status ?? publishStatus ?? PUBLISH_STATUS.ACTIVE;
  return { type: 'themeBuilder/setTheme', payload: { theme, publishStatus } };
}

function setPublishStatus(publishStatus: any) {
  return { type: 'themeBuilder/setPublishStatus', payload: { publishStatus } };
}

function setTextSelection(textSelection: any) {
  return { type: 'themeBuilder/setTextSelection', payload: { textSelection } };
}

function reducer(state = initialState, action: any) {
  const { type, payload } = action;
  switch (type) {
    case 'themeBuilder/setTheme':
      return {
        ...state,
        publishStatus: payload.publishStatus,
        theme: payload.theme,
        themeMap: payload.theme
          ? createThemeMap(payload.theme.elements)
          : undefined,
        flatThemeMap: payload.theme
          ? flattenStyleMap(payload.theme.elements)
          : undefined,
        textSelection: null
      };
    case 'themeBuilder/setPublishStatus':
      return { ...state, publishStatus: payload.publishStatus };
    case 'themeBuilder/setTextSelection':
      return { ...state, textSelection: payload.textSelection };
    default:
      return state;
  }
}

export default reducer;
export { setTheme, setPublishStatus, setTextSelection };
