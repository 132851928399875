export default function FieldManagementIcon({ width = 18, height = 18 }) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10 8L17 8'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
      />
      <rect
        x='20'
        y='11'
        width='16'
        height='6'
        transform='rotate(-180 20 11)'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <rect
        x='20'
        y='20'
        width='16'
        height='6'
        transform='rotate(-180 20 20)'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10 8L17 8'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
      />
      <path
        d='M10 17L17 17'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
      />
      <path
        d='M7 8H7.05979'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
      />
      <path
        d='M7 8H7.05979'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
      />
      <path
        d='M7 17H7.05979'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  );
}
