import { SIZE_UNITS } from '../../../utils/constants';
import { Cell } from '../GridInGrid/engine/Cell';
import { Viewport } from '../GridInGrid/engine/viewports';

// Captures any characters for a valid hex color picker input,
// i.e. #123456, #abcdef, #0FdEA4
const HEX_REGEX = /^#[0-9a-fA-F]*$/;

const UUID_REGEX =
  '[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}';

const addMobilePrefix = (string: any, isMobile: any) =>
  isMobile ? `mobile_${string}` : string;

function getGridDimensionNamesForViewport(isMobile = false) {
  return {
    gridWidth: addMobilePrefix('width', isMobile),
    gridHeight: addMobilePrefix('height', isMobile)
  };
}

function formatDimensionValueForRender(value: any) {
  if (value === SIZE_UNITS.FILL) return '100%';
  if (value === SIZE_UNITS.FIT) return 'fit-content';
  return parseInt(value) || undefined;
}

function getDimensions(root: Cell, isMobile: boolean) {
  if (!root) return { height: null, width: null };
  const { gridHeight: _gridHeight, gridWidth: _gridWidth } =
    getGridDimensionNamesForViewport(isMobile);

  const gridHeight = root instanceof Cell ? 'height' : _gridHeight;
  const gridWidth = root instanceof Cell ? 'width' : _gridWidth;

  let { [gridHeight]: height, [gridWidth]: width } = root;

  // Fall back to desktop viewport values for mobile
  if (isMobile && height === null) {
    height = root.height;
  }

  if (isMobile && width === null) {
    width = root.width;
  }

  return { height, width };
}

function calculateDimensions(viewport: any, root: Cell) {
  const isMobile = viewport === Viewport.Mobile;
  const { height: rawHeight } = getDimensions(root, isMobile);

  const height = formatDimensionValueForRender(rawHeight);

  return {
    width: '100%',
    height: height,
    maxHeight: height,
    minHeight: height
  };
}

function getModalPayload({ element, elementType }: any) {
  return { elementId: element.id, elementType };
}

const getModalPayloadFromNode = (node: Cell) => {
  const elementType =
    node._type === 'servar_field' ? node.servar.type : node._type;

  return node.isElement
    ? getModalPayload({
        element: node.unlinked(),
        elementType
      })
    : undefined;
};

export {
  HEX_REGEX,
  UUID_REGEX,
  getGridDimensionNamesForViewport,
  getDimensions,
  calculateDimensions,
  getModalPayload,
  getModalPayloadFromNode
};
