export default [
  { display: '12:00 AM', value: '0:0' },
  { display: '12:30 AM', value: '0:30' },
  { display: '1:00 AM', value: '1:0' },
  { display: '1:30 AM', value: '1:30' },
  { display: '2:00 AM', value: '2:0' },
  { display: '2:30 AM', value: '2:30' },
  { display: '3:00 AM', value: '3:0' },
  { display: '3:30 AM', value: '3:30' },
  { display: '4:00 AM', value: '4:0' },
  { display: '4:30 AM', value: '4:30' },
  { display: '5:00 AM', value: '5:0' },
  { display: '5:30 AM', value: '5:30' },
  { display: '6:00 AM', value: '6:0' },
  { display: '6:30 AM', value: '6:30' },
  { display: '7:00 AM', value: '7:0' },
  { display: '7:30 AM', value: '7:30' },
  { display: '8:00 AM', value: '8:0' },
  { display: '8:30 AM', value: '8:30' },
  { display: '9:00 AM', value: '9:0' },
  { display: '9:30 AM', value: '9:30' },
  { display: '10:00 AM', value: '10:0' },
  { display: '10:30 AM', value: '10:30' },
  { display: '11:00 AM', value: '11:0' },
  { display: '11:30 AM', value: '11:30' },
  { display: '12:00 PM', value: '12:0' },
  { display: '12:30 PM', value: '12:30' },
  { display: '1:00 PM', value: '13:0' },
  { display: '1:30 PM', value: '13:30' },
  { display: '2:00 PM', value: '14:0' },
  { display: '2:30 PM', value: '14:30' },
  { display: '3:00 PM', value: '15:0' },
  { display: '3:30 PM', value: '15:30' },
  { display: '4:00 PM', value: '16:0' },
  { display: '4:30 PM', value: '16:30' },
  { display: '5:00 PM', value: '17:0' },
  { display: '5:30 PM', value: '17:30' },
  { display: '6:00 PM', value: '18:0' },
  { display: '6:30 PM', value: '18:30' },
  { display: '7:00 PM', value: '19:0' },
  { display: '7:30 PM', value: '19:30' },
  { display: '8:00 PM', value: '20:0' },
  { display: '8:30 PM', value: '20:30' },
  { display: '9:00 PM', value: '21:0' },
  { display: '9:30 PM', value: '21:30' },
  { display: '10:00 PM', value: '22:0' },
  { display: '10:30 PM', value: '22:30' },
  { display: '11:00 PM', value: '23:0' },
  { display: '11:30 PM', value: '23:30' }
];
