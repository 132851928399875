export default function TextLeft({ width = 24, height = 24 }) {
  return (
    <svg
      width={width}
      height={height}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20 5a1 1 0 1 1 0 2V5ZM4 7a1 1 0 1 1 0-2v2Zm16 0H4V5h16v2ZM14.664 17a1 1 0 0 1 0 2v-2ZM3.997 19a1 1 0 1 1 0-2v2Zm10.667 0H3.997v-2h10.667v2ZM10.664 11a1 1 0 0 1 0 2v-2Zm-6.667 2a1 1 0 1 1 0-2v2Zm6.667 0H3.997v-2h6.667v2Z'
        fill='#414859'
      />
    </svg>
  );
}
