export default function CaseNormalIcon({ width = 19, height = 14 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 19 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13 4.5L9.5 4.5L9.5 6.5L18.5 6.5L18.5 4.5L15 4.5L15 1L13 1L13 4.5Z'
        fill='#414859'
      />
      <path d='M13 14L13 6.5H15V14H13Z' fill='#414859' />
      <path d='M6 2H10V0H0V2H4V5H6V2Z' fill='#414859' />
      <path d='M4 14V5H6V14H4Z' fill='#414859' />
    </svg>
  );
}
