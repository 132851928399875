import SideNavigation from '../../components/SideNavigation';
import { Prompt, useParams } from 'react-router-dom';
import useFeatheryRedux from '../../redux';
import { useAppSelector } from '../../hooks';
import { useEffect, useState } from 'react';
import { PUBLISH_STATUS } from '../../redux/utils';
import { objectApply } from '../../utils/core';
import { defaultCollaborator } from '../../components/Modals/UserCollaborationModal/CollaboratorConfig';
import FormNavigation from '../../components/NavBar/FormNavigation';
import PropertiesIcon from '../../components/Icons/system/settings/PropertiesIcon';
import UserTrackingIcon from '../../components/Icons/system/settings/UserTrackingIcon';
import DeleteIcon from '../../components/Icons/system/settings/DeleteIcon';
import FormBehaviorIcon from '../../components/Icons/system/settings/FormBehaviorIcon';
import PDFSubmissionSection from './sections/PDFSubmissionSection';
import SEOSection from './sections/SEOSection';
import InternationalSection from './sections/InternationalSection';
import InternationalSupportIcon from '../../components/Icons/system/settings/InternationalSupportIcon';
import DataTrackingIcon from '../../components/Icons/system/settings/DataTrackingIcon';
import ReportingSection from './sections/ReportingSection';
import DeleteSection from './sections/DeleteSection';
import DataTrackingSection from './sections/DataTrackingSection';
import UserTrackingSection from './sections/UserTrackingSection';
import FormPropertiesSection from './sections/FormPropertiesSection';
import FormBehaviorSection from './sections/FormBehaviorSection';
import CollaborationSection from './sections/CollaborationSection';
import FormFillPluginSection from './sections/FormFillPluginSection';
import { PublishButton } from '../../components/Core/Button';
import PromotionSection from './sections/PromotionSection';
import { PromoteIcon } from '../../components/Icons';

const FormSettingsPage = () => {
  const { formId } = useParams<{ formId: string }>();
  const {
    editAccountPanelData,
    editPanel,
    getPanelThemeAssetUse,
    getTranslations,
    editTranslations,
    toasts: { addInfoToast, addErrorToast }
  } = useFeatheryRedux();
  const panel = useAppSelector((state) => state.panels.panels[formId]);

  const [curWeeklyReport, setCurWeeklyReport] = useState(
    panel?.account.weekly_report
  );

  const reduxTranslations = useAppSelector(
    (state) => state.integrations.translations
  );
  const [translations, setTranslations] =
    useState<Record<string, Record<string, string>>>(reduxTranslations);
  const [updatedTranslations, setUpdatedTranslations] = useState<
    Record<string, boolean>
  >({});
  useEffect(() => setTranslations(reduxTranslations), [reduxTranslations]);

  const [formData, setFormData] = useState<Record<string, any>>({
    assetSwaps: {}
  });
  const updateFormData = (newData: Record<string, any>) => {
    setFormData({ ...formData, ...newData });
    setPublishStatus(PUBLISH_STATUS.ACTIVE);
  };
  const [publishStatus, setPublishStatus] = useState(PUBLISH_STATUS.FULFILLED);

  useEffect(() => {
    if (panel) {
      // collaboration props
      let formTemplate = panel?.collaborator_template;
      if (!formTemplate || formTemplate.length === 0)
        formTemplate = [defaultCollaborator()];
      const collaborationProps = {
        collaboration_enabled: panel?.collaboration_enabled,
        collaborator_template: formTemplate,
        collaborator_ordered: panel?.collaborator_ordered
      };

      setFormData((formData) => ({
        ...formData,
        ...panel,
        ...collaborationProps
      }));
      getTranslations({ panelId: panel.id });
    }
  }, [panel?.id]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const { key, assetSwaps, redirect_url: redirectUrl } = formData;
    if (key.trim().length < 1) {
      addErrorToast({ title: 'Please enter a name for your form' });
      return;
    }

    let formattedUrl: string | null = null;
    if (redirectUrl) {
      formattedUrl = redirectUrl.trim();
      const missingProtocol = !['https://', 'http://'].some((protocol) =>
        (formattedUrl as string).startsWith(protocol)
      );
      if (missingProtocol) formattedUrl = 'https://' + formattedUrl;
    }
    const data = { redirect_url: formattedUrl, asset_swaps: assetSwaps };
    setPublishStatus(PUBLISH_STATUS.LOADING);
    try {
      if (curWeeklyReport !== panel.account.weekly_report) {
        await editAccountPanelData({
          panelId: panel.id,
          weekly_report: curWeeklyReport
        });
      }

      await editPanel({
        panelId: panel.id,
        ...objectApply(formData, data)
      }).then(
        () =>
          Object.keys(updatedTranslations).length > 0 &&
          editTranslations({ panelId: panel.id, translations })
      );
      setPublishStatus(PUBLISH_STATUS.FULFILLED);
      setUpdatedTranslations({});
      await getPanelThemeAssetUse();
      addInfoToast('Your settings have been saved.');
    } catch (error: any) {
      addErrorToast({ title: error.message });
      setPublishStatus(PUBLISH_STATUS.ACTIVE);
    }
  };

  const handleValueChange = (key: any) => (val: any) => {
    if (formData[key] !== val) updateFormData({ [key]: val });
  };

  const handleBlockedNavigation = (nextLocation: any) => {
    const validSettingsPathRegex = new RegExp(`^/forms/${formId}/settings/?$`);
    if (validSettingsPathRegex.test(nextLocation.pathname)) return true;
    return 'You are about to leave without saving your settings. Are you sure you want to continue?';
  };

  return (
    <div className='form-settings-page'>
      <Prompt
        when={publishStatus === PUBLISH_STATUS.ACTIVE}
        message={handleBlockedNavigation}
      />
      <FormNavigation
        activeItem='settings'
        submitButton={
          <PublishButton
            status={publishStatus}
            onPublish={handleSubmit}
            saveFlag
          />
        }
      />
      <div className='settings-page'>
        <SideNavigation title='Settings'>
          <FormPropertiesSection
            title='Form Properties'
            icon={PropertiesIcon}
            formData={formData}
            handleValueChange={handleValueChange}
            updateFormData={updateFormData}
          />
          <FormBehaviorSection
            title='Form Behavior'
            icon={FormBehaviorIcon}
            formData={formData}
            handleValueChange={handleValueChange}
          />
          <UserTrackingSection
            title='User Tracking'
            icon={UserTrackingIcon}
            formData={formData}
            handleValueChange={handleValueChange}
          />
          <DataTrackingSection
            title='Data Tracking'
            icon={FormBehaviorIcon}
            formData={formData}
            handleValueChange={handleValueChange}
          />
          <CollaborationSection
            title='Collaboration'
            icon={UserTrackingIcon}
            formData={formData}
            handleValueChange={handleValueChange}
          />
          <SEOSection
            title='SEO'
            icon={DataTrackingIcon}
            formData={formData}
            handleValueChange={handleValueChange}
          />
          <ReportingSection
            title='Reporting'
            icon={PropertiesIcon}
            curWeeklyReport={curWeeklyReport}
            setCurWeeklyReport={setCurWeeklyReport}
            setPublishStatus={setPublishStatus}
          />
          <FormFillPluginSection
            title='Form Fill Plugin'
            icon={FormBehaviorIcon}
            formData={formData}
            handleValueChange={handleValueChange}
          />
          <InternationalSection
            title='International Support'
            icon={InternationalSupportIcon}
            formData={formData}
            handleValueChange={handleValueChange}
            setPublishStatus={setPublishStatus}
            translations={translations}
            setTranslations={setTranslations}
            updatedTranslations={updatedTranslations}
            setUpdatedTranslations={setUpdatedTranslations}
          />
          <PDFSubmissionSection
            title='Submission PDF'
            icon={PropertiesIcon}
            formData={formData}
            updateFormData={updateFormData}
          />
          <PromotionSection
            title='Form Promotion'
            icon={
              <div
                style={{
                  display: 'flex',
                  width: 24,
                  height: 24,
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: 8
                }}
              >
                <PromoteIcon style={{ marginRight: 0 }} />
              </div>
            }
          />
          <DeleteSection title='Delete' icon={DeleteIcon} />
        </SideNavigation>
      </div>
    </div>
  );
};

export default FormSettingsPage;
