export type DataItem = {
  id: string;
  value: any;
};

export type FieldId = {
  id: string;
  name: string;
};

export const kfnColumns = [
  {
    key: 'account_type',
    name: 'Account Type',
    noSort: true,
    renderCell: ({ account_type }: any) => {
      return <p>{account_type}</p>;
    }
  },
  {
    key: 'vendor',
    name: 'Vendor',
    noSort: true,
    renderCell: ({ vendor }: any) => {
      return <p>{vendor}</p>;
    }
  },
  {
    key: 'registration_type',
    name: 'Registration Type',
    noSort: true,
    renderCell: ({ registration_type }: any) => {
      return <p>{registration_type}</p>;
    }
  },
  {
    key: 'account_number',
    name: 'Account #',
    noSort: true,
    renderCell: ({ account_number }: any) => {
      return <p>{account_number}</p>;
    }
  }
];

export function formatKFNData(data: DataItem[], fieldIds: FieldId[]): any[] {
  // Filter data based on fieldIds
  const filteredData = data.filter((item) =>
    fieldIds.some((f) => f.id === item.id)
  );

  // Check if all ids are found, return empty array otherwise
  if (filteredData.length !== fieldIds.length) {
    return [];
  }

  // Determine the length of the longest array value
  let maxLength = 1;
  filteredData.forEach((item) => {
    if (Array.isArray(item.value) && item.value.length > maxLength) {
      maxLength = item.value.length;
    }
  });

  // Construct new list with array values distributed
  const result = [];
  for (let i = 0; i < maxLength; i++) {
    const newItem: any = {};
    fieldIds.forEach((field) => {
      const dataItem = filteredData.find((item) => item.id === field.id);
      if (dataItem) {
        newItem[field.name] = Array.isArray(dataItem.value)
          ? dataItem.value[i] && dataItem.value[i].props
            ? dataItem.value[i].props.children
            : null
          : dataItem.value;
      }
    });
    result.push(newItem);
  }

  return result;
}

export const kfnFieldIds: FieldId[] = [
  { id: '7ef3f4ba-609a-4c07-87d1-d64e938cf77a', name: 'vendor' },
  { id: '06364504-c894-45d6-8c50-5c085c105f3a', name: 'account_type' },
  { id: '1a00f469-96af-429a-8bd6-5ede3afcbbf4', name: 'registration_type' },
  { id: '853d1691-f00c-4a94-8316-17627dad7750', name: 'account_number' }
];
