import {
  Cell,
  fit,
  Viewport
} from '../components/RenderingEngine/GridInGrid/engine';
import { getUndoRedoPayload } from '../utils/step';
import { useGig } from '../context/Gig';
import useElementRefCleanup from '../utils/useElementRefCleanup';
import useFeatheryRedux from '../redux';
import { getModalPayload } from '../components/RenderingEngine/utils';
import { getNodeLabel } from '../components/RenderingEngine/utils/gig';
import { useAppSelector } from './index';

export const useClipboard = () => {
  const { gig, node, workingSteps, activeStepId } = useGig();

  const {
    toasts: { addToast, addErrorToast }
  } = useFeatheryRedux();
  const servars = useAppSelector((state) => state.formBuilder.servars);
  const theme = useAppSelector((state) => state.formBuilder.theme);
  const gigClipboard = useAppSelector((state) => state.clipboard.gigClipboard);
  const isDragging = useAppSelector((state) => state.formBuilder.isDragging);
  const { fixupElementsInStep } = useElementRefCleanup();

  const fields = [...Object.values(servars)];

  const {
    formBuilder: {
      setPanelDataWithUndoRedo,
      wipeFocus,
      focusElement,
      gigSetPosition
    },
    clipboard: { setGigClipboard }
  } = useFeatheryRedux();

  const copy = (_node?: Cell) => {
    if (isDragging) return false;
    const targetNode = _node ?? node;

    if (!targetNode) return false;
    if (targetNode.isUnlinked() || targetNode.isRoot()) {
      addErrorToast({
        title: 'Nothing to copy.',
        body: 'Select a container or element to copy it.'
      });

      return false;
    }

    const data: any = {
      theme: theme?.id ?? null
    };

    data.node = targetNode.copy({ unique: true });

    setGigClipboard(data);
    addToast({
      text: `Copied ${getNodeLabel(targetNode).toLowerCase()}.`
    });

    return true;
  };

  const canPaste = (_node?: Cell, _theme?: any) => {
    if (isDragging) return false;

    const targetNode = _node ?? node;

    if (!targetNode || targetNode.isUnlinked()) return false;
    if (targetNode.tree && targetNode.tree.viewportName !== Viewport.Desktop)
      return false;
    if (!gigClipboard) return false;

    const { theme: clipboardTheme } = gigClipboard;
    const targetTheme = _theme ?? clipboardTheme;
    return !(targetTheme && targetTheme !== theme.id);
  };

  const canPasteWithoutCellStyles = (_node?: Cell, _theme?: any) => {
    const targetNode = _node ?? node;

    return canPaste(targetNode, _theme) && !targetNode.isElement;
  };

  const paste = (withCellStyles = true, _node?: Cell, _theme?: any) => {
    if (isDragging) return false;

    let targetNode = _node ?? node;

    if (!targetNode || targetNode.isUnlinked()) return false;

    if (targetNode?.tree?.viewportName !== Viewport.Desktop) {
      addErrorToast({
        title: "You can't paste when editing mobile styles.",
        body: 'To paste, return to Desktop view.'
      });
      return false;
    }

    if (!gigClipboard) {
      addErrorToast({
        title: 'Nothing to paste.',
        body: 'Ensure a container or element is copied before attempting to paste.'
      });
      return false;
    }

    const { node: clipboardNode, theme: clipboardTheme } = gigClipboard;
    const targetTheme = _theme ?? clipboardTheme;

    if (targetTheme && targetTheme !== theme.id) {
      addErrorToast({
        title: "You can't paste into forms with a different theme.",
        body: 'Make sure the copied container or element is part of the same theme.'
      });
      return false;
    }

    const isFit = targetNode.width === fit && targetNode.height === fit;
    const hasSameDimensions =
      targetNode.width === clipboardNode.width &&
      targetNode.height === clipboardNode.height;

    if (!targetNode.isEmpty) {
      if (targetNode.isElement) {
        targetNode = targetNode.addSibling(undefined, false) as Cell;
      } else if (
        targetNode.id === clipboardNode.id ||
        targetNode.toGenericHash() === clipboardNode.toGenericHash()
      ) {
        // If pasting a container on the original container, add the container as a sibling rather than inside the container
        // Note: It's safe to assume that the target node will always have a parent due to not being able to copy a root node
        targetNode = targetNode.parent?.add(undefined, {
          after: targetNode.uuid
        }) as Cell;
      } else {
        // Paste the container inside the target container
        targetNode = targetNode
          ?.getLastChild()
          ?.addSibling(undefined, false) as Cell;
      }
    } else if (targetNode.hasStyles(true) || (!hasSameDimensions && !isFit)) {
      targetNode = targetNode.add() as Cell; // If the target node is empty and has styles, paste into it
    }

    // If the target node descends from a repeated container, then the pasted container
    // cannot be repeated.
    const clearProps: any[] = [];
    let repeatableRemoved = false;
    if (targetNode.hasRepeatingAncestor()) {
      clearProps.push('repeated');
      repeatableRemoved = clipboardNode.repeated;
    }

    let copiedIds = targetNode.consume(clipboardNode.copy({ unique: true }), {
      element: true,
      merge: false,
      withCellStyles,
      unique: true,
      servarFields: fields,
      clearProps
    });
    const copiedCells = targetNode
      .getDescendantCells()
      .reduce((acc: any, cell: any) => {
        acc[cell.id] = cell;
        return acc;
      }, {} as any);
    // add the cells to the copied ids so that they get their rules/props fixed up as well
    copiedIds = { ...copiedIds, ...copiedCells };

    let step = JSON.parse(JSON.stringify(gig?.toStep()));
    const oldSteps = JSON.parse(JSON.stringify(workingSteps));
    const newSteps = JSON.parse(JSON.stringify(workingSteps));

    // Copied containers contain elements that may have rules and properties that reference
    // other elements that are descendants of the container.  When the container is copied,
    // the rules and properties are also copied, but the references to the original elements
    // are not updated to point to the new elements yet.  This code fixes up the references to the
    // new elements that are part of the copy.
    step = fixupElementsInStep(
      step,
      Object.values(oldSteps),
      // Fixup the step elements, but only the elements that were duplicated as part of the copy
      copiedIds
    );
    // Handle https://feathery-forms.sentry.io/issues/4359410448
    step = JSON.parse(JSON.stringify(step));

    // Copy nav rules over if they exist
    Object.values(oldSteps).forEach((iterStep: any) => {
      iterStep.next_conditions.forEach((cond: any) => {
        if (cond.element_id in copiedIds && cond.next_step !== step.id) {
          const newCond = {
            ...cond,
            element_id: copiedIds[cond.element_id],
            previous_step: step.id
          };
          step.next_conditions.push(newCond);
          newSteps[newCond.next_step].previous_conditions.push({ ...newCond });
        }
      });
    });
    newSteps[activeStepId] = step;

    const undoRedoPayload = getUndoRedoPayload({
      elementType: 'cell',
      oldSteps,
      newSteps,
      stepId: activeStepId,
      changedStepKeys: [activeStepId]
    });

    setPanelDataWithUndoRedo(undoRedoPayload);

    if (targetNode.isElement) {
      const element = targetNode.element.getRaw();

      focusElement(
        getModalPayload({
          element: element,
          elementType: element._type
        })
      );
    } else {
      wipeFocus();
    }

    gigSetPosition(targetNode.position);

    const extraInfo = repeatableRemoved
      ? '  Repeatable turned off to avoid nesting.'
      : '';
    addToast({
      text: withCellStyles
        ? `Pasted ${getNodeLabel(clipboardNode).toLowerCase()}.  ${extraInfo}`
        : `Pasted container without styles.  ${extraInfo}`
    });

    return true;
  };

  return {
    copy,
    paste,
    canPaste,
    canPasteWithoutCellStyles,
    clipboard: gigClipboard?.node
  };
};
