import { useEffect } from 'react';
import { IS_PROD_FE } from '../api/utils';

const TEST_USER_EMAIL = 'support+test@feathery.io';
// test password must be supportFeathery1!

export default function useTestUser(stytchClient: any, setTestAuthId: any) {
  useEffect(() => {
    if (IS_PROD_FE || !stytchClient) return;
    const queryParams = new URLSearchParams(window.location.search);
    const password = queryParams.get('featheryTestPassword');
    const browser = queryParams.get('browser');
    if (!password) return;
    let email = TEST_USER_EMAIL;
    if (browser) email = `support+${browser}@feathery.io`;

    stytchClient.passwords
      .authenticate({
        email,
        password,
        session_duration_minutes: 10
      })
      .then((session: any) => setTestAuthId(session.user_id));
  }, [stytchClient]);
}
