import Dialog from '../../../../components/Dialog';
import {
  CredentialInput,
  RolloutConnectProvider
} from '@rollout/connect-react';
import { ROLLOUT_BASE_URL } from '../utils';
import { useAppSelector } from '../../../../hooks';

const AccountSwitchModal = ({ show, close, switchAccount, appKey }: any) => {
  const rolloutToken = useAppSelector(
    (state) => state.integrations.rolloutToken
  );

  return (
    <Dialog isOpen={show} onClose={() => close()} title='Choose New Account'>
      <div className='switch-property-modal'>
        <div className='switch-property-container'>
          <RolloutConnectProvider
            token={rolloutToken}
            apiBaseUrl={ROLLOUT_BASE_URL}
          >
            <CredentialInput
              appKey={appKey}
              onCredentialSelected={(newCredentialKey) => {
                if (newCredentialKey) {
                  switchAccount(newCredentialKey);
                  close();
                }
              }}
            />
          </RolloutConnectProvider>
          <span className='switch-property-text'>
            * This may error if your new account has fewer permissions or
            different fields than your current account.
          </span>
        </div>
      </div>
    </Dialog>
  );
};

export default AccountSwitchModal;
