import useCanvasRefs from './useCanvasRefs';
import React, { useEffect, useRef, useState } from 'react';
import {
  elementHasClass,
  frameThrottle,
  getPositionOffset,
  getScrollOffset,
  ignoreDrawControls,
  point,
  throttle,
  throttledResizeObserver
} from './utils';
import { CanvasElement } from './CanvasRefs';
import { useGig } from '../../../context/Gig';
import useFeatheryRedux from '../../../redux';
import { Cell } from '../GridInGrid/engine';
import { useAppSelector } from '../../../hooks';
import { getModalPayloadFromNode } from '../utils';
import useViewport from '../../../hooks/useViewport';
import {
  bundleOverlappingControls,
  controlSelectionHelper,
  disableTextEditControl,
  getClosestSide,
  getControlsFromCanvasElements,
  getSideProximityLimit,
  handleTextControl,
  hasMenuOpen,
  isPointInCanvas,
  lockControlLayer,
  lockControlLayerWhenNotOverSide,
  recycleControlLayerDivs,
  renderContainerControl,
  renderSideControl,
  setControlOverflow,
  setHoverState,
  styleControls
} from './utils/drawControls';
import PortalHelper from './PortalHelper';
import ControlLabel from './ControlLabel';
import ControlLayerDragDrop from './ControlLayerDragDrop';
import PlusMenu from './PlusMenu';
import CellControlMenu from '../GridInGrid/components/CellControlMenu';
import testIds from '../../../utils/testIds';
import styles from './styles.module.scss';
import {
  editorCanvasContainerId,
  editorCanvasId
} from '../RenderedStepContainer';
import classNames from 'classnames';
import { BoxSpacingDisplay } from '../../Core/BoxSpacingInput';

const primaryColor = styles.primaryColor;
const darkerPrimaryColor = styles.darkerPrimaryColor;

export const controlLayerId = 'control-layer';
export const controlLayerContainerId = 'control-layer-container';

export interface ControlReference {
  /** The id of the control. */
  id: string;
  /** The control element itself. */
  el: HTMLElement;
  /** The canvas element that is being controlled. */
  canvasReference: CanvasElement;
  /** Flag indicating if the control is overlapping another control. */
  overlapped?: boolean;
  /** References to any overlapping controls. */
  overlappingControls?: ControlReference[];
  overflowsCanvas?: boolean;
}

interface LinkedControlReference {
  id: string;
  el: HTMLElement;
  controlReference: ControlReference;
}

export interface SideControlReference {
  id: string;
  side: string;
  start: point;
  end: point;
  distance: number;
  el?: HTMLElement;
  canvasReference: CanvasElement;
}

export type ControlReferenceMap = { [id: string]: ControlReference };
export type ControlState = {
  sideControl?: SideControlReference;
  insideControl?: ControlReference;
  selectedControl?: ControlReference;
  textEditControl?: LinkedControlReference;
  isEditingText?: boolean;
  hoverState: HoverState;
};

export type HoverState = {
  /** Flag indicating whether the cursor is hovering over a line control. */
  side?: boolean;
  label?: boolean;
  textEdit?: boolean;
  ignoreDrawControls?: boolean;
};

type ControlLayerDetails = {
  /** The node that is being dragged. */
  draggingNode: Cell | null;
  /** The current state of the control layer. */
  controlState: ControlState | null;
  /** The cursor details from the current control draw. */
  currentCursorDetails: CursorDetails;
  /** The cursor details from the previous control draw. */
  previousCursorDetails: CursorDetails | null;
  /** The resize observer that is used to detect changes in the canvas size. */
  resizeObserver: ResizeObserver;
};

type CursorDetails = {
  /** The cursor position relative to the viewport. */
  clientCursor: point;
  /** The cursor position relative to the control layer. */
  controlLayerClientCursor: point;
  /** The scroll offset of the control layer. */
  controlLayerScrollOffset: point;
  /** Whether the cursor is inside the canvas. */
  isCursorInCanvas: boolean;
};

/**
 * The initial cursor detail values.
 */
const initialCursorDetails: CursorDetails = {
  clientCursor: { x: 0, y: 0 },
  controlLayerClientCursor: { x: 0, y: 0 },
  controlLayerScrollOffset: { x: 0, y: 0 },
  isCursorInCanvas: false
};

/**
 * The initial control state.
 */
const defaultControlState: ControlState = {
  hoverState: {},
  textEditControl: undefined,
  selectedControl: undefined,
  insideControl: undefined,
  isEditingText: false,
  sideControl: undefined
};

/**
 * This is a global variable that is used to store the state of the
 * @note Bypasses typical state management because the control layer is a performance bottleneck by nature.
 */
export const ControlLayerDetails: ControlLayerDetails = {
  draggingNode: null,
  controlState: defaultControlState,
  currentCursorDetails: initialCursorDetails,
  previousCursorDetails: null,
  resizeObserver: new ResizeObserver(() => {})
};

/**
 * The ControlLayer is the surface area for cursor detection, drag & drop, and is where the controls are drawn.
 * @component
 * @returns {React.ReactElement}
 * @see https://app.gitbook.com/o/xTWh7AOZrHgundNdxhku/s/cT7QzBDXYrRp9gHndc8S/engineering/frontend/frontend-architecture/control-layer
 */
const ControlLayer = () => {
  const { gig, node: selectedNode, gigPosition } = useGig();
  const { viewport, isMobile } = useViewport();
  const [controls, setControls] = useState<ControlState>(defaultControlState);
  const [canvasRect, setCanvasRect] = useState<DOMRect>();
  const controlRef = useRef<ControlState>();
  const selectedNodeRef = useRef<Cell>();
  const gigPositionRef = useRef<any>();
  const isDraggingRef = useRef<boolean>();
  const isMobileRef = useRef<boolean>();
  const [isPlusMenuOpen, setIsPlusMenuOpen] = useState(false);
  selectedNodeRef.current = selectedNode;

  const onPlusMenuToggle = (isOpen: boolean) => {
    setIsPlusMenuOpen(isOpen);
  };

  const getSelectedNode = (): Cell | undefined => selectedNodeRef.current;

  const insideControl = controls.insideControl;

  const { formBuilder } = useFeatheryRedux();

  const { gigSetPosition, focusElement } = formBuilder;

  const { getDivs, update, dispatch } = useCanvasRefs();

  const isDragging = useAppSelector(
    ({ formBuilder }) => formBuilder.isDragging
  );

  useEffect(() => {
    const insideNode = insideControl?.canvasReference?.node;
    if (insideNode && isDraggingRef.current) {
      dispatch(insideNode, {
        backgroundColor: 'rgba(0, 0, 0, 0.08)'
      });
    } else {
      dispatch('', {
        backgroundColor: 'transparent'
      });
    }
  }, [insideControl]);

  useEffect(() => {
    isDraggingRef.current = isDragging;
    forceDrawControls();
  }, [isDragging]);

  useEffect(() => {
    gigPositionRef.current = gigPosition;
    forceDrawControls();
  }, [gigPosition]);

  useEffect(() => {
    isMobileRef.current = isMobile;
  }, [isMobile]);

  const drawControls = (
    CursorDetails: CursorDetails | null,
    force?: boolean
  ) => {
    if (hasMenuOpen() && isDraggingRef.current === false && !force) {
      return;
    }

    let clientCursor: any = null;
    let controlLayerClientCursor: any = null;
    let controlLayerScrollOffset: any = null;

    if (CursorDetails) {
      clientCursor = CursorDetails.clientCursor;
      controlLayerClientCursor = CursorDetails.controlLayerClientCursor;
      controlLayerScrollOffset = CursorDetails.controlLayerScrollOffset;
    }

    const nextControls = (currentControlState: ControlState) => {
      const nextControls = getNextControlState(
        currentControlState,
        controlLayerClientCursor,
        controlLayerScrollOffset,
        clientCursor,
        force
      );

      ControlLayerDetails.controlState = nextControls;
      controlRef.current = nextControls;

      return nextControls;
    };

    return setControls(nextControls);
  };

  const getNextControlState = (
    currentControlState: ControlState,
    controlLayerClientCursor: point | null | undefined,
    controlLayerScrollOffset?: point,
    clientCursor?: point,
    force?: boolean
  ): ControlState => {
    const nextControlState: ControlState = {
      hoverState: currentControlState.hoverState
    };

    // Get scroll offset
    controlLayerScrollOffset = getScrollOffset(controlLayerId);

    // Get the cursor and if it is not in the canvas set it to 0,0
    const cursorInCanvas =
      ControlLayerDetails.currentCursorDetails.isCursorInCanvas;
    if (!cursorInCanvas) controlLayerClientCursor = { x: 0, y: 0 };

    // Use the last cursor, clientCursor and scrollOffset is none is provided
    if (
      controlLayerClientCursor === null ||
      controlLayerClientCursor === undefined
    ) {
      if (!ControlLayerDetails.previousCursorDetails)
        return defaultControlState;
      controlLayerClientCursor =
        ControlLayerDetails.previousCursorDetails.controlLayerClientCursor;
      controlLayerScrollOffset =
        ControlLayerDetails.previousCursorDetails.controlLayerScrollOffset;
      clientCursor = ControlLayerDetails.previousCursorDetails.clientCursor;
    } else {
      ControlLayerDetails.previousCursorDetails = {
        clientCursor: clientCursor || { x: 0, y: 0 },
        controlLayerScrollOffset: controlLayerScrollOffset || { x: 0, y: 0 },
        controlLayerClientCursor: controlLayerClientCursor,
        isCursorInCanvas: isPointInCanvas(clientCursor || { x: 0, y: 0 })
      };
    }

    // Maintain text editing
    if (currentControlState.textEditControl) {
      if (currentControlState.textEditControl.el === document.activeElement) {
        nextControlState.isEditingText = true;
      }
      nextControlState.textEditControl = currentControlState.textEditControl;
    }

    // Set all hovering states
    if (clientCursor) setHoverState(nextControlState, clientCursor);

    const isOverRestrictedClassAndNotEditingText =
      nextControlState.hoverState.ignoreDrawControls &&
      !nextControlState.isEditingText &&
      !force;

    const canvasElements = getDivs();

    // Lock the control layer when hovering over a restricted control
    if (nextControlState.hoverState.ignoreDrawControls && !force) {
      setControlOverflow(currentControlState);
      if (currentControlState.textEditControl) {
        disableTextEditControl(
          currentControlState.textEditControl.el,
          ControlLayerDetails.resizeObserver
        );
      }
      if (!nextControlState.hoverState.side) {
        return lockControlLayerWhenNotOverSide(
          currentControlState,
          nextControlState
        );
      } else {
        return lockControlLayer(currentControlState);
      }
    }

    // Get controls from the canvas elements
    const { closeSides, insideDiv, selectedDiv } =
      getControlsFromCanvasElements(canvasElements, {
        cursor: controlLayerClientCursor,
        scrollOffset: controlLayerScrollOffset,
        isDragging: isDraggingRef.current,
        isOverRestrictedClassAndNotEditingText,
        gigPositionRef,
        selectedNode: getSelectedNode()
      });

    // Render the selected control
    if (selectedDiv) {
      const controlReference = renderContainerControl(selectedDiv, {
        scrollOffset: controlLayerScrollOffset,
        hideControlLayer: gigPositionRef?.current?.length === 0,
        color: primaryColor
      });
      if (controlReference) {
        nextControlState.selectedControl = controlReference;
      }
    }

    // Render the control the cursor is inside of
    if (insideDiv) {
      const controlReference = renderContainerControl(insideDiv, {
        scrollOffset: controlLayerScrollOffset,
        hideControlLayer: gigPositionRef?.current?.length === 0,
        color: primaryColor
      });
      if (controlReference) {
        nextControlState.insideControl = controlReference;
      }
    }

    // Render the line we're closest to
    if (closeSides.length > 0) {
      // Get the closest side
      const closestSide = getClosestSide(
        closeSides,
        nextControlState,
        getSideProximityLimit(nextControlState, !!isDraggingRef.current)
      );

      // Render the closest side
      if (
        closestSide &&
        !isOverRestrictedClassAndNotEditingText &&
        !isMobileRef.current
      ) {
        const sideControl = renderSideControl(closestSide, {
          scrollOffset: controlLayerScrollOffset,
          color: primaryColor
        });
        if (sideControl) nextControlState.sideControl = sideControl;
      }
    }

    // Handle text control
    handleTextControl(nextControlState, currentControlState, {
      resizeObserver: ControlLayerDetails.resizeObserver,
      isDragging: !!isDraggingRef.current
    });

    // Style controls
    styleControls(nextControlState, {
      primaryColor: primaryColor,
      secondaryColor: darkerPrimaryColor,
      isDragging: !!isDraggingRef.current
    });

    // Recycle controls
    recycleControlLayerDivs(nextControlState);

    // Ensure nextControls don't have an inside or side control if cursor is not in canvas
    if (!cursorInCanvas) {
      nextControlState.insideControl = undefined;
      nextControlState.sideControl = undefined;
    }

    // Get overlapping controls
    if (nextControlState.selectedControl && nextControlState.insideControl) {
      bundleOverlappingControls({
        insideControl: nextControlState.insideControl,
        selectedControl: nextControlState.selectedControl
      });
    }

    setControlOverflow(nextControlState);

    return nextControlState;
  };

  /**
   * Initiates an update to get new DOMRects for all canvas elements.
   * @todo Look into possible alternatives to this or a way to optimize getting these values.
   */
  const updateRects = () => {
    getDivs().forEach((div: CanvasElement) => {
      update(div);
    });
  };

  const forceDrawControls = () => {
    drawControls(null, true);
  };

  const clickAction = (event: MouseEvent) => {
    if (!ControlLayerDetails.currentCursorDetails.isCursorInCanvas)
      return false;
    const insideControl = controlRef.current?.insideControl;
    if (!insideControl) return false;

    const node = insideControl.canvasReference.node;
    gigSetPosition(
      Array.isArray(node.position) && node.position.length === 0
        ? 'root'
        : node.position
    );
    const payload = getModalPayloadFromNode(node);
    focusElement(payload);
    const doubleClick = event.detail === 2;
    if (doubleClick) {
      // focus on the contenteditable span inside the dom element of the node, if one exists
      if (node.id) {
        const element = document.getElementById(`span-${node.id}`);
        if (element) {
          // set timeout is necessary because of how browser handles focus events for contenteditable
          setTimeout(function () {
            // select all text
            const range = document.createRange();
            range.selectNodeContents(element);
            const sel = window.getSelection();
            sel?.removeAllRanges();
            sel?.addRange(range);
          }, 0);
        }
      }
    }
    forceDrawControls();
  };

  useEffect(() => {
    if (selectedNode.isRoot()) {
      drawControls(initialCursorDetails, true);
    }
  }, [selectedNode]);

  useEffect(() => {
    // Throttle the updateRects and drawControls functions to optimize performance
    const throttledUpdateRects = frameThrottle(updateRects);
    const throttledDrawControls = frameThrottle(drawControls);

    const interval = setInterval(throttledUpdateRects, 1);

    const onMouseMove = throttle((e: MouseEvent) => {
      ControlLayerDetails.currentCursorDetails =
        getCursorDetailsFromMouseEvent(e);

      throttledDrawControls(ControlLayerDetails.currentCursorDetails);
    }, 10);

    const getCursorDetailsFromMouseEvent = (e: MouseEvent): CursorDetails => {
      const controlLayerScrollOffset = getScrollOffset(controlLayerId);
      const clientCursor = { x: e.clientX, y: e.clientY };
      const controlLayerClientCursor = {
        x: clientCursor.x + controlLayerScrollOffset.x,
        y: clientCursor.y + controlLayerScrollOffset.y
      };

      return {
        clientCursor,
        controlLayerClientCursor,
        controlLayerScrollOffset,
        isCursorInCanvas: isPointInCanvas(clientCursor)
      };
    };

    const onScroll = () => {
      throttledUpdateRects();
      ControlLayerDetails.currentCursorDetails.controlLayerScrollOffset =
        getScrollOffset(controlLayerId);
      throttledDrawControls(ControlLayerDetails.currentCursorDetails);
    };

    const onMouseDown = (e: MouseEvent) => {
      if (isPlusMenuOpen) return;
      onMouseMove(e);
      if (e.button === 0 && e.target) {
        const target = e.target as HTMLElement;
        if (!elementHasClass(target, ignoreDrawControls) && !hasMenuOpen()) {
          clickAction(e);
        }
      }
    };

    const controlDiv = document.getElementById(controlLayerContainerId);
    const canvasDiv = document.getElementById(editorCanvasId)?.children[0];
    const editorCanvasContainer = document.getElementById(
      editorCanvasContainerId
    );

    const resizeObserver = throttledResizeObserver(() => {
      if (canvasDiv) setCanvasRect(canvasDiv.getBoundingClientRect());
      updateRects();
      forceDrawControls();
    });

    ControlLayerDetails.resizeObserver = resizeObserver;

    if (controlDiv) resizeObserver.observe(controlDiv);
    if (canvasDiv) resizeObserver.observe(canvasDiv);

    document.addEventListener('mousedown', onMouseDown);
    document.addEventListener('mousemove', onMouseMove);
    if (editorCanvasContainer)
      editorCanvasContainer.addEventListener('scroll', onScroll);
    return () => {
      clearInterval(interval);
      if (controlDiv) resizeObserver.unobserve(controlDiv);
      if (canvasDiv) resizeObserver.unobserve(canvasDiv);
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mousedown', onMouseDown);
      if (editorCanvasContainer)
        editorCanvasContainer.removeEventListener('scroll', onScroll);
    };
  }, [gig]);

  const controlsForRender = [controls.selectedControl];
  if (controls?.insideControl?.id !== controls?.selectedControl?.id) {
    controlsForRender.push(controls.insideControl);
  }

  const sideControl = isMobile ? undefined : controls.sideControl;
  const centerPlus =
    !isMobile && controls.insideControl ? [controls.insideControl] : [];

  return (
    <div id={controlLayerContainerId} className={styles.controlLayerContainer}>
      {centerPlus.map((control) => {
        const node = control.canvasReference.node;
        const rect = control.canvasReference.rect;

        if (sideControl) return null;
        if (!node.isEmpty) return null;
        if (rect.width <= 75 || rect.height <= 75) return null;

        const isSelectedControl = control?.id === controls.selectedControl?.id;
        if (!isSelectedControl) return null;

        return (
          <>
            <PortalHelper
              node={control.canvasReference.node}
              key={control.id}
              element={control.el}
            >
              <div
                className={classNames(
                  ignoreDrawControls,
                  styles.addControlWrapper
                )}
              >
                <PlusMenu
                  control={control}
                  buttonColor={primaryColor}
                  onToggle={onPlusMenuToggle}
                />
              </div>
            </PortalHelper>
          </>
        );
      })}
      {sideControl && (
        <PortalHelper
          node={sideControl.canvasReference.node}
          key={sideControl.id}
          element={sideControl.el}
        >
          <div
            className={classNames(ignoreDrawControls, styles.addControlWrapper)}
          >
            <PlusMenu
              control={sideControl}
              buttonColor={primaryColor}
              onToggle={onPlusMenuToggle}
            />
          </div>
        </PortalHelper>
      )}
      {controlsForRender.map((control) => {
        if (!control) return null;
        const isSelectedControl = control?.id === controls.selectedControl?.id;
        const isInsideControl = control?.id === controls.insideControl?.id;
        const isActiveControl = isDraggingRef.current
          ? isInsideControl
          : isSelectedControl;
        const hideLabelToggle = isDraggingRef.current
          ? !isInsideControl
          : isInsideControl && !isSelectedControl;

        const componentKey = `${control.id}-${viewport}`;

        return (
          <PortalHelper
            node={control.canvasReference.node}
            key={componentKey}
            element={control.el}
          >
            {isSelectedControl && <BoxSpacingDisplay control={control} />}
            <ControlLabel
              key={controls.insideControl?.id}
              gigSetPosition={gigSetPosition}
              focusElement={focusElement}
              drawControls={drawControls}
              control={control}
              textColor={'white'}
              isSelected={isActiveControl}
              isDragging={!!isDraggingRef?.current}
              backgroundColor={primaryColor}
              hideToggle={hideLabelToggle}
            />
          </PortalHelper>
        );
      })}
      <ControlContainerMemo />
      <ControlLayerDragDrop
        insideControl={controls.insideControl}
        canvasRect={canvasRect}
        onDrop={forceDrawControls}
      >
        {(dragRef, dragStyle, dragId) => {
          const contextMenuNode =
            controlSelectionHelper(insideControl)?.canvasReference.node;

          // The CellControlMenu needs to also take the dragRef.
          // A separate "drag layer" would prevent the context menu from firing.
          return (
            <CellControlMenu
              enabled
              dragRef={isPlusMenuOpen ? null : dragRef}
              style={dragStyle}
              id={dragId}
              className={ignoreDrawControls}
              node={contextMenuNode}
            />
          );
        }}
      </ControlLayerDragDrop>
    </div>
  );
};

/**
 * The ControlContainer is where the ControlLayer places all the controls.
 * @component
 * @private
 * @returns {React.ReactElement}
 */
const ControlContainer = () => {
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  const getOffset = () => {
    const offset = getPositionOffset(controlLayerId);
    setOffset(offset);
  };

  useEffect(() => {
    const controlEl = document.getElementById(editorCanvasContainerId);
    if (!controlEl) return;
    const resizeObserver = new ResizeObserver(getOffset);
    getOffset();
    resizeObserver.observe(controlEl);
    return () => resizeObserver.unobserve(controlEl);
  }, []);

  return (
    <div
      data-testid={testIds.controlLayer}
      id={controlLayerId}
      className={styles.controlContainer}
      style={{
        top: -offset.y,
        left: -offset.x
      }}
    />
  );
};

/**
 * Wraps the control container in a memo to help avoid unnecessary renders in the ControlLayer.
 * @component
 * @private
 * @returns {React.ReactElement}
 */
const ControlContainerMemo = React.memo(ControlContainer);

export default ControlLayer;
