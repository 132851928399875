function Asset() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.3565 13.5829L13.5829 17.3565C12.7185 18.2208 11.2815 18.2208 10.4171 17.3565L6.64354 13.5829C5.7792 12.7185 5.7792 11.2815 6.64354 10.4171L10.4171 6.64354C11.2815 5.7792 12.7185 5.7792 13.5829 6.64354L17.3565 10.4171C18.2208 11.2815 18.2208 12.7185 17.3565 13.5829Z'
        stroke='white'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export default Asset;
