import { ChangeEvent, FC, useMemo, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { useAppSelector } from '../../../../hooks';
import { INTEGRATIONS } from '../../../FormIntegrations';
import useIntegrations from '../../../FormIntegrations/useIntegrations';
import { produce } from 'immer';
import { useGlobalMouseDownToggle } from '../../../Core/util';
import { Button, Col, Row } from 'react-bootstrap';
import {
  CheckboxField,
  ContextMenu,
  DropdownField,
  InlineTooltip,
  NumberInput,
  PropertyLabel,
  TextField
} from '../../../Core';
import {
  ClearIcon,
  EditIcon,
  OpenOverflowIcon,
  TextFieldIcon
} from '../../../Icons';
import { isValidUrl } from '../../../../utils/validate';
import FieldSelectorWithModal from '../../../Modals/FieldSelectorWithModal';
import ProductSelectorWithModal from '../../../Modals/ProductSelectorWithModal';
import { ACTION_OPTIONS } from '../../utils';
import styles from './styles.module.scss';
import { Tooltip } from '../../../Core/Tooltip/Tooltip';
export interface PaymentProduct {
  product_id?: string;
  quantity_field?: { id: string; type: 'hidden' | 'servar' }; // resolved servar key of hidden field key
  fixed_quantity?: number;
}
export interface PaymentGroup {
  payment_group_id: string;
  products: PaymentProduct[];
}
interface Action {
  type: string;
  product_id?: string;
  quantity_field?: { id: string; type: 'hidden' | 'servar' }; // resolved servar key of hidden field key
  fixed_quantity?: number;
  add_to_quantity?: boolean; // used in addToCart
  success_url?: string;
  cancel_url?: string;
  clear_cart?: boolean;
  toggle?: boolean;
}

export interface PaymentProductContextMenuProps {
  actionData: Record<string, any>[];
  handleAllActionsChange: (newActions: any[]) => void;
}

export interface StripeActionSectionProps {
  index: number; // action index
  action: Action;
  handleActionChange: (
    index: number,
    newData: Record<string, any>,
    initAction?: boolean
  ) => void;
  labelData?: any;
}

interface StripeActionsReturnType {
  StripeActionSection: FC<StripeActionSectionProps>;
  PaymentProductContextMenu: FC<PaymentProductContextMenuProps>;
  purchaseableProducts: Record<string, Product>;
}

interface PricingTier {
  up_to: number | null;
  unit_amount?: number;
  flat_amount?: number;
}
type PRICE_TYPE = 'one_time' | 'recurring';
export interface Price {
  id: string;
  currency?: string;
  type: PRICE_TYPE;
  recurring_interval?: 'month' | 'year' | 'week' | 'day' | null | '';
  recurring_usage_type?: 'licensed' | 'metered';
  billing_scheme: 'per_unit' | 'tiered';
  unit_amount?: number;
  per_unit_units?: number;
  per_unit_rounding?: 'up' | 'down';
  tiers_mode?: 'graduated' | 'volume';
  tiers?: PricingTier[];
}
export interface Product {
  id: string;
  name: string;
  description: string;
  active: boolean;
  default_price: string | null;
  prices: Price[];
}

export const useStripeActions = () => {
  const { formId } = useParams<{ formId: string }>();
  const integrations = useIntegrations({
    types: [INTEGRATIONS.STRIPE],
    panelId: formId
  });
  const allTestProducts: Record<string, Product> =
    integrations[INTEGRATIONS.STRIPE]?.data.metadata.test
      ?.product_price_cache ?? {};
  const allLiveProducts: Record<string, Product> =
    integrations[INTEGRATIONS.STRIPE]?.data.metadata.live
      ?.product_price_cache ?? {};
  const allProducts = { ...allTestProducts, ...allLiveProducts };
  const activeStepId = useAppSelector(
    (state) => state.formBuilder.activeStepId
  );

  // (Stripe) product menu (delete, toggle dynamic/fixed quantity)
  const [productMenuPosition, setProductMenuPosition] = useState<{
    x?: number;
    y?: number;
  }>({});
  const productMenuRef = useRef<HTMLDivElement>(null);
  const [showProductMenu, setShowProductMenu] = useGlobalMouseDownToggle([
    productMenuRef
  ]);
  const [activeActionIndex, setActiveActionIndex] = useState(0);

  const PaymentProductContextMenu = useMemo(() => {
    const PaymentProductContextMenuComponent: FC<PaymentProductContextMenuProps> =
      ({ actionData, handleAllActionsChange }) => {
        function handleProductClear() {
          handleAllActionsChange(
            produce(actionData, (draft) => {
              draft[activeActionIndex].product_id = '';
              delete draft[activeActionIndex].quantity_field;
              delete draft[activeActionIndex].fixed_quantity;
              draft[activeActionIndex].toggle = false;
              draft[activeActionIndex].clear_cart = false;
            })
          );
        }

        function toggleProductQuantityEntry() {
          handleAllActionsChange(
            produce(actionData, (draft) => {
              if (draft[activeActionIndex].fixed_quantity === undefined) {
                delete draft[activeActionIndex].quantity_field;
                draft[activeActionIndex].fixed_quantity = 1;
              } else {
                delete draft[activeActionIndex].fixed_quantity;
                draft[activeActionIndex].quantity_field = {};
              }
            })
          );
        }
        const toggleIcon = ({
          width,
          height,
          color
        }: {
          width: number;
          height: number;
          color: string;
        }) =>
          // Determine what type the selected value is (string or field) and show the 'opposite' icon
          actionData[activeActionIndex]?.fixed_quantity === undefined ? (
            <EditIcon color={color} width={width} height={height} />
          ) : (
            <TextFieldIcon color={color} />
          );

        return (
          <ContextMenu
            ref={productMenuRef}
            show={showProductMenu}
            close={() => setProductMenuPosition({})}
            position={productMenuPosition as any}
            actions={[
              {
                onMouseDown: handleProductClear,
                title: 'Clear',
                Icon: ClearIcon
              },
              {
                onMouseDown: toggleProductQuantityEntry,
                title:
                  actionData[activeActionIndex]?.fixed_quantity === undefined
                    ? 'Custom value'
                    : 'Pull value from field',
                Icon: toggleIcon
              }
            ]}
          />
        );
      };
    return PaymentProductContextMenuComponent;
  }, [
    showProductMenu,
    productMenuPosition,
    setProductMenuPosition,
    activeActionIndex
  ]);

  const StripeActionSection = useMemo(() => {
    const StripeActionSectionComponent: FC<StripeActionSectionProps> = ({
      index,
      action,
      handleActionChange
    }) => {
      const isStripeCheckoutType = (checkoutType: 'custom' | 'stripe') =>
        (integrations[INTEGRATIONS.STRIPE]?.data.metadata.checkout_type ??
          'custom') === checkoutType;

      function handleProductChange(
        actionIndex: number,
        newProductData: Record<string, any>
      ) {
        handleActionChange(actionIndex, { ...action, ...newProductData });
      }
      const fixedQuantityInput = action.fixed_quantity !== undefined;
      const showProductAndQty =
        (action.type === ACTION_OPTIONS.REMOVE_PRODUCT_FROM_PURCHASE &&
          !action.clear_cart) ||
        action.type === ACTION_OPTIONS.SELECT_PRODUCT_TO_PURCHASE;

      return (
        <>
          <div className={styles.actionPropertiesGroup}>
            {action.type === ACTION_OPTIONS.REMOVE_PRODUCT_FROM_PURCHASE && (
              <Row className={styles.actionType}>
                <Col sm='3'>
                  <PropertyLabel
                    label='Remove'
                    className={styles.productLabel}
                  />
                </Col>
                <Col>
                  <DropdownField
                    placeholder='Select'
                    title={`Choose what to remove`}
                    selected={action.clear_cart ? 'all' : 'one'}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      handleProductChange(index, {
                        clear_cart: e.target.value === 'all'
                      });
                    }}
                    options={[
                      { display: 'All products', value: 'all' },
                      { display: 'One product', value: 'one' }
                    ]}
                  />
                </Col>
              </Row>
            )}
            {showProductAndQty && (
              <>
                <Row className={styles.noPadding}>
                  <Col sm='5'>
                    <PropertyLabel
                      className={styles.productLabel}
                      label='Stripe Product'
                    />
                  </Col>
                  <Col>
                    {/* Don't want an error message - just show red border */}
                    <ProductSelectorWithModal
                      title={`Choose a Stripe product`}
                      required
                      selectedId={action.product_id || ''}
                      onSelect={(selectedId: string) =>
                        handleProductChange(index, {
                          product_id: selectedId
                        })
                      }
                      error={!action.product_id}
                    />
                  </Col>
                </Row>
                {action.type === ACTION_OPTIONS.SELECT_PRODUCT_TO_PURCHASE && (
                  <Row className={styles.noPadding}>
                    <Col sm='5'>
                      <PropertyLabel label='Quantity' />
                    </Col>
                    <Col>
                      {fixedQuantityInput ? (
                        <NumberInput
                          min={action.add_to_quantity ? -9999999999 : 0}
                          value={action.fixed_quantity}
                          placeholder='Fixed Quantity'
                          onComplete={({ value }: any) =>
                            handleProductChange(index, {
                              fixed_quantity: value
                            })
                          }
                          triggerCleanUp
                        />
                      ) : (
                        <FieldSelectorWithModal
                          className={styles.fieldSelector}
                          selectId={action.quantity_field?.id}
                          selectType={action.quantity_field?.type}
                          placeholder='Select'
                          title='Field selector of a field to be used as the dynamic quantity to purchase of this product'
                          onSelect={(data: any) =>
                            handleProductChange(index, {
                              quantity_field: {
                                id: data.selectId,
                                type: data.selectType
                              }
                            })
                          }
                          currentStepId={activeStepId}
                          inFormBuilder
                          error={!action.quantity_field?.id}
                        />
                      )}
                      <OpenOverflowIcon
                        width={30}
                        height={30}
                        className={styles.overflowIcon}
                        onClick={(e: any) => {
                          e.preventDefault();
                          setProductMenuPosition({
                            x: e.pageX,
                            y: e.pageY
                          });
                          setShowProductMenu(true);
                          setActiveActionIndex(index);
                        }}
                      />
                    </Col>
                  </Row>
                )}
              </>
            )}
            {action.type === ACTION_OPTIONS.SELECT_PRODUCT_TO_PURCHASE && (
              <>
                <Row className={styles.noPadding}>
                  <Col sm='4' />
                  <Col>
                    <CheckboxField
                      checked={action.toggle}
                      onChange={(value) =>
                        handleProductChange(index, { toggle: value })
                      }
                      text={
                        <div className={styles.checkboxLabel}>
                          <PropertyLabel label='Click again to deselect' />
                        </div>
                      }
                    />
                  </Col>
                </Row>
                <Row className={styles.noPadding}>
                  <Col sm='4' />
                  <Col>
                    <CheckboxField
                      checked={!action.add_to_quantity}
                      onChange={(value) =>
                        handleProductChange(index, { add_to_quantity: !value })
                      }
                      text={
                        <>
                          <div className={styles.checkboxLabel}>
                            <PropertyLabel label='Replace quantity' />
                          </div>
                          <InlineTooltip text='Have the quantity replace the existing cart quantity instead of adding to it.' />
                        </>
                      }
                    />
                  </Col>
                </Row>
              </>
            )}
            {action.type === ACTION_OPTIONS.PURCHASE_PRODUCTS &&
              isStripeCheckoutType('stripe') && (
                <>
                  <div className={styles.paymentHeader}>
                    Stripe Checkout Redirect
                  </div>
                  <Row className={styles.noPadding}>
                    <Col sm='5' className={styles.noPaddingTop}>
                      <PropertyLabel label='Completed' />
                    </Col>
                    <Col className={styles.errorMsg}>
                      <TextField
                        required
                        title='After successful checkout, redirect user to this URL'
                        placeholder='Enter URL'
                        value={action.success_url || ''}
                        onComplete={(newUrl: string) =>
                          handleActionChange(index, { success_url: newUrl })
                        }
                        triggerCleanUp
                        errorMessage={
                          action.success_url && !isValidUrl(action.success_url)
                            ? 'Enter a valid URL'
                            : ''
                        }
                        error={
                          action.success_url && !isValidUrl(action.success_url)
                        }
                      />
                    </Col>
                  </Row>
                  <Row className={styles.noPadding}>
                    <Col sm='5' className={styles.noPaddingTop}>
                      <PropertyLabel label='Canceled' />
                    </Col>
                    <Col className={styles.errorMsg}>
                      <TextField
                        title='If the user chooses not to checkout, redirect user to this URL'
                        placeholder='Enter URL'
                        value={action.cancel_url || ''}
                        onComplete={(newUrl: string) =>
                          handleActionChange(index, { cancel_url: newUrl })
                        }
                        triggerCleanUp
                        errorMessage={
                          action.cancel_url && !isValidUrl(action.cancel_url)
                            ? 'Enter a valid URL'
                            : ''
                        }
                        error={
                          action.cancel_url && !isValidUrl(action.cancel_url)
                        }
                      />
                    </Col>
                  </Row>
                </>
              )}
            {action.type === ACTION_OPTIONS.PURCHASE_PRODUCTS &&
              isStripeCheckoutType('custom') && (
                <>
                  <div className={styles.paymentHeader}>Payment Collection</div>
                  <div className={styles.paymentCollection}>
                    <div className={styles.paymentCollectionDescription}>
                      via Feathery&apos;s Stripe
                      <a
                        className={styles.pmLink}
                        target='_blank'
                        href='https://docs.feathery.io/platform/building-a-form/elements/fields/payment-method'
                        rel='noreferrer'
                      >
                        Payment Method Field
                      </a>
                    </div>
                    <Tooltip content='Edit your Stripe integration settings'>
                      <Link to={`/forms/${formId}/integrations/`}>
                        <Button variant='outline'>
                          <EditIcon className={styles.editStripeIcon} />
                        </Button>
                      </Link>
                    </Tooltip>
                  </div>
                </>
              )}
          </div>
        </>
      );
    };
    return StripeActionSectionComponent;
  }, []);

  // Determine the set of purchaseable product (ids->product) found in SELECT_PRODUCT_TO_PURCHASE actions
  // on buttons and subgids on all working steps.
  const workingSteps = useAppSelector(
    (state) => state.formBuilder.workingSteps
  );

  const purchaseableProducts = useMemo(() => {
    const purchaseableProducts: Record<string, Product> = {};

    Object.values(workingSteps).forEach((step: any) => {
      [...step.buttons, ...step.subgrids].forEach((button: any) => {
        if (button.properties.actions) {
          button.properties.actions.forEach((action: any) => {
            if (
              action.type === ACTION_OPTIONS.SELECT_PRODUCT_TO_PURCHASE &&
              action.product_id
            ) {
              purchaseableProducts[action.product_id] =
                allProducts[action.product_id];
            }
          });
        }
      });
    });
    return { ...purchaseableProducts };
  }, [workingSteps]);

  return {
    StripeActionSection,
    PaymentProductContextMenu,
    purchaseableProducts
  } as StripeActionsReturnType;
};
