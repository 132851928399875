import produce from 'immer';
import { useAppSelector } from '.';
import {
  Cell,
  Viewport
} from '../components/RenderingEngine/GridInGrid/engine';
import { objectApply, objectPick, objectRemove } from '../utils/core';
import { getStepPropFromElementType, getUndoRedoPayload } from '../utils/step';
import useElementRefCleanup from '../utils/useElementRefCleanup';
import {
  filterToStyleOverridesOnly,
  updateThemeAsset,
  updateThemeElement
} from '../utils/themes';
import { useElementRenderData } from './useElementRenderData';
import { useGig } from '../context/Gig';
import { useSelectedCell } from './useSelectedCell';
import { FIELD_TYPES } from '../utils/elements';
import { repeatConfig } from '../utils/domOperations';
import { UNDO_TITLES, UNDO_TYPES } from '../utils/constants';
import { useMemo } from 'react';
import useFeatheryRedux from '../redux';
import { useParams } from 'react-router-dom';

export const useStepUpdater = (initialNode?: Cell) => {
  const { formId } = useParams<{ formId: string }>();
  const { gig, node, workingSteps, activeStepId } = useGig();
  const { remove: removeCell } = useSelectedCell();
  const activeStep = workingSteps[activeStepId];
  const theme = useAppSelector(({ formBuilder }: any) => formBuilder.theme);
  const servars = useAppSelector((state: any) => state.formBuilder.servars);
  const servarUsage = useAppSelector((state) => state.formBuilder.servarUsage);
  const workingLogicRules = useAppSelector(
    (s) => s.formBuilder.workingLogicRules
  );
  const selectedNode = node || initialNode;

  const {
    formBuilder: {
      setDetailError,
      setPanelDataWithUndoRedo,
      addThemeToUndoStack
    },
    toasts: { addInfoToast, addErrorToast }
  } = useFeatheryRedux();

  const {
    cleanupAllElementReferences,
    cleanupLogicRuleElementReferences,
    deleteElementById,
    fixupRuleOptionsReferences
  } = useElementRefCleanup();

  const { elementRenderData } = useElementRenderData(selectedNode);
  const elementStyles = elementRenderData?.styles;
  const elementType = selectedNode?._type;
  const elementId = selectedNode?.element?.id;
  const stepElementProp = getStepPropFromElementType(elementType);
  const element = activeStep[stepElementProp].find(
    (el: any) => el.id === elementId
  );

  const hiddenFieldKeys = useAppSelector(
    (state) =>
      (state as any).fields.hiddenFields.map((field: any) => field.key),
    (p, n) => JSON.stringify(p) === JSON.stringify(n)
  );

  const servarKeyIdMap = useMemo(() => {
    const keyIdMap = {};
    Object.values(servars ?? {}).forEach(
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      (servar) => (keyIdMap[(servar as any).key] = (servar as any).id)
    );
    return keyIdMap;
  }, [servars]);

  // The servar keys in this form (draft)
  const servarKeysInForm = useMemo(() => {
    const servarKeysInForm = new Set<string>();
    Object.values(workingSteps).forEach((step: any) => {
      step.servar_fields.forEach((field: any) => {
        const { key } = field.servar;
        if (key) servarKeysInForm.add(key);
      });
    });
    return servarKeysInForm;
  }, [workingSteps]);

  const validateElementOperation = (operation: any) => {
    const { propUpdate = {} } = operation;
    const servar = propUpdate.servar;
    const errors = [];

    if (FIELD_TYPES.includes(elementType)) {
      if ('servar' in propUpdate) {
        if ('key' in servar)
          if (servar.key.length < 1)
            errors.push('You need to enter an ID for this field');
          else if (servar.key.startsWith('feathery.'))
            errors.push(
              'Field IDs may not start with the reserved "feathery." prefix'
            );

        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        const id = servarKeyIdMap[servar.key];

        // Even if the servar is present in the PUBLISHED set of servars,
        // it is possible that the server is only in this published form
        // but has been deleted from the draft.  In this case we need to allow the
        // user to use the same key again.
        // NOTE: Changing the servars object derived from the BE to reflect draft
        // changes was considered to likely be a performance issue and could cause
        // unexpected server key uniqueness conflicts when drafts are rolled back, etc.
        const servarInUseSomewhere =
          (id in servarUsage &&
            servarUsage[id].some(({ panel_id }: any) => panel_id !== formId)) ||
          servarKeysInForm.has(servar.key);
        const servarKeyInUse = id !== servar.id && servarInUseSomewhere;

        if (servarKeyInUse) {
          errors.push('Field IDs need to be unique');
        } else if (hiddenFieldKeys.includes(servar.key)) {
          errors.push('A hidden field with this ID already exists');
        }

        const newField = objectApply(element, propUpdate);
        const repeatError = repeatConfig({
          ...activeStep,
          servar_fields: [newField]
        });

        if (!(newField as any).servar.repeated && repeatError) {
          errors.push(repeatError);
        }
      }
    }

    return errors;
  };

  // ************************************
  // This is a pretty important function for pretty much all operations in the DetailPanel in the context of the form builder.
  // It handles changes to the theme elements, theme assets, as well as elements on the form itself.
  // This includes updating/resetting properties or styles at any level in the theme, any asset, or any element.
  // Modify with care and test thoroughly.
  // ************************************
  const handleUpdates = async (operations: any) => {
    const stepElementProp = getStepPropFromElementType(elementType);
    let newTheme = theme;
    let newSteps = workingSteps;
    let themeUpdated = false;
    let stepUpdated = false;
    let cellUpdated = false;
    let changedStepKeys = new Set<string>();
    let changedLogicRuleKeys = new Set<string>();
    let newLogicRules = { ...workingLogicRules };

    // We get a list of operations when the user updates or reset properties or styles in the theme, assets, or elements
    // Iterate through each operation and update or reset the correct object
    // Each operation has metadata for specifying the object to change and what properties/styles to change
    for (const _operation of operations) {
      const { node: _node = null, viewport, ...operation } = _operation;
      // const node = _node || selectedNode;
      const styleKey =
        viewport === Viewport.Mobile ? 'mobile_styles' : 'styles';

      /* eslint-disable no-inner-declarations */
      function updatePropsHelper(propUpdate: any) {
        return (element: any) => objectApply(element, propUpdate);
      }
      function resetPropsHelper(propReset: any) {
        return (element: any) => {
          // Clear properties at the element and properties dict level
          propReset.forEach((key: any) => {
            delete element[key];
            delete element.properties[key];
          });
        };
      }
      function updateStylesHelper(styleUpdate: any, filterOverrides: any) {
        return (element: any) =>
          objectApply(element, {
            [styleKey]: filterOverrides
              ? filterToStyleOverridesOnly(styleUpdate, elementStyles)
              : styleUpdate
          });
      }
      function resetStylesHelper(styleReset: any) {
        return (element: any) => {
          element[styleKey] = objectRemove(element[styleKey], styleReset);
          return element;
        };
      }
      /* eslint-enable no-inner-declarations */

      if (operation.type === 'theme') {
        // If type is 'theme', this is a theme update/reset
        const { selector, styleUpdate, styleReset } = operation;
        themeUpdated = true;
        if (styleUpdate)
          newTheme = updateThemeElement(
            newTheme,
            selector,
            // @ts-expect-error TS(2554) FIXME: Expected 2 arguments, but got 1.
            updateStylesHelper(styleUpdate)
          );
        if (styleReset)
          newTheme = updateThemeElement(
            newTheme,
            selector,
            resetStylesHelper(styleReset)
          );
      } else if (operation.type === 'asset') {
        // If type is 'asset', this is an asset update/reset
        const { elementType, asset, propUpdate, styleUpdate, styleReset } =
          operation;
        themeUpdated = true;
        if (styleUpdate)
          newTheme = updateThemeAsset(
            newTheme,
            elementType,
            asset,
            // @ts-expect-error TS(2554) FIXME: Expected 2 arguments, but got 1.
            updateStylesHelper(styleUpdate)
          );
        if (styleReset)
          newTheme = updateThemeAsset(
            newTheme,
            elementType,
            asset,
            resetStylesHelper(styleReset)
          );
        if (propUpdate)
          newTheme = updateThemeAsset(
            newTheme,
            elementType,
            asset,
            updatePropsHelper(propUpdate)
          );
      } else if (operation.type === 'cell') {
        cellUpdated = true;
        stepUpdated = true;
        const { propUpdate, styleUpdate, styleReset, remove } = operation;
        newSteps = produce(newSteps, (draft: any) => {
          if (styleUpdate) {
            node.setStyle({
              ...styleUpdate
            });
            const { background_image_id: backgroundImageId } = styleUpdate;
            if (backgroundImageId !== undefined) {
              node.setBackgroundImage(backgroundImageId);
            }
            draft[activeStepId] = gig?.toStep();
          } else if (styleReset) {
            node.styles = objectRemove(node.styles, styleReset);
            draft[activeStepId] = gig?.toStep();
          } else if (propUpdate) {
            // The properties dictionary obj is only supported on the desktop cell node in the case where
            // the same cell/key exists in both viewports. However, it is also possible to have a group
            // that only exists in the divergent mobile viewport.  In this case simply change that one.
            let targetCellNode = gig?.grid.find(
              [],
              (n: any) => n?.key === node.key
            );
            targetCellNode = targetCellNode || node;
            targetCellNode.setProperties({
              ...targetCellNode.getProperties(),
              ...propUpdate.properties // presently only supporting properties in the properties sub-object (no loading_icon for example)
            });
            if (propUpdate.hide_ifs)
              targetCellNode.setHideIfs(propUpdate.hide_ifs);
            if (propUpdate.show_logic !== undefined)
              targetCellNode.setShowLogic(propUpdate.show_logic);
            if ('repeated' in propUpdate)
              targetCellNode.setRepeated(propUpdate.repeated);
            draft[activeStepId] = gig?.toStep();
          } else if (remove) {
            const removeResults = removeCell(node, true);
            if (removeResults) {
              const [
                newWorkingSteps,
                affectedStepKeys,
                cleanedLogicRules,
                affectedLogicRuleKeys
              ] = removeResults;

              if (newWorkingSteps) {
                changedStepKeys = new Set<string>([
                  ...changedStepKeys,
                  ...affectedStepKeys
                ]);
                affectedStepKeys.forEach(
                  (stepId: string) => (draft[stepId] = newWorkingSteps[stepId])
                );
              }
              if (cleanedLogicRules) {
                changedLogicRuleKeys = new Set<string>([
                  ...changedLogicRuleKeys,
                  ...affectedLogicRuleKeys
                ]);
                affectedLogicRuleKeys.forEach(
                  (ruleId: string) =>
                    (newLogicRules[ruleId] = cleanedLogicRules[ruleId])
                );
              }
            }
          }
          changedStepKeys.add(activeStepId);
        });
      } else if (operation.type === 'element') {
        // If type is 'element', this is an element update/reset
        const { remove, propUpdate, propReset, styleUpdate, styleReset } =
          operation;
        const elementIndex = newSteps[activeStepId][stepElementProp].findIndex(
          (el: any) => el.id === elementId
        );
        let newElement = newSteps[activeStepId][stepElementProp][elementIndex];
        stepUpdated = true;
        // If remove is true, we just need to delete the current element
        // Note: We don't update focusedElement here because wipeFocus will get called in the Detail Panel onClose
        if (remove) {
          if (viewport === Viewport.Mobile) {
            // Do not consider the step to have been updated if mobile edits
            // are being prevented here
            stepUpdated = false;

            addErrorToast({
              title: "You can't delete Elements when editing mobile styles.",
              body: 'To delete, return to Desktop view.'
            });
          } else {
            const { steps, logicRules } = deleteElementById(
              newSteps,
              activeStepId,
              stepElementProp,
              elementId,
              changedStepKeys,
              newLogicRules,
              changedLogicRuleKeys
            );
            newSteps = steps;
            newLogicRules = logicRules;

            addInfoToast('Removed element from cell.');

            return;
          }
        }
        const errors = validateElementOperation(operation);
        const hasError = errors.length > 0;
        setDetailError(hasError ? errors[0] : '');
        if (hasError) return;
        // Note: It's CRUCIAL that "reset" operations happen before "update" operations
        // Otherwise you might be resetting properties or styles the user just updated
        if (styleReset) {
          newElement = produce(newElement, (draft: any) => {
            draft[styleKey] = objectRemove(draft[styleKey], styleReset);
            // If the element has text_formatted, we need to wipe any existing formatting
            // Using font_* props if in desktop view, mobile_font_* props if in mobile view
            if (draft.text_formatted) {
              const viewportReset =
                viewport === Viewport.Mobile
                  ? styleReset.map((p: any) => `mobile_${p}`)
                  : styleReset;
              draft.text_formatted.forEach((op: any) => {
                op.attributes = objectRemove(op.attributes, viewportReset);
              });
            }
          });
        }
        if (styleUpdate) {
          newElement = produce(
            newElement,
            updateStylesHelper(styleUpdate, true)
          );
        }
        // Note: It's CRUCIAL that "reset" operations happen before "update" operations
        // Otherwise you might be resetting properties or styles the user just updated
        if (propReset) {
          newElement = produce(newElement, resetPropsHelper(propReset));
          // If this propReset was for a field linking and includes 'servar', then the field
          // is being replaced and we need to clean up any rules that reference it by cleanupAllElementReferences
          if (propReset.includes('servar')) {
            const { steps, logicRules } = cleanupAllElementReferences(
              newSteps,
              element,
              changedStepKeys,
              newLogicRules,
              changedLogicRuleKeys
            );
            newSteps = steps;
            newLogicRules = logicRules;
          }
        }
        if (propUpdate) {
          // If the element was updated with new or changed options, we need to potentially fixup rules
          // However, if this is part of a field linkage and the servar is being replaced, don't because rules
          // cleaned above.
          if (propUpdate.servar?.metadata?.options && newElement.servar)
            newSteps = fixupRuleOptionsReferences(
              newSteps,
              newElement,
              propUpdate?.servar?.metadata?.options,
              changedStepKeys
            );

          if (propUpdate.servar?.key) {
            newLogicRules = await cleanupLogicRuleElementReferences(
              element,
              newLogicRules,
              changedLogicRuleKeys,
              propUpdate.servar.key
            );
          }

          newElement = produce(newElement, updatePropsHelper(propUpdate));
          // If we updated the servar for a field
          // We need to make sure we update the servar uses in all the steps
          if ('servar' in (propUpdate ?? {})) {
            const servar = newElement.servar;
            newSteps = produce(newSteps, (draft: any) => {
              Object.values(draft).forEach((step: any) => {
                step.servar_fields.forEach((field: any) => {
                  if (field.servar.id === servar.id) {
                    field.servar = servar;
                    changedStepKeys.add(step.id);
                  }
                });
              });
            });
          }
        }

        // Update the new element in the steps
        newSteps = produce(newSteps, (draft: any) => {
          draft[activeStepId][stepElementProp][elementIndex] = newElement;
        });
        changedStepKeys.add(activeStepId);
      }
    }

    const changedStepKeysArr = Array.from(changedStepKeys);
    const changedLogicRulesKeysArr = Array.from(changedLogicRuleKeys);
    const undoRedoPayload = getUndoRedoPayload({
      elementType: cellUpdated ? 'cell' : elementType,
      oldSteps: workingSteps,
      newSteps,
      stepId: activeStepId,
      changedStepKeys: changedStepKeysArr,
      oldLogicRules: workingLogicRules,
      newLogicRules,
      changedLogicRulesKeysArr
    });
    // If the operation was a remove it could have affected more than one step
    undoRedoPayload.oldValue = objectPick(workingSteps, changedStepKeysArr);
    undoRedoPayload.newValue = objectPick(newSteps, changedStepKeysArr);
    undoRedoPayload.type = UNDO_TYPES.SOME_STEPS;

    if (stepUpdated) {
      setPanelDataWithUndoRedo(undoRedoPayload);
    }

    themeUpdated &&
      addThemeToUndoStack({
        id: theme.id,
        oldValue: theme,
        newValue: newTheme,
        title: UNDO_TITLES.THEME,
        type: UNDO_TYPES.THEME
      });
  };

  return { handleUpdates };
};
