import { ErrorUFOIcon, RefreshIcon, RightArrowIcon } from '../Icons';

import styles from './styles.module.scss';
import { openTab } from '../../utils/domOperations';
import { memo } from 'react';

function ErrorDisplay() {
  return (
    <div className={styles.errorContainer}>
      <span className={styles.oopsText}>Oops!</span>
      <ErrorUFOIcon />
      <span className={styles.somethingText}>
        Something happened while loading your page.
      </span>
      <div className={styles.tryAgainButton} onClick={() => location.reload()}>
        <RefreshIcon />
        <span className={styles.tryAgainText}>Try Again</span>
      </div>
      <div
        className={styles.reportIssue}
        onClick={() => openTab('https://community.feathery.io')}
      >
        Report an issue <RightArrowIcon height={14} />
      </div>
    </div>
  );
}

export default memo(ErrorDisplay);
