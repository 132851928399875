const PropertiesIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11 8H16.5'
        stroke='#414859'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8 8H8.5'
        stroke='#414859'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11 11.6001H16.5'
        stroke='#414859'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8 11.6001H8.5'
        stroke='#414859'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11 15.5H16.5'
        stroke='#414859'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8 15.5H8.5'
        stroke='#414859'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.6667 4H5.33333C4.97971 4 4.64057 4.14048 4.39052 4.39052C4.14048 4.64057 4 4.97971 4 5.33333V18.6667C4 19.0203 4.14048 19.3594 4.39052 19.6095C4.64057 19.8595 4.97971 20 5.33333 20H18.6667C19.0203 20 19.3594 19.8595 19.6095 19.6095C19.8595 19.3594 20 19.0203 20 18.6667V5.33333C20 4.97971 19.8595 4.64057 19.6095 4.39052C19.3594 4.14048 19.0203 4 18.6667 4Z'
        stroke='#414859'
        strokeWidth='1.5'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default PropertiesIcon;
