import { useEffect } from 'react';
import useFeatheryRedux from '../../../redux';

type ModalProps = React.PropsWithChildren<{
  onClose?: () => void;
}>;

const Modal = ({ children, onClose = () => {} }: ModalProps) => {
  const {
    formBuilder: { setModalOpen }
  } = useFeatheryRedux();

  useEffect(() => {
    setModalOpen(true);

    const handleKeyEvent = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keyup', handleKeyEvent);

    return () => {
      document.removeEventListener('keyup', handleKeyEvent);
      setModalOpen(false);
    };
  }, []);

  return <>{children}</>;
};

export default Modal;
