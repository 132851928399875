import { memo } from 'react';

import styles from './styles.module.scss';
import { Overlay } from 'react-bootstrap';

function DropdownMenu({
  options,
  onSelect,
  show,
  setShow,
  targetRef
}: {
  options: { display: string; value: string }[];
  onSelect: (opt: string) => void;
  show: boolean;
  setShow: (show: boolean) => void;
  targetRef: any;
}) {
  return (
    <Overlay
      target={targetRef.current}
      show={show}
      onHide={() => setShow(false)}
      placement='bottom-end'
      rootClose
    >
      {(props) => {
        ['placement', 'arrowProps', 'show', 'popper'].forEach(
          (prop) => delete props[prop]
        );
        return (
          <div
            // Setting this ID prevents GiG controls from triggering while menu is active
            id='elevated-menu-container'
            className={styles.optionsDropdown}
            {...props}
          >
            {options.map(({ display, value }) => (
              <div
                key={value}
                className={styles.optionsDropdownEntry}
                onClick={() => {
                  setShow(false);
                  onSelect(value);
                }}
              >
                {display}
              </div>
            ))}
          </div>
        );
      }}
    </Overlay>
  );
}

export default memo(DropdownMenu);
