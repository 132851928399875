import '../../style/dialog-form.css';

import { Form } from 'react-bootstrap';
import { useState } from 'react';

import IntegrationsSidebar from './IntegrationsSidebar';
import { DropdownField, PropertyLabel, TextField } from '../Core';
import useIntegrations from './useIntegrations';
import { useParams } from 'react-router-dom';
import { ALL_INTEGRATIONS_MAP, INTEGRATIONS } from './types';
import { useAppSelector } from '../../hooks';

function AmplitudeSettingsSidebar() {
  const { formId } = useParams<{ formId: string }>();

  // Global/cached integration settings
  const integration = useIntegrations({
    type: INTEGRATIONS.AMPLITUDE,
    panelId: formId,
    includeInactive: true
  });
  const org = useAppSelector((state) => state.accounts.organization);

  // Local/draft integration settings
  const [apiKey, setAPIKey] = useState(
    integration?.data.metadata.api_key ?? ''
  );
  const [testAPIKey, setTestAPIKey] = useState(
    integration?.data.metadata.test_api_key ?? ''
  );
  const [identifyUser, setIdentifyUser] = useState(
    integration?.data.metadata.identify_user ?? false
  );
  const [trackFields, setTrackFields] = useState(
    integration?.data.metadata.track_fields ?? false
  );

  const [isPartial, setIsPartial] = useState(false);

  function onSubmitCustom(newIsActive: boolean) {
    if (newIsActive) {
      const partial = !apiKey;
      setIsPartial(partial);
      if (partial) return;
    }

    const metadata = {
      api_key: apiKey,
      test_api_key: testAPIKey,
      identify_user: identifyUser,
      track_fields: trackFields
    };
    return { isUpdate: integration?.data, metadata };
  }

  return (
    <IntegrationsSidebar
      integrationInfo={ALL_INTEGRATIONS_MAP[INTEGRATIONS.AMPLITUDE]}
      isPartial={isPartial}
      onSubmitCustom={onSubmitCustom}
    >
      <Form>
        <Form.Group controlId='api-key'>
          <PropertyLabel label='API Key' />
          <TextField
            className='dialog-form-input'
            value={apiKey || ''}
            onChange={setAPIKey}
            error={isPartial && !apiKey}
          />
        </Form.Group>
        <Form.Group controlId='test-api-key'>
          <PropertyLabel label='Test API Key (Optional)' />
          <TextField
            className='dialog-form-input'
            value={testAPIKey || ''}
            onChange={setTestAPIKey}
          />
        </Form.Group>
        <Form.Group controlId='identify-user'>
          <PropertyLabel label='Identify User in Amplitude' />
          <DropdownField
            className='dialog-form-input'
            selected={identifyUser.toString()}
            onChange={(event: any) =>
              setIdentifyUser(event.target.value === 'true')
            }
            options={[
              { value: 'false', display: 'No' },
              { value: 'true', display: 'Yes' }
            ]}
          />
        </Form.Group>
        {!org?.enterprise_features.hipaa && (
          <Form.Group>
            <PropertyLabel label='Track field entries' />
            <DropdownField
              className='dialog-form-input'
              selected={trackFields.toString()}
              onChange={(event: any) =>
                setTrackFields(event.target.value === 'true')
              }
              options={[
                { value: 'false', display: 'No' },
                { value: 'true', display: 'Yes' }
              ]}
            />
          </Form.Group>
        )}
      </Form>
    </IntegrationsSidebar>
  );
}

export default AmplitudeSettingsSidebar;
