import { PropsWithChildren } from 'react';
import { useControls } from './useControls';
import type { StyledContainerProps } from '@feathery/react';
import { StyledContainer } from '@feathery/react';
import classNames from 'classnames';
import containerStyles from './styles.module.scss';
import { ViewportType } from '../../engine/viewports';
import { Cell } from '../../engine';

export const DEFAULT_MIN_SIZE = 50;
export const DEFAULT_MIN_FILL_SIZE = 10;

export type CellControlsType = {
  Wrapper: React.ComponentType<CellControlsProps>;
};

export type CellControlsProps = Omit<ContainerProps, 'controls'>;

type BaseProps = Omit<StyledContainerProps, 'node' | 'viewport'> & {
  node: Cell;
  viewport?: ViewportType;
};

export type ContainerProps = PropsWithChildren<
  BaseProps & {
    node: Cell;
    controls?: CellControlsType;
  }
>;

/**
 * Container is a component that is to be used for Containers and wrapping around elements
 * while being controlled by the control layer.
 */
const Container = (props: Omit<ContainerProps, 'controls'>) => {
  const { node, children, ...rest } = props;
  const { controlsRef, showContainerBorders, showDroppableStyles } =
    useControls(node);

  if (!node) {
    return null;
  }
  return (
    <StyledContainer
      {...rest}
      ref={controlsRef}
      node={node}
      viewport={node.viewport}
      viewportOnly={true}
      data-testid={'canvas:' + node.position.toString()}
      className={classNames('cell-container', {
        [containerStyles.containerGridMode]: showContainerBorders,
        [containerStyles.canDrop]: showDroppableStyles
      })}
    >
      {children}
    </StyledContainer>
  );
};

type ControlledContainerProps = Omit<ContainerProps, 'controls'> & {
  controls: CellControlsType;
};

/**
 * ControlledContainer is a component that is meant to be used for Containers and wrapping around elements
 * while being controller by custom controls, not the control layer.
 */
const ControlledContainer = (props: ControlledContainerProps) => {
  const { node, controls, children, ...rest } = props;
  const Controls = controls.Wrapper;

  return (
    <StyledContainer
      node={node}
      viewport={node.viewport}
      component={Controls}
      viewportOnly={true}
      {...rest}
    >
      {children}
    </StyledContainer>
  );
};

/**
 * A component that is to be used to render Containers and wrapped around Elements.
 */
const CellContainer = ({ controls, ...props }: ContainerProps) => {
  return controls ? (
    <ControlledContainer controls={controls} {...props} />
  ) : (
    <Container {...props} />
  );
};

export default CellContainer;
