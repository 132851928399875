import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const getImages = createAsyncThunk(
  'images/getImages',
  async ({ token }: { token: string }) => {
    const response = await FeatheryAPI.getImages(token);

    if (response.status === 200) {
      return await response.json();
    } else {
      throw new Error('Could not fetch uploaded images');
    }
  }
);

const createImages = createAsyncThunk(
  'panels/createImages',
  async ({ token, ...data }: { token: string }) => {
    const response = await FeatheryAPI.createImages(token, data);

    if (response.status === 201) {
      return await response.json();
    } else {
      throw new Error('Could not upload images');
    }
  }
);

const editImage = createAsyncThunk(
  'panels/editImage',
  async ({ token, ...data }: { token: string }) => {
    const response = await FeatheryAPI.editImage(token, data);

    if (response.status === 200) {
      return await response.json();
    } else {
      throw new Error('Could not edit image');
    }
  }
);

const deleteImage = createAsyncThunk(
  'images/deleteImage',
  async ({ token, imageId }: { token: string; imageId: string }) => {
    const response = await FeatheryAPI.deleteImage(token, { imageId });

    if (response.status !== 204) {
      throw new Error('Could not delete image');
    }
  }
);

const imagesSlice = createSlice({
  name: 'images',
  initialState: {} as Record<string, any>,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getImages.fulfilled, (state: any, action: any) => {
      action.payload.forEach((image: any) => {
        state[image.id] = image;
      });
    });
    builder.addCase(createImages.fulfilled, (state: any, action: any) => {
      action.payload.forEach((data: any) => (state[data.id] = data));
    });
    builder.addCase(editImage.fulfilled, (state: any, action: any) => {
      state[action.payload.id] = action.payload;
    });
    builder.addCase(deleteImage.fulfilled, (state: any, action: any) => {
      delete state[action.meta.arg.imageId];
    });
  }
});

export default imagesSlice.reducer;
export { getImages, createImages, editImage, deleteImage };
