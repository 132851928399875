const ContainerIcon = ({ color = '#414859', ...props }: any) => {
  return (
    <svg
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect
        x='44'
        y='44'
        width='40'
        height='40'
        rx='1'
        transform='rotate(180 44 44)'
        stroke={color}
        strokeWidth='3'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeDasharray='6 6'
      />
    </svg>
  );
};

export default ContainerIcon;
