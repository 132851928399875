import { RuleProvider, useRuleBuilderUpdater } from './context';
import { RuleDSL } from './components/RuleDSL';
import { LogicRule } from '../../../FormLogicPage/LogicRuleList';
import { Prompt, useParams } from 'react-router-dom';
import { RuleBuilderNav } from './components/RuleBuilderNav';
import styles from './styles.module.scss';
import { useEffect } from 'react';
import useFeatheryRedux from '../../../../redux';

type RuleBuilderCompletePayload = {
  dsl: IRuleDSL;
  code: string;
  valid: boolean;
};

type RuleBuilderProps = {
  mode: 'edit' | 'view';
  onComplete?: (payload: RuleBuilderCompletePayload) => void;
  setMode?: (mode: 'edit' | 'view') => void;
};

const RuleBuilder = ({
  mode = 'view',
  onComplete = () => {},
  setMode = () => {}
}: RuleBuilderProps) => {
  const { getCustomAPIs } = useFeatheryRedux();
  const { formId } = useParams<{ formId?: string }>();
  const setAPIServices = useRuleBuilderUpdater((s) => s.setAPIServices);

  // Fetch the available API Services to be used by the rule builder for display purposes
  useEffect(() => {
    if (!formId) return;
    getCustomAPIs({
      panelId: formId
    }).then((res: any) => {
      if (Array.isArray(res)) {
        setAPIServices(res as any[]);
      }
    });
  }, [formId]);

  return (
    <>
      <Prompt
        when={mode === 'edit'}
        message={() => 'Are you sure you want to leave without saving?'}
      />
      <div className={styles.ruleBuilderContainer}>
        <div className={styles.ruleBuilderHeader}>
          <RuleBuilderNav
            mode={mode}
            setMode={setMode}
            onComplete={onComplete}
          />
        </div>
        <div className={styles.ruleBuilderContent}>
          <RuleDSL mode={mode === 'edit' ? 'edit' : 'view'} />
        </div>
      </div>
    </>
  );
};

type RuleBuilderContainerProps = RuleBuilderProps & {
  rule: LogicRule;
};

const RuleBuilderContainer = ({
  rule,
  mode = 'view',
  ...props
}: RuleBuilderContainerProps) => {
  const dsl = JSON.parse(JSON.stringify(rule.dsl ?? {})) as IRuleDSL;

  return (
    <RuleProvider rule={rule} dsl={dsl} mode={mode}>
      <RuleBuilder {...props} mode={mode} />
    </RuleProvider>
  );
};

export { RuleBuilderContainer as RuleBuilder };
