/**
 * @file testIds.ts
 * @desc Test ids for elements in the DOM.
 * @example
 * // JSX
 * <div data-testid={testIds.createFormButton}></div>
 * // Playwright
 * const myElement = await page.getByTestId(testIds.createFormButton);
 * @see https://playwright.dev/docs/locators#locate-by-test-id
 * @see https://testing-library.com/docs/queries/bytestid/
 */

type TestIdConstant = string;
type TestIdHelper = (...args: any[]) => string;

type TestIds = {
  createFormButton: TestIdConstant;
  setAssetNameButton: TestIdConstant;
  assetNameInput: TestIdConstant;
  saveAssetActionButton: TestIdConstant;
  editorCanvas: TestIdConstant;
  editorCanvasContainer: TestIdConstant;
  controlLayerDrop: TestIdConstant;
  controlLayer: TestIdConstant;
  controlLabelText: TestIdConstant;
  ctaPanelCloseButton: TestIdConstant;
  mobileViewportButton: TestIdConstant;
  desktopViewportButton: TestIdConstant;
  createFormDialogSubmit: TestIdConstant;
  dialogClose: TestIdConstant;
  logoLoader: TestIdConstant;
  elevatedMenu: TestIdConstant;
  shareButton: TestIdConstant;
  restoreLastPublished: TestIdConstant;
  cellControlMenu: TestIdConstant;
  plusButton: TestIdConstant;
  createButton: TestIdHelper;
  tableRow: TestIdHelper;
  element: TestIdHelper;
  template: TestIdHelper;
  canvasPosition: TestIdHelper;
};

const testIds: TestIds = {
  createFormButton: 'create-form-button',
  setAssetNameButton: 'set-asset-name-button',
  assetNameInput: 'asset-name-input',
  saveAssetActionButton: 'save-asset-action-button',
  editorCanvas: 'editor-canvas',
  editorCanvasContainer: 'editor-canvas-container',
  controlLayerDrop: 'control-layer-drop',
  controlLayer: 'control-layer',
  controlLabelText: 'control-label-text',
  ctaPanelCloseButton: 'panel-close-button',
  mobileViewportButton: 'mobile-viewport-button',
  desktopViewportButton: 'desktop-viewport-button',
  createFormDialogSubmit: 'create-form-dialog-submit',
  dialogClose: 'dialog-close',
  logoLoader: 'logo-loader',
  elevatedMenu: 'elevated-menu',
  shareButton: 'share-button',
  restoreLastPublished: 'restore-last-published',
  cellControlMenu: 'cell-control-menu',
  plusButton: 'plus-button',
  createButton: (name: string) => `create-${name.toLowerCase()}-button`,
  tableRow: (i: number) => `table-row-${i}`,
  element: (node: any) => `el-${node?.id}`,
  template: (template?: string) =>
    template ? `template:${template}` : 'template:blank',
  canvasPosition: (position: string) => `canvas:${position}`
};

export default testIds;
