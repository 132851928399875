import '../../style/dialog-form.css';

import { AI_INTEGRATIONS } from './types';
import IntegrationsSidebar from '../FormIntegrations/IntegrationsSidebar';

function DisabledAIIntegrationSidebar({
  integrationType
}: {
  integrationType: string;
}) {
  return (
    <IntegrationsSidebar
      integrationInfo={AI_INTEGRATIONS.find(
        (integ) => integ.key === integrationType
      )}
    >
      Reach out to sales for access
    </IntegrationsSidebar>
  );
}

export default DisabledAIIntegrationSidebar;
