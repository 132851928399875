export const COLLABORATOR_FILTER_ID = 'Collaborator';
export const STATUS_FILTER_ID = 'Status';
export const FORM_FILTER_ID = 'Form';
export const FORM_TAG_FILTER_ID = 'FormTag';
export const FORM_STATUS_FILTER_ID = 'FormStatus';
export const INVITED_AT_FILTER_ID = 'InvitedAt';
export const COMPLETED_AT_FILTER_ID = 'CompletedAt';
export const SUBMITTED_FILTER_ID = 'Submitted';
export const FIELD_FILTER_ID = 'Field';
export const LAST_UPDATED_AT_FILTER_ID = 'LastUpddatedAt';
export const COMPLETED_FILTER_ID = 'Completed';
export const COLLABORATOR_ROLE_FILTER_ID = 'CollaboratorRole';

export const COLLABORATOR_FILTER_LABEL = 'Collaborator';
export const STATUS_FILTER_LABEL = 'Status';
export const FORM_FILTER_LABEL = 'Form';
export const FORM_TAG_FILTER_LABEL = 'Form Tags';
export const FORM_STATUS_FILTER_LABEL = 'Form Status';
export const INVITED_AT_FILTER_LABEL = 'Invited Date';
export const COMPLETED_AT_FILTER_LABEL = 'Completed Date';
export const SUBMITTED_FILTER_LABEL = 'Submitted Date';
export const FIELD_FILTER_LABEL = 'Field';
export const LAST_UPDATED_AT_FILTER_LABEL = 'Last Updated';
export const COMPLETED_FILTER_LABEL = 'Completed';
export const PENDING_COLLABORATOR_LABEL_FILTER_LABEL =
  'Pending Collaborator Label';
