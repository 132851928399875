export default function ListViewIcon(props: any) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='#414859'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M8.64941 4C8.09713 4 7.64941 4.44772 7.64941 5C7.64941 5.55228 8.09713 6 8.64941 6V4ZM20 6C20.5523 6 21 5.55228 21 5C21 4.44772 20.5523 4 20 4V6ZM8.64941 6H20V4H8.64941V6Z' />
      <path d='M4 4C3.44772 4 3 4.44772 3 5C3 5.55228 3.44772 6 4 6V4ZM4.05979 6C4.61207 6 5.05979 5.55228 5.05979 5C5.05979 4.44772 4.61207 4 4.05979 4V6ZM4 6H4.05979V4H4V6Z' />
      <path d='M8.64941 10.6418C8.09713 10.6418 7.64941 11.0895 7.64941 11.6418C7.64941 12.1941 8.09713 12.6418 8.64941 12.6418V10.6418ZM20 12.6418C20.5523 12.6418 21 12.1941 21 11.6418C21 11.0895 20.5523 10.6418 20 10.6418V12.6418ZM8.64941 12.6418H20V10.6418H8.64941V12.6418Z' />
      <path d='M4 10.6418C3.44772 10.6418 3 11.0895 3 11.6418C3 12.1941 3.44772 12.6418 4 12.6418V10.6418ZM4.05979 12.6418C4.61207 12.6418 5.05979 12.1941 5.05979 11.6418C5.05979 11.0895 4.61207 10.6418 4.05979 10.6418V12.6418ZM4 12.6418H4.05979V10.6418H4V12.6418Z' />
      <path d='M8.64941 17.2835C8.09713 17.2835 7.64941 17.7312 7.64941 18.2835C7.64941 18.8358 8.09713 19.2835 8.64941 19.2835V17.2835ZM20 19.2835C20.5523 19.2835 21 18.8358 21 18.2835C21 17.7312 20.5523 17.2835 20 17.2835V19.2835ZM8.64941 19.2835H20V17.2835H8.64941V19.2835Z' />
      <path d='M4 17.2835C3.44772 17.2835 3 17.7312 3 18.2835C3 18.8358 3.44772 19.2835 4 19.2835V17.2835ZM4.05979 19.2835C4.61207 19.2835 5.05979 18.8358 5.05979 18.2835C5.05979 17.7312 4.61207 17.2835 4.05979 17.2835V19.2835ZM4 19.2835H4.05979V17.2835H4V19.2835Z' />
    </svg>
  );
}
