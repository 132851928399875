import { useAppSelector } from './index';

export const useRepeatedContainerChecks = (
  element: any,
  bidirectional = false
) => {
  const workingSteps = useAppSelector(
    (state) => state.formBuilder.workingSteps
  );
  const activeStepId = useAppSelector(
    (state) => state.formBuilder.activeStepId
  );
  const activeStep = workingSteps[activeStepId];

  if (!activeStep) return { repeatExists: false, inCell: false };

  const repeatGrids = activeStep.subgrids.filter((grid: any) => grid.repeated);
  const inRepeatContainer = repeatGrids.some((repeatGrid: any) => {
    const elPos = element.position.join(',') + ',';
    const repeatGridPos = repeatGrid.position.join(',') + ',';
    return (
      elPos !== repeatGridPos &&
      (elPos.startsWith(repeatGridPos) ||
        (bidirectional && repeatGridPos.startsWith(elPos)))
    );
  });
  return { repeatExists: repeatGrids.length > 0, inRepeatContainer };
};
