function HawkSoft({ width = 230, height = 45 }) {
  return (
    <img
      alt='HawkSoft logo'
      src={require('./HawkSoft.png')}
      style={{ width, height }}
    />
  );
}

export default HawkSoft;
