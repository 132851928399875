/**
 * @param key string corresponding to cookie name
 * @returns cookie value, or undefined if not found
 */
export function getCookie(key: string) {
  return document.cookie
    .split('; ')
    .filter((row) => row.startsWith(`${key}=`))
    .map((c) => c.split('=')[1])[0];
}

export const getOS = () => {
  const userAgent = window.navigator?.userAgent;

  if (!userAgent) {
    return null;
  }

  if (userAgent.indexOf('Win') >= 0) return 'Windows';
  if (userAgent.indexOf('Mac') >= 0) return 'MacOS';
  if (userAgent.indexOf('X11') >= 0) return 'UNIX';
  if (userAgent.indexOf('Linux') >= 0) return 'Linux';

  return null;
};

export function downloadFile(filename: string, content: string | Blob) {
  const element = document.createElement('a');
  element.style.display = 'none';
  let href;
  if (content instanceof Blob) {
    href = window.URL.createObjectURL(content);
  } else {
    const encodedText = encodeURIComponent(content);
    href = `data:text/plain;charset=utf-8,${encodedText}`;
  }
  element.href = href;
  element.download = filename;
  document.body.appendChild(element);
  element.click();
  if (content instanceof Blob) {
    window.URL.revokeObjectURL(href);
  }
  document.body.removeChild(element);
}

/**
 * If the app is connected to a local backend, we open urls
 * on the backend server, otherwise the link that as provided (the aws link).
 *
 * @param url
 * @returns
 */
export const getFileUrl = (url: string) => {
  return process.env.REACT_APP_BACKEND_ENV === 'local'
    ? `http://localhost:8006${url}`
    : url;
};

// Get the last section of the URL path as the name
export const nameFromUrl = (url: string) => {
  if (!url) return '';
  return new URL(url).pathname.split('/').at(-1) ?? '';
};
