import { Col, InputGroup, Row } from 'react-bootstrap';

import {
  CheckboxField,
  CollapsibleSection,
  DropdownField,
  FColorPicker,
  InlineTooltip,
  NumberInput,
  PropertyLabel,
  TextField
} from '../../../components/Core';
import { FileUploadIcon, TrashIcon } from '../../../components/Icons';
import { OPTION_FIELDS } from '../../../components/SelectionPanel/elementEntries';
import { SIZE_UNITS } from '../../../utils/constants';
import classNames from 'classnames';
import sidebarStyles from '../../../components/FormFieldEditors/formFieldEditor.module.scss';
import styles from '../styles.module.scss';
import { useAppSelector } from '../../../hooks';
import FontPicker from '../../../components/Core/FontPicker';
import { FONT_WEIGHT_OPTIONS } from '../../../utils/themes';
import { Button } from '../../../components/Core/Button/button';
import { TEXT_ALIGN_OPTIONS } from '../constants';

const TYPOGRAPHY_FIELDS = ['text', 'combobox', 'listbox'];

type Props = {
  activeAnnotatedField: any;
  setActiveAnnotatedField: any;
  setShowModal: any;
  formFieldMap: any;
  editField: any;
  updateFields: any;
  pdfField: any;
  numPages: any;
};

const FieldPanel = (props: Props) => {
  const {
    activeAnnotatedField,
    setActiveAnnotatedField,
    setShowModal,
    formFieldMap,
    editField,
    updateFields,
    pdfField,
    numPages
  } = props;

  // Active servar or hidden field
  const activeField =
    activeAnnotatedField && activeAnnotatedField.form_field_id
      ? formFieldMap[activeAnnotatedField.form_field_id]
      : null;

  const uploadedFonts: Record<string, any[]> = useAppSelector(
    (state) => state.integrations.uploadedFonts
  );

  const updateStyle = (props: any) => {
    const newStyle = {
      ...activeAnnotatedField.styles,
      ...props
    };
    const newField = {
      ...activeAnnotatedField,
      styles: newStyle
    };
    updateFields((draft: any) => {
      draft[activeAnnotatedField.id] = newField;
    });
    // refresh the active field data
    setActiveAnnotatedField(newField);
  };

  const updateProperty = (props: any) => {
    const newProperties = {
      ...activeAnnotatedField.properties,
      ...props
    };
    const newField = {
      ...activeAnnotatedField,
      properties: newProperties
    };
    updateFields((draft: any) => {
      draft[activeAnnotatedField.id] = newField;
    });
    // refresh the active field data
    setActiveAnnotatedField(newField);
  };

  const currentFontWeightOptions =
    uploadedFonts[activeAnnotatedField.styles?.font_family ?? '']?.map(
      (f) => f.weight
    ) ?? [];
  const fontWeightOptions = FONT_WEIGHT_OPTIONS.filter((weight) => {
    const isHelvetica = activeAnnotatedField.styles?.font_family === undefined;
    const isEnabled =
      (isHelvetica && weight === 400) ||
      currentFontWeightOptions.includes(weight.toString());
    return isEnabled;
  });
  const handleOptionValChange =
    (index: number) => (newValue: string | null) => {
      const newOptions = activeAnnotatedField.properties?.options.map(
        (option: string, idx: number) => (index === idx ? newValue : option)
      );
      updateProperty({ options: newOptions });
    };

  function getNewFontWeight(fontFamily: string) {
    const currentFontWeight = activeAnnotatedField.styles?.font_weight ?? 400;
    const fontWeights = uploadedFonts[fontFamily]?.map((f) => f.weight) ?? [];

    const result = fontWeights.includes(currentFontWeight.toString())
      ? currentFontWeight
      : fontWeights[0]
      ? parseInt(fontWeights[0])
      : 400;

    return result;
  }
  const updateActiveField = (props: any) => {
    const newField = {
      ...activeAnnotatedField,
      ...props
    };
    updateFields((draft: any) => {
      draft[activeAnnotatedField.id] = newField;
    });
    // refresh the active field data
    setActiveAnnotatedField(newField);
  };

  function addOption() {
    const newOptions = [
      ...(activeAnnotatedField.properties?.options ?? []),
      ''
    ];

    const newProperties = {
      ...activeAnnotatedField.properties,
      options: newOptions
    };

    const newField = {
      ...activeAnnotatedField,
      properties: newProperties
    };
    updateFields((draft: any) => {
      draft[activeAnnotatedField.id] = newField;
    });
    // refresh the active field data
    setActiveAnnotatedField(newField);
  }

  function deleteOption(index: number) {
    return () => {
      const newOptions = activeAnnotatedField.properties?.options.filter(
        (_: any, idx: number) => idx !== index
      );

      const newProperties = {
        ...activeAnnotatedField.properties,
        options: newOptions
      };

      const newField = {
        ...activeAnnotatedField,
        properties: newProperties
      };
      updateFields((draft: any) => {
        draft[activeAnnotatedField.id] = newField;
      });
      // refresh the active field data
      setActiveAnnotatedField(newField);
    };
  }

  const isSquare =
    activeAnnotatedField.type === 'checkbox' ||
    activeAnnotatedField.type === 'radio';

  const fieldStyle = activeAnnotatedField.styles ?? {};

  return (
    <>
      <div className={sidebarStyles.fixedPanel}>
        <Row>
          <Col sm='4'>
            <PropertyLabel label='PDF Name' />
          </Col>
          <Col>
            <div className={sidebarStyles.labelType}>
              {pdfField?.name ?? activeAnnotatedField.type}
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm='4'>
            <PropertyLabel label='PDF Type' />
          </Col>
          <Col>
            <div className={sidebarStyles.labelType}>
              {activeAnnotatedField.type.charAt(0).toUpperCase() +
                activeAnnotatedField.type.slice(1)}
            </div>
          </Col>
        </Row>
        {pdfField?.options && (
          <Row>
            <Col sm='5'>
              <PropertyLabel label='PDF Field Options' />
            </Col>
            <Col>{pdfField?.options.join(', ') ?? ''}</Col>
          </Row>
        )}
      </div>
      <CollapsibleSection title='Properties' collapsible>
        <Row className={styles.propertyRow}>
          <Col sm='6'>
            <PropertyLabel label='Mapped Field' />
          </Col>
          <Col>
            <div
              className={classNames(styles.clickable, sidebarStyles.labelType, {
                [styles.selected]: activeAnnotatedField.form_field_id
              })}
              onClick={() => setShowModal(true)}
            >
              {activeAnnotatedField.form_field_id ? (
                <div className={styles.mappedField}>
                  {formFieldMap[activeAnnotatedField.form_field_id]?.key ??
                    'Unknown'}
                </div>
              ) : (
                <span className={sidebarStyles.unmapped}>Set Field</span>
              )}
            </div>
          </Col>
        </Row>
        {activeField && (
          <>
            {activeField.repeated && (
              <Row className={styles.propertyRow}>
                <Col sm='6' className={styles.propertyLabel}>
                  <PropertyLabel label='Repeat Number' />
                  <InlineTooltip text='Optionally select which repeating field value to map (starts at 1)' />
                </Col>
                <Col>
                  <div style={{ marginTop: '6px', width: '80px' }}>
                    <NumberInput
                      min={1}
                      value={
                        activeAnnotatedField.form_field_repeating_index !== null
                          ? activeAnnotatedField.form_field_repeating_index + 1
                          : ''
                      }
                      onComplete={(e: any) =>
                        updateActiveField({
                          form_field_repeating_index: e.value - 1
                        })
                      }
                      triggerCleanUp
                    />
                  </div>
                </Col>
              </Row>
            )}
            {['checkbox', 'radio'].includes(activeAnnotatedField.type) &&
              OPTION_FIELDS.includes(activeField.type) && (
                <Row className={styles.propertyRow}>
                  <Col sm='6' className={styles.propertyLabel}>
                    <PropertyLabel label='Option Value' />
                    <InlineTooltip text='Choose the option this PDF field corresponds to.' />
                  </Col>
                  <Col>
                    <DropdownField
                      className={styles.input}
                      required
                      selected={activeAnnotatedField.form_field_option_value}
                      onChange={(event: any) =>
                        updateActiveField({
                          form_field_option_value: event.target.value
                        })
                      }
                      options={activeField.metadata.options.map(
                        (option: any, index: number) => {
                          let label = option;
                          if (
                            activeField.metadata.option_labels &&
                            activeField.metadata.option_labels[index]
                          )
                            label = activeField.metadata.option_labels[index];
                          return { value: option, display: label };
                        }
                      )}
                    />
                  </Col>
                </Row>
              )}
            {activeField.type === 'matrix' && (
              <Row className={styles.propertyRow}>
                <Col sm='6' className={styles.propertyLabel}>
                  <PropertyLabel label='Matrix Question' />
                  <InlineTooltip text='Choose the matrix question to map' />
                </Col>
                <Col>
                  <DropdownField
                    className={styles.input}
                    required
                    selected={activeAnnotatedField.form_field_child_item}
                    onChange={(event: any) =>
                      updateActiveField({
                        form_field_child_item: event.target.value
                      })
                    }
                    options={activeField.metadata.questions.map((q: any) => ({
                      value: q.id,
                      display: q.label
                    }))}
                  />
                </Col>
              </Row>
            )}
          </>
        )}
        {activeAnnotatedField.type === 'signature' && (
          <Row style={{ marginTop: '16px' }}>
            <Col>
              <PropertyLabel label='Sign Methods' />
            </Col>
            <Col>
              <DropdownField
                selected={activeAnnotatedField.properties?.sign_methods ?? ''}
                onChange={(e: any) =>
                  updateProperty({
                    sign_methods: e.target.value || undefined
                  })
                }
                options={[
                  { value: '', display: 'Draw or Type' },
                  { value: 'draw', display: 'Draw' },
                  { value: 'type', display: 'Type' }
                ]}
              />
            </Col>
          </Row>
        )}
        {activeAnnotatedField.type !== 'image' && (
          <Row style={{ marginTop: '16px' }}>
            <Col>
              <CheckboxField
                checked={!activeAnnotatedField.required}
                text={
                  <>
                    Optional
                    <InlineTooltip text='This field does not need to be completed by the signer.' />
                  </>
                }
                onChange={(isChecked) =>
                  updateActiveField({ required: !isChecked })
                }
              />
            </Col>
          </Row>
        )}
        {activeAnnotatedField.type === 'text' && (
          <>
            <Row style={{ marginTop: '16px' }}>
              <Col>
                <CheckboxField
                  checked={activeAnnotatedField.properties?.read_only ?? false}
                  text={
                    <>
                      Readonly
                      <InlineTooltip text='This text is not editable by the signer.' />
                    </>
                  }
                  onChange={(isChecked) =>
                    updateProperty({ read_only: isChecked || undefined })
                  }
                />
              </Col>
            </Row>
            <Row style={{ marginTop: '16px' }}>
              <Col>
                <PropertyLabel label='Default Value' />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextField
                  type='textarea'
                  value={activeAnnotatedField.properties?.default_value ?? ''}
                  onComplete={(name: any) =>
                    updateProperty({ default_value: name || undefined })
                  }
                  triggerCleanUp
                  maxLength={1024}
                  rows={3}
                  style={{ resize: 'none' }}
                />
              </Col>
            </Row>
          </>
        )}
        {activeAnnotatedField.type === 'radio' && (
          <Row style={{ marginTop: '16px' }}>
            <Col sm='5' className={styles.propertyLabel}>
              <PropertyLabel label='Radio Group' />
              <InlineTooltip text='The radio group this button belongs to.' />
            </Col>
            <Col>
              <TextField
                value={activeAnnotatedField.properties?.radio_group ?? ''}
                onComplete={(newGroup: string) => {
                  updateProperty({ radio_group: newGroup || undefined });
                }}
                triggerCleanUp
              />
            </Col>
          </Row>
        )}
      </CollapsibleSection>
      {TYPOGRAPHY_FIELDS.includes(activeAnnotatedField.type) && (
        <CollapsibleSection title='Typography' collapsible>
          <Row>
            <Col sm='3'>
              <PropertyLabel label='Font' />
            </Col>
            <Col>
              <InputGroup>
                <DropdownField
                  selected={fieldStyle.font_family ?? ''}
                  onChange={(e: any) =>
                    updateStyle({
                      font_family: e.target.value || undefined,
                      font_weight: getNewFontWeight(e.target.value)
                    })
                  }
                  options={[
                    { value: '', display: 'Helvetica' },
                    ...Object.keys(uploadedFonts).map((font) => ({
                      value: font,
                      display: font
                    }))
                  ]}
                />
                <FontPicker CustomComponent={FontUploader} />
              </InputGroup>
            </Col>
          </Row>
          <Row className={styles.propertyRow}>
            <Col sm='3'>
              <PropertyLabel label='Weight' />
            </Col>
            <Col>
              <DropdownField
                selected={fieldStyle.font_weight ?? 400}
                onChange={(e: any) =>
                  updateStyle({ font_weight: parseInt(e.target.value) })
                }
                options={fontWeightOptions}
              />
            </Col>
          </Row>
          <Row className={styles.propertyRow}>
            <Col sm='3'>
              <PropertyLabel label='Size' />
            </Col>
            <Col>
              <NumberInput
                required
                min={0}
                value={fieldStyle.font_size ?? '14'}
                onComplete={({ value }: any) => {
                  updateStyle({ font_size: value });
                }}
                units={['px']}
                triggerCleanUp
              />
            </Col>
          </Row>
          <Row className={styles.propertyRow}>
            <Col sm='3'>
              <PropertyLabel label='Text Align' />
            </Col>
            <Col>
              <DropdownField
                selected={fieldStyle.text_align ?? TEXT_ALIGN_OPTIONS[0]}
                onChange={(e: any) =>
                  updateStyle({ text_align: e.target.value })
                }
                options={TEXT_ALIGN_OPTIONS}
              />
            </Col>
          </Row>
          <Row className={styles.propertyRow}>
            <Col sm='3'>
              <PropertyLabel label='Color' />
            </Col>
            <Col>
              <FColorPicker
                value={fieldStyle.font_color ?? '000000'}
                onChange={(color: any) => {
                  updateStyle({ font_color: color });
                }}
              />
            </Col>
          </Row>
        </CollapsibleSection>
      )}
      {['listbox', 'combobox'].includes(activeAnnotatedField.type) && (
        <CollapsibleSection title='Options'>
          {(activeAnnotatedField.properties?.options ?? []).map(
            (option: any, index: any) => (
              <Row
                key={`option-${index}-${option}`}
                style={{ marginBottom: '8px' }}
              >
                <Col sm='4'>
                  <PropertyLabel label={`Option ${index + 1}`} />
                </Col>
                <Col>
                  <InputGroup>
                    <TextField
                      placeholder='Option'
                      className='field-options-input'
                      defaultValue={option}
                      onComplete={handleOptionValChange(index)}
                      triggerCleanUp
                    />
                    <Button
                      variant='outline'
                      size='icon'
                      className='!tw-rounded-l-none'
                      onClick={deleteOption(index)}
                    >
                      <TrashIcon width={18} height={18} />
                    </Button>
                  </InputGroup>
                </Col>
              </Row>
            )
          )}
          <div className={styles.buttonGroupAddContainer}>
            <Button variant='outline-primary' onClick={addOption}>
              Add option
            </Button>
          </div>
        </CollapsibleSection>
      )}
      {!activeAnnotatedField.pdf_field_id && (
        <CollapsibleSection title='Style' collapsible>
          <Row>
            <Col sm='3'>
              <PropertyLabel label='Width' />
            </Col>
            <Col>
              <NumberInput
                required
                min={0}
                value={activeAnnotatedField.width}
                unit={'px'}
                units={[SIZE_UNITS.PX]}
                onComplete={(axisSize: any) =>
                  editField({
                    id: activeAnnotatedField.id,
                    width: axisSize.value,
                    height: isSquare
                      ? axisSize.value
                      : activeAnnotatedField.height
                  })
                }
                triggerCleanUp
                dimension='width'
              />
            </Col>
          </Row>
          <Row className={styles.propertyRow}>
            <Col sm='3'>
              <PropertyLabel label='Height' />
            </Col>
            <Col>
              <NumberInput
                required
                min={0}
                value={activeAnnotatedField.height}
                unit={'px'}
                units={[SIZE_UNITS.PX]}
                onComplete={(axisSize: any) =>
                  editField({
                    id: activeAnnotatedField.id,
                    width: isSquare
                      ? axisSize.value
                      : activeAnnotatedField.width,
                    height: axisSize.value
                  })
                }
                triggerCleanUp
                dimension='height'
              />
            </Col>
          </Row>
        </CollapsibleSection>
      )}
      {!activeAnnotatedField.pdf_field_id && (
        <CollapsibleSection title='Location' collapsible>
          <Row>
            <Col>
              <PropertyLabel label='Page' />
            </Col>
            <Col sm={8}>
              <NumberInput
                required
                min={1}
                max={numPages}
                value={activeAnnotatedField.page + 1}
                onComplete={(axisSize: any) =>
                  editField({
                    id: activeAnnotatedField.id,
                    page: axisSize.value - 1
                  })
                }
                triggerCleanUp
              />
            </Col>
          </Row>
          <Row className={styles.propertyRow}>
            <Col sm='1'>
              <PropertyLabel label='X' />
            </Col>
            <Col sm='5'>
              <NumberInput
                required
                min={0}
                value={activeAnnotatedField.x}
                unit={'px'}
                units={[SIZE_UNITS.PX]}
                onComplete={(axisSize: any) =>
                  editField({
                    id: activeAnnotatedField.id,
                    x: axisSize.value
                  })
                }
                triggerCleanUp
                dimension='xPosition'
              />
            </Col>
            <Col sm='1'>
              <PropertyLabel label='Y' />
            </Col>
            <Col sm='5'>
              <NumberInput
                required
                min={0}
                value={activeAnnotatedField.y}
                unit={'px'}
                units={[SIZE_UNITS.PX]}
                onComplete={(axisSize: any) =>
                  editField({
                    id: activeAnnotatedField.id,
                    y: axisSize.value
                  })
                }
                triggerCleanUp
                dimension='yPosition'
              />
            </Col>
          </Row>
        </CollapsibleSection>
      )}
      <CollapsibleSection>
        <div className={styles.sidebarActions}>
          {!activeAnnotatedField.pdf_field_id && (
            <Button
              variant='destructive'
              onClick={() => {
                updateFields((draft: any) => {
                  delete draft[activeAnnotatedField.id];
                });

                setActiveAnnotatedField(null);
              }}
            >
              <TrashIcon className='tw-stroke-current tw-h-5 tw-w-5' />
              <span>Delete</span>
            </Button>
          )}
        </div>
      </CollapsibleSection>
    </>
  );
};

export default FieldPanel;

const FontUploader = ({ onClick }: any) => {
  return (
    <Button
      variant='outline'
      size='icon'
      className='!tw-rounded-l-none'
      onClick={onClick}
    >
      <FileUploadIcon width={18} height={18} />
    </Button>
  );
};
