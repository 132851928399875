import { memo, useCallback, useMemo, useState } from 'react';
import { AIExtractionEntryValue } from '../types';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useExtractionUpdater } from '../context';
import EntryValueDisplay from './EntryValueDisplay';
import useField from '../../../utils/useField';
import debounce from 'lodash.debounce';
import classNames from 'classnames';
import styles from './styles.module.scss';

const POPOVER_DELAY = 750; // Milliseconds

type Props = {
  entryValue: AIExtractionEntryValue;
  isHidden?: boolean;
};

const AIFieldPopover = ({ entryValue, isHidden }: Props) => {
  const { setHoveredValueId } = useExtractionUpdater();
  const [show, setShow] = useState(false);
  const debouncedSetShow = useCallback(debounce(setShow, POPOVER_DELAY), []);
  const getField = useField();

  const fieldName = useMemo(() => {
    return getField(entryValue.fieldId)?.key ?? 'Unknown Field';
  }, [entryValue.fieldId]);

  const relatedEntries = entryValue.relatedEntries
    ? Object.values(entryValue.relatedEntries)
    : [];

  const overlayComponent = (
    <Popover
      id={`${entryValue.id}-${entryValue.index}-popover`}
      className={styles.aiFieldPopover}
      hasDoneInitialMeasure={show}
    >
      <div className={styles.header}>
        <div className={styles.title}>{fieldName}</div>
      </div>
      <div className={styles.body}>
        <div className={styles.section}>
          <div className={styles.label}>Value</div>
          <div className={styles.content}>
            <EntryValueDisplay entry={entryValue} />
          </div>
        </div>
        {relatedEntries.length > 1 && (
          <div className={styles.section}>
            <div className={styles.label}>Paired Values</div>
            <div className={classNames(styles.content, styles.list)}>
              {relatedEntries.map((entry) => {
                return (
                  <div key={entry.id} className={styles.value}>
                    <div className={styles.label}>
                      {getField(entry.fieldId)?.key ?? 'Unknown Field'}
                    </div>
                    <div>
                      <EntryValueDisplay entry={entry} />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement='auto'
      overlay={overlayComponent}
      show={show}
      onToggle={(show) => {
        if (show && !isHidden) {
          debouncedSetShow(show);
        } else {
          debouncedSetShow.cancel();
          setShow(false);
          setHoveredValueId(null);
        }
      }}
    >
      <div className={styles.highlight} />
    </OverlayTrigger>
  );
};

export default memo(AIFieldPopover);
