import { useEffect, useMemo, useState } from 'react';
import useFeatheryRedux from '../../redux';
import { useHistory, useParams } from 'react-router-dom';
import classNames from 'classnames';
import taxIdTypes from '../../utils/data/taxIdTypes';

import {
  DropdownField,
  InlineTooltip,
  TextField,
  YesNoSwitch
} from '../../components/Core';

import {
  InviteTeamModal,
  SubmissionSummaryModal
} from '../../components/Modals';
import UserList from './components/UserList';

import {
  PaymentMethod,
  PlanSelector,
  UsageEnforcement
} from '../../components/PricingPlans';
import { Spinner } from 'react-bootstrap';

import styles from './styles.module.scss';
import {
  formatDate,
  formatMoneyUSD,
  formatMonthAndDay,
  formatNumeric
} from '../../utils/format';
import { booleanSort } from '../../utils/core';
import {
  chosenPlan,
  getBillingCycleDates,
  onFreePlan
} from '../../components/PricingPlans/plans';
import { useAppSelector } from '../../hooks';
import { isSSO } from '../../utils/useGetJwt';
import BrandingTab from './tabs/BrandingTab';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger
} from '../../components/Core/Tabs';
import { Button } from '../../components/Core/Button/button';

export default function GlobalSettingsPage() {
  const { tab } = useParams<{ tab: string }>();
  const history = useHistory();

  // The stripe card element can only have a single instance so either show here or on the change plan dialog
  const [showPaymentMethod, setShowPaymentMethod] = useState(true);

  const metrics = useAppSelector((state) => state.metrics.billingMetrics);
  const {
    organization: org,
    account,
    bill
  } = useAppSelector((state) => state.accounts);
  const panels = useAppSelector((state) => state.panels.panels);

  const [teamName, setTeamName] = useState(org?.name ?? '');
  const [inviteStep, setInviteStep] = useState(0);
  const [receiptEmail, setReceiptEmail] = useState(org?.receipt_email ?? '');
  const [taxId, setTaxId] = useState(org?.tax_id ?? '');
  const [taxIdType, setTaxIdType] = useState(org?.tax_id_type ?? '');

  const {
    getBillingMetrics,
    editOrganization,
    editAccount,
    toasts: { addInfoToast }
  } = useFeatheryRedux();

  useEffect(() => {
    getBillingMetrics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [submissionMetrics, setSubmissionMetrics] = useState<any>(null);
  const [totalSubmissions, setTotalSubmissions] = useState(0);
  const [showSubmissionsModal, setShowSubmissionsModal] = useState(false);

  const [billStart, billEnd] = useMemo(
    () =>
      bill.subscription_start
        ? getBillingCycleDates(new Date(bill.subscription_start))
        : [null, null],
    [bill.subscription_start]
  );
  const submissions = org?.submissions_this_month ?? 0;
  const currentPlan = useMemo(() => chosenPlan(org), [org?.tier]);

  useEffect(() => {
    setSubmissionMetrics(
      Object.entries(metrics.submissions_this_month)
        .filter(([panelId, submissions]) => submissions && panelId in panels)
        .sort(([aId, aNum], [bId, bNum]) => {
          // sort by submission count descending, then by name
          if (bNum !== aNum) return bNum - aNum;
          else return panels[aId].key.localeCompare(panels[bId].key);
        })
        .map(([panelId, submissions]) => {
          return {
            id: panelId,
            formName: panels[panelId].key,
            submissions
          };
        })
    );
    setTotalSubmissions(
      Object.values(metrics.submissions_this_month).reduce(
        (total, m) => total + m,
        0
      )
    );
  }, [metrics.submissions_this_month, panels]);

  const email = account.email;
  const MOST_ACTIVE_FORMS_COUNT = 3;

  const enterprise = org?.tier === 4;
  const freeTier = onFreePlan(org);

  return org ? (
    <>
      <h1 className='page-head'>{email}</h1>
      <div className={styles.upperSection}>
        <div className={styles.upperSectionLeft}>
          <div className={styles.monthlyUsersSection}>
            <span className={styles.monthlyUsersValue}>
              {metrics.active_users_this_month}
            </span>
            <span className={styles.settingsSublabel}>
              Total users since {formatMonthAndDay(billStart)}
            </span>
          </div>
          {booleanSort([...org.environments], 'primary').map((env: any) => (
            <div key={env.name} className={styles.environmentSection}>
              <label className={styles.settingsLabel}>
                {env.primary ? 'Live' : 'Test'} Credentials
              </label>
              <div className={styles.apiKeySection}>
                <div className={styles.settingsSublabel}>
                  SDK Key
                  <InlineTooltip
                    text={
                      <>
                        Your SDK key is used to authenticate the{' '}
                        <a href='https://docs.feathery.io/develop/react'>
                          Feathery SDK for embedding forms
                        </a>
                      </>
                    }
                    inline
                  />
                </div>
                <div className={styles.apiKeyValue}>{env.sdk_key}</div>
              </div>
              <div className={styles.apiKeySection}>
                <div className={styles.settingsSublabel}>
                  API Key
                  <InlineTooltip
                    text={
                      <>
                        Your API key is used to authenticate your requests to
                        the{' '}
                        <a href='https://api-docs.feathery.io'>
                          Feathery public API
                        </a>
                      </>
                    }
                    inline
                  />
                </div>
                <div className={styles.apiKeyValue}>{env.api_key}</div>
              </div>
            </div>
          ))}
        </div>
        <UsageEnforcement onUpgrade={() => history.push('/settings/billing')} />
      </div>
      <div className={classNames(styles.sectionSeparator, styles.spaced)} />
      <Tabs
        value={tab || 'team'}
        onValueChange={(k) => k && history.push(`/settings/${k}/`)}
      >
        <TabsList
          unstyled
          className='legacyTabsList tw-border tw-bg-white tw-rounded-t-lg'
        >
          <TabsTrigger unstyled className='legacyTabsTrigger' value='team'>
            Team
          </TabsTrigger>
          <TabsTrigger unstyled className='legacyTabsTrigger' value='branding'>
            Branding
          </TabsTrigger>
          <TabsTrigger
            unstyled
            className='legacyTabsTrigger'
            value='notifications'
          >
            Notifications
          </TabsTrigger>
          {account.role === 'admin' && (
            <TabsTrigger unstyled className='legacyTabsTrigger' value='billing'>
              Billing
            </TabsTrigger>
          )}
        </TabsList>
        <TabsContent value='team'>
          <div className={styles.sectionSeparator} />
          <div className={styles.lowerSection}>
            <label className={styles.settingsLabel}>Team Name</label>
            <div className={styles.teamFieldSection}>
              <TextField
                placeholder='My Team'
                value={teamName}
                onChange={setTeamName}
                className={styles.settingsField}
              />
              <span
                className={styles.teamFieldSave}
                onClick={() => {
                  editOrganization({ name: teamName }).then(() => {
                    addInfoToast('Team name updated');
                  });
                }}
              >
                Save
              </span>
              {account.role === 'admin' && (
                <>
                  <Button
                    variant='outline-primary'
                    className='tw-absolute tw-top-[40px] tw-right-[40px]'
                    onClick={() => setInviteStep(1)}
                  >
                    {isSSO ? 'Add Users' : 'Invite Team'}
                  </Button>
                  <InviteTeamModal step={inviteStep} setStep={setInviteStep} />
                </>
              )}
            </div>
            <UserList
              curAccount={account}
              displayAccounts={[...org.team_accounts].filter(
                ({ auth_id }) => !!auth_id
              )}
              label='Your Team'
            />
            <UserList
              curAccount={account}
              displayAccounts={[...org.team_accounts].filter(
                ({ auth_id }) => !auth_id
              )}
              label='Pending'
            />
          </div>
        </TabsContent>
        <TabsContent value='branding'>
          <BrandingTab />
        </TabsContent>
        <TabsContent value='notifications'>
          <div className={styles.sectionSeparator} />
          <div className={styles.lowerSection}>
            <label className={styles.settingsLabel}>
              Form Integration Errors{' '}
              <InlineTooltip text='Receive email alerts when form integrations fail' />
            </label>
            <YesNoSwitch
              id='form-integration-alert'
              checked={account.receive_error_notifications}
              onCheckedChange={(value) =>
                editAccount({ receive_error_notifications: value })
              }
            />
          </div>
        </TabsContent>
        {account.role === 'admin' && (
          <TabsContent value='billing'>
            <div className={styles.sectionSeparator} />
            <div className={styles.lowerSection}>
              <div className={styles.planSection}>
                <div className={styles.activitySection}>
                  <label className={styles.subsectionLabel}>
                    Activity since {formatMonthAndDay(billStart)}
                  </label>
                  <label className={styles.settingsLabel}>
                    Total Submissions
                  </label>
                  {submissionMetrics ? (
                    <>
                      <span className={styles.totalSubmissions}>
                        {formatNumeric(totalSubmissions)}
                      </span>

                      <div className={styles.submissionSummarySection}>
                        {(submissionMetrics as any)
                          .filter(
                            (m: any, i: any) => i < MOST_ACTIVE_FORMS_COUNT
                          )
                          .map((m: any) => (
                            <div className={styles.submissionItem} key={m.id}>
                              <span className={styles.count}>
                                {formatNumeric(m.submissions)}
                              </span>
                              <span className={styles.form}>{m.formName}</span>
                            </div>
                          ))}
                      </div>

                      {Boolean(totalSubmissions) && (
                        <>
                          <Button
                            variant='ghost'
                            title='View submission counts for all forms for this month'
                            onClick={() => setShowSubmissionsModal(true)}
                          >
                            VIEW ALL
                          </Button>
                          <SubmissionSummaryModal
                            submissionMetrics={submissionMetrics}
                            show={showSubmissionsModal}
                            // @ts-expect-error TS(2322) FIXME: Type '(show: any) => void' is not assignable to ty... Remove this comment to see the full error message
                            setShow={(show: any) =>
                              setShowSubmissionsModal(show)
                            }
                          />
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <div className={styles.loadingSubmissions}>
                        <>
                          <Spinner
                            className={styles.loadingSubmissionsSpinner}
                            animation='border'
                            role='status'
                          />
                          Loading...
                        </>
                      </div>
                    </>
                  )}
                </div>
                {/* @ts-expect-error TS(2322) FIXME: Type 'Dispatch<SetStateAction<boolean>>' is not as... Remove this comment to see the full error message */}
                <PlanSelector setShowPaymentMethod={setShowPaymentMethod} />
              </div>
            </div>
            {!enterprise && (
              <>
                <div className={styles.sectionSeparator} />
                <div className={styles.lowerSection}>
                  <div className={styles.billingSection}>
                    <div className={styles.billMetricsSection}>
                      <label className={styles.settingsLabel}>
                        {bill.bill_date
                          ? `Next monthly bill on ${formatDate(
                              bill.bill_date,
                              false
                            )}`
                          : 'No upcoming bill.'}
                      </label>
                      <span className={styles.billAmount}>
                        {bill.bill_date && formatMoneyUSD(bill.amount)}
                      </span>

                      {submissions > currentPlan.monthlySubmissions && (
                        <>
                          <label className={styles.settingsLabel}>
                            You are over your submissions limit for the month.
                          </label>
                          {freeTier && (
                            <span className={styles.billAmount}>
                              Submissions disabled through{' '}
                              {formatMonthAndDay(billEnd)}
                            </span>
                          )}
                        </>
                      )}
                    </div>
                    <div>
                      {showPaymentMethod && (
                        <div>
                          <label className={styles.settingsLabel}>
                            Payment Details
                          </label>
                          <div className={styles.paymentMethod}>
                            <PaymentMethod />
                          </div>
                        </div>
                      )}
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          editOrganization({ receipt_email: receiptEmail })
                            .then(() =>
                              addInfoToast('Payment receipt email saved')
                            )
                            .catch((e: any) => addInfoToast(e.message));
                        }}
                      >
                        <label className={styles.settingsLabel}>
                          Email Receipt
                        </label>
                        <div className={styles.billingFieldSetting}>
                          <TextField
                            placeholder='Enter Email Address'
                            type='email'
                            value={receiptEmail}
                            onChange={setReceiptEmail}
                            className={classNames(
                              styles.settingsField,
                              styles.email
                            )}
                          />
                          <Button type='submit'>Save</Button>
                        </div>
                      </form>
                    </div>
                    <div>
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          editOrganization({
                            tax_id: taxId,
                            tax_id_type: taxIdType
                          })
                            .then(() => addInfoToast('Tax ID saved'))
                            .catch((e: any) => addInfoToast(e.message));
                        }}
                      >
                        <label className={styles.settingsLabel}>Tax Id</label>
                        <div className={styles.billingFieldSetting}>
                          <div className={styles.taxFields}>
                            <DropdownField
                              className={classNames(
                                styles.settingsField,
                                styles.taxIdType
                              )}
                              required
                              placeholder='Select Tax ID Type'
                              onChange={(event: any) =>
                                setTaxIdType(event.target.value)
                              }
                              selected={taxIdType}
                              options={[
                                { value: '', display: '', disabled: true },
                                ...taxIdTypes.map(
                                  ({
                                    flag,
                                    countryName,
                                    taxIdType,
                                    taxIdTypeDescription
                                  }) => ({
                                    value: taxIdType,
                                    display: `${flag} ${countryName} - ${taxIdTypeDescription}`
                                  })
                                )
                              ]}
                            />

                            <TextField
                              placeholder='Enter Tax ID'
                              required
                              value={taxId}
                              onChange={setTaxId}
                              className={classNames(
                                styles.settingsField,
                                styles.taxId
                              )}
                            />
                          </div>
                          <div className={styles.buttonGroup}>
                            <Button type='submit'>Save</Button>
                            <Button
                              variant='outline'
                              type='button'
                              onClick={(e) => {
                                setTaxIdType('');
                                setTaxId('');
                                e.preventDefault();
                                editOrganization({
                                  tax_id: '',
                                  tax_id_type: ''
                                })
                                  .then(() => addInfoToast('Tax ID cleared'))
                                  .catch((e: any) => addInfoToast(e.message));
                              }}
                            >
                              Clear
                            </Button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </>
            )}
          </TabsContent>
        )}
      </Tabs>
    </>
  ) : null;
}
