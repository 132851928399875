import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import classNames from 'classnames';
import styles from './styles.module.scss';
import Switch from '../../components/Core/Switch';
import { useAppSelector } from '../../hooks';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import useFeatheryRedux from '../../redux';

export default function AllFieldsToggle({ empty = false }) {
  const { formId } = useParams<{ formId: string }>();
  const {
    editPanel,
    toasts: { addInfoToast }
  } = useFeatheryRedux();

  const logicAllFields = useAppSelector(
    (state) => state.panels.panels[formId].logic_all_fields
  );
  const [curLogicAllFields, setCurLogicAllFields] =
    useState<boolean>(logicAllFields);

  const toggleOtherFormFields = (checked: boolean) => {
    setCurLogicAllFields(checked);
    const message = checked
      ? 'Fields across all forms are now usable in your rules'
      : 'Only fields in your current form can now be used in your rules';
    editPanel({ panelId: formId, logic_all_fields: checked }).then(() =>
      addInfoToast(message)
    );
  };

  return (
    <OverlayTrigger
      placement='top'
      overlay={
        <Tooltip>
          Allow your rules to access fields from other forms. This may cause a
          performance impact if you have a lot of additional forms & fields{' '}
        </Tooltip>
      }
    >
      <div
        className={classNames(
          styles.switchContainer,
          empty && styles.emptyState
        )}
      >
        <label className={styles.otherFieldsSwitch}>
          <Switch
            id='other-form-fields'
            checked={curLogicAllFields}
            onCheckedChange={toggleOtherFormFields}
          />
          Enable all form fields
        </label>
      </div>
    </OverlayTrigger>
  );
}
