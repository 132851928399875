/* eslint-disable react-hooks/exhaustive-deps */

import { ContextMenu, DynamicTextTooltip, TextField } from '../../../Core';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import {
  stopReactEventPropagation,
  useGlobalMouseDownToggle
} from '../../../Core/util';

import {
  CopyIcon,
  EditIcon,
  OpenOverflowIcon,
  TrashIcon,
  UndoIcon
} from '../../../Icons';
import {
  assetOperation,
  elementOperation,
  elementTypeToAsset
} from '../../../../utils/themes';
import { uniqifyKey } from '../../../../utils/format';
import { produce } from 'immer';
import styles from './styles.module.scss';
import useFeatheryRedux from '../../../../redux';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import useElementDrag, {
  ElementDragOperation
} from '../../../CustomDragLayer/hooks/useElementDrag';
import { Viewport } from '../../../RenderingEngine/GridInGrid/engine';
import { useAppSelector } from '../../../../hooks';

function AssetsMenuItem({
  theme,
  setTheme,
  mode,
  asset,
  handleUpdates,
  icon,
  elementType,
  onElementMouseDown,
  listStyling = false,
  context,
  deleteAssetFromTheme,
  disableContextMenu = false
}: any) {
  const {
    toasts: { addToast }
  } = useFeatheryRedux();
  const panelThemeAssetUse = useAppSelector(
    (state) => state.themes.panelThemeAssetUse
  );
  const assetUses = useMemo(
    () =>
      panelThemeAssetUse.filter((form: any) =>
        form.assets_in_use.some((a: any) => a.id === asset?.id)
      ),
    [panelThemeAssetUse]
  );

  const ref = useRef<HTMLDivElement>(null);
  const [showSubMenu, setShowSubMenu] = useGlobalMouseDownToggle([ref]);
  const [position, setPosition] = useState({});

  const assetNameRef = useRef();
  const [assetName, setAssetName] = useState(asset?.key ?? 'Primary');
  const [assetNameError, setAssetNameError] = useState(false);
  const [canRename, setCanRename] = useState(false);

  const drag = useElementDrag(() => ({
    operation: ElementDragOperation.New,
    type: elementType,
    assetId: asset?.id,
    opts: {
      preview: {
        anchor: 'center'
      }
    }
  }));

  // When the user chooses to rename the asset, we want to automatically focus the input element
  // When the focus is removed we want to complete the renaming flow and request to the API with the new name
  useEffect(() => {
    if (canRename) {
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      assetNameRef.current.focus();
    }
  }, [canRename]);

  function revealAssetsSubMenu(event: any) {
    event.preventDefault();

    if (asset) {
      setShowSubMenu(true);
      setPosition({ x: event.clientX, y: event.clientY });
    }
  }

  function startRenamingAsset(event: any) {
    event.preventDefault();
    stopReactEventPropagation(event);
    setCanRename(true);
  }

  function finishRenamingAsset() {
    if (assetName === asset.key) {
      // If the user didn't change the name, we can skip the API request
      setCanRename(false);
    } else if (assetName !== '') {
      const assetProp = elementTypeToAsset(elementType);
      setTheme({
        theme: produce(theme, (draft: any) => {
          const index = draft[assetProp].findIndex(
            (a: any) => a.id === asset.id
          );
          draft[assetProp][index].key = assetName;
        })
      });

      setCanRename(false);
    } else {
      // If the asset name fails validation, refocus the input element so the user can try again
      setAssetNameError(true);
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      assetNameRef.current.focus();
    }
  }

  function deleteAsset(event: any) {
    stopReactEventPropagation(event);
    const assetProp = elementTypeToAsset(elementType);
    if (deleteAssetFromTheme) deleteAssetFromTheme(assetProp, asset.id);
    else {
      setTheme({
        theme: produce(theme, (draft: any) => {
          draft[assetProp] = draft[assetProp].filter(
            (a: any) => a.id !== asset.id
          );
        })
      });
    }

    addToast({
      text: (
        <>
          <strong>{assetName}</strong> was deleted.
        </>
      ),
      icon: <TrashIcon />
    });
  }

  function duplicateAsset() {
    const sourceAssetName = asset.key;
    const assetProp = elementTypeToAsset(elementType);

    const newAssetName = uniqifyKey(
      sourceAssetName,
      theme[assetProp].map((asset: any) => asset.key)
    );
    const newAsset = {
      ...asset,
      id: uuidv4(),
      key: newAssetName
    };
    setTheme({
      theme: produce(theme, (draft: any) => {
        draft[assetProp].push(newAsset);
      })
    });

    addToast({
      text: (
        <>
          New asset: <strong>{newAssetName}</strong> was created from
          {` ${sourceAssetName}`}.
        </>
      )
    });
  }

  function resetToTheme() {
    const operationCreator =
      context === 'theme' ? elementOperation : assetOperation;
    // reset styles and mobile styles
    const operations = [
      operationCreator({
        viewport: Viewport.Desktop,
        elementType,
        asset,
        styleReset: Object.keys(asset?.styles || {})
      }),
      operationCreator({
        viewport: Viewport.Mobile,
        elementType,
        asset,
        styleReset: Object.keys(asset?.mobile_styles || {})
      })
    ];

    const hasTextStyles = Boolean(asset.properties?.text_formatted);
    if (hasTextStyles) {
      // Reset richtext styles also
      const newTextFormatted = asset.properties.text_formatted.map(
        (chunk: any) => ({
          ...chunk,
          attributes: {}
        })
      );
      operations.push(
        operationCreator({
          elementType,
          asset,
          propUpdate: { properties: { text_formatted: newTextFormatted } }
        })
      );
    }

    // reset both desktop and mobile styles
    handleUpdates(operations);

    addToast({
      text: (
        <>
          Asset: <strong>{asset.key}</strong> was reset to theme settings.
        </>
      )
    });
  }

  return (
    <div
      className={classNames(styles.item, !listStyling && styles.menuStyling)}
      style={{ paddingLeft: mode === 'click' ? '12px' : undefined }}
      {...(mode === 'drag' && { ref: drag })}
      {...(['nav', 'click'].includes(mode) &&
        onElementMouseDown && {
          onMouseDown: () => onElementMouseDown(elementType, asset)
        })}
      onContextMenu={revealAssetsSubMenu}
    >
      <div className={styles.iconAndLabel}>
        <div
          className={classNames(
            styles.itemIcon,
            listStyling && styles.listStyling
          )}
        >
          {icon}
        </div>
        <DynamicTextTooltip
          text={assetName}
          maxLength={13}
          allowShow={!showSubMenu}
        >
          {canRename ? (
            <TextField
              ref={assetNameRef}
              className={classNames(
                styles.assetName,
                assetNameError ? styles.errored : '',
                !listStyling ? styles.menuStyling : ''
              )}
              value={assetName}
              disabled={!canRename}
              onChange={(name: any) => {
                setAssetNameError(false);
                setAssetName(name);
              }}
              onComplete={() => {
                finishRenamingAsset();
                // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
                assetNameRef.current.blur();
              }}
            />
          ) : (
            <span className={styles.label}>{assetName}</span>
          )}
        </DynamicTextTooltip>
      </div>
      {!disableContextMenu && asset && (
        <OpenOverflowIcon
          className={styles.overflowIcon}
          onClick={revealAssetsSubMenu}
        />
      )}
      {!disableContextMenu && (
        <ContextMenu
          ref={ref}
          position={position as { x: number; y: number }}
          show={showSubMenu}
          close={() => setShowSubMenu(false)}
          actions={[
            {
              onMouseDown: resetToTheme,
              Icon: UndoIcon,
              title: 'Reset to Theme'
            },
            {
              onMouseDown: startRenamingAsset,
              Icon: EditIcon,
              title: 'Rename'
            },
            {
              onMouseDown: duplicateAsset,
              Icon: CopyIcon,
              title: 'Duplicate'
            },
            assetUses.length === 0 && {
              onMouseDown: deleteAsset,
              Icon: TrashIcon,
              title: 'Delete'
            }
          ]}
        />
      )}
    </div>
  );
}

export default memo(AssetsMenuItem);
