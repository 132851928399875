import { memo } from 'react';

import styles from './styles.module.scss';

function AnimatedConnectionLine({ fromX, fromY, toX, toY, hide }: any) {
  return hide ? null : (
    <g>
      <defs>
        <marker
          id='arrowhead'
          markerWidth='3'
          markerHeight='5'
          refX='1.5'
          refY='2.5'
          orient='auto'
        >
          <polygon points='0 0, 3 2.5, 0 5' fill='#e2626e' />
        </marker>
      </defs>
      <line
        x1={fromX}
        y1={fromY}
        x2={toX}
        y2={toY}
        className={styles.connectionLine}
        markerEnd='url(#arrowhead)'
      />
    </g>
  );
}

export default memo(AnimatedConnectionLine);
