import * as Icon from '../Icons';

import {
  TYPE_BUTTON,
  TYPE_CONTAINER,
  TYPE_IMAGE,
  TYPE_PROGRESS_BAR,
  TYPE_TEXT,
  TYPE_VIDEO
} from '../../utils/elements';

const elementEntries: Record<
  string,
  { label: string; type: string; icon: any }
> = {
  [TYPE_TEXT]: {
    label: 'Text',
    type: 'basic',
    icon: <Icon.TextIcon />
  },
  [TYPE_BUTTON]: {
    label: 'Button',
    type: 'basic',
    icon: <Icon.ButtonIcon />
  },
  [TYPE_PROGRESS_BAR]: {
    label: 'Progress',
    type: 'basic',
    icon: <Icon.ProgressIcon />
  },
  [TYPE_IMAGE]: {
    label: 'Image',
    type: 'basic',
    icon: <Icon.ImageIcon />
  },
  [TYPE_VIDEO]: {
    label: 'Video',
    type: 'basic',
    icon: <Icon.VideoIcon />
  },
  text_field: {
    label: 'Text Field',
    type: 'field',
    icon: <Icon.TextFieldIcon />
  },
  text_area: {
    label: 'Text Area',
    type: 'field',
    icon: <Icon.TextAreaIcon />
  },
  dropdown: {
    label: 'Dropdown',
    type: 'field',
    icon: <Icon.DropdownIcon />
  },
  dropdown_multi: {
    label: 'Dropdown Multiselect',
    type: 'field',
    icon: <Icon.MultiselectDropdownIcon />
  },
  checkbox: {
    label: 'Checkbox',
    type: 'field',
    icon: <Icon.CheckboxIcon />
  },
  matrix: {
    label: 'Matrix',
    type: 'field',
    icon: <Icon.MatrixIcon />
  },
  multiselect: {
    label: 'Checkbox Group',
    type: 'field',
    icon: <Icon.CheckboxGroupIcon />
  },
  select: {
    label: 'Radio Group',
    type: 'field',
    icon: <Icon.RadioGroupIcon />
  },
  button_group: {
    label: 'Button Group',
    type: 'field',
    icon: <Icon.ButtonGroupIcon />
  },
  date_selector: {
    label: 'Date Selector',
    type: 'field',
    icon: <Icon.DateSelectorIcon />
  },
  file_upload: {
    label: 'File Upload',
    type: 'field',
    icon: <Icon.FileElementUploadIcon />
  },
  signature: {
    label: 'Signature',
    type: 'field',
    icon: <Icon.SignatureIcon />
  },
  email: {
    label: 'Email',
    type: 'field',
    icon: <Icon.EmailIcon />
  },
  phone_number: {
    label: 'Phone',
    type: 'field',
    icon: <Icon.PhoneNumberIcon />
  },
  pin_input: {
    label: 'PIN Input',
    type: 'field',
    icon: <Icon.PINIcon />
  },
  password: {
    label: 'Password',
    type: 'field',
    icon: <Icon.PasswordIcon />
  },
  integer_field: {
    label: 'Number',
    type: 'field',
    icon: <Icon.NumberIcon />
  },
  slider: {
    label: 'Slider',
    type: 'field',
    icon: <Icon.SliderIcon />
  },
  rating: {
    label: 'Rating',
    type: 'field',
    icon: <Icon.RatingIcon />
  },
  url: {
    label: 'URL',
    type: 'field',
    icon: <Icon.URLIcon />
  },
  ssn: {
    label: 'SSN',
    type: 'field',
    icon: <Icon.SSNumberIcon />
  },
  hex_color: {
    label: 'Color Picker',
    type: 'field',
    icon: <Icon.ColorPickerIcon />
  },
  gmap_line_1: {
    label: 'Street Address',
    type: 'field',
    icon: <Icon.AddressIcon />
  },
  gmap_line_2: {
    label: 'Address Line 2',
    type: 'field',
    icon: <Icon.AddressIcon />
  },
  gmap_city: {
    label: 'City',
    type: 'field',
    icon: <Icon.AddressIcon />
  },
  gmap_state: {
    label: 'State',
    type: 'field',
    icon: <Icon.AddressIcon />
  },
  gmap_zip: {
    label: 'Zip Code',
    type: 'field',
    icon: <Icon.AddressIcon />
  },
  gmap_country: {
    label: 'Country',
    type: 'field',
    icon: <Icon.AddressIcon />
  },
  payment_method: {
    label: 'Payment Method',
    type: 'field',
    icon: <Icon.PaymentMethodIcon />
  },
  qr_scanner: {
    label: 'QR Code Scanner',
    type: 'field',
    icon: <Icon.QRScannerIcon />
  },
  [TYPE_CONTAINER]: {
    label: 'Container',
    type: 'layout',
    icon: <Icon.ContainerIcon />
  }
};

const OTHER_FIELDS = ['select', 'multiselect'];
const OPTION_FIELDS = [
  'button_group',
  'dropdown',
  'dropdown_multi',
  'matrix',
  ...OTHER_FIELDS
];
const MULTI_SELECT_FIELDS = ['multiselect', 'button_group', 'dropdown_multi'];
const IMAGE_FIELDS = ['file_upload', 'url', 'text_field', 'text_area'];
const TEXT_BASED_FIELDS = [
  'date_selector',
  'password',
  'dropdown',
  'dropdown_multi',
  'email',
  'phone_number',
  'gmap_line_1',
  'gmap_line_2',
  'gmap_city',
  'gmap_state',
  'gmap_country',
  'gmap_zip',
  'integer_field',
  'ssn',
  'text_field',
  'text_area',
  'url',
  'payment_method'
];
const NUMBER_FIELDS = ['slider', 'integer_field', 'rating'];
// Mapped to max length
const MINMAX_LENGTH_FIELDS = {
  text_field: 1024, // Max storage limit on backend column
  text_area: 16384, // Max storage limit on backend column
  integer_field: Number.MAX_SAFE_INTEGER,
  slider: 2147483647, // Max size of Django integer field
  email: 1024, // Max storage limit on backend column
  password: 1024, // Max storage limit on backend column
  gmap_line_1: 1024, // Max storage limit on backend column
  gmap_zip: 10
};
const FILE_FIELDS = ['file_upload', 'signature'];
const AUTO_SUBMITTABLE_FIELDS = [
  'button_group',
  'date_selector',
  'file_upload',
  'dropdown',
  'gmap_state',
  'gmap_country',
  'pin_input',
  'qr_scanner',
  'select',
  'hex_color',
  'ssn',
  'rating'
];
const LENGTH_FIELDS = ['pin_input'];
const ACTION_FIELDS = AUTO_SUBMITTABLE_FIELDS;

function defaultFieldValue(type: any) {
  if (type === 'checkbox') return false;
  else if (type === 'multiselect') return [];
  else if (NUMBER_FIELDS.includes(type)) return 0;
  else if (type === 'hex_color') return '000000';
  else return '';
}

export default elementEntries;
export {
  AUTO_SUBMITTABLE_FIELDS,
  OTHER_FIELDS,
  OPTION_FIELDS,
  IMAGE_FIELDS,
  TEXT_BASED_FIELDS,
  NUMBER_FIELDS,
  MINMAX_LENGTH_FIELDS,
  MULTI_SELECT_FIELDS,
  LENGTH_FIELDS,
  ACTION_FIELDS,
  FILE_FIELDS,
  defaultFieldValue
};
