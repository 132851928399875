function QQCatalyst({ width = 190, height = 45 }) {
  return (
    <img
      alt='QQCatalyst logo'
      src={require('./QQCatalyst.png')}
      style={{ width, height }}
    />
  );
}

export default QQCatalyst;
