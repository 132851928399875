function TurboRater({ width = 200, height = 90 }) {
  return (
    <img
      alt='TurboRater logo'
      src={require('./TurboRater.webp')}
      style={{ width, height }}
    />
  );
}

export default TurboRater;
