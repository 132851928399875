export default function UsersIcon({
  width = 32,
  height = 32,
  color = 'currentColor'
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.131 15.737a5.368 5.368 0 1 0 0-10.737 5.368 5.368 0 0 0 0 10.737ZM20.262 23.581c0 1.799-3.649 2.737-8.131 2.737S4 25.38 4 23.58c0-1.798 3.649-3.778 8.131-3.778s8.131 1.98 8.131 3.778Z'
        stroke={color}
        strokeWidth='3'
        strokeMiterlimit='10'
        strokeLinejoin='round'
      />
      <path
        d='M22.605 5.026a1.5 1.5 0 0 0 0 3v-3Zm0 6.593a1.5 1.5 0 0 0 0 3v-3Zm0-3.593c.988 0 1.784.806 1.784 1.783h3a4.787 4.787 0 0 0-4.784-4.783v3Zm1.784 1.783c0 .986-.83 1.81-1.784 1.81v3c2.642 0 4.784-2.198 4.784-4.81h-3ZM22.612 16.181a1.5 1.5 0 1 0-.43 2.97l.43-2.97Zm2.82 5.346a1.5 1.5 0 1 0 .914 2.858l-.914-2.858Zm-3.25-2.377c1.452.211 2.648.655 3.437 1.145.9.558.881.904.881.785h3c0-1.527-1.204-2.654-2.299-3.333-1.204-.748-2.81-1.308-4.589-1.566l-.43 2.97Zm4.318 1.93c0-.153.091-.171-.049-.05-.157.136-.477.324-1.02.497l.915 2.858c.761-.244 1.496-.59 2.07-1.087.588-.51 1.084-1.258 1.084-2.218h-3Z'
        fill={color}
      />
    </svg>
  );
}
