import { useEffect, useState } from 'react';
import useFeatheryRedux from '../../../redux';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../hooks';

export default function useReceiveOauthMessage(
  integrationType: string,
  redirectUrl: string,
  hasToken: boolean,
  domain?: string
) {
  const { formId } = useParams<{ formId: string }>();

  const [childWindow, setChildWindow] = useState<Window | null>(null);
  const org = useAppSelector((state) => state.accounts.organization);
  const { exchangeOAuthToken, revokeOAuthToken } = useFeatheryRedux();

  useEffect(() => {
    if (childWindow) {
      const eventListener = (e: any) => {
        // Verify this is an event we care about
        if (!childWindow || e.origin !== 'https://app.feathery.io') return;
        const code = e.data;
        if (
          typeof code !== 'string' ||
          code.substring(0, 15) !== 'feathery-oauth:'
        )
          return;

        exchangeOAuthToken({
          code: code.substring(15),
          type: integrationType,
          panelId: formId,
          egnyte_domain: domain
        });
        childWindow.close();
      };
      window.addEventListener('message', eventListener, false);
      return () => window.removeEventListener('message', eventListener, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childWindow]);

  return () => {
    if (hasToken) {
      revokeOAuthToken({
        type: integrationType,
        egnyte_domain: domain,
        panelId: formId
      });
    } else {
      const winRef = window.open(`${redirectUrl}&state=${org?.id}`);
      if (winRef) {
        winRef.focus();
        setChildWindow(winRef);
      }
    }
  };
}
