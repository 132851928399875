import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const getTasks = createAsyncThunk(
  'tasks/getTasks',
  async ({
    token,
    page = null,
    envName,
    searchKey,
    sortKey,
    sortDirection,
    ...filterParams
  }: {
    token: string;
    page: null | string;
    envName: string;
    searchKey: string;
    sortKey: string;
    sortDirection: string;
    panel: any;
    extraction?: string;
    filterParams: any;
  }) => {
    const response = await FeatheryAPI.getTasks(token, {
      page,
      environment_name: envName,
      search: searchKey,
      sort_order: sortKey,
      sort_direction: sortDirection,
      ...filterParams
    });

    if (response.status === 200) {
      return await response.json();
    } else {
      throw new Error('Could not get tasks');
    }
  }
);

const tasksSlice = createSlice({
  name: 'tasks',
  initialState: { tasks: [], count: 0, prevUrl: '', nextUrl: '' },
  reducers: {
    clearTasks(state) {
      state.tasks = [];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getTasks.fulfilled, (state, action) => {
      const { payload } = action;
      state.tasks = payload.results;
      state.count = payload.count;
      state.prevUrl = payload.previous;
      state.nextUrl = payload.next;
    });
  }
});

const syncFunctions = tasksSlice.actions;

export default tasksSlice.reducer;
export { getTasks, syncFunctions };
