import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const getABTests = createAsyncThunk(
  'abtests/getABTests',
  async ({ token }: { token: string }) => {
    const response = await FeatheryAPI.getABTests(token);
    if (response.status === 200) {
      return await response.json();
    } else {
      throw new Error('Could not get AB test');
    }
  }
);

const createABTest = createAsyncThunk(
  'abtests/createABTest',
  async ({ token, ...data }: { token: string }) => {
    const response = await FeatheryAPI.createABTest(token, data);
    if (response.status === 201) {
      return await response.json();
    }

    throw new Error('Could not create AB test');
  }
);

const editABTest = createAsyncThunk(
  'abtests/editABTest',
  async ({ token, ...data }: { token: string }) => {
    const response = await FeatheryAPI.editABTest(token, data);

    if (response.status === 200) {
      return await response.json();
    }

    throw new Error('Could not edit AB test');
  }
);

const deleteABTest = createAsyncThunk(
  'abtests/deleteABTest',
  async ({ abTestId, token }: { abTestId: string; token: string }) => {
    const response = await FeatheryAPI.deleteABTest(token, { abTestId });
    if (response.status !== 204) {
      throw new Error('Could not delete AB Test');
    }
  }
);

const abTestsSlice = createSlice({
  name: 'abTests',
  initialState: {
    abTests: [] as any[]
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getABTests.fulfilled, (state, action) => {
      state.abTests = action.payload;
    });
    builder.addCase(createABTest.fulfilled, (state: any, action: any) => {
      state.abTests = [...state.abTests, action.payload];
    });
    builder.addCase(editABTest.fulfilled, (state: any, action: any) => {
      const {
        meta: { arg },
        payload
      } = action;

      const sID = state.abTests.findIndex(
        (abTest: any) => abTest.id === arg.abTestId
      );
      const newABTests = state.abTests.filter(
        (abTest: any) => abTest.id !== arg.abTestId
      );
      newABTests.splice(sID, 0, payload);
      return { ...state, abTests: newABTests };
    });
    builder.addCase(deleteABTest.fulfilled, (state: any, action: any) => {
      const {
        meta: { arg }
      } = action;
      const index = state.abTests.findIndex(
        (abTest: any) => abTest.id === arg.abTestId
      );
      if (index >= 0) {
        state.abTests.splice(index, 1);
      }
    });
  }
});

export default abTestsSlice.reducer;
export { getABTests, createABTest, editABTest, deleteABTest };
