import { useAppSelector } from '../../../../hooks';
import useIntegrations from '../../../FormIntegrations/useIntegrations';
import { INTEGRATIONS } from '../../../FormIntegrations';
import { useParams } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import useFeatheryRedux from '../../../../redux';

export default function useQuikFieldConfig(servarMeta: any) {
  const { formId } = useParams<{ formId: string }>();

  const { fetchQuikFields } = useFeatheryRedux();

  const isEnterprise = useAppSelector((state) => {
    const org = state.accounts.organization;
    return !!(org && org.tier >= 4);
  });

  const quikIntegration = useIntegrations({
    type: INTEGRATIONS.QUIK,
    panelId: formId
  });

  const isQuikConnected = useAppSelector((state) => {
    const org = state.accounts.organization;
    return !!(
      quikIntegration &&
      org &&
      isEnterprise &&
      org.enterprise_features.quik
    );
  });

  const quikMeta = quikIntegration?.data.secret_metadata;

  const quikFields = useAppSelector((state) => state.integrations.quikFields);

  useEffect(() => {
    if (!isQuikConnected) return;

    quikMeta.form_ids.forEach((quikFormId: string) => {
      quikFormId = quikFormId.split(':')[0];
      if (!quikFields[quikFormId])
        fetchQuikFields({ panelId: formId, quikFormId });
    });
  }, [isQuikConnected, quikFields]);

  const quikFieldOptions = useMemo(() => {
    if (!isQuikConnected) return [];

    const fieldOptions: string[] = Array.from(
      quikMeta.form_ids?.reduce((acc: Set<string>, quikFormId: string) => {
        quikFormId = quikFormId.split(':')[0];
        quikFields[quikFormId]?.forEach((quikField) =>
          acc.add(quikField.FullFieldName)
        );
        return acc;
      }, new Set<string>())
    );

    (servarMeta.quik_field_ids || []).forEach((quikFieldID: string) => {
      if (!fieldOptions.find((value: string) => value === quikFieldID)) {
        fieldOptions.push(quikFieldID);
      }
    });

    const originalFieldOptions = fieldOptions
      .sort()
      .map((option: string) => ({ value: option, label: option }));

    return [...originalFieldOptions];
  }, [quikFields, quikMeta, isQuikConnected, servarMeta.quik_field_ids]);

  return { isQuikConnected, quikFieldOptions };
}
