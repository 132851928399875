import {
  elementTypeNameMap,
  fieldTypeNameMap,
  TYPE_BUTTON,
  TYPE_FIELD,
  TYPE_IMAGE,
  TYPE_PROGRESS_BAR,
  TYPE_TEXT,
  TYPE_VIDEO
} from '../../../utils/elements';
import { Cell } from '../GridInGrid/engine';
import { isObject } from '../GridInGrid/engine/utils/utilities';
import { MOBILE_DIVERGED_VIEWPORT_PROPERTIES } from '../GridInGrid/engine/viewports';

function getGig() {
  return (window as any).featheryGIG;
}

export function getStepElements(step: any) {
  return [
    ...step.progress_bars.map((e: any, i: any) => [e, TYPE_PROGRESS_BAR, i]),
    ...step.images.map((e: any, i: any) => [e, TYPE_IMAGE, i]),
    ...step.videos.map((e: any, i: any) => [e, TYPE_VIDEO, i]),
    ...step.texts.map((e: any, i: any) => [e, TYPE_TEXT, i]),
    ...step.buttons.map((e: any, i: any) => [e, TYPE_BUTTON, i]),
    ...step.servar_fields.map((e: any, i: any) => [e, TYPE_FIELD, i])
  ];
}

export const applyToViewportProperties = (step: any, applyFunction: any) => {
  const elements = getStepElements(step);

  elements.forEach(([element]) => {
    applyFunction(element, MOBILE_DIVERGED_VIEWPORT_PROPERTIES.elements);
  });

  step.subgrids.forEach((subgrid: any) => {
    applyFunction(subgrid, MOBILE_DIVERGED_VIEWPORT_PROPERTIES.subgrids);
  });

  applyFunction(step, MOBILE_DIVERGED_VIEWPORT_PROPERTIES.step);

  return step;
};

export const hasViewportProperties = (step: any, viewportName: any) => {
  let hasProps = false;

  applyToViewportProperties(step, (item: any, props: any) => {
    props.forEach((prop: any) => {
      const viewportProp = getViewportProp(viewportName, prop);
      if (item[viewportProp]) {
        if (
          isObject(item[viewportProp]) &&
          Object.keys(item[viewportProp]).length === 0
        ) {
          return;
        }

        hasProps = true;
      }
    });
  });

  return hasProps;
};

const getViewportProp = (viewportName: any, propName: any) => {
  return `${viewportName}_${propName}`;
};

export const getNodeLabel = (node: Cell, stepName?: string) => {
  if (!node.parent && !node.isCopy()) {
    if (!stepName && !node.isUnlinked()) {
      return node?.tree?.key;
    } else {
      return stepName;
    }
  } else if (node.servar) {
    return node.servar.key;
  } else if (node.isElement) {
    return elementTypeNameMap[node._type] || fieldTypeNameMap[node._type];
  }

  return node.key;
};

export const refreshNode = (node: Cell | null): Cell | null => {
  if (!node) return null;
  const gig = getGig();
  const nodeIndex = gig.grid.idMap[node.id];
  return gig.grid.children[nodeIndex];
};
