import useMounted from './useMounted';

// Stores and maintains the cleanup function
export const cleanup = (() => {
  let _cleanup: any = null;

  return {
    set(fn: any) {
      if (typeof fn === 'function') _cleanup = fn;
    },
    clear() {
      _cleanup = null;
    },
    run() {
      _cleanup && _cleanup();
    },
    runAndClear() {
      this.run();
      this.clear();
    }
  };
})();

// Set logic to run when detail panel focus changes
export default function useCleanUp({ triggerCleanUp }: any) {
  const mounted = useMounted();

  return {
    mounted,
    clearCleanUp: () => triggerCleanUp && cleanup.clear(),
    setCleanUp: (nextCleanUpFunction: any) =>
      triggerCleanUp && cleanup.set(nextCleanUpFunction)
  };
}
