export default function ThemesIcon({ width = 28, height = 28 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M27.8282 8.86871L8.81152 27.8854H4.11462V23.2458L23.1886 4.17181L27.8282 8.86871Z'
        strokeWidth='2.7704'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.4081 7.95227L24.0478 12.6492'
        strokeWidth='2.7704'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.0453 16.2578L4 9.21241L9.21241 4L16.2578 11.0453'
        strokeWidth='2.4927'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.9547 15.7422L28 22.7876L22.7876 28L15.7422 20.9547'
        strokeWidth='2.4927'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.7637 7.55133L10.8162 9.5561'
        strokeWidth='2.4927'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M24.4487 19.8091L22.8448 21.4702'
        strokeWidth='2.4927'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
