import { Cell } from '../GridInGrid/engine';
import ReactDOM from 'react-dom';
import React from 'react';

const PortalHelper = (props: {
  node?: Cell;
  element?: HTMLElement;
  elementId?: string;
  children: any;
}) => {
  let el = null;
  if (props.element) {
    el = props.element;
  } else if (props.elementId) {
    el = document.getElementById(props.elementId);
  }

  if (!el) return null;

  return ReactDOM.createPortal(props.children, el);
};

export const PortalHelperMemo = React.memo(
  PortalHelper,
  (prevProps, nextProps) => {
    return prevProps.elementId === nextProps.elementId;
  }
);

export default PortalHelper;
