import '../../style/dialog-form.css';

import { TextField } from '../Core';
import { memo, useState } from 'react';
import { Neutral, Positive } from '../Core/Button';
import Label from '../Dialog/Label';
import Dialog from '../Dialog';
import useDialogFocus from '../../utils/useDialogFocus';
import { v4 as uuidv4 } from 'uuid';
import styles from './styles.module.scss';
import ruleFieldsStyles from '../../pages/LogicRuleDetail/styles.module.scss';
import classNames from 'classnames';
import RuleFieldsPanel from '../../pages/LogicRuleDetail/components/RuleFieldsPanel';
import { LogicRule } from '../../pages/FormLogicPage/LogicRuleList';
import { useParams } from 'react-router-dom';

const initData = (nextNum: number, type: string): LogicRule => ({
  id: uuidv4(),
  name: `Rule ${nextNum}`,
  description: '',
  index: 1,
  trigger_event: type === 'form' ? 'submit' : 'post_extraction',
  steps: [],
  elements: [], // Array of element ids or servar keys or empty. Used for onView event only.
  dsl: {
    branches: []
  },
  code: '// Javascript rule code',
  enabled: true,
  valid: true,
  mode: 'rule_builder',
  metadata: {},
  updated_at: new Date().toISOString(),
  created_at: new Date().toISOString()
});

function RuleCreateModal({
  show,
  setShow,
  steps,
  rules,
  onRuleCreate
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  steps: any;
  rules: any;
  onRuleCreate: any;
}) {
  const focusElement = useDialogFocus(show);

  const { formId } = useParams<{ formId?: string }>();
  const type = formId ? 'form' : 'ai';
  const [data, setData] = useState(
    initData(Object.keys(rules).length + 1, type)
  );
  const [error, setError] = useState('');

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const { name } = data;
    if (Object.values(rules).find((rule: any) => rule.name === name)) {
      setError('You already have a rule with that name');
      return;
    }
    setError('');

    // The index is used for determining the run order for the rules that run off the same event
    const newIndex =
      Object.values(rules)
        .filter((rule: any) => rule.trigger_event === data.trigger_event)
        .reduce((acc: number, rule: any) => Math.max(acc, rule.index), 0) + 1;
    const newRule = { ...data, index: newIndex };
    onRuleCreate(newRule);
    setData(initData(Object.keys(rules).length + 2, type));
    setShow(false);
  };

  return (
    <Dialog isOpen={show} onClose={() => setShow(false)} title='New Logic Rule'>
      <form onSubmit={handleSubmit}>
        <div className={ruleFieldsStyles.ruleField}>
          <Label className={ruleFieldsStyles.fieldLabel}>Name</Label>
          <TextField
            required
            ref={focusElement}
            placeholder='Enter rule name'
            value={data.name}
            onComplete={(name: string) =>
              setData({ ...data, name: name.trim() })
            }
          />
        </div>
        <RuleFieldsPanel
          rule={data}
          setRule={setData}
          steps={steps}
          type={type}
          fieldsOnly
        />

        <div
          className={classNames(
            'dialog-form-action',
            'text-center',
            styles.ruleCreateActions
          )}
        >
          <Neutral onClick={() => setShow(false)}>Cancel</Neutral>
          <Positive>Create</Positive>
        </div>
      </form>
      {error && <div>{error}</div>}
    </Dialog>
  );
}

export default memo(RuleCreateModal);
