import { Viewport } from './viewports';
import { Gig } from './Gig';
import { Cell } from './Cell';
import { Model } from './models';
import { BaseTree } from './abstracts';
import { Size } from './types';
import { getNextContainerKey, getSizeName } from './utils';
import { AXIS, AxisDirection } from '../../../../utils/constants';

type GridOptions = {
  step: any;
  unmapped?: any;
  viewport?: Viewport;
};

export class Grid extends BaseTree<Cell> {
  #step: any; // Raw step data
  #gig: Gig; // Reference to Gig
  #unmapped: any; // Unmapped subgrids and elements (position is unset)
  #elements: { [key: string]: boolean }; // Hashmap of elements registered to this grid
  #viewport: Viewport; // Viewport instance which manages the current viewport
  #nextContainerKey: number; // The next container key that will be used as a default key for new containers
  #hashCache: { [key: string]: Cell }; // Hashmap of cell hashes to cell instances

  [key: string]: any;

  constructor(
    gig: Gig,
    { step, unmapped, viewport }: GridOptions,
    root = null
  ) {
    super(root);

    this.#viewport = viewport ?? new Viewport(Viewport.Desktop);
    this.#step = step;
    this.#gig = gig;
    this.#elements = {};
    this.#unmapped = unmapped;
    this.#hashCache = {};

    // Spread the properties of the step's state onto this instance for direct access
    for (const key in step.state) {
      if (this[key as keyof typeof this] === undefined) {
        Object.defineProperty(this, key, {
          get: () => this.#step[key],
          set: (value) => {
            this.#step[key] = value;
          }
        });
      }
    }

    // Calculate the next container key
    this.#nextContainerKey = getNextContainerKey(this.#gig.raw.subgrids);
  }

  /**
   * Register a cell to this grid so that it can be tracked and managed by the grid
   * @param node - Cell to be registered to this grid
   * @param index - Optional. Index to register the cell at
   */
  register(node: Cell, index?: number) {
    super.register(node, index);

    // If the cell is an element, register the element as well
    if (node.isElement && !this.#elements[node.id]) {
      return this.registerElement(node);
    }

    (node.model as Model).setViewport(this.viewport);
  }

  /**
   * Unregister a cell from this grid, removing all references to it
   * @param node - Cell to be unregistered from this grid
   */
  unregister(node: Cell) {
    super.unregister(node);

    // If the cell is an element, unregister the element as well
    if (node.isElement) {
      this.unregisterElement(node);
    }
  }

  /**
   * Register a cell's element to this grid so it can be managed and tracked
   * @param node - Cell that contains the element that is to be registered
   * @returns {boolean} Whether the element was registered successfully
   */
  registerElement(node: Cell) {
    if (this.#viewport.isDesktop() && !this.#elements[node.id]) {
      this.#elements[node.id] = true;
      (node.element as Model).setViewport(this.viewport);

      return true;
    }

    return false;
  }

  /**
   * Unregister a cell's element from this grid, removing all references to it
   * @param node - Cell that contains the element that is to be unregistered
   * @returns {boolean} Whether the element was unregistered successfully
   */
  unregisterElement(node: Cell) {
    if (this.#elements[node.id]) {
      delete this.#elements[node.id];

      return true;
    }

    return false;
  }

  /**
   * Helper function to add a sibling to the target cell.
   * @param node - Cell to add a sibling to
   * @param element - Optional. Element that should be set on the new sibling cell
   * @param before - Optional. Whether to add the sibling before or after the target cell
   * @returns {Cell | null} The new sibling cell
   */
  addSibling(node: Cell, element?: Model, before = false) {
    return node.addSibling(element, before);
  }

  /**
   * Add a cell to the top of the target cell
   * @param node - Cell to add a sibling to
   * @param element - Element to set on the new cell
   * @returns {Cell | null}
   */
  addTop(node: Cell, element?: Model) {
    return node.addTop(element);
  }

  /**
   * Add a cell to the right of the target cell
   * @param node - Cell to add a sibling to
   * @param element - Element to set on the new cell
   * @returns {Cell | null}
   */
  addRight(node: Cell, element?: Model) {
    return node.addRight(element);
  }

  /**
   * Add a cell to the bottom of the target cell
   * @param node - Cell to add a sibling to
   * @param element - Element to set on the new cell
   * @returns {Cell | null}
   */
  addBottom(node: Cell, element?: Model) {
    return node.addBottom(element);
  }

  /**
   * Add a cell to the left of the target cell
   * @param node - Cell to add a sibling to
   * @param element - Element to set on the new cell
   * @returns {Cell | null}
   */
  addLeft(node: Cell, element?: Model) {
    return node.addLeft(element);
  }

  /**
   * Set an element on the target cell
   * @param node - Cell to set the element on
   * @param element - Element to set on the element
   */
  setElement(node: Cell, element?: Model) {
    node.setElement(element);
  }

  /**
   * Remove the element from the target cell
   * @param node - Cell to remove the element from
   */
  removeElement(node: Cell) {
    node.removeElement();
  }

  /**
   * Resize the step
   * @param axis - Axis that is to be resized
   * @param size - New size
   */
  resize(axis: AxisDirection, size: Size) {
    if (axis === AXIS.COL) this.width = `${size.value}${size.unit}`;
    if (axis === AXIS.ROW) this.height = `${size.value}${size.unit}`;

    if (this.root?.hasSingleChild()) {
      this.root.size = this[getSizeName(this.root.axis)];
    }
  }

  /**
   * Increases the next container key and returns it
   * @returns {string}
   */
  getNextContainerKey() {
    const containerKey = `Container ${this.#nextContainerKey}`;

    this.#nextContainerKey += 1;

    return containerKey;
  }

  restructure() {
    super.restructure();
    this.setMiscMapKey('_unmapped', this.#unmapped);
  }

  get nextContainerKey() {
    return this.#nextContainerKey;
  }

  get step() {
    return this.#step;
  }

  get unmapped() {
    return this.#unmapped;
  }

  set unmapped(value: any) {
    this.#unmapped = value;
  }

  get gig() {
    return this.#gig;
  }

  get viewport() {
    return this.#viewport;
  }

  get viewportName() {
    return this.#viewport.activeViewport;
  }

  get elements() {
    return this.#elements;
  }

  get hashCache() {
    return this.#hashCache;
  }
}
