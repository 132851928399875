export default function FormBlankStateIcon(props: any) {
  return (
    <svg
      width='132'
      height='149'
      viewBox='0 0 132 149'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M66.1852 130.704C101.93 130.704 130.909 101.725 130.909 65.8519C130.909 29.9791 101.803 1 66.1852 1C30.44 1 1.46094 29.9791 1.46094 65.8519C1.46094 101.725 30.44 130.704 66.1852 130.704Z'
        fill='#F1F3F9'
        stroke='#BDC2CE'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M104.031 114.033H122.565C124.893 114.033 126.888 112.039 126.888 109.712V23.2911C126.888 20.9644 124.893 18.9701 122.565 18.9701H104.607'
        fill='#F1F3F9'
      />
      <path
        d='M104.031 114.033H122.565C124.893 114.033 126.888 112.039 126.888 109.712V23.2911C126.888 20.9644 124.893 18.9701 122.565 18.9701H104.607L104.031 114.033Z'
        stroke='#6C7589'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M30.4455 114.033H12.4873C10.1594 114.033 8.16406 112.039 8.16406 109.712V23.2911C8.16406 20.9644 10.1594 18.9701 12.4873 18.9701H30.0756'
        fill='#F1F3F9'
      />
      <path
        d='M30.4455 114.033H12.4873C10.1594 114.033 8.16406 112.039 8.16406 109.712V23.2911C8.16406 20.9644 10.1594 18.9701 12.4873 18.9701H30.0756L30.4455 114.033Z'
        stroke='#6C7589'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M20.6562 35.2541C20.104 35.2541 19.6562 35.7018 19.6562 36.2541C19.6562 36.8063 20.104 37.2541 20.6562 37.2541V35.2541ZM29.6353 37.2541C30.1876 37.2541 30.6353 36.8063 30.6353 36.2541C30.6353 35.7018 30.1876 35.2541 29.6353 35.2541V37.2541ZM20.6562 37.2541H29.6353V35.2541H20.6562V37.2541Z'
        fill='#6C7589'
      />
      <path
        d='M20.6562 46.8877C20.104 46.8877 19.6562 47.3354 19.6562 47.8877C19.6562 48.44 20.104 48.8877 20.6562 48.8877V46.8877ZM29.6353 48.8877C30.1876 48.8877 30.6353 48.44 30.6353 47.8877C30.6353 47.3354 30.1876 46.8877 29.6353 46.8877V48.8877ZM20.6562 48.8877H29.6353V46.8877H20.6562V48.8877Z'
        fill='#6C7589'
      />
      <path
        d='M20.6562 58.8537C20.104 58.8537 19.6562 59.3015 19.6562 59.8537C19.6562 60.406 20.104 60.8537 20.6562 60.8537V58.8537ZM29.6353 60.8537C30.1876 60.8537 30.6353 60.406 30.6353 59.8537C30.6353 59.3015 30.1876 58.8537 29.6353 58.8537V60.8537ZM20.6562 60.8537H29.6353V58.8537H20.6562V60.8537Z'
        fill='#6C7589'
      />
      <path
        d='M20.6562 70.8199C20.104 70.8199 19.6562 71.2676 19.6562 71.8199C19.6562 72.3722 20.104 72.8199 20.6562 72.8199V70.8199ZM29.6353 72.8199C30.1876 72.8199 30.6353 72.3722 30.6353 71.8199C30.6353 71.2676 30.1876 70.8199 29.6353 70.8199V72.8199ZM20.6562 72.8199H29.6353V70.8199H20.6562V72.8199Z'
        fill='#6C7589'
      />
      <path
        d='M20.6562 94.0869C20.104 94.0869 19.6562 94.5347 19.6562 95.0869C19.6562 95.6392 20.104 96.0869 20.6562 96.0869V94.0869ZM29.6353 96.0869C30.1876 96.0869 30.6353 95.6392 30.6353 95.0869C30.6353 94.5347 30.1876 94.0869 29.6353 94.0869V96.0869ZM20.6562 96.0869H29.6353V94.0869H20.6562V96.0869Z'
        fill='#6C7589'
      />
      <path
        d='M113.252 36.2541H104.605'
        stroke='#6C7589'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M113.252 48.8877C113.804 48.8877 114.252 48.44 114.252 47.8877C114.252 47.3354 113.804 46.8877 113.252 46.8877V48.8877ZM104.605 46.8877C104.053 46.8877 103.605 47.3354 103.605 47.8877C103.605 48.44 104.053 48.8877 104.605 48.8877V46.8877ZM113.252 46.8877H104.605V48.8877H113.252V46.8877Z'
        fill='#6C7589'
      />
      <path
        d='M113.252 60.8537C113.804 60.8537 114.252 60.406 114.252 59.8537C114.252 59.3015 113.804 58.8537 113.252 58.8537V60.8537ZM104.605 58.8537C104.053 58.8537 103.605 59.3015 103.605 59.8537C103.605 60.406 104.053 60.8537 104.605 60.8537V58.8537ZM113.252 58.8537H104.605V60.8537H113.252V58.8537Z'
        fill='#6C7589'
      />
      <path
        d='M113.252 72.8199C113.804 72.8199 114.252 72.3722 114.252 71.8199C114.252 71.2676 113.804 70.8199 113.252 70.8199V72.8199ZM104.605 70.8199C104.053 70.8199 103.605 71.2676 103.605 71.8199C103.605 72.3722 104.053 72.8199 104.605 72.8199V70.8199ZM113.252 70.8199H104.605V72.8199H113.252V70.8199Z'
        fill='#6C7589'
      />
      <path
        d='M113.252 96.0869C113.804 96.0869 114.252 95.6392 114.252 95.0869C114.252 94.5347 113.804 94.0869 113.252 94.0869V96.0869ZM104.605 94.0869C104.053 94.0869 103.605 94.5347 103.605 95.0869C103.605 95.6392 104.053 96.0869 104.605 96.0869V94.0869ZM113.252 94.0869H104.605V96.0869H113.252V94.0869Z'
        fill='#6C7589'
      />
      <path
        d='M100.283 116.056H34.4365C32.1086 116.056 30.1133 114.159 30.1133 111.945V16.4338C30.1133 14.22 32.1086 12.3224 34.4365 12.3224H100.283C102.611 12.3224 104.607 14.22 104.607 16.4338V111.945C104.607 114.159 102.611 116.056 100.283 116.056Z'
        fill='white'
        stroke='#6C7589'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M90.1849 40.6123H44.2917C43.294 40.6123 42.6289 40.0099 42.6289 39.1063V35.1907C42.6289 34.2872 43.294 33.6848 44.2917 33.6848H90.1849C91.1825 33.6848 91.8477 34.2872 91.8477 35.1907V39.1063C91.8477 39.7087 91.1825 40.6123 90.1849 40.6123Z'
        fill='#F1F3F9'
        stroke='#6C7589'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M90.1849 58.5611H44.2917C43.294 58.5611 42.6289 57.9587 42.6289 57.0551V53.1396C42.6289 52.236 43.294 51.6336 44.2917 51.6336H90.1849C91.1825 51.6336 91.8477 52.236 91.8477 53.1396V57.0551C91.8477 57.9587 91.1825 58.5611 90.1849 58.5611Z'
        fill='#F1F3F9'
        stroke='#6C7589'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M90.1849 76.8425H44.2917C43.294 76.8425 42.6289 76.2401 42.6289 75.3365V71.421C42.6289 70.5174 43.294 69.915 44.2917 69.915H90.1849C91.1825 69.915 91.8477 70.5174 91.8477 71.421V75.3365C91.8477 76.2401 91.1825 76.8425 90.1849 76.8425Z'
        fill='#F1F3F9'
        stroke='#6C7589'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M90.1879 95.4561H71.8972C70.8995 95.4561 70.2344 94.8537 70.2344 93.9501V90.0346C70.2344 89.131 70.8995 88.5286 71.8972 88.5286H90.1879C91.1856 88.5286 91.8507 89.131 91.8507 90.0346V93.9501C91.8507 94.8537 91.1856 95.4561 90.1879 95.4561Z'
        fill='#F1F3F9'
        stroke='#6C7589'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M87.5799 132.773C87.1647 134.537 86.5419 136.406 85.8153 137.963C83.843 141.803 80.7289 144.814 76.8883 146.786C72.9438 148.758 68.2727 149.588 63.6016 148.55C52.5985 146.267 45.54 135.471 47.8236 124.468C50.1073 113.465 60.7989 106.303 71.8019 108.69C75.7464 109.521 79.1719 111.493 82.0784 114.192C86.9571 119.071 89.0331 126.129 87.5799 132.773Z'
        fill='#E2626E'
      />
      <path
        d='M74.1908 126.856H69.5197V122.185C69.5197 121.25 68.7931 120.42 67.7551 120.42C66.8208 120.42 65.9904 121.147 65.9904 122.185V126.856H61.3193C60.3851 126.856 59.5547 127.582 59.5547 128.62C59.5547 129.658 60.2813 130.385 61.3193 130.385H65.9904V135.056C65.9904 135.99 66.717 136.821 67.7551 136.821C68.6893 136.821 69.5197 136.094 69.5197 135.056V130.385H74.1908C75.125 130.385 75.9555 129.658 75.9555 128.62C75.9555 127.582 75.125 126.856 74.1908 126.856Z'
        fill='white'
      />
    </svg>
  );
}
