export default function RightArrowIcon({
  width = 20,
  height = 20,
  color = '#6C7589',
  ...props
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      stroke={color}
      {...props}
    >
      <path
        d='M9.4375 4.01284L13.336 7.91828L9.4375 11.8237'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.67187 7.91827L13.3385 7.91827'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
