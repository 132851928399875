import styles from '../styles.module.scss';
import { useRef, useState } from 'react';
import useFeatheryRedux from '../../../redux';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../hooks';
import {
  ContextMenu,
  DropdownField,
  TextField
} from '../../../components/Core';
import { OpenOverflowIcon } from '../../../components/Icons';
import { useGlobalMouseDownToggle } from '../../../components/Core/util';
import { deepEquals } from '../../../utils/core';

export default function EditableValue({
  value,
  fieldId,
  hidden = false
}: {
  value: string;
  fieldId: string;
  hover?: boolean;
  hidden?: boolean;
}) {
  const { userId, formId } = useParams<{ userId: string; formId: string }>();
  const editFormResults = useAppSelector((state) => {
    const account = state.accounts.account;
    return account.role !== 'viewer' && account.permission_edit_form_results;
  });
  const hiddenField = useAppSelector(
    (state) =>
      (state.fields.hiddenFields || []).find(({ id }) => id === fieldId),
    deepEquals
  );
  const servar = useAppSelector(
    (state) => (state.fields.servars ?? []).find(({ id }) => id === fieldId),
    deepEquals
  );
  let dropdownOptions = null;
  if (hidden) dropdownOptions = hiddenField.options;
  else if (['dropdown', 'select'].includes(servar.type))
    dropdownOptions = servar.metadata.options;

  const {
    editFieldValue,
    deleteFieldValue,
    toasts: { addErrorToast, addInfoToast }
  } = useFeatheryRedux();

  const [curVal, setCurVal] = useState(value);
  const [editing, setEditing] = useState(false);

  const [menuPosition, setMenuPosition] = useState<{
    x?: number;
    y?: number;
  }>({});
  const menuRef = useRef<HTMLDivElement>(null);
  const [showMenu, setShowMenu] = useGlobalMouseDownToggle([menuRef]);

  if (!editFormResults) return <>{curVal}</>;

  const updateVal = (newVal: string) => {
    if (newVal === value) return;

    const promise = newVal
      ? editFieldValue({
          hidden,
          id: fieldId,
          fuser: userId,
          value: newVal,
          panel_id: formId
        })
      : deleteFieldValue({ hidden, id: fieldId, fuser: userId });
    promise
      .then(() => {
        setCurVal(newVal);
        addInfoToast('Field value updated');
      })
      .catch((err: any) => {
        addErrorToast({
          title: 'Edit Error',
          body: err.message
        });
      })
      .finally(() => setEditing(false));
  };

  const inputComponent =
    dropdownOptions && dropdownOptions.length > 0 ? (
      <DropdownField
        autoFocus
        className={styles.editInput}
        value={curVal}
        options={dropdownOptions.map((opt: string) => ({
          value: opt,
          display: opt
        }))}
        onBlur={() => setEditing(false)}
        onChange={(event: any) => updateVal(event.target.value)}
      />
    ) : (
      <TextField
        autoFocus
        className={styles.editInput}
        value={curVal}
        onComplete={(newVal: string) => updateVal(newVal)}
      />
    );

  return (
    <div className={styles.editableValue}>
      {editing ? (
        inputComponent
      ) : (
        <>
          {/* If the curVal is not undefined, adding a string to the curVal will cast it to a string to display properly. This will account for numbers, booleans and even null. We only do this if not undefined as we do not want to display undefined. */}
          <span className={styles.valueDisplay}>
            {curVal !== undefined ? curVal + '' : curVal}
          </span>
          {curVal ? (
            <div
              className={styles.toggle}
              onClick={(e) => {
                e.preventDefault();
                setMenuPosition({
                  x: e.pageX,
                  y: e.pageY
                });
                setShowMenu(true);
              }}
            >
              <OpenOverflowIcon />
            </div>
          ) : (
            <span className={styles.editNew} onClick={() => setEditing(true)}>
              Click to edit
            </span>
          )}
          <ContextMenu
            ref={menuRef}
            show={showMenu}
            close={() => setMenuPosition({})}
            position={menuPosition as any}
            actions={[
              {
                onMouseDown: () => setTimeout(() => setEditing(true)),
                title: 'Edit Value'
              },
              {
                onMouseDown: () => setTimeout(() => updateVal('')),
                title: 'Clear Field'
              }
            ]}
          />
        </>
      )}
    </div>
  );
}
