function Zywave({ width = 112.5, height = 22.5 }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 112.5011444 22.5001106'
      enableBackground='new 0 0 112.5011444 22.5001106'
      xmlSpace='preserve'
    >
      <switch>
        <g>
          <g>
            <defs>
              <rect id='SVGID_1_' width='112.5011444' height='22.5001106' />
            </defs>
            <clipPath id='SVGID_2_'>
              <use xlinkHref='#SVGID_1_' overflow='visible' />
            </clipPath>
            <polyline
              clipPath='url(#SVGID_2_)'
              fill='#8F9193'
              points='94.6935272,0.0008354 88.4977722,12.5020256 82.3002167,0.0008354      79.822052,0.0008354 88.4977722,17.5322189 97.1802979,0.0008354 94.6935272,0.0008354'
            />
            <path
              clipPath='url(#SVGID_2_)'
              fill='#8F9193'
              d='M77.2337036,17.5322189h2.4844971L71.0554199,0.0008354h-0.9481506     l-8.6630249,17.5313835h2.4898071l1.9008484-3.8797989h9.4971924L77.2337036,17.5322189 M66.8479004,11.5916557     l3.7295227-7.5145798l3.730011,7.5145798H66.8479004z'
            />
            <polyline
              clipPath='url(#SVGID_2_)'
              fill='#8F9193'
              points='103.7793427,9.4653435 110.6755219,9.4653435      110.6755219,7.3977051 103.7793427,7.3977051 103.7793427,2.0599823 111.2789154,2.0599823 112.4821472,0.0008354      101.5159912,0.0008354 101.5159912,17.5322189 111.2986755,17.5322189 112.5018921,15.4219017 103.7793427,15.4219017      103.7793427,9.4653435'
            />
            <polyline
              clipPath='url(#SVGID_2_)'
              fill='#8F9193'
              points='59.260437,0.0008354 53.1130447,12.5016556 47.6199646,1.5209923      42.1755142,12.5016556 36.2229309,0.0008354 32.530899,0.0008354 29.211998,6.6946297 30.4560165,9.2076874      34.2635498,1.5285835 42.1755142,17.5322189 47.6199646,6.5310936 53.1130447,17.5322189 61.7388458,0.0008354      59.260437,0.0008354'
            />
            <polyline
              clipPath='url(#SVGID_2_)'
              fill='#8F9193'
              points='4.1601486,15.4407196 15.1462097,0.0080528 1.8289948,0.0080528      0.8010025,2.0614128 10.9860382,2.0614128 0,17.4939613 15.1333466,17.4939613 16.149353,15.4407196 4.1601486,15.4407196'
            />
            <polyline
              clipPath='url(#SVGID_2_)'
              fill='#73973D'
              points='23.4026031,0.0008354 18.477478,0.0008354 24.0242081,11.2497559      18.477478,22.5001106 23.4026031,22.5001106 28.9852829,11.2497559 23.4026031,0.0008354'
            />
          </g>
        </g>
      </switch>
    </svg>
  );
}

export default Zywave;
