function EZLynx({ width = 160, height = 90 }) {
  return (
    <img
      alt='EZLynx logo'
      src={require('./EZLynx.webp')}
      style={{ width, height }}
    />
  );
}

export default EZLynx;
