import { downloadFile } from '../../../utils/browserUtils';

export const downloadTranslationFile = (
  form: string,
  translations: Record<string, any>,
  language = ''
) => {
  const curTimeStamp = new Date().toLocaleString();
  let payload = getFileHeader(form, curTimeStamp);
  Object.entries(translations)
    .sort(([phrase1], [phrase2]) => (phrase1 < phrase2 ? -1 : 1))
    .forEach(([phrase, variants]) => {
      phrase = phrase.replaceAll('=', '\\=');
      const translation = (variants[language] ?? '').replaceAll('=', '\\=');
      payload += `${phrase}=${translation}\n`;
    });
  const suffix = language ? `_${language}` : '';
  downloadFile(`${form}_translations${suffix}.txt`, payload);
};

const getFileHeader = (form: string, timestamp: string) =>
  `# Translations for form: ${form}\n` +
  `# Generated on: ${timestamp}\n` +
  "# Your translations must be added to each line after the '='.\n" +
  '# Equal signs in your translated text must be preceded\n' +
  "# by '\\', which will not show up in your actual form.\n" +
  '# To translate text with different styles in different sections,\n' +
  '# you can wrap secondary style sections in the translation\n' +
  '# with double curly braces {{}}\n\n';
