/* eslint-disable react-hooks/exhaustive-deps */
import styles from './styles.module.scss';
import classNames from 'classnames';

const RadioButtonGroup = ({
  required = false,
  disabled = false,
  name,
  selected,
  onChange: handleChange,
  options = [],
  hideValueIfDisabled = false,
  allowUnselect = false
}: any) => {
  const showValue = !disabled || !hideValueIfDisabled;

  return (
    <div
      className={classNames(styles.radioButtonGroup, {
        [styles.disabled]: disabled
      })}
    >
      {/* @ts-expect-error TS(7006) FIXME: Parameter 'option' implicitly has an 'any' type. */}
      {options.map((option) => {
        const checked = showValue && option.value === selected;

        return (
          <label
            key={option.value}
            className={classNames(styles.label, {
              [styles.disabled]: disabled
            })}
          >
            <input
              type='radio'
              name={name}
              value={option.value}
              required={required}
              disabled={disabled}
              checked={checked}
              onClick={(e: any) => {
                if (allowUnselect && e.target.value === selected) {
                  handleChange('');
                }
              }}
              onChange={(e) => {
                if (!disabled && e.target.checked) handleChange(e.target.value);
              }}
            />
            <div
              className={classNames(styles.display, [
                { [styles.checked]: checked }
              ])}
            >
              {option.display}
            </div>
          </label>
        );
      })}
    </div>
  );
};

export default RadioButtonGroup;
