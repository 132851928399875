import * as Icons from '../../Icons';

import { Col, Row } from 'react-bootstrap';
import {
  CollapsibleSection,
  CornerStyleInput,
  DropdownField,
  FColorPicker,
  NumberInput,
  PropertyLabel,
  RadioButtonGroup
} from '../../Core';
import {
  assetUpdateOperations,
  CORNER_PROPS,
  elementOperation,
  getAsset,
  level2UpdateOperations
} from '../../../utils/themes';
import { memo } from 'react';

import { TYPE_PROGRESS_BAR } from '../../../utils/elements';
import { calculateOverrideObjects } from '../utils';
import { objectPick } from '../../../utils/core';
import { Viewport } from '../../RenderingEngine/GridInGrid/engine';
import FontStyleSection from './components/FontStyleSection';
import { AXIS } from '../../../utils/constants';
import BoxSpacingInput from '../../Core/BoxSpacingInput';

function ProgressBarStylePanel({
  mode,
  viewport,
  theme,
  baseProps,
  baseStyle,
  overrideProps,
  overrideStyle,
  handleUpdates,
  cellNode
}: any) {
  const { result: element } = calculateOverrideObjects(
    baseProps,
    overrideProps,
    true
  );
  const { result: elementStyle, isOverride: isOverrideStyle } =
    calculateOverrideObjects(baseStyle, overrideStyle);
  const asset = getAsset(theme, TYPE_PROGRESS_BAR, element.source_asset);

  function handleStyleChange(styleUpdate: any) {
    handleUpdates([elementOperation({ viewport, styleUpdate })]);
  }

  function labelData(...props: string[]) {
    const newStyle = objectPick(elementStyle, props);
    const key = viewport === Viewport.Mobile ? 'Desktop' : 'Theme';
    return {
      mode,
      highlighted: isOverrideStyle(props),
      themeOperations: {
        instance: {
          label: `Reset to ${key}`,
          operation: () => {
            handleUpdates([elementOperation({ viewport, styleReset: props })]);
          }
        },
        ...(mode === 'builder' && {
          level2: {
            label: 'Progress Bars',
            operation: () => {
              handleUpdates(
                level2UpdateOperations({
                  viewport,
                  newStyle,
                  asset,
                  elementType: TYPE_PROGRESS_BAR,
                  level2: 'progress_bar'
                })
              );
            }
          }
        }),
        ...(asset && {
          asset: {
            label: asset.key,
            operation: () => {
              handleUpdates(
                assetUpdateOperations({
                  viewport,
                  elementType: TYPE_PROGRESS_BAR,
                  asset,
                  newStyle
                })
              );
            }
          }
        })
      }
    };
  }

  return (
    <>
      <CollapsibleSection title='Layout' collapsible>
        <Row>
          <Col sm='3'>
            <PropertyLabel
              label='Width'
              {...labelData('width', 'width_unit')}
            />
          </Col>
          <Col>
            <NumberInput
              required
              min={0}
              value={elementStyle.width}
              unit={elementStyle.width_unit}
              units={['px', '%']}
              onComplete={({ value, unit }: any) =>
                handleStyleChange({ width: value, width_unit: unit })
              }
              triggerCleanUp
              dimension='width'
            />
          </Col>
          <Col sm='3'>
            <PropertyLabel
              label='Height'
              indented
              {...labelData('height', 'height_unit')}
            />
          </Col>
          <Col>
            <NumberInput
              required
              min={0}
              value={elementStyle.height}
              unit={elementStyle.height_unit}
              units={['px', '%']}
              onComplete={({ value, unit }: any) =>
                handleStyleChange({ height: value, height_unit: unit })
              }
              triggerCleanUp
              dimension='height'
            />
          </Col>
        </Row>
        <BoxSpacingInput
          elementStyle={elementStyle}
          handleStyleChange={handleStyleChange}
          labelData={labelData}
          elementType={TYPE_PROGRESS_BAR}
        />
        {cellNode && (
          <Row>
            <Col sm='3'>
              <PropertyLabel
                label='Align Self'
                {...labelData('layout', 'vertical_layout')}
              />
            </Col>
            <Col>
              <RadioButtonGroup
                selected={elementStyle.layout}
                name='progress-bar-editor-horizontal-alignment'
                allowUnselect
                hideValueIfDisabled
                disabled={!cellNode.parent || cellNode.parent.axis === AXIS.COL}
                onChange={(value: any) => handleStyleChange({ layout: value })}
                options={[
                  {
                    value: 'flex-start',
                    display: <Icons.HorizontalLeftIcon />
                  },
                  { value: 'center', display: <Icons.HorizontalCenterIcon /> },
                  { value: 'flex-end', display: <Icons.HorizontalRightIcon /> }
                ]}
              />
            </Col>
            <Col>
              <RadioButtonGroup
                selected={elementStyle.vertical_layout}
                name='progress-bar-editor-vertical-alignment'
                allowUnselect
                hideValueIfDisabled
                disabled={!cellNode.parent || cellNode.parent.axis === AXIS.ROW}
                onChange={(value: any) =>
                  handleStyleChange({ vertical_layout: value })
                }
                options={[
                  { value: 'flex-start', display: <Icons.VerticalTopIcon /> },
                  { value: 'center', display: <Icons.VerticalCenterIcon /> },
                  { value: 'flex-end', display: <Icons.VerticalBottomIcon /> }
                ]}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col sm='3'>
            <PropertyLabel
              label='Align Content'
              {...labelData('horizontal_align', 'vertical_align')}
            />
          </Col>
          <Col>
            <RadioButtonGroup
              selected={elementStyle?.horizontal_align}
              name='progress-bar-editor-horizontal-alignment'
              onChange={(value: any) =>
                handleStyleChange({ horizontal_align: value })
              }
              options={[
                {
                  value: 'flex-start',
                  display: <Icons.HorizontalLeftIcon />
                },
                {
                  value: 'center',
                  display: <Icons.HorizontalCenterIcon />
                },
                {
                  value: 'flex-end',
                  display: <Icons.HorizontalRightIcon />
                }
              ]}
            />
          </Col>
          <Col>
            <RadioButtonGroup
              selected={elementStyle?.vertical_align}
              name='progress-bar-editor-vertical-alignment'
              onChange={(value: any) =>
                handleStyleChange({ vertical_align: value })
              }
              options={[
                { value: 'flex-start', display: <Icons.VerticalTopIcon /> },
                { value: 'center', display: <Icons.VerticalCenterIcon /> },
                { value: 'flex-end', display: <Icons.VerticalBottomIcon /> }
              ]}
            />
          </Col>
        </Row>
        {mode !== 'theme' && (
          <Row>
            <Col sm='3'>
              <PropertyLabel label='Visibility' />
            </Col>
            <Col>
              <RadioButtonGroup
                selected={elementStyle.visibility}
                name='progress-bar-editor-visibility'
                onChange={(value: any) =>
                  handleStyleChange({ visibility: value })
                }
                options={[
                  {
                    value: 'visible',
                    display: <Icons.ShowIcon width={18} height={18} />
                  },
                  {
                    value: 'hidden',
                    display: <Icons.HideIcon width={18} height={18} />
                  }
                ]}
              />
            </Col>
          </Row>
        )}
      </CollapsibleSection>

      <CollapsibleSection title='Display' collapsible>
        <Row>
          <Col sm='5'>
            <PropertyLabel label='Bar Color' {...labelData('bar_color')} />
          </Col>
          <Col>
            <FColorPicker
              value={elementStyle.bar_color}
              onChange={(color: any) => handleStyleChange({ bar_color: color })}
            />
          </Col>
        </Row>
        <Row>
          <Col sm='5'>
            <PropertyLabel
              label='Text Placement'
              {...labelData('precent_text_layout')}
            />
          </Col>
          <Col>
            <DropdownField
              selected={elementStyle.percent_text_layout}
              onChange={(e: any) =>
                handleStyleChange({ percent_text_layout: e.target.value })
              }
              options={[
                { value: 'none', display: 'Hide' },
                { value: 'top', display: 'Top' },
                { value: 'bottom', display: 'Bottom' }
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <PropertyLabel label='Corners' {...labelData(...CORNER_PROPS)} />
          </Col>
        </Row>
        <Row>
          <Col>
            <CornerStyleInput
              topLeft={elementStyle.corner_top_left_radius}
              topRight={elementStyle.corner_top_right_radius}
              bottomLeft={elementStyle.corner_bottom_left_radius}
              bottomRight={elementStyle.corner_bottom_right_radius}
              onComplete={(newCorners: any) =>
                handleStyleChange({
                  corner_top_left_radius: newCorners.topLeft,
                  corner_top_right_radius: newCorners.topRight,
                  corner_bottom_left_radius: newCorners.bottomLeft,
                  corner_bottom_right_radius: newCorners.bottomRight
                })
              }
            />
          </Col>
        </Row>
      </CollapsibleSection>

      <CollapsibleSection title='Typography' collapsible>
        <FontStyleSection
          mode={mode}
          elementType={TYPE_PROGRESS_BAR}
          level2={{ type: 'progress_bar', label: 'Progress Bars' }}
          viewport={viewport}
          baseProps={baseProps}
          overrideProps={overrideProps}
          baseStyle={baseStyle}
          overrideStyle={overrideStyle}
          handleUpdates={handleUpdates}
          theme={theme}
          labelCustomData={labelData}
          handleCustomStyleChange={handleStyleChange}
          customValues={{
            line_height: elementStyle.line_height,
            text_align: elementStyle.text_align
          }}
        />
      </CollapsibleSection>
    </>
  );
}

export default memo(ProgressBarStylePanel);
