import { useEffect, useRef, useState } from 'react';
import useCanvasRefs from '../../../Controls/useCanvasRefs';
import { useAppSelector } from '../../../../../hooks';
import { ControlLayerDetails } from '../../../Controls/ControlLayer';
import useGridMode from '../../../../../hooks/useGridMode';
import { Cell } from '../../engine';

const defaultBackgroundColor = 'transparent';

export const useControls = (node: Cell) => {
  const { linkDiv, unlinkDiv, listen } = useCanvasRefs();
  const { gridMode } = useGridMode();
  const divRef = useRef<HTMLDivElement>(null);
  const portalRef = useRef<HTMLDivElement>(null);
  const isDragging = useAppSelector(
    ({ formBuilder }) => formBuilder.isDragging
  );

  const [backgroundColor, setBackgroundColor] = useState(
    defaultBackgroundColor
  );

  let canDrop = true;
  if (isDragging && node && ControlLayerDetails?.draggingNode?.hasChildren()) {
    const targetNode = node;
    const movingNode = ControlLayerDetails?.draggingNode;

    // Do not allow subgrids to be dragged into themselves.
    if (targetNode.isDescendent(movingNode)) {
      canDrop = false;
    }
  }

  useEffect(() => {
    const nodePos = [...node.position];
    linkDiv(node, divRef, portalRef);
    // TODO(drew) Currently unused backdoor into this component from the ControlLayer (was being used for animation experiments)
    const stopListening = listen(
      node,
      (eventDetail) => {
        setBackgroundColor(eventDetail.backgroundColor);
      },
      () => {
        setBackgroundColor(defaultBackgroundColor);
      }
    );
    return () => {
      unlinkDiv(node, nodePos);
      stopListening();
    };
  }, [node]);

  return {
    controlsRef: divRef,
    showContainerBorders: (gridMode || isDragging) && !node.isRoot(),
    showDroppableStyles: canDrop && backgroundColor === 'rgba(0, 0, 0, 0.08)'
  };
};
