import { Viewport, ViewportType } from '../viewports';

/**
 * Returns the formatted version of the key for the viewport specified
 * @param {string} key - Field name
 * @param {string} viewport - The viewport that we need the key in
 * @returns {string} The name of the key for the viewport specified (eg. mobile_key for mobile)
 */
export const getViewportKeyName = (key: string, viewport: ViewportType) => {
  if (viewport === Viewport.Mobile) return `mobile_${key}`;
  if (viewport === Viewport.Desktop) {
    if (key.indexOf('mobile_') >= 0) {
      return key.split('mobile_')[1];
    }
  }

  return key;
};
