import { Step } from '../../../utils/step';
import { ConditionData as Condition } from '../../NavigationRules/components/types';
export interface StepIdMap {
  [stepId: string]: Step;
}

export type EdgeDirection = 'up' | 'down'; // source to target direction in a "linear" flow
export interface DirectionalEdge {
  source: string;
  target: string;
  direction: EdgeDirection; // source to target direction in a "linear" flow
  distance: number; // distance for adjacent steps is 1
  bidirectional: boolean;
}

/**
 * Generate an ordered set of steps with edges optimized for a linear representation of a form flow.
 *
 * The algorithm produces a sequence of steps that always starts with the origin step and
 * simply puts the longest possible chains of sequentially connected
 * steps first.  Sequentially connected steps could be via a forward of reverse or bidirectional connection.
 * The last steps will be any steps that have no outgoing connections followed
 * by any "floating" steps (no inbound nor outbound connections).  Chains of floating steps (longest ones first)
 * are shown before unconnected floating steps.
 *
 * @param stepIdMap
 * @returns [orderedSteps, edges]
 */
export function linearizeFlow(
  stepIdMap: StepIdMap
): [Step[], DirectionalEdge[]] {
  const entries = Object.entries(stepIdMap);
  if (entries.length === 0) return [[], []];

  let originStep;
  entries.forEach(([, step]) => {
    if (step.origin) originStep = step;
  });

  let originSteps: Step[] = [];
  if (originStep) originSteps = linearizeSteps([originStep], [], stepIdMap);

  const nonFloatingStepIds = new Set<string>();
  originSteps.forEach((s) => nonFloatingStepIds.add(s.id));

  const floatingSteps: Step[] = [];
  entries.forEach(([id, step]) => {
    if (!nonFloatingStepIds.has(id)) floatingSteps.push(step);
  });

  // make sure no dupes which can happen with half broken navigations (next with no corresponding prev)
  const linearFloatingSteps = linearizeSteps(
    [],
    floatingSteps,
    stepIdMap
  ).filter((step) => !nonFloatingStepIds.has(step.id));

  const orderedSteps = [...originSteps, ...linearFloatingSteps];
  const stepIndexMap: { [key: string]: number } = orderedSteps.reduce(
    (p, step, index) => ({ ...p, [step.id]: index }),
    {}
  );

  // edges
  const singleDirEdges: {
    [sourceId: string]: { [targetId: string]: boolean };
  } = {};
  entries.forEach(([id, step]) => {
    step.next_conditions.forEach(({ next_step: nextId }) => {
      if (nextId in singleDirEdges && step.id in singleDirEdges[nextId]) {
        singleDirEdges[nextId][step.id] = true;
      } else if (!(step.id in singleDirEdges)) {
        singleDirEdges[step.id] = { [nextId]: false };
      } else if (
        step.id in singleDirEdges &&
        !(nextId in singleDirEdges[step.id])
      ) {
        singleDirEdges[step.id][nextId] = false;
      }
      return singleDirEdges;
    });
  });
  const edges = Object.entries(singleDirEdges).flatMap(([source, val]) =>
    Object.entries(val).map(([target, bidirectional]) => {
      const sourceIndex = stepIndexMap[source];
      const targetIndex = stepIndexMap[target];
      return {
        source,
        target,
        direction: (sourceIndex < targetIndex ? 'down' : 'up') as EdgeDirection,
        distance: Math.abs(sourceIndex - targetIndex),
        bidirectional
      };
    })
  );

  return [orderedSteps, edges];
}

const maxSearchTime = 100;

function linearizeSteps(
  linearSteps: Step[],
  otherSteps: Step[],
  stepIdMap: StepIdMap
): Step[] {
  // For each step's forward or back connections (i.e. next_conditions or previous_conditions)
  // get the longest path for each.
  // Choose the longest path and append it and do it all over again.
  const seenStepIds = new Set(linearSteps.map((s) => s.id));

  // check all the connections for what is already in the chain as well as any other (non-lneiarized) steps not already in the chain
  const connections = [
    ...linearSteps
      .map((step) => [
        ...step.previous_conditions.map(
          (prev) => stepIdMap[prev.previous_step]
        ),
        ...step.next_conditions.map((next) => stepIdMap[next.next_step]) // favoring "downward" (forward) chains
      ])
      .flat(),
    ...otherSteps
  ].filter((step) => !seenStepIds.has(step.id));

  const startTime = Date.now();

  const longest: Step[] = connections.reduce(
    (prevLongest: Step[], childStep: Step) => {
      // Make sure we converge to a solution in a timely manner.
      // If we have exceeded the time limit and we found a chain of some length,
      // then take it as the longest and move on.
      if (Date.now() - startTime > maxSearchTime && prevLongest.length)
        return prevLongest;

      const longestForChild = longestSequentialStepChain(
        childStep,
        seenStepIds,
        stepIdMap,
        startTime
      );

      if (longestForChild.length >= prevLongest.length) return longestForChild;
      return prevLongest;
    },
    []
  );

  // if finding more, then append and keep going, otherwise we are done
  if (longest.length)
    return linearizeSteps([...linearSteps, ...longest], otherSteps, stepIdMap);
  else return linearSteps;
}

function longestSequentialStepChain(
  root: Step,
  seenStepIds: Set<string>,
  stepIdMap: StepIdMap,
  startTime: number
): Step[] {
  const mySeenStepIds = new Set([...(seenStepIds ?? []), root.id]);

  // For each of the root's forward or back connections (i.e. next_conditions or previous_conditions),
  // get the longest path for each.
  // Choose the longest path and return it

  const connections = [
    ...root.previous_conditions.map(
      (prev: Condition) => stepIdMap[prev.previous_step] // favoring "downward" (forward) chains
    ),
    ...root.next_conditions.map((next: Condition) => stepIdMap[next.next_step])
  ].filter((step) => !mySeenStepIds.has(step.id));

  const longest: Step[] = connections.reduce(
    (prevLongest: Step[], childStep: Step) => {
      // if we have exceeded the time limit and we found a chain of some length, then return it.
      if (Date.now() - startTime > maxSearchTime && prevLongest.length)
        return prevLongest;

      const longestForChild = longestSequentialStepChain(
        childStep,
        mySeenStepIds,
        stepIdMap,
        startTime
      );
      if (longestForChild.length >= prevLongest.length) return longestForChild;
      return prevLongest;
    },
    []
  );

  return [root, ...longest];
}

/**
 * Determines which branch edges to display and in what "lanes".  Note that all single step edges (between two
 * adjacent steps) always will fit between steps (on the center line edge) due to the fact that we are using
 * bidirectional edges.
 * @param orderedSteps ordered steps
 * @param edges All of the edges for a flow.
 * @param maxBranchLanes max number of available lanes (displayable branch lines to the right of the steps).
 * The first one is the first one to the right of the steps and so-on out to the right-most one.
 * @param maxBranchPoints The Max number of branching connection points on the right side of the step.
 * @return An array of tuples of branch edges and display lane representing the subset of branch edges to be displayed.
 * An edge mapped to a null lane indicates a direct connection to an adjacent step.
 */
export function optimizeDisplayableBranches(
  orderedSteps: Step[],
  edges: DirectionalEdge[],
  maxBranchLanes = 4,
  maxBranchPoints = 4
): [DirectionalEdge, number | null][] {
  const branchLanePairs: [DirectionalEdge, number | null][] = [];

  // Occupied lane slots - Each slot is the vertical span in a lane that spans between to steps.
  // We want to track if a branch/arrow occuppies that slot or is it available.
  // Using a bit map for each lane to track the occupancy / availability of each slot in that lane (0 == available);
  const lanes = Array(maxBranchLanes).fill(0);

  // Outbound branches for each (ordered shortest to longest branch).
  const stepSortedEdges = orderedSteps.map((step) =>
    edges
      .filter((edge) => edge.source === step.id)
      .sort((a, b) => a.distance - b.distance)
  );

  // Occupied branch points for each step (bit map)
  const availableConnectionPoints = orderedSteps.map((step) => maxBranchPoints);

  // Approximate algorithm
  // Do
  //    foundBranchThatFits = false
  //    For each step in top to bottom order
  // 		    Find shortest out-bound branch that will fit.  Fitting rules:
  //            Any edge that is just length 1 to the next step is always displayable in the center (null lane)
  //            There must be unfilled connection point available on this source step
  //            There must be unfilled connection point available on target step
  //            Check the first lane to the right and then move to the right if not a fit.
  //            Must fit in lane without overlap of displayable branches already occupying lane
  //            If not fit, move to next longest branch until either one fits of no more branches
  // 		    If fitting branch found mark it as displayable and set foundBranchThatFits = true
  // While foundBranchThatFits

  const stepIndexMap: { [key: string]: number } = orderedSteps.reduce(
    (p, step, index) => ({ ...p, [step.id]: index }),
    {}
  );

  let foundBranchThatFits;
  do {
    foundBranchThatFits = false;
    orderedSteps.forEach((step, index) => {
      // for each step, try each outbound branch (shortest to longest) till you find one that fits
      let edge;
      while ((edge = stepSortedEdges[index].shift())) {
        // edges between adjacent steps always fit
        if (edge.distance === 1) {
          foundBranchThatFits = true;
          branchLanePairs.push([edge, null]);
          break;
        }
        // try an fit this one somewhere in a lane if connection points are available
        const sourceIndex = stepIndexMap[edge.source]; // will be mapped
        const targetIndex = stepIndexMap[edge.target]; // will be mapped
        if (
          availableConnectionPoints[sourceIndex] &&
          availableConnectionPoints[targetIndex]
        ) {
          for (let i = 0; i < lanes.length; i++) {
            // check if this edge collides with current occupants of this lane
            // lanes
            const branchPath =
              (Math.pow(2, edge.distance) - 1) <<
              (edge.direction === 'down' ? sourceIndex : targetIndex);

            if ((lanes[i] & branchPath) === 0) {
              // The branch slots needed for the path are all available
              foundBranchThatFits = true;
              branchLanePairs.push([edge, i]);
              lanes[i] = lanes[i] ^ branchPath; // mark the slots as occupied
              break;
            }
          }
        }
      }
    });
  } while (foundBranchThatFits);

  return branchLanePairs;
}
