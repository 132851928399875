import * as redux from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import abTestsReducer from './abTests';
import accountsReducer from './accounts';
import aiReducer from './ai';
import documentsReducer from './documents';
import fieldsReducer from './fields';
import foldersReducer from './folders';
import formBuilderReducer from './formBuilder';
import imagesReducer from './images';
import integrationsReducer from './integrations';
import metricsReducer from './metrics';
import panelsReducer from './panels';
import tablesReducer from './tables';
import tasksReducer from './tasks';
import themeBuilderReducer from './themeBuilder';
import themesReducer from './themes';
import toastsReducer from './toasts';
import usersReducer from './users';
import clipboardReducer from './clipboard';

const appReducer = redux.combineReducers({
  abTests: abTestsReducer,
  accounts: accountsReducer,
  ai: aiReducer,
  documents: documentsReducer,
  fields: fieldsReducer,
  folders: foldersReducer,
  formBuilder: formBuilderReducer,
  images: imagesReducer,
  integrations: integrationsReducer,
  metrics: metricsReducer,
  panels: panelsReducer,
  tables: tablesReducer,
  tasks: tasksReducer,
  themeBuilder: themeBuilderReducer,
  themes: themesReducer,
  toasts: toastsReducer,
  users: usersReducer,
  clipboard: clipboardReducer
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'LOGOUT') {
    state = undefined;
  }
  return appReducer(state, action);
};

const store = redux.createStore(
  rootReducer,
  composeWithDevTools(redux.applyMiddleware(thunk))
);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
