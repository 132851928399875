import { LogicTriggerEvent } from '../RuleFieldsPanel';
import { typeDefToGraph, getEventDef } from './featheryDefs';
import jsonpath from 'jsonpath';
import { isValidJsIdentifier, stripBeginAndEndQuotes } from './utils';
import { Ace } from 'ace-builds';
import { browserGlobals } from './validation';

interface Completer {
  getCompletions: (
    editor: any,
    session: any,
    pos: any,
    prefix: string,
    callback: any
  ) => void;
  shouldTriggerAutoComplete: (editor: any, session: any, pos: any) => boolean;
}
interface Position {
  row: number;
  column: number;
}

const getTokens = (session: any, pos: Position) => {
  const tokens = session.getTokens(pos.row);
  const currentToken = session.getTokenAt(pos.row, pos.column);
  const currentTokenIndex = tokens.indexOf(currentToken);
  const previousToken = tokens[currentTokenIndex - 1];
  return {
    tokens,
    currentToken,
    currentTokenIndex,
    previousToken
  };
};

export const getFeatheryTokenContext = (
  row: number,
  currentTokenIndex: number,
  session: Ace.EditSession
): { featheryTokenContext: any[]; inArrayContext: boolean } => {
  // In order to find any feathery context chain that we may currently be in,
  // walk back from the current token until we find a token with value of 'feathery',
  // while collecting intervening tokens that are identifiers.
  // Stop if there is a context break, which is an identifier without a receding . or [,
  // If feathery not found in the context chain, then not in a feathery context.
  // Note: ignoring whitepace and new lines (i.e. a chain can span multiple lines).
  const featheryTokenContext: string[] = [];
  let inArrayContext = false; // will be true when the current token is inside an array context (i.e. in open braket '[')
  if (currentTokenIndex < 0) return { featheryTokenContext, inArrayContext };

  let featheryContext = false;
  let isContextPath = false;
  let lastToken;
  rowLoop: for (let curRow = row; curRow >= 0; curRow--) {
    const tokens = session.getTokens(curRow);

    // exclude any partial identifiers at the end of the chain from the context
    let tokenIndexStart = tokens.length - 1;
    if (curRow === row) {
      tokenIndexStart =
        tokens[currentTokenIndex].type === 'identifier'
          ? currentTokenIndex - 1
          : currentTokenIndex;

      // if the start tokens on the start row are [] then we are in an array context.
      // Because we are walking backwards, the start is really the end of the token context.
      // If in array context then any completion will have to be a string index.
      if (
        curRow === row &&
        tokenIndexStart >= 1 &&
        // can either be a single [ or a [ followed by a ] at the end of the token context
        (tokens[tokenIndexStart].value === '[' ||
          (tokens[tokenIndexStart].value === ']' &&
            tokens[tokenIndexStart - 1].value === '['))
      )
        inArrayContext = true;
    }

    for (let i = tokenIndexStart; i >= 0; i--) {
      const token = tokens[i];
      const sawDotOrOpenBracket = ['[', '.'].includes(lastToken ?? '');
      const sawCloseBracket = ']' === lastToken;
      if (token.type === 'identifier') {
        if (sawDotOrOpenBracket) {
          isContextPath = true;
          featheryTokenContext.unshift(token.value);
          if (token.value === 'feathery') featheryContext = true;
        } else if (sawCloseBracket)
          // This is an identifier in an array prop context, so ignore it and
          // assume that the parent prop is either an array or hash in the feathery context.
          // For simplicity's sake we are not supporting that this identifier could be a prop.
          continue;
        else break rowLoop;
      } else if (token.type === 'constant.numeric' && sawCloseBracket) {
        // This is a constant number in an array context, so ignore it and
        // assume that the parent prop is an array in the feathery context.
        continue;
      } else if (token.type === 'text' && token.value.trim() === '') {
        // ignore whitespace and new lines
        continue;
      } else if (token.type === 'string') {
        if (sawCloseBracket) {
          featheryTokenContext.unshift(stripBeginAndEndQuotes(token.value));
          isContextPath = true;
        } else break rowLoop;
      } else if (
        // [] and . are valid tokens in a feathery context chain but do not add them to the context
        !['[', ']', '.'].includes(token.value)
      ) {
        // any other token type is a break in the context chain
        break rowLoop;
      }
      lastToken = token.value;
    }
  }
  if (!featheryContext || !isContextPath)
    return { featheryTokenContext: [], inArrayContext: false };
  return { featheryTokenContext, inArrayContext };
};

function isValidGlobalIdentifier(identifier: string) {
  return (
    isValidJsIdentifier(identifier) &&
    !Object.keys(browserGlobals).includes(identifier)
  );
}

const featheryCompleter = (
  event: LogicTriggerEvent | '',
  fieldKeys: string[]
): Completer => {
  const featheryTypeDefGraph = typeDefToGraph(getEventDef(event), fieldKeys);

  const getCompletionInfo = (
    key: string,
    typeInfo: any,
    inArrayContext: boolean
  ) => {
    let value = key;
    // If the completion item is a function, we want to put the function with sample params
    // into the code if it chosen.
    // if typeInfo is a string and begins with the string 'fn(' then use the typeInfo
    // as the value but replace the 'fn(' in typeInfo with the key string.
    if (
      typeof typeInfo === 'string' &&
      typeInfo.startsWith('fn(') &&
      !inArrayContext
    ) {
      value = `${key}${typeInfo.slice(2)}`;
    }
    // if inArrayContext (i.e. replacing inside of [ ]), then wrap the value in quotes
    if (inArrayContext) value = `"${value}"`;

    // prioritize non-functions (properties) over functions (methods)
    let score = 1000;
    if (typeof typeInfo === 'string' && typeInfo.startsWith('fn(')) score = 100;

    return { caption: value, value, score };
  };

  const getCompletions = (
    _editor: any,
    session: any,
    pos: any,
    _prefix: string,
    callback: any
  ) => {
    const { tokens, currentTokenIndex, previousToken } = getTokens(
      session,
      pos
    );
    const { featheryTokenContext, inArrayContext } = getFeatheryTokenContext(
      pos.row,
      currentTokenIndex,
      session
    );

    let completions: any[] = [];
    // Do not autocomplete if in a comment.  Happily, Ace does not tokenize within comments
    // and therefore if in a comment, the current token will be the comment token.
    // Also do not autocomplete if the current token is a comment.
    if (
      tokens.length &&
      (tokens[currentTokenIndex].type === 'comment' ||
        tokens[currentTokenIndex].type === 'string' || // for strings
        tokens[currentTokenIndex].type === 'string.quasi') // for template strings
    )
      return completions;

    if (featheryTokenContext.length === 0) {
      // no feathery context, so just return all the top level def items as possible completions
      // as well as the feathery fields, but only if not in anther top level item context.

      // If in another top-level token's (i.e. a field) context, then support one level
      // of completion on it.  This can be extended if needed in the future.
      const topLevelTokens = Object.keys(featheryTypeDefGraph);
      const inTopLevelTokenContext =
        previousToken && topLevelTokens.includes(previousToken.value);

      if (inTopLevelTokenContext)
        completions = [
          // add in identifiers that are sub-props of the top level token
          ...Object.keys(featheryTypeDefGraph[previousToken.value]).map(
            (key) => {
              const typeInfo = featheryTypeDefGraph[previousToken.value][key];
              return {
                ...getCompletionInfo(key, typeInfo, inArrayContext),
                meta: 'field'
              };
            }
          )
        ];
      else
        completions = [
          // add in identifiers that are valid identifiers
          ...Object.keys(featheryTypeDefGraph)
            .filter((key) => isValidGlobalIdentifier(key))
            .map((key) => {
              return {
                caption: key,
                value: key,
                score: key === 'feathery' ? 1000 : 100,
                meta: key === 'feathery' ? 'feathery' : 'field'
              };
            }),
          // add all identifiers that are not valid js identifiers as feathery.field['<field id>']
          ...Object.keys(featheryTypeDefGraph)
            .filter((key) => !isValidGlobalIdentifier(key))
            .map((key) => {
              return {
                caption: key,
                value: `feathery.fields['${key}']`,
                score: 100,
                meta: 'field'
              };
            })
        ];
    } else {
      // we have a feathery context, so return the completions for that context level
      try {
        const context = jsonpath.value(
          featheryTypeDefGraph,
          `$${featheryTokenContext.map((t) => `['${t}']`).join('')}`
        );
        if (context)
          completions = Object.entries(context).map(([key, typeInfo]) => ({
            ...getCompletionInfo(key, typeInfo, inArrayContext),
            meta: 'feathery'
          }));
      } catch (e) {
        /* ignoring really badly formed code here */
      }
    }

    callback(null, completions);
  };

  const shouldTriggerAutoComplete = (editor: any, session: any, pos: any) => {
    // If the current token is either '.' or '['
    // and currently in a feathery context chain, then trigger autocomplete.
    // Also trigger if not in feathery context but in another top-level token's
    // (i.e. a field) context.  This will only support one level of sub-context for now.
    const { currentToken, currentTokenIndex, previousToken } = getTokens(
      session,
      pos
    );
    const { featheryTokenContext } = getFeatheryTokenContext(
      pos.row,
      currentTokenIndex,
      session
    );
    const inFeatheryContextChain = featheryTokenContext.length > 0;

    const topLevelTokens = Object.keys(featheryTypeDefGraph);
    const inTopLevelTokenContext =
      previousToken && topLevelTokens.includes(previousToken.value);

    return (
      currentToken &&
      previousToken &&
      (((currentToken.value === '.' || currentToken.value === '[') &&
        (inFeatheryContextChain || inTopLevelTokenContext)) ||
        // Editor auto inserts the closing bracket, so we need to check for that too.
        (currentToken.value === ']' &&
          previousToken.value === '[' &&
          (inFeatheryContextChain || inTopLevelTokenContext)))
    );
  };

  return { getCompletions, shouldTriggerAutoComplete };
};

export default featheryCompleter;
