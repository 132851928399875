export default function CleanIcon({ width = 32, height = 32, ...props }) {
  return (
    <svg
      width={width}
      height={height}
      {...props}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='24' height='24' rx='5' fill='#EBEDF2' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.72302 14.1103L9.8563 11.4187L12.741 10.4271C12.9986 10.3799 13.1531 10.1438 13.1531 9.90771C13.1531 9.71883 12.9986 9.48273 12.741 9.43551L9.8563 8.39668L8.72302 5.75237C8.67151 5.51627 8.41395 5.37461 8.2079 5.37461C7.95034 5.37461 7.69277 5.51627 7.64126 5.75237L6.5595 8.39668L3.62328 9.43551C3.41723 9.48273 3.2627 9.71883 3.2627 9.90771C3.2627 10.1438 3.41723 10.3799 3.62328 10.4271L6.5595 11.4187L7.64126 14.1103C7.69277 14.3464 7.95033 14.4408 8.2079 14.4408C8.41395 14.4408 8.67151 14.3464 8.72302 14.1103ZM14.2306 18.4219L14.928 16.7656L16.7032 16.1553C16.8617 16.1263 16.9568 15.981 16.9568 15.8357C16.9568 15.7195 16.8617 15.5742 16.7032 15.5451L14.928 14.9058L14.2306 13.2786C14.1989 13.1333 14.0404 13.0461 13.9136 13.0461C13.7551 13.0461 13.5966 13.1333 13.5649 13.2786L12.8992 14.9058L11.0923 15.5451C10.9655 15.5742 10.8704 15.7195 10.8704 15.8357C10.8704 15.981 10.9655 16.1263 11.0923 16.1553L12.8992 16.7656L13.5649 18.4219C13.5966 18.5672 13.7551 18.6253 13.9136 18.6253C14.0404 18.6253 14.1989 18.5672 14.2306 18.4219ZM18.4784 11.6512L17.9553 12.8935C17.9316 13.0024 17.8127 13.046 17.7176 13.046C17.5987 13.046 17.4798 13.0024 17.4561 12.8935L16.9568 11.6512L15.6016 11.1936C15.5065 11.1718 15.4352 11.0628 15.4352 10.9538C15.4352 10.8667 15.5065 10.7577 15.6016 10.7359L16.9568 10.2564L17.4561 9.03599C17.4798 8.92702 17.5987 8.86164 17.7176 8.86164C17.8127 8.86164 17.9316 8.92702 17.9553 9.03599L18.4784 10.2564L19.8098 10.7359C19.9287 10.7577 20 10.8667 20 10.9538C20 11.0628 19.9287 11.1718 19.8098 11.1936L18.4784 11.6512Z'
        fill='#333849'
      />
    </svg>
  );
}
