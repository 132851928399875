import { useState } from 'react';
import { useRuleBuilderState, useRuleBuilderUpdater } from '../../context';
import { CloseIcon, ErrorMarkerIcon } from '../../../../../../components/Icons';
import { EditMode } from './EditMode';
import { ViewMode } from './ViewMode';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { ConfirmationModal } from '../../../ConfirmationModal';
import { Tooltip } from '../../../../../../components/Core/Tooltip/Tooltip';

type RuleExpressionProps = {
  expression: IRuleExpression;
  mode?: RuleBuilderMode;
  isOnlyChild?: boolean;
  isOnlyClause?: boolean;
};

export const RuleExpression = ({
  expression,
  mode = 'view',
  isOnlyChild = false,
  isOnlyClause = false
}: RuleExpressionProps) => {
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const errors = useRuleBuilderState((s) => s.errors);
  const removeExpression = useRuleBuilderUpdater((s) => s.removeExpression);

  const Component = mode === 'view' ? ViewMode : EditMode;
  const error = errors[expression.id]?.message;
  const isValid = !error;

  const handleRemove = (force = false) => {
    if (!force && isOnlyChild && !isOnlyClause) {
      setShowRemoveModal(true);
    } else {
      removeExpression(expression.id);
    }
  };

  return (
    <>
      <ConfirmationModal
        title='Warning'
        message='Deleting this condition will result in the actions being moved to the else condition. Do you want to continue?'
        show={showRemoveModal}
        setShow={setShowRemoveModal}
        onConfirm={() => handleRemove(true)}
        onCancel={() => setShowRemoveModal(false)}
      />
      <div
        className={classNames(styles.ruleExpressionContainer, {
          [styles.viewMode]: mode === 'view',
          [styles.editMode]: mode === 'edit',
          [styles.invalid]: !isValid
        })}
      >
        {mode === 'edit' && !isValid && (
          <Tooltip content={error}>
            <div className={styles.ruleExpressionErrorIcon}>
              <ErrorMarkerIcon width={20} height={20} />
            </div>
          </Tooltip>
        )}
        <div className={styles.ruleExpressionContent}>
          <Component
            expression={expression}
            allowRemove={true}
            onRemove={() => handleRemove()}
          />
        </div>
        {mode === 'edit' && (
          <div className={styles.ruleExpressionActions}>
            <CloseIcon width={18} height={18} onClick={() => handleRemove()} />
          </div>
        )}
      </div>
    </>
  );
};
