import {
  DropdownField,
  DropdownMultiField,
  NumberInput,
  TextField
} from '../../../../../../../../components/Core';
import classNames from 'classnames';
import ruleStyles from '../../../../../../../../components/NavigationRules/styles.module.scss';
import comparisonRuleStyles from '../../../../../../../../components/Modals/DecisionLogicModal/styles.module.scss';
import useField from '../../../../../../../../utils/useField';
import styles from '../styles.module.scss';

type OperandFieldValueProps = {
  operand?: IRuleOperand;
  value?: string;
  onComplete?: (value: any) => void;
};

type FieldValueProps = {
  fieldType?: string;
  field?: any;
  value?: string;
  onComplete?: (value: any) => void;
};

const TextFieldValue = (props: FieldValueProps) => {
  const { value, onComplete = () => {} } = props;

  return (
    <TextField
      className={classNames(
        ruleStyles.ruleTextField,
        comparisonRuleStyles.valueInput
      )}
      value={value ?? ''}
      title={value ?? ''}
      placeholder='value'
      onComplete={onComplete}
      triggerCleanUp
    />
  );
};

const NumberFieldValue = (props: FieldValueProps) => {
  const { field, value, onComplete = () => {} } = props;
  const min = field.min_length ?? 0;
  const max = field.max_length ?? undefined;

  return (
    <NumberInput
      classNames={{
        root: classNames(
          styles.numberInput,
          ruleStyles.ruleTextField,
          comparisonRuleStyles.valueInput
        )
      }}
      value={value ?? ''}
      placeholder='number'
      type='number'
      min={min}
      max={max}
      onComplete={({ value }: any) => onComplete(value)}
      triggerCleanUp
    />
  );
};

const CheckboxFieldValue = (props: FieldValueProps) => {
  const { value, onComplete = () => {} } = props;

  return (
    <DropdownField
      className={classNames(
        ruleStyles.ruleTextField,
        comparisonRuleStyles.valueInput
      )}
      selected={value ?? 'true'}
      onChange={(e: any) => onComplete(e.target.value)}
      options={[
        { display: 'checked', value: 'true' },
        { display: 'not checked', value: 'false' }
      ]}
    />
  );
};

const DropdownFieldValue = (props: FieldValueProps) => {
  const { field, value, onComplete = () => {} } = props;
  const options = field.metadata.options.map(
    (option: string, index: number) => {
      return {
        value: option,
        display: field.metadata.option_labels[index] ?? option
      };
    }
  );

  return (
    <DropdownField
      className={classNames(
        ruleStyles.ruleTextField,
        comparisonRuleStyles.valueInput
      )}
      selected={value ?? 'true'}
      onChange={(e: any) => onComplete(e.target.value)}
      options={options}
    />
  );
};

// multiselect, dropdown_multi, button_group (with multiple)
const MultiFieldValue = (props: FieldValueProps) => {
  const { field, value, onComplete = () => {} } = props;
  const options = field.metadata.options.map(
    (option: string, index: number) => {
      return {
        value: option,
        label: field.metadata.option_labels[index] ?? option
      };
    }
  );

  const onChange = (items: { value: string }[]) => {
    if (field.max_length && items.length > field.max_length) {
      return; // Enforce the field's max length
    }

    onComplete(items.map((item) => item.value));
  };

  return (
    <DropdownMultiField
      classNames={{
        multiFieldValueContainer: styles.multiFieldValueContainer
      }}
      options={options}
      selected={value}
      onChange={onChange}
    />
  );
};

export const FieldValueInput = (props: FieldValueProps) => {
  switch (props.fieldType) {
    case 'dropdown':
    case 'select':
      return <DropdownFieldValue {...props} />;
    case 'dropdown_multi':
    case 'multiselect':
      return <MultiFieldValue {...props} />;
    case 'checkbox':
      return <CheckboxFieldValue {...props} />;
    case 'button_group':
      return props.field?.metadata?.multiple ? (
        <MultiFieldValue {...props} />
      ) : (
        <DropdownFieldValue {...props} />
      );
    case 'rating':
    case 'slider':
    case 'integer_field':
      return <NumberFieldValue {...props} />;
    default:
      return <TextFieldValue {...props} />;
  }
};

export const FieldValue = (_props: OperandFieldValueProps) => {
  const { operand, ...props } = _props;
  const fieldType = operand?.meta.servar_type;
  const getField = useField(true);
  const field = getField((operand as IRuleOperand).value);

  if (!fieldType) {
    return <TextFieldValue field={field} {...props} />;
  }

  return <FieldValueInput field={field} fieldType={fieldType} {..._props} />;
};
