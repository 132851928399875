import { getAsset } from '../../../utils/themes';
import { useAppSelector } from '../../../hooks';
import { ConnectDragSource } from 'react-dnd';
import useFeatheryRedux from '../../../redux';
import { newElement } from '../../../utils/step';

import useCustomDragLayer from './useCustomDragLayer';
import { Element } from '../../RenderingEngine/GridInGrid/components/CellElement';
import { CustomDragOptions } from '../index';
import { Cell } from '../../RenderingEngine/GridInGrid/engine';
import useViewport from '../../../hooks/useViewport';

import reduxStore from '../../../redux/store';
import { refreshNode } from '../../RenderingEngine/utils/gig';

export const FormElementIdentifier = 'form-element';

export enum ElementDragOperation {
  New = 'new',
  Move = 'move'
}

export interface MoveElementDragArgs {
  operation: ElementDragOperation.Move;
  node: Cell;
  opts?: CustomDragOptions;
}

export interface NewElementDragArgs {
  operation: ElementDragOperation.New;
  type: string;
  assetId?: string;
  opts?: CustomDragOptions;
}

const useElementDrag = (
  getArgs: () => MoveElementDragArgs | NewElementDragArgs
): ConnectDragSource | null => {
  const theme = useAppSelector((state) => state.formBuilder.theme);
  const { isMobile } = useViewport();

  const {
    // @ts-ignore TODO(ts) featheryRedux does not have a defined return type
    formBuilder: { updateDrag, gigSetPosition },
    toasts: { addInfoToast }
  } = useFeatheryRedux();

  const args = getArgs();

  let node: Cell | null = null;
  let element: any;
  let elementType: any;
  let assetId: any;

  if (args.operation === ElementDragOperation.New && theme) {
    elementType = args.type;
    assetId = args.assetId;

    const defaultProperties =
      FeatheryConfig.default_element_properties[elementType];
    const asset = getAsset(theme, elementType, assetId);

    // newElement needs the servars in order to name the element (e.g. "Text_3").
    // we get the servars from the redux store directly in the callback to prevent
    // rerenders from the useAppSelector hook
    const servars = reduxStore.getState().formBuilder.servars;

    element = newElement({
      elementType,
      defaultProperties,
      asset,
      fields: Object.values(servars)
    });
    element._type = elementType;
  }

  if (args.operation === ElementDragOperation.Move) {
    element = args.node?.unlinked()?.element?.copy().getRaw();
    elementType = args?.node?._type;
    node = args.node;
  }

  const [, dragRef] = useCustomDragLayer(
    () => ({
      type: FormElementIdentifier,
      renderer: () => <Element element={element} />,
      onStart: () => {
        if (isMobile) {
          addInfoToast('Use Desktop view to drag elements');
          return null;
        }

        updateDrag(true);

        // refresh node with uncached version
        const updatedNode = refreshNode(node);

        if (args.operation === ElementDragOperation.Move && node?.position) {
          gigSetPosition(node.position);
        }

        return {
          operation: args.operation,
          element,
          elementType,
          assetId,
          node: updatedNode
        };
      },
      onEnd: () => {
        updateDrag(false);
        if (args && args.opts && args.opts.onEnd) args.opts.onEnd();
      },
      opts: args.opts
    }),
    [element, node?.position]
  );

  if (node && node.isContainer) return null;

  return dragRef;
};

export default useElementDrag;
