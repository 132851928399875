import { Action, ActionFn } from './actions';

export const connect =
  <T>(dispatch: React.Dispatch<Action>) =>
  (actions: { [key: string]: ActionFn }): T => {
    return Object.keys(actions).reduce((wrappedActions, currentAction) => {
      return {
        ...wrappedActions,
        [currentAction]: actions[currentAction](dispatch)
      };
    }, {} as T);
  };
