export default function ShowIcon({
  width = 32,
  height = 32,
  color = '#414859'
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16 9C10.6545 9 6.07273 12.1071 4 16.5C6.07273 20.8929 10.6545 24 16 24C21.3455 24 25.9273 20.8929 28 16.5C25.9273 12.1071 21.3455 9 16 9Z'
        stroke={color}
        strokeWidth='3'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.0001 19.2858C17.5665 19.2858 18.8364 18.0386 18.8364 16.5001C18.8364 14.9616 17.5665 13.7144 16.0001 13.7144C14.4336 13.7144 13.1637 14.9616 13.1637 16.5001C13.1637 18.0386 14.4336 19.2858 16.0001 19.2858Z'
        fill={color}
      />
    </svg>
  );
}
