function MicrosoftDynamicsCRM({ width = 50, height = 80 }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 10 16'
      width={width}
      height={height}
    >
      <path d='M0 16l3.117-8.946L0 5.01zM9.858 10.467V5.388L0 16zM0 0v4.287L6.433 7.442L9.423 5.01z' />
    </svg>
  );
}

export default MicrosoftDynamicsCRM;
