interface FolderData {
  folder: string;
  [key: string]: any; // This allows any other properties with any type
}

export function groupByFolder(data: FolderData[]) {
  const acc: Record<string, FolderData[]> = {};
  const result = data.reduce((acc, item) => {
    if (item.folder) {
      if (!acc[item.folder]) {
        acc[item.folder] = [];
      }
      acc[item.folder].push(item);
    } else {
      if (!acc['']) {
        acc[''] = [];
      }
      acc[''].push(item);
    }
    return acc;
  }, acc);

  return result;
}
