import Dialog from '../../../../components/Dialog';
import { DropdownField } from '../../../Core';

const TriggerSwitchModal = ({ show, close, switchTrigger }: any) => {
  return (
    <Dialog isOpen={show} onClose={() => close()} title='Choose New Trigger'>
      <div className='switch-property-modal'>
        <div className='switch-property-container'>
          <DropdownField
            className='trigger-switch-input'
            options={[
              { value: 'form-completion', display: 'Form Completion' },
              { value: 'data-received', display: 'Data Received' },
              { value: 'step-loaded', display: 'Step Loaded' },
              { value: 'file-submission', display: 'File Submission' },
              { value: 'custom-logic', display: 'Custom Logic' }
            ]}
            onChange={(event: any) => {
              const newTrigger = event.target.value;
              switchTrigger(newTrigger);
              close();
            }}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default TriggerSwitchModal;
