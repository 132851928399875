import * as Icons from '../../../Icons';

import { Col, Row } from 'react-bootstrap';
import {
  CollapsibleSection,
  CornerStyleInput,
  NumberInput,
  PropertyLabel,
  RadioButtonGroup
} from '../../../Core';
import {
  assetUpdateOperations,
  CORNER_PROPS,
  elementOperation,
  getAsset,
  level2UpdateOperations
} from '../../../../utils/themes';
import { memo } from 'react';

import { calculateOverrideObjects } from '../../utils';
import { objectPick } from '../../../../utils/core';
import { TYPE_IMAGE } from '../../../../utils/elements';
import { Viewport } from '../../../RenderingEngine/GridInGrid/engine';
import { useDimensionInput } from '../../../Core/DimensionInput/hooks/useDimensionInput';
import { DimensionChangeEvent } from '../../../Core/DimensionInput/hooks/types';
import LinkButton from '../../../Core/DimensionInput/components/LinkButton';
import styles from './styles.module.scss';
import { AXIS } from '../../../../utils/constants';
import BoxSpacingInput from '../../../Core/BoxSpacingInput';

function ImageStylePanel({
  mode,
  viewport,
  theme,
  baseProps,
  baseStyle,
  overrideProps,
  overrideStyle,
  handleUpdates,
  cellNode
}: any) {
  const { result: element } = calculateOverrideObjects(
    baseProps,
    overrideProps,
    true
  );

  const asset = getAsset(theme, TYPE_IMAGE, element.source_asset);
  const { result: elementStyle, isOverride: isOverrideStyle } =
    calculateOverrideObjects(baseStyle, overrideStyle);

  function handleStyleChange(styleUpdate: any) {
    handleUpdates([elementOperation({ viewport, styleUpdate })]);
  }

  function handleDimensionChange(value: DimensionChangeEvent) {
    handleStyleChange({
      width: value.x,
      height: value.y,
      width_unit: value.xUnit,
      height_unit: value.yUnit
    });
  }

  const dimensions = useDimensionInput({
    x: elementStyle.width,
    y: elementStyle.height,
    xUnit: elementStyle.width_unit,
    yUnit: elementStyle.height_unit,
    linkRatio: true,
    linkByDefault: true,
    handleUnits: true,
    onChange: handleDimensionChange
  });

  function labelData(...props: string[]) {
    const newStyle = objectPick(elementStyle, props);
    const key = viewport === Viewport.Mobile ? 'Desktop' : 'Theme';
    return {
      mode,
      highlighted: isOverrideStyle(props),
      themeOperations: {
        instance: {
          label: `Reset to ${key}`,
          operation: () => {
            handleUpdates([elementOperation({ viewport, styleReset: props })]);
          }
        },
        ...(mode === 'builder' && {
          level2: {
            label: 'Images',
            operation: () => {
              handleUpdates(
                level2UpdateOperations({
                  viewport,
                  newStyle,
                  asset,
                  elementType: TYPE_IMAGE,
                  level2: 'image'
                })
              );
            }
          }
        }),
        ...(asset && {
          asset: {
            label: asset.key,
            operation: () => {
              handleUpdates(
                assetUpdateOperations({
                  viewport,
                  elementType: TYPE_IMAGE,
                  asset,
                  newStyle
                })
              );
            }
          }
        })
      }
    };
  }

  return (
    <>
      <CollapsibleSection title='Layout' collapsible>
        <Row className={styles.dimensionsInput}>
          <div className={styles.dimensionsInputLabel}>
            <PropertyLabel
              label='Width'
              {...labelData('width', 'width_unit')}
            />
          </div>
          <div className={styles.dimensionsInputField}>
            <NumberInput
              required
              min={0}
              value={dimensions.state.x}
              unit={elementStyle.width_unit}
              units={['px', '%']}
              onChange={(value: number) => dimensions.handleChange('x', value)}
              onComplete={(value: any) => {
                dimensions.handleChange('x', value, true);
              }}
              triggerCleanUp
              dimension='width'
            />
          </div>
          <div className={styles.dimensionsInputLabel}>
            <PropertyLabel
              label='Height'
              indented
              {...labelData('height', 'height_unit')}
            />
          </div>
          <div className={styles.dimensionsInputField}>
            <NumberInput
              required
              min={0}
              value={dimensions.state.y}
              unit={elementStyle.height_unit}
              units={['px', '%']}
              onChange={(value: number) => dimensions.handleChange('y', value)}
              onComplete={(value: any) => {
                dimensions.handleChange('y', value, true);
              }}
              triggerCleanUp
              dimension='height'
            />
          </div>
          <div className={styles.dimensionsInputLinkButton}>
            <LinkButton
              linked={dimensions.state.linked}
              handleLink={dimensions.handleLink}
              disabled={!dimensions.canRatioLock}
            />
          </div>
        </Row>
        <BoxSpacingInput
          elementStyle={elementStyle}
          handleStyleChange={handleStyleChange}
          labelData={labelData}
          elementType={TYPE_IMAGE}
        />
        {cellNode && (
          <Row>
            <Col sm='3'>
              <PropertyLabel
                label='Align Self'
                {...labelData('layout', 'vertical_layout')}
              />
            </Col>
            <Col>
              <RadioButtonGroup
                selected={elementStyle.layout}
                name='image-editor-horizontal-alignment'
                allowUnselect
                hideValueIfDisabled
                disabled={!cellNode.parent || cellNode.parent.axis === AXIS.COL}
                onChange={(value: any) => handleStyleChange({ layout: value })}
                options={[
                  {
                    value: 'flex-start',
                    display: <Icons.HorizontalLeftIcon />
                  },
                  { value: 'center', display: <Icons.HorizontalCenterIcon /> },
                  { value: 'flex-end', display: <Icons.HorizontalRightIcon /> }
                ]}
              />
            </Col>
            <Col>
              <RadioButtonGroup
                selected={elementStyle.vertical_layout}
                name='image-editor-vertical-alignment'
                allowUnselect
                hideValueIfDisabled
                disabled={!cellNode.parent || cellNode.parent.axis === AXIS.ROW}
                onChange={(value: any) =>
                  handleStyleChange({ vertical_layout: value })
                }
                options={[
                  { value: 'flex-start', display: <Icons.VerticalTopIcon /> },
                  { value: 'center', display: <Icons.VerticalCenterIcon /> },
                  { value: 'flex-end', display: <Icons.VerticalBottomIcon /> }
                ]}
              />
            </Col>
          </Row>
        )}
        {mode !== 'theme' && (
          <Row>
            <Col sm='3'>
              <PropertyLabel label='Visibility' />
            </Col>
            <Col>
              <RadioButtonGroup
                selected={elementStyle.visibility}
                name='image-editor-visibility'
                onChange={(value: any) =>
                  handleStyleChange({ visibility: value })
                }
                options={[
                  {
                    value: 'visible',
                    display: <Icons.ShowIcon width={18} height={18} />
                  },
                  {
                    value: 'hidden',
                    display: <Icons.HideIcon width={18} height={18} />
                  }
                ]}
              />
            </Col>
          </Row>
        )}
      </CollapsibleSection>
      <CollapsibleSection title='Display' collapsible>
        <Row>
          <Col>
            <PropertyLabel label='Corners' {...labelData(...CORNER_PROPS)} />
          </Col>
        </Row>
        <Row>
          <Col>
            <CornerStyleInput
              topLeft={elementStyle.corner_top_left_radius}
              topRight={elementStyle.corner_top_right_radius}
              bottomLeft={elementStyle.corner_bottom_left_radius}
              bottomRight={elementStyle.corner_bottom_right_radius}
              onComplete={(newCorners: any) =>
                handleStyleChange({
                  corner_top_left_radius: newCorners.topLeft,
                  corner_top_right_radius: newCorners.topRight,
                  corner_bottom_left_radius: newCorners.bottomLeft,
                  corner_bottom_right_radius: newCorners.bottomRight
                })
              }
            />
          </Col>
        </Row>
      </CollapsibleSection>
    </>
  );
}

export default memo(ImageStylePanel);
