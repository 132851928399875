function Freshservice({ width = 128, height = 128 }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 652 652'
      width={width}
      height={height}
      enableBackground='new 0 0 652 652'
      xmlSpace='preserve'
    >
      <g>
        <path
          style={{ fill: '#4D4D4D' }}
          d='M155.4,268.7c-13.8,0-18.7,9.6-18.7,18.6v5.5h-2.9c-2.1,0-3.8,1.7-3.8,3.8s1.7,3.8,3.8,3.8h2.9v38
		c0,2.2,1.8,4,4,4c2.2,0,4-1.8,4-4v-38h8.8c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8h-8.8v-5.5c0-5,1.9-11,10.7-11
		c2.1,0,3.8-1.7,3.8-3.8C159.2,270.4,157.6,268.7,155.4,268.7'
        />
        <path
          style={{ fill: '#4D4D4D' }}
          d='M186.7,291.6c-5.9,0-11,2.4-13.9,6.5V296c0-2.2-1.8-4-4-4c-2.2,0-4,1.8-4,4v42.4c0,2.2,1.8,4,4,4
		c2.2,0,4-1.8,4-4V315c0-9.8,5.2-15.6,13.9-15.6c2.1,0,3.9-1.7,3.9-3.9C190.5,293.3,188.8,291.6,186.7,291.6'
        />
        <path
          style={{ fill: '#4D4D4D' }}
          d='M235.6,312.4c0-2.3-0.4-8.2-3.8-13.2c-3.6-5.2-9.2-7.8-16.8-7.8c-14.1,0-22.2,9.4-22.2,25.7
		c0,16.9,8,25.8,23.2,25.8c10.2,0,15.7-5,16.7-6c0.7-0.7,1.3-1.5,1.3-2.9c0-2-1.6-3.7-3.7-3.7c-1.1,0-1.6,0.3-2.3,0.8
		c-2.9,2.2-6.3,4.4-12.1,4.4c-9.1,0-14.9-6-15.1-15.7h29.8C235.6,319.7,235.6,315.3,235.6,312.4 M215,298.7
		c8.2,0,12.5,4.7,12.7,13.8H201C201.9,303.6,206.8,298.7,215,298.7'
        />
        <path
          style={{ fill: '#4D4D4D' }}
          d='M262.6,313c-6-2.1-11.1-3.8-11.1-8.3c0-4.9,5.4-5.6,8.6-5.6c5.8,0,8.7,2,10.9,4.2c0.8,0.7,1.6,1.1,2.7,1.1
		c2,0,3.7-1.6,3.7-3.7c0-1.1-0.5-1.8-0.9-2.4c-3.4-4.5-9.2-6.9-16.4-6.9c-10.3,0-16.6,5.1-16.6,13.3c0,10,8.8,13,16.5,15.6
		c5.9,2,10.9,3.7,10.9,8c0,5.9-6.5,6.8-10.4,6.8c-5.3,0-9.5-1.6-12.8-4.9c-1-1-2.1-1.2-2.8-1.2c-2,0-3.7,1.7-3.7,3.7
		c0,0.9,0.2,1.5,0.9,2.4c3,3.9,9.9,7.8,18.4,7.8c11.5,0,18.4-5.4,18.4-14.4C278.9,318.7,270.3,315.7,262.6,313'
        />
        <path
          style={{ fill: '#4D4D4D' }}
          d='M310.1,291.4c-5.5,0-10.5,2.2-13.2,5.7v-27.2c0-2.2-1.8-4-4-4c-2.2,0-4,1.8-4,4v68.5c0,2.2,1.8,4,4,4
		c2.2,0,4-1.8,4-4v-25.8c0-9.3,4.5-14,13.2-14c8.4,0,11.9,3.8,11.9,13.2v26.5c0,2.2,1.8,4,4,4s4-1.8,4-4v-27.9
		C330,297.8,323.3,291.4,310.1,291.4'
        />
        <path
          style={{ fill: '#4D4D4D' }}
          d='M359.2,313c-6-2.1-11.1-3.8-11.1-8.3c0-4.9,5.4-5.6,8.6-5.6c5.8,0,8.7,2,10.9,4.2c0.7,0.7,1.6,1.1,2.7,1.1
		c2,0,3.7-1.6,3.7-3.7c0-1.1-0.5-1.8-0.9-2.4c-3.4-4.5-9.2-6.9-16.4-6.9c-10.3,0-16.6,5.1-16.6,13.3c0,10,8.8,13,16.5,15.6
		c5.9,2,10.9,3.7,10.9,8c0,5.9-6.5,6.8-10.4,6.8c-5.3,0-9.5-1.6-12.8-4.9c-1-1-2.1-1.2-2.8-1.2c-2,0-3.7,1.7-3.7,3.7
		c0,0.9,0.2,1.5,0.9,2.4c3,3.9,9.9,7.8,18.4,7.8c11.5,0,18.4-5.4,18.4-14.4C375.5,318.7,366.8,315.7,359.2,313'
        />
        <path
          style={{ fill: '#4D4D4D' }}
          d='M425.1,312.4c0-2.3-0.4-8.2-3.8-13.2c-3.6-5.2-9.2-7.8-16.8-7.8c-14.1,0-22.2,9.4-22.2,25.7
		c0,16.9,8,25.8,23.2,25.8c10.2,0,15.7-5,16.7-6c0.7-0.7,1.3-1.5,1.3-2.9c0-2-1.6-3.7-3.7-3.7c-1,0-1.5,0.2-2.3,0.8
		c-2.9,2.2-6.3,4.4-12.1,4.4c-9.1,0-14.9-6-15.1-15.7h29.8C425.1,319.7,425.1,315.3,425.1,312.4 M404.5,298.7
		c8.2,0,12.5,4.7,12.7,13.8h-26.7C391.3,303.6,396.3,298.7,404.5,298.7'
        />
        <path
          style={{ fill: '#4D4D4D' }}
          d='M455.9,291.6c-5.9,0-11,2.4-13.9,6.5V296c0-2.2-1.8-4-4-4s-4,1.8-4,4v42.4c0,2.2,1.8,4,4,4s4-1.8,4-4V315
		c0-9.8,5.2-15.6,13.9-15.6c2.1,0,3.9-1.7,3.9-3.9C459.8,293.3,458.1,291.6,455.9,291.6'
        />
        <path
          style={{ fill: '#4D4D4D' }}
          d='M504.6,292c-1.8,0-3.1,0.9-3.7,2.6l-14.1,34.5l-14.1-34.5c-0.9-2.1-2.5-2.6-3.7-2.6c-2.2,0-4,1.8-4,4
		c0,0.7,0.2,1.2,0.3,1.5l0.1,0.2l17.7,42.2l0,0c0.7,1.6,2.1,2.5,3.7,2.5s3-0.9,3.7-2.5l17.8-42.2l0.1-0.2c0.1-0.3,0.3-0.8,0.3-1.5
		C508.6,293.7,506.8,292,504.6,292'
        />
        <path
          style={{ fill: '#4D4D4D' }}
          d='M519.6,274c-3.1,0-4.6,1.6-4.6,4.8c0,2.8,1.8,4.6,4.6,4.6c2.8,0,4.6-1.8,4.6-4.6
		C524.2,275.7,522.7,274,519.6,274'
        />
        <path
          style={{ fill: '#4D4D4D' }}
          d='M519.6,292c-2.2,0-4,1.8-4,4v42.4c0,2.2,1.8,4,4,4c2.2,0,4-1.8,4-4V296C523.6,293.7,521.8,292,519.6,292'
        />
        <path
          style={{ fill: '#4D4D4D' }}
          d='M569,330.3c-0.9,0-1.5,0.2-2.4,0.9c-2.3,1.7-5.9,4.3-11.7,4.3c-9.5,0-14.1-6-14.1-18.4
		c0-12.2,4.7-18.3,14.1-18.3c6.7,0,9.3,3,10.8,4.7c0.9,1,1.8,1.5,3,1.5c2.1,0,3.7-1.6,3.7-3.7c0-0.9-0.2-1.5-0.9-2.4
		c-0.9-1.2-6-7.5-16.6-7.5c-14.5,0-22.2,8.9-22.2,25.7c0,16.9,7.7,25.8,22.2,25.8c9.1,0,14.4-4.4,16.2-5.8c1.1-1,1.6-1.9,1.6-3.1
		C572.7,332,571,330.3,569,330.3'
        />
        <path
          style={{ fill: '#4D4D4D' }}
          d='M616.4,330.3c-1,0-1.5,0.2-2.3,0.8c-2.9,2.2-6.3,4.4-12.1,4.4c-9.1,0-14.9-6-15.1-15.7h29.8
		c4.8,0,4.8-4.4,4.8-7.3c0-2.3-0.4-8.2-3.8-13.2c-3.6-5.2-9.2-7.8-16.8-7.8c-14.1,0-22.2,9.4-22.2,25.7c0,16.9,8,25.8,23.2,25.8
		c10.2,0,15.7-5,16.7-6c0.7-0.7,1.3-1.5,1.3-2.9C620,332,618.4,330.3,616.4,330.3 M600.9,298.7c8.2,0,12.5,4.7,12.7,13.8h-26.7
		C587.7,303.6,592.7,298.7,600.9,298.7'
        />
        <path
          style={{ fill: '#08C7FB' }}
          d='M68.5,376.1L68.5,376.1c-25.9,0-47-21-47-47c0-25.9,21-47,47-47h35.2c6.5,0,11.7,5.3,11.7,11.7v35.2
		C115.4,355.1,94.4,376.1,68.5,376.1'
        />
        <path
          style={{ fill: '#FFFFFF' }}
          d='M67.8,352.4c0.6,0.4,1.4,0.3,1.8-0.3l14.8-20.6l3.4-4.8c0.8-1.1,0-2.7-1.4-2.7h-6.6H64.2l5.5-16.6
		c0.2-0.5,0-1.1-0.5-1.5c-0.6-0.4-1.4-0.3-1.8,0.3l-14.8,20.6l-3.4,4.8c-0.8,1.1,0,2.7,1.4,2.7h6.6h15.7l-5.5,16.6
		C67.1,351.4,67.3,352,67.8,352.4'
        />
      </g>
    </svg>
  );
}

export default Freshservice;
