import { useAppSelector } from '../../../hooks';
import { TextField } from '../../../components/Core';
import SettingsContainer from '../../../components/SettingsContainer';
import { Form } from 'react-bootstrap';
import UploadMetaImage from '../components/UploadMetaImage';
import { Tooltip } from '../../../components/Core/Tooltip/Tooltip';

const SEOSection = ({ title, formData, handleValueChange }: any) => {
  const org = useAppSelector((state) => state.accounts.organization);
  const seoEnabled = org && org.tier > 0;

  let seoTitle = (
    <TextField
      value={formData.seo_title ?? ''}
      onComplete={handleValueChange('seo_title')}
      disabled={!seoEnabled}
    />
  );
  let seoDescription = (
    <TextField
      type='textarea'
      value={formData.seo_description ?? ''}
      onComplete={handleValueChange('seo_description')}
      disabled={!seoEnabled}
    />
  );
  let seoMetaImage = <UploadMetaImage />;
  if (!seoEnabled) {
    seoTitle = (
      <Tooltip content='SEO access is available on paid plans'>
        {seoTitle}
      </Tooltip>
    );
    seoDescription = (
      <Tooltip content='SEO access is available on paid plans'>
        {seoDescription}
      </Tooltip>
    );
    seoMetaImage = (
      <Tooltip content='SEO access is available on paid plans'>
        {seoMetaImage}
      </Tooltip>
    );
  }

  return (
    <SettingsContainer title={title}>
      <div className='field-group'>
        <Form.Label>Title Tag</Form.Label>
        {seoTitle}
      </div>
      <div className='field-group'>
        <Form.Label>Meta Description</Form.Label>
        {seoDescription}
      </div>
      <div className='field-group'>
        <Form.Label>Meta Image</Form.Label>
        {seoMetaImage}
      </div>
    </SettingsContainer>
  );
};

export default SEOSection;
