import { URL, httpDelete, httpGet, httpPatch, httpPost } from './utils';

export default {
  getABTests: (token: string) => {
    return httpGet({ token, url: URL.AB_TESTS });
  },
  deleteABTest: (token: string, { abTestId }: any) => {
    return httpDelete({ token, baseUrl: URL.AB_TESTS, path: `${abTestId}/` });
  },
  createABTest: (token: string, data: any) => {
    return httpPost({ token, url: URL.AB_TESTS, body: data });
  },
  editABTest: (token: string, data: any) => {
    const { abTestId, ...abTestData } = data;
    return httpPatch({
      token,
      baseUrl: URL.AB_TESTS,
      path: `${abTestId}/`,
      body: abTestData
    });
  }
};
