export default function CaseCapitalIcon({ width = 21, height = 14 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 21 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M6 2H10V0H0V2H4V5H6V2Z' fill='#414859' />
      <path d='M4 14V5H6V14H4Z' fill='#414859' />
      <path d='M17 6H20.5V4H11.5V6H15V9H17V6Z' fill='#414859' />
      <path d='M15 14V9H17V14H15Z' fill='#414859' />
    </svg>
  );
}
