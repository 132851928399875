import { useMemo } from 'react';
import { useAppSelector } from '.';
import {
  Viewport,
  ViewportType
} from '../components/RenderingEngine/GridInGrid/engine/viewports';
import useFeatheryRedux from '../redux';

const useViewport = () => {
  const { updateAccountViewport } = useFeatheryRedux();

  const viewport = useAppSelector<ViewportType>(
    ({ accounts }) => accounts.account.viewport_toggle
  );

  const setViewport = (newViewport: ViewportType) => {
    if (newViewport === viewport) return;
    updateAccountViewport({ viewport_toggle: newViewport });
  };

  return useMemo(
    () => ({
      setViewport,
      viewport,
      isMobile: viewport === Viewport.Mobile,
      isDesktop: viewport === Viewport.Desktop,
      isUnconstrained: viewport === Viewport.Unconstrained
    }),
    [viewport]
  );
};

export default useViewport;
