import {
  CopyIcon,
  LinkIcon,
  DownloadIcon,
  TrashIcon,
  FolderIcon,
  EditIcon
} from '../Icons';
import { TableAction } from './types';

export function getTableActions({
  onShare,
  onCopy,
  onDownload,
  downloadName,
  onMoveToFolder,
  onDelete,
  onEdit
}: any): TableAction[] {
  const tableActions: TableAction[] = [];
  if (onShare) {
    tableActions.push({
      id: 'share',
      tooltip: 'Copy URL',
      icon: LinkIcon,
      iconClassname: 'tr-icon-fill',
      onClick: onShare
    });
  }
  if (onCopy) {
    tableActions.push({
      id: 'copy',
      tooltip: 'Duplicate',
      icon: CopyIcon,
      onClick: onCopy
    });
  }
  if (onDownload) {
    tableActions.push({
      id: 'download',
      tooltip: `Download ${downloadName}`,
      icon: DownloadIcon,
      onClick: onDownload
    });
  }
  if (onMoveToFolder) {
    tableActions.push({
      id: 'move',
      tooltip: 'Move to folder',
      icon: FolderIcon,
      onClick: onMoveToFolder
    });
  }
  if (onEdit) {
    tableActions.push({
      id: 'edit',
      tooltip: 'Edit',
      icon: EditIcon,
      onClick: onEdit
    });
  }
  if (onDelete) {
    tableActions.push({
      id: 'delete',
      tooltip: 'Delete',
      icon: TrashIcon,
      onClick: onDelete
    });
  }
  return tableActions;
}
