/* eslint-disable react-hooks/exhaustive-deps */

import { memo, useMemo, useState } from 'react';

import HeaderFilterResultsTable from '../components/HeaderFilterResultsTable';
import { useHistory } from 'react-router-dom';
import { CreateThemeModal, DeleteThemeModal } from '../components/Modals';
import { formatDate, uniqifyKey } from '../utils/format';
import { UsageEnforcement } from '../components/PricingPlans';
import styles from './styles.module.scss';
import classNames from 'classnames';
import useFeatheryRedux from '../redux';
import { useAppSelector } from '../hooks';

function ThemesPage() {
  const history = useHistory();

  const [themeToDelete, setThemeToDelete] = useState('');
  const [isCreating, setIsCreating] = useState(false);
  const themes = useAppSelector((state) => state.themes.themes);

  const {
    createTheme,
    toasts: { addToast }
  } = useFeatheryRedux();

  const dataToRender = useMemo(
    () =>
      Object.values(themes).map((t) => ({
        ...t,
        updated_at_pretty: formatDate((t as any).updated_at)
      })),
    [themes]
  );

  return (
    <>
      <div className={styles.pageHeadRow}>
        <h1 className={classNames('page-head', styles.pageHeadTitle)}>
          Themes
        </h1>
        <UsageEnforcement />
      </div>
      <HeaderFilterResultsTable
        data={dataToRender}
        columns={[
          { key: 'key', name: 'Name' },
          {
            key: 'updated_at_pretty',
            name: 'Last Updated',
            sortBy: 'updated_at'
          }
        ]}
        defaultSort={{ order: -1, key: 'updated_at' }}
        useSearch
        name='Theme'
        onCreate={() => setIsCreating(true)}
        onSelect={(theme: any) => history.push(`/themes/${theme.id}`)}
        onCopy={(theme: any, e: any) => {
          e.preventDefault();
          e.stopPropagation();
          const oldKey = theme.key;
          const allKeys = Object.values(themes).map(
            (theme) => (theme as any).key
          );
          const newKey = uniqifyKey(oldKey, allKeys);
          createTheme({ themeId: newKey, copyThemeKey: oldKey }).then(() => {
            addToast({
              text: (
                <>
                  <strong>{newKey}</strong> was created.
                </>
              )
            });
          });
        }}
        onDelete={(theme: any, e: any) => {
          e.preventDefault();
          e.stopPropagation();
          setThemeToDelete(theme.id);
        }}
      />
      <CreateThemeModal
        show={isCreating}
        setShow={(show: any) => setIsCreating(show)}
        themes={themes}
      />
      <DeleteThemeModal
        setShow={() => setThemeToDelete('')}
        show={Boolean(themeToDelete)}
        themeId={themeToDelete}
      />
    </>
  );
}

export default memo(ThemesPage);
