import { useRef, useState } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { PlusCircleIcon, PlusIcon, TrashIcon } from '../../../../Icons';
import { ContextMenu } from '../../../../Core';
import { useGlobalMouseDownToggle } from '../../../../Core/util';
import { Step } from '../../../../../utils/step';
import { StepIdentifier } from '../../../../CustomDragLayer/hooks/useStepDrag';
import { useDrop } from 'react-dnd';
import { DirectionalEdge } from '../../linearFlow';

interface NavigationEdgeProps {
  edge: DirectionalEdge;
  sourceRef: HTMLElement | null | undefined;
  targetRef: HTMLElement | null | undefined;
  containerRef: HTMLElement | null | undefined;
  bidirectional: boolean;
  direction: 'up' | 'down';
  lane: number | null;
  activeStep: boolean;
  sourceConnection: number | null;
  sourceTotalConnections: number;
  targetConnection: number | null;
  targetTotalConnections: number;
  onInsertNewOrExistingStep: (edge: DirectionalEdge, step?: Step) => void;
  onDisconnect: () => void;
}
function NavigationEdge({
  edge,
  sourceRef,
  targetRef,
  containerRef,
  bidirectional = false,
  direction,
  lane,
  activeStep,
  sourceConnection,
  sourceTotalConnections,
  targetConnection,
  targetTotalConnections,
  onInsertNewOrExistingStep,
  onDisconnect
}: NavigationEdgeProps) {
  const containerRect = containerRef?.getBoundingClientRect();
  const { left: horizOffset = 0, top: vertOffset = 0 } = containerRect ?? {};

  const {
    left: sourceLeft = 0,
    right: sourceRight = 0,
    top: sourceTop = 0,
    bottom: sourceBottom = 0,
    height: sourceHeight = 0
  } = sourceRef?.getBoundingClientRect() ?? {};
  const {
    right: targetRight = 0,
    top: targetTop = 0,
    bottom: targetBottom = 0,
    height: targetHeight = 0
  } = targetRef?.getBoundingClientRect() ?? {};

  let sourceX: number, sourceY: number, targetX: number, targetY: number;
  const markerLength = 3;
  const startMarkerLength = bidirectional || lane !== null ? 3 : 0;
  if (lane === null) {
    // adjacent steps
    sourceX = sourceLeft + Math.round((sourceRight - sourceLeft) / 2);
    targetX = sourceX;
    if (direction === 'down') {
      sourceY = sourceBottom + startMarkerLength;
      targetY = targetTop - markerLength;
    } else {
      sourceY = sourceTop - startMarkerLength;
      targetY = targetBottom + markerLength;
    }
  } else {
    // branch
    sourceX = sourceRight + markerLength;
    // spacing out the connections points
    sourceY =
      sourceTop +
      ((sourceConnection ?? 0) + 1) *
        (sourceHeight / (sourceTotalConnections + 1));
    targetX = targetRight + markerLength;
    // spacing out the connections points
    targetY =
      targetTop +
      ((targetConnection ?? 0) + 1) *
        (targetHeight / (targetTotalConnections + 1));
  }

  // adjust by offsets
  sourceX -= horizOffset;
  sourceY -= vertOffset;
  targetX -= horizOffset;
  targetY -= vertOffset;

  // dropzone
  const dropZoneWidth = sourceRight - sourceLeft;
  let dropZoneHeight =
    targetTop > sourceBottom
      ? targetTop - sourceBottom
      : sourceTop - targetBottom;
  if (dropZoneHeight > 0) dropZoneHeight = dropZoneHeight - 2; // no overlap box above/below
  const dropZoneX = sourceLeft - horizOffset;
  const dropZoneY =
    (targetBottom > sourceBottom
      ? sourceBottom - vertOffset
      : targetBottom - vertOffset) + 1; // no overlap box above

  const OFFSET_INCREMENT = 8;
  const branchOffset = lane !== null ? (lane + 1) * OFFSET_INCREMENT : 0;
  const cornerRadius = 4;
  const cornerYOffset = direction === 'down' ? cornerRadius : -1 * cornerRadius;

  const cornerArc = (
    x: number,
    y: number,
    cornerYOffset: number,
    sweep: 0 | 1
  ) => {
    if (direction === 'down')
      return (
        <path
          d={`M ${
            x + branchOffset - cornerRadius
          } ${y} A ${cornerRadius} ${cornerRadius} 0 0 ${sweep} ${
            x + branchOffset
          } ${y + cornerYOffset}`}
        />
      );
    else
      return (
        <path
          d={`M ${x + branchOffset} ${
            y + cornerYOffset
          } A ${cornerRadius} ${cornerRadius} 0 0 ${sweep} ${
            x + branchOffset - cornerRadius
          } ${y}`}
        />
      );
  };

  // Step entries can be dragged onto connections
  const [{ isOver, canDrop }, dropRef] = useDrop(
    () => ({
      accept: [StepIdentifier],
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
      }),
      canDrop: (item: { step: { id: string; key: string } }) => {
        return item.step.id !== edge.source && item.step.id !== edge.target;
      },
      drop: (item: { step: Step }) => {
        onInsertNewOrExistingStep(edge, item.step as Step);
      }
    }),
    []
  );

  // CONDITION CONTEXT MENU
  const plusContextMenuRef = useRef<HTMLDivElement>(null);
  const [showContextMenu, setShowContextMenu] = useGlobalMouseDownToggle([
    plusContextMenuRef
  ]);
  const plusSize = showContextMenu || (canDrop && isOver) ? 22 : 16;
  const [position, setPosition] = useState({});
  function revealContextMenu(event: MouseEvent) {
    event.preventDefault();
    setPosition({
      x: event.clientX,
      y: event.clientY
    });
    setShowContextMenu(true);
  }

  const drawUpDownArrow = (up: boolean) => {
    if (up) return <polygon points={'-2.5 0, 2.5 0, 0 -3'} />;
    return <polygon points={'-2.5 0, 2.5 0, 0 3'} />;
  };
  const drawArrow = () => <polygon points={'-3 0, 0 2.5, 0 -2.5'} />;

  return (
    <div className={styles.navEdgeContainer} ref={dropRef}>
      <svg xmlns='http://www.w3.org/2000/svg' className={styles.navEdge}>
        <g
          strokeWidth='2'
          className={classNames(styles.line, activeStep ? styles.active : '')}
          fill='none'
        >
          {lane === null && (
            <svg
              width={dropZoneWidth}
              className={classNames(styles.dropArea, {
                [styles.canDrop]: canDrop && isOver,
                [styles.activeMenu]: showContextMenu
              })}
            >
              <rect
                x={dropZoneX}
                y={dropZoneY}
                width={dropZoneWidth}
                height={dropZoneHeight}
              />

              {/* End arrow (could not get markers to style properly on hover).
              The browser marker impl seems to be very buggy, especially with getting hover styles to work. */}
              <g transform={`translate(${targetX} ${targetY})`}>
                {drawUpDownArrow(targetY < sourceY)}
              </g>
              {/* Start arrow (could not get markers to style properly on hover) */}
              {bidirectional && (
                <g transform={`translate(${sourceX} ${sourceY})`}>
                  {drawUpDownArrow(targetY > sourceY)}
                </g>
              )}

              <line
                x1={sourceX}
                y1={sourceY}
                x2={targetX}
                y2={targetY}
                className={activeStep ? styles.active : ''}
              />
              {(activeStep || (canDrop && isOver)) && (
                <g
                  transform={`translate(${sourceX - plusSize / 2} ${
                    sourceY - plusSize / 2 + (targetY - sourceY) / 2
                  })`}
                >
                  <PlusCircleIcon
                    height={plusSize}
                    width={plusSize}
                    className={styles.plusButton}
                    onClick={revealContextMenu}
                  />
                </g>
              )}
            </svg>
          )}
          {lane !== null && (
            <>
              <line
                x1={sourceX - (bidirectional ? 0 : markerLength)}
                y1={sourceY}
                x2={sourceX + branchOffset - cornerRadius}
                y2={sourceY}
                className={activeStep ? styles.active : ''}
              />
              <g transform={`translate(${sourceX} ${sourceY})`}>
                {bidirectional && drawArrow()}
              </g>

              {cornerArc(sourceX, sourceY, cornerYOffset, 1)}
              <line
                x1={sourceX + branchOffset}
                y1={sourceY + cornerYOffset}
                x2={targetX + branchOffset}
                y2={targetY - cornerYOffset}
              />
              {cornerArc(targetX, targetY, -1 * cornerYOffset, 0)}
              <line
                x1={targetX + branchOffset - cornerRadius}
                y1={targetY}
                x2={targetX}
                y2={targetY}
                className={activeStep ? styles.active : ''}
              />
              <g transform={`translate(${targetX} ${targetY})`}>
                {drawArrow()}
              </g>
            </>
          )}
        </g>
      </svg>

      {/* Conection Plus menu */}
      {showContextMenu && (
        <ContextMenu
          ref={plusContextMenuRef}
          position={position as { x: number; y: number }}
          show={showContextMenu}
          close={() => setShowContextMenu(false)}
          actions={[
            {
              onMouseDown: () => onInsertNewOrExistingStep(edge),
              Icon: PlusIcon,
              title: 'New step'
            },
            {
              onMouseDown: onDisconnect,
              Icon: TrashIcon,
              title: 'Delete connection'
            }
          ]}
        />
      )}
    </div>
  );
}
export default NavigationEdge; // no memo in order to update properly as step refs move
