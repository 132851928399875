import {
  DropdownField,
  DropdownMultiField,
  InlineTooltip,
  Switch,
  TextField
} from '../../../components/Core';
import Label from '../../../components/Dialog/Label';
import '../../../style/dialog-form.css';
import styles from '../styles.module.scss';
import QuestionGroup from './QuestionGroup';
import produce from 'immer';
import {
  defaultQuestionGroup,
  getDefaultEntity,
  questionGroupTemplates
} from '../utils';

export default function ExtractionProperties({
  formData,
  handleChange,
  accounts,
  fileOptions,
  hiddenFieldOptions,
  usedFields,
  fieldProperties,
  hiddenFieldProperties
}: any) {
  const removeQuestionGroup = (index: number) => {
    const newGroups = produce(formData.question_groups, (draft: any) => {
      if (draft.length === 1) {
        draft[0] = defaultQuestionGroup();
        return;
      } else {
        draft.splice(index, 1);
      }
    });
    handleChange({ question_groups: newGroups });
  };

  const addQuestionGroup = () => {
    handleChange({
      question_groups: [...formData.question_groups, defaultQuestionGroup()]
    });
  };

  const updateQuestionGroup = (index: number, key: string, val: any) => {
    const newGroups = produce(formData.question_groups, (draft: any) => {
      draft[index][key] = val;

      if (key === 'question_type') {
        draft[index].field_ids = [];
        draft[index].entities = [getDefaultEntity()];
        draft[index].criteria = '';
        draft[index].toggle_page_question = false;
      }

      if (key === 'entities') {
        draft[index].field_ids = draft[index][key].map(
          (e: any) => e.field_id ?? null
        );
      }
    });

    handleChange({ question_groups: newGroups });
  };

  const moveQuestionGroupUp = (index: number) => {
    const newGroups = produce(formData.question_groups, (draft: any) => {
      if (index > 0) {
        [draft[index - 1], draft[index]] = [draft[index], draft[index - 1]];
      }
    });

    handleChange({ question_groups: newGroups });
  };

  const moveQuestionGroupDown = (index: number) => {
    const newGroups = produce(formData.question_groups, (draft: any) => {
      if (index < draft.length - 1) {
        [draft[index], draft[index + 1]] = [draft[index + 1], draft[index]];
      }
    });

    handleChange({ question_groups: newGroups });
  };

  return (
    <>
      <Label>Name</Label>
      <TextField
        value={formData.key}
        placeholder='My Extraction'
        onComplete={(newKey: string) => handleChange({ key: newKey })}
        className={styles.modalInput}
        required
      />
      <div className={styles.labelContainer}>
        <Label>Extraction Type</Label>
        <InlineTooltip text='The type of document being processed. Leverage query templates and optimized processing.' />
      </div>
      <DropdownField
        selected={formData.extraction_type}
        options={[
          { value: '', display: 'Custom' },
          { value: 'holdings', display: 'Security Holdings Statement' },
          {
            value: 'bank_transactions',
            display: 'Bank Transactions Statement'
          },
          { value: 'acord', display: 'ACORD Forms', disabled: true },
          {
            value: 'supplemental_insurance_application',
            display: 'Supplemental Insurance Application',
            disabled: true
          },
          {
            value: 'patient_referral',
            display: 'Patient Referral Form',
            disabled: true
          },
          {
            value: 'quarterly_fund_investment_report',
            display: 'Quarterly Fund Investment Report',
            disabled: true
          },
          {
            value: 'financial_investment_statements',
            display: 'Financial Investment Statements',
            disabled: true
          },
          {
            value: 'capital_account_investment_report',
            display: 'Capital Account Investment Report',
            disabled: true
          }
        ]}
        onChange={(event: any) => {
          const newData: Record<string, any> = {};
          const val = event.target.value;
          if (val in questionGroupTemplates) {
            // @ts-ignore
            newData.question_groups = questionGroupTemplates[val]();
          } else {
            const oldVal = formData.extraction_type ?? '';
            if (oldVal in questionGroupTemplates) {
              newData.question_groups = [defaultQuestionGroup()];
            }
          }

          newData.extraction_type = val;
          if (val) newData.file_type = 'image';
          handleChange(newData);
        }}
        className={styles.modalInput}
      />
      {!formData.extraction_type && (
        <>
          <div className={styles.labelContainer}>
            <Label>Uploaded File Type</Label>
            <InlineTooltip text='The type of files being processed by this extraction' />
          </div>
          <DropdownField
            selected={formData.file_type}
            options={[
              { value: 'image', display: 'Documents or images' },
              { value: 'csv', display: 'Spreadsheets or CSVs' }
            ]}
            onChange={(event: any) =>
              handleChange({ file_type: event.target.value })
            }
            className={styles.modalInput}
            required
          />
        </>
      )}
      <div className={styles.labelContainer}>
        <Label>Uploaded File Sources</Label>
        <InlineTooltip text='The extraction will run on files stored in these fields' />
      </div>
      <DropdownMultiField
        selected={formData.file_sources}
        options={[...fileOptions, ...hiddenFieldOptions].filter(
          (option) =>
            formData.file_sources.includes(option.value) ||
            !usedFields.includes(option.value)
        )}
        onChange={(items: { value: string }[]) =>
          handleChange({ file_sources: items.map((item) => item.value) })
        }
        className={styles.modalInput}
        required
      />
      <div className={styles.labelContainer}>
        <Label>Merge Uploaded Files</Label>
        <InlineTooltip text='The extraction will run once on the combined files rather than separately on each' />
      </div>
      <Switch
        id='extraction-merge-uploaded'
        checked={formData.merge_files || false}
        onCheckedChange={(checked) => {
          handleChange({ merge_files: checked });
        }}
        className={styles.switch}
      />
      <div className={styles.labelContainer}>
        <Label>Reviewers</Label>
        <InlineTooltip text='Reviewers will be notified via email to review & approve new extraction runs.' />
      </div>
      <DropdownMultiField
        selected={formData.reviewers}
        options={accounts.map((account: any) => ({
          label: account.email,
          value: account.id
        }))}
        onChange={(items: { value: string }[]) =>
          handleChange({ reviewers: items.map((item) => item.value) })
        }
        className={styles.modalInput}
      />
      {formData.question_groups.map((group: any, index: number) => {
        return (
          <QuestionGroup
            key={group.id}
            index={index}
            group={group}
            usedFields={usedFields}
            formData={formData}
            fieldProperties={fieldProperties}
            hiddenFieldProperties={hiddenFieldProperties}
            onRemove={() => removeQuestionGroup(index)}
            onMoveUp={() => moveQuestionGroupUp(index)}
            onMoveDown={() => moveQuestionGroupDown(index)}
            onUpdate={(key: string, val: any) =>
              updateQuestionGroup(index, key, val)
            }
          />
        );
      })}
      <div onClick={() => addQuestionGroup()} className={styles.addGroupButton}>
        Add Query
      </div>
    </>
  );
}
