import type { ComponentProps } from 'react';
import LockoutButton from './LockoutButton';

function Neutral({
  variant = 'outline',
  children,
  ...props
}: Omit<ComponentProps<typeof LockoutButton>, 'type'>) {
  // In a form, the neutral button should not be triggered on `Enter` being pressed
  return (
    <LockoutButton {...props} type='button' variant={variant}>
      {children}
    </LockoutButton>
  );
}

export default Neutral;
