import '../../style/dialog-form.css';

import { Form } from 'react-bootstrap';
import { Fragment, useLayoutEffect, useState } from 'react';

import { ALL_INTEGRATIONS_MAP, INTEGRATIONS } from './types';
import {
  CollapsibleSection,
  DropdownField,
  PropertyLabel,
  TextField
} from '../Core';
import { produce } from 'immer';
import IntegrationsSidebar from './IntegrationsSidebar';
import useIntegrations from './useIntegrations';
import styles from './styles.module.scss';
import { FieldSelectorWithModal } from '../Modals';
import classNames from 'classnames';
import { PlusIcon, TrashIcon } from '../Icons';
import { useParams } from 'react-router-dom';

export default function ArgyleSettingsSidebar() {
  const { formId } = useParams<{ formId: string }>();

  const integration = useIntegrations({
    type: INTEGRATIONS.ARGYLE,
    panelId: formId,
    includeInactive: true
  });

  const [isPartial, setIsPartial] = useState(false);
  const [err, setErr] = useState('');

  const secretMeta = integration?.data.secret_metadata ?? {};
  const [apiKeySecret, setApiKeySecret] = useState(
    secretMeta.api_key_secret ?? ''
  );
  const [apiKeyId, setApiKeyId] = useState(secretMeta.api_key_id ?? '');
  const [argyleFieldMap, setArgyleFieldMap] =
    useState<ArgyleFieldMapType>(DEFAULT_PRODUCT_MAP);

  const updateArgyleMap = (newMap: ArgyleFieldMapType) => {
    setArgyleFieldMap(
      produce(newMap, (draft) => {
        Object.entries(draft).forEach(([product, data]) => {
          if (data.length === 0) data.push(defaultEntry(product));
        });
      })
    );
  };

  useLayoutEffect(() => {
    const metamap = secretMeta.argyle_field_map ?? [];
    const argyleProductMap: ArgyleFieldMapType = {};
    ARGYLE_PRODUCT_FIELD_MAP.forEach(
      ({ name }) =>
        (argyleProductMap[name] = metamap.filter(
          (data: any) => data.argyle_product === name
        ))
    );
    updateArgyleMap(argyleProductMap);
  }, []);

  const meta = integration?.data.metadata ?? {};
  const [environment, setEnvironment] = useState(
    meta.environment ?? 'production'
  );
  const [linkKey, setLinkKey] = useState(meta.link_key ?? '');

  function onSubmitCustom(newIsActive: boolean) {
    if (newIsActive) {
      let partial = !apiKeyId || !apiKeySecret;

      if (!partial) {
        // all Argyle fields must be mapped to a Feathery field
        partial = Object.values(argyleFieldMap).some((productMap) =>
          productMap.some((data) => data.argyle_field && !data.feathery_field)
        );
        if (partial)
          setErr(
            'Each Argyle field of interest needs to be mapped to a Feathery field'
          );
      }

      setIsPartial(partial);
      if (partial) return;
    }

    setErr('');
    return {
      isUpdate: integration?.data,
      metadata: {
        environment,
        link_key: linkKey
      },
      secretMetadata: {
        api_key_secret: apiKeySecret,
        api_key_id: apiKeyId,
        argyle_field_map: Object.values(argyleFieldMap).flatMap((data) => data)
      }
    };
  }

  return (
    <IntegrationsSidebar
      integrationInfo={ALL_INTEGRATIONS_MAP[INTEGRATIONS.ARGYLE]}
      isPartial={isPartial}
      onSubmitCustom={onSubmitCustom}
      customError={err}
    >
      <Form className='integration-modal-form'>
        <Form.Group controlId='argyle-credentials'>
          <PropertyLabel label='Link Key' />
          <TextField
            value={linkKey}
            onChange={setLinkKey}
            error={isPartial && !linkKey}
          />
        </Form.Group>
        <Form.Group controlId='argyle-credentials'>
          <PropertyLabel label='API Key ID' />
          <TextField
            value={apiKeyId}
            onChange={setApiKeyId}
            error={isPartial && !apiKeyId}
          />
        </Form.Group>
        <Form.Group controlId='argyle-credentials'>
          <PropertyLabel label='API Key Secret' />
          <TextField
            type='password'
            value={apiKeySecret}
            onChange={setApiKeySecret}
            error={isPartial && !apiKeySecret}
          />
        </Form.Group>
        <Form.Group controlId='argyle-credentials'>
          <PropertyLabel label='Environment' />
          <DropdownField
            selected={environment}
            onChange={(event: any) => setEnvironment(event.target.value)}
            options={[
              { value: 'sandbox', display: 'Sandbox' },
              { value: 'production', display: 'Production' }
            ]}
          />
        </Form.Group>

        {ARGYLE_PRODUCT_FIELD_MAP.map(({ name, fields }) => {
          const usedFields = argyleFieldMap[name].map(
            ({ argyle_field: field }) => field
          );
          return (
            <CollapsibleSection key={name} title={name} expanded={false}>
              <div className={styles.twoColumnContainer}>
                <div className={styles.fieldHeaderText}>Argyle Field</div>
                <div className={styles.fieldHeaderText}>Feathery Field</div>
                {argyleFieldMap[name].map((data: any, index: number) => (
                  <Fragment key={`${data.argyle_field}-${index}`}>
                    <DropdownField
                      placeholder='Select'
                      selected={data.argyle_field}
                      onChange={(event: any) =>
                        updateArgyleMap(
                          produce(argyleFieldMap, (draft) => {
                            draft[name][index] = {
                              ...draft[name][index],
                              argyle_field: event.target.value
                            };
                          })
                        )
                      }
                      options={[
                        {
                          display: data.argyle_field,
                          value: data.argyle_field
                        },
                        ...fields
                          .filter((field) => !usedFields.includes(field))
                          .map((field) => ({
                            display: field,
                            value: field
                          }))
                      ]}
                    />
                    <div className={styles.fieldSelectorContainer}>
                      <FieldSelectorWithModal
                        selectId={data.feathery_field}
                        selectType={data.feathery_type}
                        placeholder='Select'
                        onSelect={(field) => {
                          updateArgyleMap(
                            produce(argyleFieldMap, (draft) => {
                              draft[name][index] = {
                                ...draft[name][index],
                                feathery_field: field.selectId,
                                feathery_type: field.selectType
                              };
                            })
                          );
                        }}
                        error={isPartial && !data.feathery_field}
                        className={classNames(
                          styles.marginBottom,
                          styles.fieldSelector
                        )}
                      />
                      <TrashIcon
                        height={16}
                        width={16}
                        className={classNames(
                          'tr-icon',
                          styles.customPropertyDelete
                        )}
                        onClick={() => {
                          updateArgyleMap(
                            produce(argyleFieldMap, (draft) => {
                              draft[name].splice(index, 1);
                            })
                          );
                        }}
                      />
                    </div>
                  </Fragment>
                ))}
                <PlusIcon
                  className={styles.customPropertyAdd}
                  onClick={() => {
                    updateArgyleMap(
                      produce(argyleFieldMap, (draft) => {
                        draft[name].push(defaultEntry(name));
                      })
                    );
                  }}
                />
              </div>
            </CollapsibleSection>
          );
        })}
      </Form>
    </IntegrationsSidebar>
  );
}

const defaultEntry = (product: string) => ({
  argyle_product: product,
  argyle_field: '',
  feathery_field: '',
  feathery_type: ''
});

type ArgyleFieldMapType = Record<
  string,
  {
    argyle_field?: string;
    argyle_product?: string;
    feathery_field?: string;
    feathery_type?: string;
  }[]
>;

const ARGYLE_PRODUCT_FIELD_MAP = [
  {
    name: 'Profiles',
    fields: [
      'first_name',
      'last_name',
      'full_name',
      'email',
      'phone_number',
      'birth_date',
      'picture_url',
      'address.line1',
      'address.line2',
      'address.city',
      'address.state',
      'address.postal_code',
      'address.country',
      'ssn',
      'marital_status',
      'gender',
      'metadata'
    ]
  },
  {
    name: 'Employments',
    fields: [
      'employer',
      'status',
      'type',
      'job_title',
      'hire_datetime',
      'termination_datetime',
      'termination_reason',
      'base_pay.amount',
      'base_pay.period',
      'base_pay.currency',
      'pay_cycle',
      'platform_ids.employee_id',
      'platform_ids.position_id',
      'platform_ids.platform_user_id',
      'metadata'
    ]
  },
  {
    name: 'Payouts',
    fields: [
      'document_id',
      'status',
      'type',
      'payout_date',
      'payout_period.start_date',
      'payout_period.end_date',
      'currency',
      'gross_pay',
      'reimbursements',
      'deductions',
      'taxes',
      'net_pay',
      'fees',
      'bonuses',
      'commission',
      'overtime',
      'hours',
      'employer_address.line1',
      'employer_address.line2',
      'employer_address.city',
      'employer_address.state',
      'employer_address.postal_code',
      'employer_address.country',
      'metadata'
    ]
  },
  {
    name: 'Documents',
    fields: [
      'document_number',
      'document_type',
      'document_type_description',
      'expiration_date',
      'available_date',
      'file_url',
      'metadata'
    ]
  },
  {
    name: 'Activities',
    fields: [
      'status',
      'type',
      'circumstances',
      'num_tasks',
      'start_date',
      'end_date',
      'all_timestamps.shift_start',
      'all_timestamps.shift_end',
      'all_timestamps.break_start',
      'all_timestamps.break_end',
      'all_timestamps.task_start',
      'all_timestamps.task_end',
      'all_timestamps.pickup_at',
      'all_timestamps.dropoff_at',
      'all_timestamps.request_at',
      'all_timestamps.cancelled_at',
      'duration',
      'timezone',
      'earning_type',
      'income.currency',
      'income.total_charge',
      'income.fees',
      'income.total',
      'income.pay',
      'income.tips',
      'income.bonus',
      'income_rates.hour',
      'income_rates.mile',
      'start_location.lat',
      'start_location.lng',
      'start_location.formatted_address',
      'end_location.lat',
      'end_location.lng',
      'end_location.formatted_address',
      'route',
      'distance',
      'distance_unit',
      'metadata'
    ]
  },
  {
    name: 'Vehicles',
    fields: [
      'vin',
      'make',
      'model',
      'year',
      'identification',
      'type',
      'metadata'
    ]
  },
  {
    name: 'Reputations',
    fields: [
      'rating',
      'acceptance_rate',
      'ontime_rate',
      'achievements',
      'metadata'
    ]
  },
  {
    name: 'Pay Allocations',
    fields: [
      'bank_account.routing_number',
      'bank_account.account_number',
      'bank_account.account_type',
      'status',
      'allocation_type',
      'allocation_value',
      'metadata'
    ]
  }
];

const DEFAULT_PRODUCT_MAP = ARGYLE_PRODUCT_FIELD_MAP.reduce(
  (productMap, { name }) => ({
    ...productMap,
    [name]: []
  }),
  {}
);
