import { useMemo } from 'react';
import {
  elementEvents,
  elementLabelMap,
  getElementLogicId,
  stepEvents
} from '../../../../pages/LogicRuleDetail/components/RuleFieldsPanel';
import { useAppSelector } from '../../../../hooks';

const triggerEvents = {
  submit: 'This Step is Submitted',
  load: 'This Step is Loaded'
};

type ElementIdLabelMapType = Record<string, { type: string; val: string }>;

function getTriggerLabel(rule: any, labelMap: ElementIdLabelMapType) {
  const event = rule.trigger_event;
  const labelEntries = rule.elements
    .filter((elId: string) => elId in labelMap)
    .map((elId: string) => labelMap[elId]);
  let elementType = '';
  labelEntries.forEach((entry: any, index: number) => {
    if (index === 0) elementType = entry.type;
    else if (elementType !== entry.type) elementType = 'Element';
  });
  const joined = labelEntries.map((entry: any) => `'${entry.val}'`).join(', ');

  if (event in triggerEvents)
    return triggerEvents[event as keyof typeof triggerEvents];
  else if (event === 'change') {
    if (labelEntries.length > 1) return `Fields ${joined} are Changed`;
    else return `Field ${joined} is Changed`;
  } else if (event === 'view') {
    if (labelEntries.length > 1) return `${elementType}s ${joined} are Viewed`;
    else return `${elementType} ${joined} is Viewed`;
  } else if (event === 'action') {
    if (labelEntries.length > 1) return `${elementType}s ${joined} are Clicked`;
    else return `${elementType} ${joined} is Clicked`;
  }
}

export default function useActiveLogic(
  stepIdOverride?: string,
  filterElementId?: string
) {
  const workingSteps = useAppSelector(
    (state) => state.formBuilder.workingSteps
  );
  const activeStepId = useAppSelector(
    (state) => state.formBuilder.activeStepId
  );
  const curStep = workingSteps[stepIdOverride || activeStepId];
  const workingLogicRules = useAppSelector(
    (s) => s.formBuilder.workingLogicRules
  );

  return useMemo(() => {
    const elementIdLabelMap: ElementIdLabelMapType = {};
    Object.entries(elementLabelMap).forEach(([attr, label]) => {
      curStep[attr].forEach((el: any) => {
        let val = el.id ?? '';
        if (attr === 'servar_fields') val = el.servar.key;
        else if (['buttons', 'texts'].includes(attr))
          val = el.properties.text ?? '';

        if (val.length > 14) val = val.slice(0, 14) + '...';
        elementIdLabelMap[getElementLogicId(el, attr)] = { type: label, val };
      });
    });

    return Object.values(workingLogicRules)
      .filter((rule: any) => {
        if (!rule.enabled || !rule.valid) return false;

        if (filterElementId && !rule.elements.includes(filterElementId))
          return false;

        if (
          stepEvents.includes(rule.trigger_event) &&
          rule.steps.includes(curStep.id)
        )
          return true;

        return (
          elementEvents.includes(rule.trigger_event) &&
          rule.elements.some((elId: string) => elId in elementIdLabelMap)
        );
      })
      .sort((a: any, b: any) => (a.index > b.index ? 1 : -1))
      .map((rule: any) => ({
        ...rule,
        display_label: getTriggerLabel(rule, elementIdLabelMap)
      }));
  }, [workingLogicRules, curStep]);
}
