import {
  URL,
  httpDelete,
  httpGet,
  httpPatch,
  httpPost,
  CONTENT_TYPE
} from './utils';

export default {
  getImages: (token: string) => {
    return httpGet({ token, url: URL.IMAGE_UPLOAD });
  },
  createImages: (token: string, data: any) => {
    return httpPost({
      token,
      url: URL.IMAGE_UPLOAD,
      contentType: CONTENT_TYPE.MULTIPART_FORM,
      body: data
    });
  },
  editImage: (token: string, data: any) => {
    const { imageId, ...newImageData } = data;
    return httpPatch({
      token,
      baseUrl: URL.IMAGE_UPLOAD,
      path: `${imageId}/`,
      body: newImageData
    });
  },
  deleteImage: (token: string, { imageId }: any) => {
    return httpDelete({
      token,
      baseUrl: URL.IMAGE_UPLOAD,
      path: `${imageId}/`
    });
  }
};
