function ColumnSplitIcon({
  color = '#414859',
  width = 24,
  height = 24,
  ...props
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      stroke={color}
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M19.667 16.8013L19.667 17.8133C19.667 18.9064 18.7809 19.7925 17.6878 19.7925L5.81282 19.7925C4.71976 19.7925 3.83366 18.9064 3.83366 17.8133L3.83366 16.8176M19.667 6.92003L19.667 5.93729C19.667 4.84423 18.7809 3.95812 17.6878 3.95812L5.81282 3.95812C4.71976 3.95812 3.83366 4.84423 3.83366 5.93729L3.83366 6.91748'
        strokeWidth='1.48438'
        strokeLinecap='round'
      />
      <line
        x1='11.75'
        y1='19.0503'
        x2='11.75'
        y2='4.70031'
        strokeWidth='1.48438'
        strokeLinecap='round'
      />
      <path
        d='M6.16406 14.2098L3.83337 11.8749L6.16406 9.54004'
        strokeWidth='1.48438'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.78125 11.875L3.83333 11.875'
        strokeWidth='1.48438'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.335 9.54022L19.6657 11.8751L17.335 14.21'
        strokeWidth='1.48438'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.665 11.875L14.7171 11.875'
        strokeWidth='1.48438'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export default ColumnSplitIcon;
