import { memo } from 'react';
import {
  useDimensionInput,
  DimensionInputParams
} from './hooks/useDimensionInput';
import { Unit } from '../../../types/units';
import LinkButton from './components/LinkButton';
import NumberInput from '../NumberInput';
import PropertyLabel from '../PropertyLabel';
import styles from './styles.module.scss';

export type DimensionInputProps = DimensionInputParams & {
  unit?: Unit;
  units?: Unit[];
};

const DimensionInput = (props: DimensionInputProps) => {
  const { state, handleChange, handleLink } = useDimensionInput(props);
  const { unit = 'px', units = ['px', '%'] } = props;

  return (
    <div className={styles.dimensionInputContainer}>
      <div className={styles.dimensionInputLabelContainer}>
        <PropertyLabel label='W' className={styles.dimensionInputLabel} />
      </div>
      <div className={styles.dimensionInput}>
        <NumberInput
          required
          value={state.x}
          unit={unit}
          units={units}
          onChange={(value: any) => handleChange('x', value)}
          onComplete={({ value }: any) => handleChange('x', value, true)}
        />
      </div>
      <div className={styles.dimensionInputLabelContainer}>
        <PropertyLabel label='H' className={styles.dimensionInputLabel} />
      </div>
      <div className={styles.dimensionInput}>
        <NumberInput
          required
          value={state.y}
          unit={unit}
          units={units}
          onChange={(value: any) => handleChange('y', value)}
          onComplete={({ value }: any) => handleChange('y', value, true)}
        />
      </div>
      <div className={styles.dimensionInputUtility}>
        <LinkButton linked={state.linked} handleLink={handleLink} />
      </div>
    </div>
  );
};

export default memo(DimensionInput);
