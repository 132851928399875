import LogicRulesSection, { DecisionLogic } from './LogicRulesSection';
import { elementOperation } from '../../../../utils/themes';

function CustomValidationsSection({
  element,
  customValidations = [],
  handleUpdates
}: {
  element: any;
  customValidations: DecisionLogic[];
  handleUpdates: any;
}) {
  return (
    <LogicRulesSection
      element={element}
      logicItems={customValidations}
      behaviorToPerform='invalidate'
      logicRulesType='validation'
      handleUpdates={(validations: DecisionLogic[]) =>
        handleUpdates([
          elementOperation({
            propUpdate: {
              validations
            }
          })
        ])
      }
    />
  );
}

export default CustomValidationsSection;
