import { useCallback, useEffect, useRef } from 'react';
import styles from './styles.module.scss';
import useCleanUp from '../../../utils/useCleanUp';
import InputSection from './input-section';
import { useDrag } from './useDrag';
import { useSyncedRefState } from '../../../hooks/useSyncedRefState';
import useForcedRender from '../../../hooks/useForcedRender';

function MarginPaddingInput({
  value,
  onComplete: customOnComplete = () => {}
}: any) {
  // use ref so that cleanup function always has most recent value
  const marginPadding = useRef({ ...value });
  const triggerRender = useForcedRender();
  const getOnComplete = useSyncedRefState(customOnComplete);

  const setMarginPadding = useCallback((value: Record<string, number>) => {
    marginPadding.current = value;
    triggerRender();
  }, []);

  const { clearCleanUp, setCleanUp } = useCleanUp({
    // Assume the padding component is placed in the detail panel
    triggerCleanUp: true
  });

  useEffect(
    () => {
      setMarginPadding({ ...value });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      value.paddingTop,
      value.paddingBottom,
      value.paddingLeft,
      value.paddingRight,
      value.marginTop,
      value.marginBottom,
      value.marginLeft,
      value.marginRight
    ]
  );

  function formatMarginPadding(oldPadding: any) {
    const newPadding = {};
    Object.entries(oldPadding).forEach(
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      ([position, val]) => (newPadding[position] = val || 0)
    );
    return newPadding;
  }

  function onComplete(newPadding: any) {
    newPadding = formatMarginPadding(newPadding);
    setMarginPadding(newPadding);

    getOnComplete()?.(newPadding);
    clearCleanUp();
  }

  function onBlur() {
    onComplete(marginPadding.current);
  }

  function onChange(position: any) {
    return (event: any) => {
      const val = event.target.value;
      const parsedValue = Number.parseInt(val);
      if (isNaN(parsedValue) && val !== '') event.preventDefault?.();
      let value = isNaN(parsedValue) ? 0 : parsedValue;
      if (value && value < 0) value = 0;

      const newPadding = {
        ...marginPadding.current,
        [position]: value
      };
      setMarginPadding(newPadding);
      setCleanUp(() => onComplete(formatMarginPadding(newPadding)));
    };
  }

  const { cursor, onMouseDown, isDragging } = useDrag(
    (position, value) => onChange(position)({ target: { value } }),
    () => onComplete(marginPadding.current),
    0
  );

  return (
    <div className={styles.outerInput}>
      {/* Keep resize cursor while dragging */}
      {isDragging && cursor && (
        <style>{`* {cursor: ${cursor} !important;}`}</style>
      )}

      <svg
        width='100%'
        height='100%'
        viewBox='0 0 230 150'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        style={{ gridRow: '1 / 4', gridColumn: '1 / 4' }}
      >
        <path
          onMouseDown={onMouseDown(
            'marginLeft',
            marginPadding.current.marginLeft
          )}
          name='marginLeft'
          d='M46 120V30L0 0V150L46 120Z'
          fill='#EFF3F8'
        />
        <path
          onMouseDown={onMouseDown(
            'marginRight',
            marginPadding.current.marginRight
          )}
          name='marginRight'
          d='M184 30V120L230 150L230 0L184 30Z'
          fill='#EFF3F8'
        />
        <path
          onMouseDown={onMouseDown(
            'marginTop',
            marginPadding.current.marginTop
          )}
          name='marginTop'
          d='M46 30L0 0H230L184 30H46Z'
          fill='white'
        />
        <path
          onMouseDown={onMouseDown(
            'marginBottom',
            marginPadding.current.marginBottom
          )}
          name='marginBottom'
          d='M184 120L230 150L0 150L46 120L184 120Z'
          fill='white'
        />
      </svg>

      <InputSection
        gridRow='1 / 2'
        gridColumn='2 / 3'
        html={String(marginPadding.current.marginTop)}
        onChange={onChange('marginTop')}
        onBlur={onBlur}
      />
      <InputSection
        gridRow='2 / 3'
        gridColumn='3 / 4'
        html={String(marginPadding.current.marginRight)}
        onChange={onChange('marginRight')}
        onBlur={onBlur}
      />
      <InputSection
        gridRow='3 / 4'
        gridColumn='2 / 3'
        html={String(marginPadding.current.marginBottom)}
        onChange={onChange('marginBottom')}
        onBlur={onBlur}
      />
      <InputSection
        gridRow='2 / 3'
        gridColumn='1 / 2'
        html={String(marginPadding.current.marginLeft)}
        onChange={onChange('marginLeft')}
        onBlur={onBlur}
      />
      <div className={styles.innerInput}>
        <svg
          width='100%'
          height='100%'
          viewBox='0 0 138 90'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          style={{ gridRow: '1 / 4', gridColumn: '1 / 4' }}
        >
          <path
            onMouseDown={onMouseDown(
              'paddingTop',
              marginPadding.current.paddingTop
            )}
            name='paddingTop'
            d='M0 0L46 30H92L138 0H0Z'
            fill='#eff3f8'
          />
          <path
            onMouseDown={onMouseDown(
              'paddingBottom',
              marginPadding.current.paddingBottom
            )}
            name='paddingBottom'
            d='M138 90L92 60L46 60L0 90L138 90Z'
            fill='#eff3f8'
          />
          <path
            onMouseDown={onMouseDown(
              'paddingLeft',
              marginPadding.current.paddingLeft
            )}
            name='paddingLeft'
            d='M46 60V30L0 0V90L46 60Z'
            fill='#fff'
          />
          <path
            onMouseDown={onMouseDown(
              'paddingRight',
              marginPadding.current.paddingRight
            )}
            name='paddingRight'
            d='M92 30V60L138 90V0L92 30Z'
            fill='#fff'
          />
        </svg>
        <div className={styles.centerpiece} />

        <InputSection
          gridRow='1 / 2'
          gridColumn='2 / 3'
          html={String(marginPadding.current.paddingTop)}
          onChange={onChange('paddingTop')}
          onBlur={onBlur}
        />
        <InputSection
          gridRow='2 / 3'
          gridColumn='3 / 4'
          html={String(marginPadding.current.paddingRight)}
          onChange={onChange('paddingRight')}
          onBlur={onBlur}
        />
        <InputSection
          gridRow='3 / 4'
          gridColumn='2 / 3'
          html={String(marginPadding.current.paddingBottom)}
          onChange={onChange('paddingBottom')}
          onBlur={onBlur}
        />
        <InputSection
          gridRow='2 / 3'
          gridColumn='1 / 2'
          html={String(marginPadding.current.paddingLeft)}
          onChange={onChange('paddingLeft')}
          onBlur={onBlur}
        />
      </div>
    </div>
  );
}

export default MarginPaddingInput;
