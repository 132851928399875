import { useEffect, useRef } from 'react';

/**
 * This hook allows to use a Ref similar to how `useState`
 * operates to store data. Essentially you initialize it
 * with an initialValue and it returns the value of the ref
 * + a function to set the state.
 *
 * An example of where this is used would be in event listeners
 * (window.addEventListener) where normal state can not be accessed.
 */
export const useRefState = (initialValue: any = null) => {
  const state = useRef(initialValue);

  // Cleanup the state
  useEffect(() => {
    return () => {
      state.current = null;
    };
  }, []);

  const getState = () => {
    return state.current;
  };

  const setState = (fn: any) => {
    state.current = JSON.parse(JSON.stringify(fn({ ...state.current })));
    return { ...state.current };
  };

  return [state.current, setState, getState];
};
