function Fiserv({ width = 110, height = 120 }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 239 120'
    >
      <defs id='defs3103'>
        <clipPath clipPathUnits='userSpaceOnUse' id='clipPath3035'>
          <path d='M 0,0 612,0 612,792 0,792 0,0 z' id='path3037' />
        </clipPath>
        <clipPath clipPathUnits='userSpaceOnUse' id='clipPath3021'>
          <path d='M 0,0 612,0 612,792 0,792 0,0 z' id='path3023' />
        </clipPath>
        <clipPath clipPathUnits='userSpaceOnUse' id='clipPath3003'>
          <path d='M 0,0 612,0 612,792 0,792 0,0 z' id='path3005' />
        </clipPath>
      </defs>
      <g transform='translate(-327.71091,-579.87181)'>
        <g
          transform='matrix(5.6250398,0,0,-5.6250263,-7.1325214,1891.129)'
          fill='#ff6600'
          fillOpacity={1}
        >
          <g clipPath='url(#clipPath3003)' fill='#ff6600' fillOpacity={1}>
            <g
              id='g3007'
              transform='translate(100.4711,226.9748)'
              fill='#ff6600'
              fillOpacity={1}
            >
              <path
                d='m 0,0 c 0,-0.153 -0.118,-0.27 -0.27,-0.27 -0.154,0 -0.271,0.117 -0.271,0.27 0,0.154 0.117,0.27 0.271,0.27 C -0.118,0.27 0,0.154 0,0 m 0.052,0 c 0,0.184 -0.141,0.322 -0.322,0.322 -0.184,0 -0.323,-0.138 -0.323,-0.322 0,-0.183 0.139,-0.322 0.323,-0.322 0.181,0 0.322,0.139 0.322,0.322 m -0.176,0.076 c 0,0.073 -0.052,0.106 -0.115,0.106 l -0.154,0 0,-0.352 0.06,0 0,0.136 0.067,0 0.069,-0.136 0.071,0 -0.071,0.142 c 0.044,0.014 0.073,0.048 0.073,0.104 m -0.06,-0.002 c 0,-0.034 -0.024,-0.05 -0.057,-0.05 l -0.092,0 0,0.101 0.092,0 c 0.033,0 0.057,-0.018 0.057,-0.051'
                fill='#ff6600'
                fillOpacity={1}
                fillRule='nonzero'
                stroke='none'
                id='path3009'
              />
            </g>
          </g>
        </g>
        <path
          d='m 566.88591,683.66655 -15.26637,0 0,15.27195 15.26637,0 0,-15.27195 z'
          fill='#ff6600'
          fillOpacity={1}
          fillRule='nonzero'
          stroke='none'
          id='path3011'
        />
        <g
          id='g3013'
          transform='matrix(5.6250398,0,0,-5.6250263,520.64075,615.66953)'
          fill='#ff6600'
          fillOpacity={1}
        >
          <path
            d='M 0,0 1.617,-12.134 3.231,0 5.75,0 3.394,-14.803 l -3.582,0 L -2.519,0 0,0 z'
            fill='#ff6600'
            fillOpacity={1}
            fillRule='nonzero'
            stroke='none'
            id='path3015'
          />
        </g>
        <g
          transform='matrix(5.6250398,0,0,-5.6250263,-7.1325214,1891.129)'
          id='g3017'
          fill='#ff6600'
          fillOpacity={1}
        >
          <g
            id='g3019'
            clipPath='url(#clipPath3021)'
            fill='#ff6600'
            fillOpacity={1}
          >
            <g
              id='g3025'
              transform='translate(60.9243,226.7473)'
              fill='#ff6600'
              fillOpacity={1}
            >
              <path
                d='m 0,0 -1.397,0 0,-1.967 1.397,0 0,-12.836 2.455,0 0,12.836 1.725,0 0,1.967 -1.725,0 0,3.009 c 0,0.439 0.091,0.775 0.303,1.005 0.213,0.228 0.538,0.336 0.979,0.336 l 0.442,0 0,2.014 -0.888,0 C 2.2,6.364 1.149,6.133 0.649,5.658 0.149,5.183 0,4.446 0,3.396 L 0,0 z'
                fill='#ff6600'
                fillOpacity={1}
                fillRule='nonzero'
                stroke='none'
                id='path3027'
              />
            </g>
          </g>
        </g>
        <path
          d='m 363.39447,698.93288 13.84321,0 0,-83.26727 -13.84321,0 0,83.26727 z'
          fill='#ff6600'
          fillOpacity={1}
          fillRule='nonzero'
          stroke='none'
          id='path3029'
        />
        <g
          transform='matrix(5.6250398,0,0,-5.6250263,-7.1325214,1891.129)'
          id='g3031'
          fill='#ff6600'
          fillOpacity={1}
        >
          <g
            id='g3033'
            clipPath='url(#clipPath3035)'
            fill='#ff6600'
            fillOpacity={1}
          >
            <g
              id='g3039'
              transform='translate(79.6946,218.5081)'
              fill='#ff6600'
              fillOpacity={1}
            >
              <path
                d='m 0,0 5.13,0 0,4.562 C 5.124,7.291 3.812,8.41 1.326,8.41 -1.259,8.41 -2.471,7.294 -2.471,4.544 l 0,-7.497 c 0.008,-2.501 1.522,-3.795 3.796,-3.795 2.273,0 3.799,1.065 3.805,3.795 l 0,1.186 -2.377,0 0.003,-1.405 C 2.756,-4.174 2.381,-4.867 1.375,-4.867 0.367,-4.867 0,-4.168 0,-3.171 L 0,0 z m 0,5.053 c 0,0.835 0.217,1.7 1.368,1.7 1.15,0 1.383,-0.852 1.383,-1.699 l 0,-3.059 -2.751,0 0,3.058 z'
                fill='#ff6600'
                fillOpacity={1}
                fillRule='nonzero'
                stroke='none'
                id='path3041'
              />
            </g>
            <g
              id='g3043'
              transform='translate(73.8653,220.3602)'
              fill='#ff6600'
              fillOpacity={1}
            >
              <path
                d='m 0,0 c -1.867,1.621 -2.012,1.856 -2.012,3.064 0,1.029 0.089,1.655 1.014,1.655 0.924,0 1.044,-0.618 1.044,-1.667 l 0,-1.367 2.372,0 0,2.23 C 2.41,5.666 1.063,6.556 -1.053,6.556 c -2.081,0 -3.438,-0.693 -3.45,-3.492 -0.002,-0.476 0.001,-0.476 0,-0.292 0.004,-0.88 0.008,-2.275 2.183,-4.141 l 0.624,-0.534 c 1.603,-1.352 1.778,-1.515 1.771,-3.123 -0.001,-0.991 -0.062,-1.742 -1.102,-1.742 -1.04,0 -1.105,0.757 -1.105,1.224 l 0,1.894 -2.49,0 0,-1.954 c 0,-2.405 1.565,-3 3.592,-3 2.029,0 3.596,0.601 3.596,3.695 l 0,0.257 C 2.561,-2.49 2.17,-1.91 0,0'
                fill='#ff6600'
                fillOpacity={1}
                fillRule='nonzero'
                stroke='none'
                id='path3045'
              />
            </g>
            <g
              id='g3047'
              transform='translate(88.1189,225.2236)'
              fill='#ff6600'
              fillOpacity={1}
            >
              <path
                d='m 0,0 0,1.524 -2.243,0 0,-14.802 2.42,0 0,10.316 c -0.001,0.797 0.259,1.322 0.711,1.632 0.428,0.295 0.955,0.395 1.626,0.405 l 0,2.619 C 1.23,1.667 0.676,1.414 0.025,0.054 L 0,0 z'
                fill='#ff6600'
                fillOpacity={1}
                fillRule='nonzero'
                stroke='none'
                id='path3049'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Fiserv;
