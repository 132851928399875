/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useCallback } from 'react';

const Image = ({ src, alt, onLoad = () => {}, ...props }: any) => {
  const handleImageLoad = useCallback(
    (node: any) => {
      onLoad({
        width: node?.naturalWidth,
        height: node?.naturalHeight
      });
    },
    [src]
  );

  return <img ref={handleImageLoad} src={src} alt={alt} {...props} />;
};

export default memo(Image);
