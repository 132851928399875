import styles from './styles.module.scss';
import StatValue from './StatValue';

export const keyTitleMap: Record<string, string> = {
  new_submissions: 'New Submissions',
  new_viewers: 'New Viewers',
  average_time: 'Average Time',
  average_completion_rate: 'Average Completion Rate'
};

export default function StatCard({
  statKey,
  Icon,
  color
}: {
  statKey: string;
  Icon: any;
  color: string;
}) {
  return (
    <div className={styles.card}>
      <div
        style={{ backgroundColor: color + '26' }}
        className={styles.cardIcon}
      >
        <Icon width={20} height={20} color={color} />
      </div>
      <StatValue statKey={statKey} />
      <span className={styles.cardLabel}>{keyTitleMap[statKey]}</span>
    </div>
  );
}
