function SAPConcur({ width = 152, height = 152 }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 652 652'
      enableBackground='new 0 0 652 652'
      xmlSpace='preserve'
    >
      <g>
        <path
          d='M21.4,336.9l10-6c3.2,6.5,9.2,10.2,16.2,10.2c6,0,10.5-2.2,10.5-6.7s-4.2-6.5-12-8.7c-11-3-22.2-5.7-22.2-18.2
		c0-11.2,8.7-17.7,21.4-17.7c12.2,0,19.4,6,23.7,12.2l-9.2,7c-3.2-5-9-8.5-14.7-8.5c-5.5,0-8.7,2.5-8.7,6c0,4.7,4.5,6.5,12.2,8.7
		c10.5,2.7,21.7,6.2,21.7,18.4c0,9.5-7.2,18.2-23.9,18.2C33.8,351.3,25.8,345.4,21.4,336.9z M72.9,350.3l21.2-59.8h12.5l21.2,59.8
		h-13.2l-4.5-13.2H89.9l-4.5,13.2H72.9z M92.9,327.4h14l-6.7-20.2h-0.2L92.9,327.4z M134.3,350.3v-59.6H156
		c12.7,0,24.4,3.5,24.4,19.2c0,16-12.5,19.4-24.4,19.4h-8.5v21.2h-13.2V350.3z M147.2,318.9h9c8.2,0,11.2-3.2,11.2-9
		c0-5.5-3-8.7-11.2-8.7h-9V318.9z M206.1,320.4c0-20.7,12-30.9,27.2-30.9c15,0,21.7,8,24.2,18.7l-12.5,3.7
		c-2-6.7-4.7-11.5-11.7-11.5c-9,0-14,7.7-14,19.9c0,12.5,5,20.4,14,20.4c7,0,10.5-4.5,12.5-12.5l12.2,2.7c-3,12-10,20.4-24.9,20.4
		C217.5,351.3,206.1,341.4,206.1,320.4z M262.6,328.9c0-16.7,11.5-22.4,21.9-22.4c10.5,0,21.9,5.5,21.9,22.4
		c0,16.7-11.5,22.2-21.9,22.2S262.6,345.4,262.6,328.9z M293.6,328.9c0-7.2-2.5-13-9-13c-6.2,0-9,5.2-9,13c0,7.2,2.5,13.2,9,13.2
		C290.6,342.1,293.6,336.6,293.6,328.9z M312.8,350.1v-42.9h13v6.7c2.5-3.5,7.2-7.7,14.7-7.7c7.2,0,12.5,3.7,12.5,12.5v31.4h-13
		v-27.2c0-4-1.5-6-5-6c-3.5,0-6.5,1.7-9.2,4.2v28.9H312.8z M359.4,328.9c0-17.2,12.2-22.4,21.7-22.4c11.7,0,16.2,6.2,18.7,13.2
		l-11,3.7c-1.7-5.5-4.2-7.2-8-7.2c-5.2,0-8.5,4.5-8.5,13c0,7.7,2.5,13.2,8.5,13.2c3.5,0,6.5-1.7,8.5-7.7l10.7,3.7
		c-2.7,6.7-8,13-19.4,13C370.3,351.1,359.4,345.4,359.4,328.9z M406.5,338.6v-31.4h13v27.4c0,3.7,1.5,6,5,6s6.5-1.7,9.2-4.2v-29.2
		h13v42.9h-13v-6.7c-2.5,3.2-7.2,7.5-14.7,7.5C411.7,351.1,406.5,347.3,406.5,338.6z M456.1,350.1v-42.9h13v7.2l0,0
		c2.2-3.7,6.5-8,14.5-8h0.5l-0.2,11.5c-0.5,0-2.5-0.2-3.5-0.2c-5.2,0-9,2.7-11.5,6v26.7h-12.7V350.1z'
        />
        <g>
          <path
            fill='#F0AB00'
            d='M565,337.1c-9,0-16.5-7.5-16.5-16.5c0-9,7.2-16.5,16.5-16.5c4.5,0,8.7,1.7,11.5,4.7l8.5-8.5
			c-5.2-5.2-12.2-8.2-20.2-8.2c-15.7,0-28.4,12.7-28.4,28.4s12.7,28.4,28.4,28.4c7.7,0,15-3.2,20.2-8.2l-8.5-8.5
			C573.5,335.1,569.5,337.1,565,337.1'
          />
          <path
            fill='#F0AB00'
            d='M588.9,313.4c-4,0-7.2,3.2-7.2,7.2c0,4,3.2,7.2,7.2,7.2c4,0,7.2-3.2,7.2-7.2
			C596.2,316.7,592.9,313.4,588.9,313.4'
          />
          <path
            fill='#F0AB00'
            d='M513.9,374.3h107.4V266.8H513.9V374.3z M525.9,362.1v-83.3h83.3v38.6v6v38.6
			C609.1,362.1,525.9,362.1,525.9,362.1z'
          />
        </g>
      </g>
    </svg>
  );
}

export default SAPConcur;
