import { memo, useRef, useState } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import PlanDetails from './PlanDetails';
import { Card } from '../Core';
import { useAppSelector } from '../../hooks';

function PlanCard({
  className,
  plan,
  onSelectPlan,
  isSelected,
  isUpgrade
}: any) {
  const org = useAppSelector((state) => state.accounts.organization);

  const [show, setShow] = useState(false);
  const target = useRef(null);

  const mouseOver = () => {
    if (!isSelected) {
      setShow(true);
    }
  };
  const mouseLeave = () => {
    setShow(false);
  };
  const clickHandler = () => {
    setShow(false);
    if (!isSelected) onSelectPlan();
  };
  let costLabel = '';
  if (plan.cost === null) costLabel = 'Contact Us';
  else if (plan.cost) costLabel = `$${plan.cost} / Month`;
  return (
    <div
      className={styles.planCard}
      ref={target}
      onMouseOver={mouseOver}
      onMouseLeave={mouseLeave}
    >
      <Card
        className={classNames(
          className,
          styles.card,
          isSelected && styles.planCardActive
        )}
      >
        <div className={styles.planName}>{plan.title}</div>
        <div className={styles.planCost}>{costLabel}</div>
        <PlanDetails plan={plan} />
      </Card>

      {org?.tier !== 4 && (
        <div
          className={classNames(
            styles.planCardOverlay,
            show && styles.overlayVisible
          )}
          onClick={clickHandler}
        >
          {plan.cost !== null
            ? isUpgrade
              ? 'Upgrade Now'
              : `Switch to ${plan.title}`
            : 'Contact Us'}
        </div>
      )}
    </div>
  );
}

export default memo(PlanCard);
