import { useEffect, useState } from 'react';
import { useRuleBuilderState, useRuleBuilderUpdater } from './RuleProvider';
import { getErrors } from '../validation';
import { LogicRule } from '../../../../FormLogicPage/LogicRuleList';
import { isEmptyRule } from '../utils';
import { useSyncedRefState } from '../../../../../hooks/useSyncedRefState';
import { useAppSelector } from '../../../../../hooks';
import useFeatheryRedux from '../../../../../redux';
import { UNDO_TYPES } from '../../../../../utils/constants';
import { useParams } from 'react-router-dom';

type RuleEngineProps = {
  rule: LogicRule;
  dsl: IRuleDSL;
  mode?: RuleBuilderMode;
};

export const RuleEngine = ({
  rule,
  dsl: ruleDSL,
  mode = 'view'
}: RuleEngineProps) => {
  const { formId } = useParams<{ formId: string }>();
  const [initialized, setInitialized] = useState(false);
  const updateOriginalDSL = useRuleBuilderUpdater((s) => s.updateOriginalDSL);
  const updateErrors = useRuleBuilderUpdater((s) => s.updateErrors);
  const updateIsNew = useRuleBuilderUpdater((s) => s.updateIsNew);
  const updateDSL = useRuleBuilderUpdater((s) => s.updateDSL);
  const [_isUndoRedo, updateUndoRedo] = useState(false);

  const reduxDSL = useAppSelector((s) => s.formBuilder.ruleBuilderDSL);

  const { dsl, prevDSL, errors } = useRuleBuilderState((s) => ({
    dsl: s.dsl,
    prevDSL: s.prevDSL,
    errors: s.errors
  }));

  const {
    formBuilder: { addRuleActionToUndoStack }
  } = useFeatheryRedux();

  const getInitialized = useSyncedRefState(initialized);
  const getCurrentErrors = useSyncedRefState(errors);
  const getIsUndoRedo = useSyncedRefState(_isUndoRedo);
  const getMode = useSyncedRefState(mode);

  // When reduxDSL changes, that means Undo or Redo was triggered, sync the DSL with the redux DSL
  useEffect(() => {
    if (!formId) return;

    const ruleBuilderMode = getMode();
    if (reduxDSL && reduxDSL.branches) {
      if (ruleBuilderMode === 'edit') {
        updateUndoRedo(true); // Set flag to indicate that this is an Undo/Redo action to prevent the DSL from being pushed back to the Redux store
      }

      updateDSL(reduxDSL);
    }
  }, [reduxDSL]);

  // Sync DSL changes with the Redux store and Undo/Redo stacks
  useEffect(() => {
    if (!formId) return;

    const isUndoRedo = getIsUndoRedo();
    const ruleBuilderMode = getMode();

    if (!isUndoRedo && ruleBuilderMode === 'edit') {
      addRuleActionToUndoStack({
        type: UNDO_TYPES.RULE_BUILDER,
        oldValue: JSON.parse(JSON.stringify(prevDSL)),
        newValue: JSON.parse(JSON.stringify(dsl))
      });
    } else {
      updateUndoRedo(false); // Reset the undo/redo flag if the change to the DSL was caused by undo/redo
    }
  }, [dsl, prevDSL]);

  // For existing errors, check if they are resolved and update accordingly
  useEffect(() => {
    const currentErrors = getCurrentErrors();
    const errorIds = Object.keys(currentErrors);

    if (errorIds.length > 0) {
      const newErrors = getErrors(dsl);
      const filteredNewErrors = Object.keys(newErrors).reduce((acc, key) => {
        if (errorIds.includes(key)) {
          return {
            ...acc,
            [key]: newErrors[key]
          };
        }

        return acc;
      }, {});

      updateErrors(filteredNewErrors);
    }
  }, [dsl]);

  // Sync isNew with whether the rule is new/empty or not
  useEffect(() => {
    updateIsNew(isEmptyRule(rule));
  }, [rule]);

  // Sync the rule's DSL with the stored original DSL in the context
  useEffect(() => {
    const init = getInitialized();
    const ruleBuilderMode = getMode();

    if (ruleBuilderMode === 'view' || init) {
      updateOriginalDSL(ruleDSL);
      updateDSL(ruleDSL);
    } else {
      setInitialized(true);
    }
  }, [ruleDSL]);

  useEffect(() => {
    if (mode === 'view') {
      setInitialized(false);
    }
  }, [mode]);

  return null;
};
