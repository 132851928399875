import { useEffect, useState } from 'react';
import { APIService } from './types';
import HeaderFilterResultsTable from '../../components/HeaderFilterResultsTable';
import styles from '../FormLogicPage/styles.module.scss';
import useFeatheryRedux from '../../redux';
import DeleteConfirmationAPIModal from './DeleteConfirmationAPIModal';
import { APIServiceModal } from '../../components/Modals/CreateAPIServiceModal';
import { useAppSelector } from '../../hooks';

const APIConnectorPage = ({ formId }: { formId: string }) => {
  const {
    getCustomAPIs,
    editCustomAPI,
    createCustomAPI,
    toasts: { addErrorToast }
  } = useFeatheryRedux();

  const customAPIs = useAppSelector((state) => state.integrations.customAPIs);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState<APIService | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState<APIService | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(true);

  const closeCreateEditModal = () => {
    setShowCreateModal(false);
    setShowEditModal(null);
  };

  useEffect(() => {
    getCustomAPIs({
      panelId: formId
    }).then(() => setIsLoading(false));
  }, []);

  const removeAPI = async (apiSetting: APIService) => {
    setIsLoading(true);
    try {
      await editCustomAPI({
        customAPI: apiSetting,
        remove: true
      });
      setShowDeleteModal(null);
    } catch (e) {
      addErrorToast({
        text: 'Failed to delete API'
      });
    }
    setIsLoading(false);
  };

  const columns = [
    {
      key: 'name',
      name: 'Name'
    },
    {
      key: 'base_url',
      name: 'URL'
    },
    {
      key: 'request_method',
      name: 'Method'
    },
    {
      key: 'headers',
      name: 'Headers'
    },
    {
      key: 'request_parameters',
      name: 'Query Parameters'
    }
  ];

  const dataForTable: any = customAPIs.map((setting: APIService) => {
    const tableSetting: any = { ...setting };
    tableSetting.headers = Object.keys(setting.headers).join(', ');
    tableSetting.request_parameters = Object.keys(
      setting.request_parameters as any
    ).join(', ');
    return tableSetting;
  });

  return (
    <>
      <div className={styles.logicPage}>
        <HeaderFilterResultsTable
          columns={columns}
          data={dataForTable}
          name='API Connector'
          defaultSort={{ order: 1, key: 'base_url' }}
          useSearch
          onCreate={() => {
            setShowCreateModal(true);
          }}
          onDelete={(customAPI: APIService) => {
            setShowDeleteModal(customAPI);
          }}
          onSelect={(customAPI: APIService) => {
            // get original API setting
            const originalAPI = customAPIs.find(
              (entry) => entry.id === customAPI.id
            );
            if (!originalAPI) return;

            setShowEditModal(originalAPI);
          }}
          onCopy={(customAPI: any) =>
            createCustomAPI({
              panelId: formId,
              customAPI: { copy_id: customAPI.id }
            })
          }
        />
      </div>
      <APIServiceModal
        value={showEditModal?.id}
        show={showCreateModal || !!showEditModal}
        onClose={() => closeCreateEditModal()}
        onComplete={() => closeCreateEditModal()}
        options={showCreateModal ? { startPage: 1 } : { startPage: 2 }}
      />
      <DeleteConfirmationAPIModal
        key={Math.random()}
        isLoading={isLoading}
        apiService={showDeleteModal}
        onDelete={removeAPI}
        onClose={() => {
          setShowDeleteModal(null);
        }}
      />
    </>
  );
};

export default APIConnectorPage;
