function ImageUploadIcon({ className }: any) {
  return (
    <svg
      width='95'
      height='90'
      viewBox='0 0 85 80'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M64.0424 12.9027C63.5174 12.9027 63.0975 12.4672 63.0975 11.9228V0.979955C63.0975 0.435536 63.5174 0 64.0424 0C64.5674 0 64.9874 0.435536 64.9874 0.979955V11.9228C64.9874 12.4672 64.5674 12.9027 64.0424 12.9027Z'
        fill='#D6DCE8'
      />
      <path
        d='M71.7029 21.2885C71.7029 20.7441 72.1229 20.3086 72.6479 20.3086H83.1999C83.7249 20.3086 84.1448 20.7441 84.1448 21.2885C84.1448 21.833 83.7249 22.2685 83.1999 22.2685H72.6479C72.1229 22.2685 71.7029 21.833 71.7029 21.2885Z'
        fill='#D6DCE8'
      />
      <path
        d='M70.8692 14.2094C70.5017 13.8283 70.5017 13.175 70.8692 12.7939L78.3763 5.06316C78.7438 4.68207 79.3737 4.68207 79.7412 5.06316C80.1087 5.44426 80.1087 6.09756 79.7412 6.47865L72.2866 14.2094C71.8666 14.5905 71.2367 14.5905 70.8692 14.2094Z'
        fill='#D6DCE8'
      />
      <path
        d='M18.194 65.9473C13.5332 65.9473 9.38749 62.9631 7.88208 58.5204L7.78026 58.1856C7.42523 57.0091 7.2765 56.0197 7.2765 55.0298V35.1801L0.213739 58.756C-0.694627 62.2237 1.37532 65.8188 4.84833 66.7767L49.8663 78.8328C50.4282 78.9784 50.99 79.0482 51.5434 79.0482C54.4428 79.0482 57.0922 77.1238 57.8348 74.2878L60.4575 65.9473H18.194Z'
        fill='#D6DCE8'
      />
      <path
        d='M26.201 35.3798C29.4123 35.3798 32.0233 32.7682 32.0233 29.557C32.0233 26.3457 29.4123 23.7341 26.201 23.7341C22.9898 23.7341 20.3782 26.3457 20.3782 29.557C20.3782 32.7682 22.9898 35.3798 26.201 35.3798Z'
        fill='#D6DCE8'
      />
      <path
        d='M62.5923 15H18.9225C14.9111 15 11.6443 18.2667 11.6443 22.2787V54.3028C11.6443 58.3148 14.9111 61.5815 18.9225 61.5815H62.5923C66.6042 61.5815 69.871 58.3148 69.871 54.3028V22.2787C69.871 18.2667 66.6042 15 62.5923 15ZM18.9225 20.8228H62.5923C63.3962 20.8228 64.0481 21.4748 64.0481 22.2787V42.9461L54.8515 32.2147C53.8759 31.0707 52.4638 30.4593 50.9472 30.4246C49.4391 30.4332 48.0243 31.1027 47.0578 32.2616L36.2449 45.24L32.7223 41.7259C30.7312 39.7349 27.4907 39.7349 25.5023 41.7259L17.4672 49.7583V22.2787C17.4672 21.4748 18.1191 20.8228 18.9225 20.8228Z'
        fill='#D6DCE8'
      />
    </svg>
  );
}

export default ImageUploadIcon;
