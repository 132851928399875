import { memo } from 'react';
import { DropdownField, DropdownMultiField } from '../../../components/Core';
import Label from '../../../components/Dialog/Label';
import { useAppSelector } from '../../../hooks';
import { usePermissionsConfig } from '../../../hooks/usePermissionsConfig';
import { roleValueToLabel } from './PermissionsConfig';
import { PermissionsToggles } from './PermissionsToggles';

function RoleDropdown({
  showUserGroups = false,
  permissionsConfigObjects,
  hidePermissionsToggles = false,
  hideLabel = false,
  disabled = false,
  dropDownClass = '',
  permissionsStyles = {}
}: {
  permissionsStyles?: React.CSSProperties;
  dropDownClass?: string;
  hidePermissionsToggles?: boolean;
  hideLabel?: boolean;
  disabled?: boolean;
  showUserGroups: boolean;
  permissionsConfigObjects: Omit<
    ReturnType<typeof usePermissionsConfig>,
    'getSubmitFunction' | 'submissionData'
  >;
}) {
  const {
    allGroups,
    curGroups,
    setCurGroups,
    curRole,
    setCurRole,
    isEnterprise,
    permissions
  } = permissionsConfigObjects;

  function renderPermissionsToggles() {
    if (hidePermissionsToggles) return;

    return (
      <PermissionsToggles
        curRole={curRole}
        permissions={permissions}
        styles={permissionsStyles}
      />
    );
  }

  const account = useAppSelector((state) => state.accounts.account);
  const curDisabled = disabled || !isEnterprise || account.role !== 'admin';

  return (
    <div style={{ width: '100%' }}>
      {showUserGroups && allGroups.length > 0 && (
        <>
          <Label>Groups</Label>
          <DropdownMultiField
            disabled={curDisabled}
            selected={curGroups}
            options={allGroups.map((group: any) => ({
              value: group.id,
              label: group.name
            }))}
            onChange={(items: { value: string }[]) =>
              setCurGroups(items.map((item) => item.value))
            }
          />
        </>
      )}
      {!hideLabel && <Label>Role</Label>}
      <DropdownField
        onChange={(event: any) => setCurRole(event.target.value)}
        selected={curRole}
        options={[
          { value: 'admin', display: roleValueToLabel.admin },
          { value: 'editor', display: roleValueToLabel.editor },
          { value: 'viewer', display: roleValueToLabel.viewer }
        ]}
        disabled={curDisabled}
        className={dropDownClass}
      />
      {renderPermissionsToggles()}
    </div>
  );
}

export default memo(RoleDropdown);
