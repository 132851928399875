// margin pattern
import React, { RefObject, useEffect } from 'react';
import { useAppSelector } from '../../../hooks';
import useViewport from '../../../hooks/useViewport';
import {
  getElementStyle,
  getElementStyleType
} from '../GridInGrid/components/CellElement/utils';
import { ControlReference } from './ControlLayer';
import {
  getMarginName,
  getMarginPaddingConfig
} from '../../../utils/boxSpacingHelper';

const marginPattern = document.createElement('canvas');
marginPattern.width = 7;
marginPattern.height = 7;
const marginPatternCtx = marginPattern.getContext('2d');

if (marginPatternCtx) {
  marginPatternCtx.strokeStyle = 'rgba(226,98,110,0.25)';
  marginPatternCtx.beginPath();
  marginPatternCtx.moveTo(0, 7);
  marginPatternCtx.lineTo(7, 0);
  marginPatternCtx.moveTo(-1, 1);
  marginPatternCtx.lineTo(1, -1);
  marginPatternCtx.moveTo(6, 8);
  marginPatternCtx.lineTo(8, 6);
  marginPatternCtx.lineWidth = 1;
  marginPatternCtx.lineCap = 'round';
  marginPatternCtx.stroke();
}

const MarginDisplay = React.memo(function MarginDisplay(props: {
  control: ControlReference;
}) {
  const canvasRef: RefObject<HTMLCanvasElement> = React.useRef(null);

  const control = props.control;
  if (!control) return null;

  const element = control?.canvasReference?.ref?.current;
  const nodeElement =
    control?.canvasReference?.node?.unlinked()?.element?.state;

  const theme = useAppSelector((s) => s.formBuilder.theme);
  const { viewport } = useViewport();

  let computedStyle = {};
  if (nodeElement) {
    const { style } = getElementStyleType(nodeElement);
    computedStyle = getElementStyle(nodeElement, viewport, theme, style);
  } else {
    computedStyle = control?.canvasReference?.node?.getStyle();
  }

  const getRectPoints = () => {
    const width = control.canvasReference.rect.width;
    const height = control.canvasReference.rect.height;

    const top = getMarginValue(computedStyle, 'top');
    const left = getMarginValue(computedStyle, 'left');

    const corners = {
      topLeft: { x: left, y: top },
      topRight: { x: width + left, y: top },
      bottomRight: { x: width + left, y: height + top },
      bottomLeft: { x: left, y: height + top }
    };

    return [
      corners.topLeft,
      corners.topRight,
      corners.bottomRight,
      corners.bottomLeft
    ];
  };

  const getRectPointsObj = (div: HTMLDivElement) => {
    return {
      topLeft: { x: 0, y: 0 },
      topRight: { x: getMarginWidth(), y: 0 },
      bottomRight: { x: getMarginWidth(), y: getMarginHeight() },
      bottomLeft: { x: 0, y: getMarginHeight() }
    };
  };

  const getMarginValue = (
    style: any,
    side: 'top' | 'right' | 'bottom' | 'left'
  ): number => {
    const type = nodeElement?._type || 'container';
    if (!type) return 0;
    const config = getMarginPaddingConfig(type);
    const marginName = getMarginName(config);
    return style[`${marginName}_${side}`];
  };

  const getMarginOffset = () => {
    return {
      top: -1 * getMarginValue(computedStyle, 'top'),
      left: -1 * getMarginValue(computedStyle, 'left')
    };
  };

  const getMarginWidth = () => {
    return (
      control.canvasReference.rect.width +
      getMarginValue(computedStyle, 'left') +
      getMarginValue(computedStyle, 'right')
    );
  };

  const getMarginHeight = () => {
    return (
      control.canvasReference.rect.height +
      getMarginValue(computedStyle, 'top') +
      getMarginValue(computedStyle, 'bottom')
    );
  };

  const getMarginPoints = (div: HTMLDivElement) => {
    const corners = getRectPointsObj(div);

    const topMargin = getMarginValue(computedStyle, 'top');
    const rightMargin = getMarginValue(computedStyle, 'right');
    const bottomMargin = getMarginValue(computedStyle, 'bottom');
    const leftMargin = getMarginValue(computedStyle, 'left');

    if (topMargin) {
      corners.topLeft.y -= topMargin;
      corners.topRight.y -= topMargin;
    }

    if (rightMargin) {
      corners.topRight.x += rightMargin;
      corners.bottomRight.x += rightMargin;
    }

    if (bottomMargin) {
      corners.bottomRight.y += bottomMargin;
      corners.bottomLeft.y += bottomMargin;
    }

    if (leftMargin) {
      corners.bottomLeft.x -= leftMargin;
      corners.topLeft.x -= leftMargin;
    }

    return [
      corners.topLeft,
      corners.topRight,
      corners.bottomRight,
      corners.bottomLeft
    ];
  };

  const drawMargin = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const context = canvas.getContext('2d');
    if (!context) return;

    if (!element) return;

    const rectPoints = getRectPoints();
    const marginPoints = getMarginPoints(element);

    context.clearRect(0, 0, getMarginWidth(), getMarginHeight());

    const pattern: CanvasPattern | null = context.createPattern(
      marginPattern,
      'repeat'
    );
    if (pattern) context.fillStyle = pattern;
    context.beginPath();

    context.moveTo(marginPoints[0].x, marginPoints[0].y);
    for (let i = 1; i < marginPoints.length; ++i) {
      const m = marginPoints[i];
      context.lineTo(m.x, m.y);
    }

    context.closePath();
    context.fill();

    context.globalCompositeOperation = 'destination-out';

    context.fillStyle = '#00f';
    context.beginPath();

    context.moveTo(rectPoints[0].x, rectPoints[0].y);
    for (let i = 1; i < rectPoints.length; ++i) {
      const p = rectPoints[i];
      context.lineTo(p.x, p.y);
    }

    context.closePath();
    context.fill();

    context.globalCompositeOperation = 'source-over';
  };

  const mw = getMarginWidth();
  const mh = getMarginHeight();

  const to = getMarginOffset().top;
  const lo = getMarginOffset().left;

  useEffect(() => {
    drawMargin();
  }, [control.canvasReference, to, lo, mw, mh]);

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
      }}
    >
      <canvas
        width={isNaN(mw) ? 'auto' : mw}
        height={isNaN(mh) ? 'auto' : mh}
        style={{
          left: isNaN(lo) ? 0 : lo,
          top: isNaN(to) ? 0 : to,
          position: 'absolute'
        }}
        ref={canvasRef}
      />
    </div>
  );
});

export default MarginDisplay;
