export default function AddressIcon() {
  return (
    <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M34.122 17.0835C33.2936 17.0835 32.622 16.412 32.622 15.5835C32.622 14.7551 33.2936 14.0835 34.122 14.0835V17.0835ZM25.2163 38.3008C24.3879 38.3008 23.7163 37.6293 23.7163 36.8008C23.7163 35.9724 24.3879 35.3008 25.2163 35.3008V38.3008ZM41.4364 17.0835H34.122V14.0835H41.4364V17.0835ZM42.7002 18.351C42.7002 17.6491 42.1325 17.0835 41.4364 17.0835V14.0835C43.7931 14.0835 45.7002 15.996 45.7002 18.351H42.7002ZM42.7002 34.0334V18.351H45.7002V34.0334H42.7002ZM41.4364 35.3008C42.1325 35.3008 42.7002 34.7352 42.7002 34.0334H45.7002C45.7002 36.3884 43.7931 38.3008 41.4364 38.3008V35.3008ZM25.2163 35.3008H41.4364V38.3008H25.2163V35.3008Z'
        fill='#414859'
      />
      <path
        d='M15.0545 24.9247C17.3025 24.9247 19.1249 23.1023 19.1249 20.8543C19.1249 18.6063 17.3025 16.7839 15.0545 16.7839C12.8065 16.7839 10.9841 18.6063 10.9841 20.8543C10.9841 23.1023 12.8065 24.9247 15.0545 24.9247Z'
        stroke='#414859'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.0545 10C12.1758 10 9.41494 11.1436 7.37936 13.1792C5.34377 15.2147 4.2002 17.9756 4.2002 20.8543C4.2002 23.4214 4.74563 25.1011 6.23538 26.9599L15.0545 37.1358L23.8737 26.9599C25.3634 25.1011 25.9089 23.4214 25.9089 20.8543C25.9089 17.9756 24.7653 15.2147 22.7297 13.1792C20.6941 11.1436 17.9333 10 15.0545 10V10Z'
        stroke='#414859'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
