export default function ProgressIcon() {
  return (
    <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect
        x='4'
        y='16'
        width='40'
        height='15.1351'
        rx='7.56757'
        stroke='#414859'
        strokeWidth='3'
        strokeLinecap='round'
      />
      <rect
        width='25.3061'
        height='6.48649'
        rx='3.24324'
        transform='matrix(1 0 0 -1 8.34644 26.8108)'
        fill='#414859'
      />
    </svg>
  );
}
