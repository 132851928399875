export default function RadioButtonIcon({ color = '#414859', ...props }) {
  return (
    <svg
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M24 4H24C12.9543 4 4 12.9543 4 24V24C4 35.0457 12.9543 44 24 44H24C35.0457 44 44 35.0457 44 24V24C44 12.9543 35.0457 4 24 4Z'
        stroke={color}
        strokeWidth='3'
      />
      <path
        d='M23.65 30.3H23.65C19.9773 30.3 17 27.3227 17 23.65V23.65C17 19.9773 19.9773 17 23.65 17H23.65C27.3227 17 30.3 19.9773 30.3 23.65V23.65C30.3 27.3227 27.3227 30.3 23.65 30.3Z'
        stroke={color}
      />
    </svg>
  );
}
