function IBQRater({ width = 90, height = 90 }) {
  return (
    <img
      alt='IBQRater logo'
      src={require('./IBQRater.webp')}
      style={{ width, height }}
    />
  );
}

export default IBQRater;
