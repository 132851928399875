import { useCallback, useRef, useState } from 'react';

export default function useModalSubmissionLockout(
  customSubmit: any = () => {}
) {
  const lockedOut = useRef(false);
  const [lockOutFlag, setLockOutFlag] = useState(false);
  const lockoutFunction = useCallback(
    async (...props: any[]) => {
      if (lockedOut.current) return;
      lockedOut.current = true;
      setLockOutFlag(true);
      await customSubmit(...props);
      lockedOut.current = false;
      setLockOutFlag(false);
    },
    [customSubmit]
  );
  return { lockOutFlag, lockoutFunction };
}
