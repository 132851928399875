export default function FileElementUploadIcon() {
  return (
    <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M18.1014 30.0785L24.0001 24.1904L29.8987 30.0785'
        stroke='#414859'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M24.0002 40.3009L24.0002 24.1905'
        stroke='#414859'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M34.9884 36.3363C39.9884 36.3363 44 32.2798 44 27.224C44 22.1681 39.9884 18.1117 34.9884 18.1117C34.9884 12.5267 30.5116 8 24.9884 8C20.7442 8 17.1395 10.6455 15.686 14.408C15.4535 14.408 15.2209 14.3492 14.9884 14.3492C8.94186 14.3492 4 19.2875 4 25.4603C4 31.5744 8.88372 36.5714 14.9884 36.5714'
        stroke='#414859'
        strokeWidth='3'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
