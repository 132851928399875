import React, { useState } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';

export default function SideNavigation({
  children,
  title,
  showAll,
  outlined
}: {
  children: React.ReactNode | React.ReactNode[];
  title: string;
  showAll?: boolean;
  outlined?: boolean;
}) {
  // List of pages
  const pages = React.Children.toArray(children);

  const pageDetails = pages.map((page: any) => {
    return {
      title:
        typeof page.props.title === 'function'
          ? page.props.title()
          : page.props.title || page.props.key,
      icon:
        typeof page.props.icon === 'function'
          ? page.props.icon()
          : page.props.icon
    };
  });

  // State to keep current page
  const [currentPage, setCurrentPage] = useState(
    showAll ? pageDetails.length : 0
  );

  const renderPage = () => {
    if (currentPage === pageDetails.length)
      return (
        <>
          {pageDetails.map((details, index) => (
            <div key={index}>{pages[index]}</div>
          ))}
        </>
      );

    return pages[currentPage];
  };

  const itemStyles = {
    [styles.sideNavButton]: true,
    [styles.active]: currentPage === pageDetails.length,
    [styles.outlined]: outlined
  };

  // Render the side navigation bar and current page
  return (
    <div className={styles.container}>
      <div className={styles.sideNav}>
        <div className={styles.title}>{title}</div>
        {showAll && (
          <div
            className={classNames(itemStyles)}
            onClick={() => setCurrentPage(pageDetails.length)}
          >
            All
          </div>
        )}
        {pageDetails.map((details, index) => {
          if (!details.title) return null;

          itemStyles[styles.active] = currentPage === index;

          return (
            <div
              key={index}
              className={classNames(itemStyles)}
              onClick={() => setCurrentPage(index)}
            >
              <div className={styles.navItem}>
                {details.icon}
                <div>{details.title}</div>
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.page}>{renderPage()}</div>
    </div>
  );
}
