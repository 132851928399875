type FieldWithOptions = {
  metadata: {
    option_labels: string[];
    options: string[];
  };
};

export const getFieldOptions = (field: FieldWithOptions) => {
  return field.metadata.options.map((option, index) => ({
    label: field.metadata.option_labels[index] || option,
    value: option
  }));
};
