export type ViewportType = 'desktop' | 'mobile' | 'unconstrained' | 'all';

type GetKeyOptions = {
  viewport?: ViewportType | null;
  fallback?: ViewportType | null;
};

type ViewportOptions = {
  allowedViewports?: ViewportType[];
};

// These are the properties that can diverge for the mobile viewport
export const MOBILE_DIVERGED_VIEWPORT_PROPERTIES = {
  step: ['width', 'height'],
  elements: ['styles'],
  subgrids: ['axis', 'styles', 'width', 'height', 'background_image']
} as const;

/**
 * A class that manages and provides utilities around the current viewport set.
 * This class can be provided to many classes such as Gig, Cell, Grid to change
 * the viewport of all of the instances at once.
 */
export class Viewport {
  static Desktop: ViewportType = 'desktop';
  static Mobile: ViewportType = 'mobile';
  static Unconstrained: ViewportType = 'unconstrained';
  static All: ViewportType = 'all';

  static Viewports: ViewportType[] = [Viewport.Desktop, Viewport.Mobile];

  #activeViewport: ViewportType;
  #allowedViewports: { [viewport: string]: boolean }; // Viewports that are allowed to be set

  constructor(
    viewport: ViewportType = Viewport.Desktop,
    options: ViewportOptions = {}
  ) {
    const { allowedViewports = Viewport.Viewports } = options;

    this.#activeViewport = viewport;
    this.#allowedViewports = allowedViewports.reduce(
      (viewportMap, _viewport) => ({
        ...viewportMap,
        [_viewport]: true
      }),
      {}
    );
  }

  /**
   * Change the active viewport.
   * @param viewport - Viewport that is to be set
   */
  setActiveViewport(viewport: ViewportType) {
    if (this.#allowedViewports[viewport]) {
      this.#activeViewport = viewport;
    }
  }

  /**
   * A utility method that helps retrieving the value of a key on a specified object for the current or specified viewport.
   * @param obj - Object that is to be used to retrieve the value of the key
   * @param key - Key that is to be retrieved from the object
   * @param options.viewport - Viewport that the key is to be retrieved from
   * @param options.fallback - A fallback viewport that is to be used if the key does not exist on the current or specified viewport
   * @returns {any}
   */
  getKey(obj: any, key: string, options: GetKeyOptions = {}): any {
    const { viewport = null, fallback = Viewport.Desktop } = options;
    const _viewport = viewport || this.#activeViewport;

    let value = null;
    if (_viewport === Viewport.Desktop) value = obj[key];
    if (_viewport === Viewport.Mobile) value = obj[`mobile_${key}`];

    if (!value && fallback && fallback !== _viewport) {
      return this.getKey(obj, key, { viewport: fallback });
    }

    return value;
  }

  /**
   * Returns whether the viewport is set to desktop.
   * @returns {boolean}
   */
  isDesktop() {
    return this.#activeViewport === Viewport.Desktop;
  }

  /**
   * Returns whether the viewport is set to mobile.
   * @returns {boolean}
   */
  isMobile() {
    return this.#activeViewport === Viewport.Mobile;
  }

  get activeViewport() {
    return this.#activeViewport;
  }
}

(window as any).Viewport = Viewport;
