export default function MaximizeIcon({ width = 32, height = 32, ...props }) {
  return (
    <svg
      width={width}
      height={height}
      {...props}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='24' height='24' rx='5' fill='#EBEDF2' />
      <path
        d='M10 19H5V14M14 5H19V10'
        stroke='#333849'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
