export default [
  'Republic of Adygeya',
  'Altai Republic',
  'Altai Krai',
  'Amur Oblast',
  'Arkhangelsk Oblast',
  'Astrakhan Oblast',
  'Republic of Bashkortostan',
  'Belgorod Oblast',
  'Bryansk Oblast',
  'Republic of Buryatia',
  'Chechen Republic',
  'Chelyabinsk Oblast',
  'Chukotka Autonomous Okrug',
  'Chuvash Republic',
  'Republic of Dagestan',
  'Republic of Ingushetia',
  'Irkutsk Oblast',
  'Ivanovo Oblast',
  'Jewish Autonomous Oblast',
  'Kabardino-Balkarian Republic',
  'Kaliningrad Oblast',
  'Republic of Kalmykia',
  'Kaluga Oblast',
  'Kamchatka Krai',
  'Karachay–Cherkess Republic',
  'Republic of Karelia',
  'Kemerovo Oblast',
  'Khabarovsk Krai',
  'Republic of Khakassia',
  'Khanty-Mansi Autonomous Okrug',
  'Kirov Oblast',
  'Komi Republic',
  'Kostroma Oblast',
  'Krasnodar Krai',
  'Krasnoyarsk Krai',
  'Kurgan Oblast',
  'Kursk Oblast',
  'Leningrad Oblast',
  'Lipetsk Oblast',
  'Magadan Oblast',
  'Mari El Republic',
  'Republic of Mordovia',
  'Moscow',
  'Moscow Oblast',
  'Murmansk Oblast',
  'Nizhny Novgorod Oblast',
  'Novgorod Oblast',
  'Novosibirsk Oblast',
  'Omsk Oblast',
  'Orenburg Oblast',
  'Oryol Oblast',
  'Penza Oblast',
  'Perm Krai',
  'Primorsky Krai',
  'Pskov Oblast',
  'Rostov Oblast',
  'Ryazan Oblast',
  'Saint Petersburg',
  'Sakha Republic (Yakutia)',
  'Sakhalin Oblast',
  'Samara Oblast',
  'Saratov Oblast',
  'Republic of North Ossetia–Alania',
  'Smolensk Oblast',
  'Stavropol Krai',
  'Sverdlovsk Oblast',
  'Tambov Oblast',
  'Republic of Tatarstan',
  'Tomsk Oblast',
  'Tula Oblast',
  'Tver Oblast',
  'Tyumen Oblast',
  'Tyva Republic',
  'Udmurtia',
  'Ulyanovsk Oblast',
  'Vladimir Oblast',
  'Volgograd Oblast',
  'Vologda Oblast',
  'Voronezh Oblast',
  'Yamalo-Nenets Autonomous Okrug',
  'Yaroslavl Oblast',
  'Zabaykalsky Krai'
];
