import { useMemo } from 'react';
import { useRuleBuilderState } from '../../../context';
import { CheckIcon } from '../../../../../../../components/Icons';
import { Button } from '../../../../../../../components/Core/Button/button';

const replacerFn = (key: string, value: any) => {
  if (key === 'id') {
    return undefined;
  }

  return value;
};

type SaveButtonProps = {
  onSave: () => void;
};

export const SaveButton = ({ onSave = () => {} }: SaveButtonProps) => {
  const { dsl, originalDSL } = useRuleBuilderState((s) => ({
    dsl: s.dsl,
    originalDSL: s.originalDSL
  }));

  const dslChanged = useMemo(() => {
    const _dsl = JSON.stringify(dsl, replacerFn);
    const _originalDSL = JSON.stringify(originalDSL, replacerFn);

    return _dsl !== _originalDSL;
  }, [dsl, originalDSL]);

  if (dslChanged) {
    return <Button onClick={onSave}>Save Changes</Button>;
  }

  return (
    <Button variant='gray'>
      <CheckIcon width={16} height={16} /> Saved
    </Button>
  );
};
