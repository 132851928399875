import { memo, useEffect, useState } from 'react';

import styles from './styles.module.scss';
import { useHistory, useParams } from 'react-router-dom';
import useFeatheryRedux from '../../redux';
import { ConditionData } from './components/types';
import validateStepConnections from './components/utils';
import { produce } from 'immer';
import NavRule from './components/NavRule';
import classNames from 'classnames';
import { PlusIcon } from '../Icons';
import { useFormBuilderChangeStep } from '../../hooks/useFormBuilderChangeStep';
import { PanelWarningOverlay } from '../Panels/components';

//   setShowConnectionsPanel: (show: boolean) => void;
interface NavigationNodeRulesProps {
  step: any;
  inPanel?: boolean;
  setShowConnectionsPanel?: (show: boolean) => void;
}

function NavigationNodeRules({
  step,
  inPanel = false,
  setShowConnectionsPanel = () => {}
}: NavigationNodeRulesProps) {
  const { formId } = useParams<{ formId: string }>();
  const {
    formBuilder: { updateNavRulesByStep }
  } = useFeatheryRedux();
  const history = useHistory();

  const pendingFormData = [...step.next_conditions];
  const [formData, setFormData] = useState<ConditionData[]>(pendingFormData);
  useEffect(() => setFormData(pendingFormData), [step.id]);

  const [errors, setErrors] = useState({});

  const changeStep = useFormBuilderChangeStep();

  const validateData = (newData: any) => {
    const errors = validateStepConnections(newData, step);
    setErrors(errors);
    return errors;
  };

  const setData = (transform: (draft: any) => void, showIfInvalid = true) => {
    const newData = produce(formData, transform);
    setFormData(newData);

    const errors = validateData(newData);
    if (Object.keys(errors).length === 0) {
      setErrors({});
      updateNavRulesByStep({ stepId: step.id, newData, formId });
    } else if (showIfInvalid) setErrors(errors);
  };

  const addCondition = () => {
    validateData(formData);
    const newFormData = produce(formData, (draft) => {
      draft.push({
        previous_step: step.id,
        next_step: '',
        element_id: '',
        element_type: '',
        created_at: new Date().toISOString(),
        metadata: {},
        rules: []
      });
    });
    // Condition is not complete, do not validate or try to submit
    setFormData(newFormData);
  };

  return (
    <div className={classNames(styles.navRules, { [styles.inPanel]: inPanel })}>
      {!inPanel && <PanelWarningOverlay />}
      <div className={styles.navDirectionContainer}>
        <div className={styles.navDirectionHeader}>
          {step.key}
          {!inPanel && (
            <div
              className={styles.navDesignerEntry}
              onClick={() => {
                changeStep(step.id);
                setShowConnectionsPanel(false);
                history.push(`/forms/${formId}/${step.id}/`);
              }}
            >
              Edit Step
            </div>
          )}
        </div>
        {formData.map((cond, index) => (
          <NavRule
            key={JSON.stringify(cond)}
            cond={cond}
            step={step}
            index={index}
            setData={setData}
            errors={errors}
            toggleNextStep
          />
        ))}
        <div
          className={classNames(styles.addNavigationRule, styles.ruleTarget)}
          onClick={addCondition}
        >
          <PlusIcon width={15} height={15} className={styles.addNewRuleIcon} />
          Connection
        </div>
      </div>
    </div>
  );
}

export default memo(NavigationNodeRules);
