import {
  calculateElementRenderData,
  getStepPropFromElementType
} from '../utils/step';
import { flattenStyleMap } from '../utils/elements';
import {
  elementTypeToThemeSelector,
  getAsset,
  getThemeStyle
} from '../utils/themes';
import { objectApply } from '../utils/core';
import { Viewport } from '../components/RenderingEngine/GridInGrid/engine';
import { useMemo } from 'react';
import useViewport from './useViewport';
import { getElementStyleType } from '../components/RenderingEngine/GridInGrid/components/CellElement/utils';
import { useAppSelector } from './index';

export const useElementRenderData = (node: any) => {
  const workingSteps = useAppSelector((s) => s.formBuilder.workingSteps);
  const activeStepId = useAppSelector((s) => s.formBuilder.activeStepId);
  const { viewport } = useViewport();
  const theme = useAppSelector((s) => s.formBuilder.theme);
  const flatThemeMap = useMemo(() => flattenStyleMap(theme?.elements), [theme]);
  const activeStep = workingSteps[activeStepId];

  const data: any = {
    id: null,
    type: null,
    renderData: null
  };

  if (node.isElement) {
    const element = node?.element?.getRaw();
    const { style, type } = getElementStyleType(element);

    data.id = element.id;
    data.type = type;
    data.renderData = calculateElementRenderData({
      element,
      viewport,
      theme,
      style
    });
  }

  const stepElementProp = getStepPropFromElementType(data.type);
  const rawElement = activeStep[stepElementProp].find(
    (el: any) => el.id === data.id
  );

  const assetProps = useMemo(() => {
    const isAsset = !!rawElement?.source_asset;
    return {
      isAsset,
      baseProps: isAsset
        ? getAsset(theme, data.type, rawElement.source_asset)
        : {}
    };
  }, [node, rawElement, theme, data.type]);

  const baseStyle = useMemo(() => {
    if (!rawElement) {
      return {};
    }

    const [l2, l1] = elementTypeToThemeSelector(data.type);
    let style = getThemeStyle(flatThemeMap, viewport, l2, l1);
    const asset = getAsset(theme, data.type, rawElement.source_asset);
    style = objectApply(style, asset?.styles);

    // In mobile view, we also need to apply asset-level mobile styles and instance-level desktop styles
    if (viewport === Viewport.Mobile) {
      style = objectApply(style, asset?.mobile_styles, rawElement.styles);
    }

    return style;
  }, [node, flatThemeMap, rawElement, viewport, data.type]);

  const overrideProps = useMemo(() => {
    return rawElement ?? {};
  }, [node, rawElement]);

  const overrideStyle = useMemo(() => {
    return (
      (viewport !== Viewport.Mobile
        ? rawElement?.styles
        : rawElement?.mobile_styles) ?? {}
    );
  }, [node, viewport, rawElement]);

  return {
    elementRenderData: data.renderData,
    assetProps,
    baseStyle,
    overrideProps,
    overrideStyle
  };
};
