import Form from 'react-bootstrap/Form';
import styles from './styles.module.scss';

type RadioInputProps = {
  selected?: string;
  onChange: (value: string) => void;
  options: { value: string; display: any }[];
};

const RadioInput = ({
  selected,
  options,
  onChange = () => {}
}: RadioInputProps) => {
  return (
    <div className={styles.radioInput}>
      {options.map((option) => (
        <Form.Check
          className={styles.radioCheck}
          key={option.value}
          label={
            <div
              className={styles.radioInputLabel}
              onClick={() => onChange(option.value)}
            >
              {option.display}
            </div>
          }
          type='radio'
          name={option.value}
          checked={selected === option.value}
          onChange={() => onChange(option.value)}
        />
      ))}
    </div>
  );
};

export default RadioInput;
