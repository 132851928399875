import styles from './styles.module.scss';
import classNames from 'classnames';

export default function ContainerControlButton({
  label,
  icon,
  onClick,
  size = 'normal'
}: any) {
  return (
    <div
      onClick={onClick}
      className={classNames(
        styles.cellControlButton,
        size === 'wide' && styles.wide
      )}
    >
      <div className={styles.iconContainer}>{icon}</div>
      {label}
    </div>
  );
}
