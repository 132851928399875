export default function ClearIcon({
  width = 32,
  height = 32,
  color = '#414859',
  ...props
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='4 4 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 18.5C15.5899 18.5 18.5 15.5899 18.5 12C18.5 8.41015 15.5899 5.5 12 5.5C8.41015 5.5 5.5 8.41015 5.5 12C5.5 15.5899 8.41015 18.5 12 18.5ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.9404 8.92495C9.64751 8.63206 9.17263 8.63206 8.87974 8.92495C8.58685 9.21784 8.58685 9.69272 8.87974 9.98561L10.8941 12L8.87974 14.0144C8.58685 14.3073 8.58685 14.7822 8.87974 15.0751C9.17263 15.3679 9.64751 15.3679 9.9404 15.0751L11.9548 13.0607L13.9688 15.0747C14.2617 15.3676 14.7366 15.3676 15.0295 15.0747C15.3224 14.7818 15.3224 14.3069 15.0295 14.0141L13.0154 12L15.0295 9.98594C15.3224 9.69305 15.3224 9.21818 15.0295 8.92528C14.7366 8.63239 14.2617 8.63239 13.9688 8.92528L11.9548 10.9393L9.9404 8.92495Z'
        fill={color}
      />
    </svg>
  );
}
