import { CloseIcon } from '../Icons';
import styles from './styles.module.scss';
import classNames from 'classnames';
import testIds from '../../utils/testIds';

export default function CtaPanel({
  className,
  children,
  width = 'sm',
  closePanel = () => {},
  shouldShowCloseIcon = true,
  style
}: any) {
  return (
    <div
      style={style}
      className={classNames(
        styles.ctaPanel,
        className,
        styles[`panel-${width}`]
      )}
    >
      {children}
      {shouldShowCloseIcon && (
        <div
          data-testid={testIds.ctaPanelCloseButton}
          className={styles.panelClose}
          tabIndex={0}
          onClick={closePanel}
        >
          <CloseIcon width={24} height={24} />
        </div>
      )}
    </div>
  );
}
