import { CollapsibleSection } from '../../../Core';
import { Link, useParams } from 'react-router-dom';
import useActiveLogic from './useActiveLogic';

export default function LogicSection({ elementId }: any) {
  const { formId } = useParams<{ formId: string }>();

  const activeLogic = useActiveLogic('', elementId);

  if (!activeLogic.length) return null;
  return (
    <CollapsibleSection title='Advanced Logic Rules' collapsible>
      {activeLogic.map((rule) => (
        <div key={rule.id} style={{ marginBottom: '7px' }}>
          <Link to={`/forms/${formId}/logic/${rule.id}`}>
            {rule.name}: {rule.display_label}
          </Link>
        </div>
      ))}
    </CollapsibleSection>
  );
}
