import { memo, useEffect, useState } from 'react';

import Dialog from '../../Dialog';

import { CheckboxField, DynamicTextTooltip } from '../../Core';
import { Button } from '../../Core/Button/button';
import classNames from 'classnames';
import ruleStyles from '../../NavigationRules/styles.module.scss';
import styles from './styles.module.scss';
import { ChevronDownIcon } from '../../Icons';

const StepSelectorWithModal = ({
  steps,
  stepList,
  selected = [],
  placeholder = 'Step',
  setFields,
  className = '',
  size = 'lg',
  ...props
}: any) => {
  const [show, setShow] = useState(false);
  const [originalSelected, setOriginalSelected] = useState(selected);

  useEffect(() => {
    setOriginalSelected(selected);
  }, [show]);

  const selectedTitle =
    selected.length && Object.keys(steps).length
      ? selected.map((stepId: string) => steps[stepId]?.key).join(', ')
      : placeholder;

  return (
    <>
      <Dialog
        isOpen={show}
        onClose={() => {
          setShow(false);
        }}
        shouldShowCloseIcon
        title='Select Steps'
        size={size}
      >
        <>
          <span>
            Your users will need to be logged-in to access these steps.
          </span>
          <div className={styles.selectContainer}>
            <span className={styles.selectedCount}>
              {selected.length}/{stepList.length} Selected
            </span>
            <div style={{ display: 'flex' }}>
              <Button
                variant='text-primary'
                type='submit'
                onClick={() =>
                  setFields({
                    auth_gate_steps: stepList.map(({ value }: any) => value)
                  })
                }
              >
                Select All
              </Button>
              <Button
                variant='text-primary'
                type='submit'
                onClick={() => setFields({ auth_gate_steps: [] })}
              >
                Select None
              </Button>
            </div>
          </div>
          <div className={styles.stepListContainer}>
            {stepList.map((step: any) => {
              const isChecked = selected.includes(step.value);
              return (
                <div
                  onClick={() => {
                    let newStepList = [...selected];
                    if (!isChecked) newStepList.push(step.value);
                    else
                      newStepList = newStepList.filter(
                        (id) => id !== step.value
                      );
                    setFields({ auth_gate_steps: newStepList });
                  }}
                  key={step.value}
                  className={styles.stepContainer}
                >
                  <CheckboxField
                    checked={isChecked}
                    onClick={(e: any) => e.stopPropagation()}
                  />
                  {step.display}
                </div>
              );
            })}
          </div>
          <div className={styles.actionContainer}>
            <Button
              variant='outline'
              onClick={() => {
                setFields({ auth_gate_steps: originalSelected });
                setShow(false);
              }}
            >
              Cancel
            </Button>
            <Button onClick={() => setShow(false)}>Update</Button>
          </div>
        </>
      </Dialog>
      <DynamicTextTooltip text={selectedTitle} maxLength={12}>
        <div
          className={classNames(styles.selector, className)}
          onClick={() => {
            setShow(true);
          }}
          {...props}
        >
          <span className={ruleStyles.fieldSpan}>
            <b>{selected.length} steps</b> - {selectedTitle}
          </span>
          <ChevronDownIcon
            width={16}
            height={16}
            className={styles.stepExpandIcon}
          />
        </div>
      </DynamicTextTooltip>
    </>
  );
};

export default memo(StepSelectorWithModal);
