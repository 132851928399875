import MonacoEditor from '@monaco-editor/react';
import tomorrowTheme from './tomorrow.theme.json';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { useState } from 'react';

type JSONEditorProps = {
  height?: string;
  code?: string;
  onChange?: (code?: string) => void;
  readOnly?: boolean;
  placeholder?: string;
};

export const JSONEditor = ({
  height = '350px',
  code,
  onChange = () => {},
  readOnly = false,
  placeholder = ''
}: JSONEditorProps) => {
  const [showPlaceholder, setShowPlaceholder] = useState(false);

  const beforeMount = (monaco: any) => {
    monaco.editor.defineTheme('tomorrow', tomorrowTheme);
  };

  const handleChange = (code: string | undefined) => {
    if (!code && placeholder && !showPlaceholder) {
      setShowPlaceholder(true);
    } else if (code && placeholder && showPlaceholder) {
      setShowPlaceholder(false);
    }

    onChange(code);
  };

  return (
    <div style={{ width: '100%' }}>
      <MonacoEditor
        className={classNames(styles.jsonEditor, {
          [styles.readOnly]: readOnly
        })}
        height={height}
        width='100%'
        value={code}
        onChange={(code) => !readOnly && handleChange(code)}
        beforeMount={beforeMount}
        language='json'
        theme='tomorrow'
        options={{
          contextmenu: false,
          readOnly,
          formatOnPaste: true,
          formatOnType: true,
          tabSize: 2,
          minimap: {
            enabled: false
          }
        }}
      />
      {showPlaceholder && (
        <div className={styles.placeholder}>{placeholder}</div>
      )}
    </div>
  );
};
