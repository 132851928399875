import { createSlice } from '@reduxjs/toolkit';

const foldersSlice = createSlice({
  name: 'folders',
  initialState: { sessionFolders: {} as Record<string, string[]> },
  reducers: {
    addFolder(state, action) {
      const folders = state.sessionFolders[action.payload.name] ?? [];
      if (!folders.includes(action.payload.folder)) {
        folders.push(action.payload.folder);
      }
      state.sessionFolders[action.payload.name] = folders;
    }
  }
});
export default foldersSlice.reducer;
const exportFunctions = {
  syncFunctions: foldersSlice.actions
};
export { exportFunctions };
