import { useParams } from 'react-router-dom';
import FormNavigation from '../../components/NavBar/FormNavigation';
import styles from './styles.module.scss';
import { useEffect, useState } from 'react';
import { DropdownField } from '../../components/Core';

import ReactECharts from 'echarts-for-react';
import StatCard, { keyTitleMap } from './StatCard';
import { UsersIcon } from '../../components/Icons';
import StatValue from './StatValue';
import useFeatheryRedux from '../../redux';
import useMetrics from './useMetrics';
import useDraftForm from '../../utils/useDraftForm';

const lineChartBaseOptions = {
  xAxis: { type: 'category' },
  yAxis: { type: 'value' },
  series: [
    {
      type: 'line',
      smooth: true,
      lineStyle: { color: '#e2626e' },
      itemStyle: { color: '#e2626e' }
    }
  ],
  tooltip: {
    trigger: 'axis'
  },
  grid: {
    left: 60,
    right: 0,
    top: 40,
    bottom: 20
  }
};

function getLineOptions(key: string, metrics: any) {
  const newOptions = JSON.parse(JSON.stringify(lineChartBaseOptions));
  newOptions.series[0].name = keyTitleMap[key];

  const overTime = metrics[key].over_time;
  newOptions.series[0].data = overTime.map(({ value }: any) => value);
  newOptions.xAxis.data = overTime.map(({ label }: any) => label);

  return newOptions;
}

function getStartTime(endTime: any, interval: string) {
  switch (interval) {
    case '5_min':
      endTime.setMinutes(endTime.getMinutes() - 5);
      return endTime;
    case '15_min':
      endTime.setMinutes(endTime.getMinutes() - 15);
      return endTime;
    case '30_min':
      endTime.setMinutes(endTime.getMinutes() - 30);
      return endTime;
    case '1_hour':
      endTime.setHours(endTime.getHours() - 1);
      return endTime;
    case '4_hour':
      endTime.setHours(endTime.getHours() - 4);
      return endTime;
    case '1_day':
      endTime.setDate(endTime.getDate() - 1);
      return endTime;
    case '1_week':
      endTime.setDate(endTime.getDate() - 7);
      return endTime;
    case '1_month':
      endTime.setMonth(endTime.getMonth() - 1);
      return endTime;
    default:
      return endTime;
  }
}

export default function FormAnalyticsPage() {
  const { formId } = useParams<{ formId: string }>();
  const [interval, setInterval] = useState('1_week');

  const { getPanelMetrics } = useFeatheryRedux();
  const { steps } = useDraftForm();

  useEffect(() => {
    const data: Record<string, string> = { panelId: formId };
    const endTime = new Date();
    data.endTime = endTime.toISOString();
    data.startTime = getStartTime(endTime, interval).toISOString();
    getPanelMetrics(data);
  }, [interval]);

  const metrics = useMetrics();

  return (
    <>
      <FormNavigation activeItem='analytics' />
      <div className={styles.analyticsPage}>
        <div className={styles.headerContainer}>
          <h1 className={styles.headerLabel}>Analytics</h1>
          <DropdownField
            className={styles.intervalToggle}
            selected={interval}
            onChange={(event: any) => setInterval(event.target.value)}
            options={[
              { value: '5_min', display: '5 minutes' },
              { value: '15_min', display: '15 minutes' },
              { value: '30_min', display: '30 minutes' },
              { value: '1_hour', display: '1 hour' },
              { value: '4_hour', display: '4 hours' },
              { value: '1_day', display: '1 day' },
              { value: '1_week', display: '1 week' },
              { value: '1_month', display: '1 month' }
            ]}
          />
        </div>
        <div className={styles.bodyContainer}>
          <div className={styles.cardContainer}>
            <StatCard
              statKey='new_submissions'
              Icon={UsersIcon}
              color='#0F985E'
            />
            <StatCard statKey='new_viewers' Icon={UsersIcon} color='#6A48E7' />
            <StatCard statKey='average_time' Icon={UsersIcon} color='#DF7BD5' />
            <StatCard
              statKey='average_completion_rate'
              Icon={UsersIcon}
              color='#F9726A'
            />
          </div>
          {[
            { key: 'new_submissions', showChart: true },
            { key: 'new_viewers', showChart: true },
            { key: 'average_time', showChart: false },
            { key: 'average_completion_rate', showChart: false }
          ].map(({ key, showChart }) => (
            <div key={key} className={styles.chartContainer}>
              <div className={styles.chartHeader}>
                <span className={styles.chartLabel}>{keyTitleMap[key]}</span>
                <StatValue statKey={key} />
              </div>
              {showChart && (
                <ReactECharts
                  option={getLineOptions(key, metrics)}
                  className={styles.chart}
                  notMerge
                  lazyUpdate
                />
              )}
            </div>
          ))}
          {Object.keys(steps).length > 0 && (
            <div className={styles.chartContainer}>
              <div className={styles.chartHeader}>
                <span className={styles.chartLabel}>Step Completion Rates</span>
              </div>
              <ul>
                {metrics.step_metrics.map((stepMetric: any) => (
                  <li key={stepMetric.step_id}>
                    {steps[stepMetric.step_id].key} :{' '}
                    {stepMetric.completion_rate}%
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
