// https://github.com/goliatone/gflattener/blob/master/src/flattener.js
export const flatten = (
  src: any,
  delimiter?: any,
  prop?: any,
  output?: any
) => {
  prop || (prop = '');
  output || (output = {});
  delimiter || (delimiter = '.');

  let isEmpty = false;

  if (Object(src) !== src) output[prop] = src;
  else if (Array.isArray(src)) {
    let l;
    for (let i = 0, l = src.length; i < l; i++) {
      flatten(src[i], delimiter, prop ? prop + delimiter + i : '' + i, output);
    }
    if (l === 0) output[prop] = [];
  } else {
    isEmpty = true;
    for (const p in src) {
      isEmpty = false;
      if (src !== src[p]) {
        flatten(src[p], delimiter, prop ? prop + delimiter + p : p, output);
      }
    }
    if (isEmpty) output[prop] = {};
  }

  return isEmpty === true ? {} : output;
};

export const toJSONPathStructure = (str: string, includePrefix = false) => {
  const path = str.split('.').reduce((acc: string, curr: string, index) => {
    if (curr.indexOf(' ') >= 0) {
      return `${acc}['${curr}']`;
    }

    if (!Number.isNaN(Number.parseFloat(curr))) {
      return index === 0 ? `.[${curr}]` : `${acc}[${curr}]`;
    }

    return `${acc}.${curr}`;
  }, '');

  if (includePrefix) {
    return `$${path}`;
  }

  return path;
};

export const parseJSONStructure = (structure: any, types: string[] = []) => {
  const flattened = flatten(structure);
  const formatted = Object.keys(flattened).reduce((acc, key: string) => {
    if (types.length > 0) {
      const jsonPathType = typeof flattened[key];

      if (!types.includes(jsonPathType)) {
        return acc;
      }
    }

    return {
      ...acc,
      [key]: true
    };
  }, {});

  return formatted;
};

export const toValidJSON = (str: string) => {
  const validJSONString = str.replace(/(\r\n|\n|\r)/gm, '');
  let validJSON = {};

  try {
    validJSON = JSON.parse(validJSONString);
  } catch (e) {
    console.error(e);
  }

  return validJSON;
};

export const reverseFlatObj = (obj: { [key: string]: string }) => {
  const reversed: { [key: string]: string } = {};

  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    reversed[value] = key;
  });

  return reversed;
};

export const mapRawAPIConnector = (connector: any) => {
  const _connector: any = {
    id: connector.id,
    name: connector.name,
    template: connector.template,
    url: connector.base_url,
    method: connector.request_method,
    headers: connector.headers,
    body: JSON.stringify(connector.request_body, null, 2),
    params: connector.request_parameters,
    tokens: connector.tokens,
    responseStructure: JSON.stringify(connector.response_structure, null, 2),
    responseMapping: connector.response_mapping
  };

  _connector.responseMapping = Object.entries(
    _connector.responseMapping
  ).reduce((acc, [key, value]: any) => {
    let newValue = value;

    if (newValue) {
      if (newValue.charAt(0) === '$') {
        newValue = newValue.slice(1);
      }

      if (newValue.charAt(0) === '.') {
        newValue = newValue.slice(1);
      }

      newValue = newValue
        .split('.')
        .filter((segment: string) => !!segment)
        .join('.')
        .replaceAll("['", '.')
        .replaceAll("']", '')
        .replaceAll('[', '.')
        .replaceAll(']', '');

      if (newValue.charAt(0) === '.') {
        newValue = newValue.slice(1);
      }
    }

    return {
      ...acc,
      [key]: newValue
    };
  }, {});

  return _connector;
};

export const mapAPIConnectorToRaw = (connector: any) => {
  const raw: any = {
    name: connector.name,
    base_url: connector.url,
    request_method: connector.method,
    headers: connector.headers,
    tokens: connector.tokens,
    template: connector.template,
    request_body: connector.body ? toValidJSON(connector.body) : {},
    request_parameters: connector.params,
    response_structure: connector.responseStructure
      ? toValidJSON(connector.responseStructure)
      : {},
    response_mapping: connector.responseMapping
  };

  if (connector.id) {
    raw.id = connector.id;
  }

  raw.response_mapping = Object.entries(raw.response_mapping).reduce(
    (acc, [key, value]) => {
      return {
        ...acc,
        [key]: toJSONPathStructure(value as string, true)
      };
    },
    {}
  );

  return raw;
};

export const searchForFields = (str: string, jsonString = false) => {
  if (!str) return [];

  const regex = jsonString ? /(?<=".*?{{).*?(?=}}.*?")/g : /(?<={{).*?(?=}})/g;
  const results = str.match(regex);

  if (results) return results.map((s) => s.trim());

  return [];
};
