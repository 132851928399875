import {
  Asset,
  DoubleStack,
  SingleStack,
  ThemesIcon,
  TripleStack,
  UndoIcon
} from '../../Icons';
import { memo, useRef } from 'react';

import styles from './styles.module.scss';
import { useGlobalMouseDownToggle } from '../util';
import classNames from 'classnames';
import { useAppSelector } from '../../../hooks';

function PropertyLabel({
  className,
  mode = '',
  label,
  themeOperations = {},
  centered = true,
  svgLabel = false,
  highlighted = false,
  indented = false,
  position = indented ? 'bottomLeft' : 'bottomRight',
  style = {}
}: {
  className?: string;
  mode?: string;
  label: string | JSX.Element;
  themeOperations?: any;
  centered?: boolean;
  svgLabel?: boolean;
  highlighted?: boolean;
  indented?: boolean;
  position?: string;
  style?: any;
}) {
  const labelRef = useRef(null);
  const [showMenu, setShowMenu] = useGlobalMouseDownToggle([labelRef]);
  const themeId = useAppSelector(({ formBuilder }) => formBuilder.theme?.id);
  const account = useAppSelector((state) => state.accounts.account);
  const themeEditAllowed =
    account.role === 'admin' || account.permission_edit_theme;
  const anyThemeOps =
    !!themeOperations.asset ||
    !!themeOperations.level1 ||
    !!themeOperations.level2 ||
    !!themeOperations.global;

  return (
    <div
      className={classNames(
        className,
        styles.propertyLabelContainer,
        indented && styles.indented,
        centered && styles.centered
      )}
      style={style}
    >
      {/* If the label is highlighted, make it interactive, otherwise just render the text */}
      {highlighted ? (
        <div
          ref={labelRef}
          className={classNames(
            styles.propertyLabel,
            styles.highlighted,
            svgLabel && styles.svgLabel
          )}
          onMouseDown={() => setShowMenu(true)}
        >
          {label}
          {showMenu && (
            <div className={`${styles.menu} ${styles[position]}`}>
              <div className={styles.menuSection}>
                <div
                  className={styles.menuItemStroke}
                  onMouseDown={(event) => {
                    event.stopPropagation();
                    setShowMenu(false);
                    themeOperations.instance.operation();
                  }}
                >
                  <UndoIcon width={20} height={20} />
                  {themeOperations.instance.label}
                </div>
              </div>
              {/* Only render these theme update options if we're in the builder */}
              {mode === 'builder' &&
                themeId &&
                anyThemeOps &&
                themeEditAllowed && (
                  <>
                    <div className={styles.menuSection}>
                      <div className={styles.menuSectionLabel}>
                        Apply style to
                      </div>
                      {themeOperations.asset && (
                        <div
                          className={styles.menuItem}
                          onMouseDown={(event) => {
                            event.stopPropagation();
                            setShowMenu(false);
                            themeOperations.asset.operation();
                          }}
                        >
                          <Asset />
                          <div>
                            All <strong>{themeOperations.asset.label}</strong>{' '}
                            assets
                          </div>
                        </div>
                      )}
                      {themeOperations.level1 && (
                        <div
                          className={styles.menuItem}
                          onMouseDown={(event) => {
                            event.stopPropagation();
                            setShowMenu(false);
                            themeOperations.level1.operation();
                          }}
                        >
                          <SingleStack />
                          <div>
                            All <strong>{themeOperations.level1.label}</strong>{' '}
                            fields
                          </div>
                        </div>
                      )}
                      {themeOperations.level2 && (
                        <div
                          className={styles.menuItem}
                          onMouseDown={(event) => {
                            event.stopPropagation();
                            setShowMenu(false);
                            themeOperations.level2.operation();
                          }}
                        >
                          <DoubleStack />
                          <div>
                            All <strong>{themeOperations.level2.label}</strong>
                          </div>
                        </div>
                      )}
                      {themeOperations.global && (
                        <div
                          className={styles.menuItem}
                          onMouseDown={(event) => {
                            event.stopPropagation();
                            setShowMenu(false);
                            themeOperations.global.operation();
                          }}
                        >
                          <TripleStack />
                          {themeOperations.global.label}
                        </div>
                      )}
                    </div>
                    <div className={styles.menuSection}>
                      <div
                        className={styles.menuItemStroke}
                        onMouseDown={(event) => {
                          event.stopPropagation();
                          // todo (jake): implement redirect confirmation modal
                          const tab = window.open(
                            `/themes/${themeId}`,
                            '_blank'
                          );
                          // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
                          tab.focus();
                        }}
                      >
                        <ThemesIcon width={20} height={20} />
                        Edit applied theme
                      </div>
                    </div>
                  </>
                )}
            </div>
          )}
        </div>
      ) : (
        <div className={styles.propertyLabel}>{label}</div>
      )}
    </div>
  );
}

export default memo(PropertyLabel);
