export default function TrashIcon({
  width = 32,
  height = 32,
  color = '#414859',
  ...props
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 17 18'
      stroke={color}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M11.5079 17H5.16275C4.17457 17 3.39443 16.2782 3.34242 15.2986L2.40625 3.85284H14.2644L13.3802 15.2986C13.2762 16.2782 12.4961 17 11.5079 17Z'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1 3.85284H15.6667'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.26953 1H10.2223'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.875 7.25562V13.1847'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.78906 7.25562V13.1847'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
