import styles from './styles.module.scss';
import {
  fetchRolloutCredentials,
  useRolloutToken
} from '../useRolloutCredentials';
import { ROLLOUT_BASE_URL } from '../../../FormIntegrations/RolloutSidebar/utils';
import {
  CredentialInput,
  RolloutConnectProvider
} from '@rollout/connect-react';
import { Spinner } from '../../../Core/Spinner';
import { useEffect } from 'react';

type RolloutCredentialPickerProps = {
  app: string;
  credentialKey: string | null;
  onChange: (credential: any, { previousCredential }?: any) => void;
  hideLabel?: boolean;
  error?: boolean;
  errorMessage?: string;
};

// Allows user to choose which integration account to use for their api connector
export function RolloutCredentialPicker({
  app,
  credentialKey,
  onChange,
  hideLabel,
  error,
  errorMessage
}: RolloutCredentialPickerProps) {
  const rolloutToken = useRolloutToken();

  const handleChange = async (newCredential: string | undefined) => {
    const credentials = await fetchRolloutCredentials(app, rolloutToken);
    if (!credentials) return;
    const previousCredential = credentialKey
      ? credentials.find((option) => option.credentialKey === credentialKey)
      : null;

    onChange(
      newCredential
        ? credentials.find((option) => option.credentialKey === newCredential)
        : null,
      previousCredential ? { previousCredential } : undefined
    );
  };

  // preload credential if there is already a credential key
  useEffect(() => {
    if (app && rolloutToken) {
      (async () => {
        const credentials = await fetchRolloutCredentials(app, rolloutToken);
        if (credentials?.length) {
          if (!credentialKey) {
            onChange(credentials[0]);
          } else {
            onChange(
              credentials.find(
                (credential) => credential.credentialKey === credentialKey
              ) ?? credentials[0]
            );
          }
        }
      })();
    }
  }, [rolloutToken]);

  const appDisplay = app.charAt(0).toUpperCase() + app.slice(1);

  return (
    <label className={styles.credential_picker}>
      {!hideLabel && appDisplay}
      {rolloutToken && (
        <RolloutConnectProvider
          token={rolloutToken}
          apiBaseUrl={ROLLOUT_BASE_URL}
        >
          <CredentialInput
            appKey={app}
            onCredentialSelected={handleChange}
            selectedCredentialKey={credentialKey || undefined}
          />
        </RolloutConnectProvider>
      )}
      {!rolloutToken && <Spinner />}
      {error && errorMessage && (
        <div className='tw-text-[var(--red-border)]'>{errorMessage}</div>
      )}
    </label>
  );
}
