export default function SignatureIcon({ color = '#414859' }) {
  return (
    <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.5897 35.3162L4 37.3846L6.06838 27.7949L27.2991 6.5641C29.3846 4.47863 32.7521 4.47863 34.8205 6.5641C36.906 8.64957 36.906 12.0171 34.8205 14.0855L13.5897 35.3162Z'
        stroke={color}
        strokeWidth='3'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M33.7265 15.5043C35.0598 16.8376 35.0598 19.0085 33.7265 20.3419L26.1709 27.8974'
        stroke={color}
        strokeWidth='3'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.10254 41.376C11.9316 42.6752 15.7607 42.5726 20.8547 38.4701C25.094 35.0513 27.2649 32.282 29.641 33.8205C32.5983 35.7179 32.4615 42.0085 36.4102 40.5213C38.3077 39.8034 40.2906 37.3077 44 36.7607'
        stroke={color}
        strokeWidth='3'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
