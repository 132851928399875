function Freshsales({ width = 128, height = 128 }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 652 652'
      enableBackground='new 0 0 652 652'
      xmlSpace='preserve'
    >
      <g>
        <path
          style={{ fill: '#4D4D4D' }}
          d='M176.7,261.6c-15.9,0-21.6,11.1-21.6,21.6v6.4h-3.2c-2.5,0-4.5,1.9-4.5,4.5s1.9,4.5,4.5,4.5h3.2v43.9
		c0,2.5,1.9,4.8,4.8,4.8s4.8-1.9,4.8-4.8v-44.2h10.2c2.5,0,4.5-1.9,4.5-4.5c0-2.5-1.9-4.5-4.5-4.5h-10.2v-6.4
		c0-5.7,2.2-12.7,12.4-12.7c2.5,0,4.5-1.9,4.5-4.1C181.2,263.5,179.2,261.6,176.7,261.6C177,261.6,176.7,261.6,176.7,261.6'
        />
        <path
          style={{ fill: '#4D4D4D' }}
          d='M213,288c-6.4-0.3-12.1,2.5-15.9,7.6v-2.5c0-2.5-1.9-4.8-4.8-4.8c-2.9,0-4.8,2.2-4.8,4.8v49
		c0,2.5,1.9,4.8,4.8,4.8c2.9,0,4.8-1.9,4.8-4.8v-27.1c0-11.5,6-18.1,15.9-18.1c2.5,0,4.5-1.9,4.5-4.5C217.4,289.9,215.2,288,213,288
		'
        />
        <path
          style={{ fill: '#4D4D4D' }}
          d='M269.3,312.2c0-5.4-1.6-10.8-4.5-15.3c-4.5-6-11.8-9.5-19.4-8.9c-16.2,0-25.5,10.8-25.5,29.6
		c0,19.4,9.2,29.9,26.7,29.9c7,0.3,14-2.2,19.4-7c1-0.6,1.6-1.9,1.6-3.2c0-2.2-1.9-4.1-4.1-4.1c-1,0-1.9,0.3-2.5,1
		c-3.8,3.2-8.9,5.1-14,5.1c-10.5,0-17.2-7-17.5-18.1h34.4C269.3,320.5,269.3,315.4,269.3,312.2 M245.8,296.3
		c9.5,0,14.3,5.4,14.6,15.9h-30.9C230.5,302,236.2,296.3,245.8,296.3'
        />
        <path
          style={{ fill: '#4D4D4D' }}
          d='M300.5,312.9c-7-2.5-12.7-4.5-12.7-9.5c0-5.7,6.4-6.4,9.9-6.4c4.8-0.3,9.5,1.6,12.7,4.8c1.6,1.6,4.5,1.6,6,0
		c1-1,1.3-1.9,1.3-3.2c0-1-0.3-1.9-1-2.9c-4.8-5.4-11.8-8.6-18.8-8c-11.8,0-19.1,6-19.1,15.3c0,11.5,10.2,15,19.1,18.1
		c6.7,2.2,12.7,4.1,12.7,9.2c0,6.7-7.6,8-12.1,8c-5.4,0.3-10.8-1.9-14.6-5.7c-1-1-1.9-1.3-3.2-1.6c-2.2,0-4.1,1.9-4.1,4.1
		c0,1,0.3,1.9,1,2.9c5.4,6.4,13,9.5,21,9.5c13.4,0,21.3-6.4,21.3-16.6C319.3,319.2,309.4,316,300.5,312.9'
        />
        <path
          style={{ fill: '#4D4D4D' }}
          d='M355.6,287.7c-5.7-0.3-11.5,2.2-15.3,6.7v-31.2c0-2.5-1.9-4.8-4.8-4.8c-2.5,0-4.5,1.9-4.5,4.8v78.9
		c0,2.5,1.9,4.8,4.5,4.8c2.5,0,4.8-1.9,4.8-4.8v-29.6c0-10.8,5.1-16.2,15.3-16.2c10.2,0,13.7,4.5,13.7,15.3v30.6
		c0,2.5,1.9,4.8,4.8,4.8c2.5,0,4.5-1.9,4.5-4.8V310C378.5,295.4,370.9,287.7,355.6,287.7'
        />
        <path
          style={{ fill: '#4D4D4D' }}
          d='M412.2,312.9c-7-2.5-12.7-4.5-12.7-9.5c0-5.7,6.4-6.4,9.9-6.4c4.8-0.3,9.5,1.6,12.7,4.8c1,1,1.9,1.3,3.2,1.3
		c2.2,0,4.1-1.9,4.1-4.1c0-1-0.3-1.9-1-2.9c-4.8-5.4-11.8-8.6-18.8-8c-11.8,0-19.1,6-19.1,15.3c0,11.5,10.2,15,19.1,18.1
		c6.7,2.2,12.7,4.1,12.7,9.2c0,6.7-7.6,8-12.1,8c-5.4,0.3-10.8-1.9-14.6-5.7c-1-1-1.9-1.3-3.2-1.6c-2.2,0-4.1,1.9-4.1,4.1
		c0,1,0.3,1.9,1,2.9c5.4,6,13,9.2,21.3,8.9c13.4,0,21.3-6.4,21.3-16.6C431,319.2,420.8,316,412.2,312.9'
        />
        <path
          style={{ fill: '#4D4D4D' }}
          d='M463.5,287.7c-12.4,0-18.5,6-20.7,9.9c-0.3,0.6-0.6,1.6-0.6,2.5c0,2.2,1.9,4.1,4.1,4.1c1.6,0,2.9-0.6,3.8-1.9
		c3.2-4.1,8.3-6.4,13.4-5.7c8.3,0,13.4,4.8,13.4,12.4v2.5h-16.2c-13.4,0-22,6.7-22,17.8c0,11.1,8,17.8,21.6,17.8
		c6.4,0.3,12.4-2.2,16.6-7v1.9c0,2.5,1.9,4.5,4.5,4.5c2.5,0,4.5-1.9,4.5-4.5V309C485.8,296,477.2,287.7,463.5,287.7 M461.3,338.6
		c-6,0-13-1.6-13-9.5c0-8,8-9.5,12.4-9.5h16.2v4.5C476.9,334.2,468.6,339,461.3,338.6'
        />
        <path
          style={{ fill: '#4D4D4D' }}
          d='M512.2,338c-1.9,0-4.1-0.6-4.1-6v-68.8c0-2.5-1.9-4.8-4.5-4.8c-2.5,0-4.5,2.2-4.5,4.8V332
		c0,9.5,4.8,15,13.4,15c2.5,0,4.5-1.9,4.5-4.5C516.6,339.9,514.7,338,512.2,338'
        />
        <path
          style={{ fill: '#4D4D4D' }}
          d='M571.1,312.2c0-5.4-1.6-10.8-4.5-15.3c-4.5-6-11.8-9.5-19.4-8.9c-16.2,0-25.5,10.8-25.5,29.6
		c0,19.4,9.2,29.9,26.7,29.9c7,0.3,14-2.2,19.4-7c1-0.6,1.6-1.9,1.6-3.2c0-2.2-1.9-4.1-4.1-4.1c-1,0-1.9,0.3-2.5,1
		c-3.8,3.2-8.9,5.1-14,5.1c-10.5,0-17.2-7-17.5-18.1h34.4C571.1,320.5,571.1,315.4,571.1,312.2 M547.2,296.3
		c9.5,0,14.3,5.4,14.6,15.9H531C531.9,302,537.7,296.3,547.2,296.3'
        />
        <path
          style={{ fill: '#4D4D4D' }}
          d='M602.3,312.9c-7-2.5-12.7-4.5-12.7-9.5c0-5.7,6.4-6.4,9.9-6.4c4.8-0.3,9.5,1.6,12.7,4.8c1,1,1.9,1.3,3.2,1.3
		c2.2,0,4.1-1.9,4.1-4.1c0-1-0.3-1.9-1-2.9c-4.8-5.4-11.8-8.6-18.8-8c-11.8,0-19.1,6-19.1,15.3c0,11.5,10.2,15,19.1,18.1
		c6.7,2.2,12.7,4.1,12.7,9.2c0,6.7-7.6,8-12.1,8c-5.4,0.3-10.8-1.9-14.6-5.7c-1-1-1.9-1.3-3.2-1.6c-2.2,0-4.1,1.9-4.1,4.1
		c0,1,0.3,1.9,1,2.9c5.4,6,13.1,9.2,21.3,8.9c13.4,0,21.3-6.4,21.3-16.6C621,319.2,611.2,316,602.3,312.9'
        />
        <path
          style={{ fill: '#FFA800' }}
          d='M76.1,277.2h40.7c7.6,0,13.7,6,13.7,13.7v40.7c0,29.9-24.2,54.1-54.1,54.1h-0.3c-29.9,0-54.1-24.2-54.1-54.1
		c-0.3-29.9,23.9-54.1,53.8-54.4H76.1z'
        />
        <path
          style={{ fill: '#FFFFFF' }}
          d='M76.8,304.3c6,0,10.8,4.8,10.8,10.8c0,6-4.8,10.8-10.8,10.8c-6,0-10.8-4.8-10.8-10.8
		C65.9,309.1,70.8,304.3,76.8,304.3'
        />
        <polygon
          style={{ fill: '#FFFFFF' }}
          points='52.9,329.4 71.7,348.2 71.7,358.7 80.9,358.7 80.9,348.2 99.7,329.4 	'
        />
      </g>
    </svg>
  );
}

export default Freshsales;
