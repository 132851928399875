import { memo, useEffect, useMemo, useRef, useState } from 'react';
/* eslint-disable react-hooks/exhaustive-deps */
import { ElevatedMenu, TextField } from '../../../Core';
import { Button } from '../../../Core/Button/button';
import {
  stopReactEventPropagation,
  useGlobalMouseDownToggle
} from '../../../Core/util';
import { CheckIcon, PlusIcon, RefreshIcon } from '../../../Icons';
import { elementHasOverrides } from '../../../../utils/themes';
import { useAssetUpdater } from './useAssetUpdater';
import classNames from 'classnames';
import styles from './styles.module.scss';
import testIds from '../../../../utils/testIds';

const AssetActionPrompt = ({ element, handleUpdates }: any) => {
  const assetPromptRef = useRef<HTMLDivElement>(null);
  const [assetPromptPosition, setAssetPromptPosition] = useState({});
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showAssetPromptModal, setShowAssetPromptModal] =
    useGlobalMouseDownToggle([assetPromptRef]);

  const assetNameInput = useRef();
  const [assetName, setAssetName] = useState('');
  const [assetNameError, setAssetNameError] = useState('');

  const { createAsset, updateAsset, formatAsset } = useAssetUpdater(
    element,
    handleUpdates
  );

  const elementSelected = Boolean(element) && !element.source_asset;
  const overriddenAssetElementSelected = useMemo(
    () =>
      Boolean(element) && element.source_asset && elementHasOverrides(element),
    [element]
  );

  const visible = useMemo(
    () =>
      elementSelected || overriddenAssetElementSelected || showSuccessMessage,
    [elementSelected, overriddenAssetElementSelected, showSuccessMessage]
  );

  useEffect(() => {
    if (showAssetPromptModal) {
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      assetNameInput.current.focus();
    }
  }, [showAssetPromptModal]);

  useEffect(() => {
    if (elementSelected) {
      setAssetName(element?.servar?.key ?? '');
    }
  }, [element?.servar?.key]);

  useEffect(() => {
    if (showSuccessMessage) {
      const timeout = setTimeout(() => {
        setShowSuccessMessage(false);
      }, 2000);

      return () => {
        setShowSuccessMessage(false);
        clearTimeout(timeout);
      };
    }
  }, [showSuccessMessage]);

  const handleCreateAsset = (event: any) => {
    event.preventDefault();

    try {
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      const newAsset = formatAsset(assetName);
      // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      createAsset(newAsset);
      setShowAssetPromptModal(false);
      setShowSuccessMessage(true);
      setAssetName('');
    } catch (err) {
      setAssetNameError((err as any).message);
    }
  };

  const handleUpdateAsset = (event: any) => {
    stopReactEventPropagation(event);
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    updateAsset();
    setShowSuccessMessage(true);
  };

  const handleRevealAssetPrompt = (event: any) => {
    stopReactEventPropagation(event);
    setShowAssetPromptModal(true);
    setAssetPromptPosition({ x: event.clientX, y: event.clientY });
  };

  return (
    <div
      className={classNames(styles.assetActionPromptContainer, {
        [styles.hidden]: !visible
      })}
    >
      <div
        className={classNames(styles.assetActionPrompt, styles.successMessage, {
          [styles.hidden]: !showSuccessMessage
        })}
      >
        <div className={styles.assetActionButton}>
          <div className={styles.assetActionIcon}>
            <CheckIcon width={14} height={14} />
          </div>
          <div className={styles.assetActionText}>Saved!</div>
        </div>
      </div>
      <div
        className={classNames(styles.assetActionPrompt, {
          [styles.hidden]: !elementSelected
        })}
      >
        <Button
          variant='gray'
          className={'tw-w-4/5'}
          data-testid={testIds.saveAssetActionButton}
          onClick={handleRevealAssetPrompt}
        >
          <div className={styles.assetActionIcon}>
            <PlusIcon width={14} height={14} />
          </div>
          <div className={styles.assetActionText}>Save as Asset</div>
        </Button>
      </div>
      <div
        className={classNames(styles.assetActionPrompt, {
          [styles.hidden]: !overriddenAssetElementSelected
        })}
      >
        <Button
          variant='gray'
          className={'tw-w-4/5'}
          onClick={handleUpdateAsset}
        >
          <div className={styles.assetActionIcon}>
            <RefreshIcon width={14} height={14} />
          </div>
          <div className={styles.assetActionText}>Update Asset</div>
        </Button>
      </div>
      <ElevatedMenu
        ref={assetPromptRef}
        show={showAssetPromptModal}
        position={assetPromptPosition as { x: number; y: number }}
        anchor='bottom_left'
      >
        <form className={styles.createAssetModal} onSubmit={handleCreateAsset}>
          <div className={styles.title}>New Asset</div>
          <TextField
            ref={assetNameInput}
            data-testid={testIds.assetNameInput}
            className={styles.assetName}
            placeholder='Asset Name'
            required
            value={assetName}
            onChange={setAssetName}
            onComplete={() => {
              setAssetNameError('');
            }}
          />
          {assetNameError && (
            <div className={styles.assetNameError}>{assetNameError}</div>
          )}
          <Button
            variant='outline'
            type='button'
            onClick={() => setShowAssetPromptModal(false)}
          >
            Cancel
          </Button>
          <Button type='submit' data-testid={testIds.setAssetNameButton}>
            Save
          </Button>
        </form>
      </ElevatedMenu>
    </div>
  );
};

export default memo(AssetActionPrompt);
