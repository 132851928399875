import * as Actions from './actions';
import type { Action } from './actions';
import type { RuleBuilderState } from './types';
import { RuleDSLManager } from './RuleDSL';

export const reducer = (
  state: RuleBuilderState,
  action: Action
): RuleBuilderState => {
  const { type, payload } = action;
  const manager = new RuleDSLManager(state.dsl);
  const newPreviousDSL = JSON.parse(JSON.stringify(manager.toJSON()));

  switch (type) {
    case Actions.ADD_BRANCH:
      manager.addBranch();
      break;

    case Actions.UPDATE_BRANCH:
      manager.updateBranch(payload.id, payload.updates);
      break;

    case Actions.REMOVE_BRANCH:
      manager.removeBranch(payload.id);
      break;

    case Actions.ADD_CLAUSE:
      manager.addClause(payload.branchId);
      break;

    case Actions.UPDATE_CLAUSE:
      manager.updateClause(payload.id, payload.updates);
      break;

    case Actions.REMOVE_CLAUSE:
      manager.removeClause(payload.id);
      break;

    case Actions.ADD_CONDITION_TO_CLAUSE:
      manager.addConditionToClause(payload.clauseId);
      break;

    case Actions.ADD_CONDITION_TO_CONDITION:
      manager.addConditionToCondition(payload.conditionId);
      break;

    case Actions.UPDATE_CONDITION:
      manager.updateCondition(payload.id, payload.updates);
      break;

    case Actions.REMOVE_CONDITION:
      manager.removeCondition(payload.id);
      break;

    case Actions.ADD_EXPRESSION_TO_CONDITION:
      manager.addExpressionToCondition(payload.conditionId);
      break;

    case Actions.ADD_EXPRESSION_TO_EXPRESSION:
      manager.addExpressionToExpression(payload.expressionId);
      break;

    case Actions.UPDATE_EXPRESSION:
      manager.updateExpression(payload.id, payload.updates);
      break;

    case Actions.REMOVE_EXPRESSION:
      manager.removeExpression(payload.id);
      break;

    case Actions.ADD_ACTION_TO_CLAUSE:
      manager.addActionToClause(payload.clauseId);
      break;

    case Actions.UPDATE_ACTION:
      manager.updateAction(payload.id, payload.updates);
      break;

    case Actions.REMOVE_ACTION:
      manager.removeAction(payload.id);
      break;

    case Actions.ADD_OPERAND_TO_EXPRESSION:
      manager.addOperandToExpression(payload.expressionId);
      break;

    case Actions.UPDATE_OPERAND:
      manager.updateOperand(payload.id, payload.updates);
      break;

    case Actions.REMOVE_OPERAND:
      manager.removeOperand(payload.id);
      break;

    case Actions.UPDATE_DSL:
      return {
        ...state,
        dsl: payload.dsl
      };

    case Actions.UPDATE_ORIGINAL_DSL:
      return {
        ...state,
        originalDSL: payload.dsl
      };

    case Actions.UPDATE_DSL_VALIDITY:
      return {
        ...state,
        valid: payload.valid
      };

    case Actions.UPDATE_ERRORS:
      return {
        ...state,
        errors: { ...payload.errors }
      };

    case Actions.UPDATE_IS_NEW:
      return {
        ...state,
        isNew: payload.isNew
      };

    case Actions.SET_API_SERVICES:
      return {
        ...state,
        apiServices: payload.apiServices
      };

    case Actions.SET_PREVIOUS_DSL:
      return {
        ...state,
        prevDSL: payload.dsl
      };

    default:
      return state;
  }

  return {
    ...state,
    dsl: manager.toJSON(),
    prevDSL: newPreviousDSL
  };
};
