export default function validateStepConnections(
  newData: any,
  prevStep: any,
  nextStep?: any
) {
  const newErrors: Record<string, string | boolean> = {};
  const seenRules = new Set();
  // Populate seenRules with those from other step connections if necessary
  if (nextStep) {
    prevStep.next_conditions
      .filter((condition: any) => condition.next_step !== nextStep.id)
      .forEach((c: any) => {
        const rule = [c.element_type, c.element_id, c.metadata];
        c.rules.forEach((r: any) =>
          rule.push(r.field_id, r.values, r.comparison)
        );
        seenRules.add(JSON.stringify(rule));
      });
  }
  newData.forEach((c: any, i: any) => {
    let hasInlineError = false;
    if (!c.next_step) {
      newErrors[`condition-step-${i}`] = true;
      hasInlineError = true;
    }
    if (!c.element_id && c.element_type !== 'step') {
      newErrors[`condition-target-${i}`] = true;
      hasInlineError = true;
    }
    let rule = [c.element_type, c.element_id, c.metadata];
    c.rules.forEach((r: any, j: any) => {
      if (!r.field_id) {
        newErrors[`condition-field-${i}-${j}`] = true;
        hasInlineError = true;
      }
    });

    if (!hasInlineError) {
      [...c.rules].sort(ruleSortFunction).forEach((r) => {
        rule.push(r.field_id, r.values, r.comparison);
      });
      // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'any[]'.
      rule = JSON.stringify(rule);
      if (seenRules.has(rule)) {
        newErrors[`condition-error-${i}`] =
          'This element already navigates somewhere. Make your existing connection conditional or choose a different element.';
      } else seenRules.add(rule);
    }
  });

  return newErrors;
}

const ruleSortFunction = (r1: any, r2: any) => {
  const r1Val = JSON.stringify([r1.field_id, r1.value, r1.comparison]);
  const r2Val = JSON.stringify([r2.field_id, r2.value, r2.comparison]);
  return r1Val.localeCompare(r2Val);
};
