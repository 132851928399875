function Algolia({ width = 75, height = 75 }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 150 150'
      width={width}
      height={height}
    >
      <g>
        <path fill='none' d='M0,0H150V150H0V0Z' />
      </g>
      <path
        fill='#003dff'
        d='M74.5,11.68c-34.63,0-62.86,27.92-63.36,62.43-.51,35.05,27.93,64.1,62.99,64.3,10.83,.06,21.25-2.58,30.51-7.62,.9-.49,1.04-1.73,.27-2.41l-5.93-5.26c-1.2-1.07-2.92-1.37-4.4-.74-6.46,2.75-13.49,4.15-20.73,4.07-28.33-.35-51.21-23.92-50.76-52.24,.45-27.97,23.34-50.58,51.41-50.58h51.41V115.01l-29.17-25.92c-.94-.84-2.39-.67-3.15,.33-4.68,6.2-12.31,10.05-20.78,9.47-11.75-.81-21.27-10.27-22.15-22.02-1.05-14.01,10.05-25.75,23.84-25.75,12.47,0,22.75,9.6,23.82,21.8,.1,1.09,.59,2.1,1.4,2.82l7.6,6.73c.86,.76,2.23,.3,2.44-.84,.55-2.93,.74-5.98,.53-9.11-1.22-17.84-15.68-32.2-33.52-33.29-20.46-1.26-37.57,14.75-38.12,34.81-.53,19.56,15.49,36.41,35.05,36.84,8.16,.18,15.73-2.39,21.85-6.84l38.11,33.79c1.63,1.45,4.21,.29,4.21-1.89V14.07c0-1.33-1.08-2.4-2.4-2.4h-60.97s0,0,0,0Z'
      />
    </svg>
  );
}

export default Algolia;
