import { DIRECTION, DIRECTION_ACTIONS } from '../constants';

export const isObject = (obj: any) => {
  return obj && typeof obj === 'object' && !Array.isArray(obj);
};

export const mergeDeep = (
  target: { [key: string]: any },
  ...sources: { [key: string]: any }[]
): { [key: string]: any } => {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else if (source[key] !== null) {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
};

export const normalizeDirection = (dir?: string) => {
  if (!dir) return null;
  if (Object.values(DIRECTION).includes(dir)) return dir;
  if (Object.keys(DIRECTION_ACTIONS).includes(dir)) {
    return (DIRECTION_ACTIONS as any)[dir];
  }

  return null;
};
