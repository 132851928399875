import { useMemo, useRef, useState } from 'react';
import { FieldSelector } from '../../../../../../../components/Modals/DecisionLogicModal/DecisionLogicSection';
import {
  LogicFieldSelectorModal,
  SelectFieldPayload
} from '../../FieldSelectorModal';
import { useRuleBuilderUpdater } from '../../../context';
import { RuleOperand } from '../../../context/RuleDSL';
import { RuleOperand as Operand } from '../../RuleOperand';
import { Expression } from './components/Expression';
import { FieldValue } from './components/FieldValue';
import { MULTI_SELECT_FIELDS } from '../../../../../../../components/SelectionPanel/elementEntries';
import classNames from 'classnames';
import useFieldKey from '../../../../../../../utils/useFieldKey';
import comparisonRuleStyles from '../../../../../../../components/Modals/DecisionLogicModal/styles.module.scss';
import styles from '../styles.module.scss';
import { RuleExpression } from '../../../context/RuleDSL';
import { useAppSelector } from '../../../../../../../hooks';
import {
  CloseIcon,
  EditIcon,
  TextFieldIcon,
  OpenOverflowIcon
} from '../../../../../../../components/Icons';
import { useGlobalMouseDownToggle } from '../../../../../../../components/Core/util';
import { ContextMenu } from '../../../../../../../components/Core';
import { useParams } from 'react-router-dom';

type SetFieldActionProps = {
  action: IRuleAction;
  mode?: RuleBuilderMode;
  removeAction?: (id: string) => void;
};

const EditMode = ({ action, removeAction }: SetFieldActionProps) => {
  const { updateAction, updateOperand } = useRuleBuilderUpdater((s) => ({
    updateAction: s.updateAction,
    updateOperand: s.updateOperand
  }));
  const servars = useAppSelector((state) => state.fields.servars);
  // map servar id to servar
  const servarMap = useMemo(() => {
    return (servars ?? []).reduce((acc, servar) => {
      acc[servar.id] = servar;
      return acc;
    }, {} as Record<string, any>);
  }, [servars]);

  const [selectedField, setSelectedField] = useState<Field | null>(null);
  const [showFieldSelector, setShowFieldSelector] = useState(false);
  const { formId } = useParams<{ formId: string }>();
  const getFieldKey = useFieldKey(!!formId);

  const { parameters } = action;
  const [field] = parameters;

  const contextMenuRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState({});
  const [showContextMenu, setShowContextMenu] = useGlobalMouseDownToggle([
    contextMenuRef
  ]);
  const revealContextMenu = (e: any) => {
    e?.preventDefault();
    setPosition({
      x: e.clientX,
      y: e.clientY
    });
    setShowContextMenu(true);
  };

  const handleSelectField = (payload: SelectFieldPayload) => {
    const servarType = servarMap[payload.selectId]?.type ?? '';
    if (field) {
      updateOperand(field.id, {
        type: 'field',
        value: payload.selectId,
        meta: {
          ...field.meta,
          field_type: payload.selectType,
          servar_type: servarType
        }
      });

      // Clear the set value if the field type is changed
      const value = MULTI_SELECT_FIELDS.includes(servarType) ? [] : '';
      if (action.parameters[1] && action.inputMode !== 'expression') {
        updateOperand(action.parameters[1].id, {
          type: 'value',
          value: value as any
        });
      }
    } else {
      const operand = new RuleOperand();

      operand.type = 'field';
      operand.value = payload.selectId;
      operand.meta = {
        field_type: payload.selectType,
        servar_type: servarType
      };

      updateAction(action.id, {
        parameters: [operand.toJSON()]
      });
    }
  };

  const handleExpressionChange = (expr: RuleExpression) => {
    updateAction(action.id, {
      parameters: [action.parameters[0], expr]
    });
  };
  const handleValueChange = (value: string) => {
    const operand = new RuleOperand();

    operand.type = 'value';
    operand.value = value;

    updateAction(action.id, {
      parameters: [action.parameters[0], operand.toJSON()]
    });
  };

  const ToggleIcon =
    'expression' === action.inputMode ? TextFieldIcon : EditIcon;
  const toggleTitle =
    'expression' === action.inputMode
      ? 'Switch to Value'
      : 'Switch to Expression';

  const contextMenuActions = [
    {
      title: toggleTitle,
      Icon: ToggleIcon,
      onMouseDown: () => {
        if (action.inputMode && action.inputMode === 'expression') {
          // Switch to IRuleOperand for the second parameter and
          // preserve any expression IRuleOperand in the second parameter
          const [field, exprOrOp] = action.parameters;
          let operand = new RuleOperand();
          if ('type' in exprOrOp && exprOrOp.type === 'value') {
            operand = new RuleOperand(exprOrOp);
          }
          updateAction(action.id, {
            inputMode: 'value',
            parameters: [field, operand.toJSON()]
          });
        } else {
          // Switch to IRuleExpression for the second parameter
          // preserve the IRuleOperand as the first expression parameter
          const [field, value] = action.parameters;
          const expr = new RuleExpression();
          expr.operands = [new RuleOperand(value as IRuleOperand)];
          updateAction(action.id, {
            inputMode: 'expression',
            parameters: [field, expr.toJSON()]
          });
        }
      }
    }
  ];
  if (removeAction)
    contextMenuActions.push({
      title: 'Remove',
      Icon: CloseIcon,
      onMouseDown: () => removeAction(action.id)
    });

  return (
    <>
      <LogicFieldSelectorModal
        show={showFieldSelector}
        setShow={(value: boolean) => setShowFieldSelector(value)}
        selectedField={selectedField}
        onSelect={handleSelectField}
        excludeServarTypes={[
          'signature',
          'file_upload',
          'matrix',
          'password',
          'payment_method',
          'hex_color',
          'date_selector'
        ]}
      />
      <FieldSelector
        fieldId={field?.value ?? ''}
        fieldType={field?.meta?.field_type ?? 'servar'}
        fieldKey={getFieldKey(field?.value ?? '')}
        className={classNames(
          comparisonRuleStyles.fieldSelector,
          styles.fieldSelector
        )}
        error={false}
        showFieldSelector={(
          id: any,
          type: any,
          ruleItemIndex: any,
          ruleValueIndex = null
        ) => {
          setSelectedField({
            id,
            type,
            ruleItemIndex,
            ruleValueIndex
          });
          setShowFieldSelector(true);
        }}
      />
      <span>to</span>
      <div className={styles.fieldWrapper}>
        {'expression' === action.inputMode ? (
          <Expression
            leftOperand={action.parameters[0]}
            expression={action.parameters[1] as IRuleExpression}
            onComplete={handleExpressionChange}
          />
        ) : (
          <FieldValue
            operand={action.parameters[0]}
            value={(action.parameters[1] as IRuleOperand).value ?? ''}
            onComplete={handleValueChange}
          />
        )}
      </div>
      <div className={styles.overflowMenu}>
        <OpenOverflowIcon
          height={28}
          width={28}
          onClick={(e: MouseEvent) => revealContextMenu(e)}
        />
      </div>
      {showContextMenu && (
        <ContextMenu
          ref={contextMenuRef}
          position={position as { x: number; y: number }}
          show={showContextMenu}
          close={() => setShowContextMenu(false)}
          actions={contextMenuActions}
        />
      )}
    </>
  );
};

const ViewMode = ({ action }: SetFieldActionProps) => {
  const [field, expression] = action.parameters;
  const exprText =
    'type' in expression
      ? new RuleOperand(expression).toText()
      : new RuleExpression(expression).toText();
  return (
    <>
      <Operand operand={field} />
      <span>to</span>
      <span>{exprText}</span>
    </>
  );
};

export const SetFieldAction = ({
  action,
  mode = 'view',
  removeAction
}: SetFieldActionProps) => {
  return (
    <div className={styles.actionContent}>
      {mode === 'view' ? (
        <ViewMode action={action} />
      ) : (
        <EditMode action={action} removeAction={removeAction} />
      )}
    </div>
  );
};
