import { useEffect, useMemo, useState } from 'react';
import { Unit } from '../../../../types/units';
import {
  HandleChangeFn,
  HandleLinkFn,
  DimensionUtils,
  DimensionInputParams,
  DimensionChangeEvent
} from './types';

export type { DimensionInputParams };

const getXByRatio = (r: number, y: number) =>
  Math.floor(r >= 1 ? y * r : y / r);

const getYByRatio = (r: number, x: number) =>
  Math.floor(r >= 1 ? x / r : x * r);

/**
 * This hook defines utilities to handle linked ratios between `x` and `y`
 *
 * @param {number} params.x - The dimension of the `x` axis (width)
 * @param {number} params.y - The dimension of the `y` axis (height)
 * @param {string} [params.xUnit] - (Optional) The unit of the `x` dimension
 * @param {string} [params.yUnit] - (Optional) The unit of the `y` dimension
 * @param {boolean} [params.handleUnits] - (Optional) Whether the utilities should forward unit changes to onChange handler
 * @param {boolean} [params.linkRatio] - (Optional) Whether the ratio of `x` and `y` are linked
 * @param {boolean} [params.linkByDefault] - (Optional) Whether the ratio of `x` and `y` should be linked by default
 *
 * @returns {DimensionUtils} A set of utilities to manage dimensions and locking ratio
 */
export const useDimensionInput = (
  params: DimensionInputParams
): DimensionUtils => {
  const {
    x,
    y,
    xUnit = 'px',
    yUnit = 'px',
    linkRatio = false,
    linkByDefault = false,
    handleUnits = false,
    onChange = () => {}
  } = params;

  const [state, setState] = useState({
    x: x,
    y: y,
    ratio: x / y,
    linked: linkByDefault
  });

  // If the unit changes to percentage, we must unlink
  useEffect(() => {
    if (xUnit === '%' || yUnit === '%') {
      setState((prevVal) => ({ ...prevVal, linked: false }));
    }
  }, [xUnit, yUnit]);

  const canRatioLock = useMemo(() => {
    return xUnit === 'px' && yUnit === 'px';
  }, [xUnit, yUnit]);

  const handleChange: HandleChangeFn = (field, _value, write = false) => {
    let value = _value;
    let unit: Unit | null = null;

    if (handleUnits) {
      value = typeof _value === 'number' ? _value : _value.value;
      unit =
        typeof _value === 'object'
          ? _value.unit
          : field === 'x'
          ? xUnit
          : yUnit;
    }

    setState((prevVal) => {
      const { linked } = prevVal;
      const newState = {
        ...prevVal,
        [field]: value
      };

      if (linked) {
        if (linkRatio) {
          const { ratio } = prevVal;

          if (field === 'x') newState.y = getYByRatio(ratio, newState.x);
          else if (field === 'y') newState.x = getXByRatio(ratio, newState.y);
        } else {
          Object.assign(newState, {
            x: value,
            y: value
          });
        }
      } else {
        newState.ratio = newState.x / newState.y; // Set the new ratio
      }

      if (write) {
        const payload: DimensionChangeEvent = {
          x: newState.x,
          y: newState.y
        };

        if (handleUnits && unit) {
          Object.assign(payload, {
            xUnit: field === 'x' ? unit : xUnit,
            yUnit: field === 'y' ? unit : yUnit
          });
        }

        onChange(payload);
      }

      return newState;
    });
  };

  const handleLink: HandleLinkFn = () => {
    setState((prevVal) => {
      const newState = { ...prevVal, linked: !prevVal.linked };

      // Set Y to X
      if (!prevVal.linked && !linkRatio) {
        Object.assign(newState, {
          ...newState,
          y: newState.x
        });

        onChange({
          x: newState.x,
          y: newState.x
        });
      }

      return newState;
    });
  };

  return {
    state,
    handleChange,
    handleLink,
    canRatioLock
  };
};
