export default function PasswordIcon() {
  return (
    <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M26.1218 14.5835H42.4362C43.9626 14.5835 45.2001 15.8225 45.2001 17.3509V33.0333C45.2001 34.5617 43.9626 35.8008 42.4362 35.8008H36.2161'
        stroke='#414859'
        strokeWidth='3'
        strokeLinecap='round'
      />
      <path
        d='M21.5094 40H6.90293C4.96805 40 3.3999 38.4713 3.3999 36.585V27.2151C3.3999 25.3288 4.96805 23.8 6.90293 23.8H21.4968C23.4317 23.8 24.9998 25.3288 24.9998 27.2151V36.585C25.0125 38.4713 23.4443 40 21.5094 40Z'
        stroke='#414859'
        strokeWidth='3'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.68 23.598V19.6535C20.68 15.9822 17.7755 13 14.2 13C10.6244 13 7.71997 15.9822 7.71997 19.6535V23.8'
        stroke='#414859'
        strokeWidth='3'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
