import { ChangeEvent } from 'react';
import { Col, Row } from 'react-bootstrap';
import { CollapsibleSection, DropdownField } from '../../../Core';

import { useFeatheryContext } from '../../../../hooks';

function TextPropertySection({ element, handlePropChange }: any) {
  // Element.properties sometimes missing
  // See: https://feathery-forms.sentry.io/issues/4912426964/?alert_rule_id=1124137&alert_type=issue&notification_uuid=d3521cff-2d67-4953-97a1-67c034c037a1&project=5280968&referrer=slack
  const { text_mode: textMode = 'text', text_source: textSource } =
    element.properties ?? {};

  const { selectableFeatheryDataItems } = useFeatheryContext();

  // If no selectableFeatheryDataItems then show nothing for this property
  if (Object.keys(selectableFeatheryDataItems).length > 0) {
    return (
      <CollapsibleSection title='Text Source'>
        <Row>
          <Col>
            <DropdownField
              title='Choose the data item to pull text from'
              selected={textMode === 'text' ? '' : textSource ?? ''}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const source = e.target.value;
                const mode = source ? 'data' : 'text';
                handlePropChange({
                  properties: {
                    text_source: source,
                    text_mode: mode
                  }
                });
              }}
              options={[
                { display: 'Edit text directly', value: '' },
                ...Object.entries(selectableFeatheryDataItems).map(
                  ([value, display]) => ({
                    value,
                    display: `Stripe: ${display}`
                  })
                )
              ]}
            />
          </Col>
        </Row>
      </CollapsibleSection>
    );
  } else return <></>;
}

export default TextPropertySection;
