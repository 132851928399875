import { Cache } from './Cache';
import { Gig } from './Gig';
import { ViewportType } from './viewports';

// Cache used to minimize the reconstruction of GIG
const cache = new Cache();

type CreateGigOptions = {
  replaceCache?: boolean;
  viewport?: ViewportType;
  theme?: any;
};

/**
 * Helper function that utilizes cache to optimize the creation of GIG. If GIG already exists with the same data,
 * it will return the cached version; otherwise, it will create a new version of GIG.
 * @param step - Step data to create GIG with
 * @param options.viewport - The current viewport to create GIG with
 * @param options.replaceCache - Whether to replace the current GIG in cache if it exists and is not the same
 * @param options.theme - The theme data attached to the panel
 * @returns {Gig}
 */
export const createGig = (
  step: any,
  { viewport, theme, replaceCache = true }: CreateGigOptions = {}
) => {
  const hash = JSON.stringify(step);

  const cached = cache.get(hash);
  if (cached) {
    if (viewport && cached.viewportName !== viewport) {
      cached.setViewport(viewport);
    }
    return cached;
  }

  const gig = new Gig(step, { viewport, theme, setViewports: true });

  const grid = gig.grid;
  const idMap: Record<string, number> = {};
  grid.children.forEach((child: any, index: number) => {
    idMap[child.id] = index;
  });

  grid.idMap = idMap;

  if (replaceCache) {
    cache.clear();
    cache.set(hash, gig);
  }

  (window as any).featheryGIG = gig; // For debugging purposes

  return gig;
};
