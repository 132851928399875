import { createContext } from 'use-context-selector';
import { RuleBuilderState, RuleBuilderUpdater } from './types';

export const initialState: RuleBuilderState = {
  originalDSL: { branches: [] },
  dsl: { branches: [] },
  prevDSL: { branches: [] },
  errors: {},
  apiServices: [],
  valid: true,
  isNew: false
};

export const initialUpdater: RuleBuilderUpdater = {
  addBranch: () => {},
  updateBranch: () => {},
  removeBranch: () => {},
  addClause: () => {},
  updateClause: () => {},
  removeClause: () => {},
  addConditionToClause: () => {},
  addConditionToCondition: () => {},
  updateCondition: () => {},
  removeCondition: () => {},
  addExpressionToCondition: () => {},
  addExpressionToExpression: () => {},
  updateExpression: () => {},
  removeExpression: () => {},
  addActionToClause: () => {},
  updateAction: () => {},
  removeAction: () => {},
  addOperandToExpression: () => {},
  updateOperand: () => {},
  removeOperand: () => {},
  updateDSL: () => {},
  updateOriginalDSL: () => {},
  updateDSLValidity: () => {},
  updateErrors: () => {},
  updateIsNew: () => {},
  setAPIServices: () => {},
  setPreviousDSL: () => {}
};

export const StateContext = createContext<RuleBuilderState>(initialState);
export const UpdaterContext = createContext<RuleBuilderUpdater>(initialUpdater);
