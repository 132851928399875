import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AIExtractionRun } from './types';
import { parseRawRunData } from './utils';
import { useAppSelector } from '../../hooks';
import AIExtractionPanel from './components/AIExtractionPanel';
import PDFViewer from './components/PDFViewer';
import ExtractionProvider from './context';
import Zoomable from './components/Zoomable';
import classNames from 'classnames';
import styles from './styles.module.scss';
import useFeatheryRedux from '../../redux';
import AINavigation from '../../components/NavBar/AINavigation';
import BackgroundControls from './components/BackgroundControls';

type URLParams = {
  extractionId: string;
  runId: string;
};

export default function AIExtractionRunPage() {
  const { runId } = useParams<URLParams>();

  const run = useAppSelector((state) => state.ai.runDetails);
  const [parsedRun, setParsedRun] = useState<AIExtractionRun | undefined>();

  const { getAIExtractionRun } = useFeatheryRedux();

  useEffect(() => {
    getAIExtractionRun({ runId });
  }, []);

  useEffect(() => {
    if (!run || !run.id || run.id !== runId) return;
    setParsedRun(parseRawRunData(run));
  }, [run]);

  return (
    <>
      <AINavigation activeItem='results' />
      {parsedRun ? (
        <ExtractionProvider>
          <BackgroundControls>
            <div
              className={classNames(
                'background-controls',
                styles.pageContainer
              )}
            >
              <div className={styles.pageSidebar}>
                <div className={styles.pageSidebarContent}>
                  <AIExtractionPanel run={parsedRun} />
                </div>
                <div className={styles.pageSidebarPlaceholder} />
              </div>
              <div
                className={classNames(styles.pageContent, styles.centerContent)}
              >
                <Zoomable defaultMarginTop={20}>
                  <PDFViewer run={parsedRun} />
                </Zoomable>
              </div>
            </div>
          </BackgroundControls>
        </ExtractionProvider>
      ) : null}
    </>
  );
}
