import { memo, useEffect, useRef } from 'react';
import {
  useExtractionState,
  useExtractionUpdater,
  usePDFState
} from '../../context';
import { AIExtractionEntryValue } from '../../types';
import { getValueId } from '../../utils';
import { X_SCALE } from './constants';
import AIFieldPopover from '../AIFieldPopover';
import classNames from 'classnames';
import styles from '../styles.module.scss';

type Props = {
  entry: AIExtractionEntryValue;
};

const DataLayerEntry = ({ entry }: Props) => {
  const { zoom } = usePDFState();
  const {
    selectedValueId,
    hoveredValueId,
    activeComponent,
    selectedQuestionId
  } = useExtractionState();
  const { setSelectedValueId, setHoveredValueId } = useExtractionUpdater();
  const entryRef = useRef<HTMLDivElement>(null);

  const valueId = getValueId(entry.id, entry.index);
  const isSelected = valueId === selectedValueId;
  const isHovered = valueId === hoveredValueId;
  const isAIGenerated = entry.xCoordinate >= 0;
  const isSecondaryHighlight =
    selectedValueId && entry.relatedEntries?.[selectedValueId];
  const isHidden =
    (selectedQuestionId && selectedQuestionId !== entry.questionGroupId) ||
    (!!selectedValueId &&
      !isSelected &&
      !isSecondaryHighlight &&
      (!hoveredValueId || hoveredValueId === selectedValueId));

  useEffect(() => {
    if (isSelected && activeComponent === 'panel') {
      entryRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [isSelected]);

  if (!entry.value || !isAIGenerated) {
    return null;
  }

  return (
    <div
      key={`${entry.id}-${entry.index}`}
      ref={entryRef}
      className={classNames(styles.extractionEntry, {
        [styles.hovered]: isHovered,
        [styles.selected]: isSelected || isSecondaryHighlight,
        [styles.grey]:
          selectedValueId && hoveredValueId
            ? !isHovered
            : !isSelected && !isHovered,
        [styles.hide]: isHidden
      })}
      style={{
        top: entry.yCoordinate * X_SCALE * zoom,
        left: entry.xCoordinate * X_SCALE * zoom,
        width: entry.width * X_SCALE * zoom + 2,
        height: entry.height * X_SCALE * zoom + 2
      }}
      onMouseEnter={() =>
        !isHidden &&
        !isSecondaryHighlight &&
        setHoveredValueId(valueId, 'canvas')
      }
      onMouseLeave={() =>
        !isHidden && !isSecondaryHighlight && setHoveredValueId(null)
      }
      onClick={() =>
        !isHidden && setSelectedValueId(isSelected ? null : valueId, 'canvas')
      }
    >
      <AIFieldPopover
        key={`${entry.id}-popover`}
        entryValue={entry}
        isHidden={isHidden}
      />
    </div>
  );
};

export default memo(DataLayerEntry);
