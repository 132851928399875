export const EDITOR_TABS = {
  PROPERTIES: 'properties',
  STYLES: 'styles',
  CELL: 'cell'
};

export const SELECTION_PANEL_TABS = {
  ELEMENTS: 'elements',
  FLOW: 'flow'
};
