import { RefObject } from 'react';
import { Cell } from '../GridInGrid/engine';

export interface CanvasElement {
  ref: RefObject<HTMLDivElement>;
  portalRef: RefObject<HTMLDivElement>;
  node: Cell;
  rect: DOMRect;
  key: string;
}

export interface CanvasRefsData {
  elementMap: { [key: string]: CanvasElement };
}

const _data: CanvasRefsData = {
  elementMap: {}
};

interface CanvasRefs {
  data: CanvasRefsData;
  getCanvasElement: (key: string) => CanvasElement;
  setCanvasElement: (key: string, canvasElement: CanvasElement) => void;
  removeCanvasElement: (key: string) => void;
  getAllCanvasElements: () => CanvasElement[];
  update: (canvasElement: CanvasElement) => void;
}

const CanvasRefs: CanvasRefs = {
  data: _data,
  getCanvasElement(key: string) {
    if (key === 'root') key = '';
    return this.data.elementMap[key];
  },
  setCanvasElement(key: string, canvasElement: CanvasElement) {
    this.data.elementMap[key] = canvasElement;
  },
  removeCanvasElement(key: string) {
    delete this.data.elementMap[key];
  },
  getAllCanvasElements(): CanvasElement[] {
    return Object.values(this.data.elementMap);
  },
  update(canvasElement: CanvasElement): void {
    const u = _data.elementMap[canvasElement.key];
    if (u) canvasElement.ref = u.ref;
    const rect = u.ref.current?.getBoundingClientRect();
    if (rect) canvasElement.rect = rect;
  }
};

export default CanvasRefs;
