export const PUBLISH_STATUS = {
  ACTIVE: 'active',
  LOADING: 'loading',
  AUTO_SAVING: 'auto-saving', // new - used to differentiate autosaving from publishing
  REJECTED: 'rejected',
  FULFILLED: 'fulfilled'
};

export const DRAFT_STATUS = {
  OPERATIONAL: 'operational', // normal, all edit operations (& statuses above) supported
  NOT_CONNECTED: 'not-connected',
  CONFLICT: 'conflict', // version is stale
  CONFLICT_IGNORE: 'conflict-ignore',
  ERROR: 'error' // non-recoberable autosave error
};

export const OPERATION = {
  CREATE: 'create',
  READ: 'read',
  UPDATE: 'updated',
  DESTROY: 'destroy'
};

export function addDefaultReducers(
  builder: any,
  actionCreator: any,
  operation: any
) {
  builder.addCase(actionCreator.pending, (state: any, action: any) => {
    state[action.meta.arg.type] = { operation, status: PUBLISH_STATUS.LOADING };
  });
  builder.addCase(actionCreator.rejected, (state: any, action: any) => {
    const {
      meta: { arg },
      error
    } = action;
    state[arg.type] = { operation, status: PUBLISH_STATUS.REJECTED, error };
  });
}

/**
 * A general purpose method that derives the appropriate error from the response
 * payload and throws it.
 * Presently, for an error, the backend sends back payload that is
 * an Array of objects, each with a code and a message.
 */
export function parseAndThrowError(
  responseErrorPayload: any,
  defaultErrorMsg: string
) {
  throw parseError(responseErrorPayload, defaultErrorMsg);
}

export function parseError(responseErrorPayload: any, defaultErrorMsg: string) {
  const error = new Error(defaultErrorMsg);
  if (Array.isArray(responseErrorPayload) && responseErrorPayload.length) {
    const payloadError = responseErrorPayload[0];
    if (
      typeof payloadError === 'object' &&
      'code' in payloadError &&
      'message' in payloadError
    ) {
      (error as any).code = payloadError.code;
      const message = payloadError.message;
      if (Array.isArray(message)) error.message = message[0];
      else error.message = message;
    }
  } else if (responseErrorPayload.message) {
    (error as any).code = responseErrorPayload.code;
    error.message = responseErrorPayload.message;
  } else if (typeof responseErrorPayload === 'object') {
    const vals = Object.values(responseErrorPayload);
    if (vals.length > 0) {
      const val = vals[0];
      if (Array.isArray(val)) error.message = val[0];
      else if (typeof val === 'string') error.message = val;
    }
  } else if (typeof responseErrorPayload === 'string') {
    error.message = responseErrorPayload;
  }
  return error;
}
