export default function ColorPickerOpener() {
  return (
    <svg viewBox='0 0 9 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.41421 8L7 8C7.55228 8 8 7.55228 8 7L8 1.41421C8 0.523308 6.92286 0.0771399 6.29289 0.707105L0.707107 6.29289C0.0771426 6.92286 0.523309 8 1.41421 8Z'
        stroke='#c3c3c3'
        fill='#e2e2e2'
        strokeWidth='0.1'
      />
    </svg>
  );
}
