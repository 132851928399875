export default function VerticalCenterIcon({ width = 16, height = 16 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.2499 7C9.69765 7 9.24994 7.44772 9.24994 8C9.24994 8.55228 9.69765 9 10.2499 9L10.2499 7ZM13.1666 9C13.7189 9 14.1666 8.55228 14.1666 8C14.1666 7.44772 13.7189 7 13.1666 7L13.1666 9ZM10.2499 9L13.1666 9L13.1666 7L10.2499 7L10.2499 9Z'
        fill='#414859'
      />
      <path
        d='M3.16663 7C2.61434 7 2.16663 7.44772 2.16663 8C2.16663 8.55228 2.61434 9 3.16663 9L3.16663 7ZM6.08329 9C6.63558 9 7.08329 8.55228 7.08329 8C7.08329 7.44772 6.63558 7 6.08329 7L6.08329 9ZM3.16663 9L6.08329 9L6.08329 7L3.16663 7L3.16663 9Z'
        fill='#414859'
      />
      <rect
        x='6.08331'
        y='12.1667'
        width='8.33333'
        height='4.16667'
        rx='1'
        transform='rotate(-90 6.08331 12.1667)'
        stroke='#414859'
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  );
}
