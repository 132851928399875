import {
  ChangeEvent,
  FC,
  memo,
  useLayoutEffect,
  useRef,
  useState
} from 'react';

import {
  EditIcon,
  OpenOverflowIcon,
  PlusIcon,
  TextFieldIcon,
  TrashIcon
} from '../../Icons';
import { OPERATOR_CODE } from '@feathery/react';
import {
  EQUAL_OPERATORS,
  FIELD_OPERATORS,
  getComparisonOperators,
  SELECTION_OPERATORS
} from '../../../utils/validation';
import { FieldValueType, UnresolvedComparisonRule, ValueType } from './';
import { ContextMenu, DropdownField, TextField } from '../../Core';
import { useGlobalMouseDownToggle } from '../../Core/util';
import useFieldKey from '../../../utils/useFieldKey';

import styles from './styles.module.scss';
import ruleStyles from './../../NavigationRules/styles.module.scss';
import classNames from 'classnames';
import { OPTION_FIELDS } from '../../SelectionPanel/elementEntries';
import {
  FEATHERY_CART_FIELD,
  FEATHERY_COLLABORATOR_FIELD
} from '../../../utils/constants';
import { useStripeActions } from '../../Panels/Sections/ClickActionSection/useStripeActions';
import { useAppSelector } from '../../../hooks';
import { useParams } from 'react-router-dom';
import { Button } from '../../Core/Button/button';

export interface FieldSelectorProps {
  fieldId: string;
  fieldType: string;
  fieldKey: string;
  locked?: boolean;
  className?: string;
  error: boolean;
  currentStepId?: string;
  valueIndex?: number | null;
  showFieldSelector: (
    selectedFieldId: string,
    selectedFieldType: '' | 'servar' | 'hidden',
    selectedRuleValueIndex: number | null
  ) => void;
  onFieldSelected?: (
    selectedFieldId: string,
    selectedFieldType: '' | 'servar' | 'hidden',
    selectedRuleValueIndex: number | null
  ) => void;
}

const ComparisonRule = ({
  rule,
  locked = false,
  showErrors = false,
  currentStepId,
  handleDelete,
  handleUpdates,
  showFieldSelector = () => {},
  onFieldSelected = () => {},
  FieldSelector,
  canDelete = true,
  classNames: _classNames = {}
}: {
  rule: UnresolvedComparisonRule;
  locked?: boolean;
  showErrors?: boolean;
  currentStepId?: string;
  handleDelete: () => void;
  handleUpdates: (newRule: UnresolvedComparisonRule) => void;
  FieldSelector: FC<FieldSelectorProps>;
  showFieldSelector?: (
    selectedFieldId: string,
    selectedFieldType: '' | 'servar' | 'hidden',
    selectedRuleValueIndex: number | null
  ) => void;
  onFieldSelected?: (
    selectedFieldId: string,
    selectedFieldType: '' | 'servar' | 'hidden',
    selectedRuleValueIndex: number | null
  ) => void;
  canDelete?: boolean;
  classNames?: { [key: string]: string };
}) => {
  const { formId } = useParams<{ formId?: string }>();
  const panel = useAppSelector((state) =>
    formId ? state.panels.panels[formId] : {}
  );
  const servars = useAppSelector((state) =>
    formId ? state.formBuilder.servars : state.fields.servars
  );

  const getFieldKey = useFieldKey(!!formId);

  //  the set of purchasable product ids found in SELECT_PRODUCT_TO_PURCHASE actions
  const { purchaseableProducts } = useStripeActions();

  // Implement dynamically sized comparison operator field (per spec/PRD)
  const ruleRef = useRef<HTMLDivElement>(null);
  const getOptionLabel = (
    value: string,
    options: { value: string; display: string }[]
  ): string => {
    const opt = options.find((o) => o.value === value);
    return opt ? opt.display : '';
  };
  useLayoutEffect(() => {
    if (ruleRef.current) {
      const ruleContainer = ruleRef.current.querySelector(
        '.operatorSelectorContainer'
      );
      if (ruleContainer) {
        const sizer = ruleContainer.querySelector(
          '.hiddenOperatorSizer'
        ) as HTMLElement;
        const select = ruleContainer.querySelector(
          '.operatorSelector'
        ) as HTMLSelectElement;
        const width = sizer.textContent ? sizer.offsetWidth + 50 : 100; // need a minimum width when nothing selected yet
        if (sizer && select) select.style.width = `${width}px`;
      }
    }
  });

  // State: Entering string values or fields on the RHS.
  // Look at any existing data (last item) to determine which mode
  const valueTypeIsField = (v: ValueType): v is FieldValueType =>
    typeof v === 'object' && 'field_id' in v;

  const dataIsFields =
    rule.values.length && valueTypeIsField(rule.values[rule.values.length - 1]);

  const toggleFieldEntryMode = () => {
    // Determine what type the selected value is (string or field) and toggle it
    const newFieldEntryMode = !valueTypeIsField(rule.values[selectedValueIdx]);

    const newRule: UnresolvedComparisonRule = { ...rule };
    if (newRule.values.length) {
      const values = [...newRule.values];
      if (newFieldEntryMode)
        values[selectedValueIdx] = {
          field_type: 'servar',
          field_id: null
        };
      else values[selectedValueIdx] = '';
      newRule.values = values;
    }
    handleUpdates(newRule);
    // Automatically open the field selector dialog
    if (newFieldEntryMode) showFieldSelector('', 'servar', selectedValueIdx);
  };
  const toggleIcon = ({
    width,
    height,
    color
  }: {
    width: number;
    height: number;
    color: string;
  }) =>
    // Determine what type the selected value is (string or field) and show the 'opposite' icon
    valueTypeIsField(rule.values[selectedValueIdx]) ? (
      <EditIcon color={color} width={width} height={height} />
    ) : (
      <TextFieldIcon color={color} />
    );

  // COMMON STUFF USED FOR BOTH CONTEXT MENUS
  const [position, setPosition] = useState({});
  // The context menu is relative to the rule div container so that it gets into that stacking context
  // and is visible above any containing dialog.  Therefore the position below is relative to the parent's container.

  function revealContextMenu(event: any, setShowMenuFn: (_: boolean) => void) {
    event.preventDefault();
    const parentRect = ruleRef.current?.getBoundingClientRect();
    setPosition({
      x: event.clientX - (parentRect ? parentRect.x : 0),
      y: event.clientY - (parentRect ? parentRect.y : 0)
    });
    setShowMenuFn(true);
  }

  // CONDITION CONTEXT MENU
  const ruleItemContextMenuRef = useRef<HTMLDivElement>(null);
  const [showContextMenu, setShowContextMenu] = useGlobalMouseDownToggle([
    ruleItemContextMenuRef
  ]);

  // CONDITION VALUE CONTEXT MENU
  const conditionValueContextMenuRef = useRef<HTMLDivElement>(null);
  const [showValueMenu, setShowValueMenu] = useGlobalMouseDownToggle([
    conditionValueContextMenuRef
  ]);
  const [selectedValueIdx, setSelectedValueIdx] = useState(0);
  const removeValue = () => {
    const newRule: UnresolvedComparisonRule = { ...rule };
    const values = [...newRule.values];
    values.splice(selectedValueIdx, 1); // remove it
    // If no more values, must have at least one empty value item
    if (!values.length) values.push('');
    newRule.values = values;
    handleUpdates(newRule);
  };

  const servar =
    rule.field_id && rule.field_type === 'servar'
      ? servars[rule.field_id]
      : null;
  const options = getComparisonOperators(servar).map((o: any) => {
    return { value: o.code, display: o.display };
  });
  // Only lock if the field has options
  const isReallyLocked = locked && rule.field_id && options.length;

  const fieldKey = getFieldKey(rule.field_id);
  return (
    <div ref={ruleRef} className={styles.comparisonRule}>
      <div className={styles.leftContainer}>
        <FieldSelector
          fieldId={rule.field_id ?? ''}
          fieldType={rule.field_type ?? 'servar'}
          fieldKey={fieldKey}
          locked={locked}
          error={showErrors && !rule.field_id}
          currentStepId={currentStepId}
          className={classNames(
            styles.fieldSelector,
            _classNames?.fieldSelector
          )}
          showFieldSelector={showFieldSelector}
          onFieldSelected={onFieldSelected}
        />
        {!isReallyLocked && canDelete && (
          <OpenOverflowIcon
            className={classNames(
              styles.overflowIcon,
              _classNames.overflowIcon
            )}
            color={rule.field_id ? '#fff' : undefined}
            onClick={(e: MouseEvent) =>
              revealContextMenu(e, setShowContextMenu)
            }
          />
        )}
      </div>
      <div className={styles.rightContainer}>
        <div
          className={classNames(
            styles.operatorSelectorContainer,
            'operatorSelectorContainer'
          )}
        >
          {/* Used to dynamically resize the dropdown below */}
          <span
            className={classNames(
              styles.hiddenOperatorSizer,
              'hiddenOperatorSizer'
            )}
          >
            {getOptionLabel(rule.comparison as string, options)}
          </span>

          <DropdownField
            selected={rule.comparison}
            placeholder='Select...'
            isInvalid={showErrors && !rule.comparison}
            options={options}
            onChange={(event: ChangeEvent) => {
              const newComparison = (event.target as any).value;
              // make sure there is at least one value field if infix
              const values = [...(rule.values.length ? rule.values : [''])];
              // if new opertor is single value, make sure only one value
              if (!FIELD_OPERATORS[newComparison as OPERATOR_CODE]?.multiValue)
                values.splice(1);

              handleUpdates({
                ...rule,
                values,
                comparison: newComparison
              });
            }}
            className={classNames(styles.operatorSelector, 'operatorSelector')}
          />
        </div>
        {rule.comparison &&
          FIELD_OPERATORS[rule.comparison]?.infix &&
          rule.values &&
          rule.values.map((val: any, idx: number) => {
            const handleChange = (newVal: string) => {
              const newValues = [...rule.values];
              newValues.splice(idx, 1, newVal);
              handleUpdates({
                ...rule,
                values: newValues
              });
            };
            const valueIsField = valueTypeIsField(val);
            let entry;
            const comparisonOp = rule.comparison ?? '';
            if (valueIsField) {
              entry = (
                <FieldSelector
                  fieldId={val.field_id ?? ''}
                  fieldType={val.field_type ?? 'servar'}
                  fieldKey={getFieldKey(val.field_id)}
                  className={classNames(
                    styles.valueField,
                    _classNames?.fieldSelector
                  )}
                  error={showErrors && !rule.field_id}
                  currentStepId={currentStepId}
                  valueIndex={idx}
                  showFieldSelector={showFieldSelector}
                  onFieldSelected={onFieldSelected}
                />
              );
            } else {
              if (
                (comparisonOp in
                  { ...EQUAL_OPERATORS, ...SELECTION_OPERATORS } &&
                  OPTION_FIELDS.includes(servar?.type)) ||
                (comparisonOp in { ...SELECTION_OPERATORS } &&
                  fieldKey === FEATHERY_CART_FIELD) ||
                (comparisonOp in { ...EQUAL_OPERATORS } &&
                  fieldKey === FEATHERY_COLLABORATOR_FIELD)
              ) {
                // Special case for options fields as well as
                // feathery.cart and feathery.collaborator fields.
                // Need to show a dropdown with the options that are appropriate.
                let selectableValues;
                if (fieldKey === FEATHERY_CART_FIELD)
                  selectableValues = Object.keys(purchaseableProducts);
                else if (fieldKey === FEATHERY_COLLABORATOR_FIELD)
                  selectableValues = panel.collaborator_template.map(
                    (collaborator: any) => collaborator.label
                  );
                else selectableValues = servar.metadata.options;

                const options = selectableValues.map((option: string) => {
                  const display =
                    fieldKey === FEATHERY_CART_FIELD
                      ? purchaseableProducts[option].name
                      : option;
                  return {
                    display,
                    value: option
                  };
                });
                if (!selectableValues.includes(val)) {
                  options.unshift({
                    display: val,
                    option: val,
                    disabled: true
                  });
                }
                entry = (
                  <DropdownField
                    autoFocus
                    hideCaret
                    className={classNames(
                      ruleStyles.ruleTextField,
                      styles.valueInput
                    )}
                    selected={val}
                    title={val}
                    options={options}
                    onChange={(event: any) => handleChange(event.target.value)}
                    triggerCleanUp
                  />
                );
              } else {
                entry = (
                  <TextField
                    autoFocus
                    className={classNames(
                      ruleStyles.ruleTextField,
                      styles.valueInput
                    )}
                    value={val}
                    title={val}
                    placeholder='value'
                    onComplete={handleChange}
                    triggerCleanUp
                  />
                );
              }
            }
            return (
              <div key={idx} className={styles.valueContainer}>
                {idx > 0 && 'or'}
                {entry}
                <OpenOverflowIcon
                  className={classNames(
                    styles.overflowIcon,
                    _classNames?.overflowIcon
                  )}
                  color={valueIsField && val.field_id ? '#fff' : undefined}
                  onClick={(e: MouseEvent) => {
                    setSelectedValueIdx(idx);
                    revealContextMenu(e, setShowValueMenu);
                  }}
                />
              </div>
            );
          })}
        {rule.comparison &&
          FIELD_OPERATORS[rule.comparison]?.infix &&
          FIELD_OPERATORS[rule.comparison].multiValue && (
            <Button
              variant='outline'
              className='!tw-rounded-full'
              size='icon'
              onClick={() => {
                handleUpdates({
                  ...rule,
                  values: [
                    ...rule.values,
                    dataIsFields
                      ? {
                          field_type: 'servar',
                          field_id: null
                        }
                      : ''
                  ]
                });
                // pop the field selector right away
                if (dataIsFields)
                  showFieldSelector(
                    '',
                    'servar',
                    rule.values.length // index of the value just added
                  );
              }}
            >
              <PlusIcon height={14} />
            </Button>
          )}
      </div>

      {/* Condition context menu */}
      {showContextMenu && canDelete && (
        <ContextMenu
          ref={ruleItemContextMenuRef}
          relativeParent={ruleRef.current as HTMLElement}
          position={position as { x: number; y: number }}
          show={showContextMenu}
          close={() => setShowContextMenu(false)}
          actions={[
            {
              onMouseDown: handleDelete,
              Icon: TrashIcon,
              title: 'Remove Condition'
            }
          ]}
        />
      )}

      {/* Condition Value (context) menu */}
      {showValueMenu && (
        <ContextMenu
          ref={conditionValueContextMenuRef}
          relativeParent={ruleRef.current as HTMLElement}
          position={position as { x: number; y: number }}
          show={showValueMenu}
          close={() => setShowValueMenu(false)}
          actions={[
            {
              onMouseDown: toggleFieldEntryMode,
              Icon: toggleIcon,
              title:
                // Determine what type the selected field is (string or field) and
                //   show appropriate text to indicate toggle
                valueTypeIsField(rule.values[selectedValueIdx])
                  ? 'Free Entry'
                  : 'Field Value'
            },
            {
              onMouseDown: removeValue,
              Icon: TrashIcon,
              title: rule.values.length > 1 ? 'Remove' : 'Clear'
            }
          ]}
        />
      )}
    </div>
  );
};

export default memo(ComparisonRule);
