const ACTIVATION_FORM_ID = '8U3FLd';
const SSO_FORM_ID = 'aqlzc9';

const UNDO_TYPES = {
  CONDITION: 'Navigation Rule Edit',
  STEP: 'Step Edit',
  SOME_STEPS: 'Some Steps Edit',
  THEME: 'Theme Edit',
  LOGIC_RULES: 'Logic Rules Edit',
  RULE_BUILDER: 'Rule Builder Edit'
};

const UNDO_TITLES = {
  BACKGROUND: 'Background Change',
  CONDITION: 'Navigation Rule Change',
  DELETE: 'Step Deletion',
  FIRST_CHECK: 'Starting Step Change',
  FORMAT: 'Step Format Change',
  BUTTON: 'Button Change',
  TEXT: 'Text Change',
  PROGRESS_BAR: 'Progress Bar Change',
  IMAGE: 'Image Change',
  VIDEO: 'Video Change',
  NEW_ELEMENT: 'New Element Added to Step',
  NEW_STEP: 'New Step',
  REPEAT_ROW: 'Repeat Row Change',
  SERVAR: 'Field Change',
  STEP_ID: 'Step ID Change',
  THEME: 'Theme Change',
  CELL: 'Container Change',
  LOGIC_RULES: 'Logic Rules Change',
  NEW_LOGIC_RULE: 'New Locic Rule',
  DELETE_LOGIC_RULE: 'Delete Logic Rule',
  RULE_BUILDER: 'Rule Builder Change'
};

export type AxisDirection = 'row' | 'col';
const AXIS: { [key: string]: AxisDirection } = {
  COL: 'col',
  ROW: 'row'
};

export type SizeUnit = 'fit' | 'fill' | 'px' | '%';
const SIZE_UNITS: { [key: string]: SizeUnit } = {
  FIT: 'fit',
  PX: 'px',
  FILL: 'fill',
  PERCENTAGE: '%'
};

export const RESERVED_FEATHERY_PREFIX = 'feathery.';
export const FEATHERY_CART_FIELD = 'feathery.cart';
export const FEATHERY_CART_TOTAL_FIELD = 'feathery.cart.total';
export const FEATHERY_COLLABORATOR_FIELD = 'feathery.collaborator';

export {
  ACTIVATION_FORM_ID,
  SSO_FORM_ID,
  UNDO_TYPES,
  UNDO_TITLES,
  SIZE_UNITS,
  AXIS
};
