import { memo, useEffect, useMemo, useRef, useState } from 'react';
import styles from './styles.module.scss';
import GridInGridContainer from './GridInGrid';
import {
  isFill,
  isFit,
  isFixed,
  isPx,
  NullCell,
  parseValue,
  Viewport
} from './GridInGrid/engine';
import classNames from 'classnames';
import { useAppSelector } from '../../hooks';
import Keybinds from '../Keybinds';
import useViewport from '../../hooks/useViewport';
import ControlLayer from './Controls/ControlLayer';
import testIds from '../../utils/testIds';
import useFeatheryRedux from '../../redux';
import useSize from '../../hooks/useSize';

export const editorCanvasContainerId = 'editor-canvas-container';
export const editorCanvasWrapperId = 'editor-canvas-wrapper';
export const editorCanvasId = 'editor-canvas-actual';

function RenderedStepContainer() {
  const gigWrapperRef = useRef(null);
  const editorCanvasWrapperRef = useRef(null);
  const [gutterStyles, setGutterStyles] = useState<Record<string, string>>({});

  const workingSteps: any = useAppSelector(
    (state) => state.formBuilder.workingSteps
  );
  const activeStepId: string = useAppSelector(
    (state) => state.formBuilder.activeStepId
  );
  const { viewport } = useViewport();

  const {
    formBuilder: { gigSetPosition, wipeFocus }
  } = useFeatheryRedux();

  const currentStep = workingSteps[activeStepId];
  const rootSubgrid = useMemo(() => {
    if (currentStep) {
      return currentStep.subgrids.find((s: any) => s.position.length === 0);
    }

    return new NullCell();
  }, [currentStep]);

  const isDesktop = viewport === Viewport.Desktop;

  const rootWidth =
    rootSubgrid[isDesktop ? 'width' : 'mobile_width'] ?? rootSubgrid.width;
  const rootHeight =
    rootSubgrid[isDesktop ? 'height' : 'mobile_height'] ?? rootSubgrid.height;

  const gigWrapperSize = useSize(gigWrapperRef);
  const editorCanvasWrapperSize = useSize(editorCanvasWrapperRef);

  useEffect(() => {
    const gigWrapperHeight = gigWrapperSize?.height ?? 0;
    const editorCanvasWrapperHeight = editorCanvasWrapperSize?.height ?? 0;
    if (gigWrapperHeight === 0 || editorCanvasWrapperHeight === 0) return;

    const gutterStyles: Record<string, string> = {};
    if (gigWrapperHeight > editorCanvasWrapperHeight) {
      gutterStyles.height = 'calc(100% - 20px)';
      gutterStyles.paddingBottom = '13px';
    } else if (['fill', 'fit'].includes(rootHeight)) {
      gutterStyles.height = '100%';
      gutterStyles.paddingBottom = '0';
    } else {
      // we don't need to handle the case where the form is shorter than the
      // available space - the background color 'becomes' the gutter
    }
    setGutterStyles(gutterStyles);
  }, [gigWrapperSize, editorCanvasWrapperSize, rootHeight]);

  return (
    <div
      data-testid={testIds.editorCanvasContainer}
      id={editorCanvasContainerId}
      className={classNames({
        [styles.editorCanvasContainer]: true,
        [styles.editorCanvasFitContent]: viewport !== 'unconstrained'
      })}
      style={{ height: gutterStyles.height }}
      onMouseDown={(e: any) => {
        if (
          [
            'control-layer-drop',
            'editor-canvas-container',
            'editor-canvas-wrapper'
          ].includes(e.target.id)
        ) {
          wipeFocus();
          gigSetPosition();
        }
      }}
    >
      <ControlLayer />
      <div className={styles.canvasContainer}>
        <div
          ref={editorCanvasWrapperRef}
          id={editorCanvasWrapperId}
          className={styles.editorCanvasWrapper}
        >
          <div
            data-testid={testIds.editorCanvas}
            id={editorCanvasId}
            className={classNames({
              [styles.editorCanvas]: true,
              [styles[viewport]]: true,
              [styles.wFit]: isFit(rootWidth),
              [styles.wFill]: isFill(rootWidth),
              [styles.hFit]: isFit(rootHeight),
              [styles.hFill]: isFill(rootHeight)
            })}
            style={{
              width: isFixed(rootWidth) ? parseValue(rootWidth) : undefined,
              height: isPx(rootHeight) ? parseValue(rootHeight) + 40 : undefined
            }}
          >
            <div
              ref={gigWrapperRef}
              className={styles.gigWrapper}
              style={{
                paddingBottom: gutterStyles.paddingBottom
              }}
            >
              <Keybinds />
              <GridInGridContainer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(RenderedStepContainer);
