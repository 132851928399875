import { CustomDragOptions } from '..';
import useFeatheryRedux from '../../../redux';
import useCustomDragLayer from './useCustomDragLayer';
import fillableFieldStyles from '../../../pages/DocumentEditorPage/styles.module.scss';
import { DOCUMENT_FIELD_DEFAULT_SIZE } from '../../../pages/DocumentEditorPage/useFieldDrop';
import classNames from 'classnames';

export const DocumentFieldIdentifier = 'document-field';

export enum DocumentFieldDragOperation {
  New = 'new',
  Move = 'move'
}
export type DocumentFieldDragOperationType =
  | DocumentFieldDragOperation.New
  | DocumentFieldDragOperation.Move;

type FieldDragOpts = {
  operation: DocumentFieldDragOperationType;
  type: string;
  label: any;
  opts?: CustomDragOptions;
  id?: string;
};

const useDocumentFieldDrag = (getArgs: () => FieldDragOpts) => {
  const {
    formBuilder: { updateDrag }
  } = useFeatheryRedux();

  const args = getArgs();
  const [dragState, dragRef] = useCustomDragLayer(
    () => ({
      type: DocumentFieldIdentifier,
      renderer: () => {
        return (
          <div
            className={classNames(
              fillableFieldStyles.fillableField,
              fillableFieldStyles.selected,
              fillableFieldStyles.dragging,
              args.opts?.preview?.className
            )}
            style={{
              width: `${
                args.opts?.preview?.width ?? DOCUMENT_FIELD_DEFAULT_SIZE.width
              }px`,
              height: `${
                args.opts?.preview?.height ?? DOCUMENT_FIELD_DEFAULT_SIZE.height
              }px`
            }}
          >
            {args.label}
          </div>
        );
      },
      onStart: () => {
        updateDrag(true);

        return {
          operation: args.operation,
          type: args.type,
          label: args.label,
          id: args.id
        };
      },
      onEnd: () => {
        updateDrag(false);
        if (args && args.opts && args.opts.onEnd) args.opts.onEnd();
      },
      opts: args.opts
    }),
    []
  );

  return [dragState, dragRef];
};

export default useDocumentFieldDrag;
