function DoubleStack() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.3616 6.08758C11.4854 6.02989 11.6203 6 11.7568 6C11.8934 6 12.0282 6.02989 12.152 6.08758L19.3503 9.45058C19.3988 9.47317 19.4397 9.50914 19.4684 9.55424C19.497 9.59935 19.5122 9.65171 19.512 9.70514C19.5119 9.75858 19.4965 9.81086 19.4676 9.85581C19.4387 9.90077 19.3976 9.93652 19.349 9.95886L12.0941 13.2966C11.9881 13.3454 11.8728 13.3706 11.7561 13.3706C11.6395 13.3706 11.5242 13.3454 11.4182 13.2966L4.16395 9.95886C4.11518 9.93672 4.0738 9.90104 4.04473 9.85606C4.01567 9.81108 4.00014 9.7587 4 9.70514C3.99986 9.65159 4.01511 9.59913 4.04394 9.55399C4.07277 9.50886 4.11396 9.47297 4.16261 9.45058L11.3616 6.08758ZM19.351 13.7556C19.3996 13.7781 19.4408 13.814 19.4696 13.8592C19.4983 13.9044 19.5135 13.9568 19.5133 14.0104C19.5131 14.064 19.4975 14.1163 19.4684 14.1613C19.4392 14.2062 19.3978 14.2418 19.349 14.2639L12.0948 17.6016C11.9888 17.6504 11.8735 17.6757 11.7568 17.6757C11.6401 17.6757 11.5248 17.6504 11.4189 17.6016L4.16395 14.2639C4.11518 14.2418 4.0738 14.2061 4.04473 14.1611C4.01567 14.1161 4.00014 14.0637 4 14.0102C3.99986 13.9566 4.01511 13.9042 4.04394 13.859C4.07277 13.8139 4.11396 13.778 4.16261 13.7556L5.64952 13.0611C5.75601 13.0113 5.87206 12.9854 5.98959 12.985C6.10713 12.9847 6.22333 13.0099 6.33011 13.0591L11.4189 15.4009C11.6331 15.4993 11.8806 15.4993 12.0948 15.4009L17.1835 13.0591C17.3991 12.9599 17.6486 12.9606 17.8641 13.0611L19.351 13.7556Z'
        fill='white'
      />
    </svg>
  );
}

export default DoubleStack;
