import { useEffect, useState } from 'react';
import { Cell, Gig } from '../engine';

/**
 * Determines when each Cell in the Gig should render. It compares the cell hashes
 * of the current Gig instance with the previous ones. Any changes in the hash cause
 * the Cell and its parents to rerender. This helps with performance in the editor,
 * because previously, every cell would render whenever there was a new Gig instance.
 * Now only the changed cells render.
 *
 * @param gig The current gig instance (from useGig).
 *
 * @returns {Record<string, string>} renderController.hashes
 *          the current Gig's childrens hashes keyed by their cell id
 *
 * @returns {Record<string, number>} renderController.triggerRender
 *          a counter for triggering rerenders. Each time a change is detected the
 *          counter is incremented. The Cell component uses the counter to memo the
 *          component.
 */
export default function useRenderController(gig: Gig | null) {
  const [hashes, setHashes] = useState<Record<string, string>>();
  const [triggerRender, setTriggerRender] = useState<Record<string, number>>();

  // Anytime Gig is recreated, loop through its children and compare to previous hashes
  useEffect(() => {
    if (gig && gig.grid.children.length > 0) {
      const newHashes: Record<string, string> = {};
      const newTrigger = { ...triggerRender };
      gig.grid.children.forEach((cell: Cell) => {
        // Hash a cell, add it to hashes, and compare it to previous cell hash
        const cellHash = cell.toStateHash();
        newHashes[cell.id] = cellHash;
        if (hashes?.[cell.id] !== cellHash) {
          // Cell has changed, so trigger update to cell and all parents
          // Traverses up the Gig tree structure using the cell's position
          // Removing the last position will always point to the cell's parent
          const positionArray = [...cell.position]; // root is [], each index in array represents level of nesting
          while (positionArray.length > 0) {
            const positionString = positionArray.join(',');
            const parentCellUuid = gig.grid.positionMap[positionString];
            const parentIndex = gig.grid.map[parentCellUuid];
            const parent = gig.grid.children[parentIndex];

            if (parent) {
              newTrigger[parent.id] = (newTrigger[parent.id] ?? 0) + 1;
            }
            // remove last part of positionId
            //
            // we made a copy of the cell position, so we can
            // modify the array as we need
            positionArray.pop();
          }
          newTrigger[gig.grid.children[0].id] =
            (newTrigger[gig.grid.children[0].id] ?? 0) + 1;
        }
      });
      setHashes(newHashes);
      setTriggerRender(newTrigger);
    }
  }, [gig]);

  return { hashes, triggerRender };
}
