import { FilterWidget } from '.';

import { COLLABORATOR_FILTER_ID } from './constants';
import { useAppSelector } from '../../hooks';
import { DropdownMultiField } from '../Core';

// Collaborator filter custom widget
const CollaboratorFilter: FilterWidget = ({
  paramName,
  paramValue,
  onChange
}) => {
  const org = useAppSelector((state) => state.accounts.organization);
  const account = useAppSelector((state) => state.accounts.account);

  // Get all the emails and groups selectable for options
  const emailsAndGroups = new Set<string>();
  if (org) {
    org.team_accounts.forEach((account: any) => {
      if (account.email) emailsAndGroups.add(account.email.toLowerCase());
    });
    org.all_user_groups.forEach((group: any) => {
      emailsAndGroups.add(group.name);
    });
  }
  // add in any previously selected emails/groups that may have been created on the fly
  paramValue.split(',').forEach((emailOrGroup) => {
    emailsAndGroups.add(emailOrGroup);
  });

  // sort the emailsAndGroups
  const sortedEmailsAndGroups = Array.from(emailsAndGroups).sort((a, b) =>
    a.toLowerCase().localeCompare(b.toLowerCase())
  );
  // convert to options with "me" as the first option
  const emailsAndGroupsOptions = [
    {
      value: account.email,
      label: 'Me'
    },
    ...sortedEmailsAndGroups
      .filter((emailOrGroup) => emailOrGroup && emailOrGroup !== account.email)
      .map((emailOrGroup) => ({
        value: emailOrGroup,
        label: emailOrGroup
      }))
  ];

  return (
    <DropdownMultiField
      selected={paramValue ? paramValue.split(',') : []}
      options={emailsAndGroupsOptions}
      creatable
      formatCreateLabel={(inputValue: string) => `Add "${inputValue}"`}
      isClearable={false} // causing problems - dialog closes without saving
      onChange={(items: { label: string; value: string }[]) => {
        const emails = items.map((item) => item.value).join(',');
        const emailDisplay = items.map((item) => item.label).join(', ');
        const error = !emails
          ? 'At least one email or user group is required'
          : '';
        onChange(
          COLLABORATOR_FILTER_ID,
          'collaborators',
          emails,
          'Collaborators: ' + emailDisplay,
          error
        );
      }}
    />
  );
};

export default CollaboratorFilter;
