import { useMemo, useState } from 'react';

import HeaderFilterResultsTable from '../components/HeaderFilterResultsTable';
import { ABTestEditModal } from '../components/Modals';
import { UsageEnforcement } from '../components/PricingPlans';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { useAppSelector } from '../hooks';

export default function ABTestPage() {
  const abTestsData = useAppSelector((state) => state.abTests);

  const [mode, setMode] = useState('');
  const [selectedABTest, setSelectedABTest] = useState(null);

  const formatRender = (data: any) => {
    return data.map((d: any) => {
      let winner = d.winner;
      switch (d.winner) {
        case 'variant_a':
          winner = d.variant_a.key;
          break;
        case 'variant_b':
          winner = d.variant_b.key;
          break;
        case 'active':
          winner = 'Still Running';
          break;
        default:
          break;
      }

      return {
        ...d,
        winner,
        variant_a_key: d.variant_a.key,
        variant_b_key: d.variant_b.key
      };
    });
  };
  const dataToRender = useMemo(
    () => formatRender(abTestsData.abTests),
    [abTestsData.abTests]
  );
  return (
    <>
      <div className={styles.pageHeadRow}>
        <h1 className={classNames('page-head', styles.pageHeadTitle)}>
          A/B Tests
        </h1>
        <UsageEnforcement />
      </div>
      {abTestsData.abTests && (
        <>
          <HeaderFilterResultsTable
            data={dataToRender}
            columns={[
              { key: 'key', name: 'ID' },
              { key: 'variant_a_key', name: 'Form Variant A' },
              { key: 'variant_b_key', name: 'Form Variant B' },
              { key: 'winner', name: 'Winner' }
            ]}
            name='A/B Test'
            useSearch
            onCreate={() => setMode('create')}
            onSelect={(test: any) => {
              setMode('edit');
              setSelectedABTest(test);
            }}
          />
          <ABTestEditModal
            mode={mode}
            abTest={abTestsData.abTests.find(
              (s: any) => s.id === (selectedABTest as any)?.id
            )}
            isOpen={mode !== ''}
            close={() => setMode('')}
          />
        </>
      )}
    </>
  );
}
