function SimpleEmailService({ width = 64, height = 64 }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 70 70'
    >
      <path
        fill='#B8B58A'
        d='M38.169,42.115c-7.192-4.712-11.996-7.859-15.218-9.971c-2.526,3.004-6.309,4.916-10.539,4.916  c-0.939,0-1.856-0.097-2.743-0.275c0,22.563,0,22.58,0,22.58c57.75,0,57.75,0,57.75,0c0-36.75,0-36.75,0-36.75L38.169,42.115z'
      />
      <path
        fill='#B8B58A'
        d='M26.109,21.865c0.048,0.469,0.072,0.943,0.072,1.425c0,2.749-0.813,5.304-2.201,7.453  c14.185,9.12,14.188,9.122,14.188,9.122l27-18C43.518,21.865,32.116,21.865,26.109,21.865z'
      />
      <path
        fill='#B7B68B'
        d='M22.134,17.329c-1.528-2.842-4.564-4.381-7.418-4.817c-2.8-0.531-6.143-0.06-8.538,1.795  c-4.915,3.573-6.104,10.991-2.617,15.86c3.309,5.09,10.683,6.233,15.545,3.133l0.021-0.012c0.399-0.226,0.542-0.728,0.319-1.127  c-0.224-0.399-0.729-0.542-1.126-0.319l-0.022,0.014c-4.376,2.634-10.45,1.477-13.132-2.78c-2.829-4.047-1.653-10.228,2.317-12.966  c3.723-2.738,10.365-1.512,12.443,2.415c1.047,1.923,0.963,4.643,0.172,6.739c-0.738,1.903-2.301,2.866-3.273,2.121  c-0.352-0.481-0.719-0.884-0.719-1.783v-3.037c0-1.285,0.09-2.467-0.857-3.353c-0.748-0.717-1.986-0.969-2.934-0.969  c-1.854,0-3.922,0.692-4.357,2.981c-0.045,0.243,0.133,0.373,0.292,0.407l1.888,0.205c0.178-0.009,0.305-0.183,0.338-0.358  c0.162-0.789,0.824-1.17,1.566-1.17c0.4,0,0.855,0.148,1.094,0.506c0.273,0.401,0.236,0.949,0.236,1.414v0.254  c-1.131,0.125-2.605,0.21-3.663,0.675c-1.221,0.526-2.077,1.602-2.077,3.183c0,2.025,1.275,3.036,2.916,3.036  c1.385,0,2.143-0.326,3.211-1.415c0.143,0.205,0.248,0.365,0.363,0.521c-0.002,0.001-0.01,0.007-0.01,0.007  c0.926,1.551,3.496,2.485,5.23,1.5c1.705-0.829,2.673-2.308,3.223-3.751C23.566,23.334,23.638,20.184,22.134,17.329z M13.371,24.463  c0,0.76,0.018,1.391-0.365,2.066c-0.309,0.549-0.801,0.886-1.348,0.886c-0.748,0-1.186-0.569-1.186-1.413  c0-1.659,1.489-1.961,2.897-1.961L13.371,24.463L13.371,24.463z'
      />
    </svg>
  );
}

export default SimpleEmailService;
