function Feathery({ width = 64, height = 64 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 286 477'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M272.608 2.47017C275.902 9.52781 280.607 21.2905 283.901 37.2878C290.017 66.4594 278.255 106.923 259.434 127.626C244.433 144.165 220.628 157.246 199.843 168.667C195.203 171.216 190.713 173.684 186.505 176.088C171.92 184.557 157.805 192.555 143.69 200.554L143.689 200.554C134.279 205.965 124.986 211.376 115.694 216.787C106.401 222.198 97.1086 227.609 87.6985 233.02C86.2316 233.847 84.7588 234.674 83.2821 235.504C66.2856 245.053 48.779 254.888 34.0605 268.308C24.6503 277.247 18.0632 287.128 14.2991 299.832C13.3581 303.125 8.18247 304.066 6.30043 300.773C3.91928 297.201 1.87287 272.206 1.00821 261.645C0.847742 259.685 0.727976 258.223 0.654326 257.486C-1.22771 227.844 0.183817 197.261 15.2401 171.383C24.6503 154.915 38.7655 142.211 54.2923 131.86C65.9374 124.097 78.3765 117.921 90.8156 111.746C94.9619 109.687 99.1083 107.629 103.225 105.512C226.028 43.4044 254.729 20.3495 264.61 2.47017C266.492 -0.823391 271.197 -0.823391 272.608 2.47017ZM243.906 195.85C248.611 191.615 251.905 193.968 255.198 199.143C265.079 216.082 268.372 242.901 267.431 262.662C265.238 310.479 226.232 328.847 187.407 347.13C184.591 348.456 181.776 349.781 178.976 351.118L178.614 351.292C153.159 363.547 110.252 384.205 104.165 416.048C99.9302 436.75 106.988 454.159 116.869 469.686C119.221 473.45 115.928 478.155 111.693 476.744C72.6407 464.981 45.8217 436.28 35 402.403C10.4033 326.856 68.6315 296.822 136.577 261.776C141.416 259.279 146.304 256.758 151.216 254.193C196.855 230.197 226.968 211.377 243.906 195.85Z'
        fill='url(#paint0_linear)'
      />
      <defs>
        <linearGradient
          id='paint0_linear'
          x1='3.72804e-06'
          y1='95.1352'
          x2='295.005'
          y2='106.064'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#EA6081' />
          <stop offset='1' stopColor='#FF8C85' />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Feathery;
