import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import FormNavigation from '../../components/NavBar/FormNavigation';
import UserResultsTable from '../../components/UserResultsTable';
import useFeatheryRedux from '../../redux';

type Props = {
  global?: boolean;
};

function FormResultsPage({ global = false }: Props) {
  const { formId } = useParams<{ formId: string }>();
  const history = useHistory();
  const { getUsers } = useFeatheryRedux();

  const [loadError, setLoadError] = useState('');

  const handleFetchData = (payload: { [key: string]: any }) => {
    if (formId) payload.panel = formId;
    return getUsers(payload);
  };

  const handleSelectResult = (result: any) => {
    const id = result.id;
    history.push(global ? `/users/${id}` : `/forms/${formId}/results/${id}`);
  };

  const handleLoadError = (error: string) => {
    setLoadError(error);
  };

  return (
    <>
      {!global && <FormNavigation activeItem='results' error={loadError} />}
      <UserResultsTable
        formId={formId}
        title={global ? 'Users' : 'Results'}
        filtering={!global}
        hideDownloadCSV={global}
        onFetchData={handleFetchData}
        onSelectResult={handleSelectResult}
        onLoadError={handleLoadError}
        noSort={global}
      />
    </>
  );
}

export default FormResultsPage;
