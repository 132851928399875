import { isNum } from '../../../utils/validate';
import rangy from 'rangy';
import styles from '../styles.module.scss';
import { CloseIcon, PlusIcon, TrashIcon } from '../../Icons';
import ElementSelectorWithModal from '../../Modals/ElementSelectorWithModal';
import classNames from 'classnames';
import ComparisonRule, {
  FieldSelectorProps
} from '../../Modals/DecisionLogicModal/ComparisonRule';
import {
  FIELD_OPERATORS,
  getComparisonOperators
} from '../../../utils/validation';
import { OPERATOR_CODE } from '@feathery/react';
import { ConditionData } from './types';
import { FC, useMemo, useState } from 'react';
import { FieldSelectorWithModal, UnresolvedComparisonRule } from '../../Modals';
import { useAppSelector } from '../../../hooks';
import { flattenWithAsset } from '../../../utils/themes';
import { TYPE_TEXT } from '../../../utils/elements';
import StepSelector from '../../Modals/StepSelector';

const FieldSelector: FC<FieldSelectorProps> = ({
  fieldId,
  fieldType,
  error,
  currentStepId,
  valueIndex = null,
  onFieldSelected
}) => {
  return (
    <FieldSelectorWithModal
      selectId={fieldId}
      selectType={fieldType}
      onSelect={({ selectId, selectType }) =>
        onFieldSelected && onFieldSelected(selectId, selectType, valueIndex)
      }
      currentStepId={currentStepId}
      inFormBuilder
      error={error}
      includeReserved
      style={{ marginLeft: '10px', marginRight: 0, paddingRight: '20px' }}
    />
  );
};

const TRIGGER_DISPLAY_MAP: Record<string, string> = {
  field: 'is changed',
  step: ''
};

export default function NavRule({
  cond,
  step,
  index,
  setData,
  errors,
  toggleNextStep = false
}: {
  cond: ConditionData;
  step: any;
  index: number;
  setData: (transform: (draft: any) => void, submit?: boolean) => void;
  errors: Record<string, string>;
  toggleNextStep?: boolean;
}) {
  const servars = useAppSelector((state) => state.formBuilder.servars);
  const theme = useAppSelector((state) => state.formBuilder.theme);
  const [mouseDown, setMouseDown] = useState(false);

  const showSelect =
    isNum(cond.metadata.start) &&
    isNum(cond.metadata.end) &&
    cond.metadata.start !== cond.metadata.end;

  const textMap = useMemo(() => {
    const textMap = {};
    step.texts.forEach((element: any) => {
      element = flattenWithAsset(theme, TYPE_TEXT, element);
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      textMap[element.id] = element.properties.text;
    });
    return textMap;
  }, [step]);

  const richTextSelect = () => {
    const sel = rangy.getSelection();
    const anchorID = (sel.anchorNode?.parentNode as HTMLElement)?.id;
    const focusID = (sel.focusNode?.parentNode as HTMLElement)?.id;

    let anchorCursor = sel.anchorOffset;
    let focusCursor = sel.focusOffset;

    if (anchorID === 'rich-text-second') anchorCursor += cond.metadata.start;
    else if (anchorID === 'rich-text-third') anchorCursor += cond.metadata.end;
    if (focusID === 'rich-text-second') focusCursor += cond.metadata.start;
    else if (focusID === 'rich-text-third') focusCursor += cond.metadata.end;

    setData((draft) => {
      if (anchorCursor > focusCursor) {
        draft[index].metadata.start = focusCursor;
        draft[index].metadata.end = anchorCursor;
      } else {
        draft[index].metadata.start = anchorCursor;
        draft[index].metadata.end = focusCursor;
      }
    });
  };

  const deleteCondition = () =>
    setData((draft) => {
      draft.splice(index, 1);
    });

  const handleElementTargetChange = (elementType: any, elementId: any) => {
    setData((draft) => {
      draft[index] = {
        ...draft[index],
        element_type: elementType,
        element_id: elementId,
        metadata: {},
        rules: []
      };
    });
  };

  const deleteRule = (ruleIndex: number) => () => {
    setData((draft) => {
      draft[index].rules.splice(ruleIndex, 1);
    });
  };

  const updateRule = (rule: UnresolvedComparisonRule, ruleIndex: number) => {
    setData((draft) => {
      draft[index].rules[ruleIndex] = rule;
    });
  };

  const addRule = () => {
    // Do not show errors initially if rule incomplete.
    setData((draft) => {
      draft[index].rules.push({
        field_id: '',
        field_type: '',
        values: [''],
        comparison: 'equal'
      });
    }, false);
  };

  const updateNextStep = (newNextStep: string) =>
    setData((draft) => {
      draft[index].next_step = newNextStep;
    });

  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const elementText = textMap[cond.element_id];
  const condError = errors[`condition-error-${index}`];

  // TODO: remove once the old nav rules have been deprecated
  if (cond.trigger === 'load') {
    return (
      <div style={{ margin: '8px 0' }}>
        This on-load nav rule has been deprecated. Please visit your auth
        integration settings.
      </div>
    );
  }

  return (
    <div key={index} className={styles.ruleContainer}>
      <div className={styles.ruleDelete} onClick={deleteCondition}>
        <CloseIcon width={20} height={20} />
      </div>
      {toggleNextStep && (
        <div className={styles.ruleRow}>
          <div className={styles.ruleLine} />
          Go to
          <StepSelector
            filterStepId={step.id}
            curStepId={cond.next_step}
            onSelect={(newStepId: string) => updateNextStep(newStepId)}
            error={errors[`condition-step-${index}`]}
            style={{ marginLeft: '10px', marginRight: '10px' }}
          />
        </div>
      )}
      <div className={styles.ruleRow}>
        {cond.rules.length > 0 && <div className={styles.ruleLine} />}
        {cond.element_type === 'step'
          ? toggleNextStep
            ? 'And skip'
            : 'Skip'
          : 'When'}
        <ElementSelectorWithModal
          step={step}
          elementType={cond.element_type}
          elementId={cond.element_id}
          onSelect={handleElementTargetChange}
          error={errors[`condition-target-${index}`]}
          style={{ marginLeft: '10px', marginRight: '10px' }}
        />
        {TRIGGER_DISPLAY_MAP[cond.element_type] ?? 'is clicked'}
      </div>
      {cond.element_type === 'text' && (
        <div className={styles.textRow}>
          {cond.rules.length > 0 && (
            <div className={classNames(styles.ruleLine, styles.textRuleLine)} />
          )}
          <span
            key='unselect'
            id='rich-text-unselected'
            className={styles.richTextSpan}
            style={{
              display: !showSelect ? 'inline' : 'none'
            }}
            onMouseLeave={() => {
              if (mouseDown) {
                const sel = rangy.getSelection();
                setData((draft) => {
                  if (sel.anchorOffset > sel.focusOffset) {
                    draft[index].metadata.start = sel.focusOffset;
                    draft[index].metadata.end = mouseDown
                      ? elementText.length
                      : sel.anchorOffset;
                  } else {
                    draft[index].metadata.start = sel.anchorOffset;
                    draft[index].metadata.end = mouseDown
                      ? elementText.length
                      : sel.focusOffset;
                  }
                });
                setMouseDown(false);
              }
            }}
            onMouseDown={() => setMouseDown(true)}
            onMouseUp={() => {
              setMouseDown(false);
              const sel = rangy.getSelection();
              setData((draft) => {
                if (sel.anchorOffset > sel.focusOffset) {
                  draft[index].metadata.start = sel.focusOffset;
                  draft[index].metadata.end = sel.anchorOffset;
                } else {
                  draft[index].metadata.start = sel.anchorOffset;
                  draft[index].metadata.end = sel.focusOffset;
                }
              });
            }}
          >
            {elementText}
          </span>
          <span
            key='first'
            id='rich-text-first'
            className={styles.richTextSpan}
            style={{
              display: showSelect ? 'inline' : 'none'
            }}
            onMouseUp={richTextSelect}
          >
            {elementText.substring(0, cond.metadata.start || 0)}
          </span>
          <span
            key='second'
            id='rich-text-second'
            className={classNames(
              styles.richTextSpan,
              styles.richTextHighlight
            )}
            style={{
              display: showSelect ? 'inline' : 'none'
            }}
            onMouseUp={richTextSelect}
          >
            {elementText.substring(
              cond.metadata.start || 0,
              cond.metadata.end || 0
            )}
          </span>
          <span
            key='third'
            id='rich-text-third'
            className={styles.richTextSpan}
            style={{ display: showSelect ? 'inline' : 'none' }}
            onMouseUp={richTextSelect}
          >
            {elementText.substring(cond.metadata.end || 0)}
          </span>
        </div>
      )}
      {cond.rules.map((rule: any, ruleIndex: any, rules) => {
        return (
          <div
            key={ruleIndex}
            className={classNames(styles.ruleRow, styles.condition)}
          >
            {ruleIndex < rules.length - 1 && (
              <div className={styles.ruleLine} />
            )}
            {ruleIndex === 0 ? 'If' : 'And'}
            <ComparisonRule
              rule={rule}
              showErrors
              handleDelete={deleteRule(ruleIndex)}
              handleUpdates={(rule) => updateRule(rule, ruleIndex)}
              onFieldSelected={(selectId, selectType, valueIndex) => {
                const rule = { ...cond.rules[ruleIndex] };
                rule.values = [
                  ...(rule.values.length ? rule.values : ['']) // make sure there is at least one value if field is infix
                ];
                if (valueIndex === null) {
                  // left side field
                  rule.field_id = selectId;
                  rule.field_type = selectType;
                } else {
                  // Right side field value
                  rule.values[valueIndex] = {
                    field_id: selectId,
                    field_type: selectType
                  };
                }
                // Figure out the comparison operators for the new field.  If the old comparison
                // is in the new list, then preserve it.  Otherwise, if the 'equal' comparison op is present, choose
                // it.  Otherwise, choose the first one.
                const servar =
                  selectId && selectType === 'servar'
                    ? servars[selectId]
                    : null;
                const operators = getComparisonOperators(servar);
                let newComparison: OPERATOR_CODE | undefined;
                if (
                  rule.comparison &&
                  operators.find((o) => o.code === rule.comparison)
                )
                  newComparison = rule.comparison;
                else if (operators.find((o) => o.code === 'equal'))
                  newComparison = 'equal';
                else if (operators.length) newComparison = operators[0].code;
                if (newComparison) rule.comparison = newComparison;
                if (
                  !newComparison ||
                  !FIELD_OPERATORS[newComparison as OPERATOR_CODE].multiValue
                )
                  // if new opertor is single value, make sure only one value
                  rule.values.splice(1);

                updateRule(rule, ruleIndex);
              }}
              currentStepId={step.id}
              FieldSelector={FieldSelector}
            />

            {cond.element_type === 'step' && (
              <div
                onClick={deleteRule(ruleIndex)}
                className={styles.deleteIcon}
              >
                <TrashIcon width={20} height={20} />
              </div>
            )}
          </div>
        );
      })}
      <div
        className={classNames(styles.ruleRow, styles.addRow)}
        onClick={addRule}
      >
        <PlusIcon width={15} height={15} className={styles.addNewIcon} />
        Condition
      </div>
      {condError && <span className={styles.ruleErrorRow}>{condError}</span>}
    </div>
  );
}
