import {
  TYPE_BUTTON,
  TYPE_CONTAINER,
  TYPE_IMAGE,
  TYPE_PROGRESS_BAR,
  TYPE_TEXT,
  TYPE_VIDEO
} from '../../../../../utils/elements';
import { calculateElementRenderData } from '../../../../../utils/step';

export const getElementStyle = (
  element: any,
  viewport: any,
  theme: any,
  style: any
) => {
  const renderData: any = calculateElementRenderData({
    element,
    viewport,
    theme,
    style
  });

  return renderData?.styles || {};
};

export const getElementStyleType = (el: any) => {
  let type = el._type;
  let style;
  switch (type) {
    case TYPE_PROGRESS_BAR:
      style = ['progress_bar'];
      break;
    case TYPE_IMAGE:
      style = ['image'];
      break;
    case TYPE_TEXT:
      style = ['text'];
      break;
    case TYPE_BUTTON:
      style = ['button'];
      break;
    case TYPE_VIDEO:
      style = ['video'];
      break;
    case TYPE_CONTAINER:
      style = ['container'];
      break;
    default:
      style = ['field', el.servar.type];
      type = el.servar.type;
      break;
  }

  return { style, type };
};
