import { useAppSelector } from './index';
import { useMemo } from 'react';

export const useNextStepDropdownOptions = (
  excludeStepId: string
): { display: string; value: string }[] => {
  const workingSteps = useAppSelector(
    (state) => state.formBuilder.workingSteps
  );

  return useMemo(
    () =>
      Object.values(workingSteps)
        .filter((step: any) => step.id !== excludeStepId)
        .map((step: any) => ({ display: step.key, value: step.id }))
        .sort((a: any, b: any) => (a.display > b.display ? 1 : -1)),
    [excludeStepId, workingSteps]
  );
};
