export default function ItalicIcon({ width = 12, height = 12 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.1068 0.5H3.39251C3.31394 0.5 3.24965 0.55625 3.24965 0.625V1.625C3.24965 1.69375 3.31394 1.75 3.39251 1.75H6.62822L3.84251 10.25H0.94608C0.867508 10.25 0.803223 10.3062 0.803223 10.375V11.375C0.803223 11.4438 0.867508 11.5 0.94608 11.5H8.66037C8.73894 11.5 8.80322 11.4438 8.80322 11.375V10.375C8.80322 10.3062 8.73894 10.25 8.66037 10.25H5.32822L8.11394 1.75H11.1068C11.1854 1.75 11.2497 1.69375 11.2497 1.625V0.625C11.2497 0.55625 11.1854 0.5 11.1068 0.5Z'
        fill='#414859'
        stroke='#414859'
        strokeWidth='0.4'
      />
    </svg>
  );
}
