export default function ColorPickerIcon({
  width = 48,
  height = 48,
  color = '#414859'
}: any) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M38.1405 32.0172C33.2534 34.0944 26.1122 30.6953 25.6648 35.279C25.4583 37.3047 26.8177 38.9356 27.093 40.5322C27.4888 42.8155 25.2001 44 23.2556 44C12.0189 44 4 35.5021 4 24.2918C4 13.0815 13.103 4 24.3397 4C28.1083 4 31.8768 4.96137 35.1463 6.84979C47.1058 13.7339 45.7636 28.7725 38.1405 32.0172Z'
        stroke={color}
        strokeWidth='3'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.9999 28.4292C14.739 28.4292 16.1489 27.0226 16.1489 25.2876C16.1489 23.5525 14.739 22.1459 12.9999 22.1459C11.2607 22.1459 9.85083 23.5525 9.85083 25.2876C9.85083 27.0226 11.2607 28.4292 12.9999 28.4292Z'
        fill={color}
      />
      <path
        d='M15.3055 19.5708C17.0447 19.5708 18.4546 18.1642 18.4546 16.4292C18.4546 14.6941 17.0447 13.2875 15.3055 13.2875C13.5664 13.2875 12.1565 14.6941 12.1565 16.4292C12.1565 18.1642 13.5664 19.5708 15.3055 19.5708Z'
        fill={color}
      />
      <path
        d='M23.9955 15.1073C25.7347 15.1073 27.1445 13.7007 27.1445 11.9657C27.1445 10.2306 25.7347 8.82404 23.9955 8.82404C22.2563 8.82404 20.8464 10.2306 20.8464 11.9657C20.8464 13.7007 22.2563 15.1073 23.9955 15.1073Z'
        fill={color}
      />
      <path
        d='M33.0123 19.5708C34.7515 19.5708 36.1614 18.1642 36.1614 16.4292C36.1614 14.6941 34.7515 13.2875 33.0123 13.2875C31.2732 13.2875 29.8633 14.6941 29.8633 16.4292C29.8633 18.1642 31.2732 19.5708 33.0123 19.5708Z'
        fill={color}
      />
    </svg>
  );
}
