import { useState, useLayoutEffect } from 'react';
import useResizeObserver from '@react-hook/resize-observer';

const useSize = (target: any) => {
  const [size, setSize] = useState<DOMRectReadOnly>();

  useLayoutEffect(() => {
    setSize(target.current.getBoundingClientRect());
  }, [target]);

  // Uses a single observer instance for all elements + provides a pollyfill
  useResizeObserver(target, (entry) => setSize(entry.contentRect));
  return size;
};

export default useSize;
