function ReleaseIcon({ color = '#414859', width = 16, height = 16 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 32 32'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.0911 3.61282C16.8077 3.31238 16.413 3.14209 16 3.14209C15.587 3.14209 15.1923 3.31238 14.9089 3.61282L11.9096 6.79232C11.3412 7.39494 11.3689 8.34429 11.9715 8.91274C12.5741 9.48119 13.5235 9.45349 14.0919 8.85087L14.51 8.40765V12.7203C14.51 13.5487 15.1816 14.2203 16.01 14.2203C16.8384 14.2203 17.51 13.5487 17.51 12.7203V8.42887L17.9081 8.85087C18.4765 9.45349 19.4259 9.48119 20.0285 8.91274C20.6311 8.34429 20.6588 7.39494 20.0904 6.79232L17.0911 3.61282ZM28.0259 14.2146C28.0259 14.3006 28.0183 14.3854 28.0034 14.4685C28.0096 14.524 28.0128 14.5803 28.0128 14.6375V20.6383C28.0128 20.6793 28.0112 20.7198 28.008 20.7599C28.1209 21.3562 27.8474 21.9836 27.2784 22.3066L16.7855 28.2636C16.3123 28.5322 15.7273 28.5322 15.2541 28.2636L4.76113 22.3066C4.17184 21.9721 3.89959 21.3111 4.04518 20.6963C4.02397 20.5958 4.01282 20.4916 4.01282 20.3848L4.01282 14.8679C4.01282 14.7643 4.02332 14.6632 4.04332 14.5655C4.01486 14.452 4 14.3343 4 14.2146C4 13.6882 4.28722 13.2015 4.75429 12.9363L10.0008 9.95783C10.726 9.54614 11.6566 9.78472 12.0795 10.4907C12.5024 11.1967 12.2573 12.1027 11.5321 12.5144L8.53732 14.2146L16.013 18.4586L23.4886 14.2146L20.4938 12.5144C19.7686 12.1027 19.5235 11.1967 19.9464 10.4907C20.3693 9.78472 21.3 9.54614 22.0251 9.95783L27.2716 12.9363C27.7387 13.2015 28.0259 13.6882 28.0259 14.2146ZM7.01282 16.7751L7.01282 20.159L14.5128 24.4168V21.0329L7.01282 16.7751ZM17.5128 21.0331L25.0128 16.7752V20.1669L17.5128 24.4247V21.0331Z'
        fill={color}
      />
    </svg>
  );
}

export default ReleaseIcon;
