import { ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

/**
 * A helper function to merge Tailwind classes with custom classes.
 * Classnames are conditionally applied using clsx, and properly merged using tailwind-merge.
 * @param inputs - The classes to merge.
 * @returns The merged classes.
 */
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
