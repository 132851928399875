/* eslint-disable no-use-before-define, no-undef */

import { v4 as uuidv4 } from 'uuid';
import { BaseTree, ITree } from './Tree';
import { NodeIndexMap, NodeSearchFn } from './types';
import { Model } from '../models';

export type NodeOptions<T, N> = {
  uuid?: string;
  tree?: T;
  parent?: N;
  children?: N[];
  weight?: number;
  map?: NodeIndexMap;
  position?: number[];
  copy?: boolean;
};

export type NodeUnlinkOptions = {
  isChild?: boolean;
};

export type NodeDisconnectChildOptions = {
  gracefully?: boolean;
};

export type CopyNodeOptions = {
  unique?: boolean;
};

export type NodeDetails = {
  uuid: string;
};

export type NodeAddOptions = {
  before?: string;
  after?: string;
  insert?: number;
  updateWeight?: boolean;
  defaultWeight?: boolean;
};

export type NodeRemoveOptions = {
  force?: boolean;
};

export type NodeRestructureOptions = {
  remap?: boolean;
  reposition?: boolean;
  deep?: boolean;
};

export interface INode {
  uuid: string;
  tree: ITree | null;
  parent: INode | null;
  children: INode[];
  weight: number;
  map: NodeIndexMap;
  model: Model | null;
  position: number[];
  positionId: string;
  details: NodeDetails;
  data: Model | null;
  isEmpty: boolean;

  [key: string]: any; // This is to access element, subgrid and cell data without casting to any

  link(parent: ITree | INode): void;

  unlink(options?: NodeUnlinkOptions): void;

  add(node?: INode, options?: NodeAddOptions): INode | null;

  addSibling(
    node?: INode,
    before?: boolean,
    options?: NodeAddOptions
  ): INode | null;

  remove(options?: NodeRemoveOptions): void;

  removeChildren(options?: NodeRemoveOptions): void;

  disconnectChild(child: INode, options?: NodeDisconnectChildOptions): void;

  unlinked(): INode;

  copy(options?: CopyNodeOptions, parent?: INode): INode;

  consume(node: INode): void;

  indexOf(node: INode): number | null;

  has(node: INode): boolean;

  find(fn: NodeSearchFn): INode | undefined;

  get(index: number): INode | undefined;

  getSibling(node?: INode): INode | null;

  getFirstChild(): INode | undefined;

  getLastChild(): INode | undefined;

  isFirstChild(uuid?: string): boolean;

  isLastChild(uuid?: string): boolean;

  isDescendent(node?: INode): boolean;

  isEqual(node?: INode): boolean;

  isUnlinked(): boolean;

  isRoot(): boolean;

  isCopy(): boolean;

  hasChildren(): boolean;

  hasSingleChild(): boolean;

  setModel(model: Model): void;

  removeModel(): void;

  remap(recursive?: boolean): void;

  reposition(recursive?: boolean): void;

  restructure(options?: NodeRestructureOptions): void;

  updateWeight(delta: number): void;
}

/**
 * Abstract class for the base functionality of a node in a tree.
 * BaseNode is intended to hide functionality that is not related to
 * business logic and has a smaller likelihood of being changed.
 */
export abstract class BaseNode<T extends ITree, N extends INode>
  implements INode
{
  #uuid: string; // Unique identifier of the Node
  #tree: T | null; // Reference to the tree of the Node (if connected to one)
  #parent: N | null; // Reference to the parent of the Node (if it has one)
  #children: N[]; // References to the children of the Node
  #weight: number; // A number equal to the number of all descendants of the Node + itself
  #map: NodeIndexMap; // A hashmap reference of the Node's children (for performance)
  #model: Model | null; // Model gives the node a "purpose" -- This holds data unique to the Node's type
  #position: number[]; // The position of the Node in the tree
  #copy: boolean; // Whether the Node is a copy

  /**
   * Models are spread on instances of this class. That being said,
   * we need to specify that any key could possibly exist on the instance
   * as they could come from the Model.
   */
  [key: string]: any;

  constructor(model: Model | null = null, options: NodeOptions<T, N> = {}) {
    const {
      uuid = uuidv4(),
      tree = null,
      parent = null,
      children = [],
      weight = 1,
      map = {},
      position = [],
      copy = false
    } = options;

    this.#position = position;
    this.#uuid = uuid;
    this.#tree = tree;
    this.#parent = parent;
    this.#children = children;
    this.#weight = weight;
    this.#map = map;
    this.#model = model;
    this.#copy = copy;

    this.init();
  }

  protected abstract create(
    model: Model | null,
    options?: NodeOptions<T, N>
  ): N;

  /**
   * Used to initialized a Node. If a Model is present on the Node, it will spread
   * the properties of the Model onto the instance of the Node.
   */
  protected init(): void {
    if (this.#model) {
      for (const key in this.#model.state) {
        if (this[key as keyof typeof this] === undefined) {
          Object.defineProperty(this, key, {
            configurable: true,
            get: () => (this.#model as Model)[key],
            set: (value) => {
              (this.#model as Model)[key] = value;
            }
          });
        }
      }
    }
  }

  /**
   * Connect this Node to either a Tree or Node as an immediate child.
   * @param parent - The Node or Tree that this Node should link to as a child
   */
  public link(parent: T | N) {
    if (parent instanceof BaseTree && !this.isCopy()) {
      this.#tree = parent as T;
    } else {
      this.#parent = parent as N;
      this.#tree = (parent as N).tree as T;

      if (this.isCopy() && !this.isUnlinked()) {
        this.#copy = false; // Node is no longer a copy as it is linked
      }

      // Relink the children
      if (this.#children.length > 0) {
        this.#children.forEach((child) => child.link(this));
      }
    }
  }

  /**
   * Unlink this Node from it's tree and parent. References to the tree and parent will
   * be null after performing this. All children of the node will also be unlinked.
   */
  public unlink({ isChild = false }: NodeUnlinkOptions = {}) {
    this.#tree = null;

    this.#children.forEach((child) => child.unlink({ isChild: true }));

    if (!isChild) {
      this.#parent = null;
      this.restructure();
    }
  }

  /**
   * Add a node to this node as a child. Options may be passed to this function to determine
   * where in the children the Node should be added (before, after, insert, etc)
   */
  public add(node: N, options: NodeAddOptions = {}): N | null {
    const {
      before = null,
      after = null,
      insert = null,
      updateWeight = true,
      defaultWeight = false
    } = options;

    node.link(this);

    let targetPosition = this.#children.length;
    if (before) targetPosition = this.#map[before];
    if (after) targetPosition = this.#map[after] + 1;
    if (insert !== null) targetPosition = insert;
    if (targetPosition < 0 || targetPosition > this.#children.length) {
      targetPosition = this.#children.length;
    }

    this.#children.splice(targetPosition, 0, node);

    this.remap(false);
    this.reposition(true);

    if (updateWeight) {
      this.updateWeight(defaultWeight ? 1 : node.weight);
    }

    if (!this.isUnlinked()) {
      (this.#tree as T).register(node);
    }

    return node;
  }

  /**
   * Add a node as a sibling to this node.
   * @param node - Node to be added as a sibling of this node
   * @param before - Whether the Node should be added before or after this node
   * @param options - Options that will be passed to the add() call within the addSibling function
   * @returns {BaseNode | null} Either a node or null if the add was unsuccessful
   */
  public addSibling(
    node: N,
    before?: boolean,
    options?: NodeAddOptions
  ): N | null {
    if (!this.#parent) return null;

    return this.#parent.add(node, {
      before: before ? this.#uuid : undefined,
      after: !before ? this.#uuid : undefined,
      ...options
    }) as N;
  }

  /**
   * Remove this node from the tree and it's parent's children.
   */
  public remove({ force = false }: NodeRemoveOptions = {}): void {
    if (!force && this.isRoot()) {
      return;
    }

    if (this.#tree) {
      this.#tree.unregister(this);
    } else {
      if (this.#parent) {
        this.#parent.disconnectChild(this);
      }

      this.unlink();
    }
  }

  /**
   * Remove all the children from this node from the tree and itself.
   */
  public removeChildren(options: NodeRemoveOptions = {}): void {
    [...this.#children].forEach((child) => {
      child.remove(options);
    });
  }

  /**
   * Similar to `unlink()`, this is to be used to disconnect a child from its parent.
   * However, additional steps in disconnectChild will also restructure the children
   * and weight of this node afterwards.
   */
  public disconnectChild(node: N, options: NodeDisconnectChildOptions = {}) {
    const { gracefully = false } = options;
    const childIndex = this.#map[node.uuid];

    if (childIndex !== undefined) {
      this.#children.splice(childIndex, 1);
      this.restructure();

      if (!gracefully) {
        this.updateWeight(-node.weight);
      }

      return childIndex;
    }

    return null;
  }

  /**
   * Returns a copy of the node that is disconnected from the parent and tree.
   * Mutations to the copy will not effect the original node.
   * This is a proxy to `copy()`.
   */
  public unlinked() {
    return this.copy();
  }

  /**
   * Internal copy function. This encapsulates specific logic to how the node will be copied.
   */
  protected _copy({ unique = false }: CopyNodeOptions = {}, parent?: N): N {
    const node = this.create((this.#model as Model).copy(), {
      ...this.details,
      uuid: unique ? undefined : this.details.uuid,
      copy: true
    });

    if (parent) {
      node.parent = parent;
    }

    return node;
  }

  /**
   * Returns a copy of the node that is disconnected from the parent and tree.
   * Mutations to the copy will not effect the original node.
   */
  public copy(options: CopyNodeOptions = {}, parent?: N): N {
    const node = this._copy(options, parent);

    node.children = this.#children.map((child) => {
      return child.copy(options, node);
    });

    if (!parent) {
      node.restructure();
    }

    return node;
  }

  /**
   * Copy the target node's children and model onto this node. This allows for easily
   * "consuming" a copied node or set a node to another node without modifying the tree structure.
   * @param node - Node that should be consumed
   */
  public consume(node: N) {
    if (!node.isCopy()) {
      return; // Can only consume copied nodes
    }

    // Remove original children
    this.removeChildren({ force: true });

    // Copy the target cell's model to this cell
    if (node.model) {
      this.setModel(node.model.copy());
    }

    // If the cell has children, add each child to this cell
    if (node.hasChildren()) {
      node.children.forEach((child) => {
        this.add(child as N);
      });
    }
  }

  /**
   * Returns the index of the Node within this node's children
   * @param node - Node to search for
   * @returns {number | null} The index of the node within this node's children array
   */
  public indexOf(node: N) {
    return this.#map[node.uuid] ?? null;
  }

  /**
   * Returns whether this node contains the target node in it's children
   * @param node - Node to search for
   * @returns {boolean} Whether this node contains the target node
   */
  public has(node: N) {
    return this.indexOf(node) !== null;
  }

  /**
   * Find a node within this node's children utilizing a search function
   * @param fn - Search function to be used
   * @returns {BaseNode | undefined} Either a node found by the search function or undefined
   */
  public find(fn: NodeSearchFn) {
    return this.#children.find((child) => fn(child));
  }

  /**
   * Get a child of this node by index
   * @param index - The index of the target child
   * @returns {BaseNode | undefined} The node at the target index or undefined
   */
  public get(index: number): N | undefined {
    return this.#children[index];
  }

  /**
   * Will return the nearest sibling of the node provided or this node if no node was provided.
   * @param node - The node being used to retreive a sibling from. If undefined, it will return the nearest sibling of this node.
   * @returns - Either the nearest sibling of the node passed or the nearest sibling of this node if no node was passed.
   */
  public getSibling(node?: N): N | null {
    if (this.isRoot() && !node) return null;
    if (!node) {
      if (this.#parent) return this.#parent.getSibling(this) as N;
      else return null;
    }

    const nodeIndex = this.indexOf(node);

    return this.#children[nodeIndex + 1] || this.#children[nodeIndex - 1];
  }

  /**
   * Returns the first child of this node.
   * @returns {BaseNode | undefined} The first child of this node
   */
  public getFirstChild(): N | undefined {
    return this.#children[0];
  }

  /**
   * Returns the last child of this node.
   * @returns {BaseNode | undefined} The last child of this node
   */
  public getLastChild(): N | undefined {
    return this.#children[this.#children.length - 1];
  }

  /**
   * Returns whether the node belonging to the UUID passed is the first node in this node's children.
   * @param uuid - The UUID of the node to be checked
   * @returns {boolean} Whether the node with the corresponding UUID is the first node in this node's children
   */
  public isFirstChild(uuid: string | null = null): boolean {
    if (this.isRoot() && !uuid) {
      return false;
    }

    if (uuid && this.#children[0]) {
      return this.#children[0].uuid === uuid;
    }

    return (this.#parent as N).isFirstChild(this.#uuid);
  }

  /**
   * Returns whether the node belonging to the UUID passed is the last node in this node's children.
   * @param uuid - The UUID of the node to be checked
   * @returns {boolean} Whether the node with the corresponding UUID is the last node in this node's children
   */
  public isLastChild(uuid: string | null = null): boolean {
    if (this.isRoot() && !uuid) {
      return false;
    }

    if (uuid && this.#children[this.#children.length - 1]) {
      return this.#children[this.#children.length - 1].uuid === uuid;
    }

    return (this.#parent as N).isLastChild(this.#uuid);
  }

  /**
   * Returns whether the node provided is a descendant of this node. A descendant does not
   * mean that the node is an immediate child of this node.
   * @param node - Node to be checked
   * @returns {boolean} Whether the node provided is a descendant of this node
   */
  public isDescendent(node: INode): boolean {
    return this.position.join(',').startsWith(node.position.join(','));
  }

  /**
   * Returns whether the node provided is equal to this node. Equality within this
   * function is based purely on position of the nodes in the tree.
   * @param node - Node to be checked for equality
   * @returns {boolean} Whether the node provided is equal to this node
   */
  public isEqual(node: INode): boolean {
    return this.position.join(',') === node.position.join(',');
  }

  /**
   * Returns whether the node is unlinked meaning it has no tree or parent.
   * @returns {boolean}
   */
  public isUnlinked(): boolean {
    return !this.#tree;
  }

  /**
   * Returns whether the node is the root node meaning it is connected to a tree but has no parent.
   * @returns {boolean}
   */
  public isRoot(): boolean {
    return !this.#parent;
  }

  /**
   * Returns whether this node is a copy of another node.
   * @returns {boolean}
   */
  public isCopy(): boolean {
    return this.#copy;
  }

  /**
   * Returns whether this node has children or not.
   * @returns {boolean}
   */
  public hasChildren() {
    return this.#children.length > 0;
  }

  /**
   * Returns whether this node has a single child or not.
   * @returns {boolean}
   */
  public hasSingleChild() {
    return this.#children.length === 1;
  }

  /**
   * Internal use only. This function is used to update the weight of this node by the delta
   * provided. The weight of this node should always be equal to the number of descendants + itself (1).
   * @param delta - Delta to adjust the weight of this node by
   */
  public updateWeight(delta: number) {
    this.#weight += delta;

    if (this.#parent) {
      this.#parent.updateWeight(delta);
    }
  }

  /**
   * Internal use only. This function is used to reconstruct the internal hashmap of children on this node.
   * @param recursive - Specifies whether it should also remap it's children recursively.
   */
  public remap(recursive = false) {
    this.#map = this.#children.reduce(
      (map, child, index) => ({
        ...map,
        [child.uuid]: index
      }),
      {}
    );

    if (recursive) {
      this.#children.forEach((child) => child.remap(recursive));
    }
  }

  /**
   * Internal use only. This function is used to reposition the children of this node.
   * @param recursive - Specifies whether it should also reposition it's children recursively.
   */
  public reposition(recursive = false) {
    this.#position = this.#parent
      ? [...this.#parent.position, this.#parent.indexOf(this) as number]
      : [];

    if (recursive) {
      this.#children.forEach((child) => child.reposition(true));
    }
  }

  /**
   * Internal use only. This function is used as a helper function for remapping and repositioning the children of this node.
   */
  public restructure({ remap = true, reposition = true, deep = true } = {}) {
    if (remap) this.remap(deep);
    if (reposition) this.reposition(deep);
  }

  /**
   * Set a new model on this node. This will remove the old model if one exists as well as re-call the init() function on the node.
   * @param model - The model that should be set
   */
  public setModel(model: Model) {
    this.removeModel();

    this.#model = model;

    this.init();
  }

  /**
   * Remove the model from this node. This will also cleanup the properties that were spread
   * on the node by the init() function from the model.
   */
  public removeModel() {
    if (!this.#model) {
      return; // No model to remove
    }

    for (const key in this.#model.state) {
      if (Object.getOwnPropertyDescriptor(this, key)) {
        Object.defineProperty(this, key, {
          get: undefined,
          set: undefined
        });

        delete this[key as keyof typeof this];
      }
    }

    this.#model = null;
  }

  get uuid() {
    return this.#uuid;
  }

  get details() {
    return {
      uuid: this.#uuid
    };
  }

  get data() {
    return this.#model;
  }

  get tree() {
    return this.#tree;
  }

  get parent() {
    return this.#parent;
  }

  set parent(node) {
    if (this.#tree) {
      return; // Prevent setting parents if the Node is part of a tree
    }

    this.#parent = node;
  }

  get children() {
    return this.#children;
  }

  set children(children: N[]) {
    const isValid = children.every((child) => {
      return child.isUnlinked() && child.isCopy();
    });

    if (this.#children.length > 0 || !isValid) {
      return; // Prevent setting children if the node already has children
    }

    children.forEach((child) => this.add(child, { defaultWeight: true }));
  }

  get weight() {
    return this.#weight;
  }

  get map() {
    return this.#map;
  }

  get model() {
    return this.#model;
  }

  get position() {
    return this.#position;
  }

  get positionId() {
    return this.#position.join(',') || 'root';
  }

  get isEmpty(): boolean {
    return !this.data && this.#children.every((child) => child.isEmpty);
  }
}
