function RowSplitIcon({
  width = 24,
  height = 24,
  color = '#414859',
  ...props
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M16.9617 3.99945L17.9844 3.99945C19.0889 3.99945 19.9844 4.89488 19.9844 5.99945L19.9844 17.9995C19.9844 19.104 19.0889 19.9995 17.9844 19.9995L16.9782 19.9995M6.97643 3.99945L5.98334 3.99945C4.87877 3.99945 3.98334 4.89488 3.98334 5.99945L3.98334 17.9994C3.98334 19.104 4.87877 19.9995 5.98334 19.9995L6.97385 19.9995'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <line
        x1='19.2344'
        y1='11.9995'
        x2='4.73334'
        y2='11.9995'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M14.3432 17.644L11.9837 19.9992L9.62427 17.644'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.9836 14.9995L11.9836 19.9995'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.62437 6.35468L11.9838 3.99945L14.3433 6.35468'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.9839 3.99969L11.9839 8.99969'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export default RowSplitIcon;
