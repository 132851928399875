import { useMemo, useState } from 'react';
import { chosenPlan, PLANS } from './plans';
import PlanCard from './PlanCard';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { CheckCircleIcon } from '../Icons';
import { ChangePricingPlanModal } from '../Modals';
import { isDefined } from '../../utils/core';
import { useAppSelector } from '../../hooks';

function PlanSelector({
  setShowPaymentMethod: setShowPaymentMethodOnParent = () => {}
}) {
  const org = useAppSelector((state) => state.accounts.organization);
  const orgTier = isDefined(org?.pending_tier) ? org?.pending_tier : org?.tier;
  const currentPlan = useMemo(() => chosenPlan(org), [orgTier]);
  const [newPlan, setNewPlan] = useState({});
  const [showChangePlanModal, setShowChangePlanModal] = useState(false);

  const changePlan = (newPlan: any) => {
    if (newPlan.cost !== null) {
      setNewPlan(newPlan);
      // @ts-expect-error TS(2554) FIXME: Expected 0 arguments, but got 1.
      setShowPaymentMethodOnParent(false);
      setShowChangePlanModal(true);
    } else {
      // Custom plan with no cost: mail to support@feathery.io
      // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '(string |... Remove this comment to see the full error message
      window.location =
        'mailto:support@feathery.io?subject=Business plan quote request&body=Please provide me a business plan quote. My requirements are:';
    }
  };

  const AvailableOtherPlans = () => {
    return PLANS.filter((plan) => plan.active && plan.tier !== orgTier).map(
      (plan) => (
        <PlanCard
          className={styles.planCard}
          key={plan.key}
          plan={plan}
          onSelectPlan={() => changePlan(plan)}
          isUpgrade={plan.tier > orgTier}
        />
      )
    );
  };

  return (
    <>
      <div className={styles.planSelector}>
        <div className={styles.chosenPlan}>
          <div className={styles.chosenPlanIndicator}>
            <CheckCircleIcon width={16} height={16} />
            YOUR PLAN
          </div>
          <PlanCard
            className={classNames(styles.planCard, styles.planCardActive)}
            plan={currentPlan}
            onSelectPlan={() => {}}
            isSelected
          />
        </div>
        <div className={styles.browsePlans}>
          <div className={styles.browsePlansHeader}>Browse Plans</div>
          <div className={styles.availablePlans}>
            {/* @ts-expect-error TS(2786) FIXME: 'AvailableOtherPlans' cannot be used as a JSX comp... Remove this comment to see the full error message */}
            <AvailableOtherPlans />
          </div>
        </div>
      </div>
      {showChangePlanModal && (
        <ChangePricingPlanModal
          currentPlan={currentPlan}
          plan={newPlan}
          show={showChangePlanModal}
          setShow={(show: any) => {
            setShowChangePlanModal(show);
            // @ts-expect-error TS(2554) FIXME: Expected 0 arguments, but got 1.
            setShowPaymentMethodOnParent(!show);
          }}
        />
      )}
    </>
  );
}

export default PlanSelector;
