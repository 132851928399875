export default function TextUnderlineIcon({ width = 16, height = 16 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.85709 3.71429H12.2857V2H3.71423V3.71429H7.1428V6.28571H8.85709V3.71429Z'
        fill='#414859'
      />
      <path
        d='M7.14282 11.4285V6.28564H8.85711V11.4285H7.14282Z'
        fill='#414859'
      />
      <path d='M2 13.9999H14V12.2856H2V13.9999Z' fill='#414859' />
    </svg>
  );
}
