import useFeatheryRedux from '../redux';
import { useAppSelector } from './index';
import { useHistory, useParams } from 'react-router-dom';
import { useCallback } from 'react';

export const useFormBuilderChangeStep = () => {
  const { formId } = useParams<{ formId: string }>();
  const history = useHistory();
  const {
    formBuilder: { setActiveStepId, wipeFocus, gigSetPosition }
  } = useFeatheryRedux();
  const activeStepId: string = useAppSelector(
    (state) => state.formBuilder.activeStepId
  );
  const workingSteps = useAppSelector(
    (state) => state.formBuilder.workingSteps as Record<string, any>
  );

  return useCallback(
    (newStepId = '', fallback = false) => {
      if (newStepId && newStepId === activeStepId) return;

      const sameStep = newStepId === 'flow' || newStepId === 'logic';
      if (fallback) {
        if (Object.keys(workingSteps).length === 0) return;
        if (!(newStepId in workingSteps)) {
          if (activeStepId in workingSteps) return;
          newStepId = Object.values(workingSteps).find(
            (step: any) => step.origin
          ).id;
        }
      }

      if (!sameStep) {
        wipeFocus();
        gigSetPosition(null);
        history.push(`/forms/${formId}/${newStepId}/`);
      }
      if (!sameStep || !activeStepId) setActiveStepId(newStepId);
    },
    [workingSteps, activeStepId]
  );
};
