function Tarmika({ width = 230, height = 90 }) {
  return (
    <img
      alt='Tarmika logo'
      src={require('./Tarmika.png')}
      style={{ width, height }}
    />
  );
}

export default Tarmika;
