function Experian({ width = 144, height = 100 }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 512 512'
      width={width}
      height={height}
      xmlSpace='preserve'
    >
      <g id='XMLID_1_'>
        <path
          id='XMLID_3_'
          style={{ fill: '#406EB3' }}
          d='M80.99,212.26H62.21c-5.61,0-10.09-4.49-10.09-10.09v-18.79c0-5.47,4.49-10.09,10.09-10.09   h18.79c5.47,0,10.09,4.49,10.09,10.09v18.79C91.09,207.77,86.6,212.26,80.99,212.26'
        />
        <path
          id='XMLID_4_'
          style={{ fill: '#632678' }}
          d='M32.35,254.32H11.46c-6.31,0-11.36-5.19-11.36-11.36v-21.17c0-6.17,5.19-11.36,11.36-11.36   h21.17c6.31,0,11.36,5.19,11.36,11.36v21.17C43.84,249.27,38.79,254.32,32.35,254.32'
        />
        <path
          id='XMLID_5_'
          style={{ fill: '#982881' }}
          d='M30.52,298.06h-12.9c-3.79,0-6.87-3.08-6.87-6.87v-12.9c0-3.79,3.08-6.87,6.87-6.87h12.9   c3.79,0,6.87,3.08,6.87,6.87v12.9C37.39,294.97,34.31,298.06,30.52,298.06'
        />
        <path
          id='XMLID_6_'
          style={{ fill: '#BA2F7D' }}
          d='M65.01,338.72H49.73c-4.49,0-8.27-3.65-8.27-8.27v-15.28c0-4.49,3.65-8.27,8.27-8.27h15.28   c4.49,0,8.27,3.65,8.27,8.27v15.28C73.28,335.21,69.64,338.72,65.01,338.72'
        />
        <path
          id='XMLID_7_'
          style={{ fill: '#BA2F7D' }}
          d='M120.81,219.97h-10.51c-3.08,0-5.61-2.52-5.61-5.61v-10.51c0-3.08,2.52-5.61,5.61-5.61h10.51   c3.08,0,5.61,2.52,5.61,5.61v10.51C126.7,217.31,124.03,219.97,120.81,219.97'
        />
        <path
          id='XMLID_8_'
          style={{ fill: '#26478D' }}
          d='M351.99,226.98c3.36,0,6.03-2.8,6.03-6.03c0-3.36-2.8-6.03-6.03-6.03   c-3.36,0-6.17,2.8-6.17,6.03C345.83,224.18,348.49,226.98,351.99,226.98z'
        />
        <path
          id='XMLID_9_'
          style={{ fill: '#26478D' }}
          d='M129.92,291.89c-0.84,1.4-2.38,2.1-4.07,2.1c-0.84,0-2.1-0.14-2.8-0.7   c-1.4-0.84-2.24-2.52-2.24-4.35c0-0.84,0.14-1.68,0.7-2.38l14.02-22.15l-14.02-22.15c-0.56-0.7-0.7-1.68-0.7-2.38   c0-1.68,0.84-3.36,2.38-4.35c0.84-0.56,1.68-0.7,2.52-0.7c1.68,0,3.22,0.7,4.35,2.1l11.5,18.23l11.64-18.23   c0.84-1.4,2.52-2.1,4.35-2.1c0.84,0,1.68,0.14,2.52,0.7c1.54,0.84,2.38,2.52,2.38,4.35c0,0.84-0.14,1.68-0.7,2.38l-14.02,22.15   l14.02,22.15c0.56,0.7,0.7,1.54,0.7,2.38c0,1.68-0.84,3.65-2.24,4.35c-0.84,0.56-1.82,0.7-2.8,0.7c-1.68,0-3.08-0.7-4.07-2.1   l-11.64-18.09L129.92,291.89z'
        />
        <path
          id='XMLID_12_'
          style={{ fill: '#26478D' }}
          d='M180.25,241.56c5.33-4.77,12.48-7.71,20.33-7.71c16.96,0,30.42,13.6,30.42,30.42   c0,16.68-13.6,30.28-30.42,30.28c-7.71,0-15-2.94-20.33-7.71v25.94c0,2.52-2.38,4.77-5.05,4.77c-2.38,0-5.05-2.24-5.05-4.77v-73.6   c0-2.52,2.38-4.77,5.05-4.77c2.8,0,5.05,2.24,5.05,4.77V241.56z M200.3,243.94c-11.36,0-20.33,8.97-20.33,20.33   c0,11.36,8.97,20.33,20.33,20.33c11.36,0,20.33-8.97,20.33-20.33C220.77,252.92,211.8,243.94,200.3,243.94z'
        />
        <path
          id='XMLID_13_'
          style={{ fill: '#26478D' }}
          d='M316.95,258.8v30.42c0,2.52-2.38,4.77-5.05,4.77c-2.8,0-5.05-2.24-5.05-4.77v-50.05   c0-2.52,2.38-4.77,5.05-4.77c2.38,0,4.77,2.1,5.05,4.63c4.07-3.08,9.39-5.05,15.14-5.05c3.08,0,5.47,2.38,5.47,5.05   c0,2.8-2.38,5.19-5.47,5.19C324.24,244.36,316.95,247.03,316.95,258.8z'
        />
        <path
          id='XMLID_14_'
          style={{ fill: '#26478D' }}
          d='M351.99,234.41c-2.8,0-5.05,2.24-5.05,4.77v50.19c0,2.52,2.38,4.77,5.05,4.77   c2.38,0,5.05-2.24,5.05-4.77v-50.19C356.9,236.65,354.52,234.41,351.99,234.41L351.99,234.41z'
        />
        <path
          id='XMLID_17_'
          style={{ fill: '#26478D' }}
          d='M415.78,239.18c0-2.52,2.38-4.77,5.05-4.77c2.38,0,5.05,2.24,5.05,4.77v50.19   c0,2.52-2.38,4.77-5.05,4.77c-2.8,0-5.05-2.24-5.05-4.77v-2.52c-5.47,4.77-12.48,7.71-20.33,7.71c-16.96,0-30.42-13.6-30.42-30.42   c0-16.68,13.6-30.28,30.42-30.28c7.71,0,14.72,2.94,20.33,7.71V239.18z M395.6,284.46c11.36,0,20.33-8.97,20.33-20.33   c0-11.36-8.97-20.33-20.33-20.33s-20.33,8.97-20.33,20.33C375.27,275.63,384.24,284.46,395.6,284.46z'
        />
        <path
          id='XMLID_18_'
          style={{ fill: '#26478D' }}
          d='M476.77,259.36c0-8.41-7.15-15.56-15.56-15.56c-8.13,0-15.56,3.22-15.56,15.14v30.42   c0,2.52-2.38,4.77-5.05,4.77c-2.8,0-5.05-2.24-5.05-4.77v-50.19c0-2.52,2.38-4.77,5.05-4.77c2.38,0,4.77,2.1,5.05,4.63   c4.35-3.22,9.67-5.05,15.42-5.05c13.6,0,25.8,10.65,25.8,23.97v31.26c0,2.52-2.38,4.77-5.05,4.77c-2.8,0-5.05-2.24-5.05-4.77   L476.77,259.36L476.77,259.36z'
        />
        <path
          id='XMLID_21_'
          style={{ fill: '#26478D' }}
          d='M117.87,262.59c-0.14-15.42-13.88-28.46-30.28-28.46c-16.96,0-30.42,13.6-30.42,30.28   c0,16.96,13.6,30.42,30.42,30.42c8.13,0,15.56-3.08,21.17-8.55c0.98-0.84,1.68-2.38,1.68-3.79c0-2.8-2.38-5.05-5.05-5.05   c-1.54,0-2.94,0.84-3.93,2.1c-3.36,3.65-8.41,5.33-13.74,5.33c-10.09,0-18.37-6.87-20.05-16.54h45.28h0.14h0.14h0.14l0.56-0.14   C116.18,267.22,117.87,265.11,117.87,262.59L117.87,262.59z M87.72,243.94c9.11,0,16.68,5.89,19.21,14.02H68.24   C70.9,249.83,78.47,243.94,87.72,243.94z'
        />
        <path
          id='XMLID_24_'
          style={{ fill: '#26478D' }}
          d='M298.58,262.59c-0.14-15.42-14.02-28.46-30.28-28.46c-16.96,0-30.42,13.6-30.42,30.28   c0,16.96,13.6,30.42,30.42,30.42c8.13,0,15.56-3.08,21.17-8.55c0.98-0.84,1.68-2.38,1.68-3.79c0-2.8-2.38-5.05-5.05-5.05   c-1.54,0-2.94,0.84-3.93,2.1c-3.36,3.65-8.41,5.33-13.74,5.33c-10.09,0-18.37-6.87-20.05-16.54h45.28h0.14h0.14h0.28h0.14   C296.62,267.36,298.58,265.11,298.58,262.59L298.58,262.59z M268.3,243.94c9.11,0,16.68,5.89,19.21,14.02h-38.55   C251.47,249.83,259.04,243.94,268.3,243.94z'
        />
        <path
          id='XMLID_25_'
          style={{ fill: '#26478D' }}
          d='M497.38,294.13c-0.56,0-0.84-0.28-0.84-0.84v-8.41h-1.82c-0.56,0-0.84-0.28-0.84-0.84   s0.28-0.84,0.84-0.84h5.47c0.56,0,0.84,0.28,0.84,0.84s-0.28,0.84-0.84,0.84h-1.82v8.41C498.22,293.71,497.94,294.13,497.38,294.13   z'
        />
        <path
          id='XMLID_26_'
          style={{ fill: '#26478D' }}
          d='M507.19,294.13c-0.56,0-0.7-0.28-0.84-0.7l-2.38-6.87v6.73c0,0.56-0.28,0.84-0.84,0.84   s-0.84-0.28-0.84-0.84v-8.97c0-0.7,0.56-1.26,1.26-1.26c0.56,0,0.98,0.28,1.26,0.84l2.38,7.01l2.38-7.01   c0.14-0.56,0.7-0.84,1.26-0.84c0.7,0,1.26,0.56,1.26,1.26v8.97c0,0.56-0.28,0.84-0.84,0.84c-0.56,0-0.84-0.28-0.84-0.84v-6.73   c-0.28,0.98-0.84,2.8-1.4,4.07c-0.84,2.8-0.98,2.8-0.98,2.8C507.89,293.99,507.47,294.13,507.19,294.13L507.19,294.13z'
        />
      </g>
    </svg>
  );
}

export default Experian;
