import { Cell } from '../GridInGrid/engine';
import useCanvasRefs from './useCanvasRefs';
import useElementDrag, {
  ElementDragOperation,
  FormElementIdentifier
} from '../../CustomDragLayer/hooks/useElementDrag';
import useSubgridDrag, {
  SubgridIdentifier
} from '../../CustomDragLayer/hooks/useSubgridDrag';
import { ControlLayerDetails, ControlReference } from './ControlLayer';
import { ConnectDragSource, useDrop } from 'react-dnd';
import { onDropWrapper } from '../GridInGrid/components/utils';
import useFeatheryRedux from '../../../redux';
import { useGig } from '../../../context/Gig';
import { useStore } from 'react-redux';
import React, { useEffect } from 'react';
import { useAppSelector } from '../../../hooks';
import testIds from '../../../utils/testIds';

const controlLayerDragId = 'control-layer-drag';
const controlLayerDropId = 'control-layer-drop';

/*
  These values cannot be stored in component state for reasons that seem to only affect Playwright.
  TODO(drew): Investigate why this is the case.
 */
let timeoutId: any = null;
let allowDrop = false;

const setAllowDrop = (value: boolean) => {
  allowDrop = value;
};

const setTimeoutId = (value: any) => {
  timeoutId = value;
};

const useNodeDrag = (node?: Cell, isDraggingRef?: any) => {
  const { getData } = useCanvasRefs();
  const data = getData();

  let width: number;
  let height: number;

  const n = !node ? new Cell() : node;

  const adjustedOffset = { x: 0, y: 0 };

  if (node && !isDraggingRef) {
    ControlLayerDetails.draggingNode = node;
    const canvasElement = data.elementMap[node.position.join(',')] || {
      rect: { width: 0, height: 0 }
    };
    width = canvasElement.rect.width;
    height = canvasElement.rect.height;
    const el = document.getElementById(controlLayerDragId);

    if (el) {
      const dragLayer = el.getBoundingClientRect();

      adjustedOffset.y = canvasElement.rect.top - dragLayer.top;
      adjustedOffset.x = canvasElement.rect.left - dragLayer.left;
    }
  }

  if (!node && !isDraggingRef) {
    ControlLayerDetails.draggingNode = null;
  }

  const elementDrag = useElementDrag(() => ({
    operation: ElementDragOperation.Move,
    node: n,
    opts: {
      preview: { width, height, adjustedOffset },
      onEnd: () => (ControlLayerDetails.draggingNode = null)
    }
  }));

  const subgridDrag = useSubgridDrag(n, {
    // @ts-ignore
    preview: { width, height, adjustedOffset },
    onEnd: () => (ControlLayerDetails.draggingNode = null)
  });

  if (!node) return null;
  if (node.isRoot()) return null;

  return node.isElement ? elementDrag : subgridDrag;
};

const useNodeDrop = (onDrop = () => {}) => {
  const {
    formBuilder,
    toasts: { addToast }
  } = useFeatheryRedux();

  const { gig } = useGig();

  const store = useStore();

  const [, dropRef] = useDrop(() => {
    return {
      accept: [FormElementIdentifier, SubgridIdentifier],
      collect: (monitor) => {
        return {
          isOver: monitor.isOver({ shallow: true }),
          canDrop: true
        };
      },
      drop: (item) => {
        if (onDrop) onDrop();
        if (!allowDrop) return;
        const controls = ControlLayerDetails.controlState;
        if (!controls) return;

        const sideControl = controls.sideControl;
        const insideControl = controls.insideControl;

        if (sideControl?.canvasReference.node) {
          onDropWrapper(
            sideControl.canvasReference.node,
            `add${sideControl.side}`,
            item,
            store,
            formBuilder,
            addToast
          );
        } else if (insideControl?.canvasReference.node) {
          onDropWrapper(
            insideControl.canvasReference.node,
            'setElement',
            item,
            store,
            formBuilder,
            addToast
          );
        }
      }
    };
  }, [ControlLayerDetails.controlState?.insideControl, gig]);

  return dropRef;
};

export type dragLayerProps = (
  dragRef: ConnectDragSource | null,
  dragStyle: React.CSSProperties,
  dragId: string
) => React.ReactNode;
interface ControlLayerDragDrop {
  insideControl?: ControlReference;
  sideControl?: ControlReference;
  canvasRect?: DOMRect;
  children: dragLayerProps;
  onDrop: () => void;
}

const ControlLayerDragDrop: React.FC<ControlLayerDragDrop> = ({
  insideControl,
  sideControl,
  canvasRect,
  children,
  onDrop = () => {}
}) => {
  const isDragging = useAppSelector(
    ({ formBuilder }) => formBuilder.isDragging
  );

  const dragRef = useNodeDrag(insideControl?.canvasReference.node, isDragging);
  const dropRef = useNodeDrop(onDrop);

  const hasControl = insideControl?.id || !!sideControl;
  const isDroppable = isDragging && hasControl;

  /*
   Very short buffer to prevent accidental fast drag/drop micro movements
   Unfortunately this is a bit of a hack to get around ReactDnD limitations of implementing a true delay
   */
  useEffect(() => {
    if (!isDragging) {
      setAllowDrop(false);
      if (timeoutId) {
        clearTimeout(timeoutId);
        setTimeoutId(null);
      }
      return;
    }
    const id = setTimeout(() => {
      if (isDragging) setAllowDrop(true);
    }, 150);
    setTimeoutId(id);
  }, [isDragging]);

  const dragStyle: React.CSSProperties = {
    width: '100%',
    height: canvasRect?.height,
    position: 'absolute',
    pointerEvents: isDragging ? 'none' : 'auto'
  };

  return (
    <>
      <div
        data-testid={testIds.controlLayerDrop}
        id={controlLayerDropId}
        ref={dropRef}
        style={{
          position: 'absolute',
          top: -25,
          left: -25,
          width: 'calc(100% + 50px)',
          height: (canvasRect?.height || 0) + 50,
          zIndex: isDroppable ? 200 : -1
        }}
      />
      {children(dragRef, dragStyle, controlLayerDragId)}
    </>
  );
};

export default ControlLayerDragDrop;
