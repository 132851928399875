function stripPosition(position: string) {
  return position.toLowerCase().replace('padding', '').replace('margin', '');
}
export function isInverted(position: string) {
  position = stripPosition(position);
  return 'left' === position || 'bottom' === position;
}

export function isVertical(position: string) {
  position = stripPosition(position);

  return 'top' === position || 'bottom' === position;
}

export function getDistance(x1: number, y1: number, x2: number, y2: number) {
  const a = x1 - x2;
  const b = y1 - y2;
  return Math.sqrt(a * a + b * b);
}

export function getCursor(position: string | null) {
  if (!position) return null;
  position = stripPosition(position);

  switch (position) {
    case 'top':
      return 'n-resize';
    case 'right':
      return 'e-resize';
    case 'bottom':
      return 's-resize';
    case 'left':
      return 'w-resize';
  }
  return null;
}

export function onKeyDown(e: any) {
  if (['-', '+'].includes(e.key)) {
    e.preventDefault();
    e.stopPropagation();
  } else if (e.key === 'Enter') {
    e.target.blur();
  }
}

export function contentEditableSelectAll(event: any) {
  window.setTimeout(() => {
    let sel, range;
    if (window.getSelection && document.createRange) {
      range = document.createRange();
      range.selectNodeContents(event.target);
      sel = window.getSelection();
      sel?.removeAllRanges();
      sel?.addRange(range);
    }
  }, 1);
}
