import { memo, useState } from 'react';

import ElementBlockPanel from './ElementBlockPanel';
import SearchBar from '../../SelectionPanel/SearchBar';
import { objectFilter } from '../../../utils/core';
import elementEntries from '../../SelectionPanel/elementEntries';
import ElementListPanel from './ElementListPanel';
import useFeatheryRedux from '../../../redux';
import { BlockViewIcon, ListViewIcon } from '../../Icons';
import { UNDO_TITLES, UNDO_TYPES } from '../../../utils/constants';

import styles from './styles.module.scss';
import { useAppSelector } from '../../../hooks';

export function DEFAULT_CATEGORIES(elements: any) {
  return [
    {
      title: 'Layout',
      keys: Object.keys(elements).filter(
        (key) => elements[key].type === 'layout'
      )
    },
    {
      title: 'Basic',
      keys: Object.keys(elements).filter(
        (key) => elements[key].type === 'basic'
      )
    },
    {
      title: 'Fields',
      keys: Object.keys(elements).filter(
        (key) => elements[key].type === 'field'
      )
    }
  ];
}

function filterCategories(categories: any, elements: any) {
  return categories.map((category: any) => {
    return {
      title: category.title,
      keys: category.keys.filter((key: any) => elements[key])
    };
  });
}

function ElementPanel({
  elements = {},

  // Valid modes: 'drag' (step builder) or 'nav' (theme builder)
  mode = 'drag',

  onElementMouseDown = () => {},
  categories,
  handleUpdates,
  context = 'builder',
  deleteAssetFromTheme
}: any) {
  const [searchResults, setSearchResults] = useState([]);

  // The ElementPanel could exist in the theme builder or the flow builder
  // So we have to change which redux module to use accordingly
  const reduxModule = mode === 'drag' ? 'formBuilder' : 'themeBuilder';
  const theme = useAppSelector((state) => state[reduxModule].theme);
  const elementSelectView = useAppSelector(
    (state) => state.accounts.account.element_select_view
  );
  const reduxActions = useFeatheryRedux();
  const { editAccount } = reduxActions;
  const setTheme =
    reduxModule === 'themeBuilder'
      ? reduxActions[reduxModule].setTheme
      : // If in formBuilder, use undo stack for theme changes
        ({ theme: newTheme }: { theme: any }) => {
          reduxActions[reduxModule].addThemeToUndoStack({
            id: theme.id,
            oldValue: theme,
            newValue: newTheme,
            title: UNDO_TITLES.THEME,
            type: UNDO_TYPES.THEME
          });
        };

  const isBlock = elementSelectView === 'block';
  const Panel = isBlock ? ElementBlockPanel : ElementListPanel;

  const finalResults =
    searchResults.length > 0 ? searchResults : Object.keys(elements);
  const filteredElements = objectFilter(elementEntries, ({ key }) =>
    finalResults.includes(key)
  );
  const filteredCategories = categories
    ? filterCategories(categories, filteredElements)
    : DEFAULT_CATEGORIES(filteredElements);

  return (
    <>
      <div className={styles.header}>
        <SearchBar
          elementEntries={elements}
          onSearch={setSearchResults}
          padding={false}
        />
        <div
          className={styles.panelViewToggle}
          onClick={() => {
            const newView = isBlock ? 'list' : 'block';
            newView !== elementSelectView &&
              editAccount({
                element_select_view: newView
              });
          }}
        >
          {isBlock ? <ListViewIcon /> : <BlockViewIcon />}
        </div>
      </div>
      <Panel
        elements={filteredElements}
        mode={mode}
        theme={theme}
        setTheme={setTheme}
        handleUpdates={handleUpdates}
        onElementMouseDown={onElementMouseDown}
        categories={filteredCategories}
        context={context}
        deleteAssetFromTheme={deleteAssetFromTheme}
      />
    </>
  );
}

export default memo(ElementPanel);
