import { useEffect, useState } from 'react';

import sidebarStyles from '../../../components/FormFieldEditors/formFieldEditor.module.scss';
import formFieldEditorStyles from '../../../components/FormFieldEditors/formFieldEditor.module.scss';
import FieldPanel from './FieldPanel';
import DefaultPanel from './DefaultPanel';

type Props = {
  doc: any;
  activeAnnotatedField: any;
  setActiveAnnotatedField: any;
  hidePages: any;
  setHidePages: any;
  setShowModal: any;
  formFieldMap: any;
  editField: any;
  updateFields: any;
  addToast: any;
  pdfField: any;
  validateFieldMapping: any;
  numPages: any;
};

const EditorPanel = (props: Props) => {
  const { activeAnnotatedField, validateFieldMapping } = props;

  // validation of mapping properties
  const [propertyError, setPropertyError] = useState('');

  useEffect(() => {
    setPropertyError(validateFieldMapping(activeAnnotatedField));
  }, [activeAnnotatedField, setPropertyError]);

  const Panel = activeAnnotatedField ? FieldPanel : DefaultPanel;
  return (
    <div className={sidebarStyles.formFieldEditorDialog}>
      <div className={sidebarStyles.editorBody}>
        <Panel {...props} />
      </div>
      {propertyError && (
        <div className={formFieldEditorStyles.error}>{propertyError}</div>
      )}
    </div>
  );
};

export default EditorPanel;
