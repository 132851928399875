import { useAppSelector } from '../../hooks';
import { useParams } from 'react-router-dom';

const DEFAULT_METRICS = {
  new_submissions: {
    value: '0',
    trend: 0,
    over_time: []
  },
  new_viewers: {
    value: '0',
    trend: -1.2,
    over_time: []
  },
  average_time: {
    value: '0s',
    trend: 0,
    over_time: []
  },
  average_completion_rate: {
    value: '0%',
    trend: 0,
    over_time: []
  },
  step_metrics: []
};

export default function useMetrics(): any {
  const { formId } = useParams<{ formId: string }>();
  return (
    useAppSelector((state) => state.metrics.panels[formId]) ?? DEFAULT_METRICS
  );
}
