import { memo } from 'react';
import Dialog from '../Dialog';
import styles from './styles.module.scss';
import { formatNumeric } from '../../utils/format';
import classNames from 'classnames';

function SubmissionSummaryModal({
  submissionMetrics = [],
  show = false,
  setShow = () => {}
}) {
  return (
    <Dialog
      isOpen={show}
      // @ts-expect-error TS(2554) FIXME: Expected 0 arguments, but got 1.
      onClose={() => setShow(false)}
      shouldShowCloseIcon
      title='Submissions'
      size='xsm'
    >
      <div className={styles.submissionSummary}>
        <div className={styles.subTitle}>Per Form</div>

        <div className={styles.submissionsList}>
          <table className={styles.submissionsListTable}>
            <tbody>
              {submissionMetrics.map((m) => (
                <tr key={(m as any).id}>
                  <td className={classNames(styles.cell, styles.count)}>
                    {formatNumeric((m as any).submissions)}
                  </td>
                  <td
                    className={classNames(styles.cell, styles.name)}
                    title={(m as any).formName}
                  >
                    {(m as any).formName}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Dialog>
  );
}

export default memo(SubmissionSummaryModal);
