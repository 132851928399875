import { useState } from 'react';
import { ChevronDownIcon } from '../Icons';
import styles from './styles.module.scss';

const TEXT_LENGTH_THRESHOLD = 300;

const ExpandableText = ({ text = '' }) => {
  const [expanded, setExpanded] = useState(false);

  if (!text) return null;
  if (text.length < TEXT_LENGTH_THRESHOLD)
    return (
      <div
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        style={{ cursor: 'text' }}
      >
        {text}
      </div>
    );

  const toDisplay = expanded
    ? text
    : [
        text.substring(0, TEXT_LENGTH_THRESHOLD) + '...',
        <ChevronDownIcon key='overflow' className={styles.expandableTextIcon} />
      ];
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        setExpanded(!expanded);
      }}
    >
      {toDisplay}
    </div>
  );
};

export default ExpandableText;
