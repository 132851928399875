import deepDiff, { diff as findDiff } from 'deep-diff';

export const diff = (a: any, b: any, ignoreKeys?: string[]) => {
  const results = findDiff(a, b);

  if (results && ignoreKeys) {
    return results
      .filter(isRemovedEmptyOptionImages)
      .filter(
        (result) =>
          result.path &&
          !ignoreKeys.includes(pathArrayToDotNotation(result.path))
      );
  }

  return results;
};

export const hasDiffs = (a: any, b: any, ignoreKeys?: string[]): boolean => {
  const results = diff(a, b, ignoreKeys);
  return Array.isArray(results) && results.length > 0;
};

const pathArrayToDotNotation = (path: any[]): string => {
  return path.reduce((acc: string, cur: string | number, i: number) => {
    if (i === 0) return cur;
    return typeof cur === 'number' ? acc : `${acc}.${cur}`;
  }, '');
};

// Ignore diffs for removing empty option_images arrays
// This prevents unnecessary diffs for a difference in default values
// option_images: [] vs option_images: undefined should be equivalent
// so the servar modified warning is not shown for no reason (PR #2092)
const isRemovedEmptyOptionImages = (result: deepDiff.Diff<any, any>) => {
  // if kind != delete then return true
  if (result.kind !== 'D') return true;

  // if path != ['option_images'] then return true
  if (!result.path) return true;
  if (result.path.length !== 1) return true;
  if (result.path[0] !== 'option_images') return true;

  // if lhs is not an empty array then return true
  if (!Array.isArray(result.lhs)) return true;
  if (result.lhs.length !== 0) return true;

  return false;
};

export default { diff, hasDiffs };
