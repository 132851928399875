const Matrix = ({ color = '#414859', ...props }) => {
  return (
    <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect
        width='4.21053'
        height='4.21053'
        rx='2.10526'
        transform='matrix(1 0 0 -1 11.2104 15.421)'
        fill={color}
      />
      <rect
        x='7'
        y='7'
        width='12.6316'
        height='12.6316'
        rx='6.31579'
        stroke={color}
        strokeWidth='3'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <rect
        width='12.6316'
        height='12.6316'
        rx='6.31579'
        transform='matrix(1 0 0 -1 27.6316 19.6316)'
        stroke={color}
        strokeWidth='3'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <rect
        x='7'
        y='27.6316'
        width='12.6316'
        height='12.6316'
        rx='6.31579'
        stroke={color}
        strokeWidth='3'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <rect
        x='31.842'
        y='31.8422'
        width='4.21053'
        height='4.21053'
        rx='2.10526'
        fill={color}
      />
      <rect
        width='12.6316'
        height='12.6316'
        rx='6.31579'
        transform='matrix(1 0 0 -1 27.6316 40.2632)'
        stroke={color}
        strokeWidth='3'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Matrix;
