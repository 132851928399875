import React, { RefObject, useEffect } from 'react';
import { useAppSelector } from '../../../hooks';
import useViewport from '../../../hooks/useViewport';
import {
  getElementStyle,
  getElementStyleType
} from '../GridInGrid/components/CellElement/utils';
import { ControlReference } from './ControlLayer';
import {
  getMarginPaddingConfig,
  getPaddingName
} from '../../../utils/boxSpacingHelper';

// padding pattern
const paddingPattern = document.createElement('canvas');
paddingPattern.width = 7;
paddingPattern.height = 7;
const paddingPatternCtx = paddingPattern.getContext('2d');

if (paddingPatternCtx) {
  paddingPatternCtx.strokeStyle = 'rgba(0,0,250,0.25)';
  paddingPatternCtx.beginPath();
  paddingPatternCtx.moveTo(0, 7);
  paddingPatternCtx.lineTo(7, 0);
  paddingPatternCtx.moveTo(-1, 1);
  paddingPatternCtx.lineTo(1, -1);
  paddingPatternCtx.moveTo(6, 8);
  paddingPatternCtx.lineTo(8, 6);
  paddingPatternCtx.lineWidth = 1;
  paddingPatternCtx.lineCap = 'round';
  paddingPatternCtx.stroke();
}

const PaddingDisplay = React.memo(function PaddingDisplay(props: {
  control: ControlReference;
}) {
  const canvasRef: RefObject<HTMLCanvasElement> = React.useRef(null);

  const control = props.control;

  if (!control) return null;

  const element = control?.canvasReference?.ref?.current;
  const nodeElement =
    control?.canvasReference?.node?.unlinked()?.element?.state;

  const theme = useAppSelector((s) => s.formBuilder.theme);
  const { viewport } = useViewport();

  let computedStyle = {};
  if (nodeElement) {
    const { style } = getElementStyleType(nodeElement);
    computedStyle = getElementStyle(nodeElement, viewport, theme, style);
  } else {
    computedStyle = control?.canvasReference?.node?.getStyle();
  }

  const getPaddingValue = (
    style: any,
    side: 'top' | 'right' | 'bottom' | 'left'
  ): number => {
    const type = nodeElement?._type || 'container';
    const config = getMarginPaddingConfig(type);
    const paddingName = getPaddingName(config);
    return style[`${paddingName}_${side}`];
  };

  const getRectPointsObj = (div: HTMLDivElement) => {
    const rect = div.getBoundingClientRect();

    return {
      topLeft: { x: 0, y: 0 },
      topRight: { x: rect.width, y: 0 },
      bottomRight: { x: rect.width, y: rect.height },
      bottomLeft: { x: 0, y: rect.height }
    };
  };

  const getRectPoints = (div: HTMLDivElement) => {
    const corners = getRectPointsObj(div);

    return [
      corners.topLeft,
      corners.topRight,
      corners.bottomRight,
      corners.bottomLeft
    ];
  };

  const getPaddingPoints = (div: HTMLDivElement) => {
    const corners = getRectPointsObj(div);

    const topPadding = getPaddingValue(computedStyle, 'top');
    const rightPadding = getPaddingValue(computedStyle, 'right');
    const bottomPadding = getPaddingValue(computedStyle, 'bottom');
    const leftPadding = getPaddingValue(computedStyle, 'left');

    if (topPadding) {
      corners.topLeft.y += topPadding;
      corners.topRight.y += topPadding;
    }

    if (rightPadding) {
      corners.topRight.x -= rightPadding;
      corners.bottomRight.x -= rightPadding;
    }

    if (bottomPadding) {
      corners.bottomRight.y -= bottomPadding;
      corners.bottomLeft.y -= bottomPadding;
    }

    if (leftPadding) {
      corners.bottomLeft.x += leftPadding;
      corners.topLeft.x += leftPadding;
    }

    return [
      corners.topLeft,
      corners.topRight,
      corners.bottomRight,
      corners.bottomLeft
    ];
  };

  const drawPadding = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const context = canvas.getContext('2d');
    if (!context) return;

    if (!element) return;

    const rectPoints = getRectPoints(element);
    const paddingPoints = getPaddingPoints(element);

    context.clearRect(
      0,
      0,
      control.canvasReference.rect.width,
      control.canvasReference.rect.height
    );

    const pattern: CanvasPattern | null = context.createPattern(
      paddingPattern,
      'repeat'
    );
    if (pattern) context.fillStyle = pattern;
    context.beginPath();

    context.moveTo(0, 0);
    for (let i = 1; i < rectPoints.length; ++i) {
      const p = rectPoints[i];
      context.lineTo(p.x, p.y);
    }

    context.closePath();
    context.fill();

    context.globalCompositeOperation = 'destination-out';

    context.fillStyle = '#00f';
    context.beginPath();

    context.moveTo(paddingPoints[0].x, paddingPoints[0].y);
    for (let i = 1; i < paddingPoints.length; ++i) {
      const p = paddingPoints[i];
      context.lineTo(p.x, p.y);
    }

    context.closePath();
    context.fill();

    context.globalCompositeOperation = 'source-over';
  };

  useEffect(() => {
    drawPadding();
  }, [
    control.canvasReference,
    control.canvasReference.rect.width,
    control.canvasReference.rect.height
  ]);

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
      }}
    >
      <canvas
        width={control.canvasReference.rect.width}
        height={control.canvasReference.rect.height}
        ref={canvasRef}
      />
    </div>
  );
});

export default PaddingDisplay;
