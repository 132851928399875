export default function AddImageIcon({
  width = 32,
  height = 32,
  color = '#414859'
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M25.3876 16.4128V7.00006C25.3876 5.89549 24.4922 5.00005 23.3876 5.00005H7C5.89543 5.00005 5 5.89548 5 7.00005V23.304C5 24.4085 5.89543 25.304 7 25.304H16.7567'
        stroke={color}
        strokeWidth='3'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21.6267 25.304H28.9999'
        stroke={color}
        strokeWidth='2.8947'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M25.3381 29V21.6571'
        stroke={color}
        strokeWidth='2.8947'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5 17.6714L11.8784 10.8213L18.2907 17.2566'
        stroke={color}
        strokeWidth='3'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.5 17.4487L21.9237 13.384L25 16.4528'
        stroke={color}
        strokeWidth='3'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
