import { OPERATOR_CODE } from '@feathery/react';
import { FIELD_OPERATORS } from '../../../../../../utils/validation';
import { RuleOperand } from '../RuleOperand';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { Fragment } from 'react';

type ViewModeProps = {
  expression: IRuleExpression;
};

export const ViewMode = ({ expression }: ViewModeProps) => {
  if (!expression.operands.length) {
    return null;
  }

  const operator = FIELD_OPERATORS[expression.operator as OPERATOR_CODE];

  return (
    <div
      className={classNames(
        styles.conditionalExpressionContainer,
        styles.viewMode
      )}
    >
      <RuleOperand operand={expression.operands[0] as IRuleOperand} />
      <div className={styles.operator}>{operator?.display}</div>
      {!operator?.noValues &&
        expression.operands.slice(1).map((operand, index) => (
          <Fragment key={operand.id}>
            <RuleOperand operand={operand as IRuleOperand} />
            {index < expression.operands.length - 2 && (
              <div className={styles.operator}>or</div>
            )}
          </Fragment>
        ))}
    </div>
  );
};
