const monthOptions = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];
export const DATE_MULTISELECT_OPTIONS: { value: string; label: string }[] = [];
monthOptions.forEach((month, i) => {
  for (let j = 1; j < 32; j++) {
    DATE_MULTISELECT_OPTIONS.push({
      value: `${i + 1}-${j}`,
      label: `${month} ${j}`
    });
  }
});
