function Mulesoft({ width = 64, height = 64 }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 64 64'
    >
      <g transform='matrix(1.918961 0 0 1.918961 -9.594807 -25.568816)'>
        <g transform='matrix(.102746 0 0 .102746 4.52737 13.01093)'>
          <defs>
            <path id='A' d='M4.6 3.7h1095.6V327H4.6z' />
          </defs>
          <clipPath id='B'>
            <use xlinkHref='#A' />
          </clipPath>
          <path
            clipPath='url(#B)'
            d='M166.9 314.5c-81.8 0-148.1-66.3-148.1-148.1C18.7 84.5 85 18.2 166.9 18.2c81.8 0 148.1 66.3 148.1 148.1s-66.3 148.2-148.1 148.2zm-.1-310.8C77.3 3.7 4.5 76.2 4.5 165.3 4.5 254.5 77.3 327 166.8 327s162.4-72.5 162.4-161.6c0-89.2-72.8-161.7-162.4-161.7z'
            fill='#00a1df'
          />
        </g>
        <path
          d='M17.443 37.454c-2.517-1.182-4.264-3.853-4.264-6.946 0-1.634.483-3.144 1.315-4.377l5.487 8.18h3.32l5.487-8.18c.832 1.233 1.315 2.754 1.315 4.377 0 2.887-1.52 5.394-3.76 6.69l1.058 4.048c4.028-2.075 6.77-6.268 6.77-11.107 0-4.572-2.456-8.56-6.113-10.737l-6.37 9.833-6.31-9.874c-3.7 2.168-6.185 6.185-6.185 10.778 0 5.014 2.95 9.34 7.213 11.323z'
          fill='#00a1df'
        />
      </g>
    </svg>
  );
}

export default Mulesoft;
