export default function PhoneNumberIcon() {
  return (
    <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M37.076 30.7886C36.2475 30.7886 35.576 31.4601 35.576 32.2886C35.576 33.117 36.2475 33.7886 37.076 33.7886V30.7886ZM18.3738 12.0617C17.5454 12.0617 16.8738 12.7333 16.8738 13.5617C16.8738 14.3902 17.5454 15.0617 18.3738 15.0617V12.0617ZM41.5605 30.7886H37.076V33.7886H41.5605V30.7886ZM42.5 29.8459C42.5 30.3684 42.0775 30.7886 41.5605 30.7886V33.7886C43.7381 33.7886 45.5 32.0215 45.5 29.8459H42.5ZM42.5 16.0044V29.8459H45.5V16.0044H42.5ZM41.5605 15.0617C42.0775 15.0617 42.5 15.4819 42.5 16.0044H45.5C45.5 13.8288 43.7381 12.0617 41.5605 12.0617V15.0617ZM18.3738 15.0617H41.5605V12.0617H18.3738V15.0617Z'
        fill='#414859'
      />
      <path
        d='M14.1878 17.0509L10.1587 12.4002C9.69426 11.8642 8.84272 11.8666 8.30322 12.4073L4.98996 15.7265C4.00385 16.7138 3.72159 18.1799 4.29206 19.3554C7.70014 26.4118 13.3911 32.1107 20.4427 35.5286C21.617 36.0991 23.0818 35.8168 24.068 34.8295L27.4122 31.4794C27.9541 30.9375 27.9553 30.0812 27.4146 29.6167L22.746 25.6091C22.2577 25.1899 21.4991 25.2447 21.0096 25.7354L19.3851 27.3622C19.3019 27.4494 19.1925 27.5068 19.0735 27.5258C18.9545 27.5447 18.8326 27.524 18.7265 27.467C16.0712 25.9379 13.8686 23.7324 12.343 21.0751C12.2858 20.9688 12.2651 20.8467 12.2841 20.7276C12.303 20.6084 12.3605 20.4987 12.4478 20.4153L14.0675 18.7944C14.5581 18.3014 14.6117 17.5392 14.1878 17.0497V17.0509Z'
        stroke='#414859'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
