import { v4 as uuidv4 } from 'uuid';
import { SET_ACTION } from '../components/RuleAction/constants';
import { EXPRESSION_OPERATORS } from '../components/RuleAction/constants';

export class RuleOperand implements IRuleOperand {
  parent?: RuleExpression | RuleAction;

  id: string;
  type: 'field' | 'value';
  value: string;
  meta: any;

  constructor(operand?: IRuleOperand, parent?: RuleExpression | RuleAction) {
    this.parent = parent;
    this.id = operand?.id ?? uuidv4();
    this.type = operand?.type ?? 'field';
    this.value = operand?.value ?? '';
    this.meta = operand?.meta ?? {};
  }

  remove() {
    this.parent?.removeOperand(this.id);
  }

  toJSON(): IRuleOperand {
    return {
      id: this.id,
      type: this.type,
      value: this.value,
      meta: this.meta
    };
  }

  toText(): string {
    if (this.type === 'field') return this.value;
    else if (this.value && typeof this.value === 'string')
      return `'${this.value}'`;
    else if (Array.isArray(this.value))
      return `${(this.value as any[]).map((value) => `'${value}'`).join(', ')}`;
    return this.value.toString();
  }
}

export class RuleAction implements IRuleAction {
  parent?: RuleClause;

  id: string;
  name: string;
  inputMode: 'value' | 'expression';
  parameters: ParametersTuple;

  constructor(action?: Partial<IRuleAction>, parent?: RuleClause) {
    this.parent = parent;
    this.id = action?.id ?? uuidv4();
    this.name = action?.name ?? '';
    this.parameters = this._setParamsFromTuple(action?.parameters);
    this.inputMode = action?.inputMode ?? 'value';
  }

  _setParamsFromTuple(parameters?: ParametersTuple) {
    return (this.parameters = [
      parameters ? new RuleOperand(parameters[0]) : new RuleOperand(),
      ...(parameters
        ?.slice(1)
        .map((parameter: IRuleOperand | IRuleExpression) =>
          'type' in parameter
            ? new RuleOperand(parameter as any)
            : new RuleExpression(parameter)
        ) ?? [])
    ]);
  }

  addOperand() {
    const operand = new RuleOperand(undefined, this);

    this.parameters.push(operand);

    return operand;
  }

  removeOperand(id: string) {
    // removing first IRuleOperand is not supported nor needed
    this.parameters = [
      this.parameters[0],
      ...this.parameters.slice(1).filter((parameter) => parameter.id !== id)
    ];
  }

  remove() {
    if (this.parent) {
      this.parent.removeAction(this.id);
    }
  }

  setParameters(parameters: ParametersTuple) {
    this.parameters = this._setParamsFromTuple(parameters);
  }

  toJSON(): IRuleAction {
    return {
      id: this.id,
      name: this.name,
      inputMode: this.inputMode,
      parameters: [
        (this.parameters[0] as RuleOperand).toJSON(),
        ...this.parameters
          .slice(1)
          .map((parameter) =>
            (parameter as RuleOperand | RuleExpression).toJSON()
          )
      ]
    };
  }
}

export class RuleExpression implements IRuleExpression {
  parent?: RuleExpression | RuleCondition | RuleClause;

  id: string;
  operator: string;
  operands: (RuleOperand | RuleExpression)[];

  constructor(
    expression?: Partial<IRuleExpression>,
    parent?: RuleExpression | RuleCondition | RuleClause
  ) {
    this.parent = parent;
    this.id = expression?.id ?? uuidv4();
    this.operator = expression?.operator ?? '';
    this.operands = [];
    this.setOperands(expression?.operands ?? []);
  }

  addOperand() {
    const newOperand = new RuleOperand(undefined, this);

    this.operands.push(newOperand);

    return newOperand;
  }

  addExpression() {
    this.operands.push(new RuleExpression(undefined, this));
  }

  removeOperand(id: string) {
    this.operands = this.operands.filter((operand) => operand.id !== id);
  }

  remove() {
    if (this.parent instanceof RuleClause) {
      this.parent.removeAction(this.id);
    } else if (this.parent) {
      this.parent?.removeOperand(this.id);
    }
  }

  toJSON(): IRuleExpression {
    return {
      id: this.id,
      operator: this.operator,
      operands: this.operands.map((operand) => operand.toJSON())
    };
  }
  toText(topLevel = true): string {
    const params = this.operands.map((operand) => operand.toText()).join(', ');
    if (this.operator) {
      // if this operator is one of the infix EXPRESSION_OPERATORS, return the infix expression
      if (EXPRESSION_OPERATORS[this.operator]) {
        const leftOperand = this.operands.length
          ? this.operands[0].toText(false)
          : '';
        const rightOperand =
          this.operands.length > 1 ? this.operands[1].toText(false) : '';
        const lParen = topLevel ? '' : '(';
        const rParen = topLevel ? '' : ')';
        return `${lParen}${leftOperand} ${this.operator} ${rightOperand}${rParen}`;
      }
      // function call notation
      return `${this.operator}(${params})`;
    } else return params;
  }

  setOperands(operands: (IRuleOperand | IRuleExpression)[]) {
    this.operands =
      operands?.map((operand) => {
        if ('type' in operand) {
          return new RuleOperand(operand as IRuleOperand, this);
        } else {
          return new RuleExpression(operand as IRuleExpression, this);
        }
      }) ?? [];
  }
}

export class RuleCondition implements IRuleCondition {
  parent?: RuleCondition | RuleClause;

  id: string;
  operator: 'and' | 'or';
  operands: (RuleExpression | RuleCondition)[];

  constructor(condition?: IRuleCondition, parent?: RuleCondition | RuleClause) {
    this.parent = parent;
    this.id = condition?.id ?? uuidv4();
    this.operator = condition?.operator ?? 'and';
    this.operands =
      condition?.operands.map((operand) => {
        if (['and', 'or'].includes(operand.operator)) {
          return new RuleCondition(operand as IRuleCondition, this);
        } else {
          return new RuleExpression(operand as IRuleExpression, this);
        }
      }) ?? [];
  }

  addExpression() {
    const expression = new RuleExpression({ operator: 'equal' }, this);

    expression.addOperand();
    const secondOperand = expression.addOperand();

    secondOperand.type = 'value';
    secondOperand.meta = {
      ruleIndex: 0
    };

    this.operands.push(expression);
  }

  addCondition() {
    this.operands.push(new RuleCondition(undefined, this));
  }

  removeOperand(id: string) {
    this.operands = this.operands.filter((operand) => operand.id !== id);

    // Remove this condition if there are no operands left
    if (
      !this.operands.length &&
      this.parent &&
      this.parent instanceof RuleClause
    ) {
      this.remove();
    }
  }

  remove() {
    if (this.parent instanceof RuleCondition) {
      this.parent.removeOperand(this.id);
    } else if (this.parent instanceof RuleClause) {
      this.parent.removeCondition();
    }
  }

  toJSON(): IRuleCondition {
    return {
      id: this.id,
      operator: this.operator,
      operands: this.operands.map((operand) => operand.toJSON())
    };
  }
}

export class RuleClause implements IRuleClause {
  parent?: RuleBranch;

  id: string;
  when: RuleCondition | undefined;
  actions: RuleAction[];

  constructor(clause?: IRuleClause, parent?: RuleBranch) {
    this.parent = parent;
    this.id = clause?.id ?? uuidv4();
    this.when = clause?.when ? new RuleCondition(clause.when, this) : undefined;
    this.actions =
      clause?.actions.map((action) => new RuleAction(action, this)) ?? [];
  }

  addCondition() {
    if (!this.when) {
      this.when = new RuleCondition(undefined, this);
      this.when.addExpression();
    }
  }

  removeCondition() {
    this.when = undefined;
  }

  addAction() {
    const action = new RuleAction({ name: SET_ACTION }, this);

    action.addOperand();

    if ('type' in action.parameters[1]) action.parameters[1].type = 'value';

    this.actions.push(action);
  }

  setActions(actions: IRuleAction[]) {
    this.actions = actions.map((action) => new RuleAction(action, this));
  }

  removeAction(id: string) {
    this.actions = this.actions.filter((action) => action.id !== id);
  }

  remove() {
    this.parent?.removeClause(this.id);
  }

  toJSON(): IRuleClause {
    return {
      id: this.id,
      when: this.when?.toJSON(),
      actions: this.actions.map((action) => action.toJSON())
    };
  }
}

export class RuleBranch implements IRuleBranch {
  parent?: RuleDSL;

  id: string;
  clauses: RuleClause[];

  constructor(branch?: IRuleBranch, parent?: RuleDSL) {
    this.parent = parent;
    this.id = branch?.id ?? uuidv4();
    this.clauses =
      branch?.clauses.map((clause) => new RuleClause(clause, this)) ?? [];
  }

  addClause() {
    const clause = new RuleClause(undefined, this);
    clause.addAction();

    this.clauses.push(clause);

    return clause;
  }

  removeClause(id: string) {
    this.clauses = this.clauses.filter((clause) => clause.id !== id);
  }

  remove() {
    this.parent?.removeBranch(this.id);
  }

  toJSON(): IRuleBranch {
    return {
      id: this.id,
      clauses: this.clauses.map((clause) => clause.toJSON())
    };
  }
}

export class RuleDSL implements IRuleDSL {
  branches: RuleBranch[];

  constructor(dsl?: IRuleDSL) {
    this.branches =
      dsl?.branches.map((branch) => new RuleBranch(branch, this)) ?? [];
  }

  addBranch() {
    const newBranch = new RuleBranch(undefined, this);

    this.branches.push(newBranch);

    return newBranch;
  }

  removeBranch(id: string) {
    this.branches = this.branches.filter((branch) => branch.id !== id);
  }

  toJSON(): IRuleDSL {
    return {
      branches: this.branches.map((branch) => branch.toJSON())
    };
  }
}

export class RuleDSLManager {
  dsl: RuleDSL;

  constructor(dsl?: IRuleDSL) {
    this.dsl = new RuleDSL(dsl);
  }

  getBranch(id: string) {
    return this.dsl.branches.find((branch) => branch.id === id);
  }

  addBranch() {
    this.dsl.addBranch().addClause();
  }

  updateBranch(id: string, updates: Partial<IRuleBranch>) {
    const branch = this.getBranch(id);

    if (branch) {
      Object.assign(branch, updates);
    }
  }

  removeBranch(id: string) {
    const branch = this.getBranch(id);

    if (branch) {
      branch.remove();
    }
  }

  getClause(id: string) {
    return this.dsl.branches
      .flatMap((branch) => branch.clauses)
      .find((clause) => clause.id === id);
  }

  addClause(branchId: string) {
    const branch = this.getBranch(branchId);

    if (branch) {
      return branch.addClause();
    }
  }

  updateClause(id: string, updates: Partial<IRuleClause>) {
    const clause = this.getClause(id);

    if (clause) {
      Object.assign(clause, updates);
    }
  }

  removeClause(id: string) {
    const clause = this.getClause(id);

    if (clause) {
      clause.remove();
    }
  }

  getCondition(id: string) {
    return (
      [
        ...this.dsl.branches
          .flatMap((branch) => branch.clauses)
          .flatMap((clause) => clause.when),
        ...this.dsl.branches
          .flatMap((branch) => branch.clauses)
          .flatMap((clause) => clause.when)
          .flatMap((condition) => condition?.operands ?? [])
          .filter((operand) => operand instanceof RuleCondition)
      ] as RuleCondition[]
    ).find((condition) => condition?.id === id);
  }

  addConditionToClause(clauseId: string) {
    const clause = this.getClause(clauseId);

    if (clause) {
      clause.addCondition();
    }
  }

  addConditionToCondition(conditionId: string) {
    const condition = this.getCondition(conditionId);

    if (condition) {
      condition.addCondition();
    }
  }

  updateCondition(id: string, updates: Partial<IRuleCondition>) {
    const condition = this.getCondition(id);

    if (condition) {
      Object.assign(condition, updates);
    }
  }

  removeCondition(id: string) {
    const condition = this.getCondition(id);

    if (condition) {
      condition.remove();
    }
  }

  getExpression(id: string) {
    return (
      this.dsl.branches
        .flatMap((branch) => branch.clauses)
        .flatMap((clause) => clause.when?.operands ?? [])
        .filter(
          (operand) => operand instanceof RuleExpression
        ) as RuleExpression[]
    ).find((expression) => expression.id === id);
  }

  addExpressionToCondition(conditionId: string) {
    const condition = this.getCondition(conditionId);

    if (condition) {
      condition.addExpression();
    }
  }

  addExpressionToExpression(expressionId: string) {
    const expression = this.getExpression(expressionId);

    if (expression) {
      expression.addExpression();
    }
  }

  updateExpression(id: string, updates: Partial<IRuleExpression>) {
    const expression = this.getExpression(id);

    if (expression) {
      const { operands, ...rest } = updates;

      if (operands) {
        expression.setOperands(operands);
      }

      Object.assign(expression, rest);
    }
  }

  removeExpression(id: string) {
    const expression = this.getExpression(id);
    const parent = expression?.parent;
    const grandParent = parent?.parent;

    /**
     * If the expression is a direct child of a condition and the grand parent is a clause,
     * we must check for scenarios where the expression is the only child of the condition
     * to properly remove the expression along with the condition.
     */
    if (parent instanceof RuleCondition && grandParent instanceof RuleClause) {
      const branch = grandParent.parent as RuleBranch;

      if (parent.operands.length > 1) {
        // Expression can be deleted normally
        expression?.remove();
      } else if (branch?.clauses?.length === 1) {
        // Remove the condition safetly if the branch only has a when clause
        grandParent.removeCondition();
      } else if (!branch.clauses[branch.clauses.length - 1].when) {
        // Update the actions of the else clause with the actions from current clause
        const elseClause = branch.clauses[branch.clauses.length - 1];
        const existingActions = elseClause.actions.map((a) => a.toJSON());
        const actionsToMove = grandParent.actions.map((a) => a.toJSON());

        elseClause.setActions([...existingActions, ...actionsToMove]);
        grandParent.remove();
      } else {
        // Create an else clause and add the actions to the else clause
        const elseClause = branch.addClause();

        elseClause.setActions(grandParent.actions.map((a) => a.toJSON()));
        grandParent.remove();
      }
    } else {
      // Delete the expression normally
      expression?.remove();
    }
  }

  getAction(id: string) {
    return this.dsl.branches
      .flatMap((branch) => branch.clauses)
      .flatMap((clause) => clause.actions)
      .find((action) => action.id === id);
  }

  addActionToClause(clauseId: string) {
    const clause = this.getClause(clauseId);

    if (clause) {
      clause.addAction();
    }
  }

  updateAction(id: string, updates: Partial<IRuleAction>) {
    const action = this.getAction(id);

    if (action) {
      const { parameters, ...rest } = updates;

      if (parameters) {
        action.setParameters(parameters);
      }

      Object.assign(action, rest);
    }
  }

  removeAction(id: string) {
    const action = this.getAction(id);

    if (action) {
      action.remove();
    }
  }

  getOperand(id: string) {
    const clauses = this.dsl.branches.flatMap((branch) => branch.clauses);

    const actionOperands = clauses
      .flatMap((clause) => clause.actions)
      .flatMap((action) => action.parameters)
      .filter((parameter) => parameter instanceof RuleOperand);

    const conditionOperands = (
      clauses
        .flatMap((clause) => clause.when?.operands ?? [])
        .filter(
          (operand) => operand instanceof RuleExpression
        ) as RuleExpression[]
    )
      .flatMap((expression) => expression.operands)
      .filter((operand) => operand instanceof RuleOperand);

    return [...actionOperands, ...conditionOperands].find(
      (operand) => operand.id === id
    );
  }

  addOperandToExpression(expressionId: string) {
    const expression = this.getExpression(expressionId);

    if (expression) {
      expression.addOperand();
    }
  }

  updateOperand(id: string, updates: Partial<IRuleOperand>) {
    const operand = this.getOperand(id);

    if (operand) {
      Object.assign(operand, updates);
    }
  }

  removeOperand(id: string) {
    const operand = this.getOperand(id);

    if (operand) {
      (operand as RuleOperand | RuleExpression).remove();
    }
  }

  toJSON(): IRuleDSL {
    return this.dsl.toJSON();
  }
}
