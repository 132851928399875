function Equifax({ width = 144, height = 72 }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      version='1.1'
      width={width}
      height={height}
      viewBox='0 0 187.475 36.993'
      enableBackground='new 0 0 187.475 36.993'
      xmlSpace='preserve'
    >
      <polygon
        fillRule='evenodd'
        clipRule='evenodd'
        fill='#B32541'
        stroke='#B32541'
        strokeWidth='0.6211'
        strokeMiterlimit='2.6131'
        points='  29.825,5.877 31.01,0.311 6.414,0.311 3.154,15.62 0.388,27.948 25.676,27.948 26.96,22.083 12.934,22.083 14.218,16.117   26.071,16.117 27.355,10.55 15.501,10.55 16.489,5.877 29.825,5.877 '
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        fill='#B32541'
        d='M50.469,16.117h8.594c0.304-1.797,0.869-4.478,0.988-6.661  c0.137-2.555-0.865-4.436-3.062-5.667C53.841,2.033,49.203,2.127,45.728,2c-4.764-0.174-10.433,0.444-13.434,4.672  c-1.548,2.181-2.419,5.56-2.761,8.202c-0.423,3.224-2.04,8.634,0.884,11.282c2.174,1.973,7.038,2.629,9.866,2.59  c2.88-0.041,5.676-0.23,8.507-0.799l7.408,9.045h11.557L50.469,16.117L50.469,16.117z M49.185,16.316  c-0.945,4.286-2.232,7.892-7.211,7.853c-4.999-0.037-3.34-5.415-2.864-8.548c0.673-4.452,2.383-9.121,7.507-8.749  C51.804,7.25,49.973,12.752,49.185,16.316L49.185,16.316z'
      />
      <polygon
        fillRule='evenodd'
        clipRule='evenodd'
        fill='#B32541'
        points='96.599,3.392 93.932,15.62 91.265,27.948 101.34,27.948   106.773,3.392 96.599,3.392 '
      />
      <polygon
        fillRule='evenodd'
        clipRule='evenodd'
        fill='#B32541'
        points='131.468,8.263 132.653,3.392 110.625,3.392   105.291,27.948 115.466,27.948 117.737,17.409 127.714,17.409 128.109,15.62 128.702,12.836 118.824,12.836 119.812,8.263   131.468,8.263 '
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        fill='#B32541'
        d='M64.199,3.392h10.273L70.62,20.79  c-0.471,2.127,1.513,3.236,3.214,3.383c2.188,0.191,4.151-1.184,4.688-3.482l4.05-17.298h10.174l-4.05,18.392  c-1.734,4.795-5.751,6.678-10.186,7.148c-2.75,0.293-5.661,0.045-8.286-0.389l-9.087-10.936L64.199,3.392L64.199,3.392z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        fill='#B32541'
        d='M154.285,3.392h12.94l3.951,6.363l6.42-6.363h9.878l-12.939,11.432  l8.495,13.124h-12.644l-4.149-7.457l-7.111,7.457h-15.113l-0.593-5.369h-10.471l-3.654,5.369h-7.112L139.37,3.392h12.347  l2.371,19.882l8.198-7.555L154.285,3.392L154.285,3.392z M136.308,17.31l5.531-8.351l0.988,8.351H136.308L136.308,17.31z'
      />
    </svg>
  );
}

export default Equifax;
