import {
  Cell,
  createGig,
  NullCell
} from '../../components/RenderingEngine/GridInGrid/engine';

const DeadCell = new NullCell() as Cell;

export const getGigAndNode = (
  step: any,
  viewport: any,
  gigPosition: any,
  theme: any
) => {
  const state = {
    gig: createGig(step, { viewport, theme }),
    node: DeadCell
  };

  if (state.gig && gigPosition) {
    state.node = state.gig.get(gigPosition) ?? DeadCell;
  }

  return state;
};
