import type { ComponentProps } from 'react';
import { Button } from './button';

function Delete({ children, ...rest }: ComponentProps<typeof Button>) {
  return (
    <Button variant='destructive' {...rest}>
      {children}
    </Button>
  );
}

export default Delete;
