import { useEffect, useRef } from 'react';

export default function useDialogFocus(isOpen: any) {
  const focusElement = useRef<any>();

  useEffect(() => {
    if (isOpen) {
      // Focus does not trigger if called immediately on dialogue open
      setTimeout(() => focusElement.current?.focus(), 100);
    }
  }, [isOpen, focusElement]);

  return focusElement;
}
