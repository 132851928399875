import jsonpath from 'jsonpath';

/**
 * Returns a list of unique items by matching the return value of keyMaker
 * @param {Array} list Non-unique list
 * @param {Function} keyMaker Function to convert items to unique keys
 * @returns Unique list
 */
function unique(list: any, keyMaker = (item: any) => item) {
  const uniqueList: any = [];
  const keys = {};

  list.forEach((item: any, index: any) => {
    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 2.
    const key = keyMaker(item, index);
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    if (!keys[key]) {
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      keys[key] = true;
      uniqueList.push(item);
    }
  });

  return uniqueList;
}

/**
 * Layers the provided layer object over the base object
 * @param {Object} base
 * @param {Object} layers
 */
function objectApply(base = {}, ...layers: any[]) {
  const result = { ...base };

  layers.forEach((layer) => {
    Object.entries(layer ?? {}).forEach(([key, value]) => {
      if (Array.isArray(value) || value === null) {
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        result[key] = value;
      } else if (typeof value === 'object') {
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        result[key] = objectApply(result[key], value);
      } else {
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        result[key] = value;
      }
    });
  });

  return result;
}

interface FilterValue {
  key: string;
  value: any;
}

/**
 * Returns an object with filtered properties
 * @param {Object} obj Initial object to filter
 * @param {Function} filter Filter to apply to key-value pairs
 */
function objectFilter(obj = {}, filter = (_: FilterValue) => true) {
  return Object.entries(obj)
    .filter(([key, value]) => filter({ key, value }))
    .reduce((newObj, [key, value]) => ({ ...newObj, [key]: value }), {});
}

function objectMap(obj = {}, transform = (args: any) => args) {
  return Object.entries(obj).reduce((newObj, [key, value]) => {
    const [newKey, newValue] = transform([key, value]);
    return { ...newObj, [newKey]: newValue };
  }, {});
}

function objectPick(obj: any = {}, keys: string[] = []) {
  return keys.reduce(
    (newObj, key) => (key in obj ? { ...newObj, [key]: obj[key] } : newObj),
    {}
  );
}

function objectRemove(obj: Record<string, any> = {}, keys: string[] = []) {
  const newObj = { ...obj };
  keys.forEach((key) => delete newObj[key]);
  return newObj;
}

/**
 * Returns an object map of items in an array, based on a value for each item such as the ID or key
 * @param {Array} arr array of items that you want to reference by a specific key
 * @param {string} itemProperty The property who's value you want to be the object key for each item in the array
 * @returns An object
 */
function mapArrayToObject(arr: any, itemProperty: any) {
  return arr.reduce((obj: any, item: any) => {
    const key = item[itemProperty];
    obj[key] = item;
    return obj;
  }, {});
}

// @ts-expect-error TS(7023) FIXME: 'deepEquals' implicitly has return type 'any' beca... Remove this comment to see the full error message
function deepEquals(a: any, b: any) {
  if (a === b) {
    return true;
  } else if (!a || !b) {
    return false;
  } else if (typeof a === 'object' && typeof b === 'object') {
    const aKeys = Object.keys(a);
    const bKeys = Object.keys(b);
    return [...aKeys, ...bKeys].every((key) => deepEquals(a[key], b[key]));
  } else if (Array.isArray(a) && Array.isArray(b)) {
    if (a.length !== b.length) {
      return false;
    } else {
      return a.every((_, index) => deepEquals(a[index], b[index]));
    }
  } else {
    return false;
  }
}

const _getChildPath = (key: any, childObj: any) =>
  // If the cild prop is an array and the element in the array has an id,
  // then this logic constructs a specific jsonpath that finds that specific element
  // (by id using @.id===). Otherwise it falls back to using the index.
  // If not an array then standard a .property syntax is used in the jsonpath.
  Array.isArray(childObj)
    ? childObj[key] !== null && childObj[key].id !== undefined
      ? `[?(@.id==='${childObj[key].id}')]`
      : `[${key}]`
    : `.${key}`;

/**
 * Deeply diffs two objects and returns the differences as an object of key/values, each of
 * which represents a change operation (i.e. a diff).  The key of each change is a json path
 * (https://goessner.net/articles/JsonPath/) describing the point within the object for the
 * change.  The value of each change operation is of type:
 * { operation: 'edit' | 'delete'; value?: any }
 * The value is used for 'edit' operations only and represent the new value.
 * This function currently is used for diffing theme changes for autosave/delta but is generic.
 * @param previousObj Object or Array
 * @param newObj Object or Array
 * @param contextPath the path up to this object
 * @returns Dictionary of changes keyed by json path strings.
 */
export type DELTAS = {
  [path: string]: { operation: 'edit' | 'delete'; value?: any };
};
function objectDeltas(
  previousObj: any,
  newObj: any,
  contextPath = '$'
): DELTAS {
  let deltas: DELTAS = {};

  const getOtherChildProp = (childProp: any, key: any, otherObj: any) => {
    if (otherObj === null || otherObj === undefined) return undefined;

    if (
      Array.isArray(otherObj) &&
      childProp !== null &&
      childProp.id !== undefined
    )
      return otherObj.find((item) => item.id === childProp.id);

    return otherObj[key];
  };

  const childPropsToRecurse: {
    [contextPath: string]: {
      oldVal: any;
      newVal: any;
    };
  } = {};

  // edits
  if (newObj !== null && typeof newObj === 'object')
    Object.keys(newObj).forEach((key: any) => {
      const value = newObj[key];
      const oldVal = getOtherChildProp(value, key, previousObj);
      const operation = 'edit';

      const childPath = _getChildPath(key, newObj);
      let recurse = true;
      if (value !== oldVal) {
        if (value === null || typeof value !== 'object')
          deltas[contextPath + childPath] = { operation, value };
        else if (oldVal === undefined) {
          // ok, this is a new child array of object.
          // Just insert one edit record for the whole thing to be efficient.
          deltas[contextPath + childPath] = { operation, value: value };
          recurse = false;
        }
      }

      // now look for changes within the child obj (object or array)
      if (recurse && typeof value === 'object')
        childPropsToRecurse[contextPath + childPath] = {
          oldVal,
          newVal: value
        };
    });

  // deletes
  if (previousObj !== null && typeof previousObj === 'object')
    Object.keys(previousObj).forEach((key: any) => {
      const value = previousObj[key];
      const newVal = getOtherChildProp(value, key, newObj);
      const operation = 'delete';

      const childPath = _getChildPath(key, previousObj);
      // if this object is removed, no need to look further
      if (value !== newVal && newVal === undefined)
        deltas[contextPath + childPath] = { operation };
      else if (typeof value === 'object')
        childPropsToRecurse[contextPath + childPath] = {
          oldVal: value,
          newVal
        };
    });

  // Now recurse down children props looking for edits and deletes
  Object.entries(childPropsToRecurse).forEach(
    ([contextPath, { oldVal, newVal }]) =>
      (deltas = Object.assign(
        deltas,
        objectDeltas(oldVal, newVal, contextPath)
      ))
  );

  return deltas;
}

/**
 * Applies delta change record to a target object and returns the result.
 * @param targetObject
 * @param deltas
 */
function objectApplyDeltas(
  targetObject: Record<string, any>,
  deltas: DELTAS | null
): Record<string, any> {
  const changedObject = JSON.parse(JSON.stringify(targetObject));
  const propTypes = ['root', 'identifier'];
  Object.entries(deltas ?? {}).forEach(([path, change]) => {
    // [path: string]: { operation: 'edit' | 'delete'; value?: any };

    // Supporting the addition of child props and array elements here too.
    const pathComponents = jsonpath.parse(path);
    let prop = null;
    if (
      propTypes.includes(
        pathComponents[pathComponents.length - 1].expression.type
      )
    )
      prop = pathComponents[pathComponents.length - 1].expression.value;

    let parent;
    if (jsonpath.query(changedObject, path).length) {
      // jsonpath.parent throws exception if path not found
      parent = jsonpath.parent(changedObject, path);
      const resolvedPath = jsonpath.paths(changedObject, path)[0];
      prop = resolvedPath[resolvedPath.length - 1];
    } else {
      const parentPath = jsonpath.stringify(
        pathComponents.slice(0, pathComponents.length - 1)
      );
      parent = jsonpath.value(changedObject, parentPath);
    }
    if (parent) {
      // if parent is an object, need to splice it
      if (
        Array.isArray(parent) &&
        (prop === null || typeof prop === 'number')
      ) {
        if (change.operation === 'edit')
          prop !== null
            ? parent.splice(prop, 1, change.value)
            : parent.push(change.value);
        // operation === delete
        else {
          if (prop !== null) parent.splice(prop, 1);
        }
      }

      // if parent is an object, just change or delete the child prop
      else {
        if (change.operation === 'edit') parent[prop] = change.value;
        // operation === delete
        else delete parent[prop];
      }
    }
  });
  return changedObject;
}

/**
 * Removes an element from a list immutably
 * @param {Array} list Initial list
 * @param {number} index Index to remove
 * @returns Shortened list
 */
function listCopyRemove(list: any, index: any) {
  return [...list.slice(0, index), ...list.slice(index + 1)];
}

/**
 * Inserts an element into a list, optionally replacing an existing element
 * @param {Array} list Initial list
 * @param {number} index Index to insert the new element
 * @param {any} item Element to insert
 * @param {boolean?} replace Whether to replace an existing element (default false)
 * @returns List with the new element
 */
function listCopyInsert(list: any, index: any, item: any, replace = false) {
  if (replace) {
    return [...list.slice(0, index), item, ...list.slice(index + 1)];
  } else {
    return [...list.slice(0, index), item, ...list.slice(index)];
  }
}

function isDefined<T>(item: T): item is NonNullable<T> {
  return item !== null && item !== undefined;
}

function booleanSort(arr: any, key: any) {
  return arr.sort((x: any, y: any) =>
    x[key] === y[key] ? 0 : x[key] ? -1 : 1
  );
}

export {
  unique,
  objectApply,
  objectApplyDeltas,
  objectDeltas,
  objectFilter,
  objectMap,
  objectPick,
  objectRemove,
  mapArrayToObject,
  listCopyRemove,
  listCopyInsert,
  deepEquals,
  isDefined,
  booleanSort
};
