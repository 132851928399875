function SingleStack() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.3616 8.08758C11.4854 8.02989 11.6203 8 11.7568 8C11.8934 8 12.0282 8.02989 12.152 8.08758L19.3503 11.4506C19.3988 11.4732 19.4397 11.5091 19.4684 11.5542C19.497 11.5993 19.5122 11.6517 19.512 11.7051C19.5119 11.7586 19.4965 11.8109 19.4676 11.8558C19.4387 11.9008 19.3976 11.9365 19.349 11.9589L12.0941 15.2966C11.9881 15.3454 11.8728 15.3706 11.7561 15.3706C11.6395 15.3706 11.5242 15.3454 11.4182 15.2966L4.16395 11.9589C4.11518 11.9367 4.0738 11.901 4.04473 11.8561C4.01567 11.8111 4.00014 11.7587 4 11.7051C3.99986 11.6516 4.01511 11.5991 4.04394 11.554C4.07277 11.5089 4.11396 11.473 4.16261 11.4506L11.3616 8.08758Z'
        fill='white'
      />
    </svg>
  );
}

export default SingleStack;
