import '../../style/dialog-form.css';

import { Form } from 'react-bootstrap';
import { Fragment, useState } from 'react';

import { ALL_INTEGRATIONS_MAP, INTEGRATIONS } from './types';
import IntegrationsSidebar from './IntegrationsSidebar';
import {
  CheckboxField,
  CollapsibleSection,
  PropertyLabel,
  TextField
} from '../Core';
import classNames from 'classnames';
import styles from './styles.module.scss';
import useIntegrations from './useIntegrations';
import AuthorizedSteps from './shared/AuthorizedSteps';
import { useParams } from 'react-router-dom';
import { FieldSelectorWithModal } from '../Modals';
import { useAppSelector } from '../../hooks';

const DEFAULT_SETTINGS = {
  project_id: '',
  app_id: '',
  sender_id: '',
  user_field_mapping: {},
  auth_gate_steps: [],
  login_step: '',
  logout_step: '',
  no_merge: false
};

const USER_FIELDS = [
  { label: 'Email', val: 'email' },
  { label: 'Phone', val: 'phone' },
  { label: 'Name', val: 'first_name' }
];

function FirebaseSettingsSidebar() {
  const { formId } = useParams<{ formId: string }>();

  // Global/cached integration settings
  const integration = useIntegrations({
    type: INTEGRATIONS.FIREBASE,
    panelId: formId,
    includeInactive: true
  });

  const panel = useAppSelector((state) => state.panels.panels[formId]);
  const [err, setErr] = useState('');

  // Local/draft integration settings
  const [apiKey, setApiKey] = useState(integration?.data.api_key ?? '');
  const [isPartial, setIsPartial] = useState(false);
  const [firebaseConfig, setFirebaseConfig] = useState(
    integration?.data.metadata ?? DEFAULT_SETTINGS
  );
  const userFieldMapping = { ...firebaseConfig.user_field_mapping };

  const setFields = (fields: any) =>
    setFirebaseConfig((firebaseConfig: any) => ({
      ...firebaseConfig,
      ...fields
    }));

  function onSubmitCustom(newIsActive: boolean):
    | undefined
    | {
        isUpdate: boolean;
        apiKey: string;
        metadata: typeof DEFAULT_SETTINGS;
      } {
    if (!panel.track_users && newIsActive) {
      setErr(
        '`Remember User` must be turned on in your form settings to use Firebase'
      );
      return;
    }
    setErr('');

    if (newIsActive) {
      const partial =
        !apiKey ||
        !firebaseConfig.project_id ||
        !firebaseConfig.app_id ||
        !firebaseConfig.sender_id;
      setIsPartial(partial);
      if (partial) return;
    }

    return {
      isUpdate: integration?.data,
      apiKey,
      metadata: firebaseConfig
    };
  }

  return (
    <IntegrationsSidebar
      integrationInfo={ALL_INTEGRATIONS_MAP[INTEGRATIONS.FIREBASE]}
      isPartial={isPartial}
      onSubmitCustom={onSubmitCustom}
      customError={err}
    >
      <Form>
        <Form.Group controlId='api-key'>
          <PropertyLabel label='API Key' />
          <TextField
            className={classNames('dialog-form-input', styles.marginBottom)}
            type='password'
            placeholder='Enter API Key'
            value={apiKey || ''}
            onChange={setApiKey}
            error={isPartial && !apiKey}
          />
          <PropertyLabel label='Project ID' />
          <TextField
            className={classNames('dialog-form-input', styles.marginBottom)}
            placeholder='Enter Project ID'
            value={firebaseConfig.project_id || ''}
            onChange={(value: string) => setFields({ project_id: value })}
            error={isPartial && !firebaseConfig.project_id}
          />
          <PropertyLabel label='App ID' />
          <TextField
            className={classNames('dialog-form-input', styles.marginBottom)}
            placeholder='Enter App ID'
            value={firebaseConfig.app_id || ''}
            onChange={(value: string) => setFields({ app_id: value })}
            error={isPartial && !firebaseConfig.app_id}
          />
          <PropertyLabel label='Sender ID' />
          <TextField
            className={classNames('dialog-form-input', styles.marginBottom)}
            placeholder='Enter Sender ID'
            value={firebaseConfig.sender_id || ''}
            onChange={(value: string) => setFields({ sender_id: value })}
            error={isPartial && !firebaseConfig.sender_id}
          />

          <AuthorizedSteps
            integrationConfig={firebaseConfig}
            setFields={setFields}
          />

          <CheckboxField
            checked={firebaseConfig.no_merge}
            text='Allow multiple submissions from the same user login'
            onChange={(flag) => setFields({ no_merge: flag })}
            style={{
              marginTop: '7px',
              marginBottom: '17px'
            }}
          />

          <CollapsibleSection
            title='Save User Attributes'
            collapsible
            expanded={false}
          >
            <p className={classNames('text-muted', styles.helperText)}>
              When the user signs up, you can save their info into Feathery
              fields.
            </p>
            <div className={styles.twoColumnContainer}>
              {USER_FIELDS.map(({ label, val }) => (
                <Fragment key={val}>
                  <PropertyLabel label={label} />
                  <FieldSelectorWithModal
                    selectId={userFieldMapping[val]?.id}
                    selectType={userFieldMapping[val]?.type}
                    placeholder='Select'
                    onSelect={(data) => {
                      if (!data.selectId) delete userFieldMapping[val];
                      else
                        userFieldMapping[val] = {
                          id: data.selectId,
                          type: data.selectType
                        };
                      setFirebaseConfig({
                        ...firebaseConfig,
                        user_field_mapping: userFieldMapping
                      });
                    }}
                    className={styles.marginBottom}
                  />
                </Fragment>
              ))}
            </div>
          </CollapsibleSection>
        </Form.Group>
      </Form>
    </IntegrationsSidebar>
  );
}

export default FirebaseSettingsSidebar;
