import { AIExtractionEntryValue } from '../types';
import { AIStarIcon } from '../../../components/Icons';
import classNames from 'classnames';
import styles from './styles.module.scss';

type Props = {
  entry: AIExtractionEntryValue;
};

const EntryValueDisplay = ({ entry }: Props) => {
  const entryValue = entry.value;
  const isAIGenerated =
    (entry as AIExtractionEntryValue).xCoordinate !== undefined &&
    (entry as AIExtractionEntryValue).yCoordinate !== undefined;

  const isEmpty = entryValue === '';

  return (
    <div className={styles.entryValueDisplay}>
      {isAIGenerated && (
        <div
          className={classNames(styles.icon, {
            [styles.red]: !isEmpty,
            [styles.grey]: isEmpty
          })}
        >
          <AIStarIcon height={12} />
        </div>
      )}
      <div className={classNames(styles.text, { [styles.tag]: isEmpty })}>
        {isEmpty ? 'NO DATA' : entryValue}
      </div>
    </div>
  );
};

export default EntryValueDisplay;
