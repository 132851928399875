/* eslint-disable react-hooks/exhaustive-deps */

import { NumberInput, PropertyLabel } from '..';
import { memo, useEffect, useMemo, useState } from 'react';

import { stopReactEventPropagation } from '../util';
import styles from './styles.module.scss';

const CORNERS = {
  TL: 'topLeft',
  TR: 'topRight',
  BL: 'bottomLeft',
  BR: 'bottomRight'
};

const NO_FOCUSED_CORNERS = {
  [CORNERS.TL]: false,
  [CORNERS.TR]: false,
  [CORNERS.BL]: false,
  [CORNERS.BR]: false
};

/**
 * Determines what the aggregate value of the input should be.
 * If all values are the same, show that. If they are different, show empty string.
 */
function getCombined(focusedCorners: any, cornerValues: any) {
  // If no corners are focused, we consider that to mean all corners are focused
  const relevantCorners =
    JSON.stringify(NO_FOCUSED_CORNERS) === JSON.stringify(focusedCorners)
      ? Object.values(cornerValues)
      : [
          ...(focusedCorners[CORNERS.TL] ? [cornerValues[CORNERS.TL]] : []),
          ...(focusedCorners[CORNERS.TR] ? [cornerValues[CORNERS.TR]] : []),
          ...(focusedCorners[CORNERS.BL] ? [cornerValues[CORNERS.BL]] : []),
          ...(focusedCorners[CORNERS.BR] ? [cornerValues[CORNERS.BR]] : [])
        ];
  const isMixed =
    relevantCorners.length === 0 ||
    relevantCorners.some((c) => c !== relevantCorners[0]);

  return [isMixed ? '' : relevantCorners[0], isMixed];
}

function CornerStyleInput({
  labelData = {},
  topLeft,
  topRight,
  bottomLeft,
  bottomRight,
  onComplete: customOnComplete = () => {}
}: any) {
  const [cornerValues, setCornerValues] = useState({
    topLeft,
    topRight,
    bottomLeft,
    bottomRight
  });
  const [focusedCorners, setFocusedCorners] = useState(NO_FOCUSED_CORNERS);

  useEffect(
    () => setCornerValues({ topLeft, topRight, bottomLeft, bottomRight }),
    [topLeft, topRight, bottomLeft, bottomRight]
  );

  const inputKey = useMemo(() => Math.random(), [focusedCorners]);
  const [combined, isMixed] = useMemo(
    () => getCombined(focusedCorners, cornerValues),
    [focusedCorners, cornerValues]
  );

  // Change the focused corners when the user clicks on them
  // Click will toggle the current corner (add/remove it from the set of focused corners)
  function onCornerMouseDown(position: any) {
    return (event: any) => {
      // Stop propagation so the useEffect that unfocuses all corners isn't triggered
      stopReactEventPropagation(event);
      const newFocusedCorners = {
        ...focusedCorners,
        [position]: !focusedCorners[position]
      };
      setFocusedCorners(newFocusedCorners);
    };
  }

  return (
    <div className={styles.cornerInput}>
      <div className={styles.cornerGrid}>
        {Object.values(CORNERS).map((corner) => (
          <div
            key={`${corner}:${focusedCorners[corner]}`}
            className={styles.cornerGridItem}
            onMouseDown={onCornerMouseDown(corner)}
          >
            <div
              className={`${styles.cornerIcon} ${
                focusedCorners[corner] ? styles.focused : ''
              }`}
              // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              style={{ borderTopLeftRadius: `${cornerValues[corner] * 2}px` }}
            />
          </div>
        ))}
      </div>
      <div
        className={styles.cornerValue}
        onMouseDown={stopReactEventPropagation}
      >
        <PropertyLabel label='Radius' {...labelData} />
        <NumberInput
          key={inputKey}
          placeholder={isMixed ? 'Mixed' : '0'}
          value={combined}
          min={0}
          onComplete={({ value, mounted }: any) => {
            if (value !== '' && value >= 0) {
              // If no corners are focused, we consider that to mean all corners are focused
              const newCornerValues =
                JSON.stringify(NO_FOCUSED_CORNERS) ===
                JSON.stringify(focusedCorners)
                  ? {
                      [CORNERS.TL]: value,
                      [CORNERS.TR]: value,
                      [CORNERS.BL]: value,
                      [CORNERS.BR]: value
                    }
                  : {
                      [CORNERS.TL]: focusedCorners[CORNERS.TL]
                        ? value
                        : // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                          cornerValues[CORNERS.TL],
                      [CORNERS.TR]: focusedCorners[CORNERS.TR]
                        ? value
                        : // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                          cornerValues[CORNERS.TR],
                      [CORNERS.BL]: focusedCorners[CORNERS.BL]
                        ? value
                        : // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                          cornerValues[CORNERS.BL],
                      [CORNERS.BR]: focusedCorners[CORNERS.BR]
                        ? value
                        : // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                          cornerValues[CORNERS.BR]
                    };
              customOnComplete(newCornerValues);

              // If component has been destroyed, don't attempt to set state
              // @ts-expect-error TS(2345) FIXME: Argument of type '{ [x: string]: any; }' is not as... Remove this comment to see the full error message
              if (mounted) setCornerValues(newCornerValues);
            }
          }}
          units={['px']}
          triggerCleanUp
        />
      </div>
    </div>
  );
}

export default memo(CornerStyleInput);
