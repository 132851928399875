export default function CloseIcon({
  width = 32,
  height = 32,
  color = '#414859',
  ...props
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M9.54595 6.95405C8.96016 6.36826 8.01042 6.36826 7.42463 6.95405C6.83884 7.53984 6.83884 8.48958 7.42463 9.07537L9.54595 6.95405ZM24.3952 26.0459C24.981 26.6317 25.9307 26.6317 26.5165 26.0459C27.1023 25.4601 27.1023 24.5104 26.5165 23.9246L24.3952 26.0459ZM7.42463 9.07537L24.3952 26.0459L26.5165 23.9246L9.54595 6.95405L7.42463 9.07537Z'
        fill={color}
      />
      <path
        d='M7.42463 23.9246C6.83884 24.5104 6.83884 25.4602 7.42463 26.046C8.01042 26.6317 8.96016 26.6317 9.54595 26.046L7.42463 23.9246ZM26.5165 9.07539C27.1023 8.4896 27.1023 7.53985 26.5165 6.95407C25.9307 6.36828 24.981 6.36828 24.3952 6.95407L26.5165 9.07539ZM9.54595 26.046L26.5165 9.07539L24.3952 6.95407L7.42463 23.9246L9.54595 26.046Z'
        fill={color}
      />
    </svg>
  );
}
