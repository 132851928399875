/**
 * Used to cleanup form/step data.
 * The function will fix any issues within the data that it comes across such as
 * subgrids missing keys or having the same key as another subgrid.
 */
export const cleanupStep = (step: any) => {
  const newStep = JSON.parse(JSON.stringify(step));
  const getNextContainerKey = makeGetNextContainerKey(newStep.subgrids);
  const seenKeys: any = {};

  newStep.subgrids.forEach((subgrid: any, i: number) => {
    if (!subgrid.axis) {
      newStep.subgrids[i].axis = '';
    }

    if (!subgrid.mobile_axis) {
      newStep.subgrids[i].mobile_axis = '';
    }

    if (!subgrid.key || seenKeys[subgrid.key]) {
      newStep.subgrids[i].key = getNextContainerKey();
    }

    seenKeys[subgrid.key] = true;
  });

  return newStep;
};

export const getContainersFromSteps = (steps: any[]) => {
  return steps.reduce((allContainers: any[], step: any) => {
    return [...allContainers, ...step.subgrids];
  }, []);
};

/**
 * Calculates the next unique container key given an array of existing containers
 * @param containers - The containers that will be used to calculate the next container key
 * @returns The next unique container key
 */
export const getNextContainerKey = (containers: any[]) => {
  const containerKeyRegex = /^Container (\d+)$/;

  return (
    containers.reduce((greatestKey: number, { key }: any) => {
      if (!key || !containerKeyRegex.test(key)) {
        return greatestKey;
      }

      const keyNumValue = Number.parseFloat(containerKeyRegex.exec(key)![1]); // eslint-disable-line

      if (keyNumValue > greatestKey) {
        return keyNumValue;
      }

      return greatestKey;
    }, 0) + 1
  );
};

/**
 * Creates a function that will calculate the next unique container key given an array of existing containers
 * @param containers - The containers that will be used to calculate the initial next unique container key
 * @returns A function that will calculate the next unique container key
 */
export const makeGetNextContainerKey = (containers: any[]) => {
  let nextKey = getNextContainerKey(containers);

  return () => {
    const containerKey = `Container ${nextKey}`;

    nextKey += 1;

    return containerKey;
  };
};
