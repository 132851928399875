/* eslint-disable react-hooks/exhaustive-deps */

import { memo, useMemo, useRef, useState } from 'react';

import AssetsMenuItem from '../AssetItem/AssetsMenuItem';
import { ElevatedMenu } from '../../../Core';
import { elementTypeToAsset } from '../../../../utils/themes';
import styles from './styles.module.scss';
import { useGlobalMouseDownToggle } from '../../../Core/util';
import classNames from 'classnames';
import useElementDrag, {
  ElementDragOperation
} from '../../../CustomDragLayer/hooks/useElementDrag';
import { Tooltip } from '../../../Core/Tooltip/Tooltip';

const ElementBlockSource = ({
  mode = 'drag',
  elementType,
  icon,
  label,
  message,
  onMouseDown,
  theme,
  setTheme,
  handleUpdates,
  context,
  deleteAssetFromTheme
}: any) => {
  const [hovered, setHovered] = useState(false);
  const assetsProp = elementTypeToAsset(elementType);
  const assets = useMemo(() => {
    const assetsList = theme?.[assetsProp] ?? [];
    if (assetsProp === 'servar_field_assets') {
      return assetsList.filter((a: any) => a.servar.type === elementType);
    } else {
      return assetsList;
    }
  }, [theme]);

  const assetsBadgeRef = useRef(null);
  const assetsMenuRef = useRef<HTMLDivElement>(null);
  const [showAssets, setShowAssets] = useGlobalMouseDownToggle([
    assetsBadgeRef,
    assetsMenuRef
  ]);
  const [menuPosition, setMenuPosition] = useState({});

  const drag = useElementDrag(() => ({
    operation: ElementDragOperation.New,
    type: elementType,
    opts: {
      preview: {
        anchor: 'center'
      }
    }
  }));

  // When the user clicks on the assets badge
  // Reveal the assets menu and set its position next to the cursor
  function revealAssetsMenu(event: any) {
    setShowAssets(true);
    setMenuPosition({ x: event.clientX, y: event.clientY });
  }

  // If message is defined, we need to disable the field source and show a tooltip
  return message ? (
    <Tooltip content={message}>
      <div className={styles.fieldContainer}>
        <div className={classNames(styles.field, styles.disabled)}>
          <div className={styles.icon}>{icon}</div>
          <span className={styles.label}>{label}</span>
        </div>
      </div>
    </Tooltip>
  ) : (
    <div className={styles.fieldContainer}>
      <div
        className={classNames(
          styles.field,
          (hovered || showAssets) && styles.highlighted
        )}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onMouseDown={() => setHovered(false)}
        onMouseUp={() => setHovered(true)}
        {...(mode === 'drag' && { ref: drag })}
        {...(mode === 'nav' && { onMouseDown })}
      >
        <div className={styles.icon}>{icon}</div>
        <span className={styles.label}>{label}</span>
        {assets.length > 0 && (
          <div
            ref={assetsBadgeRef}
            className={styles.assetsBadge}
            onMouseDown={revealAssetsMenu}
          >
            {/* Note: We add 1 here since we're gonna kinda count the base element as an asset */}
            {assets.length + 1}
          </div>
        )}
        <ElevatedMenu
          ref={assetsMenuRef}
          className={styles.assetsMenu}
          position={menuPosition as { x: number; y: number }}
          show={showAssets}
        >
          <div className={styles.title}>Assets</div>
          <AssetsMenuItem
            theme={theme}
            setTheme={setTheme}
            handleUpdates={handleUpdates}
            mode={mode}
            icon={icon}
            elementType={elementType}
            context={context}
            deleteAssetFromTheme={deleteAssetFromTheme}
          />
          {[...assets]
            .sort((a: any, b: any) => (a.key > b.key ? 1 : -1))
            .map((asset: any) => (
              <AssetsMenuItem
                theme={theme}
                setTheme={setTheme}
                handleUpdates={handleUpdates}
                mode={mode}
                key={asset.key}
                icon={icon}
                asset={asset}
                elementType={elementType}
                context={context}
                deleteAssetFromTheme={deleteAssetFromTheme}
              />
            ))}
        </ElevatedMenu>
      </div>
    </div>
  );
};

export default memo(ElementBlockSource);
