export default function PINIcon() {
  return (
    <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M34.8837 31.692C34.0553 31.692 33.3837 32.3635 33.3837 33.192C33.3837 34.0204 34.0553 34.692 34.8837 34.692V31.692ZM10.6665 12.5C9.83808 12.5 9.1665 13.1716 9.1665 14C9.1665 14.8284 9.83808 15.5 10.6665 15.5V12.5ZM41.4999 31.692H34.8837V34.692H41.4999V31.692ZM42.4999 30.6887C42.4999 31.2446 42.0503 31.692 41.4999 31.692V34.692C43.7109 34.692 45.4999 32.8977 45.4999 30.6887H42.4999ZM42.4999 16.5033V30.6887H45.4999V16.5033H42.4999ZM41.4999 15.5C42.0503 15.5 42.4999 15.9473 42.4999 16.5033H45.4999C45.4999 14.2942 43.7109 12.5 41.4999 12.5V15.5ZM10.6665 15.5H41.4999V12.5H10.6665V15.5Z'
        fill='#414859'
      />
      <path
        d='M13.1751 25.3451L10.5298 24L13.1751 22.6549L12.0789 20.7996L9.57657 22.3534L9.67189 19.4545H7.4794L7.59855 22.3534L5.07242 20.7996L4 22.6549L6.62146 24L4 25.3451L5.07242 27.1772L7.59855 25.6466L7.4794 28.5455H9.67189L9.57657 25.6466L12.0789 27.1772L13.1751 25.3451Z'
        fill='#414859'
      />
      <path
        d='M24.9512 25.3451L22.3059 24L24.9512 22.6549L23.855 20.7996L21.3527 22.3534L21.448 19.4545H19.2555L19.3747 22.3534L16.8485 20.7996L15.7761 22.6549L18.3976 24L15.7761 25.3451L16.8485 27.1772L19.3747 25.6466L19.2555 28.5455H21.448L21.3527 25.6466L23.855 27.1772L24.9512 25.3451Z'
        fill='#414859'
      />
      <path
        d='M36.7273 25.3451L34.082 24L36.7273 22.6549L35.6311 20.7996L33.1288 22.3534L33.2241 19.4545H31.0316L31.1508 22.3534L28.6246 20.7996L27.5522 22.6549L30.1737 24L27.5522 25.3451L28.6246 27.1772L31.1508 25.6466L31.0316 28.5455H33.2241L33.1288 25.6466L35.6311 27.1772L36.7273 25.3451Z'
        fill='#414859'
      />
    </svg>
  );
}
