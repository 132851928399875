import { Gig } from './engine';
import { applyToViewportProperties } from '../utils/gig';

export function clearViewport(step: any, viewport: any) {
  const clear = (item: any, props: any) => {
    props.forEach((prop: any) => {
      const viewportProp = `${viewport}_${prop}`;

      if (prop === 'axis') {
        item[viewportProp] = '';
      } else if (prop === 'styles') {
        item[viewportProp] = {};
      } else {
        item[viewportProp] = null;
      }
    });
  };

  return applyToViewportProperties(step, clear);
}

export const getIndexInParentLayout = (node: any) => {
  return node.position[node.position.length - 1];
};

export * from './engine';
export default Gig;
