export default function SearchIcon({ color = 'black', className }: any) {
  return (
    <svg
      className={className}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M24.3739 14.1427C24.3739 8.54102 19.8131 3.99999 14.187 3.99999C8.56086 3.99999 4 8.54103 4 14.1427C4 19.7443 8.56086 24.2854 14.187 24.2854C19.8131 24.2854 24.3739 19.7443 24.3739 14.1427Z'
        stroke={color}
        strokeWidth='3'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M25.1899 25.2021L28 28'
        stroke={color}
        strokeWidth='3'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
