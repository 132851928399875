import { useState } from 'react';
import { useRuleBuilderUpdater } from '../../../context';
import { FieldSelector } from '../../../../../../../components/Modals/DecisionLogicModal/DecisionLogicSection';
import {
  LogicFieldSelectorModal,
  SelectFieldPayload
} from '../../FieldSelectorModal';
import { RuleOperand } from '../../../context/RuleDSL';
import { RuleOperand as Operand } from '../../RuleOperand';
import { TextField } from '../../../../../../../components/Core';
import classNames from 'classnames';
import useFieldKey from '../../../../../../../utils/useFieldKey';
import ruleStyles from '../../../../../../../components/NavigationRules/styles.module.scss';
import comparisonRuleStyles from '../../../../../../../components/Modals/DecisionLogicModal/styles.module.scss';
import styles from '../styles.module.scss';
import { useParams } from 'react-router-dom';

type SetErrorActionProps = {
  action: IRuleAction;
  mode?: RuleBuilderMode;
};

const EditMode = ({ action }: SetErrorActionProps) => {
  const { updateAction, updateOperand } = useRuleBuilderUpdater((s) => ({
    updateAction: s.updateAction,
    updateOperand: s.updateOperand
  }));

  const [selectedField, setSelectedField] = useState<Field | null>(null);
  const [showFieldSelector, setShowFieldSelector] = useState(false);
  const { formId } = useParams<{ formId: string }>();
  const getFieldKey = useFieldKey(!!formId);

  const { parameters } = action;
  const [field, value] = parameters as DualRuleOperandsParametersTuple;

  const handleSelectField = (payload: SelectFieldPayload) => {
    if (field) {
      updateOperand(field.id, {
        type: 'field',
        value: payload.selectId,
        meta: {
          ...field.meta,
          field_type: payload.selectType,
          servar_type: payload.selectServarType
        }
      });

      // Clear the set value if the field type is changed
      if (action.parameters[1]) {
        updateOperand(action.parameters[1].id, {
          type: 'value',
          value: ''
        });
      }
    } else {
      const operand = new RuleOperand();

      operand.type = 'field';
      operand.value = payload.selectId;
      operand.meta = {
        field_type: payload.selectType,
        servar_type: payload.selectServarType
      };

      updateAction(action.id, {
        parameters: [operand.toJSON()]
      });
    }
  };

  const handleValueChange = (newValue: string) => {
    if (value) {
      updateOperand(value.id, {
        type: 'value',
        value: newValue
      });
    } else {
      const operand = new RuleOperand();

      operand.type = 'value';
      operand.value = newValue;

      updateAction(action.id, {
        parameters: [...action.parameters, operand.toJSON()]
      });
    }
  };

  return (
    <>
      <LogicFieldSelectorModal
        show={showFieldSelector}
        setShow={(value: boolean) => setShowFieldSelector(value)}
        selectedField={selectedField}
        onSelect={handleSelectField}
      />
      <FieldSelector
        fieldId={field?.value ?? ''}
        fieldType={field?.meta?.field_type ?? 'servar'}
        fieldKey={getFieldKey(field?.value ?? '')}
        className={classNames(
          comparisonRuleStyles.fieldSelector,
          styles.fieldSelector
        )}
        error={false}
        showFieldSelector={(
          id: any,
          type: any,
          ruleItemIndex: any,
          ruleValueIndex = null
        ) => {
          setSelectedField({
            id,
            type,
            ruleItemIndex,
            ruleValueIndex
          });
          setShowFieldSelector(true);
        }}
      />
      <span>to</span>
      <TextField
        className={classNames(
          ruleStyles.ruleTextField,
          comparisonRuleStyles.valueInput
        )}
        value={value?.value ?? ''}
        title={value?.value ?? ''}
        placeholder='Error'
        onComplete={handleValueChange}
        triggerCleanUp
      />
    </>
  );
};

const ViewMode = ({ action }: SetErrorActionProps) => {
  const [field, value] = action.parameters as DualRuleOperandsParametersTuple;

  return (
    <>
      <Operand operand={field} />
      <span>to</span>
      <Operand operand={value} />
    </>
  );
};

export const SetErrorAction = ({
  action,
  mode = 'view'
}: SetErrorActionProps) => {
  return (
    <div className={styles.actionContent}>
      {mode === 'view' ? (
        <ViewMode action={action} />
      ) : (
        <EditMode action={action} />
      )}
    </div>
  );
};
