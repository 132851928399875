export default function BorderEyeIcon({ width = 18, height = 18, ...props }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 17 18'
      fill='#E2626E'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M17 15.4286V2.07143C17 1.06547 16.1845 0.25 15.1786 0.25H1.82143C0.815469 0.25 0 1.06547 0 2.07143V15.4286C0 16.4345 0.815469 17.25 1.82143 17.25H15.1786C16.1845 17.25 17 16.4345 17 15.4286Z' />
      <path
        d='M8.5 10.25C9.19036 10.25 9.75 9.69036 9.75 9C9.75 8.30964 9.19036 7.75 8.5 7.75C7.80964 7.75 7.25 8.30964 7.25 9C7.25 9.69036 7.80964 10.25 8.5 10.25Z'
        fill='white'
      />
      <path
        d='M14.7125 8.78123C13.7055 6.24029 11.2329 4.58613 8.50001 4.625C5.76708 4.58613 3.29451 6.24029 2.28751 8.78123C2.2375 8.92279 2.2375 9.07721 2.28751 9.21873C3.29451 11.7597 5.76708 13.4138 8.50001 13.375C11.2329 13.4138 13.7055 11.7597 14.7125 9.21873C14.7625 9.07717 14.7626 8.92279 14.7125 8.78123ZM8.50138 11.5C8.50093 11.5 8.50047 11.5 8.50001 11.5C7.11928 11.5 6.00001 10.3807 6.00001 9C6.00001 7.61927 7.11928 6.5 8.50001 6.5C9.88074 6.5 11 7.61927 11 9C11.0004 10.3803 9.88173 11.4996 8.50138 11.5Z'
        fill='white'
      />
    </svg>
  );
}
