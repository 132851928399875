export default function VideoIcon() {
  return (
    <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.5 6.99997C2.5 4.51469 4.51472 2.49997 7 2.49997H41C43.4853 2.49997 45.5 4.51469 45.5 6.99997V41C45.5 43.4853 43.4853 45.5 41 45.5H7C4.51472 45.5 2.5 43.4853 2.5 41V6.99997ZM7 5.49997C6.17157 5.49997 5.5 6.17154 5.5 6.99997V41C5.5 41.8284 6.17157 42.5 7 42.5H41C41.8284 42.5 42.5 41.8284 42.5 41V6.99997C42.5 6.17154 41.8284 5.49997 41 5.49997H7Z'
        fill='#414859'
      />
      <path
        d='M30.25 24.433L21.25 29.6292C20.9167 29.8216 20.5 29.5811 20.5 29.1962L20.5 18.8038C20.5 18.4189 20.9167 18.1784 21.25 18.3708L30.25 23.567C30.5833 23.7594 30.5833 24.2406 30.25 24.433Z'
        fill='#414859'
        stroke='#414859'
        strokeWidth='3'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <line
        x1='6.5'
        y1='37.5'
        x2='8.5'
        y2='37.5'
        stroke='#414859'
        strokeWidth='3'
        strokeLinecap='square'
      />
      <line
        x1='13.5'
        y1='37.5'
        x2='21.5'
        y2='37.5'
        stroke='#414859'
        strokeWidth='3'
        strokeLinecap='square'
      />
      <line
        x1='26.5'
        y1='37.5'
        x2='34.5'
        y2='37.5'
        stroke='#414859'
        strokeWidth='3'
        strokeLinecap='square'
      />
      <line
        x1='39.5'
        y1='37.5'
        x2='41.5'
        y2='37.5'
        stroke='#414859'
        strokeWidth='3'
        strokeLinecap='square'
      />
      <line
        x1='6.5'
        y1='10.5'
        x2='8.5'
        y2='10.5'
        stroke='#414859'
        strokeWidth='3'
        strokeLinecap='square'
      />
      <line
        x1='13.5'
        y1='10.5'
        x2='21.5'
        y2='10.5'
        stroke='#414859'
        strokeWidth='3'
        strokeLinecap='square'
      />
      <line
        x1='26.5'
        y1='10.5'
        x2='34.5'
        y2='10.5'
        stroke='#414859'
        strokeWidth='3'
        strokeLinecap='square'
      />
      <line
        x1='39.5'
        y1='10.5'
        x2='41.5'
        y2='10.5'
        stroke='#414859'
        strokeWidth='3'
        strokeLinecap='square'
      />
    </svg>
  );
}
