/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';

import { ColorPicker } from '../../../utils/elements';
import { HEX_REGEX } from '../../RenderingEngine/utils';
import styles from './styles.module.scss';
import classNames from 'classnames';

export default function FColorPicker({
  value,
  onChange = () => {},
  horizontal,
  vertical,
  colorPickerStyle
}: any) {
  const parseHexAndAlpha = (color: any) => {
    const colorOrDefault = color || 'FFFFFF00'; // Transparent "white"
    return [
      colorOrDefault.substring(0, 6),
      colorOrDefault.substring(6) || 'FF'
    ];
  };
  const [initialHex, initialAlpha] = parseHexAndAlpha(value);

  // Track RGB and alpha as two separate values
  const [hexColor, setHexColor] = useState(initialHex);
  const [alpha, setAlpha] = useState(initialAlpha);
  const [hoverState, setHover] = useState('');
  const [focusState, setFocus] = useState('');

  const fullColor = useMemo(() => hexColor + alpha, [hexColor, alpha]);

  // Update color if the passed in prop changes
  useEffect(() => {
    const [hex, alpha] = parseHexAndAlpha(value);
    setHexColor(hex);
    setAlpha(alpha);
  }, [value]);

  const handleChange = (e: any) => {
    // Prevent oddities with typing more characters past a hex code of length 6
    // (+1 for the pound sign)
    // Also prevent non-hex string values in general
    const input = e.target.value.trim();
    const newValue = input.startsWith('#') ? input : `#${input}`; // allow entry of hex with leading #
    if (
      newValue.length &&
      (newValue.length > 7 || !newValue.match(HEX_REGEX))
    ) {
      e.preventDefault();
    } else {
      const newColor = newValue.substring(1);
      setHexColor(newColor);
      if (newColor.length === 6) {
        // The user just entered a hex code and expects to see color - don't leave alpha at '00'
        const newAlpha = alpha !== '00' ? alpha : 'FF';
        onChange(newColor + newAlpha);
      }
    }
  };

  const onComplete = () => {
    if (fullColor.length === 8) onChange(fullColor);
  };

  return (
    <div
      className={classNames(
        styles.colorPickerWrap,
        hoverState && styles[hoverState],
        focusState && styles[focusState]
      )}
    >
      <ColorPicker
        color={value}
        setColor={(newColor: any) => {
          const [newHex, newAlpha] = parseHexAndAlpha(newColor);
          setHexColor(newHex);
          setAlpha(newAlpha);
          onChange(newColor);
        }}
        horizontal={horizontal}
        vertical={vertical}
        colorPickerStyle={colorPickerStyle}
      />
      <input
        type='text'
        className={styles.colorInput}
        value={`#${hexColor}`}
        onChange={handleChange}
        onFocus={() => setFocus('focus')}
        onMouseEnter={() => setHover('hover')}
        onBlur={() => {
          onComplete();
          setFocus('');
        }}
        onKeyPress={(e) => {
          if (e.key === 'Enter') onComplete();
        }}
        onMouseLeave={() => setHover('')}
      />
    </div>
  );
}
