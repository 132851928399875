import { useMemo } from 'react';
import { GigEngine } from './GigEngine';
import { GigContext, GigState } from './context';
import {
  createGig,
  Gig,
  Cell,
  NullCell
} from '../../components/RenderingEngine/GridInGrid/engine';
import useViewport from '../../hooks/useViewport';
import { useAppSelector } from '../../hooks';

const DeadCell = new NullCell() as Cell;

export const GigProvider = ({ children }: React.PropsWithChildren<any>) => {
  const { viewport } = useViewport();
  const theme = useAppSelector((s) => s.formBuilder.theme);
  const workingSteps = useAppSelector((s: any) => s.formBuilder.workingSteps);
  const activeStepId = useAppSelector((s: any) => s.formBuilder.activeStepId);
  const gigPosition = useAppSelector(
    ({ formBuilder: fb }: any) => fb.gigPosition
  );

  const gig: Gig | null = useMemo(() => {
    if (activeStepId) {
      return createGig(workingSteps[activeStepId], {
        viewport,
        theme
      });
    }

    return null;
  }, [workingSteps, activeStepId, viewport, theme]);

  const node: Cell = useMemo(() => {
    return gig ? gig.get(gigPosition) ?? DeadCell : DeadCell;
  }, [gig, workingSteps, activeStepId, gigPosition, viewport]);

  const gigState: GigState = useMemo(() => {
    return {
      gig,
      node,
      workingSteps,
      activeStepId,
      activeStep: workingSteps[activeStepId],
      gigPosition,
      viewport
    };
  }, [gig, node, workingSteps, activeStepId, gigPosition, viewport]);

  return (
    <GigContext.Provider value={gigState}>
      <GigEngine>{children}</GigEngine>
    </GigContext.Provider>
  );
};
