import { isObject } from '../utils/utilities';
import { getViewportKeyName } from '../utils/viewport';
import { Viewport, ViewportState, ViewportType } from '../viewports';

/**
 * Base class of a Model. This class is meant to be used by the Node
 * to provide context/purpose to the Node. The Model holds business
 * data for the Node.
 */
export class Model {
  static Type = 'Model';

  #type: string; // The type of the model, mainly used for internal purposes

  #viewport: Viewport; // The current viewport
  #sharedState: any; // State that is common to the model no matter the viewport
  #viewportState: ViewportState; // State divided by viewport (ex. desktop and mobile)
  #viewportFields: readonly string[]; // Fields of the initial state that should be divided into viewportState

  /**
   * The model's state is spread on the instance of the model so it's necessary
   * to allow any property to possibly be defined.
   */
  [key: string]: any;

  constructor(
    type = Model.Type,
    state: { [key: string]: any } = {},
    viewportFields: readonly string[],
    viewport?: Viewport
  ) {
    this.#type = type;
    this.#viewport = viewport ?? new Viewport(Viewport.Desktop);
    this.#sharedState = {};
    this.#viewportState = new ViewportState(this.#viewport);
    this.#viewportFields = viewportFields;

    // Initialize the viewport state with key/values of the viewport fields
    Viewport.Viewports.forEach((viewportName) => {
      this.#viewportState.with(
        viewportName,
        (_: any, set: (newState: any) => void) => {
          const viewportState = this.#viewportFields.reduce(
            (_state: any, field: string) => {
              const viewportFieldValue = this.#viewport.getKey(state, field, {
                viewport: viewportName,
                fallback: null
              });

              return {
                ..._state,
                [field]: viewportFieldValue
              };
            },
            {}
          );

          set(viewportState);
        }
      );
    });

    // Remaining state (non-viewport fields) will be stored in sharedState
    this.#sharedState = Object.keys(state).reduce(
      (_state: any, field: string) => {
        const desktopField = getViewportKeyName(field, Viewport.Desktop);

        if (this.#viewportFields.includes(desktopField)) {
          return _state;
        }

        return {
          ..._state,
          [field]: state[field]
        };
      },
      {}
    );

    // Assign getters/setters to this instance of the state whether shared or viewport fields
    for (const key in this.state) {
      if (this[key as keyof typeof this] === undefined) {
        if (this.#viewportFields.includes(key)) {
          if (isObject(this.state[key])) {
            Object.defineProperty(this, key, {
              get: () => {
                /**
                 * Proxy is returned when getting a viewport field that is an object because it
                 * acts like a catch-all to getting/setting any property on the field.
                 * eg. (viewportField.propertyA)
                 */
                return new Proxy(this.state[key], {
                  get: (target, property) => target[property],
                  set: (_, property, value) => {
                    this.#viewportState.setKey(key, {
                      ...this.isolatedState[key],
                      [property]: value
                    });

                    return true;
                  }
                });
              },
              set: (value: any) => {
                this.#viewportState.setKey(key, value);
              }
            });
          } else {
            Object.defineProperty(this, key, {
              get: () => this.state[key],
              set: (value: any) => {
                this.#viewportState.setKey(key, value);
              }
            });
          }
        } else {
          Object.defineProperty(this, key, {
            get: () => this.#sharedState[key],
            set: (value: any) => (this.#sharedState[key] = value)
          });
        }
      }
    }
  }

  /**
   * Given the state and hashmap of default values, it will assign the default values
   * if the given key does not exist on the state or is falsey.
   * @param state - State to transform
   * @param defaultValues - Hashmap of fields to default values
   * @returns {object} New state that has default values set
   */
  static transform(state: any = {}, defaultValues: any = {}) {
    const _state = { ...state };

    Object.keys(defaultValues).forEach((key) => {
      if (_state[key] === undefined || _state[key] === null) {
        _state[key] = defaultValues[key];
      }
    });

    return _state;
  }

  /**
   * Returns the combined raw data of the model.
   * @returns {object} Raw data of the model
   */
  getRaw(): { [key: string]: any } {
    const raw = {
      ...this.#sharedState
    };

    this.#viewportFields.forEach((field: string) => {
      raw[field] = this.#viewportState.getKey(field, Viewport.Desktop);
      raw[getViewportKeyName(field, Viewport.Mobile)] =
        this.#viewportState.getKey(field, Viewport.Mobile, { fallback: null });
    });

    return raw;
  }

  /**
   * Returns whether the model's viewport is set to desktop
   * @returns {boolean}
   */
  isDesktopViewport(): boolean {
    return this.viewport.isDesktop();
  }

  /**
   * Returns whether the model's viewport is set to mobile
   * @returns {boolean}
   */
  isMobileViewport(): boolean {
    return this.viewport.isMobile();
  }

  /**
   * Change the viewport of the model
   * @param viewport - The viewport to set the Model's viewport to
   */
  setViewport(viewport: Viewport): void {
    this.#viewport = viewport;
    this.#viewportState.setViewport(viewport);
  }

  /**
   * Returns an object keyed by viewport with the values of the target key
   * on each viewport. Ex { desktop: 'a', mobile: 'b' }.
   * @returns {object}
   */
  getKeyOnAllViewports(key: string): { [key: string]: any } {
    return Viewport.Viewports.reduce((obj, viewport) => {
      return {
        ...obj,
        [viewport]: this.#viewportFields.includes(key)
          ? this.#viewportState.getKey(key, viewport)
          : this.#sharedState[key]
      };
    }, {});
  }

  /**
   * Set a key on each viewport to a specific value provided
   * @param key - Key to set on each viewport
   * @param value - Value of the key to set on each viewport
   */
  setKeyOnAllViewports(key: string, value: any) {
    if (this.#viewportFields.includes(key)) {
      Viewport.Viewports.forEach((viewport) => {
        this.#viewportState.setKey(key, value, viewport);
      });
    } else {
      this.#sharedState[key] = value;
    }
  }

  /**
   * Retrieve the value of the key on a specific viewport
   * @param key - Key to retrieve the value of
   * @param viewport - Viewport to retrieve the value of the key from
   * @returns {any}
   */
  getKey(key: string, viewport: ViewportType = this.#viewport.activeViewport) {
    return this.#viewportFields.includes(key)
      ? this.#viewportState.getKey(key, viewport)
      : this.#sharedState[key];
  }

  /**
   * Set the value of a key on a specific viewport
   * @param key - Key to set the value of
   * @param value - Value to set the key to
   * @param viewport - Viewport to set the value of the key on
   */
  setKey(
    key: string,
    value: any,
    viewport: ViewportType = this.#viewport.activeViewport
  ) {
    if (this.#viewportFields.includes(key)) {
      this.#viewportState.setKey(key, value, viewport);
    } else {
      this.#sharedState[key] = value;
    }
  }

  /**
   * Clear the value of the key on a specific viewport
   * @param key - Key to clear the value of
   * @param viewport - Viewport to clear the value of the key on
   */
  clearKey(
    key: string,
    viewport: ViewportType = this.#viewport.activeViewport
  ) {
    if (this.#viewportFields.includes(key)) {
      this.#viewportState.setKey(key, null, viewport);
    } else {
      this.#sharedState[key] = null;
    }
  }

  /**
   * Create a copy of the model
   * @param includeViewport - Whether to include the instance of the viewport on the copied version of the model
   * @returns {Model}
   */
  copy(includeViewport = false): Model {
    if (this.constructor.name === 'Model') {
      return new (this.constructor as typeof Model)(
        undefined,
        JSON.parse(JSON.stringify(this.getRaw())),
        this.#viewportFields,
        includeViewport ? this.#viewport : undefined
      );
    } else {
      return new (this.constructor as typeof Model)(
        JSON.parse(JSON.stringify(this.getRaw())),
        includeViewport ? this.#viewport : undefined,
        this.#viewportFields
      );
    }
  }

  /**
   * Returns the value of the state of the model considering the current viewport.
   */
  get state(): { [key: string]: any } {
    return {
      ...this.#sharedState,
      ...this.#viewportState.get(undefined, {
        inherit: Viewport.Desktop,
        inheritIfFalse: ['axis']
      })
    };
  }

  /**
   * Returns only the current viewport's state. This excludes shared state between the viewports.
   */
  get isolatedState(): { [key: string]: any } {
    return this.#viewportState.get(undefined, {
      fallback: null,
      inherit: null
    });
  }

  set sharedState(value: { [key: string]: any }) {
    if (Object.keys(this.#sharedState).length === 0) {
      this.#sharedState = value;
    }
  }

  /**
   * Returns only the shared state between the viewports.
   */
  get sharedState(): { [key: string]: any } {
    return this.#sharedState;
  }

  get viewport(): Viewport {
    return this.#viewport;
  }

  get viewportState(): ViewportState {
    return this.#viewportState;
  }

  get viewportFields(): readonly string[] {
    return this.#viewportFields;
  }

  get type(): string {
    return this.#type;
  }

  /**
   * Returns a hash of the model that is made up of the current data.
   * Includes the type of the model and the raw data of the model.
   * @param {boolean} removeId defaults false - Whether to remove the id from the hash
   * @returns {string}
   */
  toHash(removeId = false): string {
    const rawData = { ...this.getRaw() };

    if (removeId) {
      Object.assign(rawData, { id: undefined });
    }

    return `[${this.#type},${JSON.stringify(rawData)}]`;
  }
}
