import { forwardRef, Ref } from 'react';
import { CloseIcon } from '../Icons';
import { ignoreDrawControls } from '../RenderingEngine/Controls/utils';
import styles from './styles.module.scss';
import classNames from 'classnames';
import Modal from './components/Modal';
import ReactPortal from './components/ReactPortal';
import testIds from '../../utils/testIds';

function Dialog(
  {
    className,
    children,
    size = 'sm',
    title,
    header,
    isOpen,
    onClose = () => {},
    shouldShowCloseIcon = true,
    alignCenter = false,
    style,
    visibleBackdrop = true
  }: any,
  ref: Ref<HTMLDivElement>
) {
  return (
    <ReactPortal>
      <div
        className={classNames(styles.dialogWrapper, {
          [styles.show]: isOpen
        })}
      >
        {isOpen && (
          <div
            onClick={() => onClose()}
            className={classNames(styles.backdrop, {
              [styles.visible]: visibleBackdrop
            })}
          />
        )}
        <div
          className={classNames(
            styles.dialogContainer,
            isOpen && styles.animate
          )}
        >
          {isOpen && (
            <Modal onClose={onClose}>
              <div
                ref={ref}
                style={style}
                className={classNames(
                  ignoreDrawControls,
                  styles.dialog,
                  className,
                  styles[`dialog-${size}`],
                  alignCenter && 'text-center',
                  className
                )}
              >
                {header && header(title)}
                {!header && title && (
                  <h2 className={styles.dialogHeader}>{title}</h2>
                )}
                {children}
                {shouldShowCloseIcon && (
                  <div
                    data-testid={testIds.dialogClose}
                    className={styles.dialogClose}
                    tabIndex={0}
                    onClick={onClose}
                  >
                    <CloseIcon />
                  </div>
                )}
              </div>
            </Modal>
          )}
        </div>
      </div>
    </ReactPortal>
  );
}

export default forwardRef(Dialog);
