export default function CheckBoxIcon({
  width = 16,
  height = 16,
  color = '#ffffff',
  backgroundColor = 'var(--prim-100)'
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        y='-0.00012207'
        width='16'
        height='16'
        rx='4'
        fill={backgroundColor}
      />
      <path
        d='M6.82919 11.615C6.73373 11.7111 6.6035 11.7646 6.46821 11.7646C6.33293 11.7646 6.20269 11.7111 6.10724 11.615L3.22438 8.73171C2.92521 8.43253 2.92521 7.9474 3.22438 7.64878L3.58536 7.28771C3.88463 6.98854 4.3692 6.98854 4.66838 7.28771L6.46821 9.08764L11.3316 4.22414C11.6309 3.92496 12.1159 3.92496 12.4146 4.22414L12.7756 4.58521C13.0748 4.88439 13.0748 5.36943 12.7756 5.66814L6.82919 11.615Z'
        fill={color}
      />
    </svg>
  );
}
