import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/editor.scss';
import styles from './styles.module.scss';

import { useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import { RenderedStepContainer } from './RenderingEngine';
import useFeatheryRedux from '../redux';
import SelectionPanel from './SelectionPanel';
import DetailPanel from './DetailPanel';
import { useGig } from '../context/Gig';
import { PanelWarningOverlay } from './Panels/components';

import { Viewport } from './RenderingEngine/GridInGrid/engine/viewports';
import { useStepUpdater } from '../hooks/useStepUpdater';
import { useElementRenderData } from '../hooks/useElementRenderData';
import useViewport from '../hooks/useViewport';

import NavigationNodeRules from './NavigationRules/NavigationNodeRules';
import { useAppSelector } from '../hooks';
import BreadcrumbBar from './BreadcrumbBar';
import SidePanel from './SidePanel';

export default function StepEditor() {
  const { handleUpdates } = useStepUpdater();
  const { node: cellNode, gigPosition, activeStepId, workingSteps } = useGig();
  const { viewport } = useViewport();
  const { assetProps, baseStyle, overrideProps, overrideStyle } =
    useElementRenderData(cellNode);

  const {
    formBuilder: { wipeFocus, gigSetPosition }
  } = useFeatheryRedux();

  const editorTooltipTarget = useRef(null);
  const [showConnectionsDetails, setShowConnectionsDetails] = useState(false);

  const theme = useAppSelector((state) => state.formBuilder.theme);

  const { elementId } = useAppSelector(
    (state) => state.formBuilder.focusedElement
  );

  // when the focused element or selected position changes, reshow the details panel
  useEffect(() => {
    if (gigPosition && gigPosition.length) setShowConnectionsDetails(false);
  }, [elementId, gigPosition]);

  if (!viewport) return null;

  return (
    <div id='editor-panel' className={classNames(styles.panel)}>
      <SidePanel
        position='left'
        classNames={{
          sidebarContent: classNames(styles.editorSidebar, 'editor-sidebar')
        }}
      >
        <PanelWarningOverlay />
        <SelectionPanel
          disabled={viewport === Viewport.Mobile}
          handleUpdates={handleUpdates}
          setShowConnectionsPanel={(show) => {
            if (show) {
              wipeFocus();
              gigSetPosition(null);
            }
            setShowConnectionsDetails(show);
          }}
        />
      </SidePanel>
      <div className={styles.editorContainer}>
        <BreadcrumbBar />
        <RenderedStepContainer />
      </div>
      <SidePanel
        position='right'
        ref={editorTooltipTarget}
        classNames={{
          sidePanel: (hidden: boolean) =>
            classNames(styles.panel, {
              [styles.connectionsSidePanel]: showConnectionsDetails && !hidden
            }),
          content: 'editor-canvas',
          sidebarContent: classNames('editor-sidebar', {
            connections: showConnectionsDetails
          })
        }}
      >
        <PanelWarningOverlay />
        <div className={styles.detailsWrapper}>
          <div className={styles.overlayWrapper}>
            <div className='editor-sidebar' ref={editorTooltipTarget}>
              <div className='editor-actions'></div>
            </div>
          </div>
          {showConnectionsDetails ? (
            <NavigationNodeRules
              key={activeStepId}
              step={workingSteps[activeStepId]}
              inPanel
              setShowConnectionsPanel={setShowConnectionsDetails}
            />
          ) : (
            <DetailPanel
              key={`${cellNode?.id}-${viewport}`} // This is needed to prevent onBlur issues within the DetailPanel when selecting a new element
              mode='builder'
              viewport={viewport}
              theme={theme}
              {...assetProps}
              baseStyle={baseStyle}
              overrideProps={overrideProps}
              overrideStyle={overrideStyle}
              cellNode={cellNode}
              handleUpdates={handleUpdates}
              workingSteps={workingSteps} // NOTE: This is passed to the DetailPanel to trigger a re-render when the workingSteps change
              onClose={wipeFocus}
            />
          )}
        </div>
      </SidePanel>
    </div>
  );
}
