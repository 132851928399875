import 'react-quill/dist/quill.snow.css';
import '../../style/dialog-form.css';

import { Form } from 'react-bootstrap';
import { useState } from 'react';

import emailStyles from './styles.module.scss';
import { useParams } from 'react-router-dom';
import useIntegrations from './useIntegrations';
import { ALL_INTEGRATIONS_MAP, INTEGRATIONS } from './types';
import IntegrationsSidebar from './IntegrationsSidebar';
import { InlineTooltip, NumberInput, PropertyLabel, TextField } from '../Core';
import { useAppSelector } from '../../hooks';

const DEFAULT_SETTINGS = {
  otp_length: 4,
  service_name: ''
};

function SMSOTPSettingsSidebar() {
  const { formId } = useParams<{ formId: string }>();

  // Global/cached integration settings
  const integration = useIntegrations({
    type: INTEGRATIONS.SMS_OTP,
    panelId: formId,
    includeInactive: true
  });
  const org = useAppSelector((state) => state.accounts.organization) as any;
  const enabled = org && org.tier >= 4 && org.enterprise_features.otp;

  const [isPartial, setIsPartial] = useState(false);
  const [OTPConfig, setOTPConfig] = useState(
    integration?.data.metadata ?? DEFAULT_SETTINGS
  );
  const [err, setErr] = useState('');
  const updateOTPConfig = (key: string, val: any) =>
    setOTPConfig({ ...OTPConfig, [key]: val });

  function onSubmitCustom(newIsActive: boolean) {
    setErr('');
    if (newIsActive) {
      const partial = !OTPConfig.service_name || !OTPConfig.otp_length;
      setIsPartial(partial);
      if (partial) return;

      if (OTPConfig.otp_length < 4) {
        setErr('The OTP code must have a minimum length of 4');
        return;
      }
    }

    return {
      isUpdate: integration?.data,
      metadata: OTPConfig
    };
  }

  let smsPreview = `Your code for ${
    OTPConfig.service_name ?? ''
  } is: ${'1'.repeat(OTPConfig.otp_length ?? 0)}`;

  if (OTPConfig.domain) {
    smsPreview += `\n\n@${OTPConfig.domain} #${'1'.repeat(
      OTPConfig.otp_length ?? 0
    )}`;
  }
  return (
    <IntegrationsSidebar
      integrationInfo={ALL_INTEGRATIONS_MAP[INTEGRATIONS.SMS_OTP]}
      isPartial={isPartial}
      onSubmitCustom={onSubmitCustom}
      customError={err}
    >
      <Form>
        {!enabled &&
          'SMS OTP codes powered by Feathery may only be used on the Business plan.'}
        <Form.Group
          controlId='otp-settings'
          className={emailStyles.emailInputGroup}
        >
          <PropertyLabel label='Length of OTP Code' />
          <NumberInput
            className='dialog-form-input'
            value={OTPConfig.otp_length}
            onComplete={({ value: length }: any) =>
              updateOTPConfig('otp_length', length)
            }
            error={isPartial && !OTPConfig.otp_length}
            max={20}
          />
          <PropertyLabel label='Service Name' />
          <TextField
            className='dialog-form-input'
            placeholder='Feathery'
            value={OTPConfig.service_name}
            onChange={(name: string) => updateOTPConfig('service_name', name)}
            error={isPartial && !OTPConfig.service_name}
          />
          <div style={{ display: 'flex' }}>
            <PropertyLabel label='Domain (optional)' />
            <InlineTooltip text='The domain you host your form on. This will be included as part of your OTP messages to enable iOS OTP keyboard autofill.' />
          </div>
          <TextField
            className='dialog-form-input'
            placeholder='form.feathery.io'
            value={OTPConfig.domain}
            onChange={(domain: string) => updateOTPConfig('domain', domain)}
          />
          {OTPConfig.service_name && (
            <>
              <PropertyLabel label='SMS Preview' />
              <pre
                style={{
                  fontFamily: 'inherit',
                  fontSize: 13.3,
                  padding: 8
                }}
              >
                {smsPreview}
              </pre>
            </>
          )}
        </Form.Group>
      </Form>
    </IntegrationsSidebar>
  );
}

export default SMSOTPSettingsSidebar;
