import { StepIdMap } from '../Panels/FlowPanel/linearFlow';
import { Step } from '../../utils/step';

export function recurseSteps(stepIdMap: StepIdMap) {
  const entries = Object.entries(stepIdMap);
  if (entries.length === 0) return [[], [], []];

  let originStep;
  entries.forEach(([, step]) => {
    if (step.origin) originStep = step;
  });

  const orderedSteps = [];
  const seenStepIds = new Set();
  const edges: Record<string, Record<string, boolean>> = {};

  if (originStep) {
    const stepQueue: Array<[Step, number]> = [[originStep, 0]];
    while (stepQueue.length > 0) {
      // @ts-expect-error TS(2488) FIXME: Type 'number[] | undefined' must have a '[Symbol.i... Remove this comment to see the full error message
      const [step, depth] = stepQueue.shift();
      if (seenStepIds.has(step.id)) continue;
      seenStepIds.add(step.id);

      if (depth >= orderedSteps.length) orderedSteps.push([]);
      // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
      orderedSteps[depth].push(step);

      for (let i = 0; i < step.next_conditions.length; i++) {
        const nextId = step.next_conditions[i].next_step;
        const nextStep = stepIdMap[nextId];
        stepQueue.push([nextStep, depth + 1]);

        if (nextId in edges && step.id in edges[nextId]) {
          edges[nextId][step.id] = true;
        } else if (!(step.id in edges)) {
          edges[step.id] = { [nextId]: false };
        } else if (step.id in edges && !(nextId in edges[step.id])) {
          edges[step.id][nextId] = false;
        }
      }
    }
  }

  const floatingSteps: any = [];
  entries.forEach(([id, step]: [string, any]) => {
    if (!seenStepIds.has(id)) {
      seenStepIds.add(id);
      floatingSteps.push(step);
      for (let i = 0; i < (step as any).next_conditions.length; i++) {
        const nextId = (step as any).next_conditions[i].next_step;
        if (nextId in edges && (step as any).id in edges[nextId]) {
          edges[nextId][(step as any).id] = true;
        } else if (!((step as any).id in edges)) {
          edges[(step as any).id] = { [nextId]: false };
        } else if (
          (step as any).id in edges &&
          !(nextId in edges[(step as any).id])
        ) {
          edges[(step as any).id][nextId] = false;
        }
      }
    }
  });

  const formattedEdges = Object.entries(edges).flatMap(([source, targets]) =>
    Object.entries(targets).map(([target, bidirectional]) => ({
      source,
      target,
      bidirectional
    }))
  );
  return [orderedSteps, formattedEdges, floatingSteps];
}

export const getEdgeLabel = (formData: any, source: any, target: any) => {
  let label = 0;
  if ('step_flow_rates' in formData) {
    if (source in formData.step_flow_rates) {
      const rate = formData.step_flow_rates[source][target];
      label = rate ? rate.toFixed(2) : 0;
    }
  }
  return `${label}%`;
};

// this helper function returns the intersection point
// of the line between the center of the intersectionNode and the target node
function getNodeIntersection(intersectionNode: any, targetNode: any) {
  // https://math.stackexchange.com/questions/1724792/an-algorithm-for-finding-the-intersection-point-between-a-center-of-vision-and-a

  // Add 5 to account for visual discrepancies
  const w = intersectionNode.width / 2 + 5;
  const h = intersectionNode.height / 2;

  const x2 = intersectionNode.position.x + w;
  const y2 = intersectionNode.position.y + h;
  const x1 = targetNode.position.x + w;
  const y1 = targetNode.position.y + h;

  const xx1 = (x1 - x2) / (2 * w) - (y1 - y2) / (2 * h);
  const yy1 = (x1 - x2) / (2 * w) + (y1 - y2) / (2 * h);
  const a = 1 / (Math.abs(xx1) + Math.abs(yy1));
  const xx3 = a * xx1;
  const yy3 = a * yy1;
  const x = w * (xx3 + yy3) + x2;
  const y = h * (-xx3 + yy3) + y2;

  return { x, y };
}

// returns the parameters (sx, sy, tx, ty, sourcePos, targetPos) you need to create an edge
export function getEdgeParams(source: any, target: any) {
  const sourceIntersectionPoint = getNodeIntersection(source, target);
  const targetIntersectionPoint = getNodeIntersection(target, source);

  return {
    sx: sourceIntersectionPoint.x,
    sy: sourceIntersectionPoint.y,
    tx: targetIntersectionPoint.x,
    ty: targetIntersectionPoint.y
  };
}
