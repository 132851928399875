export default function ConnectionIcon({
  width = 16,
  height = 17,
  color = 'white',
  ...props
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 17'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.40039 14.0156C1.40039 13.448 1.86054 12.9878 2.42817 12.9878H9.47577C10.8543 12.9878 11.9718 11.8703 11.9718 10.4918V2.85688C11.9718 2.28925 12.432 1.8291 12.9996 1.8291C13.5672 1.8291 14.0274 2.28925 14.0274 2.85688V10.4918C14.0274 13.0056 11.9895 15.0434 9.47577 15.0434L2.42817 15.0434C1.86054 15.0434 1.40039 14.5832 1.40039 14.0156Z'
      />
      <rect x='10.4277' width='5' height='5' rx='2.5' />
      <rect x='0.142578' y='11.4277' width='5' height='5' rx='2.5' />{' '}
    </svg>
  );
}
