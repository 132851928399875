export default function LockIcon({
  width = 32,
  height = 32,
  backgroundColor = '#DBDFE8',
  color = '#6C7589',
  ...props
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <circle cx='8' cy='8' r='8' fill={backgroundColor} />
      <path
        fill={color}
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        d='M10.03 11.81H5.973A.973.973 0 0 1 5 10.835v-2.67c0-.537.436-.973.973-.973h4.054c.537 0 .973.436.973.973v2.67a.968.968 0 0 1-.97.973Z'
      />
      <path
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        d='M9.973 7.133V5.967A1.968 1.968 0 0 0 8.006 4 1.968 1.968 0 0 0 6.04 5.967v1.226'
      />
    </svg>
  );
}
