/**
 * Simple cache class that is used to track key to values.
 */
export class Cache<T> {
  #state: { [key: string]: any };

  constructor() {
    this.#state = {};
  }

  /**
   * Retrieve a key from the cache
   * @param key - Key to retrieve from the cache
   * @param fallback - If the key is unset, `set` will be called with this fallback function
   * @returns {any}
   */
  get(key: string, fallback: null | (() => T) = null) {
    if (this.#state[key]) {
      return this.#state[key];
    }

    if (fallback) {
      return this.set(key, fallback);
    }

    return undefined;
  }

  /**
   * Set a key on the cache
   * @param key - Key to set on the cache
   * @param value - Value of the key to set on the cache
   * @param args - If the value is a function, these arguments will be passed to the function
   */
  set(key: string, value: any, ...args: any[]) {
    this.#state[key] = typeof value === 'function' ? value(...args) : value;
    return this.#state[key];
  }

  /**
   * Remove a key from the cache
   * @param key - Key to remove from the cache
   */
  remove(key: any) {
    this.#state[key] = null;
    delete this.#state[key];
  }

  /**
   * Clear the cache.
   */
  clear() {
    this.#state = {};
  }
}
