export default function EditIcon({
  width = 32,
  height = 32,
  color = '#FFFFFF',
  ...props
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 18 18'
      stroke={color}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M12.7399 3.26621L14.7334 5.2587L12.7399 3.26621ZM14.0218 1.5109L8.63167 6.90107C8.35316 7.17919 8.16321 7.53354 8.08578 7.91944L7.58789 10.4117L10.0802 9.91287C10.466 9.83569 10.8199 9.64651 11.0985 9.36792L16.4887 3.97775C16.6507 3.81577 16.7791 3.62348 16.8668 3.41185C16.9545 3.20022 16.9996 2.97339 16.9996 2.74433C16.9996 2.51526 16.9545 2.28843 16.8668 2.0768C16.7791 1.86517 16.6507 1.67288 16.4887 1.5109C16.3267 1.34893 16.1344 1.22044 15.9228 1.13278C15.7112 1.04512 15.4843 1 15.2553 1C15.0262 1 14.7994 1.04512 14.5877 1.13278C14.3761 1.22044 14.1838 1.34893 14.0218 1.5109V1.5109Z'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.1178 12.2942V15.1177C15.1178 15.617 14.9195 16.0958 14.5665 16.4488C14.2134 16.8018 13.7347 17.0001 13.2354 17.0001H2.88237C2.38314 17.0001 1.90435 16.8018 1.55133 16.4488C1.19832 16.0958 1 15.617 1 15.1177V4.7647C1 4.26546 1.19832 3.78667 1.55133 3.43366C1.90435 3.08064 2.38314 2.88232 2.88237 2.88232H5.70593'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
