function IDVerification({ width = 64, height = 64 }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      shapeRendering='geometricPrecision'
      textRendering='geometricPrecision'
      imageRendering='optimizeQuality'
      fillRule='evenodd'
      clipRule='evenodd'
      viewBox='0 0 509.3 334.58'
      width={width}
      height={height}
    >
      <path d='M161.16 294.14v7.9c0 5.87-4.82 10.69-10.68 10.69h-47.65c-5.87 0-10.69-4.81-10.69-10.69v-7.9H49.38c-15.53 0-27.73-4.4-36.35-13.02C4.42 272.51 0 260.33 0 244.77V49.38c0-15.57 4.39-27.76 13.01-36.37C21.62 4.39 33.81 0 49.38 0h366.14c15.57 0 27.75 4.42 36.36 13.03 8.62 8.62 13.02 20.82 13.02 36.35v91.01c-8-3.42-16.48-5.95-25.3-7.47V49.38c0-8.56-1.9-14.76-5.61-18.47-3.7-3.7-9.89-5.61-18.47-5.61H49.38c-8.59 0-14.79 1.9-18.49 5.59-3.69 3.7-5.59 9.9-5.59 18.49v195.39c0 8.58 1.91 14.76 5.61 18.46 3.71 3.71 9.91 5.61 18.47 5.61H308.4c1.86 8.86 4.74 17.34 8.51 25.3H161.16zm97.5-90.17c-4.1 0-7.43-4.44-7.43-9.92 0-5.48 3.33-9.93 7.43-9.93h65.13c-3.97 6.24-7.35 12.88-10.07 19.85h-55.06zm0-54.27c-4.1 0-7.43-4.45-7.43-9.93 0-5.48 3.33-9.92 7.43-9.92h127.18c2.71 0 5.09 1.94 6.39 4.83a113.673 113.673 0 0 0-34.36 15.02h-99.21zm0-54.29c-4.1 0-7.43-4.44-7.43-9.92 0-5.48 3.33-9.92 7.43-9.92h129.69c4.11 0 7.44 4.44 7.44 9.92 0 5.48-3.33 9.92-7.44 9.92H258.66zM140.4 210.4H52.49c1.12-4.32 2.68-8.43 5.43-12.08 2.38-3.13 5.32-5.44 8.59-7.27 10.07-5.62 36.75-7.36 46.73-15.29 1.51-3.26 3.04-7.9 4-10.83.05-.15-.08.25.41-1.21.41-1.23-.24-2.9-1.33-4.7-1.85-3.03-4.96-6.43-6.35-8.63l-8.17-13c-2.99-4.46-4.55-8.54-4.64-11.88-.05-1.58.22-3.01.8-4.25.61-1.32 1.55-2.42 2.81-3.27.59-.4 1.25-.74 1.98-1.02-.53-7.05-.73-15.93-.39-23.37.18-1.77.52-3.53 1.01-5.3 3-10.7 12.19-18.4 22.69-22.03 5.1-1.76 3.13-5.96 8.28-5.67 1.88.1 3.92.37 6.06.79 11.49 3.93 7.5 2.52 14.33 4.88 10.5 3.63 19.7 11.33 22.69 22.03.49 1.77.83 3.53 1.01 5.3.34 7.44.14 16.32-.39 23.37.73.28 1.39.62 1.99 1.02 1.25.85 2.19 1.95 2.8 3.27.58 1.24.85 2.67.81 4.25-.1 3.34-1.66 7.42-4.64 11.88l-8.18 13c-1.38 2.2-4.5 5.6-6.35 8.63-1.09 1.8-1.74 3.47-1.32 4.7.48 1.46.35 1.06.4 1.21.97 2.93 2.5 7.57 4 10.83 9.99 7.93 36.66 9.67 46.73 15.29 3.27 1.83 6.22 4.14 8.59 7.27 2.76 3.65 4.32 7.76 5.43 12.08h-87.9z' />
      <path
        fill='#10A64A'
        d='M420.09 156.14c49.27 0 89.21 39.95 89.21 89.22 0 49.27-39.94 89.22-89.21 89.22-49.27 0-89.22-39.95-89.22-89.22 0-49.27 39.95-89.22 89.22-89.22zm-25.5 76.31 13.5 12.63 35.3-35.81c3.1-3.15 5.04-5.67 8.86-1.73l12.39 12.7c4.07 4.02 3.87 6.37.03 10.12l-49.5 48.67c-8.1 7.93-6.68 8.41-14.89.27l-26.55-26.38c-1.71-1.85-1.53-3.72.35-5.57l14.38-14.92c2.18-2.3 3.92-2.1 6.13.02z'
      />
    </svg>
  );
}

export default IDVerification;
