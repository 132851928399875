import { memo } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

function PlanDetails({ plan = {} }) {
  return (
    <ul className={styles.planDetails}>
      {(plan as any).keyPoints &&
        (plan as any).keyPoints.map((p: any) => (
          <li key={p} className={classNames(styles.point, styles.keyPoint)}>
            {p}
          </li>
        ))}
      {(plan as any).additions &&
        (plan as any).additions.map((p: any) => (
          <li
            key={p}
            className={classNames(styles.point, styles.additionalPoint)}
          >
            {p}
          </li>
        ))}
      {(plan as any).more && (
        <a
          href='https://feathery.io/pricing'
          className={classNames(
            styles.point,
            styles.additionalPoint,
            styles.more
          )}
          target='_blank'
          rel='noreferrer'
        >
          and more ...
        </a>
      )}
    </ul>
  );
}

export default memo(PlanDetails);
