export default function TextRight({ width = 24, height = 24 }) {
  return (
    <svg
      width={width}
      height={height}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4 5a1 1 0 0 0 0 2V5Zm16 2a1 1 0 1 0 0-2v2ZM4 7h16V5H4v2ZM9.336 17a1 1 0 1 0 0 2v-2Zm10.667 2a1 1 0 1 0 0-2v2ZM9.336 19h10.667v-2H9.336v2ZM13.336 11a1 1 0 0 0 0 2v-2Zm6.667 2a1 1 0 1 0 0-2v2Zm-6.667 0h6.667v-2h-6.667v2Z'
        fill='#414859'
      />
    </svg>
  );
}
