import { httpGet, URL } from './utils';

export default {
  getBillingMetrics: (token: string) => {
    return httpGet({
      token,
      url: `${URL.METRICS}billing/`
    });
  },
  getPanelMetrics: (
    token: string,
    panelId: string,
    startTime: string,
    endTime: string
  ) => {
    return httpGet({
      token,
      url: `${URL.METRICS}panel/${panelId}/`,
      params: { start_time: startTime, end_time: endTime }
    });
  }
};
