import { memo, useMemo } from 'react';

import { DirectionRules } from './components/DirectionRules';
import { PanelWarningOverlay } from '../Panels/components';

import styles from './styles.module.scss';

const navRuleToTargetExists = (step: any, targetStep: any) => {
  return step.next_conditions.reduce(
    (cur: any, condition: any) => {
      const hasTarget = condition.next_step === targetStep.id;
      const isEarlier =
        !condition.created_at ||
        (cur.created_at && condition.created_at <= cur.created_at);
      if (hasTarget && (!cur.ruleExists || isEarlier))
        cur = { ruleExists: true, created_at: condition.created_at };
      return cur;
    },
    { ruleExists: false }
  );
};

function NavigationEdgeRules({ previousStep, nextStep }: any) {
  // We want to have nav rules ordered consistently in the panel.
  // Here we are ordering by the created_at time of the step nav conditions.  Note that preexisting (published) nav rule conditions will not
  // have a created_at so by default they are considered as older than newly created ones that will have a created_at.
  // So the ordering rules are:
  // 1. If the navigation is uni-directional, that rule will appear first (on top) in the nav rules panel.
  // 2. If the navigation is bi-directional, then the rule with a condition (for the target) that has created_at as undefined
  //      or older than any condition on the target, will appear first/top in the panel.
  // 3. If the navigation is bi-directional and the top rule is removed, we want the ordering to remain unchanged after
  //      the removal until the user selects another step or edge.  After any reselection, the now uni-directional nav rules
  //      are reorderd as in 1 above.
  // When creating a new nav rule via a newly added edge, make sure the "new" flag is set on the correct nav rule direction only.

  const forward = useMemo(() => {
    if (previousStep && nextStep) {
      const { ruleExists: prevToNextExists, created_at: prevToNextTimestamp } =
        navRuleToTargetExists(previousStep, nextStep);
      const { ruleExists: nextToPrevExists, created_at: nextToPrevTimestamp } =
        navRuleToTargetExists(nextStep, previousStep);
      const targetOnly = nextToPrevExists && !prevToNextExists;
      const targetIsEarlier =
        nextToPrevExists &&
        prevToNextExists &&
        prevToNextTimestamp &&
        (!nextToPrevTimestamp || nextToPrevTimestamp <= prevToNextTimestamp);
      return !(targetOnly || targetIsEarlier);
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousStep.id, nextStep.id]);

  const firstStep = forward ? previousStep : nextStep;
  const secondStep = forward ? nextStep : previousStep;
  return (
    <div className={styles.navRules}>
      <PanelWarningOverlay />
      <DirectionRules previousStep={firstStep} nextStep={secondStep} />
      <DirectionRules
        previousStep={secondStep}
        nextStep={firstStep}
        forwardDirection={false}
      />
    </div>
  );
}

export default memo(NavigationEdgeRules);
