export default function SliderIcon() {
  return (
    <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M28.5 15.5C25.5389 15.5 22.9535 17.1088 21.5703 19.5H9.5C7.29086 19.5 5.5 21.2909 5.5 23.5C5.5 25.7091 7.29086 27.5 9.5 27.5H21.5703C22.9535 29.8912 25.5389 31.5 28.5 31.5C31.4611 31.5 34.0465 29.8912 35.4297 27.5H38.5C40.7091 27.5 42.5 25.7091 42.5 23.5C42.5 21.2909 40.7091 19.5 38.5 19.5H35.4297C34.0465 17.1088 31.4611 15.5 28.5 15.5ZM9.5 24.5H20.5619C20.521 24.1724 20.5 23.8387 20.5 23.5C20.5 23.1613 20.521 22.8276 20.5619 22.5H9.5C8.94772 22.5 8.5 22.9477 8.5 23.5C8.5 24.0523 8.94772 24.5 9.5 24.5ZM38.5 24.5H36.4381C36.479 24.1724 36.5 23.8387 36.5 23.5C36.5 23.1613 36.479 22.8276 36.4381 22.5H38.5C39.0523 22.5 39.5 22.9477 39.5 23.5C39.5 24.0523 39.0523 24.5 38.5 24.5ZM33.75 23.5C33.75 26.3995 31.3995 28.75 28.5 28.75C25.6005 28.75 23.25 26.3995 23.25 23.5C23.25 20.6005 25.6005 18.25 28.5 18.25C31.3995 18.25 33.75 20.6005 33.75 23.5Z'
        fill='#414859'
      />
    </svg>
  );
}
