export default function ExpandedViewportIcon({ width = 32, height = 32 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4 2.5C3.17157 2.5 2.5 3.17157 2.5 4V28C2.5 28.8284 3.17157 29.5 4 29.5H28C28.8284 29.5 29.5 28.8284 29.5 28V4C29.5 3.17157 28.8284 2.5 28 2.5H4ZM5.5 26.5V5.5H26.5V26.5H5.5ZM13.7811 8.52546C13.7815 9.07774 13.3341 9.52578 12.7818 9.52618L10.9404 9.52752L14.0351 12.6222C14.4256 13.0127 14.4256 13.6459 14.0351 14.0364C13.6446 14.4269 13.0114 14.4269 12.6209 14.0364L9.5227 10.9382L9.52135 12.7867C9.52095 13.3389 9.07291 13.7863 8.52063 13.7859C7.96834 13.7855 7.52095 13.3375 7.52136 12.7852L7.52445 8.52855C7.52485 7.97684 7.972 7.52968 8.52372 7.52928L12.7804 7.52618C13.3327 7.52578 13.7807 7.97317 13.7811 8.52546ZM19.476 22.7341C18.9237 22.7345 18.4763 23.1826 18.4767 23.7349C18.4771 24.2871 18.9252 24.7345 19.4774 24.7341L23.7341 24.731C24.2858 24.7306 24.733 24.2835 24.7334 23.7318L24.7365 19.4751C24.7369 18.9228 24.2895 18.4748 23.7372 18.4744C23.1849 18.474 22.7369 18.9214 22.7365 19.4737L22.7351 21.3221L19.6369 18.2239C19.2464 17.8334 18.6133 17.8334 18.2227 18.2239C17.8322 18.6144 17.8322 19.2476 18.2227 19.6381L21.3174 22.7328L19.476 22.7341ZM22.7344 12.7878C22.7348 13.3401 23.1828 13.7875 23.7351 13.7871C24.2874 13.7867 24.7348 13.3386 24.7344 12.7864L24.7313 8.5297C24.7309 7.97799 24.2837 7.53083 23.732 7.53043L19.4754 7.52734C18.9231 7.52694 18.475 7.97432 18.4746 8.52661C18.4742 9.07889 18.9216 9.52693 19.4739 9.52734L21.32 9.52868L18.2226 12.6261C17.8321 13.0166 17.8321 13.6498 18.2226 14.0403C18.6131 14.4309 19.2463 14.4309 19.6368 14.0403L22.733 10.9441L22.7344 12.7878ZM9.53125 19.4735C9.53085 18.9213 9.08281 18.4739 8.53052 18.4743C7.97824 18.4747 7.53085 18.9227 7.53125 19.475L7.53435 23.7316C7.53475 24.2834 7.9819 24.7305 8.53362 24.7309L12.7903 24.734C13.3426 24.7344 13.7906 24.287 13.791 23.7347C13.7914 23.1825 13.344 22.7344 12.7917 22.734L10.9456 22.7327L14.043 19.6352C14.4336 19.2447 14.4336 18.6115 14.043 18.221C13.6525 17.8305 13.0194 17.8305 12.6288 18.221L9.53259 21.3173L9.53125 19.4735Z'
        fill='white'
      />
    </svg>
  );
}
