import type { ComponentProps } from 'react';
import { BackArrowIcon } from '../../Icons';
import { Button } from './button';

function Back({ ...rest }: Omit<ComponentProps<typeof Button>, 'children'>) {
  return (
    <Button
      {...rest}
      variant='outline'
      className='hover:tw-bg-primary hover:tw-text-white hover:tw-border-primary'
    >
      <BackArrowIcon className='tw-stroke-current' />
    </Button>
  );
}

export default Back;
