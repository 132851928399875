const DeleteIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.506 19.9999H9.1608C8.17262 19.9999 7.39248 19.2781 7.34047 18.2985L6.4043 6.85278H18.2625L17.3783 18.2985C17.2743 19.2781 16.4941 19.9999 15.506 19.9999Z'
        stroke='#6C7589'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5 6.85278H19.6667'
        stroke='#6C7589'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.2695 4H14.2223'
        stroke='#6C7589'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.877 10.2556V16.1847'
        stroke='#6C7589'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.7891 10.2556V16.1847'
        stroke='#6C7589'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default DeleteIcon;
