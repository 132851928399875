import { useAppSelector } from '../hooks';

function OAuthRedirectPage() {
  const org = useAppSelector((state) => state.accounts.organization);

  const urlSearchParams = new URLSearchParams(window.location.search);
  let code, isFeathery;
  urlSearchParams.forEach((val, key) => {
    if (key === 'code') code = val;
    // To prevent cross-site attacks
    else if (key === 'state' && val === org?.id.toString()) isFeathery = true;
  });
  if (code && isFeathery) window.opener.postMessage(`feathery-oauth:${code}`);
  return null;
}

export default OAuthRedirectPage;
