function Heap({ width = 144, height = 72 }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 800 600'
      width={width}
      height={height}
      enableBackground='new 0 0 800 600'
      xmlSpace='preserve'
    >
      <path d='M105.7,227.1H76v145h29.7V227.1z M169.9,171.9h-29.6v110.5h29.6V171.9L169.9,171.9z' />
      <path
        style={{ fill: '#31D891' }}
        d='M169.9,316.8h-29.6v110.5h29.6V316.8z M234.1,227.1h-29.6v145h29.6V227.1z'
      />
      <path d='M515.5,344c-1.4,4.3-3.4,8.4-6.1,12.1c-2.7,3.8-5.9,7.1-9.6,9.8c-3.9,2.9-8.2,5.1-12.8,6.6c-5.1,1.7-10.4,2.5-15.8,2.5  c-6.4,0-12.8-1.2-18.8-3.5c-5.9-2.3-11.4-5.7-15.9-10.2c-4.7-4.7-8.4-10.3-10.8-16.5c-2.8-7.1-4.2-14.7-4-22.3  c-0.1-7.2,1.2-14.4,3.9-21.2c2.4-6,5.9-11.6,10.3-16.4c4.3-4.5,9.4-8.2,15.1-10.6c5.6-2.5,11.7-3.8,17.8-3.8  c6.9-0.1,13.8,1.2,20.2,3.8c5.7,2.3,10.8,5.9,14.9,10.5c4.1,4.8,7.2,10.3,9.1,16.3c2.2,6.8,3.3,13.8,3.2,21c0,1.2,0,2.4-0.1,3.5  c0,0.9-0.1,1.8-0.3,2.7h-74.2c0.1,4.2,0.9,8.3,2.5,12.1c1.4,3.6,3.5,6.8,6.2,9.5c2.7,2.7,5.9,4.8,9.4,6.1c3.7,1.4,7.6,2.2,11.6,2.1  c8.1,0,14.2-1.9,18.4-5.7c4.2-3.8,7.4-8.7,9.2-14.1L515.5,344L515.5,344z M496.1,312.7c-0.1-6.6-2.7-13-7.2-17.9  c-4.5-4.8-11.1-7.2-19.9-7.3c-3.8-0.1-7.5,0.7-11,2.1c-3.1,1.3-5.9,3.3-8.2,5.7c-2.2,2.4-4.1,5.1-5.4,8.1c-1.3,2.9-2.1,6-2.3,9.2  H496.1L496.1,312.7z M387.1,372.1v-64.4h-66.5v64.4h-20.1v-145h20.1v62h66.5v-62h20.1v145C407.2,372.1,387.1,372.1,387.1,372.1z' />
      <path d='M515.4,344c-5.5,17.4-21.3,31.1-44.2,31.1c-26.4,0-49.5-19.2-49.5-52.6c0-30.9,22.3-52,47-52c30.3,0,47.2,20.9,47.2,51.5  c0,2.1-0.1,4.1-0.4,6.1h-74.1c0.4,17.6,13.1,29.9,29.7,29.9c16,0,23.9-8.8,27.6-19.8L515.4,344L515.4,344z M495.9,312.7  c-0.4-14.1-9.6-25.2-27-25.2c-16.2,0-26,12.5-26.8,25.2H495.9z M555.3,315.8l26.6-3.9c5.9-0.8,7.6-3.9,7.6-7.4  c0-9.6-6.3-17.6-21.3-17.6c-13.5,0-21.1,8.4-22.3,19.8l-18.4-4.3c2-18.8,19-31.9,40.3-31.9c29.4,0,40.9,16.8,40.9,36v49.3  c-0.1,5.4,0.3,10.8,1.2,16.2h-18.8c-0.8-4.4-1.1-8.8-1-13.3c-4.3,7-14.1,16.4-31.7,16.4c-20,0-32.9-13.9-32.9-29.4  C525.5,328.1,538.4,318.3,555.3,315.8L555.3,315.8z M589.5,329.9v-4.5l-30.1,4.5c-8.2,1.2-14.3,5.9-14.3,14.7  c0,7.4,6.1,14.3,16,14.3C576.8,359,589.5,351.4,589.5,329.9z M626.9,410.9V273.7h18.6V289c5.3-9.8,17-17.6,32.9-17.6  c29.4,0,45.2,22.7,45.2,51.3c0,29.2-17,51.9-45.8,51.9c-15.1,0-26.6-7.2-31.9-16v52.2H626.9L626.9,410.9z M674.9,288.4  c-17.6,0-29.2,14.7-29.2,34.3c0,20.2,11.7,34.8,29.2,34.8c17.8,0,29-14.5,29-34.8C704,303.1,692.9,288.4,674.9,288.4z' />
    </svg>
  );
}

export default Heap;
