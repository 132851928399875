import { useDrop } from 'react-dnd';

type Props = {
  pageRects: any;
  annotatedFields: any;
  addOrUpdateField: any;
  setActiveAnnotatedField: any;
};

export const DOCUMENT_FIELD_DEFAULT_SIZE = {
  width: 150,
  height: 30
};

const useFieldDrop = ({
  pageRects,
  annotatedFields,
  addOrUpdateField = () => {},
  setActiveAnnotatedField = () => {}
}: Props) => {
  const [, dropRef] = useDrop(
    () => ({
      accept: 'document-field',
      drop: (item: any, monitor: any) => {
        const pointerCoords = monitor.getClientOffset();
        const documentCanvas = document.getElementById('document-canvas');
        const canvasCoords = documentCanvas?.getBoundingClientRect();

        if (!canvasCoords) return false;

        const documentScrollPos = documentCanvas?.scrollTop ?? 0;
        const pointerPosition = {
          x: pointerCoords.x,
          y: pointerCoords.y + documentScrollPos
        };

        const page = pageRects.findIndex((_rect: any) => {
          const rect = {
            ..._rect,
            x: _rect.x + canvasCoords.x,
            y: _rect.y + canvasCoords.y
          };

          if (
            pointerPosition.x >= rect.x &&
            pointerPosition.x <= rect.x + rect.width &&
            pointerPosition.y >= rect.y &&
            pointerPosition.y <= rect.y + rect.height
          )
            return true;

          return false;
        });

        if (page === -1) return false;

        // places the field based on the current location and center of the field
        const width =
          item.opts?.preview?.width ?? DOCUMENT_FIELD_DEFAULT_SIZE.width;
        const height =
          item.opts?.preview?.height ?? DOCUMENT_FIELD_DEFAULT_SIZE.height;

        const clientOffset = monitor.getClientOffset();
        const sourceClientOffset = monitor.getSourceClientOffset();
        const mouseOffset =
          item.operation === 'new'
            ? { x: width / 2, y: height / 2 }
            : {
                x: clientOffset.x - sourceClientOffset.x,
                y: clientOffset.y - sourceClientOffset.y
              };

        const activeLocation = {
          page,
          x: Math.round(
            pointerPosition.x -
              canvasCoords.x -
              pageRects[page].x -
              mouseOffset.x
          ),
          y: Math.round(
            pointerPosition.y -
              canvasCoords.y -
              pageRects[page].y -
              mouseOffset.y
          ),
          width,
          height
        };

        // If item has an id, it's an existing field and this is an update
        const newField = addOrUpdateField(item.type, activeLocation, item.id);
        setActiveAnnotatedField(newField);
      },
      canDrop: (item, monitor: any) => {
        const pointerCoords = monitor.getClientOffset();
        const documentCanvas = document.getElementById('document-canvas');
        const canvasCoords = documentCanvas?.getBoundingClientRect();

        if (!canvasCoords) return false;

        const documentScrollPos = documentCanvas?.scrollTop ?? 0;
        const pointerPosition = {
          x: pointerCoords.x,
          y: pointerCoords.y + documentScrollPos
        };

        return pageRects.some((_rect: any) => {
          const rect = {
            ..._rect,
            x: _rect.x + canvasCoords.x,
            y: _rect.y + canvasCoords.y
          };

          return (
            pointerPosition.x >= rect.x &&
            pointerPosition.x <= rect.x + rect.width &&
            pointerPosition.y >= rect.y &&
            pointerPosition.y <= rect.y + rect.height
          );
        });
      }
    }),
    [pageRects, annotatedFields]
  );

  return dropRef;
};

export default useFieldDrop;
