export default function VerticalTopIcon({ width = 16, height = 16 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3 4C2.44772 4 2 3.55228 2 3C2 2.44772 2.44772 2 3 2L3 4ZM13 2C13.5523 2 14 2.44772 14 3C14 3.55229 13.5523 4 13 4L13 2ZM3 2L13 2L13 4L3 4L3 2Z'
        fill='#414859'
      />
      <rect
        width='7.23404'
        height='4.16667'
        rx='1'
        transform='matrix(-4.37114e-08 1 1 4.37114e-08 5.91669 5.76596)'
        stroke='#414859'
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  );
}
