function Bonzo({ width = 64, height = 64 }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 70.1172 57.916'
    >
      <path
        d='M28.958,57.916A28.958,28.958,0,1,1,57.916,28.958,28.9908,28.9908,0,0,1,28.958,57.916ZM28.958,4A24.958,24.958,0,1,0,53.916,28.958,24.9865,24.9865,0,0,0,28.958,4Z'
        style={{ fill: '#231f20' }}
      />
      <path
        d='M24.53,25.0459h2.9844a16.7864,16.7864,0,0,0,1.7558-.0869,3.891,3.891,0,0,0,1.4483-.418,2.5416,2.5416,0,0,0,.9873-.9434,3.2054,3.2054,0,0,0,.373-1.6679,2.9669,2.9669,0,0,0-.3945-1.6231,2.6643,2.6643,0,0,0-1.0322-.9443,4.04,4.04,0,0,0-1.4043-.417,13.6849,13.6849,0,0,0-1.5576-.0879H24.53Z'
        style={{ fill: 'none' }}
      />
      <path
        d='M42.2256,32.5518a8.3715,8.3715,0,0,0-1.7812-2.4581,7.5289,7.5289,0,0,0-2.1934-1.4482q-1.1527-.4819-1.9336-.7461a12.3459,12.3459,0,0,0,1.6377-.79,7.7448,7.7448,0,0,0,1.77-1.3828,7.6376,7.6376,0,0,0,1.4209-2.129,7.3893,7.3893,0,0,0,.59-3.1162,8.09,8.09,0,0,0-.68-3.4892,7.1329,7.1329,0,0,0-1.7754-2.37A8.6035,8.6035,0,0,0,36.7832,13.13a14.162,14.162,0,0,0-2.8916-.79,25.6156,25.6156,0,0,0-2.915-.3066c-.3115-.0147-.5938-.0234-.8828-.0332-5.9678.043-5.5586,6.8574-5.5586,6.8574h3.1553a13.6849,13.6849,0,0,1,1.5576.0879,4.04,4.04,0,0,1,1.4043.417,2.6643,2.6643,0,0,1,1.0322.9443,2.9669,2.9669,0,0,1,.3945,1.6231,3.2054,3.2054,0,0,1-.373,1.6679,2.5416,2.5416,0,0,1-.9873.9434,3.891,3.891,0,0,1-1.4483.418,16.7864,16.7864,0,0,1-1.7558.0869h-2.98v6.1006h3.3574a14.313,14.313,0,0,1,2.2051.1553,4.8253,4.8253,0,0,1,1.7031.5742,2.8911,2.8911,0,0,1,1.1143,1.1465,3.943,3.943,0,0,1,.3935,1.8759,3.8689,3.8689,0,0,1-.48,2.0547,3.1982,3.1982,0,0,1-1.2452,1.17,5.2645,5.2645,0,0,1-1.6806.5293,12.8831,12.8831,0,0,1-1.835.1309H24.5352l.0224,7.1953H28.417c.9033,0,1.8877-.0469,2.9522-.1055a24.1955,24.1955,0,0,0,3.1943-.4179,16.3929,16.3929,0,0,0,3.1289-.9219,8.9339,8.9339,0,0,0,2.69-1.69,7.7524,7.7524,0,0,0,1.8818-2.6757,9.679,9.679,0,0,0,.7-3.8848A8.382,8.382,0,0,0,42.2256,32.5518Z'
        style={{ fill: '#231f20' }}
      />
      <path
        d='M24.53,38.7832H14.9619v2.0449c-.0029.07-.01.1387-.01.209a4.8448,4.8448,0,0,0,.3867,1.93,4.73,4.73,0,0,0,1.0752,1.5644,5.2382,5.2382,0,0,0,1.5713,1.0508,4.7189,4.7189,0,0,0,1.915.3965c.127,0,.3516.002.4873.002a1.0624,1.0624,0,0,0,.1133-.002h4.0566l-.0224-7.1953Z'
        style={{ fill: '#ee3c96' }}
      />
      <path
        d='M24.5352,31.1465V25.0459H24.53V18.8574h.0049S24.126,12.043,30.0938,12l.0215-.001c-.087-.0029-.1661-.0049-.251-.0068-.1348-.0039-.2676-.0078-.3975-.0108-.3535-.0088-.6953-.0146-1.0078-.0146l-8.7519.0068h.0244a4.495,4.495,0,0,0-1.7461.4092,5.6815,5.6815,0,0,0-1.5713,1.0723,4.817,4.817,0,0,0-1.4619,3.4531c0,.1436.0088.2832.02.4228h-.01V31.1465h9.5733Z'
        style={{ fill: '#ee3c96' }}
      />
    </svg>
  );
}

export default Bonzo;
