export default function OpenOverflowIcon({ color = '#414859', ...props }: any) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill={color}
      stroke={color}
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <circle cx='12' cy='17' r='1' transform='rotate(-90 12 17)' />
      <circle cx='12' cy='12' r='1' transform='rotate(-90 12 12)' />
      <circle cx='12' cy='7' r='1' transform='rotate(-90 12 7)' />
    </svg>
  );
}
