import { v4 as uuidv4 } from 'uuid';
import { getCognitoJwt } from '../utils/useGetJwt';

export const clientIdentifier = uuidv4();

const BASE_URL_OPTIONS = {
  local: 'http://localhost:8006/',
  staging: 'https://staging.feathery.io/',
  production: 'https://api-dashboard.feathery.io/',
  productionAU: 'https://api-dashboard-au.feathery.io/',
  productionEU: 'https://api-dashboard-eu.feathery.io/',
  productionCA: 'https://api-dashboard-ca.feathery.io/'
};

const API_URL_OPTIONS = {
  local: 'http://localhost:8006/api/',
  staging: 'https://staging.feathery.io/api/',
  production: 'https://api.feathery.io/api/',
  productionAU: 'https://api-au.feathery.io/api/',
  productionEU: 'https://api-eu.feathery.io/api/',
  productionCA: 'https://api-ca.feathery.io/api/'
};

const API_BASE_OPTIONS = {
  local: 'localhost:8006',
  staging: 'staging',
  production: 'api',
  productionAU: 'api-au',
  productionEU: 'api-eu',
  productionCA: 'api-ca'
};

const REGION_OPTIONS = {
  local: '',
  staging: '',
  production: '',
  productionAU: 'au',
  productionEU: 'eu',
  productionCA: 'ca'
};

const S3_URL_OPTIONS = {
  local: 'http://localhost:8006',
  staging: 'user-files-dev.s3.us-west-1.amazonaws.com',
  production: 'user-files-1.s3.us-west-1.amazonaws.com',
  productionAU: 'user-files-au.s3.ap-southeast-2.amazonaws.com',
  productionEU: 'user-files-eu.s3.eu-west-1.amazonaws.com',
  productionCA: 'user-files-ca.s3.ca-central-1.amazonaws.com'
};

const frontendEnv = process.env.REACT_APP_FRONTEND_ENV || 'local';
export let BACKEND_ENV = (process.env.REACT_APP_BACKEND_ENV ||
  'production') as keyof typeof BASE_URL_OPTIONS;
if (window.location.host === 'au.app.feathery.io') BACKEND_ENV = 'productionAU';
else if (window.location.host === 'eu.app.feathery.io')
  BACKEND_ENV = 'productionEU';
else if (window.location.host === 'ca.app.feathery.io')
  BACKEND_ENV = 'productionCA';
export const IS_PROD_BE = BACKEND_ENV.startsWith('production');
export const IS_PROD_FE = frontendEnv.startsWith('production');

export const BASE_URL = BASE_URL_OPTIONS[BACKEND_ENV];
export const API_URL = API_URL_OPTIONS[BACKEND_ENV];
export const API_BASE = API_BASE_OPTIONS[BACKEND_ENV];
export const REGION = REGION_OPTIONS[BACKEND_ENV];
export const S3_URL = S3_URL_OPTIONS[BACKEND_ENV];
const BASE_URL_STATIC_IP = IS_PROD_BE
  ? 'https://api-static-2.feathery.io/'
  : BASE_URL;

export const METHOD = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE'
};

export const CONTENT_TYPE = {
  JSON: 'application/json',
  MULTIPART_FORM: 'multipart/form-data'
};

export const URL = {
  AB_TESTS: `${BASE_URL}metrics/abtest/`,
  ACCOUNT: `${BASE_URL}account/`,
  AI: `${BASE_URL}ai/`,
  DOCUMENTS: `${BASE_URL}document/`,
  INTEGRATIONS: `${BASE_URL}integration/`,
  INTEGRATIONS_STATIC_IP: `${BASE_URL_STATIC_IP}integration/`,
  METRICS: `${BASE_URL}metrics/`,
  PANELS: `${BASE_URL}panel/`,
  IMAGE_UPLOAD: `${BASE_URL}panel/image_upload/`,
  PANEL_STEPS: `${BASE_URL}panel/step/`,
  PANEL_RULES: `${BASE_URL}panel/logic_rule/`,
  SERVARS: `${BASE_URL}servar/`,
  THEMES: `${BASE_URL}theme/`,
  USERS: `${BASE_URL}fuser/`
};

export function encodeGetParams(params: Record<string, string>, sort = false) {
  let entries = Object.entries(params).filter((kv) => kv[1]);
  if (sort) entries = entries.sort();
  return entries.map((kv) => kv.map(encodeURIComponent).join('=')).join('&');
}

/**
 * Helper function that invalidates the internal request cache
 * @param  {...string} paths List of paths to invalidate
 */
function invalidate(...paths: string[]) {
  paths.forEach((path) => {
    const regex = new RegExp(path);
    Object.keys(FeatheryRequestCache)
      .filter((key) => regex.test(key))
      .forEach((key) => delete FeatheryRequestCache[key]);
  });
}

const TYPE_MESSAGES_TO_IGNORE = [
  'NetworkError when attempting to fetch resource.',
  'Failed to fetch'
];

/**
 * Performs a GET request to the provided URL
 * @param {Object} args
 * @param {string} args.token The bearer token used to authenticate the request
 * @param {string} args.url The URL to request to
 */
export function httpGet({
  token,
  url,
  params = {},
  cachable = true,
  forceLoad = false,
  invalid = []
}: {
  token: string;
  url: string;
  params?: Record<string, any>;
  cachable?: boolean;
  forceLoad?: boolean;
  invalid?: string[];
}) {
  const query = encodeGetParams(params, true);
  if (query) url += `?${query}`;

  let cacheKey = '';
  if (cachable && !forceLoad) {
    invalidate(...invalid);

    // Check the cache for the existing request
    // Currently, we only cache GET requests
    cacheKey = `${METHOD.GET}_${url}`;
    if (FeatheryRequestCache[cacheKey]) {
      return FeatheryRequestCache[cacheKey]?.then((r: any) => r && r.clone());
    }
  }

  const response = fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': CONTENT_TYPE.JSON,
      'X-Client-Identifier': clientIdentifier,
      'X-SSO-User': String(Boolean(getCognitoJwt()))
    },
    method: METHOD.GET
  }).catch((e) => {
    // Ignore ephemeral TypeErrors that don't impact functionality
    // E.g. Failed to fetch - https://sentry.io/organizations/feathery-forms/issues/3549611624
    // NetworkError - https://sentry.io/organizations/feathery-forms/issues/3427106759/
    if (e instanceof TypeError && TYPE_MESSAGES_TO_IGNORE.includes(e.message))
      return;
    throw e;
  });

  cacheResponse(response, url, cachable);
  return response;
}

/**
 * Performs a POST request to the provided URL
 * @param {Object} args
 * @param {string} args.token The bearer token used to authenticate the request
 * @param {string} args.url The URL to request to
 * @param {Object} [args.body] The request payload
 * @param {string[]} [args.invalid] The list of paths to invalidate within the internal cache
 */
export function httpPost({
  token,
  url,
  body = {},
  contentType = CONTENT_TYPE.JSON,
  cachableResponse = false,
  invalid = []
}: {
  token: string;
  url: string;
  body?: Record<string, any> | any[];
  contentType?: string;
  cachableResponse?: boolean;
  invalid?: string[];
}) {
  // Note: $ is appended to url so we only invalidate list requests, not all detail requests
  invalidate(`${url}$`, ...invalid);
  const postHeaders: Record<string, string> = {
    Authorization: `Bearer ${token}`,
    'X-Client-Identifier': clientIdentifier,
    'X-SSO-User': String(Boolean(getCognitoJwt()))
  };
  let postBody: any;
  if (contentType === CONTENT_TYPE.JSON) {
    postBody = JSON.stringify(body);
    postHeaders['Content-Type'] = contentType;
  } else if (contentType === CONTENT_TYPE.MULTIPART_FORM) {
    postBody = new FormData();
    Object.entries(body).forEach(([k, v]) => {
      if (Array.isArray(v)) {
        v.forEach((single) => postBody.append(k, single));
      } else postBody.append(k, v);
    });
  }
  const response = fetch(url, {
    headers: postHeaders,
    method: METHOD.POST,
    body: postBody
  });
  cacheResponse(response, url, cachableResponse);
  return response;
}

/**
 * Performs a PUT request to the provided URL
 * @param {Object} args
 * @param {string} args.token The bearer token used to authenticate the request
 * @param {string} args.url The URL to request to
 * @param {Object} [args.body] The request payload
 * @param {string[]} [args.invalid] The list of paths to invalidate within the internal cache
 */
export function httpPut({
  token,
  url,
  body,
  cachableResponse = false,
  invalid = []
}: {
  token: string;
  url: string;
  body: Record<string, any>;
  cachableResponse?: boolean;
  invalid?: string[];
}) {
  // Note: $ is appended to url so we only invalidate list requests, not all detail requests
  invalidate(`${url}$`, ...invalid);
  const response = fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': CONTENT_TYPE.JSON,
      'X-Client-Identifier': clientIdentifier,
      'X-SSO-User': String(Boolean(getCognitoJwt()))
    },
    method: METHOD.PUT,
    ...(body ? { body: JSON.stringify(body) } : {})
  });
  cacheResponse(response, url, cachableResponse);
  return response;
}

/**
 * Performs a PATCH request to the provided URL
 * @param {Object} args
 * @param {string} args.token The bearer token used to authenticate the request
 * @param {string} args.baseUrl The URL to request to
 * @param {string} [args.path] Path to the resource
 * @param {Object} [args.body] The request payload
 * @param {string[]} [args.invalid] The list of paths to invalidate within the internal cache
 */
export function httpPatch({
  token,
  baseUrl,
  body = {},
  contentType = CONTENT_TYPE.JSON,
  path = '',
  cachableResponse = false,
  invalid = []
}: {
  token: string;
  baseUrl: string;
  body?: Record<string, any>;
  contentType?: string;
  path?: string;
  cachableResponse?: boolean;
  invalid?: string[];
}) {
  const url = `${baseUrl}${path}`;

  // Invalidate list and detail requests
  invalidate(url, `${baseUrl}$`, ...invalid);

  const patchHeaders: Record<string, string> = {
    Authorization: `Bearer ${token}`,
    'X-Client-Identifier': clientIdentifier,
    'X-SSO-User': String(Boolean(getCognitoJwt()))
  };
  let patchBody: any;
  if (contentType === CONTENT_TYPE.JSON) {
    patchBody = JSON.stringify(body);
    patchHeaders['Content-Type'] = contentType;
  } else if (contentType === CONTENT_TYPE.MULTIPART_FORM) {
    patchBody = new FormData();
    Object.entries(body).forEach(([k, v]) => {
      if (Array.isArray(v)) {
        v.forEach((single) => patchBody.append(k, single));
      } else patchBody.append(k, v);
    });
  }

  const response = fetch(url, {
    headers: patchHeaders,
    method: METHOD.PATCH,
    body: patchBody
  });

  cacheResponse(response, url, cachableResponse);
  return response;
}

/**
 * Performs a DELETE request to the provided URL
 * @param {Object} args
 * @param {string} args.token The bearer token used to authenticate the request
 * @param {string} args.baseUrl The URL to request to
 * @param {string} [args.path] Path to the resource
 * @param {string[]} [args.invalid] The list of paths to invalidate within the internal cache
 */
export function httpDelete({
  token,
  baseUrl,
  body = {},
  path = '',
  invalid = []
}: {
  token: string;
  baseUrl: string;
  body?: Record<string, any>;
  path?: string;
  invalid?: string[];
}) {
  const url = `${baseUrl}${path}`;

  // Invalidate list and detail requests
  invalidate(url, `${baseUrl}$`, ...invalid);
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': CONTENT_TYPE.JSON,
      'X-Client-Identifier': clientIdentifier,
      'X-SSO-User': String(Boolean(getCognitoJwt()))
    },
    method: METHOD.DELETE,
    body: JSON.stringify(body)
  });
}

function cacheResponse(
  response: Promise<void | Response>,
  url: string,
  cacheIt: boolean
) {
  if (cacheIt) {
    const cacheKey = `${METHOD.GET}_${url}`;
    FeatheryRequestCache[cacheKey] = response.then((r) => r && r.clone());
  }
}
