import '../../style/dialog-form.css';

import { Form } from 'react-bootstrap';
import { useState } from 'react';

import IntegrationsSidebar from './IntegrationsSidebar';
import { DropdownField, PropertyLabel, TextField } from '../Core';
import useIntegrations from './useIntegrations';
import { useParams } from 'react-router-dom';
import { ALL_INTEGRATIONS_MAP, INTEGRATIONS } from './types';
import { useAppSelector } from '../../hooks';

function GoogleTagManagerSettingsSidebar() {
  const { formId } = useParams<{ formId: string }>();

  // Global/cached integration settings
  const integration = useIntegrations({
    type: INTEGRATIONS.GOOGLE_TAG_MANAGER,
    panelId: formId,
    includeInactive: true
  });
  const org = useAppSelector((state) => state.accounts.organization);

  // Local/draft integration settings
  const [apiKey, setApiKey] = useState(integration?.data.api_key ?? '');
  const [trackFields, setTrackFields] = useState(
    integration?.data.metadata.track_fields ?? false
  );
  const [isPartial, setIsPartial] = useState(false);

  function onSubmitCustom(newIsActive: boolean) {
    if (newIsActive) {
      const partial = !apiKey;
      setIsPartial(partial);
      if (partial) return;
    }

    const metadata = {
      api_key: apiKey,
      track_fields: trackFields
    };
    return {
      isUpdate: integration?.data,
      metadata,
      apiKey
    };
  }

  return (
    <IntegrationsSidebar
      integrationInfo={ALL_INTEGRATIONS_MAP[INTEGRATIONS.GOOGLE_TAG_MANAGER]}
      isPartial={isPartial}
      onSubmitCustom={onSubmitCustom}
    >
      <Form>
        <Form.Group controlId='api-key'>
          <PropertyLabel label='Container ID*' />
          <TextField
            className='dialog-form-input'
            placeholder='GTM-XXXXXXX'
            value={apiKey || ''}
            onChange={setApiKey}
            error={isPartial && (!apiKey || apiKey === '')}
          />
        </Form.Group>
        {!org?.enterprise_features.hipaa && (
          <Form.Group>
            <PropertyLabel label='Include user form data in events' />
            <DropdownField
              selected={trackFields.toString()}
              onChange={(event: any) =>
                setTrackFields(event.target.value === 'true')
              }
              options={[
                { value: 'false', display: 'No' },
                { value: 'true', display: 'Yes' }
              ]}
            />
          </Form.Group>
        )}
      </Form>
    </IntegrationsSidebar>
  );
}

export default GoogleTagManagerSettingsSidebar;
