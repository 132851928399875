import { memo, useRef, useState } from 'react';

import { DropdownMenu, DynamicTextTooltip } from '../../Core';
import classNames from 'classnames';
import ruleStyles from '../../NavigationRules/styles.module.scss';
import { useAppSelector } from '../../../hooks';
import { useNextStepDropdownOptions } from '../../../hooks/useNextStepDropdownOptions';

const StepSelector = ({
  filterStepId = '',
  curStepId = '',
  onSelect = () => {},
  error,
  className,
  style
}: any) => {
  const workingSteps = useAppSelector(
    (state) => state.formBuilder.workingSteps
  );
  const dropdown = useRef<any>();
  const [show, setShow] = useState(false);
  const [selectedStepKey, setSelectedStepKey] = useState(
    workingSteps[curStepId]?.key ?? ''
  );
  let nextStepOptions = useNextStepDropdownOptions(filterStepId);
  if (nextStepOptions.length === 0)
    nextStepOptions = [{ display: 'No steps', value: '' }];

  return (
    <>
      <DynamicTextTooltip text={selectedStepKey} maxLength={12}>
        <div
          className={classNames(
            ruleStyles.ruleTarget,
            ruleStyles.fieldTarget,
            ruleStyles.long,
            selectedStepKey && ruleStyles.selected,
            error && ruleStyles.fieldTargetError,
            className
          )}
          style={style}
          onClick={() => setShow(!show)}
          ref={dropdown}
        >
          <span className={ruleStyles.fieldSpan}>
            {selectedStepKey ? `Step :: ${selectedStepKey}` : 'Step'}
          </span>
        </div>
      </DynamicTextTooltip>
      <DropdownMenu
        targetRef={dropdown}
        show={show}
        setShow={setShow}
        options={nextStepOptions}
        onSelect={(optionId) => {
          if (optionId === '') return;
          setSelectedStepKey(workingSteps[optionId].key);
          onSelect(optionId);
        }}
      />
    </>
  );
};

export default memo(StepSelector);
