import { memo, useState } from 'react';
import styles from './styles.module.scss';
import { ImageUploadModal } from '../../Modals';
import modalStyles from '../../Modals/ImageUploadModal/styles.module.scss';
import ImageThumbnail from './ImageThumbnail';
import { ImageUploadIcon, TrashIcon } from '../../Icons';
import useFeatheryRedux from '../../../redux';
import { useAppSelector } from '../../../hooks';

function SoloImageField({
  imageId = '',
  setImage = () => {},
  fileTypes = ['image/*'],
  onLoad = () => {}
}) {
  const {
    formBuilder: { setShowImageModal }
  } = useFeatheryRedux();
  const showModalRedux = useAppSelector(
    (state) => state.formBuilder.showImageModal
  );
  const currentImage = useAppSelector((state) => state.images)[imageId];
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <ImageUploadModal
        selectedImageId={imageId}
        show={showModal || showModalRedux}
        close={() => {
          setShowModal(false);
          setShowImageModal(false);
        }}
        onSelect={setImage}
        fileTypes={fileTypes}
      />
      <div className={styles.imageFieldContainer}>
        <div
          className={styles.imageFieldCard}
          style={{ backgroundColor: currentImage ? 'var(--grey-10)' : 'white' }}
          onClick={() => setShowModal(true)}
        >
          {currentImage ? (
            <>
              <ImageThumbnail
                fileUrl={currentImage.file}
                fileType={currentImage.type}
                height={140}
                onLoad={onLoad}
              />
              <div className={modalStyles.thumbnailLabel}>
                <span className={modalStyles.thumbnailLabelText}>
                  {currentImage.name}
                </span>
                <div
                  className={modalStyles.cardDeleteContainer}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    // @ts-expect-error TS(2554) FIXME: Expected 0 arguments, but got 1.
                    setImage(null);
                  }}
                >
                  <TrashIcon
                    width={20}
                    height={20}
                    className={modalStyles.cardDelete}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className={styles.imageFieldGrid}>
              <ImageUploadIcon className={styles.imageFieldCardIcon} />
              <span className={styles.imageFieldCardLabel}>Select Image</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default memo(SoloImageField);
