import 'bootstrap/dist/css/bootstrap.min.css';

import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { LogoLoader } from '../../components/Core';
import StepEditor from '../../components/StepEditor';
import { useAppSelector } from '../../hooks';
import { useFormBuilderChangeStep } from '../../hooks/useFormBuilderChangeStep';
import { GigProvider } from '../../context/Gig';

export default function FormBuilderPage() {
  const { stepId } = useParams<{ stepId: string }>();

  const workingSteps = useAppSelector((s) => s.formBuilder.workingSteps);
  const activeStepId = useAppSelector(
    (state) => state.formBuilder.activeStepId
  );

  const changeStep = useFormBuilderChangeStep();

  useEffect(() => {
    changeStep(stepId, true);
  }, [stepId, changeStep]);

  return (
    <>
      <div id={'debug-layer'} />
      {workingSteps[activeStepId] ? (
        <GigProvider>
          <StepEditor />
        </GigProvider>
      ) : (
        <LogoLoader />
      )}
    </>
  );
}
