import { InlineTooltip, TextField } from '../../../components/Core';
import SettingsContainer from '../../../components/SettingsContainer';
import { Form } from 'react-bootstrap';
import { useAppSelector } from '../../../hooks';
import { useParams } from 'react-router-dom';
import DocumentReplaceButton from '../DocumentReplaceButton';
import Combobox from '../../../components/Core/Combobox';
import CopyableText from '../../../components/Core/CopyableText';

const DocumentPropertiesSection = ({ title, formData, updateData }: any) => {
  const { documentId } = useParams<{ documentId: string }>();
  const doc = useAppSelector((state) => state.documents.documents[documentId]);

  return (
    <SettingsContainer title={title}>
      <div className='field-group'>
        <Form.Label>Name</Form.Label>
        <TextField
          placeholder='My Document'
          value={formData.key}
          onComplete={(val: string) => updateData('key', val)}
        />
      </div>
      <div className='field-group'>
        <Form.Label>ID</Form.Label>
        <CopyableText
          text={documentId}
          toastMessage='Document ID copied to clipboard.'
        />
      </div>
      <div className='field-group'>
        <Form.Label>
          Tags
          <InlineTooltip text='Optional, custom document identifiers' inline />
        </Form.Label>
        <Combobox
          value={formData.tag_metadata ?? []}
          onChange={(items: string[]) => {
            updateData('tag_metadata', items);
          }}
        />
      </div>
      <div className='field-group'>
        <Form.Label>Replace Document File</Form.Label>
        <DocumentReplaceButton />
      </div>
      <div className='field-group'>
        <Form.Label>Download Document</Form.Label>
        <a href={doc.file} target='_blank' rel='noreferrer'>
          {formData.key}
        </a>
      </div>
    </SettingsContainer>
  );
};

export default DocumentPropertiesSection;
