import { LinkText } from '../../utils/domOperations';
import styles from './styles.module.scss';
import { getFileUrl } from '../../utils/browserUtils';
import { Tooltip } from '../../components/Core/Tooltip/Tooltip';

export function renderFile(value: any, urls: any) {
  return value.map((fileName: any, index: any) => {
    const isImage = Boolean(
      fileName.match(/.(jpg|jpeg|png|gif|svg|tiff|tif|bmp|ico)$/i)
    );
    return !isImage ? (
      <LinkText
        key={urls[index]}
        text={fileName}
        link={getFileUrl(urls[index])}
      />
    ) : (
      <Tooltip
        key={urls[index]}
        content={
          <div className={styles.assetPreviewTooltip}>
            <img src={urls[index]} alt='Preview of uploaded file.' />
          </div>
        }
      >
        <LinkText text={fileName} link={getFileUrl(urls[index])} />
      </Tooltip>
    );
  });
}
