export default function UpgradeIcon({
  width = 28,
  height = 28,
  fill = 'none'
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 25 25'
      fill={fill}
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask
        id='mask0_3211_34125'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='25'
        height='25'
      >
        <rect
          width='25'
          height='25'
          rx='12.5'
          fill='url(#paint0_diamond_3211_34125)'
        />
      </mask>
      <g mask='url(#mask0_3211_34125)'>
        <mask
          id='mask1_3211_34125'
          style={{ maskType: 'alpha' }}
          maskUnits='userSpaceOnUse'
          x='-8'
          y='-17'
          width='41'
          height='58'
        >
          <path
            d='M-5.56318 23.8364L-7.7486 5.00567C-8.93132 -5.18528 4.61868 -15.1533 14.8054 -16.3723C25.5141 -17.6537 29.396 -8.93393 30.6393 1.77933L32.684 19.3976C33.9061 29.9277 26.3855 39.4645 15.8607 40.7311C5.27269 42.0053 -4.33377 34.4297 -5.56318 23.8364Z'
            fill='url(#paint1_linear_3211_34125)'
          />
        </mask>
        <g mask='url(#mask1_3211_34125)'>
          <path
            d='M26.5699 7.02766C27.0461 10.9854 23.3535 14.8056 19.684 15.2472C16.0145 15.6888 11.0061 14.6662 10.4951 10.4198C10.2419 8.31557 7.48144 6.05578 9.00791 3.33582C10.5618 0.566992 15.6362 0.543392 17.4873 0.320626C21.1568 -0.120974 26.0936 3.06988 26.5699 7.02766Z'
            fill='#FC7980'
          />
          <g filter='url(#filter0_f_3211_34125)'>
            <path
              d='M26.2487 7.0181C26.7595 11.2627 23.9474 14.6829 21.0985 15.0257C18.2496 15.3686 14.3182 14.2171 13.8074 9.97243C13.5543 7.86901 11.2685 5.55317 12.3864 2.8834C13.5244 0.165675 17.4796 0.276531 18.9167 0.103583C21.7656 -0.239261 25.7379 2.77348 26.2487 7.0181Z'
              fill='#FC7980'
            />
          </g>
          <g filter='url(#filter1_f_3211_34125)'>
            <path
              d='M26.2645 7.14658C26.837 11.904 23.323 15.7809 19.8052 16.2043C16.2874 16.6276 11.455 15.3882 10.8825 10.6308C10.5988 8.27332 7.48356 5.74428 8.89826 2.73254C10.3384 -0.333316 15.5117 -0.298173 17.2863 -0.511727C20.8041 -0.935066 25.692 2.38919 26.2645 7.14658Z'
              fill='#FC7980'
            />
          </g>
          <g filter='url(#filter2_f_3211_34125)'>
            <path
              d='M25.1824 7.83576C24.9353 13.8242 20.4772 19.2965 15.9259 19.8442C11.3746 20.3919 6.09573 18.0028 5.40911 12.2973C5.06885 9.46991 -0.328922 6.43222 1.53325 2.80031C3.42888 -0.896847 11.7821 -1.55842 14.078 -1.83471C18.6292 -2.38243 25.4685 0.89847 25.1824 7.83576Z'
              fill='#FC7980'
            />
          </g>
          <g filter='url(#filter3_f_3211_34125)'>
            <path
              d='M24.2126 9.51312C24.9519 15.6565 19.7773 22.0897 15.3275 22.6252C10.8777 23.1607 5.68095 20.5297 4.94164 14.3863C4.57527 11.3419 2.43223 7.8499 4.21286 3.96629C6.02547 0.0129153 15.036 -2.89287 17.2807 -3.163C21.7305 -3.6985 23.4733 3.36973 24.2126 9.51312Z'
              fill='#FC7980'
            />
          </g>
          <g filter='url(#filter4_f_3211_34125)'>
            <path
              d='M22.7772 12.5567C23.5412 18.9053 18.2014 25.0925 13.6501 25.6402C9.09886 26.1879 3.7795 23.4628 3.01549 17.1142C2.63688 13.9682 8.22879 8.52975 10.0454 4.51923C11.8947 0.436657 18.2061 0.554828 20.502 0.278534C25.0533 -0.269178 22.0132 6.20804 22.7772 12.5567Z'
              fill='#FC7980'
            />
          </g>
          <g filter='url(#filter5_f_3211_34125)'>
            <path
              d='M22.9278 7.69449C23.5963 13.2495 18.1743 18.7536 13.623 19.3013C9.07175 19.849 3.8023 17.5386 3.13379 11.9835C2.80251 9.23071 1.18962 6.034 3.06246 2.4907C4.96894 -1.11625 11.2674 -1.10623 13.5633 -1.38252C18.1145 -1.93024 22.2593 2.13944 22.9278 7.69449Z'
              fill='#FC7980'
            />
          </g>
        </g>
        <path
          d='M-19.2864 15.0776C-18.8158 11.1331 -16.5862 5.50969 -9.68797 -0.273329C-3.77483 -5.23048 5.9653 -5.90996 11.8211 -0.885186L16.0019 2.70233C23.6599 9.27356 24.1883 20.9424 17.1557 28.179L-13.6734 59.9018C-14.7958 61.0567 -11.9897 58.1694 -13.1597 59.102C-19.8024 64.3968 -36.734 60.5018 -45.4913 53.5609C-47.9582 55.8258 -49.1955 58.4115 -49.1019 60.2035C-48.7384 63.0914 -48.5867 64.2646 -49.0792 60.4558C-49.0898 60.3737 -49.0974 60.2895 -49.1019 60.2035C-49.711 55.3642 -50.9149 45.7099 -50.6783 46.6166C-50.0311 49.0961 -48.1331 51.4671 -45.4913 53.5609C-45.0893 53.1918 -44.6546 52.8312 -44.1876 52.4839C-41.4689 50.462 -40.3251 49.3534 -38.1144 44.7235C-35.9037 40.0936 -37.3141 36.3482 -28.4437 31.1521C-19.5733 25.9561 -19.7569 19.0221 -19.2864 15.0776Z'
          fill='url(#paint2_linear_3211_34125)'
        />
        <g filter='url(#filter6_f_3211_34125)'>
          <path
            d='M-19.2864 15.0776C-18.8158 11.1331 -16.5862 5.50969 -9.68797 -0.273329C-3.77483 -5.23048 5.9653 -5.90996 11.8211 -0.885186L16.0019 2.70233C23.6599 9.27356 24.1883 20.9424 17.1557 28.179L-14.3928 60.6421C-16.6587 62.9737 -10.9939 57.1447 -11.6596 57.7337C-18.021 63.362 -34.3603 60.0367 -43.7087 53.4048C-46.9229 55.8945 -48.5225 59.0053 -48.3525 61.0325C-47.9477 64.1888 -47.7931 65.3837 -48.3372 61.177C-48.3433 61.1295 -48.3484 61.0813 -48.3525 61.0325C-49.0512 55.5854 -50.4954 44.2968 -50.3814 44.8479C-49.7363 47.9667 -47.2139 50.9182 -43.7087 53.4048C-43.6222 53.3378 -43.5345 53.2712 -43.4456 53.2051C-40.7268 51.1832 -39.583 50.0745 -37.3724 45.4447C-35.1617 40.8148 -36.5721 37.0694 -27.7017 31.8733C-18.8313 26.6772 -19.7569 19.0221 -19.2864 15.0776Z'
            fill='url(#paint3_linear_3211_34125)'
          />
        </g>
        <g filter='url(#filter7_f_3211_34125)'>
          <path
            d='M-19.2864 15.0776C-18.8158 11.1331 -16.5862 5.50969 -9.68797 -0.273329C-3.77483 -5.23048 5.9653 -5.90996 11.8211 -0.885186L16.0019 2.70233C23.6599 9.27356 24.1883 20.9424 17.1557 28.179L-14.3928 60.6421C-16.6587 62.9737 -10.9939 57.1447 -11.6596 57.7337C-18.021 63.362 -34.3603 60.0367 -43.7087 53.4048C-46.9229 55.8945 -48.5225 59.0053 -48.3525 61.0325C-47.9477 64.1888 -47.7931 65.3837 -48.3372 61.177C-48.3433 61.1295 -48.3484 61.0813 -48.3525 61.0325C-49.0512 55.5854 -50.4954 44.2968 -50.3814 44.8479C-49.7363 47.9667 -47.2139 50.9182 -43.7087 53.4048C-43.6222 53.3378 -43.5345 53.2712 -43.4456 53.2051C-40.7268 51.1832 -39.583 50.0745 -37.3724 45.4447C-35.1617 40.8148 -36.5721 37.0694 -27.7017 31.8733C-18.8313 26.6772 -19.7569 19.0221 -19.2864 15.0776Z'
            fill='url(#paint4_linear_3211_34125)'
          />
        </g>
        <g filter='url(#filter8_f_3211_34125)'>
          <path
            d='M-17.093 11.3617C-16.6224 7.4172 -16.8731 5.41313 -11.4205 -1.95597C-6.83109 -8.15863 4.23026 -7.56167 10.0676 -2.51576L15.2252 1.94255C23.3128 8.93357 23.8499 21.2896 16.3995 28.956L-15.5779 61.8604C-24.2904 70.8255 -45.0174 74.2822 -46.6208 61.8842C-46.8891 59.8095 -45.1986 56.4925 -41.7292 53.9123C-39.0104 51.8904 -37.8666 50.7818 -35.656 46.1519C-33.4453 41.522 -26.2141 36.8371 -15.9104 29.044C-5.6067 21.2509 -17.5635 15.3062 -17.093 11.3617Z'
            fill='url(#paint5_linear_3211_34125)'
          />
        </g>
        <g filter='url(#filter9_f_3211_34125)'>
          <path
            d='M-17.7895 16.4143C-17.3166 12.448 -16.4673 5.32378 -11.6666 -2.19629C-7.46078 -8.78448 1.5752 -10.1044 7.46319 -4.96388L14.0072 0.749332C22.7699 8.39961 23.3208 21.8354 15.2138 30.1774L-25.9032 72.4864C-28.8375 75.5058 -35.1821 77.6121 -35.7221 73.4366C-35.9904 71.3619 -34.2999 68.0449 -30.8305 65.4647C-28.1117 63.4427 -26.968 62.3341 -24.7573 57.7042C-22.5466 53.0743 -23.957 49.329 -15.0866 44.1329C-6.21619 38.9368 -18.2726 20.4645 -17.8021 16.52L-17.7895 16.4143Z'
            fill='url(#paint6_linear_3211_34125)'
          />
        </g>
        <g filter='url(#filter10_f_3211_34125)'>
          <path
            d='M-1.26105 11.554C-0.526877 10.2566 0.698049 8.55021 2.66511 6.70181C7.40767 2.24533 14.7569 3.71995 19.4239 8.25548C25.5461 14.2052 25.6859 23.9914 19.7363 30.1136L-26.7131 77.9095C-29.0985 80.3641 -32.9571 80.6079 -35.6324 78.4731C-38.808 75.9392 -41.1411 73.0391 -39.0234 69.572C-38.7953 69.1986 -38.5529 68.8014 -38.2956 68.3792C-32.0105 58.0673 -33.7936 51.8429 -33.7635 47.8097C-33.7293 43.2217 -26.4657 45.5612 -22.8784 41.87C-20.9072 39.8417 -20.8769 38.1124 -20.441 33.7182C-20.0052 29.324 -20.8164 24.8977 -12.8854 21.5931C-4.95446 18.2885 -2.76876 14.2183 -1.26105 11.554Z'
            fill='url(#paint7_linear_3211_34125)'
          />
        </g>
        <g filter='url(#filter11_f_3211_34125)'>
          <path
            d='M-1.26105 11.554C-0.526877 10.2566 0.698049 8.55021 2.66511 6.70181C7.40767 2.24533 14.7569 3.71995 19.4239 8.25548C25.5461 14.2052 25.6859 23.9914 19.7363 30.1136L-25.9495 77.1237C-28.757 80.0127 -33.3502 80.2335 -36.4608 77.6739C-40.1018 74.6778 -42.2203 71.0949 -39.352 67.3525C-32.0057 57.7677 -32.7739 54.1652 -32.7439 50.132C-32.7097 45.5441 -26.9604 45.0805 -23.3731 41.3892C-21.4019 39.3609 -20.8769 38.1124 -20.441 33.7182C-20.0052 29.324 -20.8164 24.8977 -12.8854 21.5931C-4.95446 18.2885 -2.76876 14.2183 -1.26105 11.554Z'
            fill='url(#paint8_linear_3211_34125)'
          />
        </g>
        <g filter='url(#filter12_f_3211_34125)'>
          <path
            d='M-1.26105 11.554C-0.526877 10.2566 0.698049 8.55021 2.66511 6.70181C7.40767 2.24533 14.7569 3.71995 19.4239 8.25548C25.5461 14.2052 25.686 23.9914 19.7363 30.1136L-26.3697 77.5562C-28.9449 80.2061 -33.1162 80.4541 -35.9874 78.1282C-39.3933 75.3689 -41.7852 71.9865 -39.5021 68.2446L-39.1046 67.5929C-32.8196 57.2811 -32.3639 53.4289 -32.3339 49.3957C-32.2997 44.8077 -26.1513 45.8667 -22.5641 42.1755C-20.5929 40.1471 -20.8769 38.1124 -20.441 33.7182C-20.0052 29.324 -20.8164 24.8977 -12.8854 21.5931C-4.95446 18.2885 -2.76875 14.2183 -1.26105 11.554Z'
            fill='url(#paint9_linear_3211_34125)'
          />
        </g>
        <g filter='url(#filter13_f_3211_34125)'>
          <path
            d='M-1.26105 11.554C-0.526877 10.2566 0.698049 8.55021 2.66511 6.70181C7.40767 2.24533 14.7569 3.71995 19.4239 8.25548C25.5461 14.2052 25.686 23.9914 19.7363 30.1136L-26.3697 77.5562C-28.9449 80.2061 -33.1162 80.4541 -35.9874 78.1282C-39.3933 75.3689 -41.7852 71.9865 -39.5021 68.2446L-39.1046 67.5929C-32.8196 57.2811 -31.8611 53.7209 -31.8311 49.6877C-31.7969 45.0997 -26.9604 45.0805 -23.3731 41.3892C-21.4019 39.3609 -20.8769 38.1124 -20.441 33.7182C-20.0052 29.324 -20.8164 24.8977 -12.8854 21.5931C-4.95446 18.2885 -2.76875 14.2183 -1.26105 11.554Z'
            fill='url(#paint10_linear_3211_34125)'
          />
        </g>
        <path
          d='M-6.92511 6.05028C-6.52016 5.33468 -5.96591 4.49464 -5.22018 3.57498C0.109442 -2.99768 10.0263 -0.876602 16.0947 5.02081C24.0552 12.757 24.237 25.4817 16.5008 33.4422L-26.7138 77.9096C-29.0992 80.3641 -32.9578 80.6079 -35.6331 78.4732C-38.8087 75.9392 -41.1418 73.0392 -39.0241 69.5721C-38.796 69.1987 -38.5536 68.8014 -38.2963 68.3792C-32.0112 58.0674 -34.289 51.3622 -34.2589 47.329C-34.2247 42.741 -32.6245 39.5768 -29.0372 35.8855C-27.066 33.8572 -26.5409 32.6087 -26.1051 28.2145C-25.6692 23.8202 -26.4804 19.394 -18.5495 16.0894C-10.6185 12.7848 -8.43282 8.71459 -6.92511 6.05028Z'
          fill='url(#paint11_linear_3211_34125)'
        />
        <mask
          id='mask2_3211_34125'
          style={{ maskType: 'alpha' }}
          maskUnits='userSpaceOnUse'
          x='-40'
          y='-1'
          width='63'
          height='81'
        >
          <path
            d='M-6.92511 6.05028C-6.52016 5.33468 -5.96591 4.49464 -5.22018 3.57498C0.109442 -2.99768 10.0263 -0.876602 16.0947 5.02081C24.0552 12.757 24.237 25.4817 16.5008 33.4422L-26.7138 77.9096C-29.0992 80.3641 -32.9578 80.6079 -35.6331 78.4732C-38.8087 75.9392 -41.1418 73.0392 -39.0241 69.5721C-38.796 69.1987 -38.5536 68.8014 -38.2963 68.3792C-32.0112 58.0674 -34.289 51.3622 -34.2589 47.329C-34.2247 42.741 -32.6245 39.5768 -29.0372 35.8855C-27.066 33.8572 -26.5409 32.6087 -26.1051 28.2145C-25.6692 23.8202 -26.4804 19.394 -18.5495 16.0894C-10.6185 12.7848 -8.43282 8.71459 -6.92511 6.05028Z'
            fill='url(#paint12_linear_3211_34125)'
          />
        </mask>
        <g mask='url(#mask2_3211_34125)'>
          <path
            opacity='0.6'
            d='M-16.4885 16.3383C-11.1948 13.3966 -9.23041 10.0452 -7.2488 6.83075C-2.17196 -1.40462 -5.49112 -2.97197 -12.8012 3.36512L-25.0144 13.9528C-28.5179 16.99 -30.9707 21.0578 -32.0215 25.5739L-36.7127 45.7352C-36.7991 46.1066 -36.5741 46.4793 -36.2051 46.5757C-35.7941 46.6831 -35.3832 46.4029 -35.2645 45.995C-34.5732 43.6191 -32.2258 41.2314 -30.4336 39.0083C-27.9471 35.9238 -25.044 33.184 -24.6904 30.191C-24.3368 27.198 -24.207 23.4764 -23.6303 22.2334C-23.0535 20.9903 -21.7821 19.2799 -16.4885 16.3383Z'
            fill='#FFF4CF'
          />
        </g>
        <path
          d='M-28.1169 66.7551C-28.2129 66.5166 -28.2424 66.253 -28.1975 65.9998C-26.0878 54.0925 -16.3062 48.5133 -10.668 36.5491C-4.96853 24.4548 -3.56142 20.2727 2.96885 13.3815C9.49912 6.49023 9.50947 2.558 9.75409 -3.45887C9.78879 -4.31219 10.1656 -4.50625 10.7869 -4.21088C11.4757 -3.88341 9.75375 -4.70208 12.8004 -1.45734C17.4733 3.51932 19.8427 18.6168 16.919 24.7856C15.0129 28.8077 12.4985 31.1743 7.4697 35.9077L-25.8362 67.257C-26.5611 67.9393 -27.7454 67.6787 -28.1169 66.7551Z'
          fill='#FF83DC'
        />
        <g filter='url(#filter15_f_3211_34125)'>
          <path
            d='M-30.359 67.4409C-30.5359 67.0011 -30.4329 66.5054 -30.1166 66.1523C-26.6481 62.2812 -19.9941 50.9792 -12.5279 40.802C-11.3617 39.2123 -10.7786 38.4175 -10.3836 37.8025C-10.0381 37.2648 -9.8147 36.8874 -9.50928 36.3259C-9.16004 35.6839 -8.83181 34.9798 -8.17535 33.5717C-3.79668 24.1792 -1.74711 19.5983 2.96931 13.3814C9.49958 6.49014 8.23386 0.831477 8.47849 -5.18539C8.52063 -6.2219 9.10549 -6.23447 10.0448 -5.54513C12.5685 -3.69305 13.8304 -2.76702 16.2244 1.05043C19.6514 6.51517 20.2576 18.1804 17.4155 23.9709C15.4301 28.016 12.3027 30.8541 6.04791 36.5302L-28.4875 67.8706C-29.0908 68.4181 -30.055 68.1967 -30.359 67.4409Z'
            fill='#FF99AC'
          />
        </g>
        <g filter='url(#filter16_f_3211_34125)'>
          <path
            d='M-27.5435 69.6728C-29.357 67.9984 -29.4228 65.1212 -27.8794 63.195C-24.2989 58.7266 -19.1006 51.1526 -11.2606 39.3514C-10.933 38.8583 -10.7693 38.6118 -10.6048 38.3496C-10.2128 37.7245 -9.82351 37.0319 -9.49334 36.372C-9.35488 36.0952 -9.23339 35.8356 -8.99042 35.3164C-4.03346 24.7231 -2.03055 19.9714 2.96896 13.3813C8.42705 5.36359 5.16384 -0.709631 5.40846 -6.72649C5.44996 -7.74722 6.10611 -7.73061 7.16717 -6.99924C13.0897 -2.91697 16.051 -0.875832 18.7549 5.08375C20.4162 8.74544 21.1821 15.8104 20.344 19.743C18.98 26.1436 14.5563 30.8794 5.70898 40.351L-21.4705 69.4482C-23.0886 71.1804 -25.8019 71.2808 -27.5435 69.6728Z'
            fill='#FF4A76'
          />
        </g>
        <g filter='url(#filter17_f_3211_34125)'>
          <path
            d='M-20.3705 71.7643C-26.9654 68.9707 -27.3634 62.0265 -21.3524 58.1322C-16.095 54.7261 -10.1132 49.9101 -4.92356 42.5932C-4.83234 42.4646 -5.06038 42.7861 -5.02678 42.7382C-4.41526 41.8669 -3.82237 40.8835 -3.33734 39.9359C-3.31069 39.8838 -3.2359 39.7359 -3.08632 39.4401C2.44728 28.496 8.9411 23.3851 14.0118 16.7011C19.4699 8.68337 14.3918 2.72364 14.6365 -3.29323C14.6662 -4.02439 15.0381 -4.22496 15.6686 -4.0131C20.2931 -2.45914 22.6054 -1.68216 27.0561 4.67328C29.4656 8.11385 31.6735 17.2314 31.1051 21.3931C30.055 29.0807 25.0022 34.2765 14.8966 44.668L-9.27387 69.5227C-12.1565 72.4869 -16.5633 73.3771 -20.3705 71.7643Z'
            fill='#FF9595'
          />
        </g>
        <mask
          id='mask3_3211_34125'
          style={{ maskType: 'alpha' }}
          maskUnits='userSpaceOnUse'
          x='6'
          y='-2'
          width='41'
          height='57'
        >
          <path
            d='M46.1015 18.6892L44.8878 37.6074C44.231 47.8458 29.1182 55.2328 18.8777 54.6124C8.11223 53.9602 5.85055 44.6872 6.54105 33.9242L7.67661 16.224C8.35531 5.64498 17.4586 -2.39486 28.0404 -1.7609C38.6857 -1.12313 46.7843 8.04671 46.1015 18.6892Z'
            fill='url(#paint13_linear_3211_34125)'
          />
        </mask>
        <g mask='url(#mask3_3211_34125)'>
          <path
            d='M11.4818 29.4874C11.7202 25.5082 16.0359 22.4091 19.7252 22.6302C23.4146 22.8512 28.1598 24.752 27.904 29.0214C27.7772 31.137 30.0896 33.8536 28.1018 36.2571C26.0782 38.7038 21.0812 37.8205 19.2201 37.709C15.5308 37.488 11.2434 33.4666 11.4818 29.4874Z'
            fill='#FFCB8E'
          />
          <g filter='url(#filter18_f_3211_34125)'>
            <path
              d='M11.794 29.554C12.0497 25.2864 15.4276 22.4236 18.2919 22.5952C21.1562 22.7668 24.8186 24.6022 24.5629 28.8698C24.4362 30.9846 26.2716 33.6715 24.6947 36.0986C23.0895 38.5693 19.2178 37.7537 17.7729 37.6671C14.9086 37.4955 11.5383 33.8216 11.794 29.554Z'
              fill='#FFCB8E'
            />
          </g>
          <g filter='url(#filter19_f_3211_34125)'>
            <path
              d='M11.8032 29.4249C12.0898 24.6417 16.2399 21.4549 19.7767 21.6668C23.3135 21.8787 27.8468 23.9614 27.5603 28.7445C27.4183 31.1148 30.0316 34.1597 28.1016 36.8703C26.1369 39.6295 21.0531 38.6708 19.2689 38.5639C15.7321 38.352 11.5166 34.208 11.8032 29.4249Z'
              fill='#FFCB8E'
            />
          </g>
          <g filter='url(#filter20_f_3211_34125)'>
            <path
              d='M12.9903 28.9401C14.3031 23.0921 19.6672 18.5043 24.243 18.7785C28.8189 19.0526 33.5861 22.3463 33.2425 28.0827C33.0722 30.9254 37.8404 34.8785 35.3594 38.1193C32.8338 41.4184 24.4968 40.5771 22.1885 40.4388C17.6126 40.1646 11.4694 35.7147 12.9903 28.9401Z'
              fill='#FFCB8E'
            />
          </g>
          <g filter='url(#filter21_f_3211_34125)'>
            <path
              d='M14.2431 27.4628C14.6132 21.2861 20.8538 15.8808 25.3276 16.1488C29.8015 16.4168 34.4447 19.934 34.0746 26.1106C33.8912 29.1714 35.376 32.9901 32.9302 36.4932C30.4405 40.0591 21.0558 41.3085 18.799 41.1733C14.3251 40.9053 13.8731 33.6394 14.2431 27.4628Z'
              fill='#FFCB8E'
            />
          </g>
          <g filter='url(#filter22_f_3211_34125)'>
            <path
              d='M16.1999 24.7247C16.5823 18.3418 22.9416 13.208 27.5174 13.4821C32.0933 13.7563 36.8403 17.3878 36.4579 23.7708C36.2684 26.9339 29.7949 31.2858 27.2911 34.9073C24.7422 38.5939 18.5534 37.3501 16.2451 37.2118C11.6692 36.9377 15.8175 31.1077 16.1999 24.7247Z'
              fill='#FFCB8E'
            />
          </g>
          <g filter='url(#filter23_f_3211_34125)'>
            <path
              d='M15.1843 29.4819C15.519 23.8968 21.837 19.4499 26.4129 19.724C30.9888 19.9982 35.7608 23.2128 35.4261 28.7979C35.2603 31.5656 36.2762 34.9991 33.8005 38.1508C31.2803 41.3591 25.085 40.2241 22.7767 40.0858C18.2008 39.8117 14.8497 35.067 15.1843 29.4819Z'
              fill='#FFCB8E'
            />
          </g>
        </g>
      </g>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.04125 16.7111C8.04125 16.5329 8.18702 16.3872 8.36518 16.3872H16.7874C16.9656 16.3872 17.1113 16.5329 17.1113 16.7111V17.359C17.1113 17.5371 16.9656 17.6829 16.7874 17.6829H8.36518C8.18702 17.6829 8.04125 17.5371 8.04125 17.359V16.7111ZM17.1113 10.8803C17.1113 10.3438 17.5466 9.90853 18.0831 9.90853C18.6196 9.90853 19.0549 10.3438 19.0549 10.8803C19.0549 11.4168 18.6196 11.8521 18.0831 11.8521C18.0305 11.8521 17.9779 11.844 17.9272 11.8359L16.4635 15.7393H8.68911L7.22534 11.8359C7.17473 11.844 7.12209 11.8521 7.06945 11.8521C6.53294 11.8521 6.09766 11.4168 6.09766 10.8803C6.09766 10.3438 6.53496 9.90853 7.07148 9.90853C7.60799 9.90853 8.04327 10.3438 8.04327 10.8803C8.04327 11.0241 8.00885 11.1597 7.95419 11.2812L9.41796 12.1599C9.72772 12.3461 10.1326 12.2408 10.3128 11.925L11.9628 9.03797C11.7462 8.8598 11.6045 8.59256 11.6045 8.28887C11.6045 7.75236 12.0398 7.31708 12.5763 7.31708C13.1128 7.31708 13.5481 7.75236 13.5481 8.28887C13.5481 8.59256 13.4064 8.8598 13.1897 9.03797L14.8398 11.925C15.02 12.2408 15.4228 12.3461 15.7346 12.1599L17.2004 11.2812C17.1437 11.1577 17.1113 11.0241 17.1113 10.8803ZM12.6696 14.453C13.1882 14.453 13.6085 14.0327 13.6085 13.5141C13.6085 12.9955 13.1882 12.5752 12.6696 12.5752C12.151 12.5752 11.7307 12.9955 11.7307 13.5141C11.7307 14.0327 12.151 14.453 12.6696 14.453Z'
        fill='white'
      />
      <defs>
        <filter
          id='filter0_f_3211_34125'
          x='11.815'
          y='-0.18801'
          width='14.7586'
          height='15.5356'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='0.132518'
            result='effect1_foregroundBlur_3211_34125'
          />
        </filter>
        <filter
          id='filter1_f_3211_34125'
          x='7.60362'
          y='-1.47566'
          width='19.6502'
          height='18.6849'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='0.463814'
            result='effect1_foregroundBlur_3211_34125'
          />
        </filter>
        <filter
          id='filter2_f_3211_34125'
          x='-0.182605'
          y='-3.21874'
          width='26.6992'
          height='24.4645'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='0.662592'
            result='effect1_foregroundBlur_3211_34125'
          />
        </filter>
        <filter
          id='filter3_f_3211_34125'
          x='-0.0643234'
          y='-6.76965'
          width='27.9255'
          height='33.0406'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='1.789'
            result='effect1_foregroundBlur_3211_34125'
          />
        </filter>
        <filter
          id='filter4_f_3211_34125'
          x='-5.88068'
          y='-8.63264'
          width='37.8317'
          height='43.2202'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='4.43936'
            result='effect1_foregroundBlur_3211_34125'
          />
        </filter>
        <filter
          id='filter5_f_3211_34125'
          x='-1.93619'
          y='-5.54001'
          width='29.0286'
          height='29.0278'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='2.05403'
            result='effect1_foregroundBlur_3211_34125'
          />
        </filter>
        <filter
          id='filter6_f_3211_34125'
          x='-52.1132'
          y='-6.06265'
          width='75.9433'
          height='71.6756'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='0.862286'
            result='effect1_foregroundBlur_3211_34125'
          />
        </filter>
        <filter
          id='filter7_f_3211_34125'
          x='-52.1132'
          y='-6.06265'
          width='75.9433'
          height='71.6756'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='0.862286'
            result='effect1_foregroundBlur_3211_34125'
          />
        </filter>
        <filter
          id='filter8_f_3211_34125'
          x='-50.6724'
          y='-10.4781'
          width='76.3527'
          height='84.438'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='2.012'
            result='effect1_foregroundBlur_3211_34125'
          />
        </filter>
        <filter
          id='filter9_f_3211_34125'
          x='-44.8327'
          y='-17.1938'
          width='74.8686'
          height='102.052'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='4.54137'
            result='effect1_foregroundBlur_3211_34125'
          />
        </filter>
        <filter
          id='filter10_f_3211_34125'
          x='-43.2213'
          y='0.627317'
          width='70.7161'
          height='82.6837'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='1.69269'
            result='effect1_foregroundBlur_3211_34125'
          />
        </filter>
        <filter
          id='filter11_f_3211_34125'
          x='-49.6754'
          y='-4.9752'
          width='82.7727'
          height='93.4154'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='4.49395'
            result='effect1_foregroundBlur_3211_34125'
          />
        </filter>
        <filter
          id='filter12_f_3211_34125'
          x='-55.8227'
          y='-11.4096'
          width='95.3543'
          height='106.553'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='7.71114'
            result='effect1_foregroundBlur_3211_34125'
          />
        </filter>
        <filter
          id='filter13_f_3211_34125'
          x='-46.795'
          y='-2.38191'
          width='77.299'
          height='88.4973'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='3.1973'
            result='effect1_foregroundBlur_3211_34125'
          />
        </filter>
        <filter
          id='filter15_f_3211_34125'
          x='-32.8488'
          y='-8.42355'
          width='54.4711'
          height='79.0016'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='1.20177'
            result='effect1_foregroundBlur_3211_34125'
          />
        </filter>
        <filter
          id='filter16_f_3211_34125'
          x='-34.3363'
          y='-12.8852'
          width='60.3836'
          height='89.0629'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='2.68086'
            result='effect1_foregroundBlur_3211_34125'
          />
        </filter>
        <filter
          id='filter17_f_3211_34125'
          x='-38.7305'
          y='-17.2198'
          width='83.0528'
          height='102.905'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='6.56349'
            result='effect1_foregroundBlur_3211_34125'
          />
        </filter>
        <filter
          id='filter18_f_3211_34125'
          x='11.5162'
          y='22.3229'
          width='14.0867'
          height='15.8068'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='0.132518'
            result='effect1_foregroundBlur_3211_34125'
          />
        </filter>
        <filter
          id='filter19_f_3211_34125'
          x='10.8614'
          y='20.7292'
          width='18.8806'
          height='19.0094'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='0.463814'
            result='effect1_foregroundBlur_3211_34125'
          />
        </filter>
        <filter
          id='filter20_f_3211_34125'
          x='11.4287'
          y='17.4416'
          width='25.9726'
          height='24.5193'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='0.662592'
            result='effect1_foregroundBlur_3211_34125'
          />
        </filter>
        <filter
          id='filter21_f_3211_34125'
          x='10.5626'
          y='12.5612'
          width='27.3708'
          height='32.1999'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='1.789'
            result='effect1_foregroundBlur_3211_34125'
          />
        </filter>
        <filter
          id='filter22_f_3211_34125'
          x='5.2326'
          y='4.59289'
          width='40.1266'
          height='41.8098'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='4.43936'
            result='effect1_foregroundBlur_3211_34125'
          />
        </filter>
        <filter
          id='filter23_f_3211_34125'
          x='11.054'
          y='15.604'
          width='28.5501'
          height='28.8967'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='2.05403'
            result='effect1_foregroundBlur_3211_34125'
          />
        </filter>
        <radialGradient
          id='paint0_diamond_3211_34125'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(-1.56722e-07 1.28049) rotate(43.0683) scale(32.8301 880.805)'
        >
          <stop offset='0.0001' stopColor='#FFA986' />
          <stop offset='0.255208' stopColor='#FA7BBB' />
          <stop offset='0.53125' stopColor='#D377ED' />
          <stop offset='1' stopColor='#62A1FE' />
        </radialGradient>
        <linearGradient
          id='paint1_linear_3211_34125'
          x1='20.3724'
          y1='5.96823'
          x2='-3.36444'
          y2='6.32883'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#C4C4C4' />
          <stop offset='1' stopColor='#C4C4C4' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_3211_34125'
          x1='-49.351'
          y1='53.2106'
          x2='6.33652'
          y2='0.157878'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.197917' stopColor='#FDC2B7' />
          <stop offset='1' stopColor='#FF598B' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_3211_34125'
          x1='-49.351'
          y1='53.2106'
          x2='6.33652'
          y2='0.157878'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.197917' stopColor='#FDC2B7' />
          <stop offset='1' stopColor='#FF598B' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_3211_34125'
          x1='-49.351'
          y1='53.2106'
          x2='6.33652'
          y2='0.157878'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.197917' stopColor='#FDC2B7' />
          <stop offset='1' stopColor='#FF598B' />
        </linearGradient>
        <linearGradient
          id='paint5_linear_3211_34125'
          x1='-50.4468'
          y1='51.749'
          x2='4.93265'
          y2='-1.25272'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.197917' stopColor='#FDC2B7' />
          <stop offset='1' stopColor='#FF437B' />
        </linearGradient>
        <linearGradient
          id='paint6_linear_3211_34125'
          x1='-52.4325'
          y1='50.2159'
          x2='3.01007'
          y2='-3.11201'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.197917' stopColor='#FDC2B7' />
          <stop offset='1' stopColor='#FF598B' />
        </linearGradient>
        <linearGradient
          id='paint7_linear_3211_34125'
          x1='10.7215'
          y1='2.158'
          x2='-34.0581'
          y2='57.8473'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF37AE' />
          <stop offset='0.536458' stopColor='#FF6A88' />
          <stop offset='1' stopColor='#FFBA7B' />
        </linearGradient>
        <linearGradient
          id='paint8_linear_3211_34125'
          x1='10.7215'
          y1='2.158'
          x2='-34.0581'
          y2='57.8473'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF37AE' />
          <stop offset='0.536458' stopColor='#FF6A88' />
          <stop offset='1' stopColor='#FFBA7B' />
        </linearGradient>
        <linearGradient
          id='paint9_linear_3211_34125'
          x1='10.7215'
          y1='2.158'
          x2='-34.0581'
          y2='57.8473'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF37AE' />
          <stop offset='0.536458' stopColor='#FF6A88' />
          <stop offset='1' stopColor='#FFBA7B' />
        </linearGradient>
        <linearGradient
          id='paint10_linear_3211_34125'
          x1='10.7215'
          y1='2.158'
          x2='-34.0581'
          y2='57.8473'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF37AE' />
          <stop offset='0.536458' stopColor='#FF6A88' />
          <stop offset='1' stopColor='#FFBA7B' />
        </linearGradient>
        <linearGradient
          id='paint11_linear_3211_34125'
          x1='10.7209'
          y1='2.15808'
          x2='-34.0588'
          y2='57.8473'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#CD2F8D' />
          <stop offset='0.536458' stopColor='#FFB9C7' />
          <stop offset='1' stopColor='#FFBA7B' />
        </linearGradient>
        <linearGradient
          id='paint12_linear_3211_34125'
          x1='10.7209'
          y1='2.15808'
          x2='-34.0588'
          y2='57.8473'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#CD2F8D' />
          <stop offset='0.536458' stopColor='#F72D55' />
          <stop offset='1' stopColor='#FFBA7B' />
        </linearGradient>
        <linearGradient
          id='paint13_linear_3211_34125'
          x1='17.3912'
          y1='31.6368'
          x2='40.8106'
          y2='35.5224'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#C4C4C4' />
          <stop offset='1' stopColor='#C4C4C4' stopOpacity='0' />
        </linearGradient>
      </defs>
    </svg>
  );
}
