import { Cell } from '../GridInGrid/engine';
import { ControlReference } from './ControlLayer';
import useCanvasRefs from './useCanvasRefs';
import useFeatheryRedux from '../../../redux';
import { useAppSelector } from '../../../hooks';
import { useState } from 'react';
import { CanvasElement } from './CanvasRefs';
import {
  getElementStyle,
  getElementStyleType
} from '../GridInGrid/components/CellElement/utils';
import { getNodeLabel } from '../utils/gig';
import classNames from 'classnames';
import styles from './controlLabel.module.scss';
import { ignoreDrawControls, labelControlClass } from './utils';
import OuterControl from './OuterControl';
import testIds from '../../../utils/testIds';
import useViewport from '../../../hooks/useViewport';

const CONTROL_LABEL_HEIGHT = 18;
const NEGATIVE_CONTROL_LABEL_HEIGHT = -1 * CONTROL_LABEL_HEIGHT;

const ControlLabel = ({
  gigSetPosition,
  focusElement,
  drawControls,
  control,
  textColor,
  backgroundColor,
  isSelected,
  isDragging,
  hideToggle
}: {
  gigSetPosition: (position: number[] | string) => void;
  focusElement: (node: Cell) => void;
  drawControls: (...args: any) => void; // TODO clean this up
  control: ControlReference;
  backgroundColor: string;
  textColor: string;
  isSelected: boolean;
  isDragging: boolean;
  hideToggle: boolean;
}) => {
  const { getCanvasElement } = useCanvasRefs();
  const [toggled, setToggle] = useState<boolean>(false);
  const activeStepId = useAppSelector(
    (state) => state.formBuilder.activeStepId
  );
  const workingSteps = useAppSelector(
    (state) => state.formBuilder.workingSteps
  );
  const {
    formBuilder: { selectTab, selectButtonTab, selectContainerTab }
  } = useFeatheryRedux();
  const { viewport } = useViewport();
  const theme = useAppSelector((s) => s.formBuilder.theme);
  const stepKey = workingSteps[activeStepId].key;

  const txtColor = isSelected ? textColor : backgroundColor;
  const focusedNode = control.canvasReference.node;

  const onClick = (canvasElement: CanvasElement) => {
    if (!canvasElement) return;

    if (
      Array.isArray(canvasElement.node.position) &&
      canvasElement.node.position.length === 0
    ) {
      gigSetPosition('root');
    } else {
      gigSetPosition(canvasElement.node.position);
    }
    focusElement(canvasElement.node);
    drawControls({ x: 0, y: 0 }, null, null, true, canvasElement, true);
  };

  const dimensionOnClick = (dimension: 'height' | 'width') => {
    if (focusedNode.type === 'Element') {
      if (focusedNode._type === 'button_element') selectButtonTab('styles');
      else selectTab('styles');
    } else {
      selectContainerTab('styles');
    }
    // The setTimeout is needed to ensure the tab has switched, so the input is
    // available to be focused. Requires two clicks without it. Longer timeout
    // causes delay if already on the correct tab, so 1ms is used.
    setTimeout(
      () => document.getElementById(`numberInput-${dimension}`)?.focus(),
      1
    );
  };

  const shouldDisplayParentLabels = () => {
    return toggled && !hideToggle;
  };

  const getParentElements = (): CanvasElement[] => {
    const parents: CanvasElement[] = [];

    let node: Cell = focusedNode;

    let n = 0;
    while (node.parent !== null && n < 3) {
      n = n + 1;
      node = node.parent;

      const canvasReference = getCanvasElement(node.positionId);

      if (!canvasReference) break;

      parents.push(canvasReference);
    }

    return parents;
  };

  const labels = getParentElements();

  let width = focusedNode.width;
  let height = focusedNode.height;
  if (focusedNode.isElement) {
    const nodeElement = control?.canvasReference?.node?.element?.getRaw();
    const { style } = getElementStyleType(nodeElement);
    const elStyles = getElementStyle(nodeElement, viewport, theme, style);
    width = `${elStyles.width}${elStyles.width_unit}`;
    height = `${elStyles.height}${elStyles.height_unit}`;
  }

  const flipped = control.overflowsCanvas;

  return (
    <OuterControl
      direction={'top'}
      position={'start'}
      distance={0}
      zIndex={isSelected ? 2 : 1}
      offset={{ left: -1, top: -1 }}
      flipped={flipped}
    >
      <div
        className={classNames(
          ignoreDrawControls,
          labelControlClass,
          styles.controlLabelContainer
        )}
        onClick={() =>
          !isSelected && onClick(getCanvasElement(focusedNode.positionId))
        }
      >
        <div
          id={'labels-container'}
          style={{
            position: 'relative',
            bottom: flipped
              ? NEGATIVE_CONTROL_LABEL_HEIGHT * (labels.length + 1) -
                (labels.length + 1)
              : 0
          }}
        >
          {shouldDisplayParentLabels() &&
            labels.reverse().map((canvasElement) => {
              return (
                <div
                  onClick={() => onClick(canvasElement)}
                  key={canvasElement.key + 'label'}
                  className={classNames(
                    styles.controlLabelBase,
                    styles.parentLabelContainer
                  )}
                  style={{
                    color: txtColor,
                    height: `${CONTROL_LABEL_HEIGHT}px`
                  }}
                >
                  <div
                    className={styles.parentLabel}
                    style={{
                      lineHeight: `${CONTROL_LABEL_HEIGHT}px`
                    }}
                  >
                    {getNodeLabel(canvasElement.node, stepKey)}
                  </div>
                </div>
              );
            })}
        </div>
        <div
          className={classNames({
            [styles.controlLabel]: true,
            [isSelected ? styles.selected : styles.unselected]: true
          })}
          style={{ height: `${CONTROL_LABEL_HEIGHT}px` }}
        >
          <div
            onClick={
              isSelected && labels.length > 0
                ? () => setToggle((t) => !t)
                : () => {}
            }
            className={classNames({
              [styles.controlLabelBase]: true,
              [styles.focusedLabelContainer]: true,
              [isSelected ? styles.selected : styles.unselected]: true,
              [styles.noParents]: labels.length === 0,
              [styles.opacity]: isDragging && !isSelected
            })}
            style={{
              paddingRight: labels.length > 0 ? 0 : 4,
              cursor: labels.length > 0 ? 'pointer' : 'auto'
            }}
          >
            <div
              data-testid={testIds.controlLabelText}
              className={styles.focusedLabel}
              style={{
                lineHeight: `${CONTROL_LABEL_HEIGHT}px`
              }}
            >
              {getNodeLabel(focusedNode, stepKey)}
            </div>
          </div>
          <div
            className={classNames({
              [styles.sizeContainer]: true,
              [isSelected ? styles.selected : styles.unselected]: true
            })}
          >
            <span onClick={() => dimensionOnClick('width')}>{width}</span> ×{' '}
            <span onClick={() => dimensionOnClick('height')}>{height}</span>
          </div>
        </div>
      </div>
    </OuterControl>
  );
};

export default ControlLabel;
