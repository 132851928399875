// API template for creating or updating a record in Salesforce

import { useMemo, useRef, useState } from 'react';
import { useFields } from '../../../../../../hooks/useFields';
import { TextField } from '../../../../../Core';
import { FieldReference } from '../../../components/FieldReference';
import { NavBar } from '../../../components/NavBar';
import { RolloutCredentialPicker } from '../../../components/RolloutCredentialPicker';
import {
  API_CONNECTOR_TEMPLATE_PAGE,
  CONFIGURE_REQUEST_PAGE,
  MAP_RESPONSE_STRUCTURE_PAGE,
  SELECT_API_SERVICE_PAGE
} from '../../../constants';
import { TEMPLATES } from '../../../templates';
import styles from '../../styles.module.scss';
import { PageProps } from '../../types';
import { INTEGRATION_TOKENS, validateState } from '../../utils';
import { SalesforceFieldList, SalesforceObjectPicker } from './components';

export const SalesforceCreatePage = (props: PageProps) => {
  const [errors, setErrors] = useState<any>({});
  const fieldsRef =
    useRef<{ getInvalidFields: (values: Record<string, string>) => string[] }>(
      null
    );
  const {
    state,
    setState = () => {},
    onSubmit = () => {},
    goto = () => {},
    apiConnectors = [],
    options
  } = props;

  const template =
    TEMPLATES[state.template as Exclude<keyof typeof TEMPLATES, 'custom'>];

  const [configState, setConfigState] = useState<Record<string, any>>(() =>
    template.loadConfig(state)
  );
  const handleConfigChange = (key: string, value: any) => {
    setConfigState((prev) => ({ ...prev, [key]: value }));
  };

  const fields = useFields(true);
  const fieldKeys = useMemo(() => {
    const fieldMap = fields.reduce((acc, f) => ({ ...acc, [f.key]: true }), {});
    INTEGRATION_TOKENS.forEach((token) => (fieldMap[token] = true));
    fieldMap.feathery_user_id = true;
    fieldMap.feathery_auth_email = true;
    return fieldMap;
  }, [fields]);

  const validateConfig = (config: any, { setErrors }: any): boolean => {
    const errors: Record<string, string | Record<string, string>> = {};
    if (!config.credential) {
      errors['credential'] = 'Please select a Salesforce Account.';
    }

    if (!config.object) {
      errors['object'] = 'Please select a Salesforce Object.';
    }
    if (!fieldsRef.current || !fieldsRef.current.getInvalidFields) {
      errors['field_values'] = 'Could not verify fields.';
    } else {
      const invalidFields = fieldsRef.current.getInvalidFields(
        config.field_values
      );
      if (!invalidFields) {
        errors['field_values'] = 'Could not verify fields.';
      }
      if (invalidFields.length > 0) {
        const fieldErrors: Record<string, string> = {};
        invalidFields.forEach(
          (field) => (fieldErrors[field] = 'This field is required')
        );
        errors['field_values'] = fieldErrors;
      }
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = () => {
    if (!validateConfig(configState, { setErrors })) return;
    const newState = template.saveConfig(state, configState);

    if (!validateState(newState, { setErrors, apiConnectors, fieldKeys }))
      return;
    setState(newState);
    onSubmit(MAP_RESPONSE_STRUCTURE_PAGE);
  };

  return (
    <>
      <div className={styles.page}>
        <div className={styles.content}>
          <div className='tw-flex tw-justify-end tw-absolute tw-top-[8px] tw-right-[36px] tw-left-0'>
            <FieldReference />
          </div>
          <div className={styles.field}>
            <div className={styles.label}>Connector Name</div>
            <TextField
              value={configState['name']}
              onChange={(val: string) => {
                handleConfigChange('name', val);
              }}
              error={errors['name']}
              errorMessage={errors['name']}
            />
          </div>
          <div className={styles.field}>
            <div className={styles.label}>Salesforce Account</div>
            <RolloutCredentialPicker
              app='salesforce'
              hideLabel
              credentialKey={
                configState['credential']?.credentialKey ??
                state.tokens?.['salesforce'] ??
                null
              }
              onChange={(credential) => {
                handleConfigChange('credential', credential);
              }}
              error={errors['credential']}
              errorMessage={errors['credential']}
            />
          </div>
          {!!configState['credential'] && (
            <>
              <div className={styles.field}>
                <div className={styles.label}>Salesforce Object</div>
                <SalesforceObjectPicker
                  credential={configState['credential']}
                  selected={configState['object']}
                  onChange={(event: any) => {
                    handleConfigChange('object', event.target.value);
                    handleConfigChange('field_values', {});
                  }}
                  error={errors['object']}
                  errorMessage={errors['object']}
                />
              </div>
              {!!configState['object'] && (
                <div className={styles.field}>
                  <h3 className={`${styles.label} !tw-text-xl`}>Fields</h3>
                  <SalesforceFieldList
                    ref={fieldsRef}
                    credential={configState['credential']}
                    object_name={configState['object']}
                    value={configState['field_values'] ?? {}}
                    onChange={(newValue: any) => {
                      handleConfigChange('field_values', newValue);
                    }}
                    error={errors['field_values']}
                    errorMessage={errors['field_values']}
                    create
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <NavBar
        next
        back={
          options?.startPage != null &&
          options.startPage < CONFIGURE_REQUEST_PAGE
        }
        onClick={(btn: string) => {
          if (btn === 'next') handleSubmit();
          else if (btn === 'back') {
            goto(
              state.id ? SELECT_API_SERVICE_PAGE : API_CONNECTOR_TEMPLATE_PAGE
            );
          }
        }}
      />
    </>
  );
};
