import { useAppSelector } from '../../../hooks';
import { ConnectDragSource } from 'react-dnd';
import useFeatheryRedux from '../../../redux';
import useCustomDragLayer from './useCustomDragLayer';
import { StepEntryDrag } from '../../Panels/FlowPanel/components/StepEntry';

export const StepIdentifier = 'step';

const useStepDrag = (stepId: string): ConnectDragSource => {
  const workingSteps = useAppSelector(
    (state) => state.formBuilder.workingSteps
  );
  const step = workingSteps[stepId];

  const {
    // @ts-ignore TODO(ts) featheryRedux does not have a defined return type
    formBuilder: { updateDrag }
  } = useFeatheryRedux();

  const [, dragRef] = useCustomDragLayer(
    () => ({
      type: StepIdentifier,
      renderer: () => <StepEntryDrag step={step} />,
      onStart: () => {
        updateDrag(true);

        return {
          step
        };
      },
      onEnd: () => {
        updateDrag(false);
      }
    }),
    [step]
  );

  return dragRef;
};

export default useStepDrag;
