export default function QRScannerIcon() {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.7381 6H6V22.7381H22.7381V6ZM19.5079 9.23016H9.23016V19.5079H19.5079V9.23016Z'
        fill='#414859'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.7381 26.2619H6V43H22.7381V26.2619ZM19.5079 29.4921H9.23016V39.7698H19.5079V29.4921Z'
        fill='#414859'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M42.7064 6H25.9683V22.7381H42.7064V6ZM39.4762 9.23016H29.1984V19.5079H39.4762V9.23016Z'
        fill='#414859'
      />
      <path d='M12.754 12.754H15.9841V15.9841H12.754V12.754Z' fill='#414859' />
      <path d='M12.754 33.0159H15.9841V36.246H12.754V33.0159Z' fill='#414859' />
      <path
        d='M32.7222 12.754H35.9524V15.9841H32.7222V12.754Z'
        fill='#414859'
      />
      <path
        d='M26.2619 26.2619V43H29.4921V33.0159H32.7222V36.246H35.9524V39.4762H32.7222V43H36.246V39.4762H39.4762V43H43V39.4762H39.4762V36.246H42.7064V26.2619H39.4762V29.4921H36.246V26.2619H26.2619Z'
        fill='#414859'
      />
    </svg>
  );
}
