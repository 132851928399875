export default function ImageIcon({ color = '#414859', ...props }) {
  return (
    <svg
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.5 41C5.5 41.8284 6.17157 42.5 7 42.5H27.0663H41C41.8284 42.5 42.5 41.8284 42.5 41V33.7783C42.3846 33.7129 42.2761 33.631 42.1779 33.5326L37.2999 28.6465L31.6358 35.3988C31.1034 36.0335 30.1573 36.1164 29.5226 35.584C29.431 35.5072 29.3509 35.4217 29.2825 35.3299C29.186 35.2696 29.0949 35.1974 29.0113 35.1131L17.4911 23.504L5.5 35.495V41ZM30.3775 32.2308L36.0545 25.4631C36.3249 25.1407 36.7184 24.9467 37.1388 24.9285C37.5592 24.9103 37.9679 25.0695 38.2653 25.3673L42.5 29.6091V26.4838V6.99997C42.5 6.17154 41.8284 5.49997 41 5.49997H7C6.17157 5.49997 5.5 6.17154 5.5 6.99997V31.2524L16.4345 20.3179C16.7165 20.0359 17.0992 19.8778 17.498 19.8786C17.8969 19.8793 18.279 20.0389 18.5599 20.322L30.3775 32.2308ZM2.5 34.8737V41C2.5 43.4853 4.51472 45.5 7 45.5H27.0663H41C43.4853 45.5 45.5 43.4852 45.5 41V26.4838V6.99997C45.5 4.51469 43.4853 2.49997 41 2.49997H7C4.51472 2.49997 2.5 4.51469 2.5 6.99997V34.8737ZM35.3916 15.8203C35.3916 17.9964 33.6348 19.7605 31.4676 19.7605C29.3005 19.7605 27.5437 17.9964 27.5437 15.8203C27.5437 13.6443 29.3005 11.8802 31.4676 11.8802C33.6348 11.8802 35.3916 13.6443 35.3916 15.8203Z'
        fill={color}
      />
    </svg>
  );
}
