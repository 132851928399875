import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { IntegrationCard } from '../components/FormIntegrations';
import useFilterTag from '../components/FormIntegrations/components/useFilterTag';

import styles from './styles.module.scss';
import useIntegrations from '../components/FormIntegrations/useIntegrations';
import { SearchIcon } from '../components/Icons';
import classNames from 'classnames';
import {
  AI_INTEGRATION_ORDER,
  AI_INTEGRATION_TAGS,
  AI_INTEGRATION_TYPES,
  AI_INTEGRATIONS
} from '../components/AIIntegrations/types';
import AINavigation from '../components/NavBar/AINavigation';
import InboxSettingsSidebar from '../components/AIIntegrations/InboxSettingsSidebar';
import DisabledIntegrationSidebar from '../components/AIIntegrations/DisabledIntegrationSidebar';

function AIIntegrationsPage() {
  const { extractionId } = useParams<{ extractionId: string }>();

  const [searchStr, setSearchStr] = useState('');
  const [visibleSidebar, setVisibleSidebar] = useState('');
  const { filterTag, filterPills } = useFilterTag(
    AI_INTEGRATIONS,
    Object.values(AI_INTEGRATION_TAGS)
  );

  const integrations = useIntegrations({
    all: true,
    extractionId,
    entityType: 'ai'
  });

  // Supporting passing a url param to preselect an integration/sidebar.  Needed for Stytch over quota CTA.
  const { search } = useLocation();
  useEffect(() => {
    const sidebar = new URLSearchParams(search).get('sidebar');
    sidebar && setVisibleSidebar(sidebar);
  }, [search]);

  let sidebarContent;
  switch (visibleSidebar) {
    case AI_INTEGRATION_TYPES.S3:
    case AI_INTEGRATION_TYPES.GOOGLE_CLOUD_STORAGE:
    case AI_INTEGRATION_TYPES.GOOGLE_DRIVE:
    case AI_INTEGRATION_TYPES.DROPBOX:
    case AI_INTEGRATION_TYPES.BOX:
    case AI_INTEGRATION_TYPES.EGNYTE:
    case AI_INTEGRATION_TYPES.SALESFORCE:
    case AI_INTEGRATION_TYPES.HUBSPOT:
      sidebarContent = (
        <DisabledIntegrationSidebar integrationType={visibleSidebar} />
      );
      break;
    case AI_INTEGRATION_TYPES.EMAIL_INBOX:
      sidebarContent = <InboxSettingsSidebar />;
      break;
    default:
      sidebarContent = null;
  }

  return (
    <>
      <AINavigation activeItem='integrations' />
      <div className={styles.integrationsContainer}>
        <div className={styles.leftContainer}>
          <h1 className={classNames('page-head', styles.headerTitle)}>
            Integrations
          </h1>
          {filterPills}
        </div>
        <div className={styles.integrationsScrollContainer}>
          <div className={classNames('search-wrap', styles.searchAdjust)}>
            <SearchIcon className='search-icon' />
            <input
              type='text'
              className='inp list-view-search'
              value={searchStr}
              placeholder='Search'
              onChange={(e) => setSearchStr(e.target.value)}
            />
          </div>
          <div className={styles.integrationCardsContainer}>
            {renderIntegrations(
              AI_INTEGRATIONS.sort((a: any, b: any) => {
                const aInteg = integrations[a.key];
                const bInteg = integrations[b.key];
                if ((aInteg && bInteg) || (!aInteg && !bInteg)) {
                  return AI_INTEGRATION_ORDER[a.key] >
                    AI_INTEGRATION_ORDER[b.key]
                    ? 1
                    : -1;
                } else return aInteg ? -1 : 1;
              }),
              extractionId,
              visibleSidebar,
              setVisibleSidebar,
              filterTag,
              searchStr
            )}
          </div>
        </div>
        {sidebarContent && (
          <div className={styles.sidebar}>
            <div className={styles.sidebarOverflow}>{sidebarContent}</div>
          </div>
        )}
      </div>
    </>
  );
}

const renderIntegrations = (
  integrations: typeof AI_INTEGRATIONS,
  formId: string,
  visibleSidebar: any,
  setVisibleSidebar: any,
  filterTag: string,
  searchStr: string
) => {
  return integrations
    .filter(
      (integ) =>
        (filterTag === 'All' || integ.tags.includes(filterTag)) &&
        (!searchStr ||
          integ.title.toLowerCase().includes(searchStr.toLowerCase()))
    )
    .map((integ) => (
      // eslint-disable-next-line react/jsx-key
      <IntegrationCard
        integrationType={integ.key}
        panelId={formId}
        isSelected={visibleSidebar === integ.key}
        onClick={() => setVisibleSidebar(integ.key)}
        {...integ}
      />
    ));
};

export default AIIntegrationsPage;
