import styles from './styles.module.css';
import classNames from 'classnames';

const Tag = ({ children, className }: any) => {
  return (
    <div className={classNames(className, styles.tagContainer)}>{children}</div>
  );
};

export default Tag;
