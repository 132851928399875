import {
  CheckboxField,
  DropdownField,
  InlineTooltip,
  TextField
} from '../../../components/Core';
import Label from '../../../components/Dialog/Label';
import { usePermissionsConfig } from '../../../hooks/usePermissionsConfig';
import styles from '../styles.module.scss';

interface Props {
  permissions: ReturnType<typeof usePermissionsConfig>['permissions'];
  curRole?: 'admin' | 'viewer' | 'editor' | null;
  styles?: React.CSSProperties;
}

export function PermissionsToggles({
  permissions,
  curRole,
  styles: divStyles
}: Props) {
  return (
    <div style={{ width: '100%', ...(divStyles || {}) }}>
      {curRole === 'editor' && (
        <>
          <CheckboxField
            checked={Boolean(permissions.editFormResults)}
            text='Allow editing form results'
            onChange={(checked) => permissions.setEditFormResults(checked)}
            className={styles.permissionsToggle}
          />
          <CheckboxField
            checked={Boolean(permissions.publishForms)}
            text='Allow publishing forms'
            onChange={(checked) => permissions.setPublishForms(checked)}
            className={styles.permissionsToggle}
          />
          <CheckboxField
            checked={Boolean(permissions.deleteForms)}
            text='Allow deleting forms'
            onChange={(checked) => permissions.setDeleteForms(checked)}
            className={styles.permissionsToggle}
          />
          <CheckboxField
            checked={Boolean(permissions.editTheme)}
            text='Allow editing form themes'
            onChange={(checked) => permissions.setEditTheme(checked)}
            className={styles.permissionsToggle}
          />
          <CheckboxField
            checked={Boolean(permissions.editLinkedProperties)}
            text='Allow editing linked field properties'
            onChange={(checked) => permissions.setEditLinkedProperties(checked)}
            className={styles.permissionsToggle}
          />
          <CheckboxField
            checked={Boolean(permissions.editLogic)}
            text='Allow editing custom form logic'
            onChange={(checked) => permissions.setEditLogic(checked)}
            className={styles.permissionsToggle}
          />
        </>
      )}
      {curRole !== 'admin' && (
        <>
          <CheckboxField
            checked={Boolean(permissions.inviteCollaborators)}
            text='Can invite users to collaborate on forms'
            onChange={(checked) => permissions.setInviteCollaborators(checked)}
            className={styles.permissionsToggle}
          />
          <CheckboxField
            checked={Boolean(permissions.editCollaboratorTemplate)}
            text={
              <>
                Can edit collaborator invitation template{' '}
                <InlineTooltip
                  text='When inviting users to fill out the form, you may specify multiple users to work on a
                        single form submission and define which fields each collaborator can edit.'
                  inline
                />
              </>
            }
            onChange={(checked) =>
              permissions.setEditCollaboratorTemplate(checked)
            }
            className={styles.permissionsToggle}
          />
        </>
      )}
      {curRole === 'viewer' && (
        <>
          <CheckboxField
            checked={Boolean(permissions.filterPermission)}
            text='Only see form results with a specific field value'
            onChange={(checked) =>
              permissions.setFilterPermission(
                checked
                  ? { field_type: 'servar', field_id: '', field_value: '' }
                  : null
              )
            }
            className={styles.permissionsToggle}
          />
          {permissions.filterPermission && (
            <>
              <Label>Field Type</Label>
              <DropdownField
                onChange={(event: any) =>
                  permissions.setFilterPermission({
                    ...(permissions.filterPermission as any),
                    field_type: event.target.value as string
                  })
                }
                selected={permissions.filterPermission.field_type}
                options={[
                  { value: 'servar', display: 'Form Field' },
                  { value: 'hidden', display: 'Hidden Field' }
                ]}
              />
              <Label>Field ID</Label>
              <TextField
                value={permissions.filterPermission.field_id}
                onComplete={(val: any) =>
                  permissions.setFilterPermission({
                    ...(permissions.filterPermission as any),
                    field_id: val as string
                  })
                }
              />
              <Label>Only show results with this value</Label>
              <TextField
                value={permissions.filterPermission.field_value}
                onComplete={(val: any) =>
                  permissions.setFilterPermission({
                    ...(permissions.filterPermission as any),
                    field_value: val as string
                  })
                }
              />
            </>
          )}
        </>
      )}
    </div>
  );
}
