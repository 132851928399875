import classNames from 'classnames';
import styles from './styles.module.scss';

export const HTTPMethodBadge = ({ method }: { method: string }) => {
  const color = method ? 'primary' : 'secondary';

  return (
    <div
      className={classNames(styles.httpMethodBadge, {
        [styles.primary]: color === 'primary',
        [styles.secondary]: color === 'secondary'
      })}
    >
      {method ? <>{method}</> : <>None</>}
    </div>
  );
};
