import { openTab } from '../../../utils/domOperations';
import styles from './styles.module.scss';

export default function HelpLink({ title, url, icon }: any) {
  return (
    <div onClick={() => openTab(url)} className={styles.helpLinkSection}>
      <div className={styles.iconContainer}>{icon}</div>
      <div>
        <div className={styles.title}>{title}</div>
        <div className={styles.description}>Feathery Docs</div>
      </div>
    </div>
  );
}
