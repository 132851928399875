export default function HorizontalLeftIcon({ width = 16, height = 16 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4 3C4 2.44772 3.55228 2 3 2C2.44772 2 2 2.44772 2 3H4ZM2 13C2 13.5523 2.44772 14 3 14C3.55228 14 4 13.5523 4 13H2ZM2 3V13H4V3H2Z'
        fill='#414859'
      />
      <rect
        x='5.76599'
        y='5.91666'
        width='7.23404'
        height='4.16667'
        rx='1'
        stroke='#414859'
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  );
}
