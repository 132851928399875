function EMoney({ width = 100, height = 100 }) {
  return (
    <svg
      width={width}
      height={height}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 652 652'
      enableBackground='new 0 0 0 0 652 652'
      xmlSpace='preserve'
    >
      <path
        fill='#072457'
        d='M86.7,280c0-3.9-1.8-7.5-6.4-7.5c-19.5,0-33.7,33.9-38.6,48.3C61.2,315.2,86.7,303.5,86.7,280 M115.6,319.1  C102.3,340.8,81.9,362,54.5,362c-16.7,0-28.3-10.7-28.3-27.5c0-27.5,26.5-65.5,56.3-65.5c8.2,0,13.1,3.9,13.1,12.3  c0,25.4-34.4,38-54.7,42.6c-1.5,5.1-2.6,10.9-2.6,16.3c0,10.7,4.9,18.7,16.4,18.7c26.2,0,45.3-21.4,58.3-41.6L115.6,319.1z'
      />
      <path
        fill='#072457'
        d='M222.7,362V260.9h-0.6l-40.5,101.2h-10.6l-40.8-101.2H130v101.2h-17.5V242.2h27.7l36.2,91.8l36.3-91.8h27.7V362  H222.7z M298.7,284.7c17.5,0,31.5,14,31.5,31.3c0,17.8-14.3,31.7-31.9,31.7c-17,0-30.9-14.8-30.9-31.5  C267.4,298.8,282,284.7,298.7,284.7 M298.2,362.7c28.5,0,48.5-22.1,48.7-45.9c0.4-28.1-22.8-47.3-47.5-47.3  c-27.3,0-48.7,19.9-48.7,47C250.7,341.4,273.1,362.7,298.2,362.7 M438.5,363v-54.5c0-25-16.1-38.9-38.1-38.9  c-10.6,0-20,3.5-26.7,12.2h-0.3v-9.7h-16v90.7h17.1v-49.6c0-17,9.1-28.1,24.1-28.1c15.3,0,22.8,9,22.8,28.2v49.5L438.5,363z   M465.7,311c1.4-15.2,15-25.9,28.7-25.9c14.3,0,27.7,10.9,29,25.9H465.7z M539.8,323.5c0.6-3,0.6-5.7,0.6-8.3  c0-25.3-20-45.5-46.2-45.5c-23.8,0-45,19.6-45,47.6c0,23.6,20.3,45.8,45.4,45.8c19.1,0,35.8-11.7,42.7-30h-16.7  c-5.1,9.5-15,14.9-25.5,14.9c-13.6,0-25.3-9.5-29.2-24.2L539.8,323.5z M571.8,409.6l54.4-139.3h-18L581.4,339l-26.2-68.7h-18  l35.7,86.8l-18.4,52.4H571.8z'
      />
    </svg>
  );
}

export default EMoney;
