import { useState } from 'react';
import { useRuleBuilderUpdater } from '../../../context';
import { DropdownField, TextField } from '../../../../../../../components/Core';
import { RuleOperand } from '../../../context/RuleDSL';
import { RuleOperand as Operand } from '../../RuleOperand';
import ruleStyles from '../../../../../../../components/NavigationRules/styles.module.scss';
import comparisonRuleStyles from '../../../../../../../components/Modals/DecisionLogicModal/styles.module.scss';
import styles from '../styles.module.scss';
import classNames from 'classnames';

type OpenURLActionProps = {
  action: IRuleAction;
  mode?: RuleBuilderMode;
};

const URL_REGEX = /^(https?|ftp):\/\/(-\.)?([^\s/?\\.#-]+\.?)+(\/[^\s]*)?$/i;
const TARGET_DISPLAYS: any = {
  _blank: 'New Tab',
  _self: 'Same Tab',
  _new: 'New Window'
};

const EditMode = ({ action }: OpenURLActionProps) => {
  const [validationError, setValidationError] = useState<string | null>(null);
  const { updateAction, updateOperand } = useRuleBuilderUpdater((s) => ({
    updateAction: s.updateAction,
    updateOperand: s.updateOperand
  }));

  const { parameters } = action;
  const [url, target] = parameters as DualRuleOperandsParametersTuple;

  const handleUrlChange = (newValue: string) => {
    if (newValue && !URL_REGEX.test(newValue)) {
      setValidationError('Invalid URL');
      return;
    } else {
      setValidationError(null);
    }

    if (url) {
      updateOperand(url.id, {
        type: 'value',
        value: newValue
      });
    } else {
      const operand = new RuleOperand();

      operand.type = 'value';
      operand.value = newValue;

      updateAction(action.id, {
        parameters: [operand.toJSON()]
      });
    }
  };

  const handleTargetChange = (newValue: string) => {
    if (target) {
      updateOperand(target.id, {
        type: 'value',
        value: newValue,
        meta: {
          ...target.meta,
          display: TARGET_DISPLAYS[newValue]
        }
      });
    } else {
      const operand = new RuleOperand();

      operand.type = 'value';
      operand.value = newValue;
      operand.meta = {
        display: TARGET_DISPLAYS[newValue]
      };

      updateAction(action.id, {
        parameters: [...action.parameters, operand.toJSON()]
      });
    }
  };

  return (
    <>
      <TextField
        className={classNames(
          ruleStyles.ruleTextField,
          comparisonRuleStyles.valueInput
        )}
        value={url?.value ?? ''}
        title={url?.value ?? ''}
        placeholder='URL address...'
        onComplete={handleUrlChange}
        error={!!validationError}
        errorMessage={validationError}
        triggerCleanUp
      />
      <DropdownField
        className={classNames(ruleStyles.ruleTextField, ruleStyles.valueInput)}
        selected={target?.value ?? '_blank'}
        title={target?.value ?? '_blank'}
        options={[
          { display: 'New Tab', value: '_blank' },
          { display: 'Same Tab', value: '_self' },
          { display: 'New Window', value: '_new' }
        ]}
        onChange={(event: any) => handleTargetChange(event.target.value)}
        triggerCleanUp
      />
    </>
  );
};

const ViewMode = ({ action }: OpenURLActionProps) => {
  const [url, target] = action.parameters as DualRuleOperandsParametersTuple;

  return (
    <>
      <Operand operand={url} bold />
      <span>in</span>
      <Operand operand={target} displayField='display' />
    </>
  );
};

export const OpenURLAction = ({
  action,
  mode = 'view'
}: OpenURLActionProps) => {
  return (
    <div className={styles.actionContent}>
      {mode === 'view' ? (
        <ViewMode action={action} />
      ) : (
        <EditMode action={action} />
      )}
    </div>
  );
};
