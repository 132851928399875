/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import useFeatheryRedux from '../../redux';
import Table from '../../components/Table';
import FormNavigation from '../../components/NavBar/FormNavigation';
import { fieldTypeNameMap } from '../../utils/elements';

import styles from './styles.module.scss';
import { sortStepElements } from '../../utils/step';
import useDraftForm from '../../utils/useDraftForm';
import { recurseSteps } from '../../components/ReactFlow/graph';
import { isDefined, mapArrayToObject } from '../../utils/core';
import { Button, Card, PropertyLabel } from '../../components/Core';
import { Button as ButtonComponent } from '../../components/Core/Button/button';
import CopyableText from '../../components/Core/CopyableText';
import { capitalizeFirstLetter, formatDate } from '../../utils/format';
import { LinkText, openTab } from '../../utils/domOperations';
import { useAppSelector } from '../../hooks';
import EditableValue from './components/EditableValue';
import { renderFile } from './utils';
import { FILE_FIELDS } from '../../components/SelectionPanel/elementEntries';
import EnvelopeTagsModal from './components/EnvelopeTagsModal';
import {
  CollaborationTaskDetailsModal,
  DownloadFormPdfModal
} from '../../components/Modals';
import { formatKFNData, kfnColumns, kfnFieldIds } from './kfn';
import useGetJwt from '../../utils/useGetJwt';
import { parseAndThrowError } from '../../redux/utils';
import { downloadFile, nameFromUrl } from '../../utils/browserUtils';
import { useHostedURL } from '../../components/Core/Button/ShareButton/ShareFormOverlay';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger
} from '../../components/Core/Tabs';
import { Tooltip } from '../../components/Core/Tooltip/Tooltip';

const documentColumns = [
  {
    key: 'document',
    name: 'Document',
    noSort: true,
    renderCell: ({ name, url }: any) => {
      return <LinkText text={name} link={url} />;
    }
  },
  {
    key: 'tags',
    name: 'Tags',
    noSort: true,
    renderCell: ({ tags }: any) => {
      return (
        <div className={styles.envelopeTagContainer}>
          {tags.map((tag: string) => (
            <div className={styles.envelopeTag} key={tag}>
              {tag}
            </div>
          ))}
        </div>
      );
    }
  },
  {
    key: 'updated_at',
    name: 'Last Updated'
  }
];

const hiddenFieldColumns = [
  { key: 'key', name: 'Field' },
  {
    key: 'value',
    name: 'Value',
    noSort: true,
    renderCell: ({ value, type, hidden_field: id, key }: any) => {
      const isEditable = type === 'json' && !key.startsWith('feathery.');

      if ([null, undefined].includes(value)) {
        return isEditable ? (
          <EditableValue value='' fieldId={id} hidden />
        ) : null;
      }

      if (type === 'file_value') {
        const name = nameFromUrl(value);
        return <LinkText text={name} link={value} />;
      } else if (typeof value === 'object') {
        return JSON.stringify(value, null, 2);
      } else {
        return isEditable ? (
          <EditableValue value={value} hidden fieldId={id} />
        ) : (
          value
        );
      }
    }
  },
  { key: 'type', name: 'Type' }
];

export default function FormResultsDetailPage({ global = false }: any) {
  // eslint-disable-next-line prefer-const
  let { formId, userId } = useParams<{ formId?: string; userId: string }>();
  const history = useHistory();
  const { deleteUser, getUserDetail } = useFeatheryRedux();

  const [activeTab, setActiveTab] = useState<null | string>('formFields');
  const [tryFetch, setTryFetch] = useState(true);
  const [envFilterTags, setEnvFilterTags] = useState<string[]>([]);
  const [showEnvelopeFilter, setShowEnvelopeFilter] = useState(false);
  const [downloadingPDF, setDownloadingPDF] = useState(false);

  const org = useAppSelector((state) => state.accounts.organization);
  const account = useAppSelector((state) => state.accounts.account);
  const panel = useAppSelector((state) =>
    formId ? state.panels.panels[formId] : null
  );

  const getHostedUrl = useHostedURL(org, panel);

  const userState = useAppSelector((state) =>
    state.users.users.find((u: any) => u.id === userId)
  ) as any;

  const { steps } = useDraftForm(true, global);
  const recurseData = recurseSteps(steps);
  const orderedSteps = recurseData[0];
  const floatingSteps = recurseData[2];
  let doesDataHaveNameColumn = false;

  useEffect(() => {
    (async () => {
      if (tryFetch) {
        setTryFetch(false);
        await getUserDetail({ userId, panel: formId });
      }
    })();
  }, [setTryFetch]);

  const servarData = useMemo(() => {
    const data = userState?.servar_values;
    if (
      !data ||
      (!global && orderedSteps.length === 0 && floatingSteps.length === 0)
    )
      return [];

    let orderedData: any[];
    if (global)
      orderedData = data.filter(
        (entry: any) => !['', null, undefined].includes(entry.value)
      );
    else {
      const labelServarsForSingleStep = (step: any) => {
        const servars = step.servar_fields;
        const orderedServars = servars.sort(sortStepElements);
        return {
          orderedServars,
          stepName: step.key
        };
      };
      // There can be multiple steps at the same depth
      const processSteps = (steps: any) => steps.map(labelServarsForSingleStep);
      const orderedStepsAndElements = [
        ...orderedSteps.map(processSteps).flat(),
        ...floatingSteps.map(labelServarsForSingleStep)
      ];

      const dataByKey = mapArrayToObject(data, 'key');
      orderedData = orderedStepsAndElements
        .map(({ orderedServars, stepName }) =>
          orderedServars.map(({ servar }: any) => {
            const servarData = dataByKey[servar.key];
            // Only show each servar once
            dataByKey[servar.key] = undefined;
            return !servarData
              ? undefined
              : {
                  ...servarData,
                  stepName
                };
          })
        )
        .flat()
        // filter out servars that weren't part of the user's data (from
        // branches not taken)
        .filter(isDefined);
    }

    return orderedData.map((d) => {
      let value = d.value;
      let urls;
      if (FILE_FIELDS.includes(d.type)) {
        value = value ?? '';
        // if single file, still treat as an array so render logic can use [].map
        urls = typeof value === 'string' ? [value] : value;
        value = urls.map((url: string) => nameFromUrl(url));
      } else if (d.type === 'url') {
        value = value ?? '';
        urls = typeof value === 'string' ? [value] : value;
      } else if (d.type === 'password') {
        if (!value) value = '';
        else if (typeof value === 'string') value = '*'.repeat(value.length);
        else value = value.map((val: string) => '*'.repeat(val.length));
      } else if (Array.isArray(value)) {
        value = value.map((val) => {
          // 2D array can happen for repeating multiselect value
          if (Array.isArray(val)) val = val.join(', ');
          return <div key={val}>{val.toString() || '--'}</div>;
        });
      }
      if (!doesDataHaveNameColumn && d.name) {
        doesDataHaveNameColumn = true;
      }
      return {
        ...d,
        name: d.name || '',
        urls,
        value,
        type: fieldTypeNameMap[d.type]
      };
    });
  }, [userState?.servar_values, orderedSteps]);

  const servarColumns = [
    { key: 'key', name: 'Field' },
    ...(global ? [] : [{ key: 'stepName', name: 'Step' }]),
    {
      key: 'value',
      name: 'Value',
      noSort: true,
      renderCell: ({ value, urls, type, id }: any) => {
        if ([null, undefined].includes(value)) {
          return ['text_field', 'select', 'dropdown'].some(
            (comp) => type === fieldTypeNameMap[comp]
          ) ? (
            <EditableValue value='' fieldId={id} />
          ) : null;
        }

        if (!urls) {
          if (typeof value === 'object' && !Array.isArray(value))
            return JSON.stringify(value, null, 2);
          else if (
            typeof value === 'string' &&
            type !== fieldTypeNameMap.password
          ) {
            return <EditableValue value={value} fieldId={id} />;
          } else if (typeof value === 'boolean')
            return capitalizeFirstLetter(value.toString());
          else return value;
        } else if (type === fieldTypeNameMap.url) {
          return urls.map((url: any) => (
            <LinkText key={url} text={url} link={url} />
          ));
        } else {
          return renderFile(value, urls);
        }
      }
    },
    { key: 'type', name: 'Type' },
    doesDataHaveNameColumn ? { key: 'name', name: 'Display' } : undefined
  ].filter(isDefined);

  const envelopeData = useMemo(() => {
    return (userState?.envelopes ?? []).filter((envelope: any) =>
      envFilterTags.every((tag: string) => envelope.tags.includes(tag))
    );
  }, [userState?.envelopes, envFilterTags]);

  const collaborators = userState?.collaborators ?? [];
  let pendingCollaborator: any = null,
    minIndex: any = null;
  collaborators.forEach((collab: any) => {
    if (collab.completed_at) return;
    if (minIndex === null || collab.template_index < minIndex) {
      minIndex = collab.template_index;
      pendingCollaborator = collab;
    }
  });
  let openCTA = 'Open Form Submission';
  if (collaborators.length > 0) {
    openCTA = pendingCollaborator
      ? 'Open Collaborative Submission'
      : 'Submission Completed';
  }
  const submissionActionDisabled =
    collaborators.length > 0 &&
    (!pendingCollaborator ||
      // if not an admin then only enable if the user is one of the pending invitees
      (account.role !== 'admin' &&
        // search is case insensitive!
        !pendingCollaborator.invitees.find(
          (email: string) => email.toLowerCase() === account.email.toLowerCase()
        )));

  const SubmissionActionButton = () => (
    <ButtonComponent
      variant='ghost-primary'
      onClick={() => {
        let link = getHostedUrl();
        const separator = link.includes('?') ? '&' : '?';
        link = `${link}${separator}_id=${userState?.key}`;
        if (pendingCollaborator) {
          link = `${link}&_cid=${pendingCollaborator.user_id}`;
        }
        openTab(link);
      }}
      disabled={submissionActionDisabled}
    >
      {openCTA}
    </ButtonComponent>
  );

  // collaboration task details
  const [selectedCollaborator, setSelectedCollaborator] = useState<any>(null);
  const [showCollabTaskDetails, setShowCollabTaskDetails] = useState(false);

  const TaskDetailsButton = ({
    collaborator,
    label = 'Details'
  }: {
    collaborator: any;
    label?: string;
  }) => {
    return (
      <div
        className={styles.taskDetailsMore}
        onClick={() => {
          setSelectedCollaborator(collaborator);
          setShowCollabTaskDetails(true);
        }}
      >
        {label}
      </div>
    );
  };

  const collorationHistoryData = useMemo(() => {
    return collaborators
      .map((collaborator: any) => ({
        ...collaborator,
        collaborator_index: collaborator.template_index + 1,
        completed_at_pretty: collaborator.completed_at
          ? formatDate(collaborator.completed_at)
          : '',
        invited_at_pretty: collaborator.invited_at
          ? formatDate(collaborator.invited_at)
          : ''
      }))
      .sort((a: any, b: any) => a.template_index - b.template_index);
  }, [collaborators]);

  // Collaboration history columns
  const collaborationHistoryColumns = [
    { key: 'template_label', name: 'Collaboration Role' },
    {
      key: 'completed_by',
      name: 'Collaborator',
      renderCell: (collaborator: any) => {
        if (collaborator.completed_by)
          return <div>{collaborator.completed_by}</div>;
        const collaboratorEmail = collaborator.invitees[0];
        const moreEmails = collaborator.invitees.length - 1;
        const moreEmailsLabel = `+ ${moreEmails} more`;
        return (
          <div className={styles.collaboratorEmails}>
            {collaboratorEmail}
            {moreEmails > 0 && (
              <div className={styles.moreEmails}>
                <TaskDetailsButton
                  collaborator={collaborator}
                  label={moreEmailsLabel}
                />
              </div>
            )}
          </div>
        );
      }
    },
    { key: 'collaborator_index', name: 'Collab Number' },
    { key: 'status', name: 'Status' },
    { key: 'completed_at_pretty', sortBy: 'completed_at', name: 'Completed' },
    { key: 'invited_at_pretty', sortBy: 'invited_at', name: 'Invited' },
    {
      key: 'invitees',
      name: '',
      noSort: true,
      renderCell: (collaborator: any) => (
        <TaskDetailsButton collaborator={collaborator} />
      )
    }
  ];

  const getJwt = useGetJwt();
  const {
    toasts: { addErrorToast }
  } = useFeatheryRedux();
  const downloadLog = async (envelopeId: string) => {
    const token = getJwt();
    await FeatheryAPI.downloadEnvelopeLog(token, envelopeId)
      .then(async (res: any) => {
        if (res.status === 200) {
          const blob = await res.blob();
          downloadFile('signature_log.pdf', blob);
        } else {
          const result = await res.json();
          parseAndThrowError(result, 'Your download could not be started');
        }
      })
      .catch((e: any) => {
        addErrorToast({ text: e.toString() });
      });
  };

  return (
    <>
      {!global && <FormNavigation activeItem='results' />}
      <div style={{ paddingLeft: '50px', paddingRight: '50px' }}>
        <div className={styles.backContainer}>
          <Button.Back
            onClick={() =>
              history.push(global ? '/users' : `/forms/${formId}/results`)
            }
          />
          <h3 className={styles.userDetailSettingsHeader}>
            {global ? 'User' : 'Submission'}
          </h3>
        </div>
        <Card className={styles.userDetailOverview}>
          <div className={styles.userDetailInfo}>
            <div className={styles.col}>
              <div className={styles.row}>
                <PropertyLabel label='User ID' />
                <CopyableText text={userState?.key} />
              </div>
              <div className={styles.dateText}>
                {formatDate(userState?.updated_at)}
              </div>
            </div>
            <div className={styles.separator} />
            {!global && (
              <>
                <div className='tw-px-[25px] tw-py-[20px] tw-flex tw-items-center tw-justify-center'>
                  {submissionActionDisabled && pendingCollaborator ? (
                    <Tooltip content='You are not part of this collaboration.'>
                      <div className={styles.overlayContainer}>
                        <SubmissionActionButton />
                      </div>
                    </Tooltip>
                  ) : (
                    <SubmissionActionButton />
                  )}
                </div>
                <div className={styles.separator} />
              </>
            )}
            {!global && (
              <>
                <div className='tw-px-[25px] tw-py-[20px] tw-flex tw-items-center tw-justify-center'>
                  <ButtonComponent
                    disabled={downloadingPDF}
                    variant='ghost-primary'
                    onClick={() => setDownloadingPDF(true)}
                  >
                    Export to PDF
                  </ButtonComponent>
                </div>
                <div className={styles.separator} />
              </>
            )}
          </div>
          {account.role !== 'viewer' && (
            <ButtonComponent
              variant='ghost-primary'
              onClick={() => {
                deleteUser({ userId }).then(() => {
                  history.push(global ? '/users' : `/forms/${formId}/results`);
                });
              }}
            >
              {global ? 'Delete User' : 'Delete Submission'}
            </ButtonComponent>
          )}
        </Card>
        <EnvelopeTagsModal
          tags={envFilterTags}
          updateTags={setEnvFilterTags}
          envelopes={userState?.envelopes}
          show={showEnvelopeFilter}
          close={() => setShowEnvelopeFilter(false)}
        />
        {!global && (
          <DownloadFormPdfModal
            panel={panel}
            fuserId={userId}
            show={downloadingPDF}
            close={() => setDownloadingPDF(false)}
          />
        )}
        <div className={styles.tabContainer}>
          {activeTab === 'documents' && (
            <div className='tw-absolute tw-top-0 tw-right-4 tw-h-[50px] tw-flex tw-items-center'>
              <ButtonComponent
                variant='ghost-primary'
                onClick={() => setShowEnvelopeFilter(true)}
              >
                Filter
              </ButtonComponent>
            </div>
          )}
          <Tabs
            value={activeTab as any}
            onValueChange={(tab: string | null) => setActiveTab(tab)}
          >
            <TabsList unstyled className='legacyTabsList'>
              <TabsTrigger
                unstyled
                className='legacyTabsTrigger'
                value='formFields'
              >
                Form Fields
              </TabsTrigger>
              <TabsTrigger
                unstyled
                className='legacyTabsTrigger'
                value='hiddenFields'
              >
                Hidden Fields
              </TabsTrigger>
              <TabsTrigger
                unstyled
                className='legacyTabsTrigger'
                value='documents'
              >
                Documents
              </TabsTrigger>
              {collaborators.length > 0 && (
                <TabsTrigger
                  unstyled
                  className='legacyTabsTrigger'
                  value='collaborators'
                >
                  Collaboration History
                </TabsTrigger>
              )}
              {org?.id === 24751 && (
                <TabsTrigger
                  unstyled
                  className='legacyTabsTrigger'
                  value='accounts'
                >
                  Accounts
                </TabsTrigger>
              )}
            </TabsList>
            <TabsContent value='formFields'>
              <Table
                name='Result'
                data={servarData}
                columns={servarColumns}
                type='fullPageNoMargin'
              />
            </TabsContent>
            <TabsContent value='hiddenFields'>
              <Table
                name='Result'
                data={userState?.hidden_field_values ?? []}
                columns={hiddenFieldColumns}
                type='fullPageNoMargin'
              />
            </TabsContent>
            <TabsContent value='documents'>
              <Table
                name='Document'
                data={envelopeData}
                columns={documentColumns}
                type='fullPageNoMargin'
                onDownload={(doc: any) => downloadLog(doc.id)}
                downloadName='Audit Log'
              />
            </TabsContent>
            {collaborators.length > 0 && (
              <TabsContent
                value='collaborators'
                title='Collaboration History'
                className={styles.fieldTab}
              >
                <Table
                  name='Collaboration History'
                  data={collorationHistoryData}
                  columns={collaborationHistoryColumns}
                  type='fullPageNoMargin'
                />
              </TabsContent>
            )}
            {/* KFN Only */}
            {org?.id === 24751 && (
              <TabsContent value='accounts'>
                <Table
                  name='Accounts'
                  data={formatKFNData(servarData, kfnFieldIds)}
                  columns={kfnColumns}
                  type='fullPageNoMargin'
                />
              </TabsContent>
            )}
          </Tabs>
        </div>
      </div>
      {selectedCollaborator && (
        <CollaborationTaskDetailsModal
          collaborator={selectedCollaborator}
          show={showCollabTaskDetails}
          close={() => setShowCollabTaskDetails(false)}
        />
      )}
    </>
  );
}
