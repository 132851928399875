import { useMemo } from 'react';
import { useRuleBuilderState, useRuleBuilderUpdater } from '../../context';
import { ACTION_COMPONENTS } from './constants';
import { RuleActionSelector } from '../RuleActionSelector';
import { CloseIcon, ErrorMarkerIcon } from '../../../../../../components/Icons';
import { initializeAction } from './utils';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { Tooltip } from '../../../../../../components/Core/Tooltip/Tooltip';

type RuleActionProps = {
  action: IRuleAction;
  mode?: RuleBuilderMode;
  allowRemove?: boolean;
  conditional?: boolean;
};

export const RuleAction = ({
  action,
  mode = 'view',
  allowRemove = true,
  conditional = false
}: RuleActionProps) => {
  const errors = useRuleBuilderState((s) => s.errors);
  const error = errors[action.id]?.message;
  const isValid = !error;

  const { updateAction, removeAction } = useRuleBuilderUpdater((s) => ({
    updateAction: s.updateAction,
    removeAction: s.removeAction
  }));

  const [ActionComponent, actionHasMenu] = useMemo(() => {
    const { name } = action;

    if (ACTION_COMPONENTS[name]) {
      return [ACTION_COMPONENTS[name].action, ACTION_COMPONENTS[name].hasMenu];
    }

    return [null, false];
  }, [action]);

  const handleActionChange = (actionName: string) => {
    updateAction(action.id, initializeAction(actionName));
  };

  if (!ActionComponent) {
    return null;
  }

  return (
    <div
      className={classNames(styles.actionContainer, {
        [styles.viewMode]: mode === 'view',
        [styles.editMode]: mode === 'edit',
        [styles.loneAction]: !conditional,
        [styles.invalid]: !isValid
      })}
    >
      {mode === 'edit' && !isValid && (
        <Tooltip content={error}>
          <div className={styles.actionErrorIcon}>
            <ErrorMarkerIcon width={16} height={16} />
          </div>
        </Tooltip>
      )}
      <div className={styles.actionSelector}>
        <RuleActionSelector
          action={action.name}
          onChange={handleActionChange}
          readonly={mode === 'view'}
        />
      </div>
      <ActionComponent
        action={action}
        mode={mode}
        removeAction={allowRemove ? removeAction : undefined}
      />
      {mode === 'edit' && (
        <div className={styles.actionActions}>
          {allowRemove && !actionHasMenu && (
            <CloseIcon
              width={18}
              height={18}
              onClick={() => removeAction(action.id)}
            />
          )}
        </div>
      )}
    </div>
  );
};
