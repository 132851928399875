import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAppSelector } from '../hooks';
import useMounted from './useMounted';
import useFeatheryRedux from '../redux';
import { useHistory, useParams } from 'react-router-dom';
import { getChangedServarsForStep } from './step';
import { API_URL, clientIdentifier, encodeGetParams } from '../api/utils';
import { objectDeltas } from './core';
import { DRAFT_STATUS, PUBLISH_STATUS } from '../redux/utils';
import debounce from 'lodash.debounce';
import { produce } from 'immer';
import { repeatConfig } from './domOperations';
import { sentryError } from './runtime';
import { cleanupStep } from '../components/RenderingEngine/GridInGrid/engine';
import { LogicRule } from '../pages/FormLogicPage/LogicRuleList';
import { PRIVATE_FUNCTIONS } from '../pages/LogicRuleDetail/components/RuleBuilder/transform';
import { PRIVATE_ACTIONS } from '../pages/LogicRuleDetail/components/RuleBuilder/components/RuleAction/constants';

type TimestampIndex = 'steps' | 'logic_rules' | 'theme';
type TimestampData = {
  timestamp: string;
  steps: Record<string, { timestamp: string }>;
  logic_rules: Record<string, { timestamp: string }>;
  theme: Record<string, { timestamp: string }>;
};
const DEFAULT_TIMESTAMPS = {
  timestamp: '',
  steps: {},
  logic_rules: {},
  theme: {}
};

export default function useDraftOps(
  _autosave = false,
  onVersionExpire: null | (() => void) = null,
  loadFields = false,
  draftSteps: Record<string, any> | null = null,
  loadedFormId = '',
  setReloadForm = (r: any) => {}
) {
  const { formId } = useParams<{ formId: string }>();
  const formKey = useAppSelector((state) => state.panels.panels[formId]?.key);
  const sdkKey = useAppSelector((state) => {
    const org = state.accounts.organization;
    return org ? org.environments.find((env: any) => env.primary).sdk_key : '';
  });

  const runTask = useSequentialTaskRunner();

  const mounted = useMounted();
  const history = useHistory();
  const [autosave, setAutosave] = useState(_autosave);

  const {
    deleteDraft,
    getDraft,
    getFields,
    getPanels,
    getPanelThemeAssetUse,
    getTheme,
    publishForm,
    updateDraft,
    formBuilder: {
      clearUndoRedoStacks,
      clearUnsavedChanges,
      setChangedServars,
      setDraftStatus,
      setFlowPublishStatus,
      setServarCache,
      setTheme
    },
    toasts: { addErrorToast, hideToast, removeToast }
  } = useFeatheryRedux();

  const {
    workingSteps,
    changedSteps,
    workingLogicRules,
    changedLogicRules,
    theme,
    themeBaseline,
    themePublishStatus,
    flowPublishStatus,
    draftStatus,
    oldServars
  } = useAppSelector((state) => state.formBuilder);

  const formVersion = useRef<null | string>(null);
  const draftTimestamp = useRef<null | TimestampData>(null); // timestamps by type and id

  const changedServars = useAppSelector(
    (state) => state.formBuilder.changedServars
  );
  const servarUsage = useAppSelector((state) => state.formBuilder.servarUsage);

  const themes = useAppSelector((state) => state.themes).themes;
  const panelsData = useAppSelector((state) => state.panels.panels);
  const panel = panelsData[formId];

  const refreshCache = () => {
    // All of these backend resources may also have been updated on publish
    // and need to be refreshed (or for some other reason)
    getPanelThemeAssetUse({ cached: false });
    getPanels(); // force update of last edited (i.e. published time/date)
    getFields().then(() => setShouldLoadFields(true));
    getTheme({ themeId: panel.theme });
  };
  const reloadDraftDeletion = () => {
    history.replace(`/forms/${formId}/`);
    clearUndoRedoStacks();
    setReloadForm((reload: any) => ({ ...reload })); // cause a form load
    setTheme({
      theme: themes[panel.theme],
      status: PUBLISH_STATUS.FULFILLED,
      flowStatus: PUBLISH_STATUS.FULFILLED,
      rebase: true
    });
    loadDraft(true);
  };

  const TOAST_TITLES = {
    CHANGES_NOT_SAVED: "Changes won't be saved",
    AUTOSAVE_ERROR: 'Autosave Error',
    NO_CONNECTION: 'No connection',
    PUBLISH_ERROR: 'Publish Error',
    OTHER_CHANGES: 'Someone else is editing this form.',
    PROMOTION_ERROR: "Changes won't be saved"
  };

  const TOAST_MESSAGES = {
    CHANGES_NOT_SAVED:
      "Someone has updated this form. Your work won't be saved until you refresh.",
    AUTOSAVE_ERROR:
      'Your recent change failed to save. Please refresh to resume from your last checkpoint.',
    NO_CONNECTION: 'Your work will not be saved until you re-connect.',
    PUBLISH_ERROR:
      'Your form failed to publish. Please refresh to resume from your last checkpoint.',
    OTHER_CHANGES:
      'You can keep editing, but may want to refresh to see all changes.'
  };

  function updateTimestamps(
    new_timestamps: TimestampData,
    deltas: Record<TimestampIndex, Record<string, { timestamp: string }>>
  ) {
    // only update the timestamps that have been updated
    const updated_timestamps: TimestampData = {
      ...(draftTimestamp.current ?? DEFAULT_TIMESTAMPS)
    };
    (Object.keys(deltas) as TimestampIndex[]).forEach((type) => {
      Object.keys(deltas[type]).forEach((item) => {
        // update timestamp for item
        updated_timestamps[type][item] = new_timestamps[type][item];
      });
    });
    updated_timestamps.timestamp = new_timestamps.timestamp;

    draftTimestamp.current = updated_timestamps;
  }

  function parseTimestamps(data: any): TimestampData {
    // removes all of the non-timestamp data from the response
    const transform = (
      value: any
    ): undefined | Record<string, { timestamp: string }> => {
      if (typeof value !== 'object') return undefined;
      return Object.entries(value).reduce(
        (transformData: Record<string, any>, [currentKey, currentValue]) => {
          transformData[currentKey] = {
            timestamp: (currentValue as any)?.timestamp
          };
          return transformData;
        },
        {}
      );
    };
    return Object.entries(data).reduce<TimestampData>(
      (
        timestampData: TimestampData,
        [currentKey, currentValue]: [string, unknown]
      ) => {
        const transformedData = transform(currentValue);
        if (transformedData) {
          timestampData[currentKey as TimestampIndex] = transformedData;
        }
        return timestampData;
      },
      { ...DEFAULT_TIMESTAMPS, timestamp: data.timestamp }
    );
  }

  const notifyUserWithToast = (
    title = TOAST_TITLES.CHANGES_NOT_SAVED,
    message = TOAST_MESSAGES.CHANGES_NOT_SAVED
  ) =>
    addErrorToast({
      title: title,
      body: (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            gap: '12px'
          }}
        >
          <span>{message}</span>
          <span
            style={{ color: 'var(--prim-100)', cursor: 'pointer' }}
            onClick={() => location.reload()}
          >
            REFRESH
          </span>
        </div>
      ),
      autohide: false
    });

  //
  // Toast message cleanup
  //
  const allToasts = useAppSelector((state) =>
    Object.entries(state.toasts)
      .filter(([id, toast]: [any, any]) =>
        Object.values(TOAST_TITLES).includes(toast.title)
      )
      .map(([id, toast]) => id)
  );
  const clearToasts = () => {
    allToasts.forEach((id: string) => hideToast({ id }));
    setTimeout(
      () => allToasts.forEach((id: string) => removeToast({ id })),
      500
    );
  };
  useEffect(() => {
    // toast cleanup on unmount
    return () => {
      if (!mounted.current) clearToasts();
    };
  });

  /// /////////////////////////////////////////////////////////////////////////////
  //
  //  Detecting no-connection state
  //
  /// /////////////////////////////////////////////////////////////////////////////
  const noConnectionToastId: any = useAppSelector((state) => {
    const toastEntry = Object.entries(state.toasts).find(
      ([id, toast]: [any, any]) =>
        toast.title === TOAST_TITLES.NO_CONNECTION && toast.show
    );
    return toastEntry ? toastEntry[0] : null;
  });

  const loadDraft = (forceLoad = false) =>
    runTask(async () => {
      await getDraft({
        panelId: formId,
        forceLoad: forceLoad
      }).then((draftJson: any) => {
        formVersion.current = (draftJson.form_version ?? '').toString();
        draftTimestamp.current = parseTimestamps(draftJson);
      });
    });

  const checkDraftConnection =
    (func: () => Promise<any>, onException = (_err: any) => {}) =>
    async () =>
      await func()
        .then((response: any) => {
          noConnectionToastId && hideToast({ id: noConnectionToastId });
          if (draftStatus === DRAFT_STATUS.NOT_CONNECTED)
            setDraftStatus(DRAFT_STATUS.OPERATIONAL);
          return response;
        })
        .catch((err: any) => {
          setDraftStatus(DRAFT_STATUS.NOT_CONNECTED);
          // if just seeing the disconnected state for the first time put up a message
          if (noConnectionToastId === null)
            addErrorToast({
              title: TOAST_TITLES.NO_CONNECTION,
              body: TOAST_MESSAGES.NO_CONNECTION,
              autohide: false
            });
          // Try and recover from any transient connection issues - force a GET of the draft to reset to a good state
          loadDraft(true);

          return onException(err);
        });

  //
  // Need to initialize changedServars to what is changed in any reloaded draft
  //
  useEffect(() => {
    let changedServarsMap = {};
    if (draftSteps) {
      changedServarsMap = Object.values(draftSteps).reduce(
        (changedServars: Record<string, any>, step: any) => {
          const changedServarsThisStep = getChangedServarsForStep(
            step,
            oldServars
          );
          return { ...changedServars, ...changedServarsThisStep };
        },
        {}
      );
    }
    setChangedServars(Object.keys(changedServarsMap));
  }, [draftSteps, oldServars]);

  /// /////////////////////////////////////////////////////////////////////////////
  //
  //  Logic for form/draft periodic "freshness" checking.
  //
  /// /////////////////////////////////////////////////////////////////////////////

  function checkForStaleForm(
    versionInfo: { form_version: number },
    checkVersion = true,
    updateClientVersion = true,
    onStaleForm = () => {}
  ) {
    let isStale = false;
    const { form_version: version } = versionInfo;
    const newVersion = version.toString();
    if (checkVersion) {
      if (newVersion !== formVersion.current) {
        if (newVersion === '-1') {
          // Form no longer exists (e.g. deleted) so go back to forms list page
          onStaleForm();
          history.replace('/forms');
          location.reload();
        } else if (formVersion.current && newVersion) {
          // Form has been published elsewhere
          isStale = true;
          onStaleForm();
          notifyUserWithToast();
          onVersionExpire && onVersionExpire();
        }
      }
    }
    if (updateClientVersion) {
      formVersion.current = newVersion;
    }
    setAutosave(!isStale); // stop saving if stale
    return isStale;
  }

  // Monitor form version/draft timestamp if needed
  const [checkVersion, setCheckVersion] = useState(true);
  const hasShowedOtherChange = useRef(false);

  const doVersionCheck = (onStaleVersion = () => {}) => {
    return runTask(
      checkDraftConnection(async () => {
        const params = encodeGetParams({ form_key: formKey });
        const url = `${API_URL}panel/version/?${params}`;
        const response = await fetch(url, {
          headers: {
            Authorization: `Token ${sdkKey}`,
            'X-Client-Identifier': clientIdentifier
          }
        }).then(async (response: any) => {
          response =
            response.status === 404
              ? { form_version: '-1', timestamp: '' }
              : await response.json();
          checkForStaleForm(response, true, false, onStaleVersion);
          return response;
        });
        return response;
      })
    );
  };

  useEffect(() => {
    if (!checkVersion || !onVersionExpire || !loadedFormId) return;
    // Get the draft in order to get a starting formVersion/draft timestamp
    // This request is cached at this point so efficient!
    loadDraft();

    // Now check the version/timestamp to start with a proper initial state.
    // The cached draft might be in a bad version state!
    doVersionCheck();

    // The version check period should be longer because it does tax the server (not using cdn).
    // Anyone making changes will get an autosave within 2 seconds or so and the autosave
    // will notify if anyone else has made changes in another session.  This is just a backup
    // notification for anyone just reading the form design without making changes.
    const interval: any = setInterval(
      () => doVersionCheck(() => clearInterval(interval)),
      300 * 1000
    );

    return () => clearInterval(interval);
  }, [checkVersion, loadedFormId, noConnectionToastId]);

  /// /////////////////////////////////////////////////////////////////////////////
  //
  //  Draft Auto Save
  //
  /// /////////////////////////////////////////////////////////////////////////////

  // Add the actual step or rule data to the edit change records.  Delete change records are fine as is.
  const enhanceEditChangeRecords = (
    changeRecords: any,
    workingRecords: any,
    changedItemKey: string
  ) =>
    Object.entries(changeRecords).reduce(
      (recordChangesWithData: any, [key, changeRecord]) => {
        if ((changeRecord as any).operation === 'edit')
          recordChangesWithData[key] = {
            operation: 'edit',
            [changedItemKey]: workingRecords[key]
          };
        else recordChangesWithData[key] = changeRecord;
        return recordChangesWithData;
      },
      {}
    );

  const getDeltaData = () => {
    if (workingSteps === null) return;

    const newWorkingSteps: any = produce(workingSteps, (draft: any) =>
      Object.values(draft).forEach((step: any) => {
        draft[step.id] = cleanupStep(step);
      })
    );

    return {
      // Add the actual step data to the edit change records.  Delete change records are fine as is.
      steps: enhanceEditChangeRecords(changedSteps, newWorkingSteps, 'step'),
      // Add the actual rule data to the edit change records.  Delete change records are fine as is.
      logic_rules: enhanceEditChangeRecords(
        changedLogicRules,
        workingLogicRules,
        'logic_rule'
      ),
      theme: objectDeltas(themeBaseline, theme)
    };
  };

  function applyTimestamps(deltas: any) {
    return {
      steps: applyTimestampToItem(deltas.steps, 'steps'),
      logic_rules: applyTimestampToItem(deltas.logic_rules, 'logic_rules'),
      theme: applyTimestampToItem(deltas.theme, 'theme')
    };
  }

  const applyTimestampToItem = (delta: any, type: TimestampIndex) =>
    Object.entries(delta).reduce((result: any, [key, value]) => {
      result[key] = {
        ...(value as any),
        timestamp: (draftTimestamp.current?.[type] as any)?.[key]?.timestamp
      };
      return result;
    }, {});

  const deltaDataToBeSaved = (deltaData: {
    steps: Record<string, any>;
    logic_rules: Record<string, any>;
    theme: Record<string, any>;
  }) =>
    Object.keys(deltaData.steps).length ||
    Object.keys(deltaData.logic_rules).length ||
    Object.keys(deltaData.theme).length;

  // Any unsaved local changes to the draft?
  const unsavedDraftChanges = useMemo(
    () =>
      Object.keys(changedSteps).length > 0 ||
      Object.keys(changedLogicRules).length > 0 ||
      Object.keys(objectDeltas(themeBaseline, theme)).length > 0,
    [changedSteps, changedLogicRules, themeBaseline, theme]
  );

  // Retrying any failing of saveDraft one time.
  const [retryFailedSaveDraft, setFailedRetrySaveDraft] = useState(true);

  // Save the draft changes to the BE
  async function saveDraft(
    deltas: any,
    validate = false,
    savingStatus = PUBLISH_STATUS.AUTO_SAVING,
    onException = () => {}
  ) {
    return runTask(
      checkDraftConnection(
        async () => {
          // Add timestamps to delta data
          const enhancedDeltas = applyTimestamps(deltas);

          const data = {
            ...enhancedDeltas,
            validate,
            form_version: formVersion.current,
            timestamp: draftTimestamp.current?.timestamp
          };

          // if the form has a promote form, we do not save any draft changes
          if (panel.promote_from) {
            const isRollback =
              panel.promote_from && !panel.promote_live && !panel.promote_to;
            const promotionKey = panelsData[panel.promote_from]?.key;
            const message = isRollback
              ? `This form can't be edited because it is a rollback form for ${
                  promotionKey ? promotionKey : 'a form'
                }.`
              : `This form can't be edited because ${
                  promotionKey ? promotionKey : 'a form'
                } can be promoted to it. Make your changes to ${
                  promotionKey ? promotionKey : 'that form'
                } instead.`;
            setDraftStatus(DRAFT_STATUS.ERROR);
            addErrorToast({
              title: TOAST_TITLES.PROMOTION_ERROR,
              body: message,
              autohide: false
            });
            return { error: 'promote form' };
          }

          setFlowPublishStatus(savingStatus);
          clearUnsavedChanges();

          const response: any = await updateDraft({
            panelId: panel.id,
            data
          });

          if (response.versionConflict) {
            clearToasts();
            notifyUserWithToast();
            onVersionExpire && onVersionExpire();
            setDraftStatus(DRAFT_STATUS.ERROR);
            setAutosave(false); // turn off autosave
            setCheckVersion(false); // turn off version checking to prevent misleading error messages
          } else if (response.fatalError) {
            if (retryFailedSaveDraft) {
              // only want to retry once
              setFailedRetrySaveDraft(false);
            } else {
              setDraftStatus(DRAFT_STATUS.ERROR);
              const msg = response.userError
                ? response.error
                : TOAST_MESSAGES.AUTOSAVE_ERROR;
              notifyUserWithToast(TOAST_TITLES.AUTOSAVE_ERROR, msg);
              sentryError(new Error('Autosave Error'), {
                context: { saveError: response.error }
              });
              setAutosave(false); // turn off autosave
              setCheckVersion(false); // turn off version checking to prevent misleading error messages
            }
          } else {
            // ok this one not failed, so reset the retry flag
            setFailedRetrySaveDraft(true);
            if (checkForStaleForm(response, !!response.error)) {
              return { error: 'Form version not found' };
            }

            const newTimestamps = parseTimestamps(response);
            updateTimestamps(newTimestamps, enhancedDeltas);
          }

          return response;
        },
        () => {
          onException();
          return { error: 'no connection' };
        }
      )
    );
  }

  // Debouncing the autosave to prevent calls while changes are being made
  const debouncedAutosave = useCallback(
    debounce(async () => {
      const formattedData = getDeltaData();
      if (formattedData && deltaDataToBeSaved(formattedData)) {
        // This logic makes sure that the "saving" label on the publish button does not flash too fast to notice
        const delayedStatusUpdate: any = {
          draftSaving: true,
          active: true,
          callback: function () {
            if (!this.draftSaving) setFlowPublishStatus(PUBLISH_STATUS.ACTIVE);
            this.active = false;
          }
        };
        setTimeout(() => delayedStatusUpdate.callback(), 1000);
        const result = await saveDraft(
          formattedData,
          false,
          PUBLISH_STATUS.AUTO_SAVING,
          () => debouncedAutosave()
        ); // on exception, try again in a bit of time);

        if (result && !result.error) {
          if (result.other_changes && !hasShowedOtherChange.current) {
            hasShowedOtherChange.current = true;
            notifyUserWithToast(
              TOAST_TITLES.OTHER_CHANGES,
              TOAST_MESSAGES.OTHER_CHANGES
            );
          }
        }
        if (!delayedStatusUpdate.active) {
          // draft save took longer than minimum - set active now
          setFlowPublishStatus(PUBLISH_STATUS.ACTIVE);
        }
        delayedStatusUpdate.draftSaving = false;
      }
    }, 2000),
    [
      panel.id,
      updateDraft,
      workingSteps,
      changedSteps,
      workingLogicRules,
      changedLogicRules,
      theme,
      themeBaseline,
      noConnectionToastId,
      retryFailedSaveDraft,
      setFailedRetrySaveDraft
    ]
  );

  useEffect(() => {
    if (!autosave || !(global as any).Feathery.autoSave) return;
    if (
      (flowPublishStatus === PUBLISH_STATUS.ACTIVE &&
        workingSteps &&
        Object.keys(workingSteps).length) ||
      (themePublishStatus === PUBLISH_STATUS.ACTIVE && theme)
    ) {
      debouncedAutosave.cancel();
      debouncedAutosave();
    }
    return () => {
      debouncedAutosave.cancel();
    };
  }, [
    autosave,
    workingSteps,
    workingLogicRules,
    changedSteps,
    theme,
    themePublishStatus,
    flowPublishStatus,
    noConnectionToastId
  ]);

  /// /////////////////////////////////////////////////////////////////////////////
  //
  //  Save, Validate and Publish
  //
  /// /////////////////////////////////////////////////////////////////////////////

  // save and validate form delta
  const saveAndValidate = async () => {
    const formattedData = getDeltaData();
    if (!formattedData) return null;

    // save data and do a BE validation
    const result = await saveDraft(formattedData, true, PUBLISH_STATUS.LOADING);
    if (result.error) {
      setFlowPublishStatus(PUBLISH_STATUS.ACTIVE);
      return null;
    }

    if (result.other_changes && !hasShowedOtherChange.current) {
      hasShowedOtherChange.current = true;
      notifyUserWithToast(
        TOAST_TITLES.OTHER_CHANGES,
        TOAST_MESSAGES.OTHER_CHANGES
      );
    }
    // now get any BE validations and combine with FE validations
    const warnings = (result.validations ?? []).reduce(
      (warnings: any, validation: any) => {
        const { code, level, data } = validation;
        if (level === 'WARNING') {
          warnings.push({ code, data });
        }
        return warnings;
      },
      []
    );
    const errors = (result.validations ?? []).reduce(
      (errors: any, validation: any) => {
        const { code, level, data } = validation;
        if (level === 'ERROR') {
          errors.push({ code, data });
        }
        return errors;
      },
      []
    );

    // Check step origin sanity
    let numOrigins = 0;
    Object.values(workingSteps).forEach((step) => {
      if ((step as any).origin) numOrigins++;
    });
    if (numOrigins === 0)
      errors.push({
        code: VALIDATION_CODES.NO_START_STEP,
        data: { message: 'You must have a starting step' }
      });
    else if (numOrigins > 1)
      errors.push({
        code: VALIDATION_CODES.MULTIPLE_START_STEPS,
        data: { message: 'You can only have one starting step' }
      });

    // check for duplicate step names
    const seenKeys: any = [];
    const dupKey = Object.values(workingSteps).reduce((dupKey, step: any) => {
      const thisDupKey = seenKeys.includes(step.key) ? step.key : null;
      seenKeys.push(step.key);
      return dupKey || thisDupKey;
    }, null);
    if (dupKey)
      errors.push({
        code: VALIDATION_CODES.STEP_NAME_CONFLICT,
        data: { message: `Multiple steps have the name ${dupKey}` }
      });

    // check for repeat errors
    const repeatErrMsg = Object.values(workingSteps).reduce(
      (err, step: any) => err || repeatConfig(step),
      ''
    );
    if (repeatErrMsg)
      errors.push({
        code: VALIDATION_CODES.REPEAT_ERROR,
        data: { message: repeatErrMsg }
      });

    // Error - check if any enabled workingLogicRules are not valid
    const invalidLogicRules = (
      Object.values(workingLogicRules) as LogicRule[]
    ).filter((rule) => !rule.valid && rule.enabled);
    if (invalidLogicRules.length)
      errors.push({
        code: VALIDATION_CODES.INVALID_LOGIC_RULE,
        data: { message: `One or more logic rules are not valid.` }
      });
    // Error - check if workingLogicRules include private actions
    const privateActions: string[] = [];
    const privateLogicRules = (
      Object.values(workingLogicRules) as LogicRule[]
    ).filter((rule) => {
      if (rule.code && rule.mode === 'code_editor') {
        for (const privateAction of PRIVATE_ACTIONS) {
          if (rule.code.includes(PRIVATE_FUNCTIONS[privateAction])) {
            privateActions.push(PRIVATE_FUNCTIONS[privateAction]);
            return true;
          }
        }
      }
      return false;
    });
    if (privateLogicRules.length)
      errors.push({
        code: VALIDATION_CODES.PRIVATE_LOGIC_RULE,
        data: {
          message: privateActions
        }
      });

    // Error - check to see if logic rules with trigger_event of type change, action and
    // view all have at least one item in elements array
    const logicRulesWithEmptyElements = Object.values(
      workingLogicRules as { [id: string]: LogicRule }
    )
      .filter(
        (rule: LogicRule) =>
          rule.trigger_event === 'change' ||
          rule.trigger_event === 'action' ||
          rule.trigger_event === 'view'
      )
      .filter((rule) => rule.elements.length === 0);
    if (logicRulesWithEmptyElements.length)
      errors.push({
        code: VALIDATION_CODES.INVALID_LOGIC_RULE_CONFIG,
        data: logicRulesWithEmptyElements
      });

    // Warning - Check if they updated the theme, and if that theme is used anywhere else
    if (themePublishStatus === PUBLISH_STATUS.ACTIVE) {
      const otherFormsUsingTheme = Object.values(panelsData).filter(
        (panel) =>
          (panel as any).id !== formId && (panel as any).theme === theme?.id
      );
      if (otherFormsUsingTheme.length)
        warnings.push({
          code: VALIDATION_CODES.THEME,
          data: otherFormsUsingTheme.map((panel) => panel.key)
        });
    }

    // Warning - Check to see if user has made changes to linked fields
    const linkedChangedServars: Record<string, any> = {};
    changedServars.forEach((id: string) => {
      if ((servarUsage[id] ?? []).length > 1)
        linkedChangedServars[id] = servarUsage[id];
    });

    if (Object.keys(linkedChangedServars).length > 0)
      warnings.push({
        code: VALIDATION_CODES.LINKED_FIELDS,
        data: linkedChangedServars
      });

    if (errors.length || warnings.length)
      setFlowPublishStatus(PUBLISH_STATUS.ACTIVE);

    return { errors, warnings };
  };

  const [shouldLoadFields, setShouldLoadFields] = useState(loadFields);
  const { servars: allServars, usage: originalServarUsage } = useAppSelector(
    (state) => state.fields
  );
  useEffect(() => {
    if (mounted.current && shouldLoadFields && allServars) {
      const servarMap: Record<string, any> = {};
      allServars.forEach((field: any) => (servarMap[field.id] = field));
      setServarCache({ servars: servarMap, usage: originalServarUsage });
      setShouldLoadFields(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldLoadFields, allServars]);

  //
  // publish the draft
  //
  const publish = async () => {
    await runTask(
      checkDraftConnection(() => {
        setFlowPublishStatus(PUBLISH_STATUS.LOADING);
        return publishForm({
          panelId: panel.id,
          body: {
            form_version: formVersion.current
          },
          errorFlowStatus: flowPublishStatus,
          errorThemeStatus: themePublishStatus
        }).then((response: any) => {
          if (response.error) {
            setDraftStatus(DRAFT_STATUS.ERROR);
            notifyUserWithToast(
              TOAST_TITLES.PUBLISH_ERROR,
              TOAST_MESSAGES.PUBLISH_ERROR
            );
            sentryError(new Error('Publish Error'), {
              context: { publishError: response.error }
            });
            setCheckVersion(false); // turn off version checking to prevent misleading error messages
          } else {
            checkForStaleForm(response, false); // reset version/timestamp
            refreshCache();
            setReloadForm((reload: any) => ({ ...reload })); // cause a form load
          }
        });
      })
    );
    loadDraft(true);
  };

  /// /////////////////////////////////////////////////////////////////////////////
  //
  //  Delete Draft (Restore to last published)
  //
  /// /////////////////////////////////////////////////////////////////////////////
  const _deleteDraft = async () => {
    await runTask(
      checkDraftConnection(async () => {
        await deleteDraft({
          panelId: panel.id,
          data: {
            form_version: formVersion.current
          }
        }).then((response: any) => {
          if (!checkForStaleForm(response, !!response.error)) {
            reloadDraftDeletion();
          }
        });
      })
    );
  };

  return {
    unsavedDraftChanges,
    saveAndValidate,
    publish,
    deleteDraft: _deleteDraft
  };
}

export interface ERROR_WARNING {
  code: string;
  data: any;
}
export type ERROR_WARNINGS = {
  errors: ERROR_WARNING[];
  warnings: ERROR_WARNING[];
} | null;

export const VALIDATION_CODES = {
  FIELD_REF: 'FIELD_REF', // WARNING: field being deleted that is referenced in other form(s)
  SERVAR_NAME: 'SERVAR_NAME', // ERROR: servar name conflict (must be unique)
  ASSET_IN_USE: 'ASSET_IN_USE', // WARNING: Draft deleted an asset that was in use on another form.
  // The in use asset delete will be ignored.
  THEME: 'common theme changed', // theme is used in other forms
  LINKED_FIELDS: 'linked fields changed',
  NO_START_STEP: 'no starting step',
  MULTIPLE_START_STEPS: 'multiple start steps',
  STEP_NAME_CONFLICT: 'step name duplicates',
  REPEAT_ERROR: 'repeat error',
  INVALID_LOGIC_RULE: 'invalid logic rule(s)',
  INVALID_LOGIC_RULE_CONFIG: 'invalid logic rule(s) config',
  PRIVATE_LOGIC_RULE: 'private logic rule(s) not allowed'
};

function useSequentialTaskRunner() {
  const taskQueue = useRef<Promise<void>>(Promise.resolve());

  const runTask = useCallback(function runTask<T>(
    taskFunction: () => Promise<T>
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      taskQueue.current = taskQueue.current.then(async () => {
        try {
          const result = await taskFunction();
          resolve(result);
        } catch (error) {
          reject(error);
        }
      });
    });
  },
  []);

  return runTask;
}
