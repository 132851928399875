import { ElementTypeTuple, filterAndMapStepElements } from './step';
import useFeatheryRedux from '../redux';
import { useAppSelector } from '../hooks';
import produce from 'immer';

export default function useAssetInUseUpdater() {
  const {
    themes: { setPanelThemeAssetUse }
  } = useFeatheryRedux();
  const panelThemeAssetUse = useAppSelector(
    ({ themes }) => themes.panelThemeAssetUse
  );

  // Gathers an array of source_asset and type for all elements in workingSteps
  function getAssetsInUse(workingSteps: any): { id: string; type: string }[] {
    let assetsInUse: { id: string; type: string }[] = [];
    Object.values(workingSteps).forEach((step: any) => {
      assetsInUse = assetsInUse.concat(
        filterAndMapStepElements(step, {
          filter: (element: ElementTypeTuple) => element[0].source_asset,
          map: (element: ElementTypeTuple) => {
            return {
              id: element[0].source_asset,
              type: element[1] === 'field' ? element[0].servar.type : element[1]
            };
          }
        })
      );
    });

    // Dedup
    const filteredAssets: any[] = [];
    assetsInUse.forEach((assetInUse) => {
      if (!filteredAssets.some((asset) => asset.id === assetInUse.id)) {
        filteredAssets.push(assetInUse);
      }
    });

    return filteredAssets;
  }

  // Updates the PanelThemeAssetUse object in redux store for a particular panel and theme with
  // the assets in use in the workingSteps
  function updatePanelThemeAssetUse(
    panelId: string,
    themeId: string,
    workingSteps: any
  ) {
    const newPanelThemeAssetUse = produce(panelThemeAssetUse, (draft: any) => {
      const index = draft.findIndex(
        (item: any) => item.panel_id === panelId && item.theme_id === themeId
      );
      const panelThemeAssetUseItem =
        index >= 0
          ? draft.splice(index, 1)[0]
          : {
              panel_id: panelId,
              theme_id: themeId,
              assets_in_use: []
            };
      const assetsInUse = getAssetsInUse(workingSteps);
      if (assetsInUse.length) {
        panelThemeAssetUseItem.assets_in_use = assetsInUse;
        draft.push(panelThemeAssetUseItem);
      }
    });
    setPanelThemeAssetUse(newPanelThemeAssetUse);
  }

  return { getAssetsInUse, updatePanelThemeAssetUse };
}
