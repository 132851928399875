/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react';
import { PlusIcon, RightArrowIcon } from '../../Icons';
import { produce } from 'immer';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import useFeatheryRedux from '../../../redux';
import validateStepConnections from './utils';

import styles from '../styles.module.scss';
import { ConditionData } from './types';
import NavRule from './NavRule';

export function DirectionRules({
  previousStep,
  nextStep,
  forwardDirection = true
}: any) {
  const { formId } = useParams<{ formId: string }>();
  const {
    formBuilder: { updateNavRulesByPair }
  } = useFeatheryRedux();

  const pendingFormData = previousStep.next_conditions.filter(
    (cond: any) => cond.next_step === nextStep.id
  );
  const [formData, setFormData] = useState<ConditionData[]>(pendingFormData);
  useEffect(() => setFormData(pendingFormData), [previousStep.id, nextStep.id]);

  const [errors, setErrors] = useState({});

  const validateData = (newData: any) => {
    const errors = validateStepConnections(newData, previousStep, nextStep);
    setErrors(errors);
    return errors;
  };

  const setData = (transform: (draft: any) => void, showIfInvalid = true) => {
    const newData = produce(formData, transform);
    setFormData(newData);

    const errors = validateData(newData);
    if (Object.keys(errors).length === 0) {
      setErrors({});
      updateNavRulesByPair({
        prevStepId: previousStep.id,
        nextStepId: nextStep.id,
        newData,
        formId
      });
    } else if (showIfInvalid) setErrors(errors);
  };

  const addCondition = () => {
    validateData(formData);
    const newFormData = produce(formData, (draft) => {
      draft.push({
        previous_step: previousStep.id,
        next_step: nextStep.id,
        element_id: '',
        element_type: '',
        created_at: new Date().toISOString(),
        metadata: {},
        rules: []
      });
    });
    // Condition is not complete, do not validate or try to submit
    setFormData(newFormData);
  };

  const showRules = formData.length > 0 || forwardDirection;
  return showRules ? (
    <div
      className={classNames(
        styles.navDirectionContainer,
        !forwardDirection && styles.navDirectionContainerReverse
      )}
    >
      <div className={styles.navDirectionHeader}>
        <div>
          {previousStep.key} <RightArrowIcon className={styles.arrowIcon} />{' '}
          {nextStep.key}
        </div>
      </div>
      {formData.map((cond, index) => (
        <NavRule
          key={JSON.stringify(cond)}
          cond={cond}
          step={previousStep}
          index={index}
          setData={setData}
          errors={errors}
        />
      ))}
      <div
        className={classNames(styles.addNavigationRule, styles.ruleTarget)}
        onClick={addCondition}
      >
        <PlusIcon width={15} height={15} className={styles.addNewRuleIcon} />
        Connection
      </div>
    </div>
  ) : null;
}
