export default function PlusCircleIcon({
  width = 16,
  height = 16,
  color = 'white',
  background = 'black',
  ...props
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      xmlns='http://www.w3.org/2000/svg'
      fill={background}
      stroke={background}
      {...props}
    >
      <circle cx={width / 2} cy={height / 2} r={width / 2 - 1} />
      <path
        d={`M${width / 2} 5V${height - 5}`}
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d={`M5 ${height / 2}L${width - 5} ${height / 2}`}
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
    </svg>
  );
}
