import { useEffect, useState } from 'react';
import useFeatheryRedux from '../../../redux';
import { ROLLOUT_BASE_URL } from '../../FormIntegrations/RolloutSidebar/utils';
import { useAppSelector } from '../../../hooks';

type RolloutCredential = {
  credentialKey: string;
  label: string;
};

export function useRolloutToken() {
  const { fetchRolloutToken } = useFeatheryRedux();
  const rolloutToken = useAppSelector(
    (state) => state.integrations.rolloutToken
  );

  useEffect(() => {
    if (!rolloutToken) {
      fetchRolloutToken();
    }
  }, []);

  return rolloutToken;
}

export function useRolloutCredentials(app: string) {
  const { fetchRolloutToken } = useFeatheryRedux();
  const [credentials, setCredentials] = useState<RolloutCredential[]>();

  useEffect(() => {
    (async () => {
      const token = await fetchRolloutToken();
      const newCredentials = await fetchRolloutCredentials(app, token);
      setCredentials(newCredentials);
    })();
  }, [app]);

  return { credentials };
}

export async function fetchRolloutCredentials(
  app: string,
  token: string
): Promise<RolloutCredential[]> {
  const headers = { Authorization: `Bearer ${token}` };
  let results = await fetch(
    `${ROLLOUT_BASE_URL}/credentials/${app}?` +
      new URLSearchParams({
        includeData: 'true'
      }).toString(),
    {
      headers
    }
  )
    .then((res) => res.json())
    .catch((error) => console.error(error));

  if (!results) {
    results = [];
  }

  if (!Array.isArray(results)) {
    console.error(
      `Could not fetch ${app} accounts:`,
      (results as any)?.message
    );
    results = [];
  }

  return results;
}
