import { CollapsibleSection } from '../../Core';
import HelpLink from './HelpLink';
import styles from './styles.module.scss';
import DragAndDropHintIcon from './DragAndDropHintIcon';
import {
  FillSizeIcon,
  FormsDashboardIcon,
  HorizontalCenterIcon
} from '../../Icons';

const HELP_LINKS = [
  {
    title: 'Building your first form',
    url: 'https://docs.feathery.io/platform/quickstart',
    icon: <FormsDashboardIcon className={styles.svg} />
  },
  {
    title: 'Sizing forms and elements',
    url: 'https://docs.feathery.io/platform/build-a-form/sizing-forms-and-elements',
    icon: <FillSizeIcon className={styles.svg} />
  },
  {
    title: 'Alignment and spacing',
    url: 'https://docs.feathery.io/platform/build-a-form/alignment-and-spacing',
    icon: <HorizontalCenterIcon color='white' />
  }
];

export default function HelpSection() {
  return (
    <CollapsibleSection title='Help' collapsible>
      {HELP_LINKS.map(({ title, url, icon }) => (
        <HelpLink key={url} title={title} url={url} icon={icon} />
      ))}
      <div className={styles.dragAndDropHint}>
        <div className={styles.hint}>Hint</div>
        <div className={styles.description}>
          Drag an element onto your step and select it to get started!
        </div>
        <DragAndDropHintIcon />
      </div>
    </CollapsibleSection>
  );
}
