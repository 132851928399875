import { Tooltip } from './Tooltip';

/**
 * Wraps content in a tooltip only if the tooltip is needed
 * (typically if the content length is longer and would be cut off.)
 */
export default function DynamicTextTooltip({
  text = '',

  // length of tooltip text beyond which we need to add the tooltip
  maxLength = 12,

  allowShow = true,
  children
}: any) {
  return text.length > maxLength ? (
    <Tooltip disabled={!allowShow} content={text}>
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  );
}
