export default function FlowIcon(props: any) {
  return (
    <svg
      width='15'
      height='14'
      viewBox='0 0 15 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect
        width='6.24612'
        height='6.25429'
        rx='1'
        transform='matrix(-4.7256e-08 1 1 4.04326e-08 0.4375 7.75396)'
        fill='#E2626E'
      />
      <rect
        width='6.24612'
        height='6.25429'
        rx='1'
        transform='matrix(-3.72596e-08 1 1 5.12803e-08 8.31055 -8.39233e-05)'
        fill='#E2626E'
      />
      <path
        d='M12.4794 3.12339H5.61914C4.51457 3.12339 3.61914 4.01882 3.61914 5.12339V9.89002'
        stroke='#E2626E'
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  );
}
