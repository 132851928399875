import { useState, useCallback } from 'react';

type UseConfirmOptions = {
  message: string;
  onConfirm: () => void;
  onAbort: () => void;
};
export const useConfirm = ({
  message,
  onConfirm,
  onAbort
}: UseConfirmOptions) => {
  const [isConfirming, setIsConfirming] = useState(false);

  const confirm = useCallback(() => {
    setIsConfirming(true);
    if (window.confirm(message)) {
      onConfirm();
    } else {
      onAbort();
    }
    setIsConfirming(false);
  }, [message, onConfirm, onAbort]);

  return {
    isConfirming,
    confirm
  };
};
