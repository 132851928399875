export default function LetterSpacingIcon() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='#6C7589'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M19 4C19 3.44772 19.4477 3 20 3C20.5523 3 21 3.44772 21 4H19ZM21 20C21 20.5523 20.5523 21 20 21C19.4477 21 19 20.5523 19 20H21ZM21 4V20H19V4H21Z' />
      <path d='M3 4C3 3.44772 3.44772 3 4 3C4.55228 3 5 3.44772 5 4H3ZM5 20C5 20.5523 4.55228 21 4 21C3.44772 21 3 20.5523 3 20H5ZM5 4V20H3V4H5Z' />
      <path d='M14 14C14.5523 14 15 13.5523 15 13C15 12.4477 14.5523 12 14 12V14ZM10 12C9.44772 12 9 12.4477 9 13C9 13.5523 9.44772 14 10 14V12ZM14 12H10V14H14V12Z' />
      <path d='M12.8944 8.44721C13.1414 7.95324 12.9412 7.35256 12.4472 7.10557C11.9532 6.85858 11.3526 7.05881 11.1056 7.55279L12.8944 8.44721ZM7.10557 15.5528C6.85858 16.0468 7.05881 16.6474 7.55279 16.8944C8.04677 17.1414 8.64744 16.9412 8.89443 16.4472L7.10557 15.5528ZM11.1056 7.55279L7.10557 15.5528L8.89443 16.4472L12.8944 8.44721L11.1056 7.55279Z' />
      <path d='M15.1056 16.4472C15.3526 16.9412 15.9532 17.1414 16.4472 16.8944C16.9412 16.6474 17.1414 16.0468 16.8944 15.5528L15.1056 16.4472ZM12.8944 7.55279C12.6474 7.05881 12.0468 6.85858 11.5528 7.10557C11.0588 7.35256 10.8586 7.95323 11.1056 8.44721L12.8944 7.55279ZM16.8944 15.5528L12.8944 7.55279L11.1056 8.44721L15.1056 16.4472L16.8944 15.5528Z' />
    </svg>
  );
}
