export default function LogoutIcon({ width = 28, height = 28 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M23.1364 11.5L27.5 15.8636L23.1364 20.2273'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.5 15.8637L27.5 15.8637'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M22.2976 28H7C5.89543 28 5 27.1046 5 26V6C5 4.89543 5.89543 4 7 4H22.2976'
        strokeWidth='3'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
