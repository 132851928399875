import '../../../style/dialog-form.css';

import { CollapsibleSection, PropertyLabel } from '../../Core';
import { FieldSelectorWithModal } from '../../Modals';
import classNames from 'classnames';
import styles from '../styles.module.scss';

import StripeConfig from './types';
import { memo } from 'react';

interface FieldMappingProps {
  stripeConfig: StripeConfig;
  setStripeConfig: (c: StripeConfig) => void;
  label: string;
  fieldTag: 'receipt_email' | 'has_paid';
  onlyHiddenFields?: boolean;
}

export function PaymentFieldMapping({
  stripeConfig,
  setStripeConfig,
  label,
  fieldTag,
  onlyHiddenFields = false
}: FieldMappingProps) {
  return (
    <div className={classNames(styles.fieldMapping, styles.marginBottom)}>
      <PropertyLabel label={label} />
      <FieldSelectorWithModal
        className={classNames(styles.fieldSelectorButton, 'test-target')}
        selectId={stripeConfig.metadata.payment_field_mappings?.[fieldTag]?.id}
        selectType={
          stripeConfig.metadata.payment_field_mappings?.[fieldTag]?.type
        }
        placeholder='Select'
        onlyHiddenFields={onlyHiddenFields}
        onSelect={({ selectId, selectType }) => {
          const newStripeConfig = {
            ...stripeConfig,
            metadata: {
              ...stripeConfig.metadata,
              payment_field_mappings: {
                ...stripeConfig.metadata.payment_field_mappings
              }
            }
          };
          // support removing the mapping as well
          delete newStripeConfig.metadata.payment_field_mappings[fieldTag];
          if (selectId)
            newStripeConfig.metadata.payment_field_mappings[fieldTag] = {
              id: selectId,
              type: selectType as 'servar' | 'hidden'
            };
          setStripeConfig(newStripeConfig);
        }}
      />
    </div>
  );
}

function StripePaymentFieldSettings({
  stripeConfig,
  setStripeConfig = () => {}
}: {
  stripeConfig: StripeConfig;
  setStripeConfig: (c: StripeConfig) => void;
}) {
  return (
    <CollapsibleSection title='Payment (Optional)' collapsible expanded={false}>
      <p className={classNames('text-muted', styles.helperText)}>
        Data from the payment fields will be used when processing payments in
        Stripe.
      </p>
      <PaymentFieldMapping
        stripeConfig={stripeConfig}
        setStripeConfig={setStripeConfig}
        label='Receipt Email Field (for charges)'
        fieldTag='receipt_email'
      />
      <PaymentFieldMapping
        stripeConfig={stripeConfig}
        setStripeConfig={setStripeConfig}
        label='Payment Indicator'
        fieldTag='has_paid'
        onlyHiddenFields
      />
    </CollapsibleSection>
  );
}

export default memo(StripePaymentFieldSettings);
