import { useEffect } from 'react';
import { useKeyboardListener } from '../../../hooks/useKeyboardListener';
import { ExtractionUpdater } from './types';

const KEYS: any = ['Escape'] as const;

type Props = {
  updater: ExtractionUpdater;
};

export const ExtractionEngine = ({ updater }: Props) => {
  const { setSelectedValueId } = updater;

  const { onKeyup } = useKeyboardListener({
    keys: KEYS,
    preventDefault: true
  });

  useEffect(() => {
    if (onKeyup) {
      onKeyup(({ shortcut }: any) => {
        if (shortcut === 'Escape') {
          setSelectedValueId(null);
        }
      });
    }
  }, []);

  return null;
};
