export default function PaymentMethodIcon() {
  return (
    <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.5 33.287a1.5 1.5 0 0 1-1.5-1.5V21.115h20.052a10.447 10.447 0 0 0-3.806 8.075c0 1.454.296 2.838.832 4.097H9.5Zm32.216-13.672v1.791a10.442 10.442 0 0 1 3.468 7.784c0 5.781-4.688 10.468-10.47 10.468a10.441 10.441 0 0 1-7.695-3.371H9.5a4.5 4.5 0 0 1-4.5-4.5V12.5A4.5 4.5 0 0 1 9.5 8h27.716a4.5 4.5 0 0 1 4.5 4.5v7.115Zm-3-1.5H8V16.9h30.716v1.215Zm0-4.215H8v-1.4A1.5 1.5 0 0 1 9.5 11h27.716a1.5 1.5 0 0 1 1.5 1.5v1.4ZM12.587 26.731a1.5 1.5 0 1 0 0 3h5.151a1.5 1.5 0 1 0 0-3h-5.15Zm22.128-5.01a7.469 7.469 0 1 0 0 14.937 7.469 7.469 0 0 0 0-14.937Zm2.993 9.134c0-1.143-.656-1.983-1.644-2.493l-.012-.006a14.62 14.62 0 0 0-.702-.317 12.602 12.602 0 0 1-.569-.271l-.005-.003c-.258-.138-.336-.231-.357-.262l-.001-.002c-.01-.012-.029-.041-.029-.152 0-.174.046-.242.102-.297.05-.049.135-.104.335-.104.23 0 .42.074.634.414a1 1 0 0 0 1.456.26l.265-.204a1 1 0 0 0 .239-1.322c-.37-.593-.862-1.027-1.457-1.269v-.306a1 1 0 0 0-1-1h-.342a1 1 0 0 0-1 1v.327c-.973.43-1.574 1.384-1.574 2.5 0 .553.149 1.078.472 1.538.317.45.76.777 1.261 1.016l.022.01.539.241.006.003c.299.132.512.235.624.29.254.132.338.22.363.256a.11.11 0 0 1 .02.036c.004.015.012.05.012.117 0 .162-.042.248-.144.344-.086.08-.215.149-.473.149-.348 0-.622-.162-.811-.623a1 1 0 0 0-1.402-.5l-.29.159a1 1 0 0 0-.448 1.26c.37.897 1.022 1.532 1.823 1.837v.428a1 1 0 0 0 1 1h.342a1 1 0 0 0 1-1v-.44a2.85 2.85 0 0 0 .956-.637l.01-.01c.528-.543.779-1.217.779-1.967Z'
        fill='#414859'
      />
    </svg>
  );
}
