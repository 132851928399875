import { useStepUpdater } from '../../../hooks/useStepUpdater';
import useViewport from '../../../hooks/useViewport';
import { useAppSelector } from '../../../hooks';
import useFeatheryRedux from '../../../redux';
import { AxisSize, Cell, Viewport } from '../GridInGrid/engine';
import { elementOperation } from '../../../utils/themes';
import { useCallback } from 'react';
import {
  AXIS,
  AxisDirection,
  SIZE_UNITS,
  SizeUnit
} from '../../../utils/constants';
import { getGigUndoRedoPayload } from '../../../utils/step';
import useCanvasRefs from '../Controls/useCanvasRefs';
import { Model } from '../GridInGrid/engine/models';

const MIN_AXIS_SIZE = 15;

export default function useAxisResize(node: Cell, axis: AxisDirection) {
  const { handleUpdates } = useStepUpdater(node);
  const { viewport } = useViewport();

  const workingSteps = useAppSelector(
    ({ formBuilder }) => formBuilder.workingSteps
  );

  const activeStepId = useAppSelector(
    ({ formBuilder }) => formBuilder.activeStepId
  );

  const {
    formBuilder: { setPanelDataWithUndoRedo }
  } = useFeatheryRedux();

  function handleStyleChange(styleUpdate: any, node: Cell) {
    handleUpdates([elementOperation({ viewport, styleUpdate, node })]);
  }

  // handleResize is memoized to delay the node changing if onBlur calls the function after the node has changed.
  // This ensures that the target node of handleResize is correct during onBlur logic.
  const handleResize = useCallback(
    (newSize?: any) => {
      if (node.isElement && newSize) {
        const dimension = axis === AXIS.COL ? 'width' : 'height';

        if ([SIZE_UNITS.FILL, SIZE_UNITS.FIT].includes(newSize.value)) {
          handleStyleChange(
            {
              [dimension]: '',
              [`${dimension}_unit`]: newSize.value
            },
            node
          );
        } else {
          handleStyleChange(
            {
              [dimension]: newSize.value,
              [`${dimension}_unit`]: newSize.unit
            },
            node
          );
        }
      } else {
        if (newSize) {
          node.resize(axis, newSize);
        } else if (viewport === Viewport.Mobile) {
          (node.model as Model)[axis === AXIS.COL ? 'width' : 'height'] = null;
        }

        const gig = node.getGig();
        if (!gig) return;

        const newSteps = JSON.parse(JSON.stringify(workingSteps));
        newSteps[activeStepId] = gig.toStep();

        const undoRedoPayload = getGigUndoRedoPayload({
          stepId: activeStepId,
          oldSteps: workingSteps,
          newSteps: newSteps,
          changedStepKeys: [activeStepId]
        });

        setPanelDataWithUndoRedo(undoRedoPayload);
      }
    },
    [node]
  );

  // Size value is valid either if blank and auto-layout
  // or some value >= the min size (px)
  // or some value > 0 (fr)
  function isValidSize(axisSize: AxisSize) {
    const { value, unit } = axisSize;
    return (
      unit === SIZE_UNITS.FILL ||
      unit === SIZE_UNITS.FIT ||
      (unit === SIZE_UNITS.PX && value >= MIN_AXIS_SIZE) ||
      (unit === SIZE_UNITS.PERCENTAGE && value >= 0)
    );
  }

  const { getCanvasElement } = useCanvasRefs();
  const canvasRect = getCanvasElement(node.position.join(','))?.rect;

  return (axisSize?: AxisSize, currentUnit?: SizeUnit) => {
    if (!axisSize && !currentUnit) {
      handleResize();
      return;
    }

    let { value, unit: newUnit } = axisSize as AxisSize;

    if (newUnit === SIZE_UNITS.PX && currentUnit !== SIZE_UNITS.PX) {
      if (canvasRect) {
        value = Math.ceil(
          axis === AXIS.COL ? canvasRect.width : canvasRect.height
        );
      } else value = 400; // Fallback if no canvasRect available
      return handleResize({ value, unit: newUnit });
    } else if (
      newUnit === SIZE_UNITS.PERCENTAGE &&
      currentUnit !== SIZE_UNITS.PERCENTAGE
    ) {
      return handleResize({ value: 100, unit: '%' });
    }

    // Set minimum pixel value threshold.
    if (newUnit === SIZE_UNITS.PX && value < MIN_AXIS_SIZE)
      value = MIN_AXIS_SIZE;

    if (!isValidSize({ value, unit: newUnit })) return;

    if (newUnit === SIZE_UNITS.FIT) {
      value = SIZE_UNITS.FIT;
      newUnit = '';
    }

    if (newUnit === SIZE_UNITS.FILL) {
      value = SIZE_UNITS.FILL;
      newUnit = '';
    }

    handleResize({ value, unit: newUnit });
  };
}
