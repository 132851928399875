/* eslint-disable react-hooks/exhaustive-deps */

import {
  ComparisonRule,
  DecisionLogic
} from '../components/Panels/PropertiesPanel/components/LogicRulesSection';
import { ValueType } from '../components/Modals/DecisionLogicModal';
import { useAppSelector } from '../hooks';

import { createDraft, finishDraft, produce } from 'immer';
import { ConditionData as Condition } from '../components/NavigationRules/components/types';
import { EQUAL_OPERATORS, SELECTION_OPERATORS } from './validation';
import {
  applyToStepElements,
  reduceStepElements,
  someStepElements
} from './step';
import jsonpath from 'jsonpath';
import { LogicRule } from '../pages/FormLogicPage/LogicRuleList';
import { isEmptyDSL } from '../pages/LogicRuleDetail/components/RuleBuilder/utils';
import {
  isDSLUsingField,
  patchDSLFieldKeys
} from '../pages/LogicRuleDetail/utils';
import { transformToCode } from '../pages/LogicRuleDetail/components/RuleBuilder/transform';

interface Element {
  id: string;
  properties: any;
  servar?: { id: string; key: string };
  hide_ifs?: any[];
  validations?: DecisionLogic[];
}
export interface Step {
  id: string;
  key: string;
  origin?: boolean;
  next_conditions: Condition[];
  previous_conditions: Condition[];
  texts: Element[];
  buttons: Element[];
  progress_bars: Element[];
  images: Element[];
  videos: Element[];
  servar_fields: Element[];
  subgrids: Element[];
}
interface HiddenOrServarField {
  id: string;
  servar?: { id: string };
}
interface PropertyFieldReference {
  // The property that holds a field reference (i.e. an id)
  // It is a jsonpath relative to properties sub-object of any elemen/field
  idProperty: string;
  // Other properties associated with the idProperty that must also be cleaned.
  // Array of jsonpaths relative to idProperty's parent!!!
  otherProperties?: string[];
}

/**
 * This cleans an element's validations that reference the removed or replaced servar as follows:
 * 1. For any validation that contains a comparison rule that references the servar on the LEFT side,
 *    the rule will be removed or the servar replaced.
 * 2. For any validation that contains a comparison rule that references the servar on the RIGHT side in
 *    value, the value will be removed from the rule (or servar replaced) .  If the value is the last one,
 *    then the rule will be removed ((or servar replaced) ).
 * @param element
 * @param deletedElement deleted element
 * @param replacementElement optional replacement element, could be a servar
 * @returns
 */
const cleanElementValidations = (
  element: Element,
  deletedElement: any, // element being removed, could be a servar
  replacementElement?: any // optional replacement element, could be a servar
) => {
  if (!deletedElement.servar) return element;
  const newElement = { ...element };
  if (newElement.validations) {
    newElement.validations = newElement.validations.map(
      (validation: DecisionLogic) => ({
        ...validation,
        rules: cleanupConditions(
          validation.rules,
          deletedElement,
          replacementElement
        )
      })
    );
    // remove any validations that have no rules left
    newElement.validations = newElement.validations.filter(
      (validation: DecisionLogic) => validation.rules.length
    );
  }
  return newElement;
};

/**
 * This cleans conditions (i.e. ComparisonRules) that reference the removed/replaced servar element as follows:
 * 1. For any comparison rule that references the servar on the LEFT side,
 *    the rule will be removed or the element replaced.
 * 2. For any comparison rule that references the servar on the RIGHT side in
 *    value, the value will be removed from the rule or element replaced.  If the value is the last one,
 *    then the rule will be removed (if not a replacement).
 * @param rules
 * @param element element being removed or replaced
 * @param replacementElement optional replacement element, could be a servar
 * @returns
 */
const cleanupConditions = (
  rules: ComparisonRule[],
  element: any, // element being removed, could be a servar
  replacementElement?: any // optional replacement element, could be a servar
) => {
  if (!element.servar) return rules;
  const servarId = element.servar.id;
  if (replacementElement) {
    const replacementServarId = replacementElement.servar.id;
    const replacementServarKey = replacementElement.servar.key;
    // replace the servar in the rule if present
    return rules.map((rule) => {
      let fieldId = rule.field_id;
      let fieldKey = rule.field_key;
      if (rule.field_type === 'servar' && rule.field_id === servarId) {
        fieldId = replacementServarId;
        fieldKey = replacementServarKey;
      }
      return {
        ...rule,
        field_id: fieldId,
        field_key: fieldKey,
        values: rule.values.map((value: ValueType) =>
          typeof value !== 'object' || // narrowing for TS
          !(value.field_type === 'servar' && value.field_id === servarId)
            ? value
            : {
                ...value,
                field_id: replacementServarId,
                field_key: replacementServarKey
              }
        )
      };
    });
  } else {
    // clean the right side first
    const cleanedRules = rules.map((rule) => ({
      ...rule,
      values: rule.values.filter(
        (value: ValueType) =>
          typeof value !== 'object' || // narrowing for TS
          !(value.field_type === 'servar' && value.field_id === servarId)
      )
    }));
    return cleanedRules.filter(
      (rule, index) =>
        !(rule.field_type === 'servar' && rule.field_id === servarId) &&
        // If there are NO values on RHS and there were before (meaning it is an in-fix operator), then
        // remove it
        (!rules[index].values.length || rule.values.length)
    );
  }
};

/**
 * For a given step, fixup up any references (servar field or element) in the
 * subset of elements in the step to other elements in the subset.  This
 * cleans up hide_ifs, validations, and properties.
 * @param step The step to be cleaned/fixed up (specified elements only)
 * @param steps All steps in the form
 * @param changedElementsMap map of old element ids to new element ids.  The new elements are the element ids to be cleaned/fixed up in the step, others elements in the step are left as is
 * @returns
 */
export function fixupElementsInStep(
  step: Step,
  steps: Step[],
  changedElementsMap: { [oldElementId: string]: string }
): Step {
  const stepElementMap = reduceStepElements(step, {
    callback: (elementMap: any, element: any) => {
      elementMap[element.id] = element;
      return elementMap;
    },
    initialValue: {}
  });
  let allElementsMap: { [id: string]: any } = {};
  Object.values(steps).forEach((step) => {
    allElementsMap = {
      ...allElementsMap,
      ...reduceStepElements(step, {
        callback: (elementMap: any, element: any) => {
          elementMap[element.id] = element;
          return elementMap;
        }
      })
    };
  });

  // elements to be cleaned (new duplicated elements)
  const elementIds = Object.values(changedElementsMap);

  Object.entries(changedElementsMap).forEach(([oldElementId, newElementId]) => {
    // element being removed or replaced, could be a servar
    const element = allElementsMap[oldElementId];
    // replacement element, could be a servar
    const replacementElement = stepElementMap[newElementId];

    if (element && replacementElement) {
      // clean/replace in hide show rules
      step = applyToStepElements(
        step,
        {
          map: (elementToClean: any) => {
            if (elementIds.includes(elementToClean.id))
              elementToClean.hide_ifs = cleanupConditions(
                elementToClean.hide_ifs,
                element,
                replacementElement
              );
            return elementToClean;
          }
        },
        true
      ) as any;

      // clean/replace in validations
      step = applyToStepElements(step, {
        map: (elementToClean: any) =>
          elementIds.includes(elementToClean.id)
            ? cleanElementValidations(
                elementToClean,
                element,
                replacementElement
              )
            : elementToClean
      }) as any;

      // clear out or replace any property references to the element
      step = applyToStepElements(
        step,
        {
          map: (elementToClean: any) =>
            elementIds.includes(elementToClean.id)
              ? cleanupElementProperties(
                  elementToClean,
                  element,
                  replacementElement
                )
              : elementToClean
        },
        true
      ) as any;
    }
  });

  return step;
}

const propertiesWithFieldReferences: PropertyFieldReference[] = [
  {
    idProperty: '$.actions[*].quantity_field.id',
    otherProperties: ['$.type']
  },
  {
    idProperty: '$.actions[*].auth_target_field',
    otherProperties: ['$.auth_target_field_type']
  },
  {
    idProperty: '$.actions[*].custom_store_field',
    otherProperties: ['$.custom_store_field_type']
  },
  {
    idProperty: '$.actions[*].select_field_indicator',
    otherProperties: ['$.select_field_indicator_type']
  },
  {
    idProperty: '$.uploaded_image_file_field',
    otherProperties: ['$.uploaded_image_file_field_type']
  }
];

// clean out or replace references for a particular field from an element/field's properties
const cleanupElementProperties = (
  element: Element,
  field: HiddenOrServarField,
  replacementField?: HiddenOrServarField
) => {
  const elementProperties =
    JSON.parse(JSON.stringify(element.properties)) ?? {};
  const id = field.servar ? field.servar.id : field.id;
  const replacementId = replacementField
    ? replacementField.servar
      ? replacementField.servar.id
      : replacementField.id
    : null;

  propertiesWithFieldReferences.forEach((refProp) => {
    const index = refProp.idProperty.lastIndexOf('.');
    const parentPath = refProp.idProperty.slice(0, index);
    const idProp = refProp.idProperty.slice(index + 1);

    jsonpath.query(elementProperties, parentPath).forEach((parentObj) => {
      // is this a reference?
      if (parentObj[idProp] === id) {
        // yes - clear it (or replace it) and the other (associated) properties
        parentObj[idProp] = replacementId ? replacementId : '';

        if (!replacementId)
          refProp.otherProperties &&
            refProp.otherProperties.forEach((otherPropPath) => {
              const index = otherPropPath.lastIndexOf('.');
              const otherPropParentPath = otherPropPath.slice(0, index);
              const otherProp = otherPropPath.slice(index + 1);
              jsonpath
                .query(parentObj, otherPropParentPath)
                .forEach(
                  (otherPropContainer) => (otherPropContainer[otherProp] = '')
                );
            });
      }
    });
  });
  element.properties = elementProperties;
  return element;
};

export default function useElementRefCleanup() {
  const servarUsage = useAppSelector((state) => state.formBuilder.servarUsage);

  /**
   * Cleanup all references to the element (servar field or element) in the form steps and logic rules.
   * @param steps
   * @param element
   * @param changedStepKeys
   * @param replacementElement
   * @returns Object of cleaned items: { steps, logicRules }
   */
  function cleanupAllElementReferences(
    steps: any,
    element: any,
    changedStepKeys = new Set<string>(),
    logicRules: { [id: string]: LogicRule },
    changedLogicRuleKeys = new Set<string>(),
    replacementElement?: any
  ) {
    // only cleanup servar field references if the servar is not used anywhere else (linked)
    if (
      element.servar &&
      servarUsage[element.servar.id] &&
      servarUsage[element.servar.id].length > 1
    )
      return { steps, logicRules };

    // cleanup all references to the element in the form steps
    const cleanedSteps = produce(
      steps,
      (draft: any) => {
        // only change the draft if actually changing anything

        Object.values(draft).forEach((step: any) => {
          // Only change the draft if actually changing anything!

          // Clear out or replace any hide_if configurations based on the element
          if (
            someStepElements(
              draft[step.id],
              {
                test: (elementToTest: any) =>
                  conditionReferencesField(elementToTest.hide_ifs, element)
              },
              true
            )
          ) {
            draft[step.id] = applyToStepElements(
              draft[step.id],
              {
                map: (elementToClean: any) => {
                  elementToClean.hide_ifs = cleanupConditions(
                    elementToClean.hide_ifs,
                    element,
                    replacementElement
                  );
                  return elementToClean;
                }
              },
              true
            );
          }

          // Clear out or replace any validation rules in the form that reference the deleted servar field
          if (
            someStepElements(draft[step.id], {
              test: (elementToTest: any) =>
                elementToTest.validations &&
                elementToTest.validations.some((validation: any) =>
                  conditionReferencesField(validation.rules, element)
                )
            })
          )
            draft[step.id] = applyToStepElements(draft[step.id], {
              map: (elementToClean: any) =>
                cleanElementValidations(
                  elementToClean,
                  element,
                  replacementElement
                )
            });

          // Clear out or replace any navigation rules in the form that depend on the deleted element
          if (
            draft[step.id].next_conditions.some(
              (cond: any) =>
                cond.element_id === element.id ||
                conditionReferencesField(cond.rules, element)
            ) ||
            draft[step.id].previous_conditions.some(
              (cond: any) =>
                cond.element_id === element.id ||
                conditionReferencesField(cond.rules, element)
            )
          )
            draft[step.id] = {
              ...draft[step.id],
              next_conditions: draft[step.id].next_conditions
                .filter((cond: any) => cond.element_id !== element.id)
                .map((cond: any) => ({
                  ...cond,
                  rules: cleanupConditions(
                    cond.rules,
                    element,
                    replacementElement
                  )
                })),
              previous_conditions: draft[step.id].previous_conditions
                .filter((cond: any) => cond.element_id !== element.id)
                .map((cond: any) => ({
                  ...cond,
                  rules: cleanupConditions(
                    cond.rules,
                    element,
                    replacementElement
                  )
                }))
            };

          // clear out or replace any property references
          if (
            someStepElements(draft[step.id], {
              test: (elementToTest: any) =>
                elementPropertiesReferenceField(elementToTest, element)
            }) ||
            draft[step.id].subgrids?.some((subgrid: any) =>
              elementPropertiesReferenceField(subgrid, element)
            )
          ) {
            // clean the props
            draft[step.id] = applyToStepElements(draft[step.id], {
              map: (elementToClean: any) =>
                cleanupElementProperties(
                  elementToClean,
                  element,
                  replacementElement
                )
            });
            // don't forget the subgrids
            draft[step.id].subgrids &&
              draft[step.id].subgrids.forEach((subgrid: any) =>
                cleanupElementProperties(subgrid, element, replacementElement)
              );
          }
        });
      },
      (patches) => {
        // tracking real step changes
        patches.forEach((patch) => {
          patch.path &&
            patch.path.length &&
            changedStepKeys.add(patch.path[0].toString());
        });
      }
    );

    // Cleanup all references to the element in the logic rules.
    // For change event, the elements are servar ids.
    // For other events, the elements are element ids (or container ids)
    const cleanedLogicRules = produce(
      logicRules,
      (draft) => {
        Object.values(draft).forEach((logicRule) => {
          // Only change the draft if actually changing anything!
          if (
            logicRule.elements &&
            logicRule.elements.some((elementId) =>
              element.servar
                ? elementId === element.servar.id
                : elementId === element.id
            )
          ) {
            // clean out or replace any rule.element references to the deleted element
            // with the replacementElement (id or servar.id as appropriate)
            const elements = logicRule.elements.filter((elementId) =>
              element.servar
                ? elementId !== element.servar.id
                : elementId !== element.id
            );
            if (replacementElement) {
              // replacing with replacementElement.servar.id or replacementElement.id?
              const replacementElementId =
                replacementElement.servar &&
                logicRule.elements.some((elementId) =>
                  element.servar
                    ? elementId === element.servar.id
                    : elementId === element.id
                )
                  ? replacementElement.servar.id
                  : replacementElement.id;
              elements.push(replacementElementId);
            }

            draft[logicRule.id] = {
              ...logicRule,
              elements
            };
          }
        });
      },
      (patches) => {
        // tracking real logicRule changes
        patches.forEach((patch) => {
          patch.path &&
            patch.path.length &&
            changedLogicRuleKeys.add(patch.path[0].toString());
        });
      }
    );

    return { steps: cleanedSteps, logicRules: cleanedLogicRules };
  }

  async function cleanupLogicRuleElementReferences(
    element: any,
    logicRules: { [id: string]: LogicRule },
    changedLogicRuleKeys = new Set<string>(),
    newKey?: string
  ) {
    const rulesUsingServar = Object.values(logicRules).filter((rule: any) => {
      if (rule.mode === 'code_editor' || isEmptyDSL(rule.dsl)) return false;
      else return isDSLUsingField(rule.dsl, element.servar.id);
    });

    if (rulesUsingServar.length) {
      const newLogicRules = createDraft(logicRules);

      for (const rule of rulesUsingServar) {
        changedLogicRuleKeys.add(rule.id);

        newLogicRules[rule.id].dsl = patchDSLFieldKeys(
          rule.dsl as IRuleDSL,
          element.servar.id,
          newKey ?? element.servar.key
        );

        newLogicRules[rule.id].code = await transformToCode(
          newLogicRules[rule.id].dsl as IRuleDSL
        );
      }

      return finishDraft(newLogicRules);
    }

    return logicRules;
  }

  // Helper function for deleting an element from a given step in the Step Editor
  function deleteElementById(
    steps: any,
    stepId: any,
    stepElementProp: any,
    elementId: any,
    changedStepKeys = new Set<string>(),
    logicRules: any,
    changedLogicRuleKeys: Set<string>
  ) {
    const element = steps[stepId][stepElementProp].find(
      (el: any) => el.id === elementId
    );
    return deleteElement(
      steps,
      stepId,
      stepElementProp,
      element,
      changedStepKeys,
      logicRules,
      changedLogicRuleKeys
    );
  }
  function deleteElement(
    steps: any,
    stepId: any,
    stepElementProp: any,
    element: any,
    changedStepKeys: Set<string>,
    logicRules: any,
    changedLogicRuleKeys: Set<string>
  ) {
    const elementId = element.id;

    const { steps: newSteps, logicRules: newLogicRules } =
      cleanupAllElementReferences(
        steps,
        element,
        changedStepKeys,
        logicRules,
        changedLogicRuleKeys
      );

    return {
      steps: produce(
        newSteps,
        (draft: any) => {
          // only change the draft if actually changing anything
          if (
            draft[stepId][stepElementProp].some(
              (el: any) => el.id === elementId
            )
          )
            draft[stepId][stepElementProp] = draft[stepId][
              stepElementProp
            ].filter((el: any) => el.id !== elementId);
        },
        (patches) => {
          // tracking real step changes
          patches.forEach((patch) => {
            patch.path &&
              patch.path.length &&
              changedStepKeys.add(patch.path[0].toString());
          });
        }
      ),
      logicRules: newLogicRules
    };
  }

  function deleteElements(
    steps: any,
    stepId: any,
    elementsMap: any,
    changedStepKeys = new Set<string>(),
    logicRules: any,
    changedLogicRuleKeys = new Set<string>()
  ) {
    let newLogicRules = logicRules;
    steps = produce(steps, (draft: any) => {
      Object.entries(elementsMap).forEach(([stepElementProp, elements]) => {
        (elements as any).forEach((element: any) => {
          const { steps: newSteps, logicRules } = deleteElement(
            draft,
            stepId,
            stepElementProp,
            element,
            changedStepKeys,
            newLogicRules,
            changedLogicRuleKeys
          );
          newLogicRules = logicRules;

          Object.entries(newSteps).forEach(
            ([stepId, step]) => (draft[stepId] = step)
          );
        });
      });
    }) as any;
    return { steps, logicRules: newLogicRules };
  }

  const conditionReferencesField = (
    rules: ComparisonRule[],
    field: HiddenOrServarField // element/field being removed, could be a servar or hidden
  ) => {
    const id = field.servar ? field.servar.id : field.id;
    const type = field.servar ? 'servar' : 'hidden';
    return rules.some(
      (rule) =>
        rule.values.some(
          (value: ValueType) =>
            typeof value === 'object' &&
            value.field_type === type &&
            value.field_id === id
        ) ||
        (rule.field_type === type && rule.field_id === id)
    );
  };

  // Do any of the the element/field's properties reference the field (servar or hidden)?
  const elementPropertiesReferenceField = (
    element: { properties: any },
    field: HiddenOrServarField
  ) => {
    const elementProperties = element.properties ?? {};
    const id = field.servar ? field.servar.id : field.id;

    return propertiesWithFieldReferences.some((refProp) =>
      jsonpath
        .query(elementProperties, refProp.idProperty)
        .some((value: any) => value === id)
    );
  };

  function isFieldReferenced(
    field: HiddenOrServarField, // element/field being removed, could be a servar or hidden
    steps: { [stepKey: string]: any }
  ) {
    const id = field.servar ? field.servar.id : field.id;
    return Object.values(steps).reduce(
      (isRef, step) =>
        isRef ||
        // check hide-ifs, validations and properties on every field/element type
        someStepElements(step, {
          test: (elementToTest: any) =>
            (elementToTest.hide_ifs &&
              conditionReferencesField(elementToTest.hide_ifs, field)) ||
            (elementToTest.validations &&
              elementToTest.validations.some((validation: any) =>
                conditionReferencesField(validation.rules, field)
              )) ||
            elementPropertiesReferenceField(elementToTest, field)
        }) ||
        // check subgrid properties as well
        step.subgrids.some((subgrid: any) =>
          elementPropertiesReferenceField(subgrid, field)
        ) ||
        // check for navigation rules in the step that depend on the field
        step.next_conditions.some(
          (cond: any) =>
            cond.element_id === id ||
            conditionReferencesField(cond.rules, field)
        ) ||
        step.previous_conditions.some(
          (cond: any) =>
            cond.element_id === id ||
            conditionReferencesField(cond.rules, field)
        ),
      false
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////
  // Logic to fixup rules that reference old options that have been change for a field
  ////////////////////////////////////////////////////////////////////////////////////////
  const optionOperators = { ...EQUAL_OPERATORS, ...SELECTION_OPERATORS };
  // This rule needed fixing if the LHS is the field id and the comparison is one of the option operators
  // and at least one of the values is an old option that needs to be replaced
  // because it's not in the new options
  const ruleOptionsNeedFixing = (
    rule: ComparisonRule,
    field: { servar: { id: string; metadata: { options: string[] } } },
    newOptions: string[]
  ) =>
    rule.field_id === field.servar.id &&
    rule.comparison in optionOperators &&
    rule.values.some(
      (value: ValueType) =>
        typeof value === 'string' && !newOptions.includes(value)
    );
  const rulesNeedOptionFixup = (
    rules: ComparisonRule[],
    field: { servar: { id: string; metadata: { options: string[] } } },
    newOptions: string[]
  ) => {
    return rules.some((rule) => ruleOptionsNeedFixing(rule, field, newOptions));
  };

  const fixupRuleOptions = (
    rules: ComparisonRule[],
    field: { servar: { id: string; metadata: { options: string[] } } },
    oldToNewOptionMap: { [oldOption: string]: string },
    newOptions: string[]
  ): ComparisonRule[] => {
    // create a new rules array with the options fixed up
    return rules.map((rule) => {
      // Fixup this if the LHS is the field id and the comparison is one of the option operators
      // and at least one of the values is an old option that needs to be replaced
      // because it's not in the new options
      if (ruleOptionsNeedFixing(rule, field, newOptions)) {
        // create a new rule with the options fixed up
        return {
          ...rule,
          values: rule.values.map((value) => {
            if (typeof value === 'string') {
              // if the old option is in the new options, use it
              // otherwise if the old option is found in the oldToNewOptionMap, use the new option
              // otherwise keep the old option as is
              if (newOptions.includes(value)) {
                return value;
              }
              return oldToNewOptionMap[value] ?? value;
            } else {
              return value;
            }
          })
        };
      } else {
        // no need to fix up this rule
        return rule;
      }
    });
  };

  function fixupRuleOptionsReferences(
    steps: any,
    field: { servar: { id: string; metadata: { options: string[] } } },
    newOptions: string[],
    changedStepKeys = new Set<string>()
  ) {
    // create a map of old option values to new option values
    const oldToNewOptionMap = newOptions.reduce((map, newOption, index) => {
      const options = field.servar.metadata.options;
      if (options && options.length >= index) map[options[index]] = newOption;
      return map;
    }, {} as { [oldOption: string]: string });

    // use immer to produce a new steps object with the options fixed up
    return produce(
      steps,
      (draftSteps: any) => {
        Object.values(draftSteps).forEach((step: any) => {
          // Because we are tracking changes using immer patches for efficiency with drafts,
          //  only change the draft if actually need to change it.

          // Fix up any hide_if configurations based on the field and changed options
          if (
            someStepElements(
              draftSteps[step.id],
              {
                test: (elementToTest: any) =>
                  rulesNeedOptionFixup(
                    elementToTest.hide_ifs,
                    field,
                    newOptions
                  )
              },
              true
            )
          ) {
            draftSteps[step.id] = applyToStepElements(
              draftSteps[step.id],
              {
                map: (elementToClean: any) => {
                  elementToClean.hide_ifs = fixupRuleOptions(
                    elementToClean.hide_ifs,
                    field,
                    oldToNewOptionMap,
                    newOptions
                  );
                  return elementToClean;
                }
              },
              true
            );
          }

          // Fix up any validation rules in the form that are based on the field and changed options
          if (
            someStepElements(draftSteps[step.id], {
              test: (elementToTest: any) =>
                elementToTest.validations &&
                elementToTest.validations.some((validation: any) =>
                  rulesNeedOptionFixup(validation.rules, field, newOptions)
                )
            })
          ) {
            draftSteps[step.id] = applyToStepElements(draftSteps[step.id], {
              map: (elementToClean: any) => {
                const newElement = { ...elementToClean };
                if (newElement.validations) {
                  newElement.validations = newElement.validations.map(
                    (validation: DecisionLogic) => ({
                      ...validation,
                      rules: fixupRuleOptions(
                        validation.rules,
                        field,
                        oldToNewOptionMap,
                        newOptions
                      )
                    })
                  );
                }
                return newElement;
              }
            });
          }

          // Fix up any navigation rules in the form that are based on the field and changed options
          if (
            draftSteps[step.id].next_conditions.some((cond: any) =>
              rulesNeedOptionFixup(cond.rules, field, newOptions)
            ) ||
            draftSteps[step.id].previous_conditions.some((cond: any) =>
              rulesNeedOptionFixup(cond.rules, field, newOptions)
            )
          )
            draftSteps[step.id] = {
              ...draftSteps[step.id],
              next_conditions: draftSteps[step.id].next_conditions.map(
                (cond: any) => ({
                  ...cond,
                  rules: fixupRuleOptions(
                    cond.rules,
                    field,
                    oldToNewOptionMap,
                    newOptions
                  )
                })
              ),
              previous_conditions: draftSteps[step.id].previous_conditions.map(
                (cond: any) => ({
                  ...cond,
                  rules: fixupRuleOptions(
                    cond.rules,
                    field,
                    oldToNewOptionMap,
                    newOptions
                  )
                })
              )
            };
        });
      },
      (patches) => {
        // tracking real step changes
        patches.forEach((patch) => {
          patch.path &&
            patch.path.length &&
            changedStepKeys.add(patch.path[0].toString());
        });
      }
    );
  }

  return {
    fixupElementsInStep,
    cleanupAllElementReferences,
    cleanupLogicRuleElementReferences,
    fixupRuleOptionsReferences,
    isFieldReferenced,
    deleteElements,
    deleteElementById
  };
}
