import { SVGProps } from 'react';

const FolderIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={20}
    height={20}
    viewBox='0 0 20 20'
    fill='none'
    {...props}
  >
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.5}
      d='M18.334 15.833a1.666 1.666 0 0 1-1.667 1.667H3.334a1.667 1.667 0 0 1-1.667-1.667V4.167A1.667 1.667 0 0 1 3.334 2.5H7.5L9.167 5h7.5a1.667 1.667 0 0 1 1.667 1.667v9.166Z'
    />
  </svg>
);
export default FolderIcon;
