export default function NumberIcon() {
  return (
    <svg viewBox='0 0 50 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M37.9223 32.7163C37.0939 32.7163 36.4223 33.3879 36.4223 34.2163C36.4223 35.0447 37.0939 35.7163 37.9223 35.7163V32.7163ZM2.21753 11.5C1.3891 11.5 0.717529 12.1716 0.717529 13C0.717529 13.8284 1.3891 14.5 2.21753 14.5V11.5ZM45.2363 32.7163H37.9223V35.7163H45.2363V32.7163ZM46.5 31.4489C46.5 32.1507 45.9324 32.7163 45.2363 32.7163V35.7163C47.593 35.7163 49.5 33.8039 49.5 31.4489H46.5ZM46.5 15.7673V31.4489H49.5V15.7673H46.5ZM45.2363 14.5C45.9324 14.5 46.5 15.0655 46.5 15.7673H49.5C49.5 13.4124 47.593 11.5 45.2363 11.5V14.5ZM2.21753 14.5H45.2363V11.5H2.21753V14.5Z'
        fill='#414859'
      />
      <path
        d='M6.00508 35.3329V19.7852H3.16631L0 22.5367V25.5938L3.16631 22.8424V35.3329H6.00508Z'
        fill='#414859'
      />
      <path
        d='M20.5694 35.3329V32.778H14.5644L18.9099 27.7338C19.9799 26.4891 20.5694 25.6811 20.5694 24.1744C20.5694 21.3793 18.6041 19.6542 15.8527 19.6542C13.1887 19.6542 11.1142 21.3575 11.1142 24.1962H13.9529C13.9529 22.6895 14.9137 22.2091 15.8527 22.2091C17.0537 22.2091 17.7307 22.9952 17.7307 24.1526C17.7307 24.8732 17.4905 25.3536 16.9227 26.0305L11.1142 32.778V35.3329H20.5694Z'
        fill='#414859'
      />
      <path
        d='M32.7099 30.8346C32.7099 28.9566 31.8146 27.8866 30.701 27.297C31.7273 26.7511 32.4916 25.7903 32.4916 24.1089C32.4916 21.4885 30.5044 19.6542 27.753 19.6542C25.1326 19.6542 23.0581 21.3138 22.9926 24.0871H25.8314C25.8969 22.9079 26.683 22.2091 27.753 22.2091C28.7793 22.2091 29.6528 22.886 29.6528 24.1962C29.6528 25.2881 29.0632 26.1397 27.6875 26.1397H27.2726V28.6072H27.6875C29.085 28.6072 29.8712 29.4589 29.8712 30.7472C29.8712 32.1448 28.9977 32.9091 27.7749 32.9091C26.6612 32.9091 25.7004 32.2758 25.6567 30.8783H22.8179C22.8616 34.1319 25.2418 35.464 27.7749 35.464C30.4389 35.464 32.7099 33.9354 32.7099 30.8346Z'
        fill='#414859'
      />
    </svg>
  );
}
