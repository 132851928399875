/* eslint-disable react-hooks/exhaustive-deps */

import { memo, useMemo, useState } from 'react';

import HeaderFilterResultsTable from '../components/HeaderFilterResultsTable';
import { UsageEnforcement } from '../components/PricingPlans';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from '../hooks';
import { formatDate } from '../utils/format';
import { CreateDocumentModal, DeleteDocumentModal } from '../components/Modals';
import { TagsContainer } from '../components/TagsContainer';
import MoveDocumentModal from '../components/Modals/MoveDocumentModal';

function getDocumentColumns(hasTag: any) {
  return [
    { key: 'key', name: 'Name' },
    { key: 'id', name: 'ID' },
    {
      key: 'updated_at_pretty',
      name: 'Last Updated',
      sortBy: 'updated_at'
    },
    ...(hasTag
      ? [{ key: 'tags', name: 'Tags', noSort: true, filterKey: 'tag_metadata' }]
      : [])
  ];
}

function DocumentsPage() {
  const history = useHistory();

  const [docToDelete, setDocToDelete] = useState<any>(null);
  const [docToMove, setDocToMove] = useState<any>(null);
  const [createFolder, setCreateFolder] = useState('');

  const documents = useAppSelector((state) => state.documents.documents);
  const account = useAppSelector((state) => state.accounts.account);

  const [showCreateDocumentModal, setShowCreateDocumentModal] = useState(false);

  const [dataToRender, hasTag] = useMemo(() => {
    const data = Object.values(documents);
    const toRender = data.map((doc: any) => {
      const tags = <TagsContainer tags={doc.tag_metadata} />;

      return {
        ...doc,
        tags,
        updated_at_pretty: formatDate(doc.updated_at)
      };
    });
    const hasTag = data.some((doc: any) => doc.tag_metadata);
    return [toRender, hasTag];
  }, [documents]);

  let onCreate;
  if (account.role !== 'viewer')
    onCreate = (folder: any) => {
      setCreateFolder(folder);
      setShowCreateDocumentModal(true);
    };

  return (
    <>
      <div className={styles.pageHeadRow}>
        <h1 className={classNames('page-head', styles.pageHeadTitle)}>
          Documents
        </h1>
        <UsageEnforcement />
      </div>
      <HeaderFilterResultsTable
        data={dataToRender}
        columns={getDocumentColumns(hasTag)}
        defaultSort={{ order: -1, key: 'updated_at' }}
        useSearch
        hasFolders
        name='Document'
        onCreate={onCreate}
        onSelect={(doc: any) => {
          const nextPage =
            account.role === 'viewer'
              ? `/documents/${doc.id}/envelopes`
              : `/documents/${doc.id}`;
          history.push(nextPage);
        }}
        onDelete={(doc: any) => setDocToDelete(doc)}
        onMoveToFolder={(doc: any) => setDocToMove(doc)}
      />
      <DeleteDocumentModal
        close={() => setDocToDelete(null)}
        show={Boolean(docToDelete)}
        documentId={docToDelete?.id}
        name={docToDelete?.key}
      />
      <MoveDocumentModal
        key={docToMove?.id}
        show={Boolean(docToMove)}
        setShow={() => setDocToMove(null)}
        documentId={docToMove?.id}
        name={docToMove?.key}
      />
      <CreateDocumentModal
        close={() => setShowCreateDocumentModal(false)}
        show={showCreateDocumentModal}
        folder={createFolder}
      />
    </>
  );
}

export default memo(DocumentsPage);
