import useFeatheryRedux from '../redux';
import { useAppSelector } from './index';

export default function useGridMode() {
  const gigGridMode = useAppSelector(
    (state) => state.accounts.account.grid_mode_toggle
  );
  const { editAccount } = useFeatheryRedux();

  const toggleGridMode = () => editAccount({ grid_mode_toggle: !gigGridMode });
  return { toggleGridMode, gridMode: gigGridMode };
}
