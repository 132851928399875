import {
  CSSProperties,
  ReactElement,
  RefObject,
  useEffect,
  useRef,
  memo
} from 'react';
import { DragLayerMonitor, useDragLayer, XYCoord } from 'react-dnd';
import styles from './styles.module.scss';
import { point } from '../RenderingEngine/Controls/utils';
import { useAppSelector } from '../../hooks';

const layerId = 'drag-layer';

export interface CustomDragOptions {
  preview?: {
    adjustedOffset?: point;
    width?: number;
    height?: number;
    x?: number;
    y?: number;
    opacity?: number;
    className?: string;
    // Used to anchor the top left corner or center of the drag preview to the cursor
    // Makes positioning more precise.
    anchor?: 'topLeft' | 'center';
  };
  onEnd?: () => void;
  onCanDrag?: () => boolean;
  duration?: number;
  useDefaultPreview?: boolean;
}

const defaultOpts: Required<CustomDragOptions> = {
  preview: {
    width: 200,
    height: 50,
    opacity: 0.7
  },
  duration: 600,
  useDefaultPreview: false,
  onEnd: () => {},
  onCanDrag: () => true
};

const getItemStyle = (
  currentOffset: XYCoord | null
): CSSProperties | undefined => {
  if (!currentOffset) return undefined;
  const { x, y } = currentOffset;
  return {
    transform: `translate(${x}px, ${y}px)`,
    position: 'absolute'
  };
};

const getCenterItemStyle = (
  opts: CustomDragOptions
): CSSProperties | undefined => {
  const enabled = opts?.preview?.anchor === 'center';
  if (!enabled) return undefined;
  return {
    transform: `translate(-50%, -50%)`,
    position: 'absolute',
    minWidth: '100px',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  };
};

const addElementToDragLayer = (el: HTMLElement): HTMLElement => {
  const ghost = el.cloneNode(true);
  document.getElementById(layerId)?.appendChild(ghost);
  return ghost as HTMLElement;
};

const startTransitionAndRemove = (ghostId: string, nextStyle: any) => {
  const ghost: HTMLElement | null = document.getElementById(ghostId);

  if (!ghost) {
    return;
  }

  ghost.addEventListener('transitionend', () => {
    if (ghost) ghost.remove();
  });

  ghost.addEventListener('transitioncancel', () => {
    if (ghost) ghost.remove();
  });

  setTimeout(() => {
    if (ghost) ghost.remove();
  }, 500);

  // Start the transition on the next tick or else the styles will be immediately applied
  setTimeout(() => {
    ghost.style.transition = nextStyle.transition;
    ghost.style.transform = nextStyle.transform;
    ghost.style.opacity = nextStyle.opacity;
  }, 0);
};

interface TransitionStyle {
  transition: string;
  transform: string;
  opacity: number;
}

const getTransitionStyle = (
  offset: XYCoord,
  opts?: CustomDragOptions
): TransitionStyle => {
  const duration = opts?.duration || defaultOpts.duration;
  return {
    transition: `transform ${duration}ms, opacity ${duration + 300}ms`,
    transform: `translate(${offset.x}px, ${offset.y}px)`,
    opacity: 0
  };
};

const getPreviewStyle = (
  monitor: DragLayerMonitor,
  opts?: CustomDragOptions
): CSSProperties => {
  const opacity = opts?.preview?.opacity || defaultOpts.preview.opacity;
  let width = opts?.preview?.width || defaultOpts.preview.width;
  let height = opts?.preview?.height || defaultOpts.preview.height;

  const sourceNode = getMonitorSourceNode(monitor);
  if (sourceNode) {
    width = opts?.preview?.width || sourceNode.getBoundingClientRect().width;
    height = opts?.preview?.height || sourceNode.getBoundingClientRect().height;
  }

  return { width, height, opacity };
};

const getMonitorSourceNode = (
  monitor: DragLayerMonitor
): HTMLElement | null => {
  // @ts-ignore monitor.registry *does* exist despite not being defined
  const source = monitor?.registry?.pinnedSource;

  return source?.connector?.dragSourceNode || null;
};

const DefaultPreview = (props: {
  monitor: DragLayerMonitor;
}): ReactElement | null => {
  const ref: RefObject<HTMLDivElement> = useRef(null);

  useEffect(() => {
    const sourceNode = getMonitorSourceNode(props.monitor);
    if (ref.current && sourceNode) {
      ref.current.appendChild(sourceNode.cloneNode(true));
    }
  }, [props.monitor]);

  return (
    <div
      id={'default-preview'}
      style={{ width: '100%', height: '100%' }}
      ref={ref}
    />
  );
};

const Preview = ({
  item,
  monitor,
  currentOffset,
  initialOffset,
  opts
}: {
  item: any;
  monitor: any;
  currentOffset: XYCoord | null;
  initialOffset: XYCoord | null;
  opts?: CustomDragOptions;
}) => {
  const previewRef = useRef(null);
  useEffect(() => {
    // Create the fading preview ghost element on unmount
    const currentRef = previewRef.current;
    const initialOffsetCopy = initialOffset ? { ...initialOffset } : null;
    const optsCopy = { ...opts };
    return () => {
      if (item.didDrop || currentRef === null || initialOffsetCopy === null) {
        return;
      }
      const ghost = addElementToDragLayer(currentRef);
      const ghostId = `ghost-${Math.floor(Math.random() * 10000)}`;
      ghost.id = ghostId;
      startTransitionAndRemove(
        ghostId,
        getTransitionStyle(initialOffsetCopy, optsCopy)
      );
    };
  }, []);

  if (!item.customRenderer) {
    return (
      <div ref={previewRef} style={getItemStyle(currentOffset)}>
        <div
          className={styles.defaultPreview}
          style={getPreviewStyle(monitor, opts)}
        >
          <DefaultPreview monitor={monitor} />
        </div>
      </div>
    );
  }

  return (
    <div ref={previewRef} style={getItemStyle(currentOffset)}>
      <MemoizedPreviewRenderer monitor={monitor} opts={opts} item={item} />
    </div>
  );
};

const PreviewRenderer = ({ monitor, opts, item }: any) => {
  return (
    <div style={getPreviewStyle(monitor, opts)}>
      <span style={getCenterItemStyle(opts)}>{item.customRenderer()}</span>
    </div>
  );
};

const MemoizedPreviewRenderer = memo(PreviewRenderer, () => true);

export const DragLayer = () => {
  const isAppDragging = useAppSelector((state) => state.formBuilder.isDragging);
  const { isDragging, item, currentOffset, initialOffset, monitor } =
    useDragLayer((monitor) => ({
      monitor: monitor,
      item: monitor.getItem(),
      isDragging: monitor.isDragging(),
      currentOffset: monitor.getSourceClientOffset(),
      initialOffset: monitor.getInitialSourceClientOffset()
    }));

  if (!isAppDragging) return null;
  if (!isDragging) return null;
  if (!currentOffset) return null;
  if (!initialOffset) return null;
  if (!item) return null;
  if (!monitor) return null;

  const offset: XYCoord = { x: currentOffset.x, y: currentOffset.y };
  const initOffset: XYCoord = { x: initialOffset.x, y: initialOffset.y };

  // Support anchoring a drag preview to the cursor
  const anchor = item?.opts?.preview?.anchor;
  if (anchor === 'topLeft' || anchor === 'center') {
    // anchor preview's topLeft corner to the cursor
    // if anchor is centered, an additional transform is applied in the renderer
    const initialSourceClientOffset = monitor.getInitialSourceClientOffset();
    const initialClientOffset = monitor.getInitialClientOffset();
    if (initialSourceClientOffset && initialClientOffset) {
      const adjustedOffset = {
        x: initialClientOffset.x - initialSourceClientOffset.x,
        y: initialClientOffset.y - initialSourceClientOffset.y
      };
      offset.x += adjustedOffset.x;
      offset.y += adjustedOffset.y;
      initOffset.x += adjustedOffset.x;
      initOffset.y += adjustedOffset.y;
    }
  }

  if (offset && item?.opts?.preview?.adjustedOffset) {
    const adjustedOffset = item.opts.preview.adjustedOffset;

    offset.x += adjustedOffset.x;
    offset.y += adjustedOffset.y;
    initOffset.x += adjustedOffset.x;
    initOffset.y += adjustedOffset.y;
  }

  return (
    <Preview
      item={item}
      monitor={monitor}
      currentOffset={offset}
      initialOffset={initOffset}
      opts={item.opts}
    />
  );
};

// This layer exists outside the actual <DragLayer/> so that it does not destroy ghost elements by re-rendering
const CustomDragLayer = () => {
  return (
    <div id={layerId} className={styles.dragLayer}>
      <DragLayer />
    </div>
  );
};

export default CustomDragLayer;
