import { useEffect, useMemo, useState } from 'react';

import { FilterWidget } from '.';
import { useAppSelector } from '../../hooks';

import styles from './styles.module.scss';
import classNames from 'classnames';
import { FIELD_FILTER_ID } from './constants';
import { DropdownField, TextField } from '../Core';
import {
  EQUAL_OPERATORS,
  FIELD_OPERATORS,
  getComparisonOperators,
  OperatorMeta
} from '../../utils/validation';

import { OPERATOR_CODE } from '@feathery/react';
import elementEntries, {
  OPTION_FIELDS
} from '../SelectionPanel/elementEntries';

const OPERATOR_CODE_FIELD_LOOKUP_MAP: Partial<
  Record<OPERATOR_CODE, { lookup: string; fixedValue?: string }>
> = {
  equal: { lookup: 'eq' },
  not_equal: { lookup: 'ne' },
  equal_ignore_case: { lookup: 'ieq' },
  not_equal_ignore_case: { lookup: 'ine' },
  greater_than: { lookup: 'gt' },
  greater_than_or_equal: { lookup: 'gte' },
  less_than: { lookup: 'lt' },
  less_than_or_equal: { lookup: 'lte' },
  contains: { lookup: 'contains' },
  contains_ignore_case: { lookup: 'icontains' },
  not_contains: { lookup: 'ncontains' },
  not_contains_ignore_case: { lookup: 'incontains' },
  starts_with: { lookup: 'startswith' },
  not_starts_with: { lookup: 'nstartswith' },
  ends_with: { lookup: 'endswith' },
  not_ends_with: { lookup: 'nendswith' },
  is_empty: { lookup: 'empty', fixedValue: 'true' },
  is_filled: { lookup: 'empty', fixedValue: 'false' },
  is_true: { lookup: 'eq', fixedValue: 'true' },
  is_false: { lookup: 'empty', fixedValue: 'true' }
};
const OPERATOR_CODE_FIELD_REVERSE_LOOKUP: Record<string, OPERATOR_CODE[]> = {};
Object.entries(OPERATOR_CODE_FIELD_LOOKUP_MAP).forEach(([key, value]) => {
  OPERATOR_CODE_FIELD_REVERSE_LOOKUP[value.lookup] =
    OPERATOR_CODE_FIELD_REVERSE_LOOKUP[value.lookup] ?? [];
  OPERATOR_CODE_FIELD_REVERSE_LOOKUP[value.lookup].push(key as OPERATOR_CODE);
});

const SERVAR_TYPES = Object.entries(elementEntries)
  .filter(([key, value]: [string, any]) => value.type === 'field')
  .map(([key, value]) => key);

// Need to restrict the operators that can be used with certain field types
// because some operators are not supported on the BE
const RESTRICTED_MULTI_OPERATORS: OPERATOR_CODE[] = [
  'is_empty',
  'is_filled',
  'contains'
];
const RESTRICTED_SERVAR_TYPE_OPERATORS: Record<string, OPERATOR_CODE[]> = {
  dropdown_multi: RESTRICTED_MULTI_OPERATORS,
  multiselect: RESTRICTED_MULTI_OPERATORS,
  button_group: RESTRICTED_MULTI_OPERATORS
};
// Some field types are not supported for filtering at all
const UNSUPPORTED_SERVAR_TYPES: string[] = ['matrix', 'payment_method'];

type ServarIdentifiers = 'key' | 'id';

// Servar field filter custom widget.
// Components using this filter can use either the servar key of the servar id
// as the param name of the field filter param.
const FieldFilter: (
  identifierProp?: ServarIdentifiers,
  servarTypeDisallowedOperators?: Record<string, OPERATOR_CODE[]>
) => FilterWidget =
  (identifierProp = 'key', servarTypeDisallowedOperators = {}) =>
  // eslint-disable-next-line react/display-name
  ({ paramName, paramValue, onChange }) => {
    const servars = useAppSelector((state) => state.fields.servars);
    // map servar identifier to servar
    const servarMap = useMemo(() => {
      const _servarMap: Record<string, any> = {};
      (servars ?? []).forEach(
        (servar: any) =>
          (_servarMap[identifierProp === 'key' ? servar.key : servar.id] =
            servar)
      );
      return _servarMap;
    }, [servars, identifierProp]);

    const [servarIdentifier, setServarIdentifier] = useState('');
    const [operator, setOperator] = useState<OPERATOR_CODE>();
    const [val, setVal] = useState(paramValue);
    const servarOptions = useMemo(
      () =>
        (servars ?? [])
          .filter(
            (servar: any) => !UNSUPPORTED_SERVAR_TYPES.includes(servar.type)
          )
          .map((servar: any) => ({
            value: identifierProp === 'key' ? servar.key : servar.id,
            display: servar.key
          }))
          .sort((a, b) =>
            a.display.toLowerCase().localeCompare(b.display.toLowerCase())
          ),
      [servars, identifierProp]
    );
    const getSupportedOperators = (servarIdentifier: string) => {
      const supportedOperators = Object.keys(OPERATOR_CODE_FIELD_LOOKUP_MAP);
      const servar = servarMap[servarIdentifier];
      const restrictedOperators = RESTRICTED_SERVAR_TYPE_OPERATORS[servar.type];
      return (
        getComparisonOperators(servar)
          .filter((o: any) => supportedOperators.includes(o.code))
          .filter(
            // either no restrictions or the operators is in the restricted list
            (o: any) =>
              !restrictedOperators || restrictedOperators.includes(o.code)
          )
          // also filter out any disallowed operators
          .filter(
            (o: any) =>
              !servarTypeDisallowedOperators[servar.type] ||
              !servarTypeDisallowedOperators[servar.type].includes(o.code)
          )
      );
    };
    const operatorOptions = useMemo(() => {
      if (servarIdentifier)
        return getSupportedOperators(servarIdentifier).map((o: any) => ({
          value: o.code,
          display: o.display
        }));
      return [];
    }, [servarIdentifier]);

    const valueOptions = useMemo(() => {
      if (
        servarIdentifier &&
        operator &&
        operator in EQUAL_OPERATORS &&
        OPTION_FIELDS.includes(servarMap[servarIdentifier]?.type)
      ) {
        const selectableValues = servarMap[servarIdentifier].metadata.options;

        const options = selectableValues.map((option: string) => {
          return {
            display: option,
            value: option
          };
        });
        if (!selectableValues.includes(val)) {
          options.unshift({
            display: val,
            option: val,
            disabled: true
          });
        }
        return options;
      }
      return [];
    }, [servarIdentifier, operator, servarMap, val]);

    // Field filter request param name format: <type>_<servar_key>__<field_lookup_alias>
    // e.g. text_field_my_text_field__icontains
    useEffect(() => {
      if (paramName && !servarIdentifier && !operator) {
        // parse the paramName to get the servarIdentifier, fieldLookupAlias
        const fieldLookupAliasIndex = paramName.lastIndexOf('__');
        if (fieldLookupAliasIndex > -1) {
          const fieldLookupAlias = paramName.substring(
            fieldLookupAliasIndex + 2
          );
          const remaining = paramName.substring(0, fieldLookupAliasIndex);
          // Find the servar type that the remaining string starts with
          // It is bracketed with []
          const servarType = SERVAR_TYPES.find((key) => {
            const searchText = `[${key}]_`;
            return remaining.startsWith(searchText);
          });
          if (servarType) {
            const fieldIdentifier = remaining.substring(servarType.length + 3); // brackets and underscore
            setServarIdentifier(fieldIdentifier);

            // Use the fieldLookupAlias and the servar type and value to determine the operator
            let operators =
              OPERATOR_CODE_FIELD_REVERSE_LOOKUP[fieldLookupAlias] ?? [];
            operators = getSupportedOperators(fieldIdentifier)
              .map((o: OperatorMeta) => o.code)
              .filter((o: OPERATOR_CODE) => operators.includes(o))
              .filter((o: OPERATOR_CODE) => {
                const fixedValue =
                  OPERATOR_CODE_FIELD_LOOKUP_MAP[o]?.fixedValue;
                return fixedValue === undefined || fixedValue === paramValue;
              });

            let operator: OPERATOR_CODE | undefined =
              operators.length > 0 ? operators[0] : undefined;
            // special handling for is_empty -> is_filled
            if (operator === 'is_empty' && paramValue === 'false')
              operator = 'is_filled';
            setOperator(operator);
          }
        }
      }
    }, [
      paramName,
      paramValue,
      servarIdentifier,
      setServarIdentifier,
      operator,
      setOperator
    ]);

    const handleChange = (
      servarIdentifier: string | undefined,
      operator: OPERATOR_CODE | undefined,
      val: string | undefined
    ) => {
      if (!servarIdentifier || !operator) return;
      // construct the paramName as: <type>_<servar_key>__<field_lookup_alias>
      const type = servarMap[servarIdentifier]?.type;
      const lookupAlias = OPERATOR_CODE_FIELD_LOOKUP_MAP[operator]?.lookup;
      // infix type operators are handled differently and have a fix value for the paramValue
      const value = OPERATOR_CODE_FIELD_LOOKUP_MAP[operator]?.fixedValue ?? val;
      const displayValue = FIELD_OPERATORS[operator]?.infix ? value : '';

      onChange(
        // Adding the servar key to the id so that we can support multiple field filters
        //  but only one per field.
        `${FIELD_FILTER_ID}_${servarIdentifier}`,
        `[${type}]_${servarIdentifier}__${lookupAlias}`,
        value ?? '',
        `${servarMap[servarIdentifier].key} ${FIELD_OPERATORS[operator].display} ${displayValue}`
      );
    };

    return (
      <>
        <div className={classNames(styles.filterExpression, styles.field)}>
          <DropdownField
            className={styles.input}
            onChange={(event: any) => {
              setServarIdentifier(event.target.value);
              handleChange(event.target.value, operator, val);
            }}
            selected={servarIdentifier}
            options={servarOptions}
          />
          <DropdownField
            className={styles.input}
            onChange={(event: any) => {
              setOperator(event.target.value);
              handleChange(servarIdentifier, event.target.value, val);
            }}
            selected={operator}
            options={operatorOptions}
          />
          {operator && FIELD_OPERATORS[operator]?.infix && (
            <>
              {operator in EQUAL_OPERATORS &&
              OPTION_FIELDS.includes(servarMap[servarIdentifier]?.type) ? (
                <DropdownField
                  autoFocus
                  hideCaret
                  className={styles.valueInput}
                  selected={val}
                  title={val}
                  options={valueOptions}
                  onChange={(event: any) => {
                    setVal(event.target.value);
                    handleChange(
                      servarIdentifier,
                      operator,
                      event.target.value
                    );
                  }}
                  triggerCleanUp
                />
              ) : (
                <TextField
                  autoFocus
                  className={styles.valueInput}
                  value={val}
                  title={val}
                  placeholder='value'
                  onComplete={(newVal: string) => {
                    setVal(newVal);
                    handleChange(servarIdentifier, operator, newVal);
                  }}
                  triggerCleanUp
                />
              )}
            </>
          )}
        </div>
      </>
    );
  };

export default FieldFilter;
