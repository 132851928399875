import { PageProps } from './types';
import styles from './styles.module.scss';

import {
  API_CONNECTOR_TEMPLATE_PAGE,
  CONFIGURE_REQUEST_PAGE,
  SELECT_API_SERVICE_PAGE
} from '../constants';
import { useState } from 'react';
import { NavBar } from '../components/NavBar';
import { useAppSelector } from '../../../../hooks';
import { useParams } from 'react-router-dom';
import { APPS, TEMPLATES } from '../templates';
import { DropdownField } from '../../../Core';
import { TemplateButton } from '../components/TemplateButton';

export const APIConnectorTemplatePage = (props: PageProps) => {
  const { setState, options, goto = () => {} } = props;
  const { formId } = useParams<{ formId: string }>();
  const [selectedApp, setSelectedApp] = useState<keyof typeof APPS | ''>('');
  const [selectedAction, setSelectedAction] = useState<string>('');
  const enabledIntegrations = useAppSelector((state) => {
    return Object.entries(state.integrations.integrations[formId] ?? {})
      .filter(([_, value]: any) => value?.data?.active)
      .map(([key, _]) => key);
  });
  const handleSubmit = () => {
    if (selectedApp === 'custom') {
      setState(APPS.custom.actions[0].defaultState);
      goto(CONFIGURE_REQUEST_PAGE);
    } else if (selectedAction && selectedAction in TEMPLATES) {
      setState(
        TEMPLATES[selectedAction as keyof typeof TEMPLATES].defaultState
      );
      goto(CONFIGURE_REQUEST_PAGE);
    } else {
      console.error('Invalid action');
    }
  };

  return (
    <div className={styles.page}>
      <div className={styles.content}>
        <div className='tw-flex tw-flex-row tw-flex-wrap tw-w-full -tw-mx-2'>
          {Object.keys(APPS).map((id) => (
            <TemplateButton
              key={id}
              app={id}
              onClick={() => {
                if (selectedApp !== id) {
                  setSelectedApp(id as any);
                  setSelectedAction(
                    APPS[id as keyof typeof APPS].actions[0].id
                  );
                }
              }}
              selected={selectedApp === id}
              enabledIntegrations={enabledIntegrations}
            />
          ))}
        </div>
        {selectedApp && selectedApp !== 'custom' && (
          <div className='tw-mt-8'>
            <div className={styles.label}>Select Action</div>
            <DropdownField
              placeholder='Select Action'
              selected={selectedAction}
              options={APPS[selectedApp].actions.map((action) => ({
                display: action.display,
                value: action.id
              }))}
              onChange={(event: any) => setSelectedAction(event.target.value)}
            />
          </div>
        )}
      </div>
      <NavBar
        next
        back={
          options?.startPage != null &&
          options.startPage < API_CONNECTOR_TEMPLATE_PAGE
        }
        disabled={{
          next: !selectedAction
        }}
        onClick={(btn: string) => {
          if (btn === 'next') handleSubmit();
          if (btn === 'back') goto(SELECT_API_SERVICE_PAGE);
        }}
      />
    </div>
  );
};
