function QuoteRUSH({ width = 230, height = 80 }) {
  return (
    <img
      alt='QuoteRUSH logo'
      src={require('./QuoteRUSH.png')}
      style={{ width, height }}
    />
  );
}

export default QuoteRUSH;
