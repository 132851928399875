import {
  CONTENT_TYPE,
  httpDelete,
  httpGet,
  httpPatch,
  httpPost,
  URL
} from './utils';

export default {
  getDocuments: (token: string) => {
    return httpGet({
      token,
      url: URL.DOCUMENTS
    });
  },
  createDocument: (
    token: string,
    { file, annotated_fields, type, folder }: any
  ) => {
    return httpPost({
      token,
      url: URL.DOCUMENTS,
      body: {
        file,
        annotated_fields: JSON.stringify(annotated_fields),
        verify_email: true,
        type,
        folder: folder ?? ''
      },
      contentType: CONTENT_TYPE.MULTIPART_FORM
    });
  },
  editDocument: (token: string, { documentId, ...data }: any) => {
    const transformed: Record<string, any> = {};
    Object.entries(data).forEach(([key, val]) => {
      if (typeof val !== 'string' && !(val instanceof Blob))
        val = JSON.stringify(val);
      transformed[key] = val;
    });

    return httpPatch({
      token,
      baseUrl: URL.DOCUMENTS,
      path: `${documentId}/`,
      body: transformed,
      contentType: CONTENT_TYPE.MULTIPART_FORM
    });
  },
  deleteDocument: (token: string, documentId: string) => {
    return httpDelete({
      token,
      baseUrl: URL.DOCUMENTS,
      path: `${documentId}/`
    });
  },
  getEnvelopes: (token: string, documentId: string) => {
    return httpGet({ token, url: `${URL.DOCUMENTS}${documentId}/envelope/` });
  },
  createEnvelope: (token: string, { documentId, ...data }: any) => {
    return httpPost({
      token,
      url: `${URL.DOCUMENTS}${documentId}/envelope/`,
      body: data
    });
  },
  deleteEnvelope: (token: string, envelopeId: string) => {
    return httpDelete({
      token,
      baseUrl: URL.DOCUMENTS,
      path: `envelope/${envelopeId}/`,
      invalid: [`${URL.DOCUMENTS}/envelope/`]
    });
  },
  downloadEnvelopeLog: (token: string, envelopeId: string) => {
    return httpGet({
      token,
      url: `${URL.DOCUMENTS}envelope/${envelopeId}/log/`
    });
  }
};
