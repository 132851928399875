import classNames from 'classnames';
import styles from './styles.module.scss';

const PROGRESS_STEPS = ['Configure Request', 'Map Response'];

type ProgressBarProps = {
  activeStep: number;
  onSetActiveStep?: (step: number) => void;
  allowSkipping?: boolean;
};

export const ProgressBar = ({
  activeStep,
  onSetActiveStep = () => {},
  allowSkipping = false
}: ProgressBarProps) => {
  return (
    <div className={styles.progressBar}>
      {PROGRESS_STEPS.map((step, index) => (
        <>
          <div
            key={step}
            onClick={() =>
              (allowSkipping || index < activeStep) && onSetActiveStep(index)
            }
            className={classNames(styles.progressStep, {
              [styles.active]: index === activeStep,
              [styles.complete]: allowSkipping || index < activeStep,
              [styles.canNavigate]: allowSkipping || index < activeStep
            })}
          >
            {step}
          </div>
          {index < PROGRESS_STEPS.length - 1 && (
            <div key={`${step}-dividor`} className={styles.progressLine}>
              •••••
            </div>
          )}
        </>
      ))}
    </div>
  );
};
