import { FC, useState } from 'react';

import { useAppSelector } from '../../hooks';
import {
  DownloadCSVModal,
  EditTableViewModal,
  UploadSubmissionsCSVModal
} from '../Modals';
import { ColumnsPropType } from '../Modals/EditTableViewModal';

import SummaryStatistic from './SummaryStatistic';
import { DropdownField } from '../Core';

import styles from './styles.module.scss';
import { Button } from '../Core/Button/button';

const w = window as any;
const FORM_ENVIRONMENTS = [
  { display: 'Live', value: 'Production' },
  { display: 'Test', value: 'Development' }
];

export type IconProps = {
  width?: number;
  height?: number;
  color?: string;
};
export type Icon = FC<IconProps>;

type ResultsHeaderProps = {
  label: string;
  count: number;
  refresh?: () => void;
  onPageChange: (page: string) => void;
  onEnvChange: (env: string) => void;
  hideEditTableView?: boolean;
  Icon: Icon;
  columnsProp?: ColumnsPropType;
  ConfigComponent?: FC;
  hideDownloadCSV?: boolean;
  panel?: any;
};

const ResultsHeader = ({
  label,
  count,
  refresh = () => {},
  onPageChange = () => {},
  onEnvChange = () => {},
  hideEditTableView = false,
  Icon,
  columnsProp = 'result_columns',
  ConfigComponent,
  hideDownloadCSV = true,
  panel
}: ResultsHeaderProps) => {
  const account = useAppSelector((state) => state.accounts.account);
  const org = useAppSelector((state) => state.accounts.organization) as any;
  const disableUploadSubmissions = org && org.tier !== 4;

  const [editTableView, setEditTableView] = useState(false);
  const [downloadingCSV, setDownloadingCSV] = useState(false);
  const [showUploadCSVModal, setshowUploadCSVModal] = useState(false);

  const [envName, setEnvName] = useState(w.envName || 'Production');
  const updateEnv = (newEnv: string) => {
    if (newEnv === envName) return;
    setEnvName(newEnv);
    onEnvChange(newEnv);
    onPageChange('');
  };

  return (
    <>
      {!hideEditTableView && (
        <EditTableViewModal
          show={editTableView}
          close={() => setEditTableView(false)}
          onEdit={() => {
            onPageChange('');
            refresh();
          }}
          columnsProp={columnsProp}
          panel={panel}
        />
      )}
      {!hideDownloadCSV && panel && (
        <DownloadCSVModal
          panel={panel}
          envName={envName}
          show={downloadingCSV}
          close={() => setDownloadingCSV(false)}
        />
      )}
      {showUploadCSVModal && (
        <UploadSubmissionsCSVModal
          panelId={panel.id}
          show={showUploadCSVModal}
          setShow={setshowUploadCSVModal}
          refreshSubmissionData={refresh}
        />
      )}
      <div className={styles.resultsHeader}>
        <div className={styles.summaryCard}>
          <SummaryStatistic
            label='Environment'
            value={
              <DropdownField
                className={styles.resultsEnvSelector}
                selected={envName}
                options={FORM_ENVIRONMENTS}
                onChange={(e: any) => {
                  const {
                    target: { value }
                  } = e;
                  updateEnv(value);
                  (window as any).envName = value;
                }}
              />
            }
          />
          <div className={styles.separator} />
          <SummaryStatistic
            icon={<Icon height={32} width={32} color='var(--prim-100)' />}
            label={label}
            value={count}
          />
          {!hideEditTableView && account.role !== 'viewer' && (
            <>
              <div className={styles.separator} />
              <div className='tw-px-[25px] tw-py-[20px]'>
                <Button
                  variant='ghost-primary'
                  disabled={editTableView}
                  onClick={() => setEditTableView(true)}
                >
                  Edit Table View
                </Button>
              </div>
            </>
          )}
          <>
            <div className={styles.separator} />
            <div className='tw-px-[25px] tw-py-[20px]'>
              <Button
                variant='ghost-primary'
                disabled={disableUploadSubmissions}
                style={{ width: '162px' }}
                onClick={() => {
                  setshowUploadCSVModal(true);
                }}
              >
                Upload Submissions
              </Button>
            </div>
          </>
          {!hideDownloadCSV && (
            <>
              <div className={styles.separator} />
              <div className='tw-px-[25px] tw-py-[20px]'>
                <Button
                  variant='ghost-primary'
                  disabled={downloadingCSV}
                  onClick={() => setDownloadingCSV(true)}
                >
                  Download CSV
                </Button>
              </div>
            </>
          )}
        </div>
        {ConfigComponent ? <ConfigComponent /> : <div />}
      </div>
    </>
  );
};

export default ResultsHeader;
